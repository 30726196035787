import {
	GET_USER_NAME_FAILED,
	GET_USER_NAME_FETCH,
	GET_USER_NAME_SUCCESS,
	LOGIN_FAILED,
	LOGIN_FETCH,
	LOGIN_SUCCESS,
	LOGOUT,
	TOGGLE_TUTORIAL_MODAL_OPEN,
	CHANGE_PASSWORD_FETCH,
	CHANGE_PASSWORD_FAILED,
	UPDATE_USER_CREDIT_INFORMATION,
	VERIFY_VERIFICATION_INIT,
	VERIFY_VERIFICATION_DONE,
	VERIFY_VERIFICATION_FAIL,
	SEND_VERIFICATION_EMAIL_INIT,
	SEND_VERIFICATION_EMAIL_DONE,
	SEND_VERIFICATION_EMAIL_FAIL,
	DELETE_USER_WITH_ID_INIT,
	DELETE_USER_WITH_ID_DONE,
	DELETE_USER_WITH_ID_FAILED,
	USERS_TRANSFER_INIT,
	USERS_TRANSFER_DONE,
	USERS_TRANSFER_FAILED,
	VERIFY_EMAIL_INIT,
	VERIFY_EMAIL_DONE,
	VERIFY_EMAIL_FAIL,
	USERS_UPDATE_INIT,
	USERS_UPDATE_DONE,
	USERS_UPDATE_FAIL,
	LOAD_PROFILE_INIT,
	LOAD_PROFILE_DONE,
	LOAD_PROFILE_FAIL,
	REGISTER_CONTACTS_BLOCKED_BY_COUNTRY_INIT,
	REGISTER_CONTACTS_BLOCKED_BY_COUNTRY_DONE,
	REGISTER_CONTACTS_BLOCKED_BY_COUNTRY_FAIL,
	GET_USER_LOCATION_INIT,
	GET_USER_LOCATION_DONE,
	GET_USER_LOCATION_FAIL,
} from "store/mapx/mapXActionTypes";

export const userInitialState = {
	isTutorialModalOpened: false,
	loginInProgress: null,
	loggedOutManually: false,
	authUserError: false,
	user: {},
	getUserNameInProgress: null,
	getUserNameError: null,
	resetPasswordInProgress: null,
	deleteUserInProgress: false,
	userTransferProgress: false,
	resetPassProgress: false,
	resetPasswordError: null,
	iseEditedUser: false,
	verificationCodeInProgress: false,
	verificationCode: null,
	verificationEmailInProgress: false,
	verificationEmailError: null,
	verificationEmailSent: false,
	verificationEmail: "",
	updatingUsers: [],
	loadingProfile: false,
	registerContactsBlockedByCountryInProgress: false,
	registerContactsBlockedByCountrySubmitted: false,
	location: {},
	locationInProgress: false,
};

const userReducer = (state = userInitialState, action) => {
	switch (action.type) {
		case GET_USER_LOCATION_INIT:
			return {
				...state,
				locationInProgress: true,
			};
		case GET_USER_LOCATION_DONE:
			return {
				...state,
				locationInProgress: false,
				location: action.payload,
			};
		case GET_USER_LOCATION_FAIL:
			return {
				...state,
				locationInProgress: false,
			};
		case REGISTER_CONTACTS_BLOCKED_BY_COUNTRY_INIT:
			return {
				...state,
				registerContactsBlockedByCountryInProgress: true,
			};
		case REGISTER_CONTACTS_BLOCKED_BY_COUNTRY_DONE:
			return {
				...state,
				registerContactsBlockedByCountryInProgress: false,
				registerContactsBlockedByCountrySubmitted: true,
			};
		case REGISTER_CONTACTS_BLOCKED_BY_COUNTRY_FAIL:
			return {
				...state,
				registerContactsBlockedByCountryInProgress: false,
			};
		case SEND_VERIFICATION_EMAIL_INIT:
			return {
				...state,
				verificationEmailInProgress: true,
				verificationEmailError: null,
				verificationEmailSent: false,
			};
		case SEND_VERIFICATION_EMAIL_DONE:
			return {
				...state,
				verificationEmailInProgress: false,
				verificationEmailSent: true,
				verificationEmail: action.payload,
			};
		case SEND_VERIFICATION_EMAIL_FAIL:
			return {
				...state,
				verificationEmailInProgress: false,
				verificationEmailError: action.payload,
			};
		case VERIFY_VERIFICATION_INIT:
			return {
				...state,
				verificationCodeInProgress: true,
			};
		case VERIFY_VERIFICATION_DONE:
			return {
				...state,
				verificationCode: action.payload,
				verificationCodeInProgress: false,
			};
		case VERIFY_VERIFICATION_FAIL:
			return {
				...state,
				verificationCodeInProgress: false,
			};
		case LOAD_PROFILE_INIT:
			return {
				...state,
				loadingProfile: true,
			};
		case LOAD_PROFILE_DONE:
			return {
				...state,
				loadingProfile: false,
				user: action.payload,
			};
		case LOAD_PROFILE_FAIL:
			return {
				...state,
				loadingProfile: false,
			};
		case LOGIN_FETCH:
			return {...state, authAdminInProgress: true};

		case LOGIN_SUCCESS:
			return {
				...state,
				authAdminInProgress: false,
				user: action.payload,
				loggedOutManually: false,
			};
		case LOGIN_FAILED:
			return {
				...state,
				authAdminError: action.payload,
				authAdminInProgress: false,
			};
		case GET_USER_NAME_FETCH:
			return {...state, getUserNameInProgress: true};
		case GET_USER_NAME_SUCCESS:
			return {
				...state,
				getUserNameInProgress: false,
				user: {...state.user, email: action.payload},
			};
		case GET_USER_NAME_FAILED:
			return {
				...state,
				getUserNameError: action.payload,
				getUserNameInProgress: false,
			};
		case TOGGLE_TUTORIAL_MODAL_OPEN:
			return {...state, isTutorialModalOpened: !state.isTutorialModalOpened};

		case LOGOUT:
			return {...userInitialState, loggedOutManually: true};
		case CHANGE_PASSWORD_FETCH:
			return {...state, changePass: action.payload};
		case CHANGE_PASSWORD_FAILED:
			return {...state, changePassFailed: action.payload};
		case UPDATE_USER_CREDIT_INFORMATION:
			return {
				...state,
				user: {...state.user, ...action.payload}, // action payload contains {credit: xxx, credits_refresh_at: xxx}
			};

		case DELETE_USER_WITH_ID_INIT:
			return {...state, deleteUserInProgress: true};
		case DELETE_USER_WITH_ID_DONE:
			return {
				...state,
				deleteUserInProgress: false,
			};
		case DELETE_USER_WITH_ID_FAILED:
			return {...state, deleteUserInProgress: false};

		case USERS_TRANSFER_INIT:
			return {...state, userTransferProgress: true};
		case USERS_TRANSFER_DONE:
			return {
				...state,
				userTransferProgress: false,
			};
		case USERS_TRANSFER_FAILED:
			return {...state, userTransferProgress: false};

		case VERIFY_EMAIL_INIT:
			return {...state, resetPassProgress: true};
		case VERIFY_EMAIL_DONE:
			return {
				...state,
				resetPassProgress: false,
			};
		case VERIFY_EMAIL_FAIL:
			return {...state, resetPassProgress: false};

		case USERS_UPDATE_INIT: {
			const {userID} = action;

			return {
				...state,
				iseEditedUser: true,
				updatingUsers: [...state.updatingUsers, userID],
			};
		}
		case USERS_UPDATE_DONE: {
			const {userID} = action;

			return {
				...state,
				iseEditedUser: false,
				updatingUsers: state.updatingUsers.filter((id) => id !== userID),
			};
		}
		case USERS_UPDATE_FAIL: {
			const {userID} = action;

			return {
				...state,
				iseEditedUser: false,
				updatingUsers: state.updatingUsers.filter((id) => id !== userID),
			};
		}
		default:
			return state;
	}
};

export default userReducer;
