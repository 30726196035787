import React, {type FC, useCallback, useMemo} from "react";
import classNames from "classnames";

import {ChevronIcon, InfoIcon} from "assets/icons";
import LockIcon from "assets/icons/LockIcon";
import {UpgradeNowTooltip} from "components";
import {useAppDispatch, useAppSelector} from "hooks";
import {setActiveAccordionOnFilter} from "store/mapx/filter/filterActions";
import {activeAccordionOnFilterSelector} from "store/mapx/filter/filterSelectors";

import type {TAccordionProps} from "./types";
import styles from "./accordion.module.scss";

const Accordion: FC<TAccordionProps> = ({
	title,
	children,
	subtitle = "",
	disabled = false,
	displayLock = false,
	customClass,
	titleClass,
	onOpen,
	onClose,
	tooltipId,
}) => {
	const dispatch = useAppDispatch();

	const activeAccordion = useAppSelector(activeAccordionOnFilterSelector);

	const expanded = useMemo(() => activeAccordion === title, [activeAccordion, title]);

	const handleToggle = useCallback(() => {
		if (disabled) return;

		if (expanded) {
			dispatch(setActiveAccordionOnFilter(null));
			onClose && onClose(); // Call onClose only if defined and accordion is collapsing
		} else {
			dispatch(setActiveAccordionOnFilter(title));
			onOpen && onOpen(); // Call onOpen only if defined and accordion is expanding
		}
	}, [disabled, expanded, title, dispatch, onOpen, onClose]);

	return (
		<div
			className={classNames(styles.accordion, customClass, {
				[styles.disabled]: disabled,
				[styles.expanded]: expanded,
			})}
			aria-expanded={expanded}
			key={title}
		>
			<div className={styles.header}>
				<div className={styles.left}>
					<div className={classNames(styles.title, titleClass)} onClick={handleToggle}>
						{title}
					</div>

					{!!subtitle && <div className={styles.count}>{subtitle}</div>}

					{tooltipId && (
						<div
							style={{marginLeft: "5px", position: "relative"}}
							data-testid="info-icon"
							data-tooltip-id={tooltipId}
						>
							<InfoIcon />
						</div>
					)}
				</div>
				<div className={styles.right} onClick={handleToggle}>
					{displayLock ? (
						<div data-tip={true} data-tooltip-id={title}>
							<LockIcon style={{cursor: "pointer"}} />

							<UpgradeNowTooltip id={title} />
						</div>
					) : (
						<ChevronIcon
							className={classNames(styles.chevron, {[styles.rotated]: expanded})}
							color={disabled ? "#CBC5C2" : "#B5B1B0"}
						/>
					)}
				</div>
			</div>
			<div className={classNames(styles.children, {[styles.expanded]: expanded})}>
				{expanded && children}
			</div>
		</div>
	);
};

export default React.memo(Accordion);
