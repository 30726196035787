import {Dispatch} from "redux";
import {TAppDispatch, TRootState} from "types";
import {TConnectorLogic, TPositionLogic} from "store/mapx/market-report/types";
import {
	mRCandidateCompaniesByPositionSelector,
	mRCandidateCompanyEventSizeSelector,
	mRCandidateCompanyEventTypesSelector,
	mRCandidateCompanyHeadcountByPositionSelector,
	mRCandidateCompanyRevenueByPositionSelector,
	mRCandidateCompanyTypesByPositionSelector,
	mRCandidateCountryIdsByPositionSelector,
	mRCandidateIndustriesByPositionSelector,
	mRCandidateLanguageSelector,
	mRCandidateRegionIdsByPositionSelector,
	mRCompaniesFilterKeyByPosition,
	mRCompanyFilterConfigSelector,
	mRCompanyHeadcountFilterKeyByPosition,
	mRCompanyRevenueFilterKeyByPosition,
	mRCompanyTypeFilterKeyByPosition,
	mRIndustriesFilterKeyByPosition,
	mRKeywordFilterKeyByPosition,
	mRLocationFilterKeyByPosition,
	mRRegionFilterKeyByPosition,
	mrSpecialismFilterKeyByPosition,
} from "store/mapx/market-report/marketReportFilterSelectors";
import {
	TJobFunctionPosition,
	TJobFunctionPositionConnectivityLogic,
} from "api/filterOptionsApi/JobFunctionApi/types";
import {specialismFilterKeyByPosition} from "store/mapx/filter/jobFunctionFilterSelectors";
import {
	setMarketReportMultipleFilterValueInForm,
	setMRSelectedCompanies,
} from "store/mapx/market-report/marketReportActions";
import {TIndustryPosition} from "api/filterOptionsApi/IndustryApi/types";
import {TCompanyHeadcountRangeItem} from "mapx-components/Filters/CompanyHeadcountSearchFilter/types";
import {TCompanyRevenueRangeItem} from "mapx-components/Filters/CompanyRevenueSearchFilter/types";
import {TCompanyTypePosition} from "mapx-components/Filters/CandidateCompanyTypeSearchFilter/types";
import {TLocationPosition} from "containers/Filters/PeopleFilters/LocationFilter/types";
import {handleMoveLocation} from "mapx-components/Filters/LocationSearchFilter/utils";
import {TKeywordPosition} from "api/filterOptionsApi/KeywordApi/types";
import {getArrayDifference} from "helpers/array";
import {
	TCandidateCompanyEventSizeItem,
	TCandidateCompanyEventTypeItem,
} from "mapx-components/Filters/DealExperienceSearchFilter/types";
import {STCompany} from "api/companyApi/types";
import {removeDuplicateObjectFromArray} from "helpers/filterHandlers";
import {getMRSelectedCompaniesSelector} from "store/mapx/market-report/marketReportSelector";

/**
 * JOB FUNCTIONS START
 */

export const setSpecialismForMarketReportFormFilter =
	({position, logic, id}: {position: TPositionLogic; logic: TConnectorLogic; id: number}) =>
	(dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const state = getState();

		const specialismFilterKey = mrSpecialismFilterKeyByPosition(state, position, logic);

		const specialisms: number[] =
			state.marketReport.marketReportForm.filters[specialismFilterKey];

		const index = specialisms.indexOf(id);

		const isSelectedAlready = index !== -1;

		const nextSpecialisms = isSelectedAlready
			? [...specialisms.slice(0, index), ...specialisms.slice(index + 1)]
			: [...specialisms, id];

		const payload = {
			[specialismFilterKey]: nextSpecialisms,
		};

		dispatch(setMarketReportMultipleFilterValueInForm(payload));
	};

export const setBulkSpecialismsForMarketReportFormFilter =
	(position: TJobFunctionPosition, logic: TJobFunctionPositionConnectivityLogic, ids: number[]) =>
	(dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const state = getState();

		const specialismFilterKey = mrSpecialismFilterKeyByPosition(state, position, logic);

		const payload = {
			[specialismFilterKey]: ids,
		};

		dispatch(setMarketReportMultipleFilterValueInForm(payload));
	};

export const switchSpecialismsConnectorForMarketReportFormFilterPosition =
	({
		position,
		fromLogic,
		toLogic,
	}: {
		position: TJobFunctionPosition;
		fromLogic: TJobFunctionPositionConnectivityLogic;
		toLogic: TJobFunctionPositionConnectivityLogic;
	}) =>
	(dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const state = getState();

		const fromSpecialismFilterKey = mrSpecialismFilterKeyByPosition(state, position, fromLogic);

		const toSpecialismFilterKey = mrSpecialismFilterKeyByPosition(state, position, toLogic);

		const specialismIds: number[] =
			state.marketReport.marketReportForm.filters[fromSpecialismFilterKey];

		dispatch(
			setMarketReportMultipleFilterValueInForm({
				[fromSpecialismFilterKey]: [],
				[toSpecialismFilterKey]: specialismIds,
			}),
		);
	};

export const moveSpecialismForMarketReportFormFilter =
	({
		from,
		to,
		fromLogic,
		toLogic,
		id,
	}: {
		from: TJobFunctionPosition;
		to: TJobFunctionPosition;
		fromLogic: TJobFunctionPositionConnectivityLogic;
		toLogic: TJobFunctionPositionConnectivityLogic;
		id: number;
	}) =>
	(dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const state = getState();

		const fromSpecialismFilterKey = specialismFilterKeyByPosition(state, from, fromLogic);

		const toSpecialismFilterKey = specialismFilterKeyByPosition(state, to, toLogic);

		const fromSpecialisms: number[] =
			state.marketReport.marketReportForm.filters[fromSpecialismFilterKey];

		const toSpecialisms: number[] =
			state.marketReport.marketReportForm.filters[toSpecialismFilterKey];

		const nextFromSpecialisms = [...fromSpecialisms].filter((i) => i !== id);

		const nextToSpecialisms = [id, ...toSpecialisms];

		dispatch(
			setMarketReportMultipleFilterValueInForm({
				[fromSpecialismFilterKey]: nextFromSpecialisms,
				[toSpecialismFilterKey]: nextToSpecialisms,
			}),
		);
	};

export const clearSpecialismsByPositionForMarketReportFormFilter =
	(position: TJobFunctionPosition) => (dispatch: Dispatch<TAppDispatch>) => {
		let payload;

		switch (position) {
			case "current":
				payload = {
					current_specialisms_and: [],
					current_specialisms_or: [],
					current_specialisms_not: [],
				};
				break;
			case "previous":
				payload = {
					previous_specialisms_and: [],
					previous_specialisms_or: [],
					previous_specialisms_not: [],
				};
				break;
			default:
				payload = {
					specialisms_and: [],
					specialisms_or: [],
					specialisms_not: [],
				};
		}

		dispatch(setMarketReportMultipleFilterValueInForm(payload));
	};

/**
 * JOB FUNCTIONS END
 */

/**
 * COMPANIES START
 */

export const mRAddCompaniesToCompaniesSelectedList =
	(companies: STCompany[]) =>
	async (dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		if (companies?.length > 0) {
			const state = getState();

			const selectedCompanies = getMRSelectedCompaniesSelector(state);

			const companyFilterConfig = mRCompanyFilterConfigSelector(state);

			const position = companyFilterConfig.active_position;

			const updatedCompanies = selectedCompanies?.length
				? removeDuplicateObjectFromArray([...selectedCompanies, ...companies])
				: companies;

			dispatch(setMRSelectedCompanies(updatedCompanies));

			dispatch(
				setBulkCompaniesForMarketReportFormFilter({
					position,
					companies: updatedCompanies,
				}),
			);
		}
	};

export const setCompaniesForMarketReportFormFilter =
	({position, id}: {position: TPositionLogic; id: number}) =>
	async (dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const state = getState();

		const filterKey = mRCompaniesFilterKeyByPosition(state, position);

		const idsOnState = mRCandidateCompaniesByPositionSelector(state, position);

		const index = idsOnState.indexOf(id);

		const alreadySelected = index !== -1;

		const nextIds = alreadySelected
			? [...idsOnState.slice(0, index), ...idsOnState.slice(index + 1)]
			: [...idsOnState, id];

		dispatch(
			setMarketReportMultipleFilterValueInForm({
				[filterKey]: nextIds,
			}),
		);
	};

export const setBulkCompaniesForMarketReportFormFilter =
	({position, companies}: {position: TPositionLogic; companies: STCompany[]}) =>
	async (dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const state = getState();

		const filterKey = mRCompaniesFilterKeyByPosition(state, position);

		dispatch(
			setMarketReportMultipleFilterValueInForm({
				[filterKey]: companies.map((item) => item.id),
			}),
		);
	};

export const moveCompaniesForMarketReportFormFilter =
	({from, to, ids}: {from: TPositionLogic; to: TPositionLogic; ids: number[]}) =>
	async (dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const idsSet = new Set(ids);

		const state = getState();

		const fromFilterKey = mRCompaniesFilterKeyByPosition(state, from);

		const toFilterKey = mRCompaniesFilterKeyByPosition(state, to);

		const fromIds = mRCandidateCompaniesByPositionSelector(state, from);

		const toIds = mRCandidateCompaniesByPositionSelector(state, to);

		dispatch(
			setMarketReportMultipleFilterValueInForm({
				[fromFilterKey]: fromIds.filter((id: number) => !idsSet.has(id)),
				[toFilterKey]: [
					...toIds.filter((id: number) => !idsSet.has(id)), // prevent duplication
					...fromIds.filter((id: number) => idsSet.has(id)),
				],
			}),
		);
	};

/**
 * COMPANIES END
 */

/*
 ** INDUSTRY START
 */

export const moveIndustriesForMarketReportFormFilter =
	({from, to, ids}: {from: TIndustryPosition; to: TIndustryPosition; ids: number[]}) =>
	async (dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const idsSet = new Set(ids);

		const state = getState();

		const fromIndustriesFilterKey = mRIndustriesFilterKeyByPosition(state, from);

		const toIndustriesFilterKey = mRIndustriesFilterKeyByPosition(state, to);

		const fromIndustries = mRCandidateIndustriesByPositionSelector(state, from);

		const toIndustries = mRCandidateIndustriesByPositionSelector(state, to);

		dispatch(
			setMarketReportMultipleFilterValueInForm({
				[fromIndustriesFilterKey]: fromIndustries.filter((id: number) => !idsSet.has(id)),
				[toIndustriesFilterKey]: [
					...toIndustries.filter((id: number) => !idsSet.has(id)), // prevent duplication
					...fromIndustries.filter((id: number) => idsSet.has(id)),
				],
			}),
		);
	};
export const setIndustryForMarketReportFormFilter =
	({position, nextIds}: {position: TIndustryPosition; nextIds: number[]}) =>
	async (dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const state = getState();

		const industryFilterKey = mRIndustriesFilterKeyByPosition(state, position);

		dispatch(
			setMarketReportMultipleFilterValueInForm({
				[industryFilterKey]: nextIds,
			}),
		);
	};

/**
 * INDUSTRY END
 */

/**
 * HEADCOUNT START
 */

export const setCompanyHeadcountForMarketReportFormFilter =
	({
		position,
		headcountItem,
	}: {
		position: TPositionLogic;
		headcountItem: TCompanyHeadcountRangeItem;
	}) =>
	async (dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const state = getState();

		const headCountFilterKey = mRCompanyHeadcountFilterKeyByPosition(state, position);

		const idsOnState = mRCandidateCompanyHeadcountByPositionSelector(state, position);

		const index = idsOnState.indexOf(headcountItem.id);

		const alreadySelected = index !== -1;

		const nextIds = alreadySelected
			? [...idsOnState.slice(0, index), ...idsOnState.slice(index + 1)]
			: [...idsOnState, headcountItem.id];

		dispatch(
			setMarketReportMultipleFilterValueInForm({
				[headCountFilterKey]: nextIds,
			}),
		);
	};

export const moveHeadcountForMarketReportFormFilter =
	({from, to, ids}: {from: TPositionLogic; to: TPositionLogic; ids: string[]}) =>
	async (dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const idsSet = new Set(ids);

		const state = getState();

		const fromFilterKey = mRCompanyHeadcountFilterKeyByPosition(state, from);

		const toFilterKey = mRCompanyHeadcountFilterKeyByPosition(state, to);

		const fromIds = mRCandidateCompanyHeadcountByPositionSelector(state, from);

		const toIds = mRCandidateCompanyHeadcountByPositionSelector(state, to);

		dispatch(
			setMarketReportMultipleFilterValueInForm({
				[fromFilterKey]: fromIds.filter((id: string) => !idsSet.has(id)),
				[toFilterKey]: [
					...toIds.filter((id: string) => !idsSet.has(id)), // prevent duplication
					...fromIds.filter((id: string) => idsSet.has(id)),
				],
			}),
		);
	};

/**
 * HEADCOUNT END
 */

/**
 * REVENUE START
 */

export const setCompanyRevenueForMarketReportFormFilter =
	({position, revenueItem}: {position: TPositionLogic; revenueItem: TCompanyRevenueRangeItem}) =>
	async (dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const state = getState();

		const headCountFilterKey = mRCompanyRevenueFilterKeyByPosition(state, position);

		const idsOnState = mRCandidateCompanyRevenueByPositionSelector(state, position);

		const index = idsOnState.indexOf(revenueItem.id);

		const alreadySelected = index !== -1;

		const nextIds = alreadySelected
			? [...idsOnState.slice(0, index), ...idsOnState.slice(index + 1)]
			: [...idsOnState, revenueItem.id];

		dispatch(
			setMarketReportMultipleFilterValueInForm({
				[headCountFilterKey]: nextIds,
			}),
		);
	};

export const moveRevenueForMarketReportFormFilter =
	({from, to, ids}: {from: TPositionLogic; to: TPositionLogic; ids: string[]}) =>
	async (dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const idsSet = new Set(ids);

		const state = getState();

		const fromFilterKey = mRCompanyRevenueFilterKeyByPosition(state, from);

		const toFilterKey = mRCompanyRevenueFilterKeyByPosition(state, to);

		const fromIds = mRCandidateCompanyRevenueByPositionSelector(state, from);

		const toIds = mRCandidateCompanyRevenueByPositionSelector(state, to);

		dispatch(
			setMarketReportMultipleFilterValueInForm({
				[fromFilterKey]: fromIds.filter((id: string) => !idsSet.has(id)),
				[toFilterKey]: [
					...toIds.filter((id: string) => !idsSet.has(id)), // prevent duplication
					...fromIds.filter((id: string) => idsSet.has(id)),
				],
			}),
		);
	};

/**
 * REVENUE END
 */

/**
 * COMPANY TYPE START
 */

export const moveCompanyTypeForMarketReportFormFilter =
	({from, to, id}: {from: TCompanyTypePosition; to: TCompanyTypePosition; id: string}) =>
	async (dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const idsSet = new Set([id]);

		const state = getState();

		const fromFilterKey = mRCompanyTypeFilterKeyByPosition(state, from);

		const toFilterKey = mRCompanyTypeFilterKeyByPosition(state, to);

		const fromCompanyTypes = mRCandidateCompanyTypesByPositionSelector(state, from);

		const toCompanyType = mRCandidateCompanyTypesByPositionSelector(state, to);

		dispatch(
			setMarketReportMultipleFilterValueInForm({
				[fromFilterKey]: fromCompanyTypes.filter((id: string) => !idsSet.has(id)),
				[toFilterKey]: [
					...toCompanyType.filter((id: string) => !idsSet.has(id)), // prevent duplication
					...fromCompanyTypes.filter((id: string) => idsSet.has(id)),
				],
			}),
		);
	};

export const setCompanyTypeForMarketReportFormFilter =
	({position, id}: {position: TCompanyTypePosition; id: string}) =>
	async (dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const state = getState();

		const idsOnState = mRCandidateCompanyTypesByPositionSelector(state, position);

		const index = idsOnState.indexOf(id);

		const alreadySelected = index !== -1;

		const nextIds = alreadySelected
			? [...idsOnState.slice(0, index), ...idsOnState.slice(index + 1)]
			: [...idsOnState, id];

		const specialtyFilterKey = mRCompanyTypeFilterKeyByPosition(state, position);

		dispatch(setMarketReportMultipleFilterValueInForm({[specialtyFilterKey]: nextIds}));
	};

/**
 * COMPANY TYPE END
 */

/**
 * LOCATION START
 */

export const setLocationForMarketReportFormFilter =
	({
		position,
		nextCountryIds,
		toBeSetRegionIds,
	}: {
		position: TLocationPosition;
		nextCountryIds: number[];
		toBeSetRegionIds: number[];
	}) =>
	async (dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const state = getState();

		const locationFilterKey = mRLocationFilterKeyByPosition(state, position);
		const regionFilterKey = mRRegionFilterKeyByPosition(state, position);

		dispatch(
			setMarketReportMultipleFilterValueInForm({
				[locationFilterKey]: nextCountryIds,
				[regionFilterKey]: toBeSetRegionIds,
			}),
		);
	};

export const moveLocationsForMarketReportFormFilter = (params: {
	from: TLocationPosition;
	to: TLocationPosition;
	country_id: number;
	region_id: string;
}) =>
	handleMoveLocation(
		params,
		(position, state) => ({
			locationKey: mRLocationFilterKeyByPosition(state, position),
			regionKey: mRRegionFilterKeyByPosition(state, position),
		}),
		(position, state) => ({
			locations: mRCandidateCountryIdsByPositionSelector(state, position),
			regions: mRCandidateRegionIdsByPositionSelector(state, position),
		}),
		setMarketReportMultipleFilterValueInForm,
	);

/**
 * LOCATION END
 */

/**
 * KEYWORDS STARTS
 */
const updateSelectedKeywordsByKeywordPositionAfterSelection =
	(selectedTexts: string[], position: TPositionLogic) =>
	(dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const state = getState();

		const logic =
			state.marketReport.candidateKeywordFilterConfig[
				`active_${position}_position_connect_logic`
			];

		const keywordFilterKey = mRKeywordFilterKeyByPosition(state, position, logic);

		const keywordTextsBySelectedPosition: string[] =
			state.marketReport.marketReportForm.filters[keywordFilterKey];

		const updatedTexts = getArrayDifference(keywordTextsBySelectedPosition, selectedTexts);

		return {
			[keywordFilterKey]: updatedTexts,
		};
	};

export const mRSetKeywordForCandidate =
	({position, logic, text}: {position: TPositionLogic; logic: TConnectorLogic; text: string}) =>
	(dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const state = getState();

		const keywordFilterKey = mRKeywordFilterKeyByPosition(state, position, logic);

		const keywords: string[] = state.marketReport.marketReportForm.filters[keywordFilterKey];

		const index = keywords.indexOf(text);

		const isSelectedAlready = index !== -1;

		const nextKeywords = isSelectedAlready
			? [...keywords.slice(0, index), ...keywords.slice(index + 1)]
			: [...keywords, text];

		let payload = {
			[keywordFilterKey]: nextKeywords,
		};

		const positionOptions: TKeywordPosition[] = ["current", "previous", "any"];

		const filteredPositionOptions = positionOptions.filter((option) => option !== position);

		for (const filteredPosition of filteredPositionOptions) {
			const filteredPositionUpdatedPayload = dispatch(
				updateSelectedKeywordsByKeywordPositionAfterSelection(
					nextKeywords,
					filteredPosition,
				),
			);

			payload = {...payload, ...filteredPositionUpdatedPayload};
		}

		dispatch(setMarketReportMultipleFilterValueInForm(payload));
	};

export const mRSwitchKeywordConnectorForCandidatePosition =
	({
		position,
		fromLogic,
		toLogic,
	}: {
		position: TPositionLogic;
		fromLogic: TConnectorLogic;
		toLogic: TConnectorLogic;
	}) =>
	(dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const state = getState();

		const fromFilterKey = mRKeywordFilterKeyByPosition(state, position, fromLogic);

		const toFilterKey = mRKeywordFilterKeyByPosition(state, position, toLogic);

		const keywords: string[] = state.marketReport.marketReportForm.filters[fromFilterKey];

		dispatch(
			setMarketReportMultipleFilterValueInForm({
				[fromFilterKey]: [],
				[toFilterKey]: keywords,
			}),
		);
	};

export const mRMoveKeywordForCandidate =
	({
		from,
		to,
		fromLogic,
		toLogic,
		text,
	}: {
		from: TPositionLogic;
		to: TPositionLogic;
		fromLogic: TConnectorLogic;
		toLogic: TConnectorLogic;
		text: string;
	}) =>
	(dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const state = getState();

		const fromFilterKey = mRKeywordFilterKeyByPosition(state, from, fromLogic);

		const toFilterKey = mRKeywordFilterKeyByPosition(state, to, toLogic);

		const fromKeywords: string[] = state.marketReport.marketReportForm.filters[fromFilterKey];

		const toKeywords: string[] = state.marketReport.marketReportForm.filters[toFilterKey];

		const nextFromKeywords = [...fromKeywords].filter((i) => i !== text);

		const nextToKeywords = [text, ...toKeywords];

		dispatch(
			setMarketReportMultipleFilterValueInForm({
				[fromFilterKey]: nextFromKeywords,
				[toFilterKey]: nextToKeywords,
			}),
		);
	};

export const mRClearKeywordsByPositionForCandidate =
	(position: TPositionLogic) => (dispatch: Dispatch<TAppDispatch>) => {
		let payload;

		switch (position) {
			case "current":
				payload = {
					current_text_keywords_and: [],
					current_text_keywords_or: [],
					current_text_keywords_not: [],
				};
				break;
			case "previous":
				payload = {
					previous_text_keywords_and: [],
					previous_text_keywords_or: [],
					previous_text_keywords_not: [],
				};
				break;
			default:
				payload = {
					text_keywords_and: [],
					text_keywords_or: [],
					text_keywords_not: [],
				};
		}

		dispatch(setMarketReportMultipleFilterValueInForm(payload));
	};

/**
 * KEYWORDS END
 */

/**
 * DEAL EXPERIENCE START
 */
export const setCompanyEventSizeFilterForMarketReportForm =
	({eventSizeItem}: {eventSizeItem: TCandidateCompanyEventSizeItem}) =>
	async (dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const state = getState();

		const idsOnState = mRCandidateCompanyEventSizeSelector(state);

		const index = idsOnState.indexOf(eventSizeItem.id);

		const alreadySelected = index !== -1;

		const nextIds = alreadySelected
			? [...idsOnState.slice(0, index), ...idsOnState.slice(index + 1)]
			: [...idsOnState, eventSizeItem.id];

		dispatch(
			setMarketReportMultipleFilterValueInForm({
				company_events: {
					...state.marketReport.marketReportForm.filters.company_events,
					size: nextIds,
				},
			}),
		);
	};

export const setCompanyEventTypesFilterForMarketReportForm =
	({eventItem}: {eventItem: TCandidateCompanyEventTypeItem}) =>
	async (dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const state = getState();

		const valuesOnState = mRCandidateCompanyEventTypesSelector(state);

		const index = valuesOnState.indexOf(eventItem.name);

		const alreadySelected = index !== -1;

		const nextValues = alreadySelected
			? [...valuesOnState.slice(0, index), ...valuesOnState.slice(index + 1)]
			: [...valuesOnState, eventItem.name];

		dispatch(
			setMarketReportMultipleFilterValueInForm({
				company_events: {
					...state.marketReport.marketReportForm.filters.company_events,
					types: nextValues,
				},
			}),
		);
	};

/**
 * DEAL EXPERIENCE END
 */

export const setLanguagesForMRCandidates =
	({name}: {name: string}) =>
	async (dispatch: TAppDispatch, getState: TRootState) => {
		const state = getState();

		const languages = mRCandidateLanguageSelector(state);

		const index = languages.indexOf(name);
		const alreadySelected = index !== -1;

		const nextItems = alreadySelected
			? [...languages.slice(0, index), ...languages.slice(index + 1)]
			: [...languages, name];

		dispatch(setMarketReportMultipleFilterValueInForm({languages: nextItems}));
	};
