import {
	BULK_PROJECT_CANDIDATE_STATUS_CHANGE,
	FETCHING_PROJECT_CANDIDATE_TRACK_LEAVERS,
	FETCHING_PROJECT_CANDIDATE_TRACK_NO_RECENT_CHANGES,
	FETCHING_PROJECT_CANDIDATE_TRACK_PROMOTIONS,
	GET_INDUSTRIES_REPORT_FETCH,
	GET_INDUSTRIES_REPORT_SUCCESS,
	GET_INDUSTRY_BACKGROUND_REPORT_DONE,
	GET_INDUSTRY_BACKGROUND_REPORT_FAILED,
	GET_INDUSTRY_BACKGROUND_REPORT_INIT,
	GET_PROJECT_CANDIDATE_IDS_DONE,
	GET_PROJECT_CANDIDATE_IDS_FAIL,
	GET_PROJECT_CANDIDATE_IDS_INIT,
	GET_PROJECT_CANDIDATE_STATUSES_DONE,
	GET_PROJECT_CANDIDATE_STATUSES_FAILED,
	GET_PROJECT_CANDIDATE_STATUSES_INIT,
	GET_PROJECT_COMPANY_IDS_DONE,
	GET_PROJECT_COMPANY_IDS_FAIL,
	GET_PROJECT_COMPANY_IDS_INIT,
	GET_PROJECT_PROGRESS_STATUSES_DONE,
	GET_PROJECT_PROGRESS_STATUSES_FAILED,
	GET_PROJECT_PROGRESS_STATUSES_INIT,
	GET_PROJECT_SAVED_COMPANIES_DONE,
	GET_PROJECT_SAVED_COMPANIES_FAIL,
	GET_PROJECT_SAVED_COMPANIES_INIT,
	PROJECT_SAVED_PEOPLE_FETCH_COMPLETED,
	PROJECT_SAVED_PEOPLE_FETCH_IN_PROGRESS,
	SET_ACTIVE_PROJECT_REPORT_VIEW_ON_CANDIDATE_CARD,
	SET_AP_CANDIDATES_WITH_RECOMMENDED_CANDIDATES,
	SET_AP_CANDIDATES_WITH_UPDATED_SUMMARY,
	SET_CANDIDATE_IDS_FOR_ASSESSMENT,
	SET_COUNTRY_REPORT,
	SET_CRITERIA_FOR_PROJECT_CANDIDATE_ASSESSMENT,
	SET_ETHNIC_DIVERSITY_DATA,
	SET_EXPANDED_CANDIDATE_IDS_FOR_PROJECT,
	SET_EXPANDED_CRITERIONS_FOR_PROJECT,
	SET_GENDER_DIVERSITY_DATA,
	SET_GENERATE_CONTACT_DETAILS_CANDIDATE_IDS_FOR_ALL_PROJECT,
	SET_GENERATE_SUMMARY_CANDIDATE_IDS_FOR_CURRENT_PROJECT,
	SET_INDUSTRIES_REPORT_FAILED,
	SET_JOB_FUNCTION_DISTRIBUTION_DATA,
	SET_JOB_FUNCTION_DISTRIBUTION_INIT,
	SET_ONBOARDING_STEP,
	SET_ONBOARDING_VISIBILITY,
	SET_PRESENTATION_AVAILABLE_TEMPLATES,
	SET_PROJECT_CANDIDATE_STATUSES_FINISHED,
	SET_PROJECT_CANDIDATE_TRACK_LEAVERS,
	SET_PROJECT_CANDIDATE_TRACK_NO_RECENT_CHANGES,
	SET_PROJECT_CANDIDATE_TRACK_PROMOTIONS,
	SET_PROJECT_CANDIDATES_SELECTION_IN_PROGRESS,
	SET_PROJECT_CHECKLIST_FULL_DISPLAY,
	SET_PROJECT_FREQUENTLY_HIRED_FROM_DATA,
	SET_PROJECT_PROGRESS_STATUSES_FINISHED,
	SET_PROJECT_REPORT_TABLE_ROWS,
	SET_PROJECT_SAVED_COMPANIES_PAGINATION_INFO,
	SET_PROJECT_SAVED_PEOPLE_PAGINATION_INFO,
	SET_PROJECT_STATS,
	SET_PROJECT_STATS_FAILED,
	SET_PROJECT_STATS_INIT,
	SET_SELECTED_PRESENTATION_TEMPLATE,
	SET_SELECTED_TRACK_CANDIDATE_IDS,
	SET_SELECTED_TRACK_CANDIDATE_POSITION_CHANGED_DAYS,
	UPDATE_AP_CANDIDATE_BY_KEY_IN_LIST,
	UPDATE_PROJECT_SAVED_PEOPLE_WITH_SUMMARY,
	UPDATE_PROJECT_SUCCESS_CHECKLIST,
} from "store/mapx/mapXActionTypes";

export function setGenderDiversityData(payload) {
	return {type: SET_GENDER_DIVERSITY_DATA, payload};
}

export function getIndustriesReportFetch() {
	return {type: GET_INDUSTRIES_REPORT_FETCH};
}

export function getIndustriesReportFailed() {
	return {type: SET_INDUSTRIES_REPORT_FAILED};
}

export function setCountryReport(payload) {
	return {type: SET_COUNTRY_REPORT, payload};
}

export function getIndustriesReportSuccess(payload) {
	return {type: GET_INDUSTRIES_REPORT_SUCCESS, payload};
}

export function setEthnicDiversityData(payload) {
	return {type: SET_ETHNIC_DIVERSITY_DATA, payload};
}

export function setJobFunctionDistributionData(payload) {
	return {type: SET_JOB_FUNCTION_DISTRIBUTION_DATA, payload};
}

export function setJobFunctionDistributionInit() {
	return {type: SET_JOB_FUNCTION_DISTRIBUTION_INIT};
}

export function setProjectStats(payload) {
	return {type: SET_PROJECT_STATS, payload};
}

export function setProjectCandidatesSelectionInProgress(payload) {
	return {type: SET_PROJECT_CANDIDATES_SELECTION_IN_PROGRESS, payload};
}

export function getProjectStatsInit() {
	return {type: SET_PROJECT_STATS_INIT};
}

export function getProjectStatsFailed() {
	return {type: SET_PROJECT_STATS_FAILED};
}

export function setProjectFrequentlyHiredFromData(payload) {
	return {type: SET_PROJECT_FREQUENTLY_HIRED_FROM_DATA, payload};
}

export function setPresentationAvailableTemplates(payload) {
	return {type: SET_PRESENTATION_AVAILABLE_TEMPLATES, payload};
}

export function setSelectedPresentationTemplate(payload) {
	return {type: SET_SELECTED_PRESENTATION_TEMPLATE, payload};
}

//industries background report
export function getIndustriesBackgroundReportInit() {
	return {type: GET_INDUSTRY_BACKGROUND_REPORT_INIT};
}

export function getIndustriesBackgroundReportDone(payload) {
	return {type: GET_INDUSTRY_BACKGROUND_REPORT_DONE, payload};
}

export function getIndustriesBackgroundReportFailed() {
	return {type: GET_INDUSTRY_BACKGROUND_REPORT_FAILED};
}

export function setProjectChecklistFullDisplay(payload) {
	return {type: SET_PROJECT_CHECKLIST_FULL_DISPLAY, payload};
}

export function setCandidateIdsForAssessment(payload) {
	return {type: SET_CANDIDATE_IDS_FOR_ASSESSMENT, payload};
}

export function setCriteriaForProjectCandidateAssessment(payload) {
	return {type: SET_CRITERIA_FOR_PROJECT_CANDIDATE_ASSESSMENT, payload};
}

export function setExpandedCriterionsForProject(payload) {
	return {type: SET_EXPANDED_CRITERIONS_FOR_PROJECT, payload};
}

export function setExpandedCandidateIDsForProject(payload) {
	return {type: SET_EXPANDED_CANDIDATE_IDS_FOR_PROJECT, payload};
}

export function setActiveProjectReportViewOnCandidateCard(payload) {
	return {type: SET_ACTIVE_PROJECT_REPORT_VIEW_ON_CANDIDATE_CARD, payload};
}

export function updateProjectSuccessChecklist(payload) {
	return {type: UPDATE_PROJECT_SUCCESS_CHECKLIST, payload};
}

export function setProjectReportTableRows(payload) {
	return {type: SET_PROJECT_REPORT_TABLE_ROWS, payload};
}

// Onboarding
export function setOnboardingVisibility(payload) {
	return {type: SET_ONBOARDING_VISIBILITY, payload};
}

export function setOnboardingStep(payload) {
	return {type: SET_ONBOARDING_STEP, payload};
}

// CANDIDATE SUMMARIES

export function setGenerateSummaryCandidateIdsForCurrentProject(payload) {
	return {type: SET_GENERATE_SUMMARY_CANDIDATE_IDS_FOR_CURRENT_PROJECT, payload};
}

// CANDIDATE CONTACT DETAILS

export function setGenerateContactDetailsCandidateIdsForAllProject(payload) {
	return {type: SET_GENERATE_CONTACT_DETAILS_CANDIDATE_IDS_FOR_ALL_PROJECT, payload};
}

// PROJECT PROGRESS STATUSES

export function getProjectProgressStatusesInit() {
	return {type: GET_PROJECT_PROGRESS_STATUSES_INIT};
}

export function getProjectProgressStatusesFailed() {
	return {type: GET_PROJECT_PROGRESS_STATUSES_FAILED};
}

export function setProjectProgressStatusesFinished() {
	return {type: SET_PROJECT_PROGRESS_STATUSES_FINISHED};
}

export function setProjectProgressStatuses(payload) {
	return {type: GET_PROJECT_PROGRESS_STATUSES_DONE, payload};
}

export function getProjectCandidateStatusesInit() {
	return {type: GET_PROJECT_CANDIDATE_STATUSES_INIT};
}

export function getProjectCandidateStatusesFailed() {
	return {type: GET_PROJECT_CANDIDATE_STATUSES_FAILED};
}

export function setProjectCandidateStatusesFinished() {
	return {type: SET_PROJECT_CANDIDATE_STATUSES_FINISHED};
}

export function setProjectCandidateStatuses(payload) {
	return {type: GET_PROJECT_CANDIDATE_STATUSES_DONE, payload};
}

export function bulkProjectCandidateStatusChange(payload) {
	return {type: BULK_PROJECT_CANDIDATE_STATUS_CHANGE, payload};
}

export function updateProjectCandidateByKeyInTheList(payload) {
	return {type: UPDATE_AP_CANDIDATE_BY_KEY_IN_LIST, payload};
}

export function projectSavedPeopleFetchCompleted(payload) {
	return {type: PROJECT_SAVED_PEOPLE_FETCH_COMPLETED, payload};
}

export function setProjectSavedPeopleFetchIsInProgress(payload) {
	return {type: PROJECT_SAVED_PEOPLE_FETCH_IN_PROGRESS, payload};
}

export function setProjectSavedPeoplePaginationInfo(payload) {
	return {type: SET_PROJECT_SAVED_PEOPLE_PAGINATION_INFO, payload};
}

export function projectSavedCompaniesInit() {
	return {type: GET_PROJECT_SAVED_COMPANIES_INIT};
}

export function getProjectSavedCompaniesDone(data) {
	return {type: GET_PROJECT_SAVED_COMPANIES_DONE, payload: data};
}

export function getProjectSavedCompaniesFail(error) {
	return {type: GET_PROJECT_SAVED_COMPANIES_FAIL, payload: error};
}

export function setProjectSavedCompaniesPagination(error) {
	return {type: SET_PROJECT_SAVED_COMPANIES_PAGINATION_INFO, payload: error};
}

export function updateProjectSavedPeopleWithSummary(payload) {
	return {type: UPDATE_PROJECT_SAVED_PEOPLE_WITH_SUMMARY, payload};
}

export function setAPCandidatesWithUpdatedRecommendedCandidates(payload) {
	return {type: SET_AP_CANDIDATES_WITH_RECOMMENDED_CANDIDATES, payload};
}

export function setAPCandidatesWithUpdatedSummary(payload) {
	return {type: SET_AP_CANDIDATES_WITH_UPDATED_SUMMARY, payload};
}

export function getProjectCompanyIDsInit() {
	return {type: GET_PROJECT_COMPANY_IDS_INIT};
}

export function getProjectCompanyIDsDone(payload) {
	return {type: GET_PROJECT_COMPANY_IDS_DONE, payload};
}

export function getProjectCompanyIDsFail(error) {
	return {type: GET_PROJECT_COMPANY_IDS_FAIL, payload: error};
}

export function getProjectCandidateIDsInit() {
	return {type: GET_PROJECT_CANDIDATE_IDS_INIT};
}

export function getProjectCandidateIDsDone(payload) {
	return {type: GET_PROJECT_CANDIDATE_IDS_DONE, payload};
}

export function getProjectCandidateIDsFail(error) {
	return {type: GET_PROJECT_CANDIDATE_IDS_FAIL, payload: error};
}

//TRACKING CANDIDATES
export function setSelectedTrackCandidateIds(payload) {
	return {type: SET_SELECTED_TRACK_CANDIDATE_IDS, payload};
}
export function setSelectedTrackCandidatePositionChangedDays(payload) {
	return {type: SET_SELECTED_TRACK_CANDIDATE_POSITION_CHANGED_DAYS, payload};
}
export function fetchingProjectCandidateTrackLeavers(payload) {
	return {type: FETCHING_PROJECT_CANDIDATE_TRACK_LEAVERS, payload};
}
export function setProjectCandidateTrackLeavers(payload) {
	return {type: SET_PROJECT_CANDIDATE_TRACK_LEAVERS, payload};
}
export function fetchingProjectCandidateTrackPromotions(payload) {
	return {type: FETCHING_PROJECT_CANDIDATE_TRACK_PROMOTIONS, payload};
}
export function setProjectCandidateTrackPromotions(payload) {
	return {type: SET_PROJECT_CANDIDATE_TRACK_PROMOTIONS, payload};
}
export function fetchingProjectCandidateTrackNoRecentChanges(payload) {
	return {type: FETCHING_PROJECT_CANDIDATE_TRACK_NO_RECENT_CHANGES, payload};
}
export function setProjectCandidateTrackNoRecentChanges(payload) {
	return {type: SET_PROJECT_CANDIDATE_TRACK_NO_RECENT_CHANGES, payload};
}
