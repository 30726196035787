import {TPaginationParam} from "api/candidateApi/types";
import {
	CLEAR_MARKET_REPORT_DETAILS,
	CREATE_MARKET_REPORT_DONE,
	CREATE_MARKET_REPORT_FAIL,
	CREATE_MARKET_REPORT_INIT,
	DELETE_MARKET_REPORT_DONE,
	DELETE_MARKET_REPORT_FAIL,
	DELETE_MARKET_REPORT_INIT,
	DISPLAY_DIVERSITY_PATHWAYS_LOADING,
	DISPLAY_DIVERSITY_STATS_LOADING,
	DISPLAY_MARKET_REPORT_MODAL,
	GET_MARKET_REPORTS_DONE,
	GET_MARKET_REPORTS_FAIL,
	GET_MARKET_REPORTS_INIT,
	RESET_MARKET_REPORT_FORM,
	RESET_MARKET_REPORT_FORM_DATA,
	SET_DIVERSITY_PATHWAYS_MODE,
	SET_DIVERSITY_PATHWAYS_REPORT,
	SET_DIVERSITY_PATHWAYS_SELECTED_TYPE,
	SET_DIVERSITY_STATS_REPORT,
	SET_DIVERSITY_STATS_SELECTED_TYPE,
	SET_MARKET_REPORT_DETAILS,
	SET_MARKET_REPORT_FILTER_VALUE_IN_FORM,
	SET_MARKET_REPORT_FORM_DATA,
	SET_MARKET_REPORT_MULTIPLE_FILTER_VALUE_IN_FORM,
	SET_MARKET_REPORT_NAME_IN_FORM,
	SET_MARKET_REPORTS_PAGINATION_INFO,
	SET_MR_COMPANIES_MODE,
	SET_MR_COMPANY_FILTER_CONFIG,
	SET_MR_COMPANY_TYPE_FILTER_CONFIG,
	SET_MR_DISABLED_FILTER_KEYS,
	SET_MR_HEADCOUNT_FILTER_CONFIG,
	SET_MR_INDUSTRIES_FILTER_CONFIG,
	SET_MR_JOB_FUNCTION_FILTER_CONFIG,
	SET_MR_JOB_TITLE_MODE,
	SET_MR_KEYWORD_FILTER_CONFIG,
	SET_MR_LOCATIONS_FILTER_CONFIG,
	SET_MR_REVENUE_FILTER_CONFIG,
	UPDATE_MARKET_REPORT_DONE,
	UPDATE_MARKET_REPORT_FAIL,
	UPDATE_MARKET_REPORT_INIT,
} from "store/mapx/mapXActionTypes";
import {
	IMarketReport,
	STDiversePathwaysMode,
	STDiversePathwaysReport,
	STDiversityStats,
	TCandidateFilterOptions,
	TUpdateMarketReportForm,
} from "api/marketReportApi/types";
import {
	TDiversityPathwaysSelectedType,
	TDiversityStatsSelectedType,
	TFilterPositionConfig,
	TFilterPositionConfigWithConnector,
	TJobTitleFilterMode,
} from "store/mapx/market-report/types";
import {STCompany} from "api/companyApi/types";
import {ATPagination} from "api/types";

export function getMarketReportsInit() {
	return {type: GET_MARKET_REPORTS_INIT};
}

export function setMarketReportPaginationInfo(data: TPaginationParam) {
	return {type: SET_MARKET_REPORTS_PAGINATION_INFO, payload: data};
}

export function getMarketReportsDone(
	data: {results: IMarketReport[]; pagination: ATPagination},
	name: string,
	createdBy: ("self" | "organisation" | "mapx")[],
) {
	return {type: GET_MARKET_REPORTS_DONE, payload: {data, name, createdBy}};
}

export function getMarketReportsFail(error: unknown) {
	return {type: GET_MARKET_REPORTS_FAIL, payload: error};
}

export function createMarketReportInit() {
	return {type: CREATE_MARKET_REPORT_INIT};
}

export function createMarketReportDone(data: IMarketReport) {
	return {type: CREATE_MARKET_REPORT_DONE, payload: data};
}

export function createMarketReportFail(error: unknown) {
	return {type: CREATE_MARKET_REPORT_FAIL, payload: error};
}

export function setMarketReportDetails(payload: IMarketReport) {
	return {type: SET_MARKET_REPORT_DETAILS, payload};
}

export function clearMarketReportDetails() {
	return {type: CLEAR_MARKET_REPORT_DETAILS};
}

export function updateMarketReportInit() {
	return {type: UPDATE_MARKET_REPORT_INIT};
}

export function updateMarketReportDone(data: unknown) {
	return {type: UPDATE_MARKET_REPORT_DONE, payload: data};
}

export function updateMarketReportFail(error: unknown) {
	return {type: UPDATE_MARKET_REPORT_FAIL, payload: error};
}

export function deleteMarketReportInit(id: number) {
	return {type: DELETE_MARKET_REPORT_INIT, payload: id};
}

export function deleteMarketReportDone(id: number) {
	return {type: DELETE_MARKET_REPORT_DONE, payload: id};
}

export function deleteMarketReportFail(id: number, error: unknown) {
	return {type: DELETE_MARKET_REPORT_FAIL, payload: {id, error}};
}

export function displayMarketReportModal(payload: boolean) {
	return {type: DISPLAY_MARKET_REPORT_MODAL, payload};
}

export function resetMarketReportForm() {
	return {type: RESET_MARKET_REPORT_FORM};
}

export function setMarketReportNameInForm(payload: string) {
	return {type: SET_MARKET_REPORT_NAME_IN_FORM, payload};
}

export function setMarketReportFilterValueInForm<K extends keyof TCandidateFilterOptions>(payload: {
	key: K;
	value: TCandidateFilterOptions[K];
}) {
	return {type: SET_MARKET_REPORT_FILTER_VALUE_IN_FORM, payload};
}

export function setMarketReportMultipleFilterValueInForm(
	payload: Partial<TCandidateFilterOptions>,
) {
	return {type: SET_MARKET_REPORT_MULTIPLE_FILTER_VALUE_IN_FORM, payload};
}

export function setMarketReportFormData(payload: TUpdateMarketReportForm) {
	return {type: SET_MARKET_REPORT_FORM_DATA, payload};
}

export function resetMarketReportFormData() {
	return {type: RESET_MARKET_REPORT_FORM_DATA};
}

export function setMRJobFunctionFilterConfig(payload: TFilterPositionConfigWithConnector) {
	return {type: SET_MR_JOB_FUNCTION_FILTER_CONFIG, payload};
}

export function setMRIndustriesFilterConfig(payload: TFilterPositionConfig) {
	return {type: SET_MR_INDUSTRIES_FILTER_CONFIG, payload};
}

export function setMRHeadcountFilterConfig(payload: TFilterPositionConfig) {
	return {type: SET_MR_HEADCOUNT_FILTER_CONFIG, payload};
}

export function setMRRevenueFilterConfig(payload: TFilterPositionConfig) {
	return {type: SET_MR_REVENUE_FILTER_CONFIG, payload};
}
export function setMRCompanyTypeFilterConfig(payload: TFilterPositionConfig) {
	return {type: SET_MR_COMPANY_TYPE_FILTER_CONFIG, payload};
}
export function setMRLocationsFilterConfig(payload: TFilterPositionConfig) {
	return {type: SET_MR_LOCATIONS_FILTER_CONFIG, payload};
}
export function setMRKeywordFilterConfig(payload: TFilterPositionConfigWithConnector) {
	return {type: SET_MR_KEYWORD_FILTER_CONFIG, payload};
}
export function setMRCompanyFilterConfig(payload: TFilterPositionConfig) {
	return {type: SET_MR_COMPANY_FILTER_CONFIG, payload};
}

export function setMRJobTitleMode(payload: TJobTitleFilterMode) {
	return {type: SET_MR_JOB_TITLE_MODE, payload};
}
export function setMRSelectedCompanies(payload: STCompany[]) {
	return {type: SET_MR_COMPANIES_MODE, payload};
}

export function displayDiversityStatsLoading(payload: boolean) {
	return {type: DISPLAY_DIVERSITY_STATS_LOADING, payload};
}
export function setDiversityStatsSelectedType(payload: TDiversityStatsSelectedType) {
	return {type: SET_DIVERSITY_STATS_SELECTED_TYPE, payload};
}
export function setDiversityStatsReport(payload: STDiversityStats) {
	return {type: SET_DIVERSITY_STATS_REPORT, payload};
}
export function displayDiversityPathwaysLoading(payload: boolean) {
	return {type: DISPLAY_DIVERSITY_PATHWAYS_LOADING, payload};
}
export function setDiversityPathwaysSelectedType(payload: TDiversityPathwaysSelectedType) {
	return {type: SET_DIVERSITY_PATHWAYS_SELECTED_TYPE, payload};
}
export function setDiversityPathwaysMode(payload: STDiversePathwaysMode) {
	return {type: SET_DIVERSITY_PATHWAYS_MODE, payload};
}
export function setDiversityPathwaysReport(payload: STDiversePathwaysReport) {
	return {type: SET_DIVERSITY_PATHWAYS_REPORT, payload};
}

export function setMRDisabledFilterKeys(payload: (keyof TCandidateFilterOptions)[]) {
	return {type: SET_MR_DISABLED_FILTER_KEYS, payload};
}
