import {FC} from "react";
import {LeftArrow} from "assets/icons";
import classNames from "classnames";

import {colors} from "./utils";
import type {TListItemProps} from "./types";
import styles from "./PieChart.module.scss";

const ListItem: FC<TListItemProps> = ({filterChartItems, changeActive, activeColor}) => {
	const renderSpecialism = filterChartItems?.children
		?.filter((el) => !!el?.value)
		.map((el) => {
			const itemName = el.name;

			return (
				<div className={styles.container__wrapper__list_specialisms_child_item} key={el.id}>
					<p className={styles.container__wrapper__list_specialisms_child_item_name}>
						{itemName}
					</p>
					<p className={styles.container__wrapper__list_specialisms_child_item_percent}>
						{el.value}%
					</p>
				</div>
			);
		});

	return (
		<div className={styles.container__wrapper__list_specialisms}>
			<div className={styles.container__wrapper__list_specialisms_item}>
				<div
					role="button"
					onClick={() => changeActive(null)}
					className={styles.container__wrapper__list_specialisms_item_content}
				>
					<LeftArrow
						fill="#2C3030"
						className={styles.container__wrapper__list_specialisms_item_arrow}
					/>
					<div
						style={{backgroundColor: colors[Number(activeColor)]}}
						className={styles.container__wrapper__list_item_left_dot}
					/>
					<p className={styles.container__wrapper__list_specialisms_item_name}>
						{filterChartItems?.name}
					</p>
				</div>
				<div className={styles.container__wrapper__list_specialisms_item_percent}>
					<p>{filterChartItems?.value}%</p>
				</div>
			</div>

			<div
				className={classNames(styles.container__wrapper__list_specialisms_child, {
					[styles.container__wrapper__list_specialisms_childOther]:
						filterChartItems?.id === -1,
				})}
			>
				{renderSpecialism}
			</div>
		</div>
	);
};

export default ListItem;
