import type {TGetExpandableDataResult} from "types";
import {
	STJobFunction,
	STSpecialism,
	TSpecialismTag,
} from "api/filterOptionsApi/JobFunctionApi/types";

export const getFunctionExpandableData = (filteredData?: STJobFunction[] | null) =>
	filteredData?.reduce<TGetExpandableDataResult>((acc, curVal) => {
		acc[curVal.id] = true;

		return acc;
	}, {});

export const getSpecialismsTagByIds = (specialisms: number[], options: STJobFunction[]) => {
	const data: TSpecialismTag[] = [];

	const sSet: Set<number> = new Set(specialisms);

	options.forEach((o: STJobFunction) => {
		o.specialisms.forEach((s: STSpecialism) => {
			if (sSet.has(s.id)) {
				data.push({
					id: s.id,
					name: s.name,
					type: "specialism",
					jobFunctionId: o.id,
					jobFunctionName: o.name,
				});
			}
		});
	});

	return data;
};
