import {FC, useState} from "react";
import classNames from "classnames";
import {Cell, Pie, PieChart as PieChartComponent, ResponsiveContainer} from "recharts";

import {colors} from "./utils";
import ListItem from "./ListItem";
import ChartList from "./ChartList";
import EmptyOrLoadingChart from "./EmptyOrLoadingChart";
import type {IPieChartDataProps, IPieChartProps} from "./types";
import styles from "./PieChart.module.scss";
import CustomLabel from "components/Charts/PieChart/CustomLabel";

const PieChart: FC<IPieChartProps<IPieChartDataProps>> = ({
	isLoading,
	data,
	pieChartClass = "",
	innerRadius = 26,
}) => {
	const [isActiveId, setIsActiveId] = useState<number | null>(null);
	const [activeColor, setActiveColor] = useState<number | null>(null);

	const dataLength = data.length;

	const changeActive = (id: number | null, idx?: number | null) => {
		setIsActiveId(id);
		setActiveColor(idx ?? 0);
	};

	const filterChartItems = data.find((item) => item.id === isActiveId);

	const renderPieItems = data.map((item: IPieChartDataProps, index: number) => {
		const renderOpacity = item.id === isActiveId ? 1 : 0.5;

		return (
			<Cell
				width={200}
				radius={30}
				height={200}
				fill={colors[index]}
				key={`cell-${index}`}
				style={{opacity: isActiveId ? renderOpacity : 1}}
			/>
		);
	});

	const renderContent =
		!isLoading && dataLength ? (
			<div className={classNames(styles.container__wrapper, pieChartClass)}>
				{!!dataLength && (
					<div className={styles.container__wrapper__chart}>
						<ResponsiveContainer
							width="100%"
							height="100%"
							className={styles.container__wrapper__chart__cont}
						>
							<PieChartComponent>
								<Pie
									cx="50%"
									cy="50%"
									data={data}
									stroke="none"
									dataKey="value"
									innerRadius={innerRadius}
									outerRadius={80}
									labelLine={false}
									paddingAngle={1}
									label={CustomLabel}
									isAnimationActive={false}
								>
									{renderPieItems}
								</Pie>
							</PieChartComponent>
						</ResponsiveContainer>
					</div>
				)}

				<div className={styles.container__wrapper__list}>
					{!isActiveId ? (
						<ChartList data={data} changeActive={changeActive} />
					) : (
						<ListItem
							activeColor={activeColor}
							changeActive={changeActive}
							filterChartItems={filterChartItems}
						/>
					)}
				</div>
			</div>
		) : (
			<EmptyOrLoadingChart isLoading={isLoading} dataLength={dataLength} />
		);

	return <div data-testid="pie-chart-skeleton">{renderContent}</div>;
};

export default PieChart;
