import {confirmAlert} from "react-confirm-alert";
import classNames from "classnames";

import "styles/custom/_confrmAlert.scss";

import type {TCustomConfirmAlertParams} from "./types";
import ConfirmAlertIcon from "../assets/icons/ConfirmAlertIcon";

const customConfirmAlert = ({
	handlePressYes,
	title = "Are you sure?",
	message = "You want to delete this file?",
	yesButtonLabel = "Yes",
	noButtonLabel = "No",
	afterClose,
	actionButtonPrefixIcon = null,
	customIcon,
	alertType = "success",
	onlyYesButton = false,
}: TCustomConfirmAlertParams): void => {
	const alertHeaderIconColor = () => {
		if (alertType === "success") {
			return "#0C5850";
		} else if (alertType === "error") {
			return "#C91515";
		} else {
			return "#FFC34C";
		}
	};

	const buttonTypeClass = alertType === "error" ? "btn-red" : "btn-green";

	return confirmAlert({
		title,
		message,
		customUI: ({onClose}) => {
			return (
				<div className="react-confirm-alert-body">
					{customIcon ?? <ConfirmAlertIcon color-={alertHeaderIconColor()} />}
					<h1>{title}</h1>
					<p>{message}</p>

					<div className="react-confirm-alert-button-group">
						{!onlyYesButton && (
							<button
								onClick={() => {
									onClose();

									if (afterClose) {
										afterClose();
									}
								}}
								className="btn btn-white"
							>
								{noButtonLabel}
							</button>
						)}
						<button
							className={classNames("btn", buttonTypeClass)}
							onClick={() => {
								handlePressYes();
								onClose();
							}}
						>
							{actionButtonPrefixIcon !== null && actionButtonPrefixIcon}{" "}
							{yesButtonLabel}
						</button>
					</div>
				</div>
			);
		},
	});
};

export default customConfirmAlert;
