const ArrowRightIcon = () => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<mask
				id="mask0_2383_57045"
				style={{maskType: "alpha"}}
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="20"
				height="20"
			>
				<rect width="20" height="20" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_2383_57045)">
				<path
					d="M12 14L10.9375 12.9375L13.125 10.75H4V9.25H13.125L10.9375 7.0625L12 6L16 10L12 14Z"
					fill="#0C5850"
				/>
			</g>
		</svg>
	);
};

export default ArrowRightIcon;
