import {useCallback, useEffect, useMemo} from "react";

import styles from "./styles.module.scss";
import ProjectCandidatesFilter from "mapx-pages/Project/ProjectCandidatesFilter";
import {useSelector} from "react-redux";
import {
	additionalProfileFiltersSelector,
	apCandidateOrderingSelector,
} from "store/mapx/additional-profiles/additionalProfilesSelectors";
import {buildCandidateApiRequestPayload} from "helpers/filterHandlers";
import {useAppDispatch} from "hooks";
import classNames from "classnames";
import AddPeople from "mapx-pages/Project/SavedPeople/FilterSection/AddPeople/index";
import {Dropdown} from "components";
import {sortOption} from "mapx-pages/Project/SavedPeople/FilterSection/type";
import {ArrowDown} from "assets/icons";
import {setAPFilter} from "store/mapx/additional-profiles/additionalProfilesActions";
import {toggleAllTargetCompaniesOnAPCandidateFilter} from "store/mapx/additional-profiles/additionalProfilesAsyncActions";
import FitToBrief from "mapx-pages/Project/SavedPeople/FilterSection/FitToBrief";
import {cancelRequest} from "api/cancelTokens";
import {GET_PROJECT_SAVED_PEOPLE_TOKEN} from "api/requestCancelTokenStrings";
import {
	fetchProjectCandidateIDs,
	getPaginatedProjectSavedPeople,
} from "store/mapx/project/projectCandidatesAsyncActions";

const OPTIONS: sortOption[] = [
	{value: "full_name", name: "Full Name A to Z"},
	{value: "-full_name", name: "Full Name Z to A"},
	{value: "company_name", name: "Company A to Z"},
	{value: "-company_name", name: "Company Z to A"},
	{value: "-added_to_project_date", name: "Recently Added"},
	{value: "-fit_to_brief_score", name: "Scoring"},
	{value: "-progress_status_priority", name: "Status"},
];

const FilterSection = () => {
	const dispatch = useAppDispatch();

	const filterSelection = useSelector(additionalProfileFiltersSelector);

	const selectedSort = useSelector(apCandidateOrderingSelector);

	const candidateFilterPayload = useMemo(() => {
		return buildCandidateApiRequestPayload({...filterSelection}, true);
	}, [filterSelection]);

	const hasActiveFilter = useMemo(() => {
		return (
			candidateFilterPayload !== null &&
			candidateFilterPayload.filters &&
			Object.keys(candidateFilterPayload.filters).length > 0
		);
	}, [candidateFilterPayload]);

	useEffect(() => {
		dispatch(getPaginatedProjectSavedPeople());
		dispatch(fetchProjectCandidateIDs());

		return () => {
			cancelRequest(GET_PROJECT_SAVED_PEOPLE_TOKEN);
		};
	}, [dispatch, candidateFilterPayload]);

	useEffect(() => {
		return () => {
			dispatch(toggleAllTargetCompaniesOnAPCandidateFilter(false));
		};
	}, [dispatch]);

	const sortOnSelect = useCallback(
		(option: sortOption) => {
			dispatch(setAPFilter({attribute: "ordering", value: option.value}));
		},
		[dispatch],
	);

	const displayedSelectedSort = useMemo(() => {
		return OPTIONS.find((option) => option.value === selectedSort)?.name;
	}, [selectedSort]);

	return (
		<div className={styles.filterSectionWrapper}>
			<div className={styles.left}>
				<ProjectCandidatesFilter
					canUserClick={true}
					source={"saved_people"}
					className={classNames(styles.filterButton, {
						[styles.hasFilter]: hasActiveFilter,
					})}
					titleClass={styles.filterButtonTitle}
					shouldDisplayResetFilter={hasActiveFilter}
				/>

				<Dropdown
					key={displayedSelectedSort || "Default"}
					onSelect={sortOnSelect}
					options={OPTIONS}
					getOptionLabel={(option) => option.name}
					defaultSelectedOption={OPTIONS.find((s) => s.value === selectedSort) || null}
					placeholder={
						<div className={styles.dropdownPlaceholder}>
							<div>
								<span>Sort By</span> {displayedSelectedSort}
							</div>
							<div>
								<ArrowDown />
							</div>
						</div>
					}
				/>
			</div>

			<div className={styles.right}>
				<AddPeople />

				<FitToBrief />
			</div>
		</div>
	);
};

export default FilterSection;
