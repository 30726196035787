import {memo, useCallback, useMemo} from "react";
import {TabSelect} from "components";
import {Accordion, FunctionNSpecialismSearchFilter} from "mapx-components";

import {useAppDispatch, useAppSelector} from "hooks";

import {filterPositionOptionsSelector} from "store/mapx/filter/filterSelectors";
import {TRootState} from "types";
import {
	mrAllSpecialismsCountSelector,
	mrAnyJobFunctionTagsSelector,
	mrCurrentJobFunctionTagsSelector,
	mRJFAnyPositionConnectivityLogicSelector,
	mRJFCurrentPositionConnectivityLogicSelector,
	mRJFPreviousPositionConnectivityLogicSelector,
	mRJobFunctionCandidatePositionSelector,
	mRJobFunctionFilterConfigSelector,
	mRJobFunctionSelectionTreeSelector,
	mrPreviousJobFunctionTagsSelector,
	mRSpecialismsByPositionSelector,
} from "store/mapx/market-report/marketReportFilterSelectors";
import {
	setMarketReportMultipleFilterValueInForm,
	setMRJobFunctionFilterConfig,
} from "store/mapx/market-report/marketReportActions";
import {TConnectorLogic, TPositionLogic} from "store/mapx/market-report/types";
import {
	clearSpecialismsByPositionForMarketReportFormFilter,
	moveSpecialismForMarketReportFormFilter,
	setBulkSpecialismsForMarketReportFormFilter,
	setSpecialismForMarketReportFormFilter,
	switchSpecialismsConnectorForMarketReportFormFilterPosition,
} from "store/mapx/market-report/marketReportFormAsyncActions";

function JobFunctionFilter() {
	const dispatch = useAppDispatch();

	const jobFunctionFilterConfig = useAppSelector(mRJobFunctionFilterConfigSelector);

	const position = useAppSelector<TPositionLogic>(mRJobFunctionCandidatePositionSelector);

	const currentPositionConnectivityLogic = useAppSelector<TConnectorLogic>(
		mRJFCurrentPositionConnectivityLogicSelector,
	);

	const previousPositionConnectivityLogic = useAppSelector<TConnectorLogic>(
		mRJFPreviousPositionConnectivityLogicSelector,
	);

	const anyPositionConnectivityLogic = useAppSelector<TConnectorLogic>(
		mRJFAnyPositionConnectivityLogicSelector,
	);

	const logicByCurrentlySelectedPosition = useMemo(() => {
		if (position === "current") {
			return currentPositionConnectivityLogic;
		} else if (position === "previous") {
			return previousPositionConnectivityLogic;
		} else {
			return anyPositionConnectivityLogic;
		}
	}, [
		anyPositionConnectivityLogic,
		currentPositionConnectivityLogic,
		position,
		previousPositionConnectivityLogic,
	]);

	const filterPositionOptions = useAppSelector(filterPositionOptionsSelector);

	const selectionTree = useAppSelector((state: TRootState) =>
		mRJobFunctionSelectionTreeSelector(state, position, logicByCurrentlySelectedPosition),
	);

	const count = useAppSelector(mrAllSpecialismsCountSelector);

	const anyTags = useAppSelector(mrAnyJobFunctionTagsSelector);

	const currentTags = useAppSelector(mrCurrentJobFunctionTagsSelector);

	const previousTags = useAppSelector(mrPreviousJobFunctionTagsSelector);

	const selectedSpecialismIds = useAppSelector((state) =>
		mRSpecialismsByPositionSelector(state, position, logicByCurrentlySelectedPosition),
	);

	const handlePositionChange = useCallback(
		(updatedPositionValue: string) => {
			dispatch(
				setMRJobFunctionFilterConfig({
					...jobFunctionFilterConfig,
					active_position: updatedPositionValue as TPositionLogic,
				}),
			);
		},
		[dispatch, jobFunctionFilterConfig],
	);

	const handleSpecialismChange = useCallback(
		(id: number) => {
			dispatch(
				setSpecialismForMarketReportFormFilter({
					position: position,
					logic: logicByCurrentlySelectedPosition,
					id: id,
				}),
			);
		},
		[dispatch, logicByCurrentlySelectedPosition, position],
	);

	const handleTagClick = useCallback(
		(id: number, position: TPositionLogic, logic: TConnectorLogic) => {
			dispatch(
				setSpecialismForMarketReportFormFilter({
					position,
					logic,
					id,
				}),
			);
		},
		[dispatch],
	);

	const getLogicConnectorByPosition = useCallback(
		(positionParam: TPositionLogic) => {
			let logic: TConnectorLogic;

			switch (positionParam) {
				case "current":
					logic = currentPositionConnectivityLogic;
					break;
				case "previous":
					logic = previousPositionConnectivityLogic;
					break;
				case "any":
					logic = anyPositionConnectivityLogic;
					break;
				default:
					throw Error("This position doesn't exist");
			}

			return logic;
		},
		[
			anyPositionConnectivityLogic,
			currentPositionConnectivityLogic,
			previousPositionConnectivityLogic,
		],
	);

	const moveSpecialismTagPosition = useCallback(
		(id: number, fromPosition: TPositionLogic, toPosition: TPositionLogic) => {
			const fromLogic = getLogicConnectorByPosition(fromPosition);
			const toLogic = getLogicConnectorByPosition(toPosition);

			dispatch(
				moveSpecialismForMarketReportFormFilter({
					from: fromPosition,
					to: toPosition,
					fromLogic: fromLogic,
					toLogic: toLogic,
					id: id,
				}),
			);
		},
		[dispatch, getLogicConnectorByPosition],
	);

	const handleBulkUpdate = useCallback(
		(ids: number[]) => {
			dispatch(
				setBulkSpecialismsForMarketReportFormFilter(
					position,
					logicByCurrentlySelectedPosition,
					ids,
				),
			);
		},
		[dispatch, logicByCurrentlySelectedPosition, position],
	);

	const handleClearTagClickByPosition = useCallback(
		(position: TPositionLogic) => {
			dispatch(clearSpecialismsByPositionForMarketReportFormFilter(position));
		},
		[dispatch],
	);

	const handleResetClick = useCallback(() => {
		dispatch(
			setMarketReportMultipleFilterValueInForm({
				specialisms_and: [],
				specialisms_or: [],
				specialisms_not: [],
				current_specialisms_and: [],
				current_specialisms_or: [],
				current_specialisms_not: [],
				previous_specialisms_and: [],
				previous_specialisms_or: [],
				previous_specialisms_not: [],
			}),
		);
	}, [dispatch]);

	const handleConnectivityOptionChange = useCallback(
		(position: TPositionLogic, toLogic: TConnectorLogic) => {
			let fromLogic: TConnectorLogic;

			switch (position) {
				case "current":
					fromLogic = currentPositionConnectivityLogic;

					dispatch(
						setMRJobFunctionFilterConfig({
							...jobFunctionFilterConfig,
							active_current_position_connect_logic: toLogic,
						}),
					);
					break;
				case "previous":
					fromLogic = previousPositionConnectivityLogic;

					dispatch(
						setMRJobFunctionFilterConfig({
							...jobFunctionFilterConfig,
							active_previous_position_connect_logic: toLogic,
						}),
					);
					break;
				case "any":
					fromLogic = anyPositionConnectivityLogic;

					dispatch(
						setMRJobFunctionFilterConfig({
							...jobFunctionFilterConfig,
							active_any_position_connect_logic: toLogic,
						}),
					);
					break;
				default:
					throw Error("This position doesn't exist");
			}

			dispatch(
				switchSpecialismsConnectorForMarketReportFormFilterPosition({
					position,
					fromLogic,
					toLogic,
				}),
			);
		},
		[
			anyPositionConnectivityLogic,
			currentPositionConnectivityLogic,
			dispatch,
			jobFunctionFilterConfig,
			previousPositionConnectivityLogic,
		],
	);

	return (
		<Accordion title="Function & Specialism" subtitle={count}>
			<TabSelect.LabelContainer label="Position">
				<TabSelect
					selected={position}
					onTabChange={handlePositionChange}
					options={filterPositionOptions}
				/>
			</TabSelect.LabelContainer>

			<FunctionNSpecialismSearchFilter
				selectionTree={selectionTree}
				handleTagClick={handleTagClick}
				handleResetClick={handleResetClick}
				handleBulkUpdate={handleBulkUpdate}
				handleSpecialismChange={handleSpecialismChange}
				displayResetFilterOption={count > 0}
				selectedSpecialismIds={selectedSpecialismIds}
				moveSpecialismTagPosition={moveSpecialismTagPosition}
				selectedSpecialismTags={{
					current: currentTags,
					previous: previousTags,
					any: anyTags,
				}}
				handleConnectivityOptionChange={handleConnectivityOptionChange}
				connectionLogics={{
					current: currentPositionConnectivityLogic,
					previous: previousPositionConnectivityLogic,
					any: anyPositionConnectivityLogic,
				}}
				clearTagsByPosition={handleClearTagClickByPosition}
			/>
		</Accordion>
	);
}

export default memo(JobFunctionFilter);
