import React from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";
import {TTrackTableHeader} from "mapx-components/Cards/ProjectCandidateTableCard/types";
import LeaverIcon from "mapx-components/Cards/ProjectCandidateTableCard/Icons/LeaverIcon";
import PromotionIcon from "mapx-components/Cards/ProjectCandidateTableCard/Icons/PromotionIcon";
import NewHiresIcon from "mapx-components/Cards/ProjectCandidateTableCard/Icons/NewHiresIcon";
import NoChangeIcon from "mapx-components/Cards/ProjectCandidateTableCard/Icons/NoChangeIcon";

const PeopleCardTableHeader = ({type}: TTrackTableHeader) => {
	return (
		<div className={styles.container}>
			<div className={styles.basicInfo}>
				<div className={classNames(styles.name, styles.tableHeaderText)}>
					{type === "Leaver" && <LeaverIcon />}
					{type === "Promotion" && <PromotionIcon />}
					{type === "New Hires" && <NewHiresIcon />}
					{type === "No Change" && <NoChangeIcon />}
					<span
						className={classNames(styles.type, {
							[styles.leaver]: type === "Leaver",
							[styles.promotion]: type === "Promotion",
							[styles.newHires]: type === "New Hires",
							[styles.noChange]: type === "No Change",
						})}
					>
						{type}
					</span>
				</div>

				<div className={classNames(styles.role, styles.tableHeaderText)}>
					{type === "No Change" ? "Current" : "New"} Role
				</div>

				<div className={styles.company}>
					<span className={classNames(styles.companyName, styles.tableHeaderText)}>
						{type === "No Change" ? "Current" : "New"} Company
					</span>
				</div>

				<div className={classNames(styles.startDate, styles.tableHeaderText)}>
					Start Date
				</div>

				<div className={classNames(styles.previousRole, styles.tableHeaderText)}>
					Previous Role
				</div>

				<div className={styles.company}>
					<span className={classNames(styles.companyName, styles.tableHeaderText)}>
						Previous Company
					</span>
				</div>

				{/*<div className={styles.removeCandidateFromList} />*/}
			</div>
		</div>
	);
};

export default PeopleCardTableHeader;
