import {useCallback} from "react";
import {Accordion} from "mapx-components";
import {useAppDispatch, useAppSelector} from "hooks";
import {filterPositionOptionsSelector} from "store/mapx/filter/filterSelectors";
import {TCompanyRevenueRangeItem} from "mapx-components/Filters/CompanyRevenueSearchFilter/types";
import CompanyRevenueSearchFilter from "mapx-components/Filters/CompanyRevenueSearchFilter";
import {TabSelect} from "components";
import Tags from "mapx-components/Filters/CompanyRevenueSearchFilter/Tags";
import {
	mRCandidateCompanyRevenueByPositionSelector,
	mRCandidateCompanyRevenueFilterConfigSelector,
	mRCandidatesAllCompanyRevenueCountSelector,
	mRSelectedAnyCompanyRevenueTagsSelector,
	mRSelectedCurrentCompanyRevenueTagsSelector,
	mRSelectedPreviousCompanyRevenueTagsSelector,
} from "store/mapx/market-report/marketReportFilterSelectors";
import {
	setMarketReportMultipleFilterValueInForm,
	setMRRevenueFilterConfig,
} from "store/mapx/market-report/marketReportActions";
import {TPositionLogic} from "store/mapx/market-report/types";
import {
	moveRevenueForMarketReportFormFilter,
	setCompanyRevenueForMarketReportFormFilter,
} from "store/mapx/market-report/marketReportFormAsyncActions";

const RevenueFilter = () => {
	const dispatch = useAppDispatch();

	const filterPositionOptions = useAppSelector(filterPositionOptionsSelector);

	const candidateCompanyRevenueFilterConfig = useAppSelector(
		mRCandidateCompanyRevenueFilterConfigSelector,
	);

	const position = candidateCompanyRevenueFilterConfig.active_position;

	const selectedRevenues = useAppSelector((state) =>
		mRCandidateCompanyRevenueByPositionSelector(state, position),
	);

	const currentRevenueTags = useAppSelector(mRSelectedCurrentCompanyRevenueTagsSelector);

	const previousRevenueTags = useAppSelector(mRSelectedPreviousCompanyRevenueTagsSelector);

	const anyRevenueTags = useAppSelector(mRSelectedAnyCompanyRevenueTagsSelector);

	const revenueCount = useAppSelector(mRCandidatesAllCompanyRevenueCountSelector);

	const handlePositionChange = useCallback(
		(updatedPositionValue: string) => {
			dispatch(
				setMRRevenueFilterConfig({
					...candidateCompanyRevenueFilterConfig,
					active_position: updatedPositionValue,
				}),
			);
		},
		[dispatch, candidateCompanyRevenueFilterConfig],
	);

	const handleResetClick = useCallback(() => {
		dispatch(
			setMarketReportMultipleFilterValueInForm({
				revenue: [],
				current_revenue: [],
				previous_revenue: [],
			}),
		);
	}, [dispatch]);

	const clearTagsSelectionByPosition = useCallback(
		(position: TPositionLogic) => {
			if (position === "current") {
				dispatch(
					setMarketReportMultipleFilterValueInForm({
						current_revenue: [],
					}),
				);
			} else if (position === "previous") {
				dispatch(
					setMarketReportMultipleFilterValueInForm({
						previous_revenue: [],
					}),
				);
			} else {
				dispatch(
					setMarketReportMultipleFilterValueInForm({
						revenue: [],
					}),
				);
			}
		},
		[dispatch],
	);

	const handleOnChange = useCallback(
		(revenueItem: TCompanyRevenueRangeItem) => {
			dispatch(setCompanyRevenueForMarketReportFormFilter({position, revenueItem}));
		},
		[dispatch, position],
	);

	const moveRevenueTagPosition = useCallback(
		(id: string, from: TPositionLogic, to: TPositionLogic) => {
			dispatch(
				moveRevenueForMarketReportFormFilter({
					from,
					to,
					ids: [id],
				}),
			);
		},
		[dispatch],
	);

	return (
		<Accordion title="Company Revenue" subtitle={revenueCount ?? ""}>
			<TabSelect.LabelContainer label="Position">
				<TabSelect
					selected={position}
					onTabChange={handlePositionChange}
					options={filterPositionOptions}
				/>
			</TabSelect.LabelContainer>

			<CompanyRevenueSearchFilter
				handleOnChange={handleOnChange}
				handleResetClick={handleResetClick}
				selectedRevenues={selectedRevenues}
			/>

			{revenueCount > 0 && (
				<>
					<Tags
						tagLabel={"Current"}
						tagValue={"current"}
						tags={currentRevenueTags}
						handleTagClick={handleOnChange}
						clearTagsByPosition={clearTagsSelectionByPosition}
						moveRevenueTagPosition={moveRevenueTagPosition}
					/>

					<Tags
						tagLabel={"Previous"}
						tagValue={"previous"}
						tags={previousRevenueTags}
						handleTagClick={handleOnChange}
						clearTagsByPosition={clearTagsSelectionByPosition}
						moveRevenueTagPosition={moveRevenueTagPosition}
					/>

					<Tags
						tagLabel={"Any"}
						tagValue={"any"}
						tags={anyRevenueTags}
						handleTagClick={handleOnChange}
						clearTagsByPosition={clearTagsSelectionByPosition}
						moveRevenueTagPosition={moveRevenueTagPosition}
					/>
				</>
			)}
		</Accordion>
	);
};

export default RevenueFilter;
