import {get} from "lodash";

import type {TCountry, TLocation, TRegion, TGetLocationExpandableDataResult} from "./types";
import {TFilterCountry} from "api/filterOptionsApi/CountryApi/types";
import {TLocationPosition} from "containers/Filters/PeopleFilters/LocationFilter/types";
import {TAppDispatch, TRootState} from "types";
import {Dispatch} from "redux";

export const getLocationExpandableData = (
	filteredData?: (TLocation | TCountry | TRegion)[] | null,
) =>
	filteredData?.reduce<TGetLocationExpandableDataResult>((acc, curVal) => {
		const curValRegions = get(curVal, "regions");
		const curValCountries = get(curVal, "countries");

		if (curValCountries || curValRegions) {
			acc[curVal.id] = true;

			Object.assign(acc, getLocationExpandableData(curValCountries || curValRegions));
		}

		return acc;
	}, {});

export const getSelectedCountriesAndRegions = (
	countryIDs: number[],
	regionIDs: number[],
	options: {id: number; name: string; regions?: TRegion[]}[],
): TFilterCountry[] => {
	const selected: TFilterCountry[] = [];

	const countrySet = new Set(countryIDs);
	const regionSet = new Set(regionIDs);

	options.forEach((o) => {
		const isCountryMatch = countrySet.has(o.id);

		if (o.regions && o.regions.length > 0) {
			if (isCountryMatch) {
				selected.push({
					id: o.id,
					name: o.name,
				});
			} else {
				const matchingRegions = o.regions.filter((region: TRegion) =>
					regionSet.has(region.id),
				);

				matchingRegions.forEach((region: TRegion) => {
					selected.push({
						id: o.id,
						name: `${o.name}: ${region.name}`,
						region_id: region.id,
					});
				});
			}
		} else if (isCountryMatch) {
			selected.push({
				id: o.id,
				name: o.name,
			});
		}
	});

	return selected;
};

export const handleMoveLocation =
	(
		{
			from,
			to,
			country_id,
			region_id,
		}: {
			from: TLocationPosition;
			to: TLocationPosition;
			country_id: number;
			region_id: string;
		},
		getFilterKeys: (
			position: TLocationPosition,
			state: TRootState,
		) => {locationKey: string; regionKey: string},
		getCandidateIds: (
			position: TLocationPosition,
			state: TRootState,
		) => {locations: number[]; regions: number[]},
		setFilterAction: (filterChanges: Record<string, number[]>) => TAppDispatch,
	) =>
	async (dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const state = getState();

		const {locationKey: fromLocationKey, regionKey: fromRegionKey} = getFilterKeys(from, state);
		const {locationKey: toLocationKey, regionKey: toRegionKey} = getFilterKeys(to, state);
		const {locations: fromLocations, regions: fromRegions} = getCandidateIds(from, state);
		const {locations: toLocations, regions: toRegions} = getCandidateIds(to, state);

		const moveIdsBetweenFilters = (
			fromIds: number[],
			toIds: number[],
			filterCondition: (id: number) => boolean,
		) => ({
			from: fromIds.filter((id) => !filterCondition(id)),
			to: [...toIds.filter((id) => !filterCondition(id)), ...fromIds.filter(filterCondition)],
		});

		const filterChanges = region_id
			? {
					[fromRegionKey]: moveIdsBetweenFilters(
						fromRegions,
						toRegions,
						(id) => region_id === String(id),
					).from,
					[toRegionKey]: moveIdsBetweenFilters(
						fromRegions,
						toRegions,
						(id) => region_id === String(id),
					).to,
			  }
			: {
					[fromLocationKey]: moveIdsBetweenFilters(
						fromLocations,
						toLocations,
						(id) => country_id === id,
					).from,
					[toLocationKey]: moveIdsBetweenFilters(
						fromLocations,
						toLocations,
						(id) => country_id === id,
					).to,
			  };

		dispatch(setFilterAction(filterChanges));
	};
