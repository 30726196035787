import React, {useMemo} from "react";
import {SearchInput} from "mapx-components";
import styles from "./StatusSearchFilter.module.scss";
import useFetchListOptions from "hooks/useFetchListOptions";
import {Checkbox, CheckboxSkeletonLoader, ResetFilters, Tag} from "components";
import {getName} from "helpers/string";
import CheckboxList from "mapx-components/Inputs/CheckboxList";
import {useAppSelector} from "hooks";
import {TStatusesSearchFilterProps, TStatusItem} from "./types";
import {projectProgressStatusesSelector} from "store/mapx/project/projectSelectors";
import {getProjectProgressStatuses} from "store/mapx/project/projectAsyncActions";
import FilterTags from "../../FilterTags";
import MultiPeopleIcon from "assets/icons/MultiPeopleIcon";
import {useStatusCountMap} from "mapx-pages/Project/utils";

export default function StatusSearchFilter({
	handleResetClick,
	handleOnChange,
	selectedStatuses,
	candidateStatusCount,
}: TStatusesSearchFilterProps) {
	const statusOptions = useAppSelector(projectProgressStatusesSelector);

	const updatedStatusOptions = useMemo(() => {
		return [{id: null, name: "No Status"}, ...statusOptions];
	}, [statusOptions]);

	const {filteredData, onFilterChanged, loading} = useFetchListOptions({
		options: updatedStatusOptions,
		callbackMethod: getProjectProgressStatuses,
	});

	const typedFilteredData = filteredData as TStatusItem[];

	const reorderedData = useMemo(() => {
		return typedFilteredData?.filter((item) => !selectedStatuses.includes(item.id));
	}, [typedFilteredData, selectedStatuses]);

	const selectedStatusItems = useMemo(() => {
		return updatedStatusOptions?.filter((option: TStatusItem) =>
			selectedStatuses.includes(option.id),
		);
	}, [updatedStatusOptions, selectedStatuses]);

	const statusCountMap = useStatusCountMap(candidateStatusCount);

	return (
		<div>
			<SearchInput
				onChange={onFilterChanged}
				placeholder="Search for a status"
				type="text"
				errorText={undefined}
				errorClass={undefined}
				isLoading={loading}
			/>

			<ResetFilters
				parentStyle={{color: "#5A5A5A", marginRight: 19}}
				onClick={handleResetClick}
				displayIcon={true}
			>
				Clear Selection
			</ResetFilters>
			<div className={styles.checkboxList}>
				<CheckboxList data-testid={"statusList"}>
					{reorderedData?.map((item: TStatusItem) => (
						<div key={item.id} className={styles.checkboxWrapper}>
							<Checkbox
								borderColor="#0C5850"
								containerClass={styles.checkboxContainer}
								isChecked={selectedStatuses.includes(item.id)}
								label={getName(item.name)}
								onChange={() => handleOnChange(item)}
								value={getName(item.name)}
							/>
							<span className={styles.statusCountSection}>
								<small>{statusCountMap?.[item.name] ?? 0}</small>
								<MultiPeopleIcon />
							</span>
						</div>
					))}
				</CheckboxList>

				{(loading || !statusOptions?.length) && <CheckboxSkeletonLoader />}
			</div>

			{selectedStatuses?.length > 0 && (
				<div style={{margin: "8px 0"}}>
					<FilterTags
						onDrop={(e) => e.preventDefault()}
						label={"Selected Statuses"}
						count={selectedStatusItems?.length}
						moveDisabled={true}
						tags={selectedStatusItems?.map((tag: TStatusItem) => (
							<Tag key={tag.id} onClick={() => handleOnChange(tag)}>
								{`${tag.name}` || ""}
							</Tag>
						))}
						onClear={handleResetClick}
					/>
				</div>
			)}
		</div>
	);
}
