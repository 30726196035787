import React from "react";
import ContentLoader from "react-content-loader";

const CustomBarChartLoader = () => {
	return (
		<ContentLoader
			speed={2}
			height={185}
			width={"100%"}
			backgroundColor="#DCE7E7"
			foregroundColor="#F9F9F9"
		>
			<rect x="1" y="10" rx="4" width="60%" height="18" />
			<rect x="450" y="10" rx="4" width="10%" height="18" />

			<rect x="1" y="40" rx="4" width="72%" height="18" />
			<rect x="450" y="40" rx="4" width="10%" height="18" />

			<rect x="1" y="70" rx="4" width="80%" height="18" />
			<rect x="450" y="70" rx="4" width="10%" height="18" />

			<rect x="1" y="100" rx="4" width="66%" height="18" />
			<rect x="450" y="100" rx="4" width="10%" height="18" />

			<rect x="1" y="130" rx="4" width="49%" height="18" />
			<rect x="450" y="130" rx="4" width="10%" height="18" />
		</ContentLoader>
	);
};

export default CustomBarChartLoader;
