import React, {useCallback, useState} from "react";
import {useAppDispatch, useAppSelector, useModalHook} from "hooks";
import {
	duplicateProjectModalSelector,
	editProjectFormSelector,
} from "store/mapx/project-list/projectListSelectors";
import {duplicateProjectModal} from "store/mapx/project-list/projectListActions";
import {ModalHeader, ModalSeparator} from "components";
import ProjectForm from "mapx-components/Modals/ProjectCreateWithWorkflowsModal/ProjectForm";
import {TProjectFormData} from "mapx-components/Modals/ProjectCreateWithWorkflowsModal/ProjectForm/types";
import {duplicateProject} from "store/mapx/project-list/projectWorkflow";

const ProjectDuplicateModal = () => {
	const dispatch = useAppDispatch();

	const [loading, setLoading] = useState(false);

	const modalDisplay = useAppSelector(duplicateProjectModalSelector);

	const projectFormData: TProjectFormData = useAppSelector(editProjectFormSelector);

	const {modalIsOpen, customStyles, Modal} = useModalHook(
		{
			content: {
				maxWidth: "588px",
				borderRadius: "4px",
				width: "99%",
				boxShadow: "0px 25px 40px -10px rgba(79, 75, 74, 0.08)",
			},
		},
		modalDisplay,
	);

	const handleModalHide = useCallback(() => {
		dispatch(duplicateProjectModal(false));
	}, [dispatch]);

	const handleDuplicateProject = useCallback(
		async (formData: TProjectFormData) => {
			setLoading(true);

			await dispatch(duplicateProject(projectFormData.id as number, formData));

			setLoading(false);
		},
		[dispatch, projectFormData],
	);

	return (
		<Modal
			contentLabel="Modal"
			isOpen={modalIsOpen}
			onRequestClose={handleModalHide}
			style={customStyles}
			title="Duplicate Project"
		>
			<ModalHeader title={projectFormData.name} onClose={handleModalHide} />

			<ModalSeparator />

			<ProjectForm
				formType={"duplicate-form"}
				projectName={projectFormData.name}
				projectLocation={projectFormData.location}
				projectCompany={projectFormData.company}
				projectUsers={projectFormData.users}
				handleSubmitButtonClick={handleDuplicateProject}
				loading={loading}
			/>
		</Modal>
	);
};

export default ProjectDuplicateModal;
