import {format} from "date-fns";
import {
	TCandidateCompanyDateRangeItem,
	TDEDateRange,
} from "mapx-components/Filters/DealExperienceSearchFilter/types";

export const filterDealExperienceDateRanges = (
	existingDates: TDEDateRange[],
	newDateRanges: {from_date: string; to_date: string}[],
) => {
	let updatedDates = [...existingDates];

	newDateRanges.forEach(({from_date, to_date}) => {
		const dateExists = updatedDates.some(
			(date) => date.from_date === from_date && date.to_date === to_date,
		);

		if (dateExists) {
			updatedDates = updatedDates.filter(
				(date) => date.from_date !== from_date || date.to_date !== to_date,
			);
		} else {
			updatedDates.push({from_date, to_date});
		}
	});

	return updatedDates;
};

export const formatDealExperienceDate = (dates: TCandidateCompanyDateRangeItem[]) => {
	const formattedDates = dates.map((date) => {
		const min_value = date.min_value ? format(new Date(date.min_value), "yyyy-MM-dd") : "";
		const max_value = date.max_value ? format(new Date(date.max_value), "yyyy-MM-dd") : "";

		return {
			from_date: min_value,
			to_date: max_value,
		};
	});

	return filterDealExperienceDateRanges([], formattedDates);
};
