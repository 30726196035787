import {type FC, Fragment} from "react";
import classNames from "classnames";
import {matchPath, NavLink, useHistory, useLocation} from "react-router-dom";

import {TChildNav, TNavItemProps} from "./types";
import styles from "./Sidebar.module.scss";
import {ArrowDown} from "assets/icons";
import {useAppDispatch, useAppSelector} from "hooks";
import {expandedSidebarChildItemsSelector} from "store/mapx/layout/layoutSelectors";
import {setSideBarChildItemsExpanded} from "store/mapx/layout/layoutActions";

const NavItem: FC<TNavItemProps> = ({name, Icon, path, childPaths, collapsed}) => {
	const childItemsExpanded = useAppSelector(expandedSidebarChildItemsSelector);

	const dispatch = useAppDispatch();

	const history = useHistory();

	const openFirstChildPath = (paths: TChildNav[]) => {
		if (paths?.length > 0 && paths[0]?.path) {
			history.push(paths[0].path);

			dispatch(setSideBarChildItemsExpanded(paths[0].path));
		}
	};

	const handleToggleExpandChildItems = () => {
		if (childPaths && childPaths[0]?.path === childItemsExpanded) {
			dispatch(setSideBarChildItemsExpanded(null));
		} else {
			dispatch(setSideBarChildItemsExpanded(childPaths ? childPaths[0]?.path : null));
		}
	};

	const {pathname} = useLocation();

	return (
		<Fragment>
			{path &&
				Icon &&
				(path === "#" ? (
					<div
						className={classNames(styles.link, styles.mainTitle, {
							[styles.active]: childPaths?.some(
								(i) => i.path && matchPath(i.path, pathname) && pathname !== "/",
							),
						})}
					>
						<div className={classNames(styles.navMainItem)}>
							<Icon
								className={styles.navItemIcon}
								onClick={() => openFirstChildPath(childPaths ?? [])}
							/>

							{!collapsed && (
								<span
									onClick={() => openFirstChildPath(childPaths ?? [])}
									className={styles.navItemText}
								>
									{name}
								</span>
							)}

							{!collapsed && (
								<ArrowDown
									onClick={handleToggleExpandChildItems}
									style={{
										cursor: "pointer",
										position: "absolute",
										width: "35px",
										right: "2px",
										top: "10px",
									}}
									className={classNames({
										[styles.arrowReversed]:
											childPaths &&
											childItemsExpanded === childPaths[0]?.path,
									})}
								/>
							)}
						</div>

						{!collapsed &&
							childPaths &&
							childItemsExpanded === childPaths[0]?.path &&
							Array.isArray(childPaths) &&
							childPaths.length > 0 && (
								<div className={styles.navChildItemsWrapper}>
									{childPaths
										.filter((cp) => cp.path !== undefined)
										.map((childItem) => (
											<NavLink
												key={childItem.path}
												// eslint-disable-next-line @typescript-eslint/ban-ts-comment
												// @ts-ignore
												to={childItem.path}
												className={(isActive) =>
													classNames(styles.navChildItem, {
														[styles.active]: isActive,
													})
												}
											>
												{childItem.name}
											</NavLink>
										))}
								</div>
							)}
					</div>
				) : (
					<NavLink
						to={path}
						onClick={() => dispatch(setSideBarChildItemsExpanded(null))}
						className={(isActive) =>
							classNames(styles.link, {[styles.active]: isActive})
						}
					>
						<span
							className={classNames(styles.navItem, {[styles.expanded]: !collapsed})}
						>
							<Icon className={styles.navItemIcon} />

							{!collapsed && <span className={styles.navItemText}>{name}</span>}
						</span>
					</NavLink>
				))}
		</Fragment>
	);
};

export default NavItem;
