import React from "react";

const NoChangeIcon = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
		>
			<path d="M2 10H19" stroke="#868D8D" strokeWidth="2" />
		</svg>
	);
};

export default NoChangeIcon;
