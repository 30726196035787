import {Dispatch} from "redux";
import {TAppDispatch, TRootState} from "types";
import {userEmailSelector} from "store/mapx/user/userSelectors";
import {
	addOrUpdateSearchRequestToTheList,
	clearAPActiveResult,
	displayAPResults,
	setActiveSearchRequest,
	setAllSearchRequests,
	setAPActiveResult,
	setPRFilter,
	setSearchRequestDetailsLoading,
} from "store/mapx/additional-profiles/additionalProfilesActions";
import {Mixpanel} from "helpers/mixpanel";
import {
	TAllSearchRequests,
	TSearchRequestCreate,
	TSearchRequestDetails,
	TSearchRequestType,
} from "api/projectApi/searchRequestApi/types";
import searchRequestApi from "api/projectApi/searchRequestApi";
import {projectSelector, projectsSelector} from "store/mapx/project-list/projectListSelectors";
import axios, {AxiosResponse} from "axios";
import {SICandidate} from "api/candidateApi/types";
import mapXCandidateApi from "api/candidateApi";
import {successResponse} from "helpers/map";
import {STProject} from "api/projectApi/types";
import {ICandidateSearchApiRequestPayload} from "api/candidateApi/form";
import {TCreateSearchRequest} from "store/mapx/additional-profiles/asyncActionsReturnTypes";
import {toast} from "react-toastify";
import {ToastContent} from "components";
import {getJobTitlesSuccess} from "store/mapx/search/searchActions";
import {
	activeSearchRequestSelector,
	activeSearchRequestTypeSelector,
	allSearchRequestsSelector,
} from "store/mapx/additional-profiles/additionalProfilesSelectors";
import {globalPerPageSelector} from "../userPreference/userPreferenceSelectors";

/* eslint-disable  @typescript-eslint/no-explicit-any */
const cancelTokens = {} as any;

export const prepareMoreLikeThisPayloadAndCreateSearchRequest =
	(candidate: SICandidate) =>
	async (dispatch: Dispatch<TAppDispatch>, getState: () => TRootState): Promise<void> => {
		const state = getState();

		const selectedProjectInstate = projectSelector(state);

		const moreLikeThisApiPayload: TSearchRequestCreate = {
			type: "More Like This",
			candidate_id: candidate.id,
			project_id: selectedProjectInstate.id,
		};

		try {
			await dispatch(createSearchRequest(moreLikeThisApiPayload));
		} catch (error) {
			// Handle error if needed
			console.error("Error occurred during dispatch:", error);
			throw error; // Re-throw error to propagate it
		}
	};

export const createSearchRequest =
	(apiPayload: TSearchRequestCreate) =>
	async (
		dispatch: Dispatch<TAppDispatch>,
		getState: () => TRootState,
	): Promise<TCreateSearchRequest> => {
		try {
			const state = getState();

			const userEmail = userEmailSelector(state);

			const selectedProjectInstate = projectSelector(state);

			const response = await searchRequestApi.createSearchRequest(apiPayload);

			if (successResponse(response, 201)) {
				const searchDetails: TSearchRequestDetails = response.data;

				if (apiPayload.type === "Additional Profiles" && "job_titles" in apiPayload) {
					searchDetails["job_titles"] = apiPayload.job_titles;

					dispatch(setPRFilter({type: "selected_job_titles", values: []}));
					dispatch(setPRFilter({type: "suggester_job_titles", values: []}));
					dispatch(
						getJobTitlesSuccess({
							results: [],
							pagination: {page: 1, count: 0, pages: 1},
							shouldAddMore: false,
						}),
					); // reset job titles list after every search
				}

				const searchDetailsWithProject = {
					...searchDetails,
					project: selectedProjectInstate,
				};

				dispatch(clearAPActiveResult());
				dispatch(displayAPResults(true));

				dispatch(addOrUpdateSearchRequestToTheList(searchDetailsWithProject)); // added to the list for tab generate
				dispatch(setActiveSearchRequest(searchDetailsWithProject)); // set active more like this

				Mixpanel.track(`Ran ${apiPayload.type} Search`, {
					name: `${apiPayload.type}`,
					pageTitle: `${selectedProjectInstate.name}`,
					url: window.location.pathname,
					distinct_id: userEmail,
					// candidate,
				});

				return {
					status: "success",
					message: "Success",
				};
			} else if (successResponse(response, 400)) {
				toast.error(ToastContent, {
					data: {
						title: response.data.detail,
					},
				});

				return {
					status: "error",
					message: response.data.detail,
				};
			} else {
				return {
					status: "error",
					message: "Something went wrong.",
				};
			}
		} catch (e) {
			console.log(e);

			return {
				status: "error",
				message: (e as Error).message || "Something went wrong.",
			};
		}
	};

export const getAllSearchRequestsByType =
	(projectId: number, searchType: TSearchRequestType) =>
	async (dispatch: Dispatch<TAppDispatch>) => {
		const requestKey = `GET_ALL_SEARCH_REQUEST_BY_PROJECT_${projectId}`;

		if (requestKey in cancelTokens) {
			cancelTokens[requestKey].cancel("Operation canceled due to new request.");
		}

		cancelTokens[requestKey] = axios.CancelToken.source();

		const config = {
			cancelToken: cancelTokens[requestKey].token,
		};

		try {
			const response: AxiosResponse =
				await searchRequestApi.getAllSearchRequestByTypeForProject(
					projectId,
					searchType,
					{page: 1, perPage: 100},
					config,
				);

			if (successResponse(response, 200)) {
				const data: TAllSearchRequests = response.data;

				const searchRequests = data.results;

				dispatch(setAllSearchRequests(searchRequests));

				await dispatch(setActiveSearchRequestFromAllSearchRequests(projectId, searchType));
			}
		} catch (e) {
			console.log((e as Error).message || "Something went wrong.");
		}
	};

export const setActiveSearchRequestFromAllSearchRequests =
	(projectId: number, searchType: string) =>
	async (dispatch: Dispatch<TAppDispatch>, getState: TRootState) => {
		const state = getState();

		const activeSearchRequest = activeSearchRequestSelector(state);

		const searchRequests = allSearchRequestsSelector(state);

		// state will preserve the previous selection of search request
		if (
			activeSearchRequest === null ||
			activeSearchRequest?.project_id !== projectId ||
			activeSearchRequest?.type !== searchType
		) {
			let latestSearchRequest = searchRequests.find(
				(item: Partial<TSearchRequestDetails>) => item.status === "In Progress",
			);

			if (!latestSearchRequest) {
				latestSearchRequest = searchRequests.find(
					(item: Partial<TSearchRequestDetails>) => item.status === "Created",
				);

				if (!latestSearchRequest) {
					latestSearchRequest = searchRequests.find(
						(item: Partial<TSearchRequestDetails>) => item.status === "Completed",
					);
				}
			}

			if (latestSearchRequest && "id" in latestSearchRequest) {
				// dispatch(setActiveSearchRequest(latestSearchRequest));
				await dispatch(getSearchRequestDetails(latestSearchRequest.id));
			}
		}
	};

export const getSearchRequestDetails =
	(searchRequestId: number, projectFromProgressWatch?: STProject) =>
	async (dispatch: Dispatch<TAppDispatch>, getState: TRootState) => {
		const requestKey = `GET_SEARCH_REQUEST_DETAILS_${searchRequestId}`;

		if (requestKey in cancelTokens) {
			cancelTokens[requestKey].cancel("Operation canceled due to new request.");
		}

		cancelTokens[requestKey] = axios.CancelToken.source();

		const config = {
			cancelToken: cancelTokens[requestKey].token,
		};

		const state = getState();

		const selectedProject = projectSelector(state);

		const projects = projectsSelector(state);

		const activeSearchRequest = activeSearchRequestSelector(state);

		// it means we have sent this request from sidebar list, not from progress watch
		if (projectFromProgressWatch === undefined) {
			dispatch(setSearchRequestDetailsLoading(true));
		}

		try {
			const response: AxiosResponse = await searchRequestApi.getSearchDetailsById(
				searchRequestId,
				config,
			);

			if (successResponse(response, 200)) {
				const searchDetails: TSearchRequestDetails = response.data;

				const projectToBeSet =
					projectFromProgressWatch ??
					projects.find((item: STProject) => item.id === searchDetails.project_id);

				const data = {
					...searchDetails,
					project: projectToBeSet,
				};

				// we run multiple quick search at a time
				// if search is for currently selected project, then we set it to state
				if (
					(projectFromProgressWatch === undefined &&
						selectedProject !== null &&
						selectedProject?.id === projectToBeSet?.id) ||
					(activeSearchRequest !== null && searchRequestId === activeSearchRequest.id)
				) {
					dispatch(setActiveSearchRequest(data));
				}

				const activeSearchRequestType = activeSearchRequestTypeSelector(state);

				if (
					projectFromProgressWatch !== undefined &&
					selectedProject !== null &&
					selectedProject?.id === projectToBeSet?.id &&
					activeSearchRequestType === searchDetails.type
				) {
					dispatch(addOrUpdateSearchRequestToTheList(data));
				}

				dispatch(setSearchRequestDetailsLoading(false));

				return data;
			}

			dispatch(setSearchRequestDetailsLoading(false));

			return null;
		} catch (e) {
			dispatch(setSearchRequestDetailsLoading(false));

			console.log(`Error from getting all Profile request: ${e}`);

			return null;
		}
	};

export const getSearchRequestedProfilesByQuery =
	(
		payload: Omit<ICandidateSearchApiRequestPayload, "pagination">,
		page = 1,
		onlyCountCheck = false,
		perPage?: number,
	) =>
	async (dispatch: Dispatch<TAppDispatch>, getState: TRootState): Promise<number | string> => {
		const requestKey = `GET_SEARCH_REQUESTED_RESULTS${
			payload?.filters?.found_by_search_request
		}${onlyCountCheck ? "_COUNT" : ""}`;

		const state = getState();

		const globalPerPageValue = globalPerPageSelector(state);

		if (requestKey in cancelTokens) {
			cancelTokens[requestKey].cancel("Operation canceled due to new request.");
		}

		cancelTokens[requestKey] = axios.CancelToken.source();

		const config = {
			cancelToken: cancelTokens[requestKey].token,
		};

		let response;

		try {
			const per_page = onlyCountCheck ? 1 : globalPerPageValue ?? perPage ?? 20;

			const apiPayload: ICandidateSearchApiRequestPayload = {
				...payload,
				pagination: {
					page,
					per_page,
				},
			};

			response = await mapXCandidateApi.getCandidatesByFilter(apiPayload, config);

			if (successResponse(response, 200)) {
				if (!onlyCountCheck) {
					dispatch(setAPActiveResult(response.data));
				}

				if (response.data.results.length > 0) {
					return response.data.pagination.count;
				}
			} else if (response === undefined) {
				return "cancelled";
			}

			return "unknown-error";
		} catch (e) {
			console.log(`Error from getting quick search Profiles: ${e}`);

			return "error";
		}
	};
