import {CreditCardIcon} from "assets/icons";
import classNames from "classnames";
import {sortArrayOfObjectsAlphabetically} from "helpers/array";
import {useAppDispatch, useAppSelector} from "hooks";
import {Accordion, GeneralButton} from "mapx-components";
import InfoTooltip from "mapx-pages/Home/LayoutHeader/InfoTooltip";
import React, {useCallback, useMemo, useState} from "react";
import {toast} from "react-toastify";
import {
	activeProjectResultsTabContentSelector,
	activeSearchRequestSelector,
	allSearchRequestsSelector,
	prCompaniesSelector,
	prFiltersSelector,
	prJobTitleSuggestionLoadingSelector,
	prSelectedJobTitlesSelector,
} from "store/mapx/additional-profiles/additionalProfilesSelectors";
import {createProfileRequest} from "store/mapx/additional-profiles/profileRequestAsyncActions";
import {
	targetListCandidatesSelector,
	targetListCompaniesSelector,
} from "store/mapx/target-list/targetListSelectors";
import {
	userAvailableCreditSelector,
	userCreditRefreshSelector,
	userLicenseTypeNameSelector,
} from "store/mapx/user/userSelectors";
import styles from "./styles.module.scss";
import {SICandidate} from "api/candidateApi/types";

import {
	TSearchRequestDetails,
	TSearchRequestMiniResponse,
} from "api/projectApi/searchRequestApi/types";
import {TProjectsResultsTabContentTypes} from "mapx-components/SearchRequestResults/FilterBlock/types";
import APCompaniesFilter from "./CompaniesFilter";
import APCandidatesFilter from "./CandidatesFilter";
import APLocationFilter from "./LocationFilter";
import APAdvanceFilter from "./AIMatchSettingsFilter";
import SidebarTabs from "mapx-components/SearchRequestResults/FilterBlock/SidebarTabs";
import {projectSelector} from "store/mapx/project-list/projectListSelectors";
import filterBlockStyles from "mapx-components/SearchRequestResults/FilterBlock/styles.module.scss";
import FindIcon from "assets/icons/IconFind";

const ProfileRequestConfigurator = () => {
	const dispatch = useAppDispatch();

	const targetListCandidates = useAppSelector(targetListCandidatesSelector);

	const targetListCompanies = useAppSelector(targetListCompaniesSelector);

	const profileRequestFilters = useAppSelector(prFiltersSelector);

	const prCompanies = useAppSelector(prCompaniesSelector);

	const prSelectedJobTitles = useAppSelector(prSelectedJobTitlesSelector);

	const isPRSaving = useAppSelector(prJobTitleSuggestionLoadingSelector);

	const allSearchRequests: TSearchRequestMiniResponse[] =
		useAppSelector(allSearchRequestsSelector);

	const activeSearchRequest: TSearchRequestDetails = useAppSelector(activeSearchRequestSelector);

	const userCreditRefreshInfo = useAppSelector(userCreditRefreshSelector);

	// const userCreditRefreshSelect = useAppSelector(userCreditRefreshSelector);

	const userLicenseTypeName = useAppSelector(userLicenseTypeNameSelector);

	const userAvailableCredit = useAppSelector(userAvailableCreditSelector) || 5000;

	// const userMaxAvailableCredit = useAppSelector(userMaxAvailableCreditSelector);

	const activeContent: TProjectsResultsTabContentTypes = useAppSelector(
		activeProjectResultsTabContentSelector,
	);

	const project = useAppSelector(projectSelector);

	const lockFilterForFreemium = useMemo(
		() => userLicenseTypeName === "Starter",
		[userLicenseTypeName],
	);

	const [prLoading, setPrLoading] = useState(false);

	const aiSearchIsInProgress = useMemo(() => {
		return (
			allSearchRequests.filter((sr) => sr.status === "In Progress")?.length > 0 &&
			activeContent !== "profile_request"
		);
	}, [activeContent, allSearchRequests]);

	const handleLunchSearchClick = useCallback(async () => {
		setPrLoading(true);

		toast.dismiss();

		await dispatch(createProfileRequest());

		setPrLoading(false);
	}, [dispatch]);

	// @TODO: Needs refactoring here with types
	const filters = useMemo(() => {
		const companiesWithCandidatesCount =
			targetListCompanies?.length > 0
				? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
				  // @ts-ignore
				  Object.assign(...targetListCompanies.map((C) => ({[C.id]: 0})))
				: [];

		let updatedTargetListCandidates: SICandidate[] = [];

		const candidates = sortArrayOfObjectsAlphabetically(
			[...targetListCandidates],
			"first_name",
		);

		for (const candidate of candidates) {
			const positions = candidate?.positions || candidate?.experience;
			const recentExp = positions?.length ? positions[0] : null;

			if (recentExp !== null && recentExp?.company) {
				candidate.company = [
					{
						name: `${recentExp?.company?.name}`,
						id: recentExp.company.id,
						logo_url: recentExp.company.logo_url,
					},
				]; // this added for searching by company name
			}

			updatedTargetListCandidates = [...updatedTargetListCandidates, candidate];

			if (Array.isArray(positions)) {
				for (const exp of positions) {
					if (
						exp?.company?.id &&
						exp?.company?.id?.toString() in companiesWithCandidatesCount
					) {
						companiesWithCandidatesCount[exp.company.id] =
							companiesWithCandidatesCount[exp.company.id] + 1;
						break; // exit for this candidate
					}
				}
			}
		}

		const companies = targetListCompanies.map(
			(company: {candidates_count: number; id: number}) => {
				company.candidates_count = companiesWithCandidatesCount[company.id] || 0;

				return company;
			},
		);

		return [
			{
				name: "1. Select Companies *",
				key: "company_ids",
				type: "profile_requests",
				filter: <APCompaniesFilter options={companies} />,
				disabled: !project,
			},
			{
				name: "2. Select Profiles & Job Titles *",
				key: "selected_job_titles",
				type: "profile_requests",
				filter: <APCandidatesFilter options={updatedTargetListCandidates} />,
				disabled: !project,
			},
			{
				name: "3. Add a Location",
				key: "country_ids",
				type: "profile_requests",
				filter: <APLocationFilter />,
				disabled: lockFilterForFreemium || !project,
				displayLock: lockFilterForFreemium,
			},
			{
				name: "4. AI Matching Settings",
				key: "search_width",
				type: "profile_requests",
				filter: <APAdvanceFilter />,
				disabled: !project,
			},
		];
	}, [targetListCompanies, targetListCandidates, lockFilterForFreemium, project]);

	const displayPRValidationWarning = useMemo(
		() =>
			targetListCompanies?.length === 0 ||
			prCompanies?.length === 0 ||
			prSelectedJobTitles?.length === 0,
		[prCompanies?.length, targetListCompanies?.length, prSelectedJobTitles?.length],
	);

	const userHasAPRAccess = useMemo(() => userAvailableCredit > 0, [userAvailableCredit]);

	return (
		<div className={styles.container}>
			<div
				className={classNames(styles.aiWebSearch, {
					[filterBlockStyles.inProgress]: activeSearchRequest?.status === "In Progress",
				})}
			>
				<div className={styles.title}>
					<FindIcon /> AI Web Search
				</div>
			</div>

			<div
				className={classNames(styles.filterBlock, styles.prFilter, {
					[styles.inProgress]: aiSearchIsInProgress,
				})}
			>
				{(project === null ||
					(activeContent === "profile_request" &&
						activeSearchRequest?.status !== "In Progress")) &&
					filters.map((item) => {
						let subtitle =
							profileRequestFilters[item.key] !== undefined &&
							profileRequestFilters[item.key]?.length;

						if (item.key === "search_width") {
							subtitle =
								profileRequestFilters[item.key] === "Narrow" ? "Exact" : "Close";
						}

						return (
							<Accordion
								key={`${item.type}-${item.name}-${item.key}`}
								title={item.name}
								subtitle={subtitle}
								disabled={item?.disabled}
								displayLock={item?.displayLock}
							>
								{item.filter}
							</Accordion>
						);
					})}

				{activeContent === "profile_request" && (
					<div className={styles.buttonBlock}>
						{displayPRValidationWarning && (
							<span className={styles.infoText}>
								Please select at least 1 option from the
								<span> companies</span>, <span>job titles</span> filter section
							</span>
						)}

						<div className={styles.actionButton} data-tooltip-id="outOfCredit">
							<div className={styles.aiCreditInformation}>
								<div style={{display: "flex", gap: 8}}>
									<CreditCardIcon />

									<span style={{color: "#4E5555"}}>Remaining AI Credits</span>
								</div>

								<div style={{display: "flex", gap: 8}}>
									<div className={styles.creditsInformation}>
										<span>{userAvailableCredit}</span>
										{/*<span style={{color: "#4E5555"}}>*/}
										{/*	/{userMaxAvailableCredit}*/}
										{/*</span>*/}
									</div>

									{/*<span style={{color: "#4E5555", cursor: "pointer"}}>*/}
									{/*    <HelpIcon dataTooltipId="infoTooltip"/>*/}

									{/*    <InfoTooltip*/}
									{/*        id="infoTooltip"*/}
									{/*        nextRefresh={userCreditRefreshSelect}*/}
									{/*        creditLeftHandler={undefined}*/}
									{/*        nextRefreshHandler={undefined}*/}
									{/*        creditLeft={`${Number(userAvailableCredit)}`}*/}
									{/*    />*/}
									{/*</span>*/}
								</div>
							</div>

							<GeneralButton
								loadingSave={prLoading}
								handleClick={handleLunchSearchClick}
								title={userHasAPRAccess ? "Launch search" : "Out of Credits"}
								customClass={classNames(styles.lunchButton, {
									[styles.lunchButton__out_of_credit]: !userHasAPRAccess,
								})}
								disabled={
									!userHasAPRAccess ||
									displayPRValidationWarning ||
									isPRSaving ||
									prLoading ||
									activeSearchRequest?.status === "In Progress"
								}
							/>
						</div>

						{!userHasAPRAccess && (
							<InfoTooltip
								id="outOfCredit"
								title={"Out of Credits"}
								tooltipFor={"apr_configurator"}
								nextRefresh={userCreditRefreshInfo}
							/>
						)}
					</div>
				)}
			</div>

			<SidebarTabs isDisplayingMatchingTo={false} />
		</div>
	);
};

export default ProfileRequestConfigurator;
