import React from "react";
import classNames from "classnames";
import styles from "../../Projects/Loader/styles.module.scss";
import ContentLoader from "react-content-loader";

const MarketReportDetailsSkeletonLoader = () => {
	return (
		<div
			className={classNames(styles.skeletonContainer)}
			data-tooltip={"Loading market report details..."}
		>
			<div className={classNames(styles.skeletonContainer__card)}>
				<ContentLoader
					speed={2}
					height={150}
					width={"100%"}
					backgroundColor="#DCE7E7"
					foregroundColor="#F9F9F9"
				>
					<rect x="24" y="10" rx="3" ry="3" width="200" height="24" />
					<rect x="90%" y="10" rx="3" ry="3" width="60" height="24" />

					<rect x="0" y="60" rx="3" ry="3" width="100%" height="1" />

					<rect x="24" y="84" rx="3" ry="3" width="48" height="48" />
					<rect x="88" y="84" rx="3" ry="3" width="90" height="20" />
					<rect x="88" y="112" rx="3" ry="3" width="150" height="20" />

					<rect x="318" y="84" rx="3" ry="3" width="48" height="48" />
					<rect x="382" y="84" rx="3" ry="3" width="61" height="20" />
					<rect x="382" y="112" rx="3" ry="3" width="150" height="20" />

					<rect x="612" y="84" rx="3" ry="3" width="48" height="48" />
					<rect x="676" y="84" rx="3" ry="3" width="157" height="20" />
					<rect x="676" y="112" rx="3" ry="3" width="206" height="20" />

					<rect x="906" y="84" rx="3" ry="3" width="48" height="48" />
					<rect x="970" y="84" rx="3" ry="3" width="59" height="20" />
					<rect x="970" y="112" rx="3" ry="3" width="171" height="20" />
				</ContentLoader>
			</div>
		</div>
	);
};

export default MarketReportDetailsSkeletonLoader;
