import {TLinkedInUrlImportItem} from "mapx-components/AddPeopleByLinkedIn/types";

export const STATUS_COLOR_MAP: {[key: string]: string} = {
	success: "#0C5850FF",
	imported: "#0C5850FF",
	completed: "#0C5850FF",
	"already exists": "#0C5850FF",
	"in progress": "#444444",
	created: "#444444",
	scraped: "#444444",
	"import error": "#F5A300",
	error: "#F5A300",
	"unknown error": "#F5A300",
	"bad url": "#F5A300",
	"not found": "#F5A300",
	"scraping error": "#F5A300",
};

export const STATUS_MESSAGES: {[key: string]: string} = {
	created: "Processing...",
	scraped: "Scraped...",
	imported: "Success",
	completed: "Success",
	"already exists": "Success",
	"in progress": "Processing...",
	"import error": "Error",
	"scraping error": "Error",
	"bad url": "Not a valid URL",
	"not found": "Not a valid URL",
};

export const CalculateStatusTypeCounts = (linkedInUrl?: TLinkedInUrlImportItem[]) => {
	const statusCounts = {
		success: 0,
		error: 0,
		badURL: 0,
		notFound: 0,
		finished: 0,
	};

	linkedInUrl?.forEach(({status}) => {
		const lowerStatus = status?.toLowerCase();
		if (["success", "imported", "completed", "already exists"].includes(lowerStatus)) {
			statusCounts.success++;
			statusCounts.finished++;
		} else if (["bad url", "not found", "scraping error"].includes(lowerStatus)) {
			lowerStatus === "bad url" ? statusCounts.badURL++ : statusCounts.notFound++;
			lowerStatus === "scraping error" && statusCounts.error++;
			statusCounts.finished++;
		} else {
			statusCounts.error++;
			if (["import error"].includes(lowerStatus)) {
				statusCounts.finished++;
			}
		}
	});

	return statusCounts;
};
