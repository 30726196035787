import {Dispatch} from "react";
import {TAppDispatch, TRootState} from "types";
import {
	fetchingProjectCandidateTrackLeavers,
	fetchingProjectCandidateTrackNoRecentChanges,
	fetchingProjectCandidateTrackPromotions,
	setProjectCandidateTrackLeavers,
	setProjectCandidateTrackNoRecentChanges,
	setProjectCandidateTrackPromotions,
} from "store/mapx/project/projectActions";
import {getCancelToken} from "api/cancelTokens";
import {
	GET_PROJECT_TRACK_LEAVERS_PEOPLE_TOKEN,
	GET_PROJECT_TRACK_NO_RECENT_CHANGES_PEOPLE_TOKEN,
	GET_PROJECT_TRACK_PROMOTION_PEOPLE_TOKEN,
} from "api/requestCancelTokenStrings";
import {successResponse} from "helpers/map";
import axios from "axios";
import {projectSelector} from "store/mapx/project-list/projectListSelectors";
import candidateTracking from "api/projectApi/candidateTracking";
import {ATPagination, STPaginationParam} from "api/types";
import {
	projectCandidateTrackLeaversPaginationSelector,
	projectCandidateTrackNoRecentChangesPaginationSelector,
	projectCandidateTrackPromotionsPaginationSelector,
	selectedTrackCandidatePositionChangedDaysSelector,
} from "store/mapx/project/projectCandidateTrackingSelectors";
import {STCandidateAPIResponse} from "api/candidateApi/types";

/**
 * Asynchronous action to fetch project track candidate leavers.
 *
 * This function dispatches an action to indicate the fetching process
 * and retrieves the list of candidates who have left a project within
 * a specified number of days. It uses the project ID and position change
 * days selected in the state to fetch data from the API. If the response
 * is successful, it dispatches an action to update the state with the
 * retrieved data. If the request fails or is canceled, it dispatches
 * an action to indicate the fetching process has ended.
 *
 * @param pagination - Pagination parameters for the API request.
 * @returns A thunk action that performs the API call and handles the response.
 */

export const getProjectTrackCandidateLeavers =
	(pagination?: STPaginationParam) =>
	async (dispatch: Dispatch<TAppDispatch>, getState: TRootState) => {
		try {
			const state = getState();

			const project = projectSelector(state);

			const selectedTrackCandidatePositionChangedDays =
				selectedTrackCandidatePositionChangedDaysSelector(state);

			const leaversPagination: ATPagination =
				projectCandidateTrackLeaversPaginationSelector(state);

			const finalPagination = {
				page: pagination?.page ?? leaversPagination.page ?? 1,
				per_page: pagination?.per_page ?? 20,
			};

			dispatch(fetchingProjectCandidateTrackLeavers(true));

			const response = await candidateTracking.projectTrackCandidateLeavers(
				project.id,
				selectedTrackCandidatePositionChangedDays,
				finalPagination,
				{
					cancelToken: getCancelToken(GET_PROJECT_TRACK_LEAVERS_PEOPLE_TOKEN).token,
				},
			);

			if (successResponse(response, 200)) {
				const data: STCandidateAPIResponse = response.data;

				dispatch(setProjectCandidateTrackLeavers(data));
			} else {
				dispatch(fetchingProjectCandidateTrackLeavers(false));
			}
		} catch (error) {
			if (axios.isCancel(error)) {
				console.log("Request canceled:", error.message);
			} else {
				console.log(error);
			}

			dispatch(fetchingProjectCandidateTrackLeavers(false));
		}
	};

/**
 * A thunk action that fetches people who have been promoted within a project,
 * based on the given pagination parameters.
 *
 * If the request is successful, it dispatches an action to store the retrieved
 * data. If the request fails or is canceled, it dispatches an action to indicate
 * the fetching process has ended.
 *
 * @param pagination - Pagination parameters for the API request.
 * @returns A thunk action that performs the API call and handles the response.
 */

export const getProjectTrackCandidatePromotions =
	(pagination?: STPaginationParam) =>
	async (dispatch: Dispatch<TAppDispatch>, getState: TRootState) => {
		try {
			const state = getState();

			const project = projectSelector(state);

			const selectedTrackCandidatePositionChangedDays =
				selectedTrackCandidatePositionChangedDaysSelector(state);

			const leaversPagination: ATPagination =
				projectCandidateTrackPromotionsPaginationSelector(state);

			const finalPagination = {
				page: pagination?.page ?? leaversPagination.page ?? 1,
				per_page: pagination?.per_page ?? 20,
			};

			dispatch(fetchingProjectCandidateTrackPromotions(true));

			const response = await candidateTracking.projectTrackCandidatePromotions(
				project.id,
				selectedTrackCandidatePositionChangedDays,
				finalPagination,
				{
					cancelToken: getCancelToken(GET_PROJECT_TRACK_PROMOTION_PEOPLE_TOKEN).token,
				},
			);

			if (successResponse(response, 200)) {
				const data: STCandidateAPIResponse = response.data;

				dispatch(setProjectCandidateTrackPromotions(data));
			} else {
				dispatch(fetchingProjectCandidateTrackPromotions(false));
			}
		} catch (error) {
			if (axios.isCancel(error)) {
				console.log("Request canceled:", error.message);
			}
			dispatch(fetchingProjectCandidateTrackPromotions(false));
		}
	};

/**
 * Asynchronous action to fetch project track candidates with no recent changes.
 *
 * This function dispatches an action to indicate the fetching process
 * and retrieves the list of candidates who have not changed positions
 * within a specified number of days for a given project. It uses the
 * project ID and position change days selected in the state to fetch
 * data from the API. If the response is successful, it dispatches an
 * action to update the state with the retrieved data. If the request
 * fails or is canceled, it dispatches an action to indicate the fetching
 * process has ended.
 *
 * @param pagination - Pagination parameters for the API request.
 * @returns A thunk action that performs the API call and handles the response.
 */

export const getProjectTrackCandidateNoRecentChanges =
	(pagination?: STPaginationParam) =>
	async (dispatch: Dispatch<TAppDispatch>, getState: TRootState) => {
		try {
			const state = getState();

			const project = projectSelector(state);

			const selectedTrackCandidatePositionChangedDays =
				selectedTrackCandidatePositionChangedDaysSelector(state);

			const leaversPagination: ATPagination =
				projectCandidateTrackNoRecentChangesPaginationSelector(state);

			const finalPagination = {
				page: pagination?.page ?? leaversPagination.page ?? 1,
				per_page: pagination?.per_page ?? 20,
			};

			dispatch(fetchingProjectCandidateTrackNoRecentChanges(true));

			const response = await candidateTracking.projectTrackCandidateNoRecentChanges(
				project.id,
				selectedTrackCandidatePositionChangedDays,
				finalPagination,
				{
					cancelToken: getCancelToken(GET_PROJECT_TRACK_NO_RECENT_CHANGES_PEOPLE_TOKEN)
						.token,
				},
			);

			if (successResponse(response, 200)) {
				const data: STCandidateAPIResponse = response.data;

				dispatch(setProjectCandidateTrackNoRecentChanges(data));
			} else {
				dispatch(fetchingProjectCandidateTrackNoRecentChanges(false));
			}
		} catch (error) {
			if (axios.isCancel(error)) {
				console.log("Request canceled:", error.message);
			}
			dispatch(fetchingProjectCandidateTrackNoRecentChanges(false));
		}
	};
