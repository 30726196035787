import styles from "mapx-pages/Project/project.module.scss";
import {CompaniesSkeletonLoader, CompanyCard} from "mapx-components";
import {useAppDispatch, useAppSelector} from "hooks";
import {STCompany} from "api/companyApi/types";
import Pagination from "mapx-components/Pagination";
import {useEffect} from "react";
import {cancelRequest} from "api/cancelTokens";
import {GET_PAGINATED_TARGETLIST_COMPANIES_TOKEN} from "api/requestCancelTokenStrings";
import {
	fetchProjectSavedCompanyIDs,
	getPaginatedProjectSavedCompanies,
} from "store/mapx/project/projectCompaniesAsyncActions";
import {
	projectSavedCompaniesSelector,
	projectSavedCompaniesInProgressSelector,
	projectSavedCompanyPaginationSelector,
} from "store/mapx/project/projectSelectors";
import {setPerPagePreference} from "store/mapx/userPreference/userPreferenceActions";
import {globalPerPageSelector} from "store/mapx/userPreference/userPreferenceSelectors";

const SavedCompanies = () => {
	const dispatch = useAppDispatch();

	const savedCompanies: STCompany[] = useAppSelector(projectSavedCompaniesSelector);

	const companiesFetchInProgress = useAppSelector(projectSavedCompaniesInProgressSelector);

	const pagination = useAppSelector(projectSavedCompanyPaginationSelector);

	const globalPerPageValue = useAppSelector(globalPerPageSelector);

	useEffect(() => {
		dispatch(getPaginatedProjectSavedCompanies());
		dispatch(fetchProjectSavedCompanyIDs());

		return () => {
			cancelRequest(GET_PAGINATED_TARGETLIST_COMPANIES_TOKEN);
		};
	}, [dispatch]);

	function onPageChange(page: number) {
		dispatch(
			getPaginatedProjectSavedCompanies({
				page: page,
				per_page: globalPerPageValue,
			}),
		);
	}

	const onPerPageChange = async (count: number) => {
		dispatch(setPerPagePreference(count));

		dispatch(
			getPaginatedProjectSavedCompanies({
				page: pagination.page,
				per_page: count,
			}),
		);
	};

	return (
		<div className={styles.listedItems}>
			{companiesFetchInProgress && <CompaniesSkeletonLoader count={5} />}

			{!companiesFetchInProgress &&
				savedCompanies?.map((company, index) => {
					return <CompanyCard key={index} company={company} type="project" />;
				})}

			{!companiesFetchInProgress && pagination && pagination?.count >= 10 && (
				<div className={styles.paginationWrapper}>
					<Pagination
						currentPage={pagination?.page}
						resultsCount={pagination?.count}
						totalPages={pagination?.pages}
						perPage={globalPerPageValue}
						onPageChange={onPageChange}
						onPerPageChange={onPerPageChange}
						scrollDelayAfterPageChange={400}
					/>
				</div>
			)}

			{!savedCompanies?.length && !companiesFetchInProgress && (
				<span>
					No companies to display here. You can import companies from market maps.
				</span>
			)}
		</div>
	);
};

export default SavedCompanies;
