import {type FC, useEffect} from "react";

import {useAppSelector} from "hooks";
import {userLicenseTypeNameSelector} from "store/mapx/user/userSelectors";

import Locations from "mapx-pages/Project/Insights/Locations";
// import {HeadcountChart} from "./HeadcountChart";
import ProjectFreqHiredFrom from "./ProjectFreqHiredFrom";
import {GenderDiversityChart} from "./GenderDiversityChart";
// import {CompanyRevenueChart} from "./CompanyRevenueChart";
import {EthnicDiversityChart} from "./EthniticDiversityChart";
import UserPreloadInsightSkeleton from "./UserPreloadInsightSkeleton";
import JobFunctionDistributionChart from "./JobFunctionDistributionChart";
import styles from "./styles.module.scss";
import {cancelRequest} from "api/cancelTokens";
import {
	PROJECT_DIVERSITY_REPORT_TOKEN,
	PROJECT_ETHNICITY_REPORT_TOKEN,
	PROJECT_FREQ_HIRED_FROM_REPORT_TOKEN,
	PROJECT_INDUSTRIES_BACKGROUND_REPORT_TOKEN,
	PROJECT_JOB_FUNCTION_REPORT_TOKEN,
	PROJECT_LOCATION_REPORT_TOKEN,
} from "api/requestCancelTokenStrings";

export const Insights: FC = () => {
	const licenseName = useAppSelector(userLicenseTypeNameSelector);

	useEffect(() => {
		return () => {
			cancelRequest(PROJECT_DIVERSITY_REPORT_TOKEN);
			cancelRequest(PROJECT_ETHNICITY_REPORT_TOKEN);
			cancelRequest(PROJECT_LOCATION_REPORT_TOKEN);
			cancelRequest(PROJECT_FREQ_HIRED_FROM_REPORT_TOKEN);
			cancelRequest(PROJECT_JOB_FUNCTION_REPORT_TOKEN);
			cancelRequest(PROJECT_INDUSTRIES_BACKGROUND_REPORT_TOKEN);
		};
	}, []);

	return (
		<div className={styles.wrapper}>
			{licenseName === "Starter" ? (
				<UserPreloadInsightSkeleton />
			) : (
				<div className={styles.insight_container}>
					<div className={styles.insight_container__genders}>
						<GenderDiversityChart />
						<EthnicDiversityChart />
					</div>
					<Locations />

					<JobFunctionDistributionChart />

					<ProjectFreqHiredFrom />

					{/* <CompanyRevenueChart />
        <HeadcountChart /> */}
				</div>
			)}
		</div>
	);
};
