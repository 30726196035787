import HttpClient from "api/index";
import {
	TProjectCandidateDeleteForm,
	TProjectCandidatesStatusFormBody,
	TUpdateProjectCandidateInfoForm,
} from "api/projectApi/projectCandidatesApi/types";

class ProjectCandidateApi extends HttpClient {
	async updateCandidateInfoByCandidateId(
		projectId: number,
		candidateId: number,
		formBody: TUpdateProjectCandidateInfoForm,
		config = {},
	) {
		return this.doPatch(`/projects/${projectId}/candidates/${candidateId}`, formBody, config);
	}

	async updateProjectCandidatesStatus(
		projectId: number,
		formBody: TProjectCandidatesStatusFormBody,
		config = {},
	) {
		return this.doPatch(`/projects/${projectId}/candidates`, formBody, config);
	}

	async deleteProjectCandidatesStatus(projectId: number, formBody: TProjectCandidateDeleteForm) {
		return this.doDelete(`/projects/${projectId}/candidates`, formBody);
	}
}

const projectCandidateApi = new ProjectCandidateApi();

export default projectCandidateApi;
