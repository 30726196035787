import {useCallback} from "react";
import {Accordion} from "mapx-components";
import SenioritySearchFilter from "mapx-components/Filters/SenioritySearchFilter";
import {useAppDispatch, useAppSelector} from "hooks";
import {TMarketReportForm} from "api/marketReportApi/types";
import {setMarketReportFilterValueInForm} from "store/mapx/market-report/marketReportActions";
import {getMarketReportFormSelector} from "store/mapx/market-report/marketReportSelector";

function SeniorityFilter() {
	const dispatch = useAppDispatch();

	const marketReportForm: TMarketReportForm = useAppSelector(getMarketReportFormSelector);

	const selectedSeniority = marketReportForm.filters.current_seniority;

	const handleChange = useCallback(
		(id: string) => {
			const updatedSeniority = selectedSeniority.includes(id)
				? selectedSeniority.filter((seniority) => seniority !== id)
				: [...selectedSeniority, id];

			dispatch(
				setMarketReportFilterValueInForm({
					key: "current_seniority",
					value: updatedSeniority,
				}),
			);
		},
		[dispatch, selectedSeniority],
	);

	const handleResetClick = useCallback(() => {
		dispatch(
			setMarketReportFilterValueInForm({
				key: "current_seniority",
				value: [],
			}),
		);
	}, [dispatch]);

	return (
		<Accordion
			title="Seniority"
			subtitle={selectedSeniority.length}
			// tooltipId={"seniority_filter_hover"}
		>
			<SenioritySearchFilter
				handleOnChange={handleChange}
				handleResetClick={handleResetClick}
				selectedSeniority={selectedSeniority}
			/>
		</Accordion>
	);
}

export default SeniorityFilter;
