import {useCallback, useMemo} from "react";
import {useSelector} from "react-redux";
import {useAppDispatch} from "hooks";
import StatusSearchFilter from "mapx-components/Filters/StatusSearchFilter";
import {TStatusCountItem, TStatusItem} from "mapx-components/Filters/StatusSearchFilter/types";
import {setAPMultipleFilters} from "store/mapx/additional-profiles/additionalProfilesActions";
import {apCandidateStatusesSelector} from "store/mapx/additional-profiles/additionalProfilesSelectors";
import {projectCandidatesStatusesSelector} from "store/mapx/project/projectSelectors";

function StatusFilterForAP() {
	const dispatch = useAppDispatch();

	const selectedStatusIds = useSelector(apCandidateStatusesSelector);

	const projectCandidateStatusCount = useSelector(projectCandidatesStatusesSelector);

	const handleChange = useCallback(
		(item: TStatusItem) => {
			const index = selectedStatusIds.indexOf(item.id);
			const alreadySelected = index !== -1;

			const nextItems = alreadySelected
				? [...selectedStatusIds.slice(0, index), ...selectedStatusIds.slice(index + 1)]
				: [...selectedStatusIds, item.id];

			dispatch(setAPMultipleFilters({progress_status_ids: nextItems}));
		},
		[dispatch, selectedStatusIds],
	);

	const handleReset = useCallback(() => {
		dispatch(setAPMultipleFilters({progress_status_ids: []}));
	}, [dispatch]);

	const processedCandidateStatusCountData = useMemo(() => {
		const secondaryCount =
			projectCandidateStatusCount?.find(
				(item: TStatusCountItem) => item.status === "secondary",
			)?.count || 0;

		const recommendedCount =
			projectCandidateStatusCount?.find(
				(item: TStatusCountItem) => item.status === "recommended",
			)?.count || 0;

		const filteredData = projectCandidateStatusCount?.filter(
			(item: TStatusCountItem) => item.status !== "recommended",
		);

		return [...filteredData, {count: secondaryCount + recommendedCount, status: "No Status"}];
	}, [projectCandidateStatusCount]);

	return (
		<StatusSearchFilter
			handleOnChange={handleChange}
			handleResetClick={handleReset}
			selectedStatuses={selectedStatusIds}
			candidateStatusCount={processedCandidateStatusCountData}
		/>
	);
}

export default StatusFilterForAP;
