import React, {type FC, Fragment, useState} from "react";
import {noop} from "lodash";

import {CompanyInfoTooltip, RoundedButton} from "components";
import IconUsers from "assets/icons/IconUsers";

import type {TIndustryCardProps} from "./types";
import styles from "./industryCard.module.scss";
import {STSingleCompany} from "api/companyApi/types";
import LazyLoadImageWrapper from "components/LazyLoadImageWrapper";
import {setAPCompany} from "store/mapx/additional-profiles/additionalProfilesAsyncActions";
import {useAppDispatch} from "hooks";
import classNames from "classnames";

const INITIAL_ITEMS_PER_PAGE = 15;

const IndustryCard: FC<TIndustryCardProps> = ({
	name,
	index,
	industry,
	setCompanyId,
	companyToWatch,
	currentCompanyId,
	setRemoveMouse = noop,
}) => {
	const dispatch = useAppDispatch();
	const [visibleCount, setVisibleCount] = useState(INITIAL_ITEMS_PER_PAGE);

	if (!name) return null;

	const mouseEnterHandler = (id: string) => {
		setRemoveMouse(true);
		setCompanyId(id);
	};

	const handleOnClick = (id: number) => {
		dispatch(setAPCompany({id}));
		window.open(`/company/${id}`, "_blank");
	};

	const handleSeeMore = (count: number) => {
		setVisibleCount((prev) => prev + count);
	};

	const renderIndustry = industry?.companies
		?.slice(0, visibleCount)
		.map((company: STSingleCompany, index: number) => (
			<span
				data-tooltip-delay-show={1000}
				key={`${company?.id}-${index}`}
				className={styles.companyContainer}
				onMouseOut={() => setRemoveMouse(false)}
				onBlur={() => setRemoveMouse(false)}
				data-tooltip-id={String(currentCompanyId)}
				onMouseEnter={() => mouseEnterHandler(String(company?.id))}
				onClick={() => handleOnClick(company?.id)}
			>
				<div
					onMouseOut={() => setRemoveMouse(false)}
					onBlur={() => setRemoveMouse(false)}
					className={styles.companyContainer__skeleton}
					onMouseEnter={() => mouseEnterHandler(String(company?.id))}
				/>
				<LazyLoadImageWrapper
					onMouseEnter={() => mouseEnterHandler(String(company?.id))}
					onMouseOut={() => setRemoveMouse(false)}
					className={styles.logo}
					image={
						company?.logo_url
							? company?.logo_url
							: "https://storage.googleapis.com/nebula-static/logos100x100/empty_logo.png"
					}
					alt={company?.name}
					style={{background: `url(${company.logo_url})`}}
				/>
				<span className={styles.companyName}>{company?.name}</span>

				{company?.candidates_count > 0 && (
					<span className={styles.candidateCount}>
						<IconUsers />
						<span className={styles.count}>{company?.candidates_count}</span>
					</span>
				)}
			</span>
		));

	return (
		<Fragment>
			<span className={styles.cardContainer}>
				<span className={styles.titleContainer}>
					{name}
					<span className={styles.industryCandidateCount}>
						{industry?.candidates_count !== 0 && <IconUsers />}
						{industry?.candidates_count || null}
					</span>
				</span>

				<span
					className={classNames(styles.contentContainer, {
						[styles.scrollableContentContainer]: industry?.companies?.length > 15,
					})}
				>
					{renderIndustry}
					{visibleCount < (industry?.companies?.length || 0) && (
						<div className={styles.showMore}>
							<RoundedButton
								className={styles.showMoreButton}
								onClick={() => handleSeeMore(50)}
							>
								Show More
							</RoundedButton>
						</div>
					)}
				</span>
			</span>

			{index === 0 && (
				<CompanyInfoTooltip id={String(currentCompanyId)} company={companyToWatch} />
			)}
		</Fragment>
	);
};

export default IndustryCard;
