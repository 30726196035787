class ProjectTabs {
	static overview = 0;
	static saved_people = 1;
	static saved_companies = 2;
	static insights = 3;
	static tracking_people = 4;
	static share = 5;

	static get overviewTabIndex() {
		return this.overview;
	}

	static get savedPeopleTabIndex() {
		return this.saved_people;
	}

	static get savedCompaniesTabIndex() {
		return this.saved_companies;
	}

	static get insightsTabIndex() {
		return this.insights;
	}

	static get trackingTabIndex() {
		return this.tracking_people;
	}

	static get shareTabIndex() {
		return this.share;
	}
}

export default ProjectTabs;
