import styles from "../Diversity.module.scss";
import React, {useCallback, useEffect, useMemo} from "react";
import {TextTabPane} from "mapx-components";
import {TDiversityStatsSelectedType} from "store/mapx/market-report/types";
import classNames from "classnames";
import PieChart from "components/Charts/PieChart";
import {
	mrDiversityStatsLoadingSelector,
	mrDiversityStatsReportSelector,
	mrDiversityStatsSelectedTypeSelector,
} from "store/mapx/market-report/marketReportSelector";
import {useAppDispatch, useAppSelector} from "hooks";
import {setDiversityStatsSelectedType} from "store/mapx/market-report/marketReportActions";
import {transformToPieChartData} from "helpers/array";
import {isEmptyObject} from "helpers/filterHandlers";

const diversityTabs: {id: TDiversityStatsSelectedType; title: "Gender" | "Ethnicity"}[] = [
	{id: "gender", title: "Gender"},
	{id: "ethnicity", title: "Ethnicity"},
];

const GenderDiversity = () => {
	const genderDiversityStats = useAppSelector(mrDiversityStatsReportSelector);

	const isLoading = useAppSelector(mrDiversityStatsLoadingSelector);

	const activeTabSelection: TDiversityStatsSelectedType = useAppSelector(
		mrDiversityStatsSelectedTypeSelector,
	);

	const dispatch = useAppDispatch();

	const handleTabChange = useCallback(
		(id: TDiversityStatsSelectedType) => {
			dispatch(setDiversityStatsSelectedType(id));
		},
		[dispatch],
	);

	const activeChartData = useMemo(() => {
		if (genderDiversityStats) {
			let data;
			if (activeTabSelection === "gender") {
				data = genderDiversityStats.gender;
			} else {
				data = genderDiversityStats.ethnicity;
			}

			return data && !isEmptyObject(data) ? transformToPieChartData(data) : [];
		} else {
			return [];
		}
	}, [genderDiversityStats, activeTabSelection]);

	const tabs = useMemo(() => {
		const GenderTab = (
			<TextTabPane title="Gender" key="gender">
				<div className={styles.tabContainer}>
					<div className={styles.chartContainer}>
						<h4 className={styles.barChartTitle}>
							Percentage of gender diversity in the market review
						</h4>

						<PieChart
							key="gender"
							data={activeChartData}
							isLoading={isLoading}
							innerRadius={50}
							pieChartClass={styles.customWrapper}
						/>
					</div>
				</div>
			</TextTabPane>
		);
		const EthnicityTab = (
			<TextTabPane title="Ethnicity" key="ethnicity">
				<div className={styles.chartContainer}>
					<h4 className={styles.barChartTitle}>
						Percentage of ethnicity in the market review
					</h4>

					<PieChart
						key="ethnicity"
						data={activeChartData}
						isLoading={isLoading}
						innerRadius={50}
						pieChartClass={styles.customWrapper}
					/>
				</div>
			</TextTabPane>
		);

		return {
			gender: GenderTab,
			ethnicity: EthnicityTab,
		};
	}, [activeChartData, isLoading]);

	useEffect(() => {
		if (!activeTabSelection) {
			handleTabChange("gender");
		}
	}, [activeTabSelection, handleTabChange]);

	const renderTabOptions = diversityTabs.map(({id, title}) => (
		<div
			key={id}
			onClick={() => handleTabChange(id)}
			className={classNames(styles.tabTitle, {
				[styles.activeTab]: activeTabSelection === id,
			})}
		>
			{title}
		</div>
	));

	return (
		<div className={styles.genderDiversity}>
			<div className={styles.titleSection}>
				<span>Gender Diversity</span>
			</div>

			<div className={styles.tabWrapper}>
				<div className={styles.tabContainer}>
					<div className={classNames(styles.tabs)}>{renderTabOptions}</div>
				</div>
			</div>

			{tabs[activeTabSelection]}
		</div>
	);
};

export default GenderDiversity;
