import {useMemo} from "react";
import {TStatusCountItem} from "mapx-components/Filters/StatusSearchFilter/types";

/**
 * Creates a memoized status count map from an array of status count items.
 * @param projectCandidateStatusCount - The array containing status counts.
 * @returns A memoized map of status names to their respective counts.
 */
export const useStatusCountMap = (projectCandidateStatusCount: TStatusCountItem[]) => {
	return useMemo(() => {
		return projectCandidateStatusCount?.reduce(
			(acc: Record<string, number>, item: TStatusCountItem) => {
				acc[item.status] = item.count;

				return acc;
			},
			{} as Record<string, number>,
		);
	}, [projectCandidateStatusCount]);
};
