import {TAppDispatch, TRootState} from "types";
import {
	IMarketReport,
	STDiversePathwaysMode,
	STDiversePathwaysReport,
	STDiversityStats,
	TCandidateFilterOptions,
	TCreateMarketReportForm,
} from "api/marketReportApi/types";
import marketReportApi from "api/marketReportApi";
import {successResponse} from "helpers/map";
import axios, {AxiosResponse} from "axios";
import {
	createMarketReportDone,
	createMarketReportFail,
	createMarketReportInit,
	deleteMarketReportDone,
	deleteMarketReportFail,
	deleteMarketReportInit,
	displayDiversityPathwaysLoading,
	displayDiversityStatsLoading,
	getMarketReportsDone,
	getMarketReportsFail,
	getMarketReportsInit,
	setDiversityPathwaysReport,
	setDiversityStatsReport,
	setMarketReportDetails,
	updateMarketReportDone,
	updateMarketReportFail,
	updateMarketReportInit,
} from "store/mapx/market-report/marketReportActions";
import {clearCancelToken, getCancelToken} from "api/cancelTokens";
import {
	GET_MARKET_DIVERSITY_PATHWAYS_REPORTS_TOKEN,
	GET_MARKET_DIVERSITY_STATS_REPORTS_TOKEN,
	GET_MARKET_REPORTS_TOKEN,
} from "api/requestCancelTokenStrings";
import {ATPagination} from "api/types";
import {TPaginationParam} from "api/candidateApi/types";
import {Dispatch} from "react";
import {
	prepareDateForFileNameSuffix,
	preparePowerpointFileAndDownload,
} from "helpers/documentHelpers";
import {userEmailSelector} from "store/mapx/user/userSelectors";
import {Mixpanel} from "helpers/mixpanel";
import {
	getMarketReportDetailsSelector,
	getMarketReportsPaginationSelector,
} from "store/mapx/market-report/marketReportSelector";
import {globalPerPageSelector} from "../userPreference/userPreferenceSelectors";

export const createMarketReport =
	(payload: TCreateMarketReportForm) => async (dispatch: TAppDispatch) => {
		try {
			dispatch(createMarketReportInit());

			const response: AxiosResponse = await marketReportApi.createMarketReport(payload);

			if (successResponse(response, 201)) {
				const data: IMarketReport = response.data;

				dispatch(createMarketReportDone(data));

				return data;
			}

			return null;
		} catch (e) {
			console.log(e);

			dispatch(createMarketReportFail(e));

			return null;
		}
	};

export const updateMarketReport =
	(payload: TCreateMarketReportForm, id: number) => async (dispatch: TAppDispatch) => {
		try {
			dispatch(updateMarketReportInit());

			const response: AxiosResponse = await marketReportApi.updateMarketReport(id, payload);

			if (successResponse(response, 200)) {
				const data: IMarketReport = response.data;

				dispatch(updateMarketReportDone(data));

				return data;
			}

			return null;
		} catch (e) {
			console.log(e);

			dispatch(updateMarketReportFail(e));

			return null;
		}
	};

export const getMarketReports =
	(
		name = "",
		pagination?: TPaginationParam,
		createdBy: ("self" | "organisation" | "mapx")[] = [],
	) =>
	async (dispatch: TAppDispatch, getState: TRootState) => {
		try {
			dispatch(getMarketReportsInit());

			const state = getState();

			const currentPagination = getMarketReportsPaginationSelector(state);

			const globalPerPageValue = globalPerPageSelector(state);

			const finalPagination = {
				page: pagination?.page ?? currentPagination?.page ?? 1,
				per_page: globalPerPageValue ?? pagination?.per_page ?? 20,
			};

			const payload = {
				name,
				pagination: finalPagination,
				created_by: createdBy,
			};

			// Remove falsy values
			Object.keys(payload).forEach((k) => {
				const key = k as keyof typeof payload;
				if (!payload[key]) delete payload[key];
				if (
					Array.isArray(payload[key]) &&
					(payload[key] as ("self" | "organisation" | "mapx")[]).length === 0
				) {
					delete payload[key];
				}
			});

			const response: AxiosResponse = await marketReportApi.getMarketReports(payload, {
				cancelToken: getCancelToken(GET_MARKET_REPORTS_TOKEN).token,
			});

			if (successResponse(response, 200)) {
				const data: {results: IMarketReport[]; pagination: ATPagination} = response.data;

				dispatch(getMarketReportsDone(data, name, createdBy));
			}
		} catch (e) {
			if (axios.isCancel(e)) {
				console.log("Request canceled", e.message);
			} else {
				dispatch(getMarketReportsFail(e));
			}
		} finally {
			clearCancelToken(GET_MARKET_REPORTS_TOKEN);
		}
	};

export const getMarketReportById = (id: number) => async (dispatch: TAppDispatch) => {
	try {
		const response: AxiosResponse = await marketReportApi.getMarketReportById(id);

		if (successResponse(response, 200)) {
			const data: IMarketReport = response.data;

			dispatch(setMarketReportDetails(data));
		}
	} catch (e) {
		console.log(e);
	}
};

export const duplicateMarketReport = (id: number) => async () => {
	try {
		const response: AxiosResponse = await marketReportApi.duplicateMarketReportById(id);

		if (successResponse(response, 200)) {
			const data: IMarketReport = response.data;

			return data;
		}
	} catch (e) {
		console.log("error in duplicate market report call", e);
	}
};

export const deleteMarketReport = (id: number) => async (dispatch: TAppDispatch) => {
	try {
		dispatch(deleteMarketReportInit(id));

		const response: AxiosResponse = await marketReportApi.deleteMarketReport(id);

		if (successResponse(response, 204)) {
			dispatch(deleteMarketReportDone(id));

			return;
		}

		return null;
	} catch (e) {
		console.log(e);

		dispatch(deleteMarketReportFail(id, e));

		return null;
	}
};

export const getMarketDiversityStatsReportById =
	(id: number, ignoredKeys: (keyof TCandidateFilterOptions)[]) =>
	async (dispatch: TAppDispatch) => {
		try {
			dispatch(displayDiversityStatsLoading(true));

			const response: AxiosResponse = await marketReportApi.getMarketDiversityStatsReportById(
				id,
				ignoredKeys,
				{
					cancelToken: getCancelToken(GET_MARKET_DIVERSITY_STATS_REPORTS_TOKEN).token,
				},
			);

			if (successResponse(response, 200)) {
				const data: STDiversityStats = response.data;

				dispatch(setDiversityStatsReport(data));
			}
		} catch (e) {
			console.log(e);
			dispatch(displayDiversityStatsLoading(false));
		}
	};
export const getMarketDiversityPathwaysReportById =
	(id: number, mode: STDiversePathwaysMode, ignoredKeys: (keyof TCandidateFilterOptions)[]) =>
	async (dispatch: TAppDispatch) => {
		try {
			dispatch(displayDiversityPathwaysLoading(true));

			const response: AxiosResponse =
				await marketReportApi.getMarketDiversePathwaysReportById(id, mode, ignoredKeys, {
					cancelToken: getCancelToken(GET_MARKET_DIVERSITY_PATHWAYS_REPORTS_TOKEN).token,
				});

			if (successResponse(response, 200)) {
				const data: STDiversePathwaysReport = response.data;

				dispatch(setDiversityPathwaysReport(data));
			}
		} catch (e) {
			console.log(e);
			dispatch(displayDiversityPathwaysLoading(false));
		}
	};

export const downloadMarketReportAsPowerpoint =
	() => async (_: Dispatch<TAppDispatch>, getState: TRootState) => {
		try {
			const state = getState();

			const marketReport: IMarketReport = getMarketReportDetailsSelector(state);

			const projectName = marketReport?.name ?? "Unnamed Report";

			const formattedDate = prepareDateForFileNameSuffix();

			const fileName = `${projectName}-${formattedDate}`;

			const response = await marketReportApi.downloadProjectPowerpoint(marketReport.id);

			if (successResponse(response, 200)) {
				preparePowerpointFileAndDownload(response.data, fileName);

				const userEmail = userEmailSelector(state);

				Mixpanel.track(`Market Report Powerpoint Downloaded`, {
					name: `${marketReport.name}`,
					pageTitle: window.name,
					url: window.location.pathname,
					distinct_id: userEmail,
				});
			}
		} catch (e) {
			console.error(e);
		}
	};
