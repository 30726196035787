import {type FC, useCallback} from "react";
import classNames from "classnames";

import Badge from "../../Badge";
import type {TTabTitleProps} from "./types";
import styles from "./tabTitle.module.scss";
import {Loader} from "components";

const TabTitle: FC<TTabTitleProps> = ({
	title,
	extendedTitle,
	index,
	setSelectedTab,
	isActive,
	count,
	icon,
	loading = false,
	className,
}) => {
	const handleOnClick = useCallback(() => {
		setSelectedTab(index);
	}, [setSelectedTab, index]);

	return (
		<li
			className={classNames(styles.tabTitle, className, {
				[styles.activeTab]: isActive,
			})}
		>
			<span
				role="button"
				onClick={handleOnClick}
				className={classNames(styles.title, {[styles.activeTab]: isActive})}
				title={extendedTitle || title}
				aria-label={isActive ? "active" : "inactive"}
			>
				{icon && icon} {title}
				{count ? (
					loading ? (
						<Loader height={20} width={20} />
					) : (
						<Badge className={styles.count} count={`${count}`} />
					)
				) : null}
			</span>
		</li>
	);
};

export default TabTitle;
