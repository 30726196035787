import {useCallback, useEffect, useMemo} from "react";
import styles from "mapx-pages/Project/SavedPeople/styles.module.scss";
import {ActionAltButton} from "mapx-components";
import classNames from "classnames";
import FilterIcon from "assets/icons/IconFilter";
import {useAppDispatch, useAppSelector} from "hooks";
import {
	customSelectionOption,
	TSavedPeopleHeaderSectionProps,
} from "mapx-pages/Project/SavedPeople/SavedPeopleHeaderSection/types";
import AssessmentIcon from "mapx-pages/Project/SavedPeople/SavedPeopleHeaderSection/Icons/assessmentIcon";
import SummaryViewIcon from "mapx-pages/Project/SavedPeople/SavedPeopleHeaderSection/Icons/summaryViewIcon";
import {
	activeProjectReportViewOnCandidateCardSelector,
	expandedCandidateIDsForProjectSelector,
	expandedCriterionsForProjectSelector,
	projectProgressStatusesSelector,
	projectSavedPeopleSelector,
} from "store/mapx/project/projectSelectors";
import {
	setActiveProjectReportViewOnCandidateCard,
	setExpandedCandidateIDsForProject,
	setExpandedCriterionsForProject,
} from "store/mapx/project/projectActions";
import {Checkbox, Dropdown} from "components";
import {
	handleAllCandidateSelectionForAssessment,
	handleCustomCandidateSelectionForAction,
} from "store/mapx/project/projectAssessmentAsyncActions";
import BulkActions from "mapx-pages/Project/SavedPeople/SavedPeopleHeaderSection/BulkActions";
import {ArrowDown} from "assets/icons";
import {STProjectProgressStatus} from "api/projectApi/types";
import {SICandidate} from "api/candidateApi/types";
import TableViewIcon from "mapx-pages/Project/SavedPeople/SavedPeopleHeaderSection/Icons/tableViewIcon";
import SmallCardIcon from "mapx-pages/Project/SavedPeople/SavedPeopleHeaderSection/Icons/smallCardIcon";
import LargeCardIcon from "mapx-pages/Project/SavedPeople/SavedPeopleHeaderSection/Icons/largeCardIcon";
import {setProjectLayoutViewPreference} from "store/mapx/userPreference/userPreferenceActions";
import {TProjectLayoutView} from "store/mapx/userPreference/types";
import {globalProjectLayoutViewSelector} from "store/mapx/userPreference/userPreferenceSelectors";

const SavedPeopleHeaderSection = ({
	setExpandAllCriterions,
	currentDisplayedCount,
	totalSavedPeopleCount,
	hasActiveFilters,
	candidateSelectionTypeForAssessment = "none",
}: TSavedPeopleHeaderSectionProps) => {
	const dispatch = useAppDispatch();

	const activeProjectReportViewOnCandidateCard = useAppSelector(
		activeProjectReportViewOnCandidateCardSelector,
	);

	const projectProgressStatuses: STProjectProgressStatus[] = useAppSelector(
		projectProgressStatusesSelector,
	);

	const expandedCandidateIDs = useAppSelector(expandedCandidateIDsForProjectSelector);
	const expandedCriterions = useAppSelector(expandedCriterionsForProjectSelector);

	const savedCandidates = useAppSelector(projectSavedPeopleSelector);

	const globalProjectLayoutView = useAppSelector(globalProjectLayoutViewSelector);

	const expandableCandidateIDs = useMemo(() => {
		return (
			savedCandidates
				?.filter((item: SICandidate) => item?.positions?.length > 3)
				?.map((candidate: SICandidate) => candidate.id) || []
		);
	}, [savedCandidates]);

	useEffect(() => {
		if (expandedCriterions?.length > 0) {
			dispatch(setExpandedCandidateIDsForProject(expandableCandidateIDs));
		} else {
			// setDisplayAllExperience(false);
			dispatch(setExpandedCandidateIDsForProject([]));
		}
	}, [expandedCriterions, expandableCandidateIDs, dispatch]);

	const dropdownOptions = useMemo(() => {
		const BULK_ACTIONS_OPTIONS: customSelectionOption[] = [
			{
				value: "select_all",
				name: "Select all",
			},
			{
				value: "select_all_in_page",
				name: "Select all in page",
			},
			{
				value: "deselect_all",
				name: "Deselect all",
			},
			{
				value: "select_recommended",
				name: "Select recommended",
			},
			{
				value: "select_status_with",
				name: "Select status with",
				children: [
					{
						value: null,
						name: "No Status",
					},
				],
			},
			{
				value: "invert_selection",
				name: "Invert selection",
				hasBreakLine: true,
			},
			{
				value: "scores_100",
				name: "Scores 100",
			},
			{
				value: "scores_over_75",
				name: "Scores above >75",
			},
			{
				value: "scores_over_50",
				name: "Scores above >50",
			},
			{
				value: "scores_over_25",
				name: "Scores above >25",
				hasBreakLine: true,
			},
			{
				value: "scores_below_75",
				name: "Scores below <75",
			},
			{
				value: "scores_below_50",
				name: "Scores below <50",
			},
			{
				value: "scores_below_25",
				name: "Scores below >25",
				hasBreakLine: true,
			},
			{
				value: "not_scored",
				name: "Not Scored",
			},
		];

		return BULK_ACTIONS_OPTIONS.map((option) => {
			if (
				option.value === "select_status_with" &&
				option.children &&
				projectProgressStatuses?.length > 0
			) {
				return {
					...option,
					children: [
						...option.children,
						...projectProgressStatuses.map((status) => ({
							value: status.id,
							name: status.name,
						})),
					],
				};
			}

			return option;
		});
	}, [projectProgressStatuses]);

	const onSelect = useCallback(
		async (option: customSelectionOption) => {
			if (option.value !== "select_status_with") {
				dispatch(handleCustomCandidateSelectionForAction(option));
			}
		},
		[dispatch],
	);

	useEffect(() => {
		if (expandedCandidateIDs?.length === expandableCandidateIDs?.length) {
			dispatch(setProjectLayoutViewPreference("max-card"));
		}
	}, [dispatch, expandableCandidateIDs, expandedCandidateIDs]);

	const handleSwitchLayoutView = useCallback(
		(view: TProjectLayoutView) => {
			if (globalProjectLayoutView !== view) {
				dispatch(setProjectLayoutViewPreference(view));

				if (view === "mini-card" || view === "max-card") {
					if (view === "mini-card") {
						setExpandAllCriterions(false);
						dispatch(setExpandedCandidateIDsForProject([]));
					} else {
						setExpandAllCriterions(true);
						dispatch(setExpandedCandidateIDsForProject(expandableCandidateIDs));
					}
				} else {
					dispatch(setExpandedCriterionsForProject([]));
					dispatch(setExpandedCandidateIDsForProject([]));
				}
			}
		},
		[dispatch, expandableCandidateIDs, globalProjectLayoutView, setExpandAllCriterions],
	);

	return (
		<div className={styles.headerInfoWrapper}>
			<div className={styles.left}>
				<div className={styles.userSelectCheckboxContainer}>
					<Checkbox
						value={"Select All"}
						isChecked={candidateSelectionTypeForAssessment === "all"}
						partiallySelected={candidateSelectionTypeForAssessment === "partial"}
						onChange={() =>
							dispatch(
								handleAllCandidateSelectionForAssessment(
									candidateSelectionTypeForAssessment,
								),
							)
						}
					/>

					<Dropdown
						key={"Default"}
						dropdownButtonClass={styles.dropdownButton}
						onSelect={onSelect}
						options={dropdownOptions}
						getOptionLabel={(option) => option.name}
						hasSeparator={(option) => !!option.hasBreakLine}
						/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
						// @ts-ignore
						getChildrenItems={(option) => (option.children ? option.children : [])}
						dropdownButtonWidth={256}
						placeholder={
							<div className={styles.customSelectionOption}>
								<ArrowDown />
							</div>
						}
						dropdownAlign={"right"}
					/>
				</div>

				<div className={styles.viewButtonsGroup}>
					<div
						className={classNames(styles.viewButton, {
							[styles.active]: globalProjectLayoutView === "table-card",
						})}
						onClick={() => handleSwitchLayoutView("table-card")}
					>
						<TableViewIcon />
					</div>
					<div
						className={classNames(styles.viewButton, {
							[styles.active]: globalProjectLayoutView === "mini-card",
						})}
						onClick={() => handleSwitchLayoutView("mini-card")}
					>
						<SmallCardIcon />
					</div>
					<div
						className={classNames(styles.viewButton, {
							[styles.active]: globalProjectLayoutView === "max-card",
						})}
						onClick={() => handleSwitchLayoutView("max-card")}
					>
						<LargeCardIcon />
					</div>
				</div>

				{hasActiveFilters && (
					<div className={styles.filterCountInfo}>
						<span>
							<FilterIcon height={20} width={20} />
						</span>{" "}
						Showing {currentDisplayedCount ?? 0} of {totalSavedPeopleCount} people...
					</div>
				)}
			</div>

			<div className={styles.right}>
				{globalProjectLayoutView !== "table-card" && (
					<div className={styles.buttonsGroup}>
						<ActionAltButton
							key={"assessment_view"}
							customClass={classNames(styles.viewButton, {
								[styles.active]:
									activeProjectReportViewOnCandidateCard === "assessment",
							})}
							iconComponent={<AssessmentIcon />}
							handleClick={() =>
								dispatch(setActiveProjectReportViewOnCandidateCard("assessment"))
							}
							title={"Assessment View"}
						/>
						<ActionAltButton
							key={"summary_view"}
							customClass={classNames(styles.viewButton, {
								[styles.active]:
									activeProjectReportViewOnCandidateCard === "summary",
							})}
							iconComponent={<SummaryViewIcon />}
							handleClick={() =>
								dispatch(setActiveProjectReportViewOnCandidateCard("summary"))
							}
							title={"Summary View"}
						/>
					</div>
				)}

				<BulkActions />
			</div>
		</div>
	);
};

export default SavedPeopleHeaderSection;
