const StarIcon = () => {
	return (
		<svg
			width="48"
			height="48"
			viewBox="0 0 48 48"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<mask
				id="mask0_2569_1508"
				style={{maskType: "alpha"}}
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="48"
				height="48"
			>
				<rect width="48" height="48" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_2569_1508)">
				<path
					d="M15.8145 13.6125L21.4115 6.25401C21.7492 5.81468 22.1413 5.49068 22.588 5.28201C23.0347 5.07368 23.5067 4.96951 24.004 4.96951C24.5013 4.96951 24.9718 5.07368 25.4155 5.28201C25.8592 5.49068 26.2502 5.81468 26.5885 6.25401L32.1855 13.6125L40.742 16.5075C41.4577 16.7488 42.0128 17.1625 42.4075 17.7485C42.8025 18.3342 43 18.9813 43 19.69C43 20.0173 42.9522 20.3457 42.8565 20.675C42.7612 21.0047 42.6052 21.3117 42.3885 21.596L36.8615 29.316L37.0615 37.5885C37.0742 38.5338 36.7555 39.3387 36.1055 40.003C35.4555 40.6677 34.6785 41 33.7745 41C33.7505 41 33.4487 40.9563 32.869 40.869L24 38.327L15.1385 40.866C14.9668 40.9347 14.798 40.9742 14.632 40.9845C14.4663 40.9948 14.3145 41 14.1765 41C13.2615 41 12.4847 40.6663 11.846 39.999C11.2077 39.3317 10.9052 38.5115 10.9385 37.5385L11.1385 29.264L5.5925 21.5655C5.3885 21.2638 5.23883 20.9492 5.1435 20.6215C5.04783 20.2938 5 19.9662 5 19.6385C5 18.9382 5.19983 18.2958 5.5995 17.7115C5.99883 17.1272 6.558 16.7195 7.277 16.4885L15.8145 13.6125ZM17.2075 15.569L7.508 18.8385C7.31567 18.9025 7.18417 19.0338 7.1135 19.2325C7.04283 19.4315 7.07167 19.6143 7.2 19.781L13.4575 28.6345L13.2075 38.1575C13.1948 38.3755 13.2718 38.5455 13.4385 38.6675C13.6052 38.7892 13.791 38.8243 13.996 38.773L24 35.946L34.004 38.823C34.209 38.8743 34.3948 38.8392 34.5615 38.7175C34.7282 38.5955 34.8052 38.4255 34.7925 38.2075L34.5425 28.6345L40.8 19.881C40.9283 19.7143 40.9572 19.5315 40.8865 19.3325C40.8158 19.1338 40.6843 19.0025 40.492 18.9385L30.7925 15.569L24.481 7.28451C24.3653 7.11785 24.205 7.03451 24 7.03451C23.795 7.03451 23.6347 7.11785 23.519 7.28451L17.2075 15.569Z"
					fill="#837D7C"
				/>
			</g>
		</svg>
	);
};

export default StarIcon;
