import mapxProjectApi from "api/projectApi";
import {isValidId} from "helpers/string";
import {clearAPWorkflowState} from "store/mapx/additional-profiles/additionalProfilesActions";
import {
	projectPaginationSelector,
	projectSelector,
} from "store/mapx/project-list/projectListSelectors";
import {setMultipleFilterForCandidates} from "../filter/filterActions";
import {
	candidateTargetListPositionSelector,
	targetListFilterCountSelector,
	tlCompaniesFilterKeyByPositionSelector,
} from "../filter/filterSelectors";
import {
	deleteProjectDone,
	deleteProjectFail,
	deleteProjectInit,
	getLatestProjectListDone,
	getLatestProjectListFail,
	getLatestProjectListInit,
	getProjectDone,
	getProjectFail,
	getProjectInit,
	getProjectListDone,
	getProjectListFail,
	getProjectListInit,
	setProject,
	updateProjectListDone,
	updateProjectListFail,
	updateProjectListInit,
} from "./projectListActions";
import {globalPerPageSelector} from "../userPreference/userPreferenceSelectors";

export const updateProjectList = () => async (dispatch, getState) => {
	try {
		const state = getState();

		const prevPagination = projectPaginationSelector(state);

		dispatch(updateProjectListInit());

		const {
			data: {results, pagination},
		} = await mapxProjectApi.getProjects({
			page: prevPagination.page + 1,
			per_page: prevPagination.per_page,
		});

		await dispatch(updateProjectListDone({results, pagination}));
	} catch (error) {
		dispatch(updateProjectListFail(error));

		return {error};
	}
};

export const getProjectList = (pagination, name) => async (dispatch, getState) => {
	const state = getState();

	try {
		dispatch(getProjectListInit());

		const currentPagination = projectPaginationSelector(state);

		const globalPerPageValue = globalPerPageSelector(state);

		const paginationInfo = {
			page: pagination?.page ?? currentPagination?.page ?? 1,
			per_page: globalPerPageValue ?? pagination?.per_page ?? 20,
		};

		const response = await mapxProjectApi.getProjects(paginationInfo, name);

		if (response.status === 200) {
			const {results, pagination} = response.data;
			dispatch(getProjectListDone({results, pagination}));
		}
	} catch (error) {
		dispatch(getProjectListFail(error));

		return {error};
	}
};

export const getLatestProjectList =
	(page = 1, perPage = 20) =>
	async (dispatch) => {
		try {
			dispatch(getLatestProjectListInit());

			const pagination = {page, per_page: perPage};

			const response = await mapxProjectApi.getProjects(pagination);

			if (response.status === 200) {
				const {results} = response.data;
				dispatch(getLatestProjectListDone({results}));
			}
		} catch (error) {
			dispatch(getLatestProjectListFail(error));

			return {error};
		}
	};

export const getPaginatedProjectList =
	(page = 1, name = "", perPage = 20) =>
	async (dispatch) => {
		try {
			dispatch(updateProjectListInit());

			const pagination = {page, per_page: perPage};

			const response = await mapxProjectApi.getProjects(pagination, name);

			if (response.status === 200) {
				const {results, pagination} = response.data;
				dispatch(updateProjectListDone({results, pagination}));
			}
		} catch (error) {
			dispatch(updateProjectListFail(error));

			return {error};
		}
	};

export const deleteProject = (projectID) => async (dispatch, getState) => {
	try {
		const state = getState();

		const project = projectSelector(state);

		dispatch(deleteProjectInit());

		await mapxProjectApi.deleteProject(projectID);

		dispatch(deleteProjectDone(projectID));

		if (project?.id === projectID) {
			dispatch(setProjectAsync(null));
		}
	} catch (error) {
		dispatch(deleteProjectFail({error}));

		return {error};
	}
};

export const setProjectAsync = (project) => async (dispatch, getState) => {
	const state = getState();

	const count = targetListFilterCountSelector(state);

	// Reset the targetListApi-list filterOptions if it has any company already added.
	if (count > 0) {
		const position = candidateTargetListPositionSelector(state);

		const filterKey = tlCompaniesFilterKeyByPositionSelector(state, position);

		dispatch(
			setMultipleFilterForCandidates({
				[filterKey]: [],
			}),
		);
	}

	dispatch(setProject(project));
	dispatch(clearAPWorkflowState());
};

export const getProject = (projectID) => async (dispatch) => {
	try {
		dispatch(getProjectInit());

		if (!isValidId(projectID)) {
			window.location = "/404";

			throw new Error("Project doesn't not exist");
		}

		const {data: project, status} = await mapxProjectApi.getProject(projectID);

		if (status === 403) {
			window.location = "/403";

			throw new Error("You don't have permission to access this project.");
		}

		if (status === 404) {
			window.location = "/404";

			throw new Error("Project doesn't not exist");
		}

		dispatch(getProjectDone(project));

		return project;
	} catch (error) {
		dispatch(getProjectFail(error));

		return {error};
	}
};
