import {AxiosRequestConfig, CanceledError} from "axios";
import mapXCompanyApi from "api/companyApi";
import {successResponse} from "helpers/map";
import {getCancelToken} from "api/cancelTokens";
import {
	SEARCH_COMPANY_BY_DESCRIPTION,
	SEARCH_COMPANY_BY_FILTER,
} from "api/requestCancelTokenStrings";
import {TFilterCompany, TSearchCompanyApiPayload} from "api/companyApi/types";

export const getSearchedCompaniesDataByDescription = (description: string) => async () => {
	const config: AxiosRequestConfig = {
		cancelToken: getCancelToken(SEARCH_COMPANY_BY_DESCRIPTION).token,
	};

	try {
		if (description !== null) {
			const response = await mapXCompanyApi.getCompanyLLMSuggestions(description, config);

			if (response == null) {
				throw new CanceledError("Operation canceled due to new request.");
			} else if (successResponse(response, 200)) {
				return response.data?.results || [];
			}
		}

		return [];
	} catch (error) {
		console.error("error", error);

		return [];
	}
};

export const searchCompaniesByFilter =
	(
		filters: Partial<TFilterCompany>,
		pagination: {
			page: number;
			per_page: number;
		},
	) =>
	async () => {
		const config: AxiosRequestConfig = {
			cancelToken: getCancelToken(SEARCH_COMPANY_BY_FILTER).token,
		};

		try {
			const apiPayload: TSearchCompanyApiPayload = {
				filters,
				pagination,
			};

			const response = await mapXCompanyApi.searchCompaniesByFilter(apiPayload, config);

			if (response == null) {
				throw new CanceledError("Operation canceled due to new request.");
			} else if (successResponse(response, 200)) {
				return (
					response.data || {
						pagination: {
							count: 0,
							page: 1,
							pages: 1,
						},
						results: [],
					}
				);
			}

			return {
				pagination: {
					count: 0,
					page: 1,
					pages: 1,
				},
				results: [],
			};
		} catch (error) {
			console.error("error", error);

			return {
				pagination: {
					count: 0,
					page: 1,
					pages: 1,
				},
				results: [],
			};
		}
	};
