import React, {useCallback, useRef, useState} from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";
import {AccordionAlt} from "mapx-components/index";
import YesIcon from "mapx-components/AccordionAlt/Icons/yesIcon";
import NoIcon from "mapx-components/AccordionAlt/Icons/noIcon";
import MaybeIcon from "mapx-components/AccordionAlt/Icons/maybeIcon";
import {TCandidateFitToBrief} from "api/candidateApi/types";
import ContentLoader from "react-content-loader";
import EditFitToBriefModal from "../../Modals/FitToBriefModal/Edit";
import AssessmentEditIcon from "../../Modals/FitToBriefModal/Icon/editIcon";
import {setExpandedCriterionsForProject} from "store/mapx/project/projectActions";
import {useAppDispatch, useAppSelector} from "hooks";
import {expandedCriterionsForProjectSelector} from "store/mapx/project/projectSelectors";
import useCandidateScoringUtils from "mapx-components/Cards/CandidateScoring/useCandidateScoringUtils";

const CandidateScoring = ({
	fit_to_brief,
	isLoading = false,
	expanded = false,
	handleOnEdit,
}: {
	fit_to_brief?: TCandidateFitToBrief;
	isLoading?: boolean;
	expanded?: boolean;
	handleOnEdit: () => void;
}) => {
	const dispatch = useAppDispatch();

	const expandedCriterions = useAppSelector(expandedCriterionsForProjectSelector);

	const [modalDisplay, setModalDisplay] = useState(false);

	const containerRef = useRef(null);

	const {matchScorePercentage, progressCircleColor, CIRCULAR_PROGRESS} =
		useCandidateScoringUtils(fit_to_brief);

	const handleToggle = useCallback(
		(criterionName: string) => {
			const isAlreadyExpanded = expandedCriterions.includes(criterionName);
			const newExpandedCriterions = isAlreadyExpanded
				? expandedCriterions.filter((name: string) => name !== criterionName)
				: [...expandedCriterions, criterionName];

			dispatch(setExpandedCriterionsForProject(newExpandedCriterions));
		},
		[dispatch, expandedCriterions],
	);

	const handleEditClick = () => {
		setModalDisplay(true);
		handleOnEdit();
	};

	return (
		<div className={styles.scoringContainer}>
			{isLoading && (
				<ContentLoader
					style={{marginTop: 10}}
					speed={2}
					height={130}
					width={"100%"}
					backgroundColor="#EEEDEC"
					foregroundColor="#e6e6e6"
				>
					<rect x="1" y="8.8" width="99%" height="22" />
					<rect x="1" y="38.8" width="96%" height="22" />
					<rect x="1" y="68.8" width="98%" height="22" />
					<rect x="1" y="98.8" width="91%" height="22" />
				</ContentLoader>
			)}

			{!isLoading &&
				fit_to_brief &&
				(fit_to_brief?.assessments === undefined ||
					fit_to_brief?.assessments?.length === 0) && (
					<div className={styles.noScore}>
						<span className={styles.title}>Not scored yet</span>
						<span className={styles.description}>
							{"Click 'Fit to Brief' to start the AI scoring process."}
						</span>
					</div>
				)}

			{!isLoading && fit_to_brief && fit_to_brief?.assessments?.length > 0 && (
				<div>
					<div
						className={classNames(styles.fitToBriefScore, {
							[styles.lowScoreMatch]: matchScorePercentage === "low",
							[styles.midScoreMatch]: matchScorePercentage === "mid",
							[styles.highScoreMatch]: matchScorePercentage === "high",
						})}
					>
						<span className={styles.title}>
							<span> Overall Score </span>

							<span
								className={styles.editIcon}
								onClick={handleEditClick}
								style={{border: `1px solid ${progressCircleColor}`}}
							>
								<AssessmentEditIcon
									color={progressCircleColor}
									width={16}
									height={16}
								/>{" "}
								Edit
							</span>
						</span>

						<span className={styles.score}>
							<span className={styles.percentage}>{fit_to_brief.score}% Match</span>

							{CIRCULAR_PROGRESS}
						</span>
					</div>

					<div className={styles.criteriaResults} ref={containerRef} id="criteria-list">
						<AccordionAlt>
							{fit_to_brief.assessments.map((item) => (
								<div
									key={item.criterion.name}
									onClick={() => handleToggle(item.criterion.name)}
								>
									<AccordionAlt.AccordionItem
										title={item.criterion.name}
										expanded={
											expanded ||
											expandedCriterions?.includes(item.criterion.name)
										}
										titlePostFixChildren={
											<div
												className={classNames(styles.status, {
													[styles.yes]: item.verdict === "Yes",
													[styles.no]: item.verdict === "No",
													[styles.maybe]: item.verdict === "Maybe",
												})}
											>
												{item.verdict}

												{item.verdict === "Yes" && <YesIcon />}
												{item.verdict === "No" && <NoIcon />}
												{item.verdict === "Maybe" && <MaybeIcon />}
											</div>
										}
									>
										{item.assessment}
									</AccordionAlt.AccordionItem>
								</div>
							))}
						</AccordionAlt>
					</div>
				</div>
			)}

			{fit_to_brief && (
				<div>
					<EditFitToBriefModal
						modalDisplay={modalDisplay}
						setModalDisplay={setModalDisplay}
						assessments={fit_to_brief.assessments}
					/>
				</div>
			)}
		</div>
	);
};

export default CandidateScoring;
