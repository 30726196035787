import {FloatAddedIcon} from "assets/icons";
import {Checkbox, CheckboxSkeletonLoader, Loader, ResetFilters} from "components";
import {labelCapitalized} from "helpers/string";
import useFetchListPaginateOptions from "hooks/useFetchListPaginateOptions";

import {SearchInput} from "mapx-components";
import styles from "mapx-components/Filters/KeywordSearchFilter/styles.module.scss";
import React, {KeyboardEvent, useCallback, useEffect, useMemo} from "react";
import {useSelector} from "react-redux";
import {keywordOptionsSelector} from "store/mapx/search/searchSelectors";
import {TKeywordSearchFilterProps} from "mapx-components/Filters/KeywordSearchFilter/types";
import {STKeywords} from "api/filterOptionsApi/KeywordApi/types";
import Tags from "./Tags";
import {useAppDispatch} from "hooks";

const KeywordSearchFilter = ({
	selectedKeywords,
	handleResetClick,
	handleOnChange,
	handleTagClick,
	displayResetFilterOption,
	moveKeywordTagPosition,
	selectedKeywordTags,
	handleConnectivityOptionChange,
	connectionLogics,
	clearTagsByPosition,
	apiCall,
	keywordSearchQuery,
	keywordPagination,
	setKeywordQueryOnState,
}: TKeywordSearchFilterProps) => {
	const keywordOptions: STKeywords[] = useSelector(keywordOptionsSelector);

	const dispatch = useAppDispatch();

	const {loading, searchTerm, setSearchTerm, onFilterChanged, listInnerRef, page, setPage, data} =
		useFetchListPaginateOptions({
			options: keywordOptions,
			apiCall,
			initialSearchQuery: keywordSearchQuery,
			// eslint-disable-next-line
			// @ts-ignore
			setQueryCallbackOnState: setKeywordQueryOnState,
		});

	const onScroll = useCallback(() => {
		try {
			if (!!setPage && !loading && keywordPagination?.pages > page) {
				if (listInnerRef.current) {
					const {scrollTop, scrollHeight, clientHeight} = listInnerRef.current;
					if (Math.ceil(scrollTop) + clientHeight >= scrollHeight - 1) {
						setPage(keywordPagination.page + 1);
					}
				}
			}
		} catch (e) {
			console.log(e);
		}
	}, [setPage, loading, keywordPagination, page, listInnerRef]);

	const sortedFilteredData = useMemo(() => {
		return [...data].filter((item: STKeywords) => !selectedKeywords.includes(item.name));
	}, [data, selectedKeywords]);

	const hideAddIcon = useMemo(() => {
		if (searchTerm === "" || loading) return true;

		if (searchTerm !== "" && selectedKeywords?.length > 0) {
			for (const item of selectedKeywords) {
				if (item.toLowerCase() === searchTerm.toLowerCase()) {
					return true;
				}
			}
		}

		return false;
	}, [loading, searchTerm, selectedKeywords]);

	const resetSearchInput = useCallback(() => {
		setSearchTerm("");
		dispatch(setKeywordQueryOnState(""));

		const inputElement = document.querySelector(
			'input[placeholder="Search for a keyword"]',
		) as HTMLInputElement;

		if (inputElement) {
			inputElement.value = "";
		}

		setPage(1);
		dispatch(apiCall);
	}, [apiCall, dispatch, setKeywordQueryOnState, setPage, setSearchTerm]);

	const handleAddJobTitleIcon = async () => {
		handleOnChange(searchTerm);

		resetSearchInput();
	};

	const handleKeywordSearchEnter = useCallback(
		(e: KeyboardEvent<HTMLInputElement>) => {
			if (e.key === "Enter" && !loading) {
				handleOnChange(searchTerm);

				resetSearchInput();
			}
		},
		[handleOnChange, loading, resetSearchInput, searchTerm],
	);

	useEffect(() => {
		if (
			sortedFilteredData?.length === 0 &&
			!loading &&
			keywordPagination?.pages > keywordPagination?.page
		) {
			setPage(keywordPagination?.page + 1);
		}
	}, [loading, keywordPagination?.page, keywordPagination?.pages, setPage, sortedFilteredData]);

	return (
		<div>
			<div className={styles.searchInputWrapper}>
				{!hideAddIcon && (
					<FloatAddedIcon
						onClick={handleAddJobTitleIcon}
						className={styles.floatAddedIcon}
					/>
				)}

				<SearchInput
					isLoading={loading}
					defaultValue={keywordSearchQuery}
					onChange={onFilterChanged}
					onKeyDown={handleKeywordSearchEnter}
					placeholder="Search for a keyword"
					type="text"
					errorText={undefined}
					errorClass={undefined}
				/>
			</div>

			{displayResetFilterOption && (
				<ResetFilters
					parentStyle={{color: "#5A5A5A", marginRight: 19}}
					onClick={handleResetClick}
					displayIcon={true}
				>
					Clear Selection
				</ResetFilters>
			)}

			<div className={styles.checkboxList} ref={listInnerRef} onScroll={onScroll}>
				{sortedFilteredData.map((s: STKeywords) => {
					return (
						<Checkbox
							borderColor="#0C5850"
							containerClass={styles.checkboxContainer}
							isChecked={false}
							key={s.id}
							label={`${labelCapitalized(s.name)}`}
							onChange={() => handleOnChange(s.name)}
							value={s.name}
						/>
					);
				})}

				{sortedFilteredData.length > 0 && loading && (
					<div style={{textAlign: "center"}}>
						<Loader
							width={30}
							height={30}
							type="ThreeDots"
							color="#0c5850"
							displayAtCenterOfPage={false}
						/>
					</div>
				)}
			</div>

			{loading && (sortedFilteredData?.length === 0 || data.length === 0) && (
				<CheckboxSkeletonLoader />
			)}

			<div style={{display: "flex", flexDirection: "column", gap: 8, marginTop: 8}}>
				<Tags
					key={"current"}
					tagLabel={"Current"}
					tagValue={"current"}
					handleTagClick={handleTagClick}
					tags={selectedKeywordTags.current}
					selectedLogic={connectionLogics.current}
					moveKeywordTagPosition={moveKeywordTagPosition}
					handleConnectivityOptionChange={handleConnectivityOptionChange}
					clearTagsByPosition={clearTagsByPosition}
				/>

				<Tags
					key={"previous"}
					tagLabel={"Previous"}
					tagValue={"previous"}
					handleTagClick={handleTagClick}
					tags={selectedKeywordTags.previous}
					selectedLogic={connectionLogics.previous}
					moveKeywordTagPosition={moveKeywordTagPosition}
					handleConnectivityOptionChange={handleConnectivityOptionChange}
					clearTagsByPosition={clearTagsByPosition}
				/>

				<Tags
					key={"any"}
					tagLabel={"Any"}
					tagValue={"any"}
					handleTagClick={handleTagClick}
					tags={selectedKeywordTags.any}
					selectedLogic={connectionLogics.any}
					moveKeywordTagPosition={moveKeywordTagPosition}
					handleConnectivityOptionChange={handleConnectivityOptionChange}
					clearTagsByPosition={clearTagsByPosition}
				/>
			</div>
		</div>
	);
};

export default KeywordSearchFilter;
