import {type FC, useCallback, useMemo} from "react";
import {noop} from "lodash";
import {useHistory, useLocation} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "hooks";
import {PlusIconModule, ShowProjects} from "assets/icons";
import {HeaderDropDown, ProgressBar} from "components";
import type {TFeatureProp} from "components/HeaderDropDown/types";
import {setProjectAsync} from "store/mapx/project-list/projectListAsyncActions";
import {clearAPWorkflowState} from "store/mapx/additional-profiles/additionalProfilesActions";
import {
	getTargetListCandidatesDone,
	getTargetListCompaniesDone,
} from "store/mapx/target-list/targetListActions";
import {
	getLatestProjectListInProgressSelector,
	latestProjectsSelector,
	projectSelector,
} from "store/mapx/project-list/projectListSelectors";
import {activeSearchRequestSelector} from "store/mapx/additional-profiles/additionalProfilesSelectors";
import type {TSearchRequestDetails} from "api/projectApi/searchRequestApi/types";
import {createProjectModal, setLatestProject} from "store/mapx/project-list/projectListActions";
import {userLicenseTypeNameSelector} from "store/mapx/user/userSelectors";

import styles from "../header.module.scss";

import {STProject} from "api/projectApi/types";
import {fetchTargetListCompanyIDs} from "store/mapx/target-list/targetListAsyncActions";
import {fetchTargetListCandidateIDs} from "store/mapx/target-list/targetListCandidatesAsyncActions";
import {fetchProjectCandidateIDs} from "store/mapx/project/projectCandidatesAsyncActions";
import {fetchProjectSavedCompanyIDs} from "store/mapx/project/projectCompaniesAsyncActions";

const MyProjects: FC = () => {
	const history = useHistory();

	const location = useLocation();

	const dispatch = useAppDispatch();

	const project = useAppSelector(projectSelector);

	const projects = useAppSelector(latestProjectsSelector);

	const activeSearchRequest = useAppSelector(activeSearchRequestSelector);

	const userLicenseTypeName = useAppSelector(userLicenseTypeNameSelector);

	const getProjectIsInProgress = useAppSelector(getLatestProjectListInProgressSelector);

	const isStarterOrPlusUser: boolean =
		userLicenseTypeName === "Starter" || userLicenseTypeName === "Plus";

	const featureUserList: TFeatureProp[] = [
		{
			id: 1,
			icon: PlusIconModule,
			text: "Create New Project",
			btnClassName: isStarterOrPlusUser ? styles.starter : "",
			onClick: isStarterOrPlusUser ? noop : () => dispatch(createProjectModal(true)),
		},
		{
			id: 2,
			icon: ShowProjects,
			text: "Show All Projects",
			to: projects?.length > 0 ? "/projects" : "",
			btnClassName: projects?.length > 0 ? "" : styles.starter,
		},
	];

	const shouldDisplayProgressBar = useMemo(() => {
		let inProgress = false;

		if (activeSearchRequest) {
			inProgress =
				activeSearchRequest?.status === "Created" ||
				activeSearchRequest?.status === "In Progress";

			if (!inProgress) {
				if (
					"children" in activeSearchRequest &&
					activeSearchRequest?.children?.length > 0
				) {
					inProgress = activeSearchRequest?.children.some(
						(item: Partial<TSearchRequestDetails>) => item.status === "In Progress",
					);
				}
			}
		}

		return inProgress;
	}, [activeSearchRequest]);

	const progressPercentage = useMemo(() => {
		if (shouldDisplayProgressBar) {
			try {
				let progress = 0;

				if (activeSearchRequest) {
					const inProgress =
						activeSearchRequest?.status === "Created" ||
						activeSearchRequest?.status === "In Progress";

					if (inProgress) {
						progress = activeSearchRequest.progress;
					} else {
						if (
							"children" in activeSearchRequest &&
							activeSearchRequest?.children?.length > 0
						) {
							const inProgressChild = activeSearchRequest?.children.find(
								(item: Partial<TSearchRequestDetails>) =>
									item.status === "In Progress",
							);

							if (inProgressChild && "progress" in inProgressChild) {
								progress = inProgressChild.progress || 4;
							}
						}
					}
				}

				return progress;
			} catch (e) {
				console.log(`Error parsing AP progress percentage`, e);

				return 100;
			}
		} else {
			return 100;
		}
	}, [activeSearchRequest, shouldDisplayProgressBar]);

	const handleExitProjectClick = () => {
		dispatch(setProjectAsync(null));
		dispatch(getTargetListCandidatesDone([]));
		dispatch(getTargetListCompaniesDone([]));

		const paramsProjectId = Number(location.pathname.replace("/project/", ""));

		if (paramsProjectId) {
			history.push("/projects");
		}
	};

	const handleOptionClick = useCallback(
		(selectedProject: STProject) => {
			if (project) {
				dispatch(clearAPWorkflowState());

				if (selectedProject.id !== project.id) {
					dispatch(fetchProjectCandidateIDs());
					dispatch(fetchProjectSavedCompanyIDs());
				}
			}

			if (project === null || project.id !== selectedProject.id) {
				dispatch(setLatestProject(selectedProject));
			}

			const url = window.location.pathname;
			const projectUrlRegex = /^\/project\/\d+$/;

			if (projectUrlRegex.test(url)) {
				history.push(`/project/${selectedProject.id}`);
			}

			if (selectedProject.id !== project?.id) {
				dispatch(fetchTargetListCompanyIDs());
				dispatch(fetchTargetListCandidateIDs());
			}
		},
		[dispatch, history, project],
	);

	const projectTitle = useMemo(() => {
		if (project && project?.name) {
			return project.name.length > 30 ? project.name.slice(0, 27) + "..." : project.name;
		} else {
			return "No Project Selected";
		}
	}, [project]);

	return (
		<HeaderDropDown
			showItem
			groupLeft
			rightArrow
			pathName="project"
			scrollList={projects}
			onClick={handleOptionClick}
			listClasses={styles.list__width}
			featureUserList={featureUserList}
			closeHandler={handleExitProjectClick}
			headerClasses={styles.project__width}
			title={projectTitle}
			titleChildren={
				shouldDisplayProgressBar &&
				project?.name && (
					<ProgressBar
						mini
						value={progressPercentage}
						className={styles.progress}
						miniClass={styles.progress__mini}
						background={`linear-gradient(90deg, #0C5850 ${
							progressPercentage - 3
						}%, #FCFEFF 100%)`}
					/>
				)
			}
			loading={getProjectIsInProgress}
		></HeaderDropDown>
	);
};

export default MyProjects;
