import classNames from "classnames";
import {TextTabTitle} from "mapx-components";

import styles from "./tabs.module.scss";
import {TTextTabsProps} from "mapx-components/TextTabs/types";

const TextTabs = ({
	children,
	onTabChange,
	className,
	activeTab,
	tabTitleClass,
	tabListClass,
}: TTextTabsProps) => {
	const onTabChangeExtended = (idx: number) => {
		onTabChange && onTabChange(idx);
	};

	return (
		<div
			className={classNames(
				styles.tabs,
				{
					[styles.tabs__is]: activeTab === 0,
				},
				className && className,
			)}
		>
			<ul className={classNames(styles.tabList, tabListClass && tabListClass)}>
				{children.map((item, index) => (
					<TextTabTitle
						key={item.props.title + index}
						title={item.props.title}
						extendedTitle={item.props?.extendedTitle}
						icon={item.props?.icon}
						count={item.props.count}
						loading={item.props.loading}
						index={index}
						isActive={index === activeTab}
						setSelectedTab={onTabChangeExtended}
						className={tabTitleClass}
					/>
				))}
			</ul>

			{/* show selected tab by index*/}
			{children[activeTab]}
		</div>
	);
};

export default TextTabs;
