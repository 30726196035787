import {useCallback} from "react";
import {useSelector} from "react-redux";
import {Accordion, IndustrySearchFilter} from "mapx-components";
import {TabSelect} from "components";
import AnyTags from "./Tags/AnyTags";
import CurrentTags from "./Tags/CurrentTags";
import PreviousTags from "./Tags/PreviousTags";
import {useAppDispatch, useAppSelector} from "hooks";

import {filterPositionOptionsSelector} from "store/mapx/filter/filterSelectors";
import {
	mRAllIndustriesCountSelector,
	mRCandidateIndustriesByPositionSelector,
	mRIndustryFilterConfigSelector,
} from "store/mapx/market-report/marketReportFilterSelectors";
import {TPositionLogic} from "store/mapx/market-report/types";
import {
	setMarketReportMultipleFilterValueInForm,
	setMRIndustriesFilterConfig,
} from "store/mapx/market-report/marketReportActions";
import {setIndustryForMarketReportFormFilter} from "store/mapx/market-report/marketReportFormAsyncActions";

function IndustryFilter() {
	const dispatch = useAppDispatch();

	const filterPositionOptions = useAppSelector(filterPositionOptionsSelector);

	const industryFilterConfig = useAppSelector(mRIndustryFilterConfigSelector);

	const position: TPositionLogic = industryFilterConfig.active_position;

	const industries = useSelector((state) =>
		mRCandidateIndustriesByPositionSelector(state, position),
	);

	const count = useSelector(mRAllIndustriesCountSelector);

	const handlePositionChange = useCallback(
		(updatedPositionValue: string) => {
			dispatch(
				setMRIndustriesFilterConfig({
					...industryFilterConfig,
					active_position: updatedPositionValue,
				}),
			);
		},
		[dispatch, industryFilterConfig],
	);

	const handleChange = useCallback(
		(nextIds: number[]) => {
			dispatch(setIndustryForMarketReportFormFilter({nextIds, position}));
		},
		[dispatch, position],
	);

	const handleResetClick = useCallback(() => {
		dispatch(
			setMarketReportMultipleFilterValueInForm({
				industries: [],
				current_industries: [],
				previous_industries: [],
			}),
		);
	}, [dispatch]);

	return (
		<Accordion title="Industry" subtitle={count}>
			<TabSelect.LabelContainer label="Position">
				<TabSelect
					selected={position}
					onTabChange={handlePositionChange}
					options={filterPositionOptions}
				/>
			</TabSelect.LabelContainer>

			<IndustrySearchFilter
				selectedIndustries={industries}
				handleResetClick={handleResetClick}
				handleOnChange={handleChange}
			/>

			{count !== 0 && (
				<>
					<CurrentTags />

					<PreviousTags />

					<AnyTags />
				</>
			)}
		</Accordion>
	);
}

export default IndustryFilter;
