import type {FC} from "react";
import classNames from "classnames";

import {InfoCard} from "assets/icons";

import type {TSkeletonLoaderCardAsListProps} from "./types";
import styles from "./styles.module.scss";
import ContentLoader from "react-content-loader";

const SkeletonLoaderCardAsList: FC<TSkeletonLoaderCardAsListProps> = ({
	count = 5,
	customClass = "",
	isMini = false,
}) => (
	<div
		className={classNames(styles.container, {
			[customClass]: customClass,
			[styles.noGap]: isMini,
		})}
		data-testid="candidates-skeleton-list"
	>
		{Array.from({length: count}, (_, idx) => (
			<div key={idx} className={classNames(styles.container__card, {[styles.noGap]: isMini})}>
				{!isMini && <InfoCard />}

				{isMini && (
					<ContentLoader
						speed={2}
						height={35}
						width={"100%"}
						backgroundColor="#EEEDEC"
						foregroundColor="#e6e6e6"
					>
						<circle cx="15" cy="15" r="13" />
						<rect x="35" y="4" width="190" height="20" />
						<rect x="265" y="4" width="145" height="20" />
						<circle cx="480" cy="15" r="13" />
						<rect x="505" y="4" width="130" height="20" />
						<rect x="680" y="4" width="60" height="20" />
						<rect x="775" y="4" width="130" height="20" />
						<circle cx="940" cy="15" r="13" />
						<rect x="965" y="4" width="130" height="20" />
						<rect x="1130" y="4" width="20" height="20" />
					</ContentLoader>
				)}
			</div>
		))}
	</div>
);

export default SkeletonLoaderCardAsList;
