import {ChangeEvent, useCallback, useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import useSearchDebounce from "hooks/useSearchDebounce";
import {TUseFetchListPaginateOptionsProps} from "hooks/useFetchListPaginateOptions/types";
import {AnyAction} from "redux";

const useFetchListPaginateOptions = <T>({
	apiCall,
	setFilteredData,
	options = [],
	initialSearchQuery = "",
	setQueryCallbackOnState,
}: TUseFetchListPaginateOptionsProps<T>) => {
	const [searchTerm, setSearchTerm] = useSearchDebounce(800, initialSearchQuery);

	const [loading, setLoading] = useState<boolean>(false);

	const [page, setPage] = useState<number>(1);

	const [data, setData] = useState<T[]>([]);

	const [initialFetchCompleted, setInitialFetchCompleted] = useState<boolean>(false);

	const [stopPaginate, setStopPaginate] = useState<boolean>(false);

	const listInnerRef = useRef<HTMLDivElement | null>(null);

	const dispatch = useDispatch();

	const fetchData = useCallback(
		async (query = "", pageNumber = 1) => {
			if (stopPaginate) return;

			let response;

			setLoading(true);

			if (query !== "") {
				response = await dispatch(<AnyAction>apiCall(query, pageNumber));

				if (setFilteredData && pageNumber === 1) {
					setFilteredData([]);
				}
			} else {
				response = await dispatch(<AnyAction>apiCall("", pageNumber));
			}

			setLoading(false);

			if (response !== undefined && response.stopPaginate !== undefined) {
				setStopPaginate(response.stopPaginate);
			}
		},
		[stopPaginate, setFilteredData, dispatch, apiCall],
	);

	useEffect(() => {
		if (options.length) {
			setData([...options]);
		}

		if (options.length === 0 && !initialFetchCompleted) {
			fetchData().then(() => {
				if (!initialFetchCompleted) {
					setInitialFetchCompleted(true);
				}
			});
		}
	}, [options, fetchData, initialFetchCompleted]);

	useEffect(() => {
		if (searchTerm !== "" || page > 1) {
			fetchData(searchTerm, page).finally(() => setLoading(false));
		}
	}, [searchTerm, page, fetchData]);

	const onFilterChanged = async (e: ChangeEvent<HTMLInputElement>) => {
		setPage(1);
		setStopPaginate(false);
		const value = e.target.value;
		setSearchTerm(value);

		if (setQueryCallbackOnState) {
			dispatch(setQueryCallbackOnState(value));
		}

		if (value === "" && typeof setFilteredData === "function") {
			setFilteredData([]);
		}

		if (value === "") {
			await dispatch(<AnyAction>apiCall("", 1));
		}
	};

	return {
		data,
		onFilterChanged,
		page,
		setPage,
		loading,
		listInnerRef,
		searchTerm,
		setSearchTerm,
		stopPaginate,
	};
};

export default useFetchListPaginateOptions;
