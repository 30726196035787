import {TRootState} from "types";

export const projectStatsSelector = (state: TRootState) => state.mapxProject.projectStats;

export const projectStatsLoadingSelector = (state: TRootState) =>
	state.mapxProject.projectStatsLoading;

export const projectCandidatesSelectionInProgressSelector = (state: TRootState) =>
	state.mapxProject.projectCandidatesSelectionInProgress;

export const industryReportLoadingSelector = (state: TRootState) =>
	state.mapxProject.industryReportLoading;

export const industryReportPaginationSelector = (state: TRootState) =>
	state.mapxProject.industryReportPagination;

export const industryReportSelector = (state: TRootState) => state.mapxProject.industryReport;

export const projectFrequentlyHiredFromDataSelector = (state: TRootState) =>
	state.mapxProject.projectFrequentlyHiredFromData;

export const industryBackgroundReportSelector = (state: TRootState) =>
	state.mapxProject.industryBackgroundReport;

export const industryBackgroundReportProgressSelector = (state: TRootState) =>
	state.mapxProject.industryBackgroundReportProgress;

export const jobFunctionDistributionInProgressSelector = (state: TRootState) =>
	state.mapxProject.industryBackgroundReportProgress;
export const jobFunctionDistributionSelector = (state: TRootState) =>
	state.mapxProject.insights.jobFunctionDistribution;

export const projectCountryReportSelector = (state: TRootState) =>
	state.mapxProject.insights.countryReport;

export const onboardingForStarterUserSelector = (state: TRootState) =>
	state.mapxProject.openOnboardingComponent;

export const onboardingStepSelector = (state: TRootState) => state.mapxProject.onboardingStep;
export const activeProjectReportViewOnCandidateCardSelector = (state: TRootState) =>
	state.mapxProject?.activeProjectReportViewOnCandidateCard;
export const displayFullProjectChecklistSelector = (state: TRootState) =>
	state.mapxProject?.displayFullProjectChecklist;

export const projectSuccessChecklistOptionsSelector = (state: TRootState) =>
	state.mapxProject.projectChecklistOptions || [];

export const projectReportTableRowsSelector = (state: TRootState) =>
	state.mapxProject.projectReportTableRows;

export const projectProgressStatusesSelector = (state: TRootState) =>
	state.mapxProject.progressStatuses;

export const fetchingProgressStatusesSelector = (state: TRootState) =>
	state.mapxProject.fetchingProgressStatuses;

export const projectCandidatesStatusesSelector = (state: TRootState) =>
	state.mapxProject.candidateStatuses;

export const fetchingProjectCandidateStatusesSelector = (state: TRootState) =>
	state.mapxProject.fetchingCandidateStatuses;

export const presentationAvailableTemplatesSelector = (state: TRootState) =>
	state.mapxProject.presentationAvailableTemplates;

export const selectedPresentationTemplateSelector = (state: TRootState) =>
	state.mapxProject.selectedPresentationTemplate;

export const bulkStatusChangeInProgressSelector = (state: TRootState) =>
	state.mapxProject.bulkStatusChangeInProgress;

export const projectSavedPeopleSelector = (state: TRootState) =>
	state.mapxProject.projectSavedCandidates;

export const projectSavedPeoplePaginationSelector = (state: TRootState) =>
	state.mapxProject.projectSavedCandidatesPagination;

export const projectSavedPeopleInProgressSelector = (state: TRootState) =>
	state.mapxProject.projectSavedCandidatesFetchIsInProgress;

export const projectSavedCompanyPaginationSelector = (state: TRootState) =>
	state.mapxProject.projectSavedCompaniesPagination;

export const projectSavedCompaniesInProgressSelector = (state: TRootState) =>
	state.mapxProject.projectSavedCompaniesFetchIsInProgress;

export const projectSavedCompaniesSelector = (state: TRootState) =>
	state.mapxProject.projectSavedCompanies;

export const projectCandidateIDsSelector = (state: TRootState) =>
	state.mapxProject.projectSavedCandidateIDs;

export const projectCompanyIdsSelector = (state: TRootState) => state.mapxProject.projectCompanyIDs;
export const expandedCriterionsForProjectSelector = (state: TRootState) =>
	state.mapxProject.expandedCriterionsForProject || [];
export const expandedCandidateIDsForProjectSelector = (state: TRootState) =>
	state.mapxProject.expandedCandidateIDsForProject || [];
