import {type ChangeEvent, useCallback, useState} from "react";
import styles from "./styles.module.scss";
import {TextInput} from "components";
import classNames from "classnames";
import FooterButtons from "mapx-components/Modals/FooterButtons";
import SelectDropdown from "components/Inputs/SelectDropdown";
import useLocationSearchService from "services/useLocationSearchService";
import {STCountry} from "api/filterOptionsApi/CountryApi/types";
import {STUser} from "api/organisationApi/types";
import useUserSearchService from "services/useUserSearchService";
import placeHolderImage from "assets/images/avatar-icon.png";
import {TProjectForm} from "mapx-components/Modals/ProjectCreateWithWorkflowsModal/ProjectForm/types";
import {STCompany} from "api/companyApi/types";
import useCompanySearchService from "services/useCompanySearchService";

const ProjectForm = ({
	formType,
	projectName = "",
	projectLocation = null,
	projectCompany = null,
	projectUsers = [],
	handleSubmitButtonClick,
	handleGoBackButtonClick,
	loading,
}: TProjectForm) => {
	const [name, setName] = useState(projectName);

	const [location, setLocation] = useState<Nullable<STCountry>>(projectLocation);

	const [company, setCompany] = useState<Nullable<STCompany>>(projectCompany);

	const [users, setUsers] = useState<STUser[]>(projectUsers);

	const {loadingLocations, locations} = useLocationSearchService();

	const {loadingCompanies, fetchCompanies} = useCompanySearchService();

	const {loadingUsers, fetchUsers} = useUserSearchService();

	const handleNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		setName(e.target.value);
	}, []);

	const handleSubmit = () => {
		handleSubmitButtonClick({
			name,
			location,
			users,
			company,
		});
	};

	return (
		<div>
			<div className={classNames(styles.formContainer, styles.withPadding)}>
				<form onSubmit={handleSubmit}>
					<div className={styles.itemBlock}>
						<TextInput
							label="Project Name*"
							onChange={handleNameChange}
							type="text"
							value={name}
							placeholder={"Enter a project name"}
						/>
					</div>

					<div className={styles.itemBlock} data-testid="location">
						<label className={styles.label}>Location</label>

						<SelectDropdown
							borderColor="#dadada"
							value={location}
							onChange={setLocation}
							options={locations}
							showClearIcon={!loadingLocations}
							isSearchable={true}
							isClearable={true}
							isLoading={loadingLocations}
							placeholder={"Select a Location"}
							noOptionsMessage={"Select a Location"}
							noResultsText={
								"No location found with your query, try to refine your query"
							}
						/>
					</div>

					<div className={styles.itemBlock} data-testid="client-company">
						<label className={styles.label} htmlFor="client company">
							Client Company
						</label>

						<SelectDropdown
							aria-label={"company"}
							inputId={"company"}
							name={"company"}
							cacheOptions
							isAsync={true}
							value={company}
							onChange={setCompany}
							loadOptions={fetchCompanies}
							showClearIcon={!loadingCompanies}
							isClearable={true}
							hideIndicator={true}
							isLoading={loadingCompanies}
							placeholder={"Which company is this role for?"}
							noOptionsMessage={"Search by company name"}
							noResultsText={
								"No company found with your query, try to refine your query"
							}
							formatOptionLabel={(company: STCompany) => (
								<div className={styles.dropdownItem}>
									<img
										src={company.logo_url || placeHolderImage}
										alt={company.name}
									/>
									<span>{company.name}</span>
								</div>
							)}
						/>
					</div>

					<div className={styles.itemBlock} data-testid="user">
						<label className={styles.label}>Invite user</label>

						<SelectDropdown
							isAsync={true}
							isMulti={true}
							borderColor="#dadada"
							value={users}
							onChange={setUsers}
							cacheOptions
							loadOptions={fetchUsers}
							showClearIcon={!loadingUsers}
							isSearchable={true}
							isClearable={true}
							isLoading={loadingUsers}
							placeholder={"Invite user by name or email"}
							noOptionsMessage={"Invite user by name or email"}
							noResultsText={
								"No user found with your query, try to refine your query"
							}
							formatOptionLabel={(user: STUser) => (
								<div className={styles.dropdownItem}>
									<img src={placeHolderImage} alt={user.first_name} />
									<span>{`${user.first_name} ${user.last_name}`}</span>
								</div>
							)}
						/>
					</div>
				</form>
			</div>

			<FooterButtons
				actionButtonLabel={
					formType === "create-form"
						? "Create New Project"
						: formType === "duplicate-form"
						? "Duplicate Project"
						: "Save Project"
				}
				handleActionButtonClick={handleSubmit}
				handleGoButtonClick={handleGoBackButtonClick}
				isLoading={loading}
				isActionButtonDisabled={name === "" || name?.length < 2}
			/>
		</div>
	);
};

export default ProjectForm;
