import {TRootState} from "types";

export const loadingMarketReportSelector = (state: TRootState) =>
	state.marketReport.loadingMarketReport;
export const creatingMarketReportSelector = (state: TRootState) =>
	state.marketReport.creatingMarketReport;
export const updatingMarketReportSelector = (state: TRootState) =>
	state.marketReport.updatingMarketReport;
export const deletingMarketReportSelector = (state: TRootState) =>
	state.marketReport.deletion_in_progress;
export const duplicatingMarketReportSelector = (state: TRootState) =>
	state.marketReport.duplicatingMarketReport;
export const displayMarketReportModalSelector = (state: TRootState) =>
	state.marketReport.displayMarketReportModal;
export const getMarketReportsSelector = (state: TRootState) => state.marketReport.results;
export const getMarketReportsPaginationSelector = (state: TRootState) =>
	state.marketReport.pagination;
export const getMarketReportCreatedBySelector = (state: TRootState) => state.marketReport.createdBy;
export const getMarketReportFormSelector = (state: TRootState) =>
	state.marketReport.marketReportForm;
export const getMarketReportDetailsSelector = (state: TRootState) =>
	state.marketReport.marketReportDetails;
export const getMRSelectedCompaniesSelector = (state: TRootState) =>
	state.marketReport.selectedCompanies;
export const mrDiversityStatsLoadingSelector = (state: TRootState) =>
	state.marketReport.diversityStatsLoading;
export const mrDiversityStatsSelectedTypeSelector = (state: TRootState) =>
	state.marketReport.diversityStatsSelectedType;
export const mrDiversityStatsReportSelector = (state: TRootState) =>
	state.marketReport.diversityStatsReport;
export const mrDiversePathwaysLoadingSelector = (state: TRootState) =>
	state.marketReport.diversePathwaysLoading;
export const mrDiversePathwaysSelectedTypeSelector = (state: TRootState) =>
	state.marketReport.diversePathwaysSelectedType;
export const mrDiversePathwaysModeSelector = (state: TRootState) =>
	state.marketReport.diversePathwaysMode;
export const mrDiversePathwaysReportSelector = (state: TRootState) =>
	state.marketReport.diversePathwaysReport;
export const mrDisabledFilterKeysOnCriteriaBreakDown = (state: TRootState) =>
	state.marketReport.disabledFilterKeysOnCriteriaBreakDown;
