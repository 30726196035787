import {clearCancelToken, getCancelToken} from "api/cancelTokens";
import mapXProjectApi from "api/projectApi";
import {
	GET_PAGINATED_TARGETLIST_COMPANIES_TOKEN,
	GET_TARGETLIST_COMPANIES_TOKEN,
	GET_TARGETLIST_COMPANY_IDS_TOKEN,
} from "api/requestCancelTokenStrings";
import mapXTargetListApi from "api/targetListApi";
import {successResponse} from "helpers/map";
import {projectSelector} from "store/mapx/project-list/projectListSelectors";
import {selectedCandidateIdsForAssessmentSelector} from "store/mapx/project/candidateScoringSelectors";
import {
	getProjectCandidateStatusesFailed,
	getProjectCandidateStatusesInit,
	setCandidateIdsForAssessment,
	setProjectCandidateStatuses,
} from "store/mapx/project/projectActions";
import {updateProjectSuccessChecklistItem} from "store/mapx/project/projectSuccessChecklistAsyncActions";
import {setMultipleFilterForCandidates} from "../filter/filterActions";
import {
	candidateJobTitleFilterModeSelector,
	candidateTargetListPositionSelector,
	candidateTargetListToggleSelector,
	selectedCompaniesSelector,
	tlCompaniesFilterKeyByPositionSelector,
	tlCompanyIdsByTargetListPositionSelector,
} from "../filter/filterSelectors";
import {updateProjectDone} from "../project-list/projectListActions";

import {
	addCandidateToTargetListDone,
	addCandidateToTargetListFail,
	addCandidateToTargetListInit,
	addCompaniesFromMarketMapToTargetListFail,
	addCompaniesFromMarketMapToTargetListInit,
	addCompanyToTargetListDone,
	addCompanyToTargetListFail,
	addCompanyToTargetListInit,
	getPaginatedTargetListCompaniesDone,
	getPaginatedTargetListCompaniesInProgress,
	getTargetListCandidateIDsFail,
	getTargetListCandidateIDsInit,
	getTargetListCompaniesDone,
	getTargetListCompaniesFail,
	getTargetListCompaniesInit,
	getTargetListCompanyIDsDone,
	getTargetListCompanyIDsFail,
	getTargetListCompanyIDsInit,
	getTargetListsByOwnerIDDone,
	getTargetListsByOwnerIDFail,
	getTargetListsByOwnerIDInit,
	removeCandidateFromTargetListDone,
	removeCandidateFromTargetListFail,
	removeCandidateFromTargetListInit,
	removeCandidatesFromTargetListDone,
	removeCandidatesFromTargetListFail,
	removeCandidatesFromTargetListInit,
	removeCompaniesFromTargetListDone,
	removeCompaniesFromTargetListFail,
	removeCompaniesFromTargetListInit,
	removeCompanyFromTargetListDone,
	removeCompanyFromTargetListFail,
	removeCompanyFromTargetListInit,
	saveCandidatesByFiltersDone,
	saveCandidatesByFiltersInit,
	saveCompaniesByFiltersDone,
	saveCompaniesByFiltersInit,
} from "./targetListActions";
import {targetListCompanyIdsSelector, targetListIDSelector} from "./targetListSelectors";
import {
	removeCompanyFromProjectSavedPeople,
	setTargetListFiltersForApCompanies,
} from "../additional-profiles/additionalProfilesAsyncActions";
import axios from "axios";
import {
	fetchTargetListCandidateIDs,
	getTargetListCandidates,
} from "./targetListCandidatesAsyncActions";
import {projectSavedCompanyPaginationSelector} from "../project/projectSelectors";

export const getTargetListsByOwnerID = () => async (dispatch, getState) => {
	try {
		dispatch(getTargetListsByOwnerIDInit());

		const state = getState();

		const ownerID = state.user.user.user_id;

		let {
			data: {results},
		} = await mapXTargetListApi.getTargetListsByOwnerID(ownerID);

		const isDetachedAvailable = results.some((targetList) => !targetList.project_id);

		if (results.length === 0) {
			const {data: targetList} = await mapXTargetListApi.createDetachedTargetList();

			results = [targetList];
		} else if (!isDetachedAvailable) {
			const {data: targetList} = await mapXTargetListApi.createDetachedTargetList();

			results = [...results, targetList];
		}

		await dispatch(getTargetListsByOwnerIDDone(results));

		dispatch(fetchTargetListCompanyIDs());
		dispatch(fetchTargetListCandidateIDs());
	} catch (error) {
		dispatch(getTargetListsByOwnerIDFail({error}));
	}
};

export const getTargetListCompanies = () => async (dispatch, getState) => {
	const cancelTokenSource = getCancelToken(GET_TARGETLIST_COMPANIES_TOKEN);

	try {
		const state = getState();

		const targetListID = targetListIDSelector(state);

		dispatch(getTargetListCompaniesInit());

		const apiPayload = {
			filters: {
				target_list: targetListID,
			},
			pagination: {
				page: 1,
				per_page: 50,
			},
		};

		const response = await mapXTargetListApi.getTargetListCompanies(apiPayload, {
			cancelToken: cancelTokenSource.token,
		});

		if (successResponse(response, 200)) {
			const data = response.data;

			dispatch(getTargetListCompaniesDone(data));

			dispatch(enableTargetListFilters());

			return data.results;
		}

		return [];
	} catch (e) {
		if (axios.isCancel(e)) {
			console.log("Request canceled:", e.message);
		} else {
			console.error(e);
			dispatch(getTargetListCompaniesFail({error: e}));
		}

		return [];
	} finally {
		clearCancelToken(GET_TARGETLIST_COMPANIES_TOKEN);
	}
};

export const getPaginatedTargetListCompanies = (pagination) => async (dispatch, getState) => {
	const cancelTokenSource = getCancelToken(GET_PAGINATED_TARGETLIST_COMPANIES_TOKEN);

	try {
		const state = getState();

		const targetListID = targetListIDSelector(state);
		const currentPagination = projectSavedCompanyPaginationSelector(state);

		const finalPagination = {
			page: pagination?.page ?? currentPagination?.page ?? 1,
			per_page: pagination?.per_page ?? currentPagination?.per_page ?? 50,
		};

		dispatch(getPaginatedTargetListCompaniesInProgress(true));

		const apiPayload = {
			filters: {
				target_list: targetListID,
			},
			pagination: {
				page: finalPagination.page,
				per_page: finalPagination.per_page,
			},
		};

		const response = await mapXTargetListApi.getTargetListCompanies(apiPayload, {
			cancelToken: cancelTokenSource.token,
		});

		if (successResponse(response, 200)) {
			const data = response.data;

			dispatch(getPaginatedTargetListCompaniesDone(data));
		}

		return [];
	} catch (e) {
		if (axios.isCancel(e)) {
			console.log("Request canceled:", e.message);
		} else {
			console.error(e);
			dispatch(getPaginatedTargetListCompaniesInProgress(false));
		}

		return [];
	} finally {
		clearCancelToken(GET_PAGINATED_TARGETLIST_COMPANIES_TOKEN);
	}
};

export const enableTargetListFilters = () => async (dispatch, getState) => {
	const state = getState();

	const isTargetListOn = candidateTargetListToggleSelector(state);

	if (isTargetListOn) {
		const state = getState();

		const position = candidateTargetListPositionSelector(state);

		const filterKey = tlCompaniesFilterKeyByPositionSelector(state, position);

		const targetListCompanyIDs = targetListCompanyIdsSelector(state);

		dispatch(setMultipleFilterForCandidates({[filterKey]: targetListCompanyIDs}));

		dispatch(setTargetListFiltersForApCompanies());
	}
};

export const addCandidateToTargetList = (candidateID) => async (dispatch, getState) => {
	try {
		dispatch(addCandidateToTargetListInit(candidateID));

		dispatch(getTargetListCandidateIDsInit());

		const state = getState();

		const targetListID = targetListIDSelector(state);

		const {data: candidate} = await mapXTargetListApi.addCandidateToTargetList(
			targetListID,
			candidateID,
		);

		dispatch(addCandidateToTargetListDone(candidate, targetListID));

		await dispatch(
			updateProjectSuccessChecklistItem({
				attribute: "has_saved_people",
				value: true,
			}),
		);
	} catch (error) {
		dispatch(addCandidateToTargetListFail({error}));
	}
};

export const addCompanyToTargetList = (companyID) => async (dispatch, getState) => {
	try {
		dispatch(addCompanyToTargetListInit(companyID));
		dispatch(getTargetListCompanyIDsInit());

		const state = getState();

		const targetListID = targetListIDSelector(state);

		const {data: company} = await mapXTargetListApi.addCompanyToTargetList(
			targetListID,
			companyID,
		);

		dispatch(addCompanyToTargetListDone(company, targetListID));

		await dispatch(
			updateProjectSuccessChecklistItem({
				attribute: "has_saved_companies",
				value: true,
			}),
		);

		const isTargetListOn = candidateTargetListToggleSelector(state);

		if (isTargetListOn) {
			const state = getState();

			const position = candidateTargetListPositionSelector(state);

			const filterKey = tlCompaniesFilterKeyByPositionSelector(state, position);

			const companyIds = tlCompanyIdsByTargetListPositionSelector(state);

			const existingCompanyIds = new Set(companyIds.map((id) => id));

			let newCompanies = [...companyIds];

			if (companyIds.length > 0) {
				const exist = existingCompanyIds.has(company.id);

				if (!exist) {
					newCompanies.push(company.id);
				}
			} else {
				newCompanies = [company.id];
			}

			dispatch(setMultipleFilterForCandidates({[filterKey]: newCompanies}));
		}
	} catch (error) {
		dispatch(addCompanyToTargetListFail({error}));
	}
};

export const removeCandidateFromTargetList = (candidate) => async (dispatch, getState) => {
	try {
		const state = getState();

		const targetListID = targetListIDSelector(state);

		dispatch(removeCandidateFromTargetListInit(candidate.id));

		dispatch(getProjectCandidateStatusesInit());

		dispatch(getTargetListCandidateIDsInit());

		await mapXTargetListApi.removeCandidateFromTargetList(targetListID, candidate.id);

		dispatch(removeCandidateFromTargetListDone(candidate, targetListID));

		const selectedCandidateIds = selectedCandidateIdsForAssessmentSelector(state);

		dispatch(
			setCandidateIdsForAssessment(
				[...selectedCandidateIds].filter((id) => id !== candidate.id),
			),
		);
	} catch (error) {
		console.error("error in removeCandidateFromTargetList function: ", error);

		const candidateID = candidate.id;

		dispatch(removeCandidateFromTargetListFail({error, candidateID}));
		dispatch(getTargetListCandidateIDsFail());
		dispatch(getProjectCandidateStatusesFailed());
	}
};

export const removeCompanyFromTargetList = (companyID) => async (dispatch, getState) => {
	try {
		const state = getState();

		const targetListID = targetListIDSelector(state);

		dispatch(removeCompanyFromTargetListInit(companyID));

		dispatch(getTargetListCompanyIDsInit());

		await mapXTargetListApi.removeCompanyFromTargetList(targetListID, companyID);

		dispatch(removeCompanyFromTargetListDone(companyID, targetListID));

		const isTargetListOn = candidateTargetListToggleSelector(state);

		if (isTargetListOn) {
			const position = candidateTargetListPositionSelector(state);
			const filterKey = tlCompaniesFilterKeyByPositionSelector(state, position);
			const companyIds = tlCompanyIdsByTargetListPositionSelector(state);

			dispatch(
				setMultipleFilterForCandidates({
					[filterKey]: companyIds.filter((item) => item !== companyID),
				}),
			);
		}

		dispatch(removeCompanyFromProjectSavedPeople);
	} catch (error) {
		dispatch(removeCompanyFromTargetListFail({error, companyID}));
		dispatch(getTargetListCompanyIDsFail());
	}
};

export const removeCandidatesFromTargetList = () => async (dispatch, getState) => {
	try {
		const state = getState();

		const targetListID = targetListIDSelector(state);

		dispatch(removeCandidatesFromTargetListInit());

		await mapXTargetListApi.removeCandidatesFromTargetList(targetListID);

		dispatch(removeCandidatesFromTargetListDone(targetListID));

		dispatch(setCandidateIdsForAssessment([]));

		dispatch(setProjectCandidateStatuses([]));
	} catch (error) {
		dispatch(removeCandidatesFromTargetListFail({error}));
	}
};

export const removeCompaniesFromTargetList = () => async (dispatch, getState) => {
	try {
		const state = getState();

		const targetListID = targetListIDSelector(state);

		dispatch(removeCompaniesFromTargetListInit());

		await mapXTargetListApi.removeCompaniesFromTargetList(targetListID);

		dispatch(removeCompaniesFromTargetListDone(targetListID));
	} catch (error) {
		dispatch(removeCompaniesFromTargetListFail({error}));
	}
};

export const saveCompaniesByFilters =
	(filters, showEnhancedCompanies = false) =>
	async (dispatch, getState) => {
		try {
			dispatch(saveCompaniesByFiltersInit());

			const state = getState();

			const project = projectSelector(state);

			const targetListID = targetListIDSelector(state);

			const selectedCompanies = selectedCompaniesSelector(state);

			await mapXTargetListApi.saveCompaniesByFilters(
				filters,
				targetListID,
				showEnhancedCompanies,
			);

			const targetListCompanyIds = await dispatch(fetchTargetListCompanyIDs());

			if (project) {
				const {data: newProject} = await mapXProjectApi.getProject(project.id);
				dispatch(updateProjectDone(newProject));
			}

			// SOME OF THE SELECTED COMPANIES ARE NOT ADDED BY FILTERS
			// SO WE MANUALLY ADD TO TL
			if (selectedCompanies?.length > 0) {
				for (const sCompany of selectedCompanies) {
					if (!targetListCompanyIds.includes(sCompany.id)) {
						dispatch(addCompanyToTargetList(sCompany.id));
					}
				}
			}

			dispatch(saveCompaniesByFiltersDone());

			await dispatch(
				updateProjectSuccessChecklistItem({
					attribute: "has_saved_companies",
					value: true,
				}),
			);

			dispatch(getTargetListCompanies());
		} catch (error) {
			dispatch(saveCompaniesByFiltersDone());

			return {error};
		}
	};

export const addCompaniesFromMarketMapToTargetList =
	(marketMapID, targetListID) => async (dispatch, getState) => {
		try {
			dispatch(addCompaniesFromMarketMapToTargetListInit());

			const state = getState();

			// const marketMaps = marketMapsModalSelector(state);

			targetListID = targetListID || targetListIDSelector(state);

			await mapXTargetListApi.addCompaniesFromMarketMapToTargetList(
				targetListID,
				marketMapID,
			);

			// const {data} = await mapXTargetListApi.getTargetListCompaniesForProject(targetListID);
			//
			// const companies =
			// 	data?.results || marketMaps.find((m) => m.id === marketMapID).companies;

			// dispatch(addCompaniesFromMarketMapToTargetListDone(companies));

			dispatch(getTargetListCompanies());
			dispatch(fetchTargetListCompanyIDs());

			await dispatch(
				updateProjectSuccessChecklistItem({
					attribute: "has_saved_companies",
					value: true,
				}),
			);
		} catch (error) {
			dispatch(addCompaniesFromMarketMapToTargetListFail(error));

			return {error};
		}
	};

export const fetchTargetListCompanyIDs = () => async (dispatch, getState) => {
	try {
		const cancelTokenSource = getCancelToken(GET_TARGETLIST_COMPANY_IDS_TOKEN);

		dispatch(getTargetListCompanyIDsInit());

		const state = getState();

		const targetListID = targetListIDSelector(state);

		const response = await mapXTargetListApi.getTargetListCompanyIDs(targetListID, {
			cancelToken: cancelTokenSource.token,
		});

		if (response) {
			if (successResponse(response, 200)) {
				const data = response.data.results;

				dispatch(getTargetListCompanyIDsDone(data));

				return data;
			} else {
				dispatch(getTargetListCompanyIDsFail({error: "something went wrong"}));

				return [];
			}
		}
	} catch (error) {
		console.error("error in fetchProjectSavedCompanyIDs:", error);

		return [];
	} finally {
		clearCancelToken(GET_TARGETLIST_COMPANY_IDS_TOKEN);
	}
};

export const saveCandidatesByFilters =
	(apiPayload, projectUpdate = true) =>
	async (dispatch, getState) => {
		try {
			dispatch(saveCandidatesByFiltersInit());

			const state = getState();

			const project = projectSelector(state);

			const targetListID = targetListIDSelector(state);

			const jobTitleFilterMode = candidateJobTitleFilterModeSelector(state);

			if (jobTitleFilterMode === "close" && apiPayload.filters) {
				delete apiPayload.filters?.current_job_titles_or;
			}

			if ("sorting_options" in apiPayload) {
				delete apiPayload.sorting_options;
			}

			await mapXTargetListApi.saveCandidatesByFilters(targetListID, apiPayload);

			if (project && projectUpdate) {
				const {data: newProject} = await mapXProjectApi.getProject(project.id);
				dispatch(updateProjectDone(newProject));
			}

			dispatch(getTargetListCandidates());
			dispatch(fetchTargetListCandidateIDs());

			dispatch(saveCandidatesByFiltersDone());

			await dispatch(
				updateProjectSuccessChecklistItem({
					attribute: "has_saved_people",
					value: true,
				}),
			);
		} catch (error) {
			dispatch(saveCandidatesByFiltersDone());

			return {error};
		}
	};
