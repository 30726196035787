import type {FC} from "react";

import type {IconProps} from "./types";

const ChevronIcon: FC<IconProps> = ({color = "#B5B1B0", height = 20, width = 20, ...rest}) => (
	<svg
		width={width}
		height={height}
		viewBox={`0 0 ${width} ${height}`}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...rest}
	>
		<mask
			id="mask0_3096_15553"
			style={{
				maskType: "alpha",
			}}
			maskUnits="userSpaceOnUse"
			x="0"
			y="0"
			width="20"
			height="20"
		>
			<rect width="20" height="20" fill="#D9D9D9" />
		</mask>
		<g mask="url(#mask0_3096_15553)">
			<path
				d="M10.0002 13.2169L4.84082 8.05752L6.06274 6.8356L10.0002 10.7731L13.9377 6.8356L15.1597 8.05752L10.0002 13.2169Z"
				fill={color}
			/>
		</g>
	</svg>
);

export default ChevronIcon;
