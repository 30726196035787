import {TRootState} from "types";
import {TConnectorLogic, TPositionLogic} from "store/mapx/market-report/types";
import {createSelector} from "reselect";
import {
	companyHeadcountRangeOptionsSelector,
	companyRevenueRangeOptionsSelector,
	companyTypeOptionSelector,
	flattenIndustriesSelector,
	jobFunctionOptionsSelector,
} from "store/mapx/search/searchSelectors";
import {STJobFunction} from "api/filterOptionsApi/JobFunctionApi/types";
import {TMarketReportForm} from "api/marketReportApi/types";
import {getSpecialismsTagByIds} from "mapx-components/Filters/FunctionNSpecialismSearchFilter/utils";
import {SIIndustry} from "api/filterOptionsApi/IndustryApi/types";
import {getIndustriesBySelectedIds} from "mapx-components/Filters/IndustrySearchFilter/utils";
import {TCompanyHeadcountRangeItem} from "mapx-components/Filters/CompanyHeadcountSearchFilter/types";
import {TCompanyRevenueRangeItem} from "mapx-components/Filters/CompanyRevenueSearchFilter/types";
import {STCompanyType} from "api/companyApi/types";
import {getCompanyTypeLabelsByIds} from "mapx-components/Filters/CompanyTypeSearchFilter/utils";
import {flattenCountriesSelector} from "store/mapx/filter/filterSelectors";
import {getSelectedCountriesAndRegions} from "mapx-components/Filters/LocationSearchFilter/utils";
import {getMarketReportFormSelector} from "store/mapx/market-report/marketReportSelector";

const getPosition = (_: TRootState, position: TPositionLogic) => position;
const getConnectLogic = (_: TRootState, __: TRootState, logic: TConnectorLogic) => logic;

export const mRCandidateLanguageSelector = (state: TRootState) =>
	state.marketReport.marketReportForm.filters.languages;

/**
 ** JOB TITLE SELECTORS START-------------------
 **/
export const mRCandidateJobTitleFilterModeSelector = (state: TRootState) =>
	state.marketReport.jobTitleFilterMode;

export const mRCandidateSelectedJobTitlesSelector = (state: TRootState) =>
	state.marketReport.marketReportForm.filters.current_job_titles_or || [];

export const mRCandidateSuggestedJobTitlesSelector = (state: TRootState) =>
	state.marketReport.marketReportForm.filters.suggested_job_titles || [];

/**
 ** JOB TITLE SELECTORS END-------------------
 **/

/**
 ** COMPANY TARGET LIST SELECTORS END-------------------
 **/
export const mRCompanyFilterConfigSelector = (state: TRootState) =>
	state.marketReport.companyFilterConfig;

export const mRAllCompaniesFilterCountSelector = createSelector(
	getMarketReportFormSelector,
	(marketReportForm: TMarketReportForm) =>
		marketReportForm.filters.current_companies.length +
		marketReportForm.filters.previous_companies.length +
		marketReportForm.filters.companies.length,
);
export const mRCompaniesFilterKeyByPosition = createSelector(getPosition, (position) => {
	switch (position) {
		case "current":
			return "current_companies";
		case "previous":
			return "previous_companies";
		case "any":
		default:
			return "companies";
	}
});

export const mRCandidateCompaniesByPositionSelector = createSelector(
	[getMarketReportFormSelector, getPosition],
	(marketReportForm: TMarketReportForm, position) => {
		switch (position) {
			case "current":
				return marketReportForm.filters.current_companies || [];
			case "previous":
				return marketReportForm.filters.previous_companies || [];
			case "any":
				return marketReportForm.filters.companies || [];
			default:
				return [];
		}
	},
);
/**
 ** JOB TITLE SELECTORS END-------------------
 **/

/**
 ** JOB FUNCTION SELECTORS START-------------------
 **/
export const mRJobFunctionFilterConfigSelector = (state: TRootState) =>
	state.marketReport.candidateJobFunctionFilterConfig;

export const mRJobFunctionCandidatePositionSelector = (state: TRootState) =>
	state.marketReport.candidateJobFunctionFilterConfig.active_position;

export const mRJFCurrentPositionConnectivityLogicSelector = (state: TRootState) =>
	state.marketReport.candidateJobFunctionFilterConfig.active_current_position_connect_logic;

export const mRJFPreviousPositionConnectivityLogicSelector = (state: TRootState) =>
	state.marketReport.candidateJobFunctionFilterConfig.active_previous_position_connect_logic;

export const mRJFAnyPositionConnectivityLogicSelector = (state: TRootState) =>
	state.marketReport.candidateJobFunctionFilterConfig.active_any_position_connect_logic;

export const mRSpecialismsByPositionSelector = createSelector(
	[getMarketReportFormSelector, getPosition, getConnectLogic],
	(marketReportForm, position, logic) => {
		const filters = marketReportForm.filters;

		if (position === "current" && logic === "and") {
			return filters.current_specialisms_and || [];
		} else if (position === "current" && logic === "or") {
			return filters.current_specialisms_or || [];
		} else if (position === "current" && logic === "not") {
			return filters.current_specialisms_not || [];
		} else if (position === "previous" && logic === "and") {
			return filters.previous_specialisms_and || [];
		} else if (position === "previous" && logic === "or") {
			return filters.previous_specialisms_or || [];
		} else if (position === "previous" && logic === "not") {
			return filters.previous_specialisms_not || [];
		} else if (position === "any" && logic === "and") {
			return filters.specialisms_and || [];
		} else if (position === "any" && logic === "or") {
			return filters.specialisms_or || [];
		} else {
			return filters.specialisms_not || [];
		}
	},
);

export const mRJobFunctionSelectionTreeSelector = createSelector(
	mRSpecialismsByPositionSelector,
	jobFunctionOptionsSelector,
	(specialisms, options: []) => {
		const speFunc: Map<number, number> = new Map();
		const tree: Record<number, number[]> = {};

		options.forEach((o: STJobFunction) => {
			o.specialisms.forEach((s) => {
				if (specialisms.includes(s.id)) {
					speFunc.set(s.id, o.id);
				}
			});
		});

		specialisms.forEach((id: number) => {
			const functionId = speFunc.get(id);

			if (functionId !== undefined) {
				tree[functionId] = [...(tree[functionId] || []), id].sort();
			}
		});

		return tree;
	},
);

export const mrCurrentJobFunctionTagsSelector = createSelector(
	[getMarketReportFormSelector, jobFunctionOptionsSelector],
	(marketReportForm: TMarketReportForm, options: []) => {
		const filters = marketReportForm.filters;
		const andSpecialisms = filters.current_specialisms_and || [];
		const orSpecialisms = filters.current_specialisms_or || [];
		const notSpecialisms = filters.current_specialisms_not || [];

		const specialisms = [...andSpecialisms, ...orSpecialisms, ...notSpecialisms];

		return getSpecialismsTagByIds(specialisms, options);
	},
);

export const mrPreviousJobFunctionTagsSelector = createSelector(
	[getMarketReportFormSelector, jobFunctionOptionsSelector],
	(marketReportForm: TMarketReportForm, options: []) => {
		const filters = marketReportForm.filters;
		const andSpecialisms = filters.previous_specialisms_and || [];
		const orSpecialisms = filters.previous_specialisms_or || [];
		const notSpecialisms = filters.previous_specialisms_not || [];

		const specialisms = [...andSpecialisms, ...orSpecialisms, ...notSpecialisms];

		return getSpecialismsTagByIds(specialisms, options);
	},
);

export const mrAnyJobFunctionTagsSelector = createSelector(
	[getMarketReportFormSelector, jobFunctionOptionsSelector],
	(marketReportForm: TMarketReportForm, options: []) => {
		const filters = marketReportForm.filters;
		const andSpecialisms = filters.specialisms_and || [];
		const orSpecialisms = filters.specialisms_or || [];
		const notSpecialisms = filters.specialisms_not || [];

		const specialisms = [...andSpecialisms, ...orSpecialisms, ...notSpecialisms];

		return getSpecialismsTagByIds(specialisms, options);
	},
);

export const mrSpecialismFilterKeyByPosition = createSelector(
	getPosition,
	getConnectLogic,
	(position, logic) => {
		if (position === "current" && logic === "and") {
			return "current_specialisms_and";
		} else if (position === "current" && logic === "or") {
			return "current_specialisms_or";
		} else if (position === "current" && logic === "not") {
			return "current_specialisms_not";
		} else if (position === "previous" && logic === "and") {
			return "previous_specialisms_and";
		} else if (position === "previous" && logic === "or") {
			return "previous_specialisms_or";
		} else if (position === "previous" && logic === "not") {
			return "previous_specialisms_not";
		} else if (position === "any" && logic === "and") {
			return "specialisms_and";
		} else if (position === "any" && logic === "or") {
			return "specialisms_or";
		} else {
			return "specialisms_not";
		}
	},
);

export const mrAllSpecialismsCountSelector = createSelector(
	getMarketReportFormSelector,
	(marketReportForm) =>
		marketReportForm.filters.current_specialisms_and.length +
		marketReportForm.filters.current_specialisms_or.length +
		marketReportForm.filters.current_specialisms_not.length +
		marketReportForm.filters.previous_specialisms_and.length +
		marketReportForm.filters.previous_specialisms_or.length +
		marketReportForm.filters.previous_specialisms_not.length +
		marketReportForm.filters.specialisms_and.length +
		marketReportForm.filters.specialisms_or.length +
		marketReportForm.filters.specialisms_not.length,
);

/**
 ** JOB FUNCTION SELECTORS END-------------------
 **/

/**
 ** INDUSTRY SELECTORS START-------------------
 **/
export const mRIndustryFilterConfigSelector = (state: TRootState) =>
	state.marketReport.candidateIndustriesFilterConfig;

export const mRSelectedAnyIndustriesSelector = createSelector(
	[getMarketReportFormSelector, flattenIndustriesSelector],
	(marketReportForm: TMarketReportForm, options = []) => {
		const filters = marketReportForm.filters;
		const industries = filters.industries || [];

		return getIndustriesBySelectedIds(industries, options as SIIndustry[]);
	},
);

export const mRSelectedCurrentIndustriesSelector = createSelector(
	[getMarketReportFormSelector, flattenIndustriesSelector],
	(marketReportForm: TMarketReportForm, options = []) => {
		const filters = marketReportForm.filters;
		const industries = filters.current_industries || [];

		return getIndustriesBySelectedIds(industries, options as SIIndustry[]);
	},
);

export const mRSelectedPreviousIndustriesSelector = createSelector(
	[getMarketReportFormSelector, flattenIndustriesSelector],
	(marketReportForm: TMarketReportForm, options = []) => {
		const filters = marketReportForm.filters;
		const industries = filters.previous_industries || [];

		return getIndustriesBySelectedIds(industries, options as SIIndustry[]);
	},
);

export const mRAllIndustriesCountSelector = createSelector(
	[getMarketReportFormSelector],
	(marketReportForm: TMarketReportForm) =>
		marketReportForm.filters.industries.length +
		marketReportForm.filters.current_industries.length +
		marketReportForm.filters.previous_industries.length,
);

export const mRIndustriesFilterKeyByPosition = createSelector(getPosition, (position) => {
	switch (position) {
		case "current":
			return "current_industries";
		case "previous":
			return "previous_industries";
		case "any":
		default:
			return "industries";
	}
});

export const mRCandidateIndustriesByPositionSelector = createSelector(
	[getMarketReportFormSelector, getPosition],
	(marketReportForm: TMarketReportForm, position) => {
		const currentIndustries = marketReportForm.filters.current_industries;
		const previousIndustries = marketReportForm.filters.previous_industries;
		const anyIndustries = marketReportForm.filters.industries;

		switch (position) {
			case "current":
				return currentIndustries || [];
			case "previous":
				return previousIndustries || [];
			case "any":
				return anyIndustries || [];
			default:
				return [];
		}
	},
);

/**
 ** INDUSTRY SELECTORS END-------------------
 **/

/**
 ** HEADCOUNT SELECTORS START-------------------
 **/

export const mRCandidateCompanyHeadcountFilterConfigSelector = (state: TRootState) =>
	state.marketReport.candidateCompanyHeadcountFilterConfig;

export const mrSelectedCurrentCompanyHeadcountTagsSelector = createSelector(
	[getMarketReportFormSelector, companyHeadcountRangeOptionsSelector],
	(marketReportForm: TMarketReportForm, options) => {
		const current: TCompanyHeadcountRangeItem[] = [];

		options.forEach((item: TCompanyHeadcountRangeItem) => {
			if (marketReportForm.filters.current_company_size.includes(item.id)) {
				current.push(item);
			}
		});

		return current;
	},
);

export const mRSelectedAnyCompanyHeadcountTagsSelector = createSelector(
	[getMarketReportFormSelector, companyHeadcountRangeOptionsSelector],
	(marketReportForm: TMarketReportForm, options) => {
		const current: TCompanyHeadcountRangeItem[] = [];

		options.forEach((item: TCompanyHeadcountRangeItem) => {
			if (marketReportForm.filters.company_size.includes(item.id)) {
				current.push(item);
			}
		});

		return current;
	},
);

export const mRSelectedPreviousCompanyHeadcountTagsSelector = createSelector(
	[getMarketReportFormSelector, companyHeadcountRangeOptionsSelector],
	(marketReportForm: TMarketReportForm, options) => {
		const current: TCompanyHeadcountRangeItem[] = [];

		options.forEach((item: TCompanyHeadcountRangeItem) => {
			if (marketReportForm.filters.previous_company_size.includes(item.id)) {
				current.push(item);
			}
		});

		return current;
	},
);

export const mRCompanyHeadcountFilterKeyByPosition = createSelector(getPosition, (position) => {
	switch (position) {
		case "current":
			return "current_company_size";
		case "previous":
			return "previous_company_size";
		case "any":
		default:
			return "company_size";
	}
});

export const mRCandidateCompanyHeadcountByPositionSelector = createSelector(
	[getMarketReportFormSelector, getPosition],
	(marketReportForm: TMarketReportForm, position) => {
		switch (position) {
			case "current":
				return marketReportForm.filters.current_company_size || [];
			case "previous":
				return marketReportForm.filters.previous_company_size || [];
			case "any":
				return marketReportForm.filters.company_size || [];
			default:
				return [];
		}
	},
);

export const mRCandidatesAllCompanyHeadcountCountSelector = createSelector(
	[getMarketReportFormSelector],
	(marketReportForm: TMarketReportForm) =>
		marketReportForm.filters.company_size.length +
		marketReportForm.filters.previous_company_size.length +
		marketReportForm.filters.current_company_size.length,
);

/**
 ** HEADCOUNT SELECTORS END-------------------
 **/

/**
 ** REVENUE SELECTORS START-------------------
 **/

export const mRCandidateCompanyRevenueFilterConfigSelector = (state: TRootState) =>
	state.marketReport.candidateCompanyRevenueFilterConfig;

export const mRSelectedCurrentCompanyRevenueTagsSelector = createSelector(
	[getMarketReportFormSelector, companyRevenueRangeOptionsSelector],
	(marketReportForm: TMarketReportForm, options = []) => {
		const current: TCompanyRevenueRangeItem[] = [];

		options.forEach((item: TCompanyRevenueRangeItem) => {
			if (marketReportForm.filters.current_revenue.includes(item.id)) {
				current.push(item);
			}
		});

		return current;
	},
);

export const mRSelectedAnyCompanyRevenueTagsSelector = createSelector(
	[getMarketReportFormSelector, companyRevenueRangeOptionsSelector],
	(marketReportForm: TMarketReportForm, options = []) => {
		const current: TCompanyRevenueRangeItem[] = [];

		options.forEach((item: TCompanyRevenueRangeItem) => {
			if (marketReportForm.filters.revenue.includes(item.id)) {
				current.push(item);
			}
		});

		return current;
	},
);

export const mRSelectedPreviousCompanyRevenueTagsSelector = createSelector(
	[getMarketReportFormSelector, companyRevenueRangeOptionsSelector],
	(marketReportForm: TMarketReportForm, options = []) => {
		const current: TCompanyRevenueRangeItem[] = [];

		options.forEach((item: TCompanyRevenueRangeItem) => {
			if (marketReportForm.filters.previous_revenue.includes(item.id)) {
				current.push(item);
			}
		});

		return current;
	},
);

export const mRCompanyRevenueFilterKeyByPosition = createSelector(getPosition, (position) => {
	switch (position) {
		case "current":
			return "current_revenue";
		case "previous":
			return "previous_revenue";
		case "any":
		default:
			return "revenue";
	}
});

export const mRCandidateCompanyRevenueByPositionSelector = createSelector(
	[getMarketReportFormSelector, getPosition],
	(marketReportForm: TMarketReportForm, position) => {
		switch (position) {
			case "current":
				return marketReportForm.filters.current_revenue || [];
			case "previous":
				return marketReportForm.filters.previous_revenue || [];
			case "any":
				return marketReportForm.filters.revenue || [];
			default:
				return [];
		}
	},
);

export const mRCandidatesAllCompanyRevenueCountSelector = createSelector(
	[getMarketReportFormSelector],
	(marketReportForm: TMarketReportForm) =>
		marketReportForm.filters.revenue.length +
		marketReportForm.filters.previous_revenue.length +
		marketReportForm.filters.current_revenue.length,
);

/**
 ** REVENUE SELECTORS END-------------------
 **/

/**
 ** COMPANY TYPE SELECTORS START-------------------
 **/

export const mRCompanyTypeFilterConfigSelector = (state: TRootState) =>
	state.marketReport.candidateCompanyTypeFilterConfig;

export const mRSelectedAnyCompanyTypesSelector = createSelector(
	[getMarketReportFormSelector, companyTypeOptionSelector],
	(marketReportForm: TMarketReportForm, options: STCompanyType[]) => {
		return getCompanyTypeLabelsByIds(marketReportForm.filters.company_type, options);
	},
);
export const mRSelectedPreviousCompanyTypesSelector = createSelector(
	[getMarketReportFormSelector, companyTypeOptionSelector],
	(marketReportForm: TMarketReportForm, options: STCompanyType[]) => {
		return getCompanyTypeLabelsByIds(marketReportForm.filters.previous_company_type, options);
	},
);
export const mRSelectedCurrentCompanyTypesSelector = createSelector(
	[getMarketReportFormSelector, companyTypeOptionSelector],
	(marketReportForm: TMarketReportForm, options: STCompanyType[]) => {
		return getCompanyTypeLabelsByIds(marketReportForm.filters.current_company_type, options);
	},
);
export const mRAllCompanyTypesCountSelector = createSelector(
	getMarketReportFormSelector,
	(marketReportForm: TMarketReportForm) =>
		marketReportForm.filters.company_type.length +
		marketReportForm.filters.previous_company_type.length +
		marketReportForm.filters.current_company_type.length,
);
export const mRCompanyTypeFilterKeyByPosition = createSelector(getPosition, (position) => {
	switch (position) {
		case "current":
			return "current_company_type";
		case "previous":
			return "previous_company_type";
		case "any":
		default:
			return "company_type";
	}
});

export const mRCandidateCompanyTypesByPositionSelector = createSelector(
	[getMarketReportFormSelector, getPosition],
	(marketReportForm: TMarketReportForm, position) => {
		switch (position) {
			case "current":
				return marketReportForm.filters.current_company_type || [];
			case "previous":
				return marketReportForm.filters.previous_company_type || [];
			case "any":
				return marketReportForm.filters.company_type || [];
			default:
				return [];
		}
	},
);

/**
 ** COMPANY TYPE SELECTORS END-------------------
 **/

/**
 ** LOCATION SELECTORS START-------------------
 **/

export const mRLocationFilterConfigSelector = (state: TRootState) =>
	state.marketReport.candidateLocationFilterConfig;

export const mRSelectedAnyCountriesSelector = createSelector(
	[getMarketReportFormSelector, flattenCountriesSelector],
	(marketReportForm: TMarketReportForm, options) =>
		getSelectedCountriesAndRegions(
			marketReportForm.filters.countries_or,
			marketReportForm.filters.regions_or,
			options,
		),
);

export const mRSelectedPreviousCountriesSelector = createSelector(
	[getMarketReportFormSelector, flattenCountriesSelector],
	(marketReportForm: TMarketReportForm, options) =>
		getSelectedCountriesAndRegions(
			marketReportForm.filters.previous_countries_or,
			marketReportForm.filters.previous_regions_or,
			options,
		),
);

export const mRSelectedCurrentCountriesSelector = createSelector(
	[getMarketReportFormSelector, flattenCountriesSelector],
	(marketReportForm: TMarketReportForm, options) =>
		getSelectedCountriesAndRegions(
			marketReportForm.filters.current_countries_or,
			marketReportForm.filters.current_regions_or,
			options,
		),
);

export const mRAllCountriesCountSelector = createSelector(
	[getMarketReportFormSelector],
	(marketReportForm: TMarketReportForm) =>
		marketReportForm.filters.countries_or.length +
		marketReportForm.filters.regions_or.length +
		marketReportForm.filters.previous_countries_or.length +
		marketReportForm.filters.previous_regions_or.length +
		marketReportForm.filters.current_countries_or.length +
		marketReportForm.filters.current_regions_or.length,
);

export const mRLocationFilterKeyByPosition = createSelector(getPosition, (position) => {
	switch (position) {
		case "current":
			return "current_countries_or";
		case "previous":
			return "previous_countries_or";
		case "any":
		default:
			return "countries_or";
	}
});

export const mRRegionFilterKeyByPosition = createSelector(getPosition, (position) => {
	switch (position) {
		case "current":
			return "current_regions_or";
		case "previous":
			return "previous_regions_or";
		case "any":
		default:
			return "regions_or";
	}
});

export const mRCandidateCountryIdsByPositionSelector = createSelector(
	[getMarketReportFormSelector, getPosition],
	(marketReportForm: TMarketReportForm, position) => {
		switch (position) {
			case "current":
				return marketReportForm.filters.current_countries_or || [];
			case "previous":
				return marketReportForm.filters.previous_countries_or || [];
			case "any":
				return marketReportForm.filters.countries_or || [];
			default:
				return [];
		}
	},
);

export const mRCandidateRegionIdsByPositionSelector = createSelector(
	[getMarketReportFormSelector, getPosition],
	(marketReportForm: TMarketReportForm, position) => {
		switch (position) {
			case "current":
				return marketReportForm.filters.current_regions_or || [];
			case "previous":
				return marketReportForm.filters.previous_regions_or || [];
			case "any":
				return marketReportForm.filters.regions_or || [];
			default:
				return [];
		}
	},
);

/**
 ** LOCATION SELECTORS END-------------------
 **/

/**
 ** KEYWORDS SELECTORS START-------------------
 **/

export const mRKeywordFilterConfigSelector = (state: TRootState) =>
	state.marketReport.candidateKeywordFilterConfig;

export const mRKeywordsByPositionSelector = createSelector(
	[getMarketReportFormSelector, getPosition, getConnectLogic],
	(marketReportForm: TMarketReportForm, position, logic) => {
		if (position === "current" && logic === "and") {
			return marketReportForm.filters.current_text_keywords_and || [];
		} else if (position === "current" && logic === "or") {
			return marketReportForm.filters.current_text_keywords_or || [];
		} else if (position === "current" && logic === "not") {
			return marketReportForm.filters.current_text_keywords_not || [];
		} else if (position === "previous" && logic === "and") {
			return marketReportForm.filters.previous_text_keywords_and || [];
		} else if (position === "previous" && logic === "or") {
			return marketReportForm.filters.previous_text_keywords_or || [];
		} else if (position === "previous" && logic === "not") {
			return marketReportForm.filters.previous_text_keywords_not || [];
		} else if (position === "any" && logic === "and") {
			return marketReportForm.filters.text_keywords_and || [];
		} else if (position === "any" && logic === "or") {
			return marketReportForm.filters.text_keywords_or || [];
		} else {
			return marketReportForm.filters.text_keywords_not || [];
		}
	},
);

export const mRCurrentKeywordTagsSelector = createSelector(
	[getMarketReportFormSelector],
	(marketReportForm: TMarketReportForm) => {
		return [
			...marketReportForm.filters.current_text_keywords_and,
			...marketReportForm.filters.current_text_keywords_or,
			...marketReportForm.filters.current_text_keywords_not,
		];
	},
);
export const mRPreviousKeywordTagsSelector = createSelector(
	[getMarketReportFormSelector],
	(marketReportForm: TMarketReportForm) => {
		return [
			...marketReportForm.filters.previous_text_keywords_and,
			...marketReportForm.filters.previous_text_keywords_or,
			...marketReportForm.filters.previous_text_keywords_not,
		];
	},
);
export const mRAnyKeywordTagsSelector = createSelector(
	[getMarketReportFormSelector],
	(marketReportForm: TMarketReportForm) => {
		return [
			...marketReportForm.filters.text_keywords_and,
			...marketReportForm.filters.text_keywords_or,
			...marketReportForm.filters.text_keywords_not,
		];
	},
);

export const mRKeywordFilterKeyByPosition = createSelector(
	getPosition,
	getConnectLogic,
	(position, logic) => {
		if (position === "current" && logic === "and") {
			return "current_text_keywords_and";
		} else if (position === "current" && logic === "or") {
			return "current_text_keywords_or";
		} else if (position === "current" && logic === "not") {
			return "current_text_keywords_not";
		} else if (position === "previous" && logic === "and") {
			return "previous_text_keywords_and";
		} else if (position === "previous" && logic === "or") {
			return "previous_text_keywords_or";
		} else if (position === "previous" && logic === "not") {
			return "previous_text_keywords_not";
		} else if (position === "any" && logic === "and") {
			return "text_keywords_and";
		} else if (position === "any" && logic === "or") {
			return "text_keywords_or";
		} else {
			return "text_keywords_not";
		}
	},
);

export const mRAllKeywordsCountSelector = createSelector(
	[getMarketReportFormSelector],
	(marketReportForm: TMarketReportForm) =>
		marketReportForm.filters.current_text_keywords_and.length +
		marketReportForm.filters.current_text_keywords_or.length +
		marketReportForm.filters.current_text_keywords_not.length +
		marketReportForm.filters.previous_text_keywords_and.length +
		marketReportForm.filters.previous_text_keywords_or.length +
		marketReportForm.filters.previous_text_keywords_not.length +
		marketReportForm.filters.text_keywords_and.length +
		marketReportForm.filters.text_keywords_or.length +
		marketReportForm.filters.text_keywords_not.length,
);

/**
 ** KEYWORDS SELECTORS END-------------------
 **/

/**
 ** DEAL EXPERIENCE SELECTORS END-------------------
 **/

export const mRCandidateCompanyEventSizeSelector = (state: TRootState) =>
	state.marketReport.marketReportForm.filters.company_events.size;

export const mRCandidateCompanyEventTypesSelector = (state: TRootState) =>
	state.marketReport.marketReportForm.filters.company_events.types;

export const mRCandidateCompanyEventsSelector = (state: TRootState) =>
	state.marketReport.marketReportForm.filters.company_events;

/**
 ** DEAL EXPERIENCE SELECTORS END-------------------
 **/
