import {TRootState} from "types";

export const candidateCompanyEventSizeSelector = (state: TRootState) =>
	state.filter.candidate_filters.company_events.size;

export const candidateCompanyEventTypesSelector = (state: TRootState) =>
	state.filter.candidate_filters.company_events.types;

export const candidateCompanyEventsSelector = (state: TRootState) =>
	state.filter.candidate_filters.company_events;
export const isSizeDropDownActiveOnDEFilterSelector = (state: TRootState) =>
	state.filter.isSizeDropDownActiveOnDEFilter;

export const isDateDropDownActiveOnDEFilterSelector = (state: TRootState) =>
	state.filter.isDateDropDownActiveOnDEFilter;

export const isTypeDropDownActiveOnDEFilterSelector = (state: TRootState) =>
	state.filter.isTypeDropDownActiveOnDEFilter;
