import React, {type FC} from "react";
import type {IconProps} from "assets/icons/types";

export const ExpandIcon: FC<IconProps> = (props) => (
	<svg
		width="32"
		height="32"
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<rect x="0.5" y="0.5" width="31" height="31" rx="3.5" stroke="#DAD8D7" />
		<mask
			id="mask0_3447_979"
			style={{maskType: "alpha"}}
			maskUnits="userSpaceOnUse"
			x="6"
			y="6"
			width="20"
			height="20"
		>
			<rect x="6" y="6" width="20" height="20" fill="#D9D9D9" />
		</mask>
		<g mask="url(#mask0_3447_979)">
			<path
				d="M10.1665 21.8332V16.8332H11.8332V20.1665H15.1665V21.8332H10.1665ZM20.1665 15.1665V11.8332H16.8332V10.1665H21.8332V15.1665H20.1665Z"
				fill="#837D7C"
			/>
		</g>
	</svg>
);
