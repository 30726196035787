import {ArrowUp, RecommendedIcon} from "assets/icons";
import EnhanceIcon from "assets/icons/EnhanceIcon";
import classNames from "classnames";

import {Checkbox, LazyLoadImageWrapper, UpgradeNowCard} from "components";

import {useAppDispatch, useAppSelector} from "hooks";

import useEventMonitor from "hooks/mapx/useEventMonitor";
import useUserBehaviorTrackHook from "hooks/mapx/useUserBehaviorTrackHook";
import {ActionAltButton, CandidateExperience, GeneralButton} from "mapx-components";
import AdditionalProfileActions from "mapx-components/AdditionalProfileActions";

import React, {useCallback, useEffect, useMemo, useState} from "react";

import {BiPhone, BiUser} from "react-icons/bi";
import {targetListCompaniesSelector} from "store/mapx/target-list/targetListSelectors";

import styles from "./projectCandidateCard.module.scss";
import css from "../CandidateCard/CandidateInformation/candidateInformation.module.scss";
import {TProjectCandidateCardProps} from "mapx-components/Cards/ProjectCandidateCard/types";
import {STCompanyShort} from "api/companyApi/types";
import {getSingleCandidateDone} from "store/mapx/candidate/candidateActions";
import CandidateContactDetails from "../CandidateContactDetails";
import CandidateScoring from "../CandidateScoring";
import {
	activeProjectReportViewOnCandidateCardSelector,
	expandedCandidateIDsForProjectSelector,
	expandedCriterionsForProjectSelector,
} from "store/mapx/project/projectSelectors";
import CandidateSummary from "mapx-components/Cards/CandidateSummary";
import {handleCandidateSelectionForAssessment} from "store/mapx/project/projectAssessmentAsyncActions";
import {setExpandedCandidateIDsForProject} from "store/mapx/project/projectActions";
import useProjectSavedCandidatesHook from "mapx-pages/Project/SavedPeople/useProjectSavedCandidatesHook";

export default function ProjectCandidateCard({
	candidate,
	position = null,
	searchType,
	shouldTrack = false,
	isAdditionalProfileList = false,
	displayAllExperience = false,
	expandAllCriterions = false,
	displayDealExperience = false,
	upgradeView = false,
	isRecommended = false,
}: TProjectCandidateCardProps) {
	const dispatch = useAppDispatch();

	const {
		handleItemClick,
		loadingCandidateContactDetails,
		isCandidateSelectedForAssessment,
		isCurrentCandidateAssessmentInProgress,
		positions,
		isThisAISearchType,
		handleGenerateContactButtonClick,
		isCurrentCurrentCandidateRefreshIsInProgress,
		PROJECT_STATUS_DROPDOWN,
	} = useProjectSavedCandidatesHook(candidate, searchType);

	const [displayAllExp, setDisplayAllExp] = useState(false);

	const {trackStart, trackComplete, itemEventDetails} = useUserBehaviorTrackHook({
		itemType: "candidate",
		itemId: candidate.id,
		source: searchType,
		isRecommended: false,
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		position,
	});

	const activeProjectReportViewOnCandidateCard = useAppSelector(
		activeProjectReportViewOnCandidateCardSelector,
	);

	const expandedCandidateIDs = useAppSelector(expandedCandidateIDsForProjectSelector);

	/**
	 ** USER BEHAVIOR TRACKING LOGICS
	 */
	const containerRef = useEventMonitor({shouldTrack, itemEventDetails});

	/**
	 * CHECKING IF OUTSIDE COMPANIES
	 */

	const targetListCompanies = useAppSelector(targetListCompaniesSelector);

	const isFromOutsideCompany = useMemo(() => {
		const targetCompanyIds = targetListCompanies.map((company: STCompanyShort) => company.id);

		if (positions && positions?.length > 0 && positions[0]?.company !== undefined) {
			for (const exp of positions) {
				if (exp.year_to === null) {
					const foundCompany = targetCompanyIds?.includes(exp.company.id);

					if (foundCompany) {
						return false;
					}
				}
			}

			return true;
		} else {
			return true; // if no experience means outside company
		}
	}, [positions, targetListCompanies]);

	const displaySpecialIndicator = useMemo(() => {
		if (isThisAISearchType) {
			return isFromOutsideCompany;
		} else {
			return false;
		}
	}, [isFromOutsideCompany, isThisAISearchType]);

	const candidateExperiences = useMemo(() => {
		return positions && positions?.length > 0
			? displayAllExp
				? positions
				: positions.slice(0, 3)
			: [];
	}, [displayAllExp, positions]);

	const handleExpandExperience = useCallback(
		(candidateID: number) => {
			setDisplayAllExp((prev) => !prev);

			const isAlreadyExpanded = expandedCandidateIDs?.includes(candidateID);
			const newExpandedExperienceCandidate = isAlreadyExpanded
				? expandedCandidateIDs?.filter((id: number) => id !== candidateID)
				: [...expandedCandidateIDs, candidateID];

			dispatch(setExpandedCandidateIDsForProject(newExpandedExperienceCandidate));
		},
		[dispatch, expandedCandidateIDs],
	);

	const ToggleExperienceLengthButtonComponent = ((positions && positions?.length > 3) ||
		candidate.summary !== null) && (
		<GeneralButton
			customClass={classNames(styles.showMoreButton, {
				[styles.reverse]: !displayAllExp,
			})}
			icon={<ArrowUp width={20} height={20} />}
			isClicked={false}
			handleClick={() => handleExpandExperience(candidate.id)}
			title={""}
		/>
	);

	const expandedCriterions = useAppSelector(expandedCriterionsForProjectSelector);

	useEffect(() => {
		const shouldExpand = displayAllExperience || expandedCriterions?.length > 0;

		setDisplayAllExp(shouldExpand);
	}, [displayAllExperience, expandedCriterions]);

	const handleOnEditScore = () => {
		dispatch(getSingleCandidateDone(candidate));
	};

	return (
		<div
			onMouseEnter={trackStart}
			onMouseLeave={trackComplete}
			ref={containerRef}
			className={classNames(styles.candidate, {
				[styles.outsideCompany]: displaySpecialIndicator,
				[styles.isRecommended]: isRecommended,
			})}
		>
			{upgradeView && <UpgradeNowCard />}

			<div className={classNames({[styles.upgradeView]: upgradeView})}>
				{displaySpecialIndicator && (
					<span className={styles.enhanceIcon}>
						<EnhanceIcon color="white" />
					</span>
				)}

				{isRecommended && (
					<span className={styles.recommendedIcon}>
						<RecommendedIcon />
					</span>
				)}

				<div
					className={classNames(styles.container, styles.informationContainer, {
						[styles.apCandidateContainer]: isThisAISearchType,
					})}
				>
					<div className={styles.contentBlock}>
						<div className={styles.infoBlockWithExperience}>
							<div className={styles.infoTitle}>
								<div className={styles.nameArea}>
									{searchType === "Project" && (
										<div style={{paddingRight: 10, display: "flex"}}>
											<Checkbox
												value={candidate.id?.toString()}
												isChecked={isCandidateSelectedForAssessment}
												onChange={() =>
													dispatch(
														handleCandidateSelectionForAssessment(
															candidate?.id,
														),
													)
												}
											/>
										</div>
									)}

									<div
										className={classNames({
											[css.rotatingWrapper]:
												isCurrentCurrentCandidateRefreshIsInProgress,
										})}
									>
										{candidate.avatar_url !== null ? (
											<LazyLoadImageWrapper
												className={classNames(
													styles.logo,
													css.layLoadImage,
												)}
												image={candidate.avatar_url}
												alt={candidate.full_name ?? candidate.name}
												onClick={handleItemClick}
											/>
										) : (
											<BiUser
												className={classNames(
													styles.iconLogo,
													css.layLoadImage,
												)}
												onClick={handleItemClick}
											/>
										)}
									</div>

									<div className={styles.info} onClick={handleItemClick}>
										<span className={styles.candidateName}>
											{candidate.full_name ?? candidate.name}
										</span>
										<span className={styles.candidateCountry}>
											{candidate.country?.name}
										</span>
									</div>
								</div>

								{searchType === "Project" && PROJECT_STATUS_DROPDOWN}
							</div>

							<div className={styles.experienceBlock}>
								{positions && positions?.length > 0 && (
									<CandidateExperience
										key={candidate.id}
										positions={candidateExperiences}
										fromQuickView={false}
										isRecommended={false}
										candidateId={candidate?.id}
										position={position}
										displayDealExperience={displayDealExperience}
										searchType={searchType}
									/>
								)}
							</div>
						</div>

						{searchType === "Project" && (
							<div className={styles.reportBlock}>
								{activeProjectReportViewOnCandidateCard === "summary" && (
									<CandidateSummary
										key={candidate.id}
										id={candidate.id}
										summary={candidate.summary}
										expanded={displayAllExp}
									/>
								)}

								{activeProjectReportViewOnCandidateCard === "assessment" && (
									<CandidateScoring
										isLoading={isCurrentCandidateAssessmentInProgress}
										fit_to_brief={candidate.fit_to_brief}
										expanded={expandAllCriterions}
										handleOnEdit={handleOnEditScore}
									/>
								)}
							</div>
						)}
					</div>

					{searchType === "Project" && (
						<div className={styles.contactDetails}>
							<CandidateContactDetails
								contactDetails={candidate?.contact_details}
								loading={loadingCandidateContactDetails}
							/>
						</div>
					)}

					{/*{isThisAISearchType && (*/}
					<div className={styles.actionButtonContainer}>
						<div className={styles.buttonGroup}>
							{ToggleExperienceLengthButtonComponent}

							{searchType === "Project" && (
								<ActionAltButton
									disabled={candidate?.contact_details !== null}
									key={"Generate Contact Details"}
									customClass={classNames(
										styles.generateContactDetails,
										{
											[styles.loading]: loadingCandidateContactDetails,
										},
										// {
										//     [styles.additionalMargin]:
										//     positions && positions?.length <= 3,
										// },
									)}
									iconComponent={<BiPhone color={"#4E5555"} />}
									handleClick={handleGenerateContactButtonClick}
									title={
										loadingCandidateContactDetails
											? "Generating Contact Details..."
											: "Generate Contact Details"
									}
									loading={loadingCandidateContactDetails}
								/>
							)}
						</div>

						<AdditionalProfileActions
							searchType={searchType}
							candidate={candidate}
							isAdditionalProfileList={isAdditionalProfileList}
							isQuickView={false}
							isRecommended={candidate.is_recommended}
						/>
					</div>
					{/*)}*/}
				</div>
			</div>
		</div>
	);
}
