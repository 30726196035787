import {clearCancelToken, getCancelToken} from "api/cancelTokens";
import {
	GET_PAGINATED_PROJECT_SAVED_COMPANIES_TOKEN,
	GET_PROJECT_COMPANY_IDS_TOKEN,
} from "api/requestCancelTokenStrings";
import {targetListIDSelector} from "../target-list/targetListSelectors";
import mapXTargetListApi from "api/targetListApi";
import {successResponse} from "helpers/map";
import axios from "axios";
import {TPaginationParam} from "api/candidateApi/types";
import {TAppDispatch, TRootState} from "../../../types";
import {projectSavedCompanyPaginationSelector} from "./projectSelectors";
import {
	getProjectCompanyIDsDone,
	getProjectCompanyIDsFail,
	getProjectCompanyIDsInit,
	getProjectSavedCompaniesDone,
	getProjectSavedCompaniesFail,
	projectSavedCompaniesInit,
} from "./projectActions";
import {globalPerPageSelector} from "../userPreference/userPreferenceSelectors";

/**
 * Fetches paginated saved companies for the project.
 *
 * @param {TPaginationParam} [pagination] - Optional pagination parameters.
 */
export const getPaginatedProjectSavedCompanies =
	(pagination?: TPaginationParam) => async (dispatch: TAppDispatch, getState: TRootState) => {
		const cancelTokenSource = getCancelToken(GET_PAGINATED_PROJECT_SAVED_COMPANIES_TOKEN);

		try {
			const state = getState();

			const targetListID = targetListIDSelector(state);
			const currentPagination = projectSavedCompanyPaginationSelector(state);

			const globalPerPageValue = globalPerPageSelector(state);

			const finalPagination = {
				page: pagination?.page ?? currentPagination?.page ?? 1,
				per_page: globalPerPageValue ?? pagination?.per_page ?? 20,
			};

			dispatch(projectSavedCompaniesInit());

			const apiPayload = {
				filters: {
					target_list: targetListID,
				},
				pagination: {
					page: finalPagination.page,
					per_page: finalPagination.per_page,
				},
			};

			const response = await mapXTargetListApi.getTargetListCompanies(apiPayload, {
				cancelToken: cancelTokenSource.token,
			});

			if (successResponse(response, 200)) {
				const data = response.data;

				dispatch(getProjectSavedCompaniesDone(data));
			}

			return [];
		} catch (e) {
			if (axios.isCancel(e)) {
				console.log("Request canceled:", e.message);
			} else {
				console.error(e);
				dispatch(getProjectSavedCompaniesFail({error: e}));
			}

			return [];
		} finally {
			clearCancelToken(GET_PAGINATED_PROJECT_SAVED_COMPANIES_TOKEN);
		}
	};

/**
 * Fetches the saved company IDs for the project.
 *
 * Resolves when the function completes execution.
 */
export const fetchProjectSavedCompanyIDs =
	() => async (dispatch: TAppDispatch, getState: TRootState) => {
		try {
			const cancelTokenSource = getCancelToken(GET_PROJECT_COMPANY_IDS_TOKEN);

			dispatch(getProjectCompanyIDsInit());

			const state = getState();

			const targetListID = targetListIDSelector(state);

			const response = await mapXTargetListApi.getTargetListCompanyIDs(targetListID, {
				cancelToken: cancelTokenSource.token,
			});

			if (response) {
				if (successResponse(response, 200)) {
					const data = response.data.results;

					dispatch(getProjectCompanyIDsDone(data));
				} else {
					dispatch(getProjectCompanyIDsFail({error: "something went wrong"}));
				}
			}
		} catch (error) {
			console.error("error in fetchProjectSavedCompanyIDs:", error);
		} finally {
			clearCancelToken(GET_PROJECT_COMPANY_IDS_TOKEN);
		}
	};
