import {STProject} from "api/projectApi/types";
import {TAppDispatch, TRootState} from "types";
import {
	setBulkImportStateFromBackgroundInProgressData,
	setLastCompleteImportLinkedInProfileInfoForProject,
	setShowLastImportReport,
} from "../linkedin-bulk-import/linkedInBulkImportActions";
import axios, {AxiosResponse, CancelToken, CancelTokenSource} from "axios";
import mapXBackgroundTaskAPI from "api/backgroundTaskApi";
import {
	setBackgroundBulkImportsInProgress,
	setProjectCandidateAssessmentProcess,
} from "./backgroundTaskActions";
import {
	backgroundTaskApiParameterOptions,
	STBackgroundTaskResponse,
	TImportDataInitialize,
	TImportObject,
	TImportUrlInfo,
	TLinkedinBulkImportData,
	TLinkedinBulkImportInfo,
} from "api/backgroundTaskApi/types";
import {successResponse} from "helpers/map";
import {isImportOrRefreshInProgressForCurrentProject} from "../linkedin-bulk-import/linkedinBulkImportSelectors";
import {projectSelector} from "store/mapx/project-list/projectListSelectors";

const cancelTokens: {[key: string]: CancelTokenSource} = {};

/**
 * Dispatches an action to update the state with bulk import information.
 * @param response - API response containing LinkedIn bulk import info.
 * @param urls - List of LinkedIn profile URLs being imported.
 * @param activeProjectInState - The currently active project.
 */
const setBulkImportStates =
	(
		response: AxiosResponse<TLinkedinBulkImportInfo>,
		urls: string[],
		activeProjectInState: STProject,
	) =>
	async (dispatch: TAppDispatch) => {
		const payload = {urls, activeProjectInState, response};

		dispatch(setBulkImportStateFromBackgroundInProgressData(payload));
	};

/**
 * Handles any bulk imports that are still in progress.
 * Fetches their latest status from the API and updates the Redux store.
 * @param inProgressImports - Array of import objects that are still in progress.
 * @param activeProjectInState - The currently active project.
 * @param config - Axios request config, including a cancel token.
 */
const handleInProgressImports =
	(
		inProgressImports: {id: number}[],
		activeProjectInState: STProject,
		config: {cancelToken: CancelToken},
	) =>
	async (dispatch: TAppDispatch) => {
		try {
			// Fetches background task status by ID
			const response: AxiosResponse = await mapXBackgroundTaskAPI.getBackgroundTasksById(
				inProgressImports[0].id,
				config,
			);

			if (response.status === 200) {
				// Extracts URLs of candidates being imported
				const urls = response.data?.linkedin_candidate_urls.map(
					(u: TImportUrlInfo) => u.url,
				);

				// Dispatches action to update state with bulk import progress
				await dispatch(setBulkImportStates(response, urls, activeProjectInState));
			}

			dispatch(setBackgroundBulkImportsInProgress(false));
		} catch (error) {
			console.error("Error fetching in-progress import:", error);
		}
	};

/**
 * Maps API response to a structured object containing import data.
 * @param response - API response with bulk import details.
 * @param activeProjectInState - The currently active project.
 */
const mapResponseToImportData = (
	response: AxiosResponse<TLinkedinBulkImportInfo>,
	activeProjectInState: STProject,
): TLinkedinBulkImportData => ({
	id: activeProjectInState.id,
	importInfo: response.data,
	status: response.data.status,
});

/**
 * Handles status updates for each imported LinkedIn profile URL.
 * If a URL is stuck in an unfinished state, it marks it as "Scraping Error".
 * @param urlInfos - Array of LinkedIn profile import objects.
 */
const handleUrlStatuses = (urlInfos: TImportUrlInfo[]): TImportUrlInfo[] => {
	const notFinishedStateValues = [
		"waiting",
		"importing",
		"scraping",
		"in progress",
		"created",
		"scraped",
	];
	const scrapingErrorMessage = "This url could not be processed";

	return urlInfos.map((urlInfo) => {
		if (notFinishedStateValues.includes(urlInfo?.status.toLowerCase())) {
			urlInfo.status = "Scraping Error";
			urlInfo.reason = scrapingErrorMessage;
		} else if (["Import Error", "Scraping Error"].includes(urlInfo?.status)) {
			urlInfo.reason = scrapingErrorMessage;
		} else if (urlInfo?.status === "Not Found") {
			urlInfo.reason = "This profile was not found";
		}

		return urlInfo;
	});
};

/**
 * Processes and accumulates completed imports from API responses.
 * This helps in consolidating bulk import data from multiple requests.
 * @param responses - Array of API responses with completed import data.
 * @param activeProjectInState - The currently active project.
 */
const accumulateCompletedImportsData = (
	responses: Awaited<AxiosResponse<TLinkedinBulkImportInfo>>[],
	activeProjectInState: STProject,
): TImportObject[] => {
	const allImportData: TImportObject[] = [];

	responses.forEach((response) => {
		if (response?.status === 200) {
			const responseData = response.data;

			// Fixes incorrect status values before storing the data
			responseData.linkedin_candidate_urls = handleUrlStatuses(
				responseData.linkedin_candidate_urls,
			);

			if (activeProjectInState.id === responseData.project_id) {
				const data = mapResponseToImportData(response, activeProjectInState);
				allImportData.push({id: responseData.project_id, data});
			}
		}
	});

	return allImportData;
};

/**
 * Merges data from multiple import responses into a single structured object.
 * This helps in organizing multiple completed imports as a single entity.
 * @param allImportData - Array of structured import data.
 */
const mergeImportData = (allImportData: TImportObject[]): TImportDataInitialize => {
	const mergedData: TImportDataInitialize = {
		id: allImportData[0].id,
		status: "Completed",
		importInfo: {
			status: "",
			linkedin_candidate_urls: [],
		},
	};

	allImportData.forEach((item) => {
		mergedData.importInfo.linkedin_candidate_urls =
			mergedData.importInfo.linkedin_candidate_urls.concat(
				item.data.importInfo.linkedin_candidate_urls,
			);
	});

	return mergedData;
};

/**
 * Handles completed imports and updates the Redux store accordingly.
 * It also merges multiple import results if needed.
 * @param purpose - Can be either "download" (returns data) or "import" (dispatches state updates).
 * @param completedImports - List of completed import objects.
 * @param activeProjectInState - The currently active project.
 * @param config - Axios request configuration (optional).
 */
export const handleCompletedImports =
	(
		purpose: "download" | "import",
		completedImports: {id: number}[],
		activeProjectInState: STProject,
		config?: {cancelToken: CancelToken},
	) =>
	async (dispatch: TAppDispatch) => {
		try {
			const importIDsForCurrentProject = completedImports.map((importItem) => importItem.id);

			// Fetches details of all completed imports in parallel
			const promises = importIDsForCurrentProject.map((importID) =>
				mapXBackgroundTaskAPI.getBackgroundTasksById(importID, config),
			);

			const responses: Awaited<AxiosResponse<unknown>>[] = await Promise.all(promises);

			const allImportData = accumulateCompletedImportsData(
				responses as AxiosResponse<TLinkedinBulkImportInfo>[],
				activeProjectInState,
			);

			if (allImportData.length > 0) {
				const mergedData = mergeImportData(allImportData);

				if (purpose === "download") {
					return mergedData;
				}

				const payload = {id: mergedData.id, data: mergedData};

				// Updates the store with the latest completed import
				dispatch(setLastCompleteImportLinkedInProfileInfoForProject(payload));
			}

			dispatch(setBackgroundBulkImportsInProgress(false));
		} catch (error) {
			console.error("Error fetching completed imports:", error);
		}
	};
// Function to fetch all background bulk imports for the active project
export const getAllBackgroundBulkImports =
	() => async (dispatch: TAppDispatch, getState: TRootState) => {
		const state = getState();

		const activeProjectInState: STProject = projectSelector(state);

		// Check if an import or refresh operation is already in progress
		const importInProgress = isImportOrRefreshInProgressForCurrentProject(state);

		if (!importInProgress) {
			const requestKey = `GET_BACKGROUND_BULK_IMPORTS`;

			if (requestKey in cancelTokens) {
				cancelTokens[requestKey].cancel("Operation canceled due to new request.");
			}

			cancelTokens[requestKey] = axios.CancelToken.source();

			const config = {
				cancelToken: cancelTokens[requestKey].token,
			};

			dispatch(setBackgroundBulkImportsInProgress(true));

			if (activeProjectInState) {
				const payload: backgroundTaskApiParameterOptions = {
					projectId: activeProjectInState.id,
					status: ["Completed", "In Progress", "Created"],
					backgroundTaskType: ["Candidates Bulk Import", "Candidate Assessment"],
				};

				const pagination = {
					page: 1,
					perPage: 10,
				};

				try {
					const response: AxiosResponse =
						await mapXBackgroundTaskAPI.getAllBackgroundTaskRequests(
							payload,
							pagination,
							config,
						);

					if (successResponse(response, 200)) {
						const backgroundTasks: STBackgroundTaskResponse[] = response.data.results;

						// Separate bulk import tasks from candidate assessments
						const bulkImportTasks = backgroundTasks.filter(
							(p: STBackgroundTaskResponse) => p.type === "Candidates Bulk Import",
						);

						const inProgressAssessments = backgroundTasks.filter(
							(p: STBackgroundTaskResponse) => p.type === "Candidate Assessment",
						);

						// If there are bulk import tasks, process them
						if (bulkImportTasks.length > 0) {
							dispatch(
								processBackgroundBulkImportsFromResponse(
									bulkImportTasks,
									activeProjectInState,
								),
							);
						} else {
							// If no bulk import tasks, set import progress to false
							dispatch(setBackgroundBulkImportsInProgress(false));
						}

						// If there are candidate assessment tasks in progress, process them
						if (inProgressAssessments.length > 0) {
							dispatch(
								processProjectCandidateInProgressAssessment(inProgressAssessments),
							);
						}
					} else {
						// If response is unsuccessful, hide the last import report and stop progress indicator
						dispatch(setShowLastImportReport(false));
						dispatch(setBackgroundBulkImportsInProgress(false));
					}
				} catch (error) {
					console.error("Error fetching background bulk imports:", error);
					dispatch(setBackgroundBulkImportsInProgress(false));
				}
			}
		}
	};

// Function to process candidate assessment tasks that are still in progress
export const processProjectCandidateInProgressAssessment =
	(tasks: STBackgroundTaskResponse[]) => async (dispatch: TAppDispatch) => {
		try {
			// Filter candidate assessment tasks that are still in progress or newly created
			const inProgressTasks = tasks.filter(
				(p: STBackgroundTaskResponse) =>
					p.type === "Candidate Assessment" &&
					(p.status === "In Progress" || p.status === "Created"),
			);

			if (inProgressTasks?.length > 0) {
				const requestKey = `HANDLE_IN_PROGRESS_ASSESSMENTS`;

				if (requestKey in cancelTokens) {
					cancelTokens[requestKey].cancel("Operation canceled due to new request.");
				}

				cancelTokens[requestKey] = axios.CancelToken.source();

				const config = {
					cancelToken: cancelTokens[requestKey].token,
				};

				// Get the most recent in-progress task
				const latestInProgressTask = inProgressTasks[0];

				// Fetch additional task details from API
				const data = (await dispatch(
					getBackgroundTaskInfoById(latestInProgressTask.id, config),
				)) as unknown as Nullable<STBackgroundTaskResponse>;

				// If the data is valid, update the Redux state with the candidate assessment process
				if (data !== null) {
					dispatch(setProjectCandidateAssessmentProcess(latestInProgressTask));
				}
			}
		} catch (e) {
			console.error(e);
		}
	};

// Function to process bulk import tasks retrieved from the API
export const processBackgroundBulkImportsFromResponse =
	(bulkImportBackgroundTasks: STBackgroundTaskResponse[], project: STProject) =>
	async (dispatch: TAppDispatch) => {
		// Identify tasks that are still in progress or newly created
		const inProgressImports = bulkImportBackgroundTasks.filter(
			(p: STBackgroundTaskResponse) =>
				p.type === "Candidates Bulk Import" &&
				(p.status === "In Progress" || p.status === "Created"),
		);

		// Find the most recently completed bulk import task
		const lastCompletedImport = bulkImportBackgroundTasks
			.filter(
				(c: STBackgroundTaskResponse) =>
					c.type === "Candidates Bulk Import" && c.status === "Completed",
			)
			.reduce((maxItem, currentItem) =>
				currentItem.id > (maxItem?.id ?? -Infinity) ? currentItem : maxItem,
			);

		const requestKey = `HANDLE_IN_PROGRESS_IMPORTS`;

		if (requestKey in cancelTokens) {
			cancelTokens[requestKey].cancel("Operation canceled due to new request.");
		}

		cancelTokens[requestKey] = axios.CancelToken.source();

		const config = {
			cancelToken: cancelTokens[requestKey].token,
		};

		// If there are in-progress imports, process them
		if (inProgressImports.length > 0) {
			dispatch(setShowLastImportReport(false));
			await dispatch(handleInProgressImports(inProgressImports, project, config));
		} else if (lastCompletedImport) {
			// If no in-progress imports but a completed import exists, process it
			dispatch(setShowLastImportReport(true));
			await dispatch(
				handleCompletedImports("import", [lastCompletedImport], project, config),
			);
		}
	};

// Function to fetch details of a specific background task by its ID
export const getBackgroundTaskInfoById =
	(taskId: number, config = {}) =>
	async () => {
		try {
			const response = await mapXBackgroundTaskAPI.getBackgroundTasksById(taskId, config);

			if (response && successResponse(response, 200)) {
				return response.data;
			}
		} catch (error) {
			console.error("Error fetching in-progress import:", error);
		}

		return null;
	};
