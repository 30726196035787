import {useCallback, useEffect, useRef, useState} from "react";

import colors from "styles/themes.module.scss";
import {ThreeDotsVerticalIcon} from "assets/icons";
import {Loader, Tooltip} from "components";

import styles from "./styles.module.scss";
import type {TOptions} from "../types";
import OutlinedAddIcon from "assets/icons/OutlinedAddIcon";
import {useAppSelector} from "hooks";
import {projectSelector} from "store/mapx/project-list/projectListSelectors";

const Options = ({options, loading, onActionClick, hasPermission}: TOptions) => {
	const ref = useRef<HTMLDivElement>(null);
	const buttonRef = useRef<HTMLDivElement>(null);

	const project = useAppSelector(projectSelector);

	const [expanded, setExpanded] = useState(false);

	const toggleExpand = useCallback(() => setExpanded(!expanded), [expanded]);

	useEffect(() => {
		const handleClickOutside = (event: Event) => {
			if (
				ref.current &&
				!ref.current.contains(event.target as Node) &&
				buttonRef.current &&
				!buttonRef.current.contains(event.target as Node) &&
				buttonRef.current &&
				buttonRef.current !== event.target
			) {
				setExpanded(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	return (
		<Tooltip showArrow placement="bottom" open={expanded && !loading} allowInteraction>
			<Tooltip.Trigger asChild>
				<div className={styles.trigger}>
					{loading ? (
						<Loader
							width={24}
							height={24}
							type="TailSpin"
							color={colors.loaderDotColor}
						/>
					) : (
						<div className={styles.actionWrapper}>
							{project && (
								<div className={styles.actionButton} onClick={onActionClick}>
									<OutlinedAddIcon />
								</div>
							)}

							{hasPermission && (
								<div
									className={styles.actionButton}
									onClick={toggleExpand}
									ref={buttonRef}
								>
									<ThreeDotsVerticalIcon />
								</div>
							)}
						</div>
					)}
				</div>
			</Tooltip.Trigger>
			<Tooltip.Content ref={ref}>
				{options.map((option) => (
					<div
						className={styles.option}
						role="button"
						key={option.name}
						onClick={() => {
							option.action();
							setExpanded(false);
						}}
						style={{color: option.color || "inherit"}}
					>
						{option.icon}
						{option.name}
					</div>
				))}
			</Tooltip.Content>
		</Tooltip>
	);
};

export default Options;
