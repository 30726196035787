import {
	ADD_FROM_MARKET_MAP_MODAL,
	ADD_FROM_MARKET_MAP_MODAL_FROM_MR,
	ADD_TO_MARKET_MAP_MODAL,
	CREATE_MARKET_MAP_DONE,
	CREATE_MARKET_MAP_FAIL,
	CREATE_MARKET_MAP_INIT,
	CREATE_MARKET_MAP_MODAL,
	DELETE_MARKET_MAP_DONE,
	DELETE_MARKET_MAP_FAIL,
	DELETE_MARKET_MAP_INIT,
	DOWNLOAD_MARKET_MAPS_PNG_DONE,
	DOWNLOAD_MARKET_MAPS_PNG_FAIL,
	DOWNLOAD_MARKET_MAPS_PNG_INIT,
	DUPLICATE_MARKET_MAP_MODAL,
	EDIT_MARKET_MAP_MODAL,
	GET_MARKET_MAPS_DONE,
	GET_MARKET_MAPS_FAIL,
	GET_MARKET_MAPS_FOR_MODAL_DONE,
	GET_MARKET_MAPS_FOR_MODAL_FAIL,
	GET_MARKET_MAPS_FOR_MODAL_INIT,
	GET_MARKET_MAPS_INIT,
	RESET_MARKET_MAP_ERROR,
	SET_MARKET_MAPS_PAGINATION_INFO,
	UPDATE_MARKET_MAP_DONE,
	UPDATE_MARKET_MAP_FAIL,
	UPDATE_MARKET_MAP_INIT,
} from "store/mapx/mapXActionTypes";
import {TPaginationParam} from "api/candidateApi/types";

export function getMarketMapsInit() {
	return {type: GET_MARKET_MAPS_INIT};
}

export function setMarketMapPaginationInfo(data: TPaginationParam) {
	return {type: SET_MARKET_MAPS_PAGINATION_INFO, payload: data};
}

export function getMarketMapsDone(
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data: any,
	name: string,
	createdBy: ("self" | "organisation" | "mapx")[],
) {
	return {type: GET_MARKET_MAPS_DONE, payload: {data, name, createdBy}};
}

export function getMarketMapsFail(error: unknown) {
	return {type: GET_MARKET_MAPS_FAIL, payload: error};
}

export function getMarketMapsForModalInit() {
	return {type: GET_MARKET_MAPS_FOR_MODAL_INIT};
}

// TODO: Define API response types
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getMarketMapsForModalDone(data: any, name: string) {
	return {type: GET_MARKET_MAPS_FOR_MODAL_DONE, payload: {data, name}};
}

export function getMarketMapsForModalFail(error: unknown) {
	return {type: GET_MARKET_MAPS_FOR_MODAL_FAIL, payload: error};
}

export function createMarketMapInit() {
	return {type: CREATE_MARKET_MAP_INIT};
}

export function createMarketMapDone(data: unknown) {
	return {type: CREATE_MARKET_MAP_DONE, payload: data};
}

export function createMarketMapFail(error: unknown) {
	return {type: CREATE_MARKET_MAP_FAIL, payload: error};
}

export function resetMarketMapError() {
	return {type: RESET_MARKET_MAP_ERROR};
}

export function createMarketMapModal(display: boolean, props: Record<string, unknown>) {
	return {type: CREATE_MARKET_MAP_MODAL, payload: {display, props}};
}

export function editMarketMapModal(payload: boolean) {
	return {type: EDIT_MARKET_MAP_MODAL, payload};
}

export function duplicateMarketMapModal(payload: boolean) {
	return {type: DUPLICATE_MARKET_MAP_MODAL, payload};
}

export function addFromMarketMapModal(display: boolean, props: Record<string, unknown>) {
	return {type: ADD_FROM_MARKET_MAP_MODAL, payload: {display, props}};
}

export function addFromMarketMapModalForMR(display: boolean) {
	return {type: ADD_FROM_MARKET_MAP_MODAL_FROM_MR, payload: {display}};
}

export function updateMarketMapInit() {
	return {type: UPDATE_MARKET_MAP_INIT};
}

export function updateMarketMapDone(data: unknown) {
	return {type: UPDATE_MARKET_MAP_DONE, payload: data};
}

export function updateMarketMapFail(error: unknown) {
	return {type: UPDATE_MARKET_MAP_FAIL, payload: error};
}

export function deleteMarketMapInit(id: number) {
	return {type: DELETE_MARKET_MAP_INIT, payload: id};
}

export function deleteMarketMapDone(id: number) {
	return {type: DELETE_MARKET_MAP_DONE, payload: id};
}

export function deleteMarketMapFail(id: number, error: unknown) {
	return {type: DELETE_MARKET_MAP_FAIL, payload: {id, error}};
}

export function addToMarketMapModal(payload: boolean) {
	return {type: ADD_TO_MARKET_MAP_MODAL, payload};
}

export function downloadMarketMapsPngInit() {
	return {type: DOWNLOAD_MARKET_MAPS_PNG_INIT};
}

export function downloadMarketMapsPngDone() {
	return {type: DOWNLOAD_MARKET_MAPS_PNG_DONE};
}

export function downloadMarketMapsPngFail(error: unknown) {
	return {type: DOWNLOAD_MARKET_MAPS_PNG_FAIL, payload: error};
}
