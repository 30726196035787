import {STCompanyType} from "api/companyApi/types";

export const getCompanyTypeLabelsByIds = (companyTypes: string[], options: STCompanyType[]) => {
	const data: STCompanyType[] = [];

	const iSet = new Set(companyTypes);

	options.forEach((o) => {
		if (iSet.has(o.id)) {
			data.push({
				id: o.id,
				name: o.name,
			});
		}
	});

	return data;
};
