import React, {type ChangeEvent, useCallback, useMemo, useState} from "react";
import styles from "./styles.module.scss";
import SelectedProjectTypeHeader from "mapx-components/Modals/ModalFormHeaderInformation";
import {Loader, TextInput} from "components";
import FooterButtons from "mapx-components/Modals/FooterButtons";
import {
	createProjectModal,
	setProjectWorkFlowStep,
} from "store/mapx/project-list/projectListActions";
import {useAppDispatch} from "hooks";
import {useHistory} from "react-router-dom";
import SelectDropdown from "components/Inputs/SelectDropdown";
import {TJobTitleForDropdown, TStep} from "./types";
import StartWithCompaniesIcon from "mapx-components/Modals/ProjectCreateWithWorkflowsModal/Icons/StartWithCompaniesIcon";
import useTargetListSearchService from "services/useTargetListSearchService";
import {STMarketMap} from "api/marketMapApi/types";
import useJobTitleSearchService from "services/useJobTitleSearchService";
import useLocationSearchService from "services/useLocationSearchService";
import {STCountry} from "api/filterOptionsApi/CountryApi/types";
import useKeywordSearchService from "services/useKeywordSearchService";
import useIndustrySearchService from "services/useIndustrySearchService";

import {STKeywords} from "api/filterOptionsApi/KeywordApi/types";
import {STIndustry} from "api/filterOptionsApi/IndustryApi/types";
import {TCompanyHeadcountRangeItem} from "mapx-components/Filters/CompanyHeadcountSearchFilter/types";
import {TCompanyRevenueRangeItem} from "mapx-components/Filters/CompanyRevenueSearchFilter/types";

import companySizeOptions from "api/companyApi/companySizeBand.json";
import companyRevenueOptions from "api/companyApi/companyRevenueBand.json";
import {saveProjectForAI} from "store/mapx/project-list/projectWorkflow";
import {
	setCandidateTargetListToggle,
	setFilterForCandidatesFilterSearch,
	setJobTitleFilterMode,
} from "store/mapx/filter/filterActions";
import {TProjectForm} from "mapx-components/Modals/ProjectCreateWithWorkflowsModal/types";
import {ActionMeta} from "react-select";
import {updateProjectSuccessChecklistItem} from "store/mapx/project/projectSuccessChecklistAsyncActions";
import {sortObjectsAlphabetically} from "helpers/array";
import {STCompany} from "api/companyApi/types";
import placeHolderImage from "assets/images/avatar-icon.png";
import useCompanySearchService from "services/useCompanySearchService";
import {debounce} from "lodash";
import {removeDuplicateObjectFromArray, removeDuplicatesFromArray} from "helpers/filterHandlers";
import {getJobTitleClassification} from "store/mapx/search/jobTitlesAsyncActions";
import {
	fetchTargetListCompanyIDs,
	getTargetListCompanies,
} from "store/mapx/target-list/targetListAsyncActions";

const StartWithJobTitle = () => {
	const dispatch = useAppDispatch();

	const [loading, setLoading] = useState<boolean>(false);

	const [step, setStep] = useState<TStep>("project name");

	const [name, setName] = useState("");

	const [company, setCompany] = useState<Nullable<STCompany>>(null);

	const [targetList, setTargetList] = useState<Nullable<STMarketMap>>(null);

	const [jobTitles, setJobTitles] = useState<TJobTitleForDropdown[]>([]);

	const [seniorities, setSeniorities] = useState<string[]>([]);

	const [specialisms, setSpecialisms] = useState<number[]>([]);

	const [keywords, setKeywords] = useState<STKeywords[]>([]);

	const [locations, setLocations] = useState<STCountry[]>([]);

	const [industries, setIndustries] = useState<STIndustry[]>([]);

	const [companySizes, setCompanySizes] = useState<TCompanyHeadcountRangeItem[]>([]);

	const [companyRevenues, setCompanyRevenues] = useState<TCompanyRevenueRangeItem[]>([]);

	const {loadingCompanies, fetchCompanies} = useCompanySearchService();

	const {loadingTargetList, fetchTargetList} = useTargetListSearchService();

	const {loadingJobTitle, setLoadingJobTitle, fetchJobTitle} = useJobTitleSearchService(
		company ? company.id : null,
	);

	const {loadingLocations, flattenLocations} = useLocationSearchService();

	const {loadingKeyword, fetchKeywords} = useKeywordSearchService();

	const {loadingIndustry, flattenIndustries} = useIndustrySearchService();

	const history = useHistory();

	const handleNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		setName(e.target.value);
	}, []);

	const handleJobTitleSelection = useCallback(
		async (
			selectedOptions: TJobTitleForDropdown[],
			actionMeta: ActionMeta<TJobTitleForDropdown>,
		) => {
			if (selectedOptions?.length > 0) {
				if (actionMeta.action === "remove-value") {
					setJobTitles(selectedOptions);
				}

				setLoadingJobTitle(true);

				const filters = await dispatch(
					getJobTitleClassification(selectedOptions.map((s) => s.value)),
				);

				setSeniorities(filters.current_seniority);
				setSpecialisms(filters.specialisms);

				setLoadingJobTitle(false);

				if (actionMeta.action === "select-option") {
					setJobTitles(selectedOptions);
				}
			} else {
				setJobTitles([]);
				setSeniorities([]);
				setSpecialisms([]);
			}
		},
		[dispatch, setLoadingJobTitle],
	);

	const selectAllJobTitleData = (data: string[]) => {
		const jtData: TJobTitleForDropdown[] = data.map((item) => ({
			label: item,
			value: item,
		}));

		const updatedData = removeDuplicateObjectFromArray([...jobTitles, ...jtData], "value");

		handleJobTitleSelection(updatedData, {
			action: "select-option",
			option: undefined,
			removedValue: undefined,
			removedValues: [],
		}).finally(() => {
			const elem = document.querySelector("#jobTitle input") as HTMLInputElement;

			if (elem) {
				elem?.blur();
			}
		});
	};

	const handleGenerateJobTitle = debounce(async (inputString: string) => {
		const allData = (data: string[]) => selectAllJobTitleData(data);

		await fetchJobTitle(inputString, allData);
	}, 800);

	const handleGoBack = useCallback(() => {
		if (step === "select criteria") {
			setStep("project name");
		} else {
			dispatch(setProjectWorkFlowStep(null));
		}
	}, [dispatch, step]);

	const handleCreateNewProjectButtonClick = useCallback(async () => {
		setLoading(true);

		const formData: TProjectForm = {name};

		if (company) {
			formData.company_id = company.id;
		}

		if (targetList) {
			formData.market_map_id = targetList.id;
		}

		const newProject = await dispatch(saveProjectForAI(formData));

		dispatch(setJobTitleFilterMode("close"));

		if (newProject) {
			const list: STCompany[] = await dispatch(getTargetListCompanies());

			dispatch(fetchTargetListCompanyIDs());

			if (Array.isArray(list) && list?.length > 0) {
				await dispatch(
					updateProjectSuccessChecklistItem({
						attribute: "has_saved_companies",
						value: true,
					}),
				);
			}

			const filterObject = {
				current_job_titles_or: jobTitles.map((jt) => jt.value),
				current_seniority: removeDuplicatesFromArray(seniorities),
				current_specialisms_or: removeDuplicatesFromArray(specialisms),
				text_keywords_or: keywords.map((k) => k.name), // params accepts string not number
				countries: locations.map((loc) => loc.id),
				current_industries: industries.map((i) => i.id),
				current_company_size: companySizes.map((cs) => cs.id),
				current_revenue: companyRevenues.map((cr) => cr.id),
				current_tl_companies: list,
			};

			dispatch(setFilterForCandidatesFilterSearch(filterObject));

			dispatch(createProjectModal(false));

			dispatch(setCandidateTargetListToggle(true));

			history.push("/candidates-filter-search");
		}

		setLoading(false);
	}, [
		name,
		company,
		targetList,
		dispatch,
		jobTitles,
		seniorities,
		specialisms,
		keywords,
		locations,
		industries,
		companySizes,
		companyRevenues,
		history,
	]);

	const handleActionButtonClick = useCallback(() => {
		if (step === "project name") {
			setStep("select criteria");
		} else {
			handleCreateNewProjectButtonClick();
		}
	}, [step, handleCreateNewProjectButtonClick]);

	const isActionButtonDisabled = useMemo(() => {
		if (step === "project name") {
			return !(name !== "" && name?.length > 3);
		} else if (step === "select criteria") {
			return jobTitles?.length === 0;
		} else {
			return false;
		}
	}, [step, name, jobTitles]);

	const headerInfo = useMemo(() => {
		let info: {description: string; title: string};

		if (step === "project name") {
			info = {
				title: "Name Your Project",
				description:
					"Start by giving your project a name and picking a list of companies to search at",
			};
		} else {
			info = {
				title: "Enter Your Search Criteria",
				description: "Who are you looking to find?",
			};
		}

		return info;
	}, [step]);

	const handleEnterPress = (event: React.KeyboardEvent) => {
		if (event.code === "Enter" || event.code === "NumpadEnter") {
			event.preventDefault();

			if (!isActionButtonDisabled) {
				handleActionButtonClick();
			}
		}
	};

	return (
		<div>
			<div className={styles.container} onKeyDown={handleEnterPress}>
				<SelectedProjectTypeHeader
					title={headerInfo.title}
					description={headerInfo.description}
					icon={<StartWithCompaniesIcon />}
				/>

				{step === "project name" && (
					<div className={styles.content}>
						<TextInput
							label="Project Name*"
							onChange={handleNameChange}
							type="text"
							value={name}
							placeholder={"Enter a project name"}
						/>

						<div className={styles.inputContainer} data-testid="client-company">
							<label htmlFor="client company">Client Company</label>

							<SelectDropdown
								aria-label={"company"}
								inputId={"company"}
								name={"company"}
								cacheOptions
								isAsync={true}
								value={company}
								onChange={setCompany}
								loadOptions={fetchCompanies}
								showClearIcon={!loadingCompanies}
								isClearable={true}
								hideIndicator={true}
								isLoading={loadingCompanies}
								placeholder={"Which company is this role for?"}
								noOptionsMessage={"Search by company name"}
								noResultsText={
									"No company found with your query, try to refine your query"
								}
								formatOptionLabel={(company: STCompany) => (
									<div className={styles.dropdownItem}>
										<img
											src={company.logo_url || placeHolderImage}
											alt={company.name}
										/>
										<span>{company.name}</span>
									</div>
								)}
							/>
						</div>

						<div className={styles.inputContainer} data-testid="target-list">
							<label htmlFor="company target list">Company Target List</label>

							<SelectDropdown
								isAsync={true}
								defaultOptions={true}
								value={targetList}
								onChange={setTargetList}
								loadOptions={fetchTargetList}
								showClearIcon={!targetList}
								isSearchable={true}
								isClearable={true}
								isLoading={loadingTargetList}
								placeholder={"Choose a previously created company target list"}
								noOptionsMessage={"Search by target list name"}
								noResultsText={
									"No target list found with your query, try to refine your query"
								}
								menuPortalTarget={document.body}
								menuPosition={"fixed"}
							/>
						</div>
					</div>
				)}

				{step === "select criteria" && (
					<div className={styles.content}>
						<div className={styles.inputContainer} data-testid="job-title">
							<label htmlFor="job title">Job Title *</label>

							<SelectDropdown
								id="jobTitle"
								isMulti={true}
								isAsync={true}
								value={jobTitles}
								onChange={handleJobTitleSelection}
								loadOptions={handleGenerateJobTitle}
								showClearIcon={!jobTitles}
								isSearchable={true}
								isClearable={true}
								isLoading={loadingJobTitle}
								loadingMessage={() => (
									<div className={styles.loadingMessage}>
										Generating Job Titles
										<Loader
											width={20}
											height={20}
											type="ThreeDots"
											color="#868d8d"
											displayAtCenterOfPage={false}
										/>
									</div>
								)}
								placeholder={"What job title are you searching for?"}
								noOptionsMessage={"Search by job title"}
								noResultsText={
									"No job title found with your query, try to refine your query"
								}
								formatOptionLabel={(item: TJobTitleForDropdown) => (
									<div className={styles.dropdownItem}>
										<span>{item.label}</span>
									</div>
								)}
								isOptionDisabled={() => jobTitles?.length >= 15}
							/>
						</div>

						<div className={styles.inputContainer} data-testid="keywords">
							<label htmlFor="example companies">Keywords</label>

							<SelectDropdown
								isMulti={true}
								isAsync={true}
								value={keywords}
								onChange={setKeywords}
								loadOptions={fetchKeywords}
								showClearIcon={keywords?.length > 0}
								isSearchable={true}
								isClearable={true}
								isLoading={loadingKeyword}
								placeholder={
									"Any specific words or phrases to include in their profile?"
								}
								noOptionsMessage={"Search by keyword string"}
								noResultsText={
									"No keyword found with your query, try to refine your query"
								}
							/>
						</div>

						<div className={styles.inputContainer} data-testid="location">
							<label htmlFor={"Location"}>Location</label>

							<SelectDropdown
								isMulti={true}
								value={locations}
								onChange={setLocations}
								options={flattenLocations}
								showClearIcon={locations?.length > 0}
								isSearchable={true}
								isClearable={true}
								isLoading={loadingLocations}
								placeholder={"Which locations do you want to see people from?"}
								noOptionsMessage={"Search by country"}
								noResultsText={
									"No location found with your query, try to refine your query"
								}
							/>
						</div>

						<div className={styles.inputContainer} data-testid="industry">
							<label htmlFor="company target list">Industry</label>

							<SelectDropdown
								isMulti={true}
								defaultOptions={true}
								value={industries}
								onChange={setIndustries}
								options={sortObjectsAlphabetically(
									flattenIndustries.filter((i) => i.total_companies_count >= 100),
									"name",
								)}
								showClearIcon={industries?.length > 0}
								isSearchable={true}
								isClearable={true}
								isLoading={loadingIndustry}
								placeholder={
									"Which industries or sectors should these people work in?"
								}
								noOptionsMessage={"Search by industry name"}
								noResultsText={
									"No industry found with your query, try to refine your query"
								}
							/>
						</div>

						<div className={styles.inputContainer} data-testid="company-size">
							<label htmlFor={"Location"}>Company Size</label>

							<SelectDropdown
								isMulti={true}
								value={companySizes}
								onChange={setCompanySizes}
								options={companySizeOptions}
								showClearIcon={companySizes?.length > 0}
								isSearchable={false}
								isClearable={true}
								placeholder={"What company sizes (in headcount) are relevant?"}
								isDisabled={companyRevenues?.length > 0}
								menuPortalTarget={document.body}
								menuPosition={"fixed"}
							/>
						</div>

						<div className={styles.inputContainer} data-testid="company-revenue">
							<label htmlFor={"Location"}>Company Revenue</label>

							<SelectDropdown
								isMulti={true}
								value={companyRevenues}
								onChange={setCompanyRevenues}
								options={companyRevenueOptions}
								showClearIcon={companyRevenues?.length > 0}
								isSearchable={false}
								isClearable={true}
								placeholder={"What company revenue sizes are relevant?"}
								isDisabled={companySizes?.length > 0}
								menuPortalTarget={document.body}
								menuPosition={"fixed"}
							/>
						</div>
					</div>
				)}
			</div>

			<FooterButtons
				actionButtonLabel={step === "select criteria" ? "Create New Project" : "Continue"}
				handleGoButtonClick={handleGoBack}
				handleActionButtonClick={handleActionButtonClick}
				isActionButtonDisabled={isActionButtonDisabled}
				isLoading={loading}
			/>
		</div>
	);
};

export default StartWithJobTitle;
