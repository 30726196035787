import projectChecklist from "api/projectApi/projectChecklistData.json";
import {CLEAR_AP_WORKFLOW_STATE} from "store/mapx/additional-profiles/additionalProfilesActionTypes";
import {
	ADD_CANDIDATE_TO_TARGET_LIST_DONE,
	ADD_COMPANY_TO_TARGET_LIST_DONE,
	BULK_PROJECT_CANDIDATE_STATUS_CHANGE,
	CLEAR_PROJECT_CANDIDATE_ASSESSMENT_PROCESS,
	FETCHING_PROJECT_CANDIDATE_TRACK_LEAVERS,
	FETCHING_PROJECT_CANDIDATE_TRACK_NO_RECENT_CHANGES,
	FETCHING_PROJECT_CANDIDATE_TRACK_PROMOTIONS,
	GET_INDUSTRIES_REPORT_FETCH,
	GET_INDUSTRIES_REPORT_SUCCESS,
	GET_INDUSTRY_BACKGROUND_REPORT_DONE,
	GET_INDUSTRY_BACKGROUND_REPORT_FAILED,
	GET_INDUSTRY_BACKGROUND_REPORT_INIT,
	GET_PROJECT_CANDIDATE_IDS_DONE,
	GET_PROJECT_CANDIDATE_IDS_FAIL,
	GET_PROJECT_CANDIDATE_IDS_INIT,
	GET_PROJECT_CANDIDATE_STATUSES_DONE,
	GET_PROJECT_CANDIDATE_STATUSES_FAILED,
	GET_PROJECT_CANDIDATE_STATUSES_INIT,
	GET_PROJECT_COMPANY_IDS_DONE,
	GET_PROJECT_COMPANY_IDS_FAIL,
	GET_PROJECT_COMPANY_IDS_INIT,
	GET_PROJECT_PROGRESS_STATUSES_DONE,
	GET_PROJECT_PROGRESS_STATUSES_FAILED,
	GET_PROJECT_PROGRESS_STATUSES_INIT,
	GET_PROJECT_SAVED_COMPANIES_DONE,
	GET_PROJECT_SAVED_COMPANIES_FAIL,
	GET_PROJECT_SAVED_COMPANIES_INIT,
	GET_TARGETLIST_COMPANIES_DONE,
	LOGOUT,
	PROJECT_SAVED_PEOPLE_FETCH_COMPLETED,
	PROJECT_SAVED_PEOPLE_FETCH_IN_PROGRESS,
	REMOVE_CANDIDATE_FROM_TARGET_LIST_DONE,
	REMOVE_CANDIDATES_FROM_TARGET_LIST_DONE,
	REMOVE_COMPANIES_FROM_TARGET_LIST_DONE,
	REMOVE_COMPANY_FROM_TARGET_LIST_DONE,
	SET_ACTIVE_PROJECT_REPORT_VIEW_ON_CANDIDATE_CARD,
	SET_AP_CANDIDATES_WITH_RECOMMENDED_CANDIDATES,
	SET_AP_CANDIDATES_WITH_UPDATED_SUMMARY,
	SET_CANDIDATE_IDS_FOR_ASSESSMENT,
	SET_COUNTRY_REPORT,
	SET_CRITERIA_FOR_PROJECT_CANDIDATE_ASSESSMENT,
	SET_ETHNIC_DIVERSITY_DATA,
	SET_EXPANDED_CANDIDATE_IDS_FOR_PROJECT,
	SET_EXPANDED_CRITERIONS_FOR_PROJECT,
	SET_GENDER_DIVERSITY_DATA,
	SET_GENERATE_CONTACT_DETAILS_CANDIDATE_IDS_FOR_ALL_PROJECT,
	SET_GENERATE_SUMMARY_CANDIDATE_IDS_FOR_CURRENT_PROJECT,
	SET_INDUSTRIES_REPORT_FAILED,
	SET_JOB_FUNCTION_DISTRIBUTION_DATA,
	SET_JOB_FUNCTION_DISTRIBUTION_INIT,
	SET_ONBOARDING_STEP,
	SET_ONBOARDING_VISIBILITY,
	SET_PRESENTATION_AVAILABLE_TEMPLATES,
	SET_PROJECT,
	SET_PROJECT_CANDIDATE_STATUSES_FINISHED,
	SET_PROJECT_CANDIDATE_TRACK_LEAVERS,
	SET_PROJECT_CANDIDATE_TRACK_NO_RECENT_CHANGES,
	SET_PROJECT_CANDIDATE_TRACK_PROMOTIONS,
	SET_PROJECT_CANDIDATES_SELECTION_IN_PROGRESS,
	SET_PROJECT_CHECKLIST_FULL_DISPLAY,
	SET_PROJECT_FREQUENTLY_HIRED_FROM_DATA,
	SET_PROJECT_PROGRESS_STATUSES_FINISHED,
	SET_PROJECT_REPORT_TABLE_ROWS,
	SET_PROJECT_SAVED_COMPANIES_PAGINATION_INFO,
	SET_PROJECT_SAVED_PEOPLE_PAGINATION_INFO,
	SET_PROJECT_STATS,
	SET_PROJECT_STATS_FAILED,
	SET_PROJECT_STATS_INIT,
	SET_SELECTED_PRESENTATION_TEMPLATE,
	SET_SELECTED_TRACK_CANDIDATE_IDS,
	SET_SELECTED_TRACK_CANDIDATE_POSITION_CHANGED_DAYS,
	UPDATE_AP_CANDIDATE_BY_KEY,
	UPDATE_AP_CANDIDATE_BY_KEY_IN_LIST,
	UPDATE_PROJECT_SAVED_PEOPLE_WITH_SUMMARY,
	UPDATE_PROJECT_SUCCESS_CHECKLIST,
} from "store/mapx/mapXActionTypes";

export const mapxProjectInitialState = {
	jobFunctionDistributionReportProgress: false,
	insights: {
		genderDiversity: {
			male_percent: 0,
			female_percent: 0,
		},
		ethnicDiversity: {},
		jobFunctionDistribution: [],
		countryReport: null,
	},
	industryReport: null,
	industryReportPagination: null,
	industryReportLoading: false,
	projectStats: null,
	projectStatsLoading: false,
	projectTabIndex: 0,
	projectFrequentlyHiredFromData: null,
	industryBackgroundReport: [],
	expandedCriterionsForProject: [],
	expandedCandidateIDsForProject: [],
	industryBackgroundReportProgress: false,
	onboardingStep: 0,
	openOnboardingComponent: false,
	projectCandidatesSelectionInProgress: false,

	projectSavedCandidates: [],
	projectSavedCandidatesPagination: [],
	projectSavedCandidatesFetchIsInProgress: false,

	projectSavedCandidateIDsFetchInProgress: false,
	projectSavedCandidateIDs: [],

	projectCompanyIDsFetchInProgress: false,
	projectCompanyIDs: [],

	projectSavedCompanies: [],
	projectSavedCompaniesPagination: [],
	projectSavedCompaniesFetchIsInProgress: false,

	generateCandidatesSummaryByProject: {}, // targetListId: [candidate_id]
	generateCandidatesContactDetailsByProject: [], //[candidate_id]
	activeProjectReportViewOnCandidateCard: "assessment", // "summary" or "assessment"
	selectedCandidateIdsForAssessment: [],
	brief_criteria_project_assessment: [],

	presentationAvailableTemplates: [],
	selectedPresentationTemplate: "MapX",
	projectChecklistOptions: projectChecklist,
	displayFullProjectChecklist: false,
	projectReportTableRows: [
		{
			status: "recommended",
			slide_type: "full-page",
			order: "full_name",
		},
		{
			status: "secondary",
			slide_type: "full-page",
			order: "full_name",
		},
	],
	progressStatuses: [],
	fetchingProgressStatuses: false,

	candidateStatuses: [],
	fetchingCandidateStatuses: false,

	bulkStatusChangeInProgress: false,

	selectedTrackCandidateIds: [],
	selectedTrackCandidatePositionChangedDays: 14,

	fetchingCandidateTrackLeavers: false,
	projectCandidateTrackLeavers: [],
	projectCandidateTrackLeaversPagination: {page: 1, per_page: 20},

	fetchingCandidateTrackPromotions: false,
	projectCandidateTrackPromotions: [],
	projectCandidateTrackPromotionsPagination: {page: 1, per_page: 20},

	fetchingCandidateTrackNoRecentChanges: false,
	projectCandidateTrackNoRecentChanges: [],
	projectCandidateTrackNoRecentChangesPagination: {page: 1, per_page: 20},
};

const projectReducer = (state = mapxProjectInitialState, action) => {
	switch (action.type) {
		case CLEAR_AP_WORKFLOW_STATE: {
			return {
				...state,
				...mapxProjectInitialState,
				generateCandidatesSummaryByProject: {...state.generateCandidatesSummaryByProject},
				projectSavedCandidatesFetchIsInProgress: {
					...state.projectSavedCandidatesFetchIsInProgress,
				},
				fetchingCandidateStatuses: {
					...state.fetchingCandidateStatuses,
				},
				projectSavedCompaniesFetchIsInProgress: {
					...state.projectSavedCompaniesFetchIsInProgress,
				},
				generateCandidatesContactDetailsByProject:
					state.generateCandidatesContactDetailsByProject,
			};
		}
		case BULK_PROJECT_CANDIDATE_STATUS_CHANGE:
			return {
				...state,
				bulkStatusChangeInProgress: action.payload,
			};
		case SET_PRESENTATION_AVAILABLE_TEMPLATES:
			return {
				...state,
				presentationAvailableTemplates: action.payload,
			};
		case SET_SELECTED_PRESENTATION_TEMPLATE:
			return {
				...state,
				selectedPresentationTemplate: action.payload,
			};
		case SET_PROJECT_CHECKLIST_FULL_DISPLAY: {
			return {
				...state,
				displayFullProjectChecklist: action.payload,
			};
		}

		case SET_CANDIDATE_IDS_FOR_ASSESSMENT: {
			return {
				...state,
				selectedCandidateIdsForAssessment: action.payload,
			};
		}

		case SET_CRITERIA_FOR_PROJECT_CANDIDATE_ASSESSMENT: {
			return {
				...state,
				brief_criteria_project_assessment: action.payload,
			};
		}

		case SET_EXPANDED_CRITERIONS_FOR_PROJECT: {
			return {
				...state,
				expandedCriterionsForProject: action.payload,
			};
		}

		case SET_PROJECT_CANDIDATES_SELECTION_IN_PROGRESS: {
			return {
				...state,
				projectCandidatesSelectionInProgress: action.payload,
			};
		}

		case SET_EXPANDED_CANDIDATE_IDS_FOR_PROJECT: {
			return {
				...state,
				expandedCandidateIDsForProject: action.payload,
			};
		}

		case CLEAR_PROJECT_CANDIDATE_ASSESSMENT_PROCESS: {
			return {
				...state,
				selectedCandidateIdsForAssessment: [],
			};
		}

		case SET_ACTIVE_PROJECT_REPORT_VIEW_ON_CANDIDATE_CARD: {
			return {
				...state,
				activeProjectReportViewOnCandidateCard: action.payload,
			};
		}

		case UPDATE_PROJECT_SUCCESS_CHECKLIST: {
			return {
				...state,
				projectChecklistOptions: [...action.payload],
			};
		}

		case SET_PROJECT_REPORT_TABLE_ROWS: {
			return {
				...state,
				projectReportTableRows: [...action.payload],
			};
		}

		case SET_PROJECT: {
			return {
				...state,
				projectChecklistOptions: [...projectChecklist],
				displayFullProjectChecklist: false,
			};
		}
		case GET_PROJECT_SAVED_COMPANIES_INIT:
			return {
				...state,
				projectSavedCompaniesFetchIsInProgress: true,
			};
		case GET_PROJECT_SAVED_COMPANIES_DONE:
			return {
				...state,
				projectSavedCompaniesFetchIsInProgress: false,
				projectSavedCompanies: action.payload?.results || [],
				projectSavedCompaniesPagination: action.payload.pagination ?? null,
			};
		case GET_PROJECT_SAVED_COMPANIES_FAIL:
			return {
				...state,
				projectSavedCompaniesFetchIsInProgress: false,
			};
		case SET_PROJECT_SAVED_COMPANIES_PAGINATION_INFO:
			return {
				...state,
				projectSavedCompaniesPagination: action.payload,
			};

		case REMOVE_COMPANY_FROM_TARGET_LIST_DONE: {
			const {companyID} = action.payload;

			const prevPaginatedCompPagination = state.projectSavedCompaniesPagination
				? {...state.projectSavedCompaniesPagination}
				: {count: 1};

			return {
				...state,
				projectSavedCompanies: [...state.projectSavedCompanies].filter(
					(company) => company.id !== companyID,
				),

				projectSavedCompaniesPagination: {
					...prevPaginatedCompPagination,
					count: state.projectSavedCompanies?.count - 1,
				},

				projectCompanyIDsFetchInProgress: false,
				projectCompanyIDs: [
					...(state.projectCompanyIDs?.filter((item) => item !== companyID) || []),
				],
			};
		}

		case ADD_COMPANY_TO_TARGET_LIST_DONE: {
			const {company} = action.payload;

			return {
				...state,
				projectCompanyIDsFetchInProgress: false,
				projectCompanyIDs: [...state.projectCompanyIDs, company.id],
			};
		}

		case GET_PROJECT_COMPANY_IDS_INIT:
			return {
				...state,
				projectCompanyIDsFetchInProgress: true,
			};
		case GET_PROJECT_COMPANY_IDS_DONE:
			return {
				...state,
				projectCompanyIDsFetchInProgress: false,
				projectCompanyIDs: action.payload || [],
			};
		case GET_PROJECT_COMPANY_IDS_FAIL:
			return {
				...state,
				projectCompanyIDsFetchInProgress: false,
			};

		case REMOVE_COMPANIES_FROM_TARGET_LIST_DONE:
			return {
				...state,
				projectSavedCompanies: [],
				projectCompanyIDs: [],
			};

		case SET_ONBOARDING_VISIBILITY: {
			return {
				...state,
				openOnboardingComponent: action.payload,
			};
		}

		case SET_ONBOARDING_STEP: {
			let updatedOnboardingStep = state.onboardingStep;
			const boardingStep = state.onboardingStep === 5 ? 4 : state.onboardingStep - 1;

			// Checking the action which should be triggered in case of payload's type is a number or a string
			if (typeof action.payload === "string") {
				if (action.payload === "increment") {
					updatedOnboardingStep =
						updatedOnboardingStep === null ? 1 : state.onboardingStep + 1;
				} else if (action.payload === "decrement") {
					updatedOnboardingStep = boardingStep;
				} else {
					updatedOnboardingStep = null;
				}
			} else if (typeof action.payload === "number") {
				updatedOnboardingStep = action.payload;
			}

			return {
				...state,
				onboardingStep: updatedOnboardingStep,
			};
		}
		case SET_GENDER_DIVERSITY_DATA:
			return {
				...state,
				insights: {
					...state.insights,
					genderDiversity: action.payload,
				},
			};

		case SET_COUNTRY_REPORT:
			return {
				...state,
				insights: {
					...state.insights,
					countryReport: action.payload,
				},
			};

		case GET_INDUSTRIES_REPORT_FETCH:
			return {
				...state,
				industryReportLoading: true,
			};
		case GET_INDUSTRIES_REPORT_SUCCESS:
			return {
				...state,
				industryReport: action.payload?.results,
				industryReportLoading: false,
				industryReportPagination: action.payload?.pagination,
			};
		case SET_INDUSTRIES_REPORT_FAILED:
			return {
				...state,
				industryReportLoading: false,
			};
		case SET_ETHNIC_DIVERSITY_DATA:
			return {
				...state,
				insights: {
					...state.insights,
					ethnicDiversity: action.payload,
				},
			};
		case SET_JOB_FUNCTION_DISTRIBUTION_INIT:
			return {
				...state,
				jobFunctionDistributionReportProgress: true,
			};
		case SET_JOB_FUNCTION_DISTRIBUTION_DATA:
			return {
				...state,
				jobFunctionDistributionReportProgress: false,
				insights: {
					...state.insights,
					jobFunctionDistribution: action.payload,
				},
			};
		case SET_PROJECT_STATS:
			return {
				...state,
				projectStats: action.payload,
				projectStatsLoading: false,
			};
		case SET_PROJECT_STATS_INIT:
			return {
				...state,
				projectStatsLoading: true,
			};

		case REMOVE_CANDIDATES_FROM_TARGET_LIST_DONE:
			return {
				...state,
				projectSavedCandidates: [],
				projectSavedCandidateIDs: [],
				projectSavedCandidatesPagination: null,
				projectSavedCandidateIDsFetchInProgress: false,
			};
		case ADD_CANDIDATE_TO_TARGET_LIST_DONE: {
			const {candidate} = action.payload;

			return {
				...state,

				projectSavedCandidateIDsFetchInProgress: false,
				projectSavedCandidateIDs: [...state.projectSavedCandidateIDs, candidate.id],
			};
		}
		case GET_PROJECT_CANDIDATE_IDS_INIT:
			return {
				...state,
				projectSavedCandidateIDsFetchInProgress: true,
			};
		case GET_PROJECT_CANDIDATE_IDS_DONE:
			return {
				...state,
				projectSavedCandidateIDsFetchInProgress: false,
				projectSavedCandidateIDs: action.payload || [],
			};
		case GET_PROJECT_CANDIDATE_IDS_FAIL:
			return {
				...state,
				projectSavedCandidateIDsFetchInProgress: false,
			};
		case GET_TARGETLIST_COMPANIES_DONE:
			return {
				...state,
				projectSavedCompaniesPagination: {
					...action.payload.pagination,
					per_page: 20,
				},
			};

		case REMOVE_CANDIDATE_FROM_TARGET_LIST_DONE: {
			const {candidate} = action.payload;

			const candidateID = candidate.id;
			const candidateStatus = candidate.progress_status;
			const isCandidateRecommended = candidate.is_recommended;

			const existsInSavedCandidates = state.projectSavedCandidates.find(
				(c) => c.id === candidateID,
			);

			let updatedSavedCandidates = [...state.projectSavedCandidates];
			let updatedSavedCandidatesPagination = state.projectSavedCandidatesPagination;

			if (existsInSavedCandidates) {
				updatedSavedCandidates = updatedSavedCandidates.filter((c) => c.id !== candidateID);
				updatedSavedCandidatesPagination = {
					...updatedSavedCandidatesPagination,
					count: state.projectSavedCandidatesPagination?.count - 1,
				};
			}

			const existingCandidateStatuses = [...state.candidateStatuses];

			const updatedCandidateStatuses = existingCandidateStatuses
				?.map((statusItem) => {
					if (
						statusItem.status?.toLowerCase() === candidateStatus?.toLowerCase() ||
						(isCandidateRecommended &&
							statusItem.status?.toLowerCase() === "recommended")
					) {
						return {...statusItem, count: Math.max(0, statusItem.count - 1)};
					}

					return statusItem;
				})
				.filter((statusItem) => statusItem.count > 0);

			return {
				...state,
				projectSavedCandidates: updatedSavedCandidates,
				projectSavedCandidatesPagination: updatedSavedCandidatesPagination,
				projectSavedCandidateIDsFetchInProgress: false,
				projectSavedCandidateIDs: [
					...(state.projectSavedCandidateIDs?.filter((item) => item !== candidateID) ||
						[]),
				],
				candidateStatuses: updatedCandidateStatuses,
				fetchingCandidateStatuses: false,
				projectCandidateTrackLeavers: [...state.projectCandidateTrackLeavers].filter(
					(item) => item.id !== candidateID,
				),
				projectCandidateTrackPromotions: [...state.projectCandidateTrackPromotions].filter(
					(item) => item.id !== candidateID,
				),
				projectCandidateTrackNoRecentChanges: [
					...state.projectCandidateTrackNoRecentChanges,
				].filter((item) => item.id !== candidateID),
				projectCandidateTrackLeaversPagination: {
					...state.projectCandidateTrackLeaversPagination,
					count: state.projectCandidateTrackLeaversPagination.count - 1,
				},
				projectCandidateTrackPromotionsPagination: {
					...state.projectCandidateTrackPromotionsPagination,
					count: state.projectCandidateTrackPromotionsPagination.count - 1,
				},
				projectCandidateTrackNoRecentChangesPagination: {
					...state.projectCandidateTrackNoRecentChangesPagination,
					count: state.projectCandidateTrackNoRecentChangesPagination.count - 1,
				},
			};
		}

		case PROJECT_SAVED_PEOPLE_FETCH_IN_PROGRESS:
			return {
				...state,
				projectSavedCandidatesFetchIsInProgress: action.payload,
			};

		case PROJECT_SAVED_PEOPLE_FETCH_COMPLETED:
			return {
				...state,
				projectSavedCandidates: action.payload?.results || [],
				projectSavedCandidatesPagination: action.payload.pagination ?? null,
				projectSavedCandidatesFetchIsInProgress: false,
			};

		case SET_PROJECT_SAVED_PEOPLE_PAGINATION_INFO:
			return {
				...state,
				projectSavedCandidatesPagination: action.payload,
			};

		case SET_AP_CANDIDATES_WITH_RECOMMENDED_CANDIDATES: {
			const {id, is_recommended} = action.payload;

			let updatedCandidateStatuses = [...state.candidateStatuses];

			const recommendedIndex = updatedCandidateStatuses.findIndex(
				(statusItem) => statusItem.status?.toLowerCase() === "recommended",
			);

			const secondaryIndex = updatedCandidateStatuses.findIndex(
				(statusItem) => statusItem.status?.toLowerCase() === "secondary",
			);

			if (is_recommended) {
				// Increase recommended count
				if (recommendedIndex !== -1) {
					updatedCandidateStatuses = updatedCandidateStatuses.map((statusItem, index) =>
						index === recommendedIndex
							? {...statusItem, count: statusItem.count + 1}
							: statusItem,
					);
				} else {
					updatedCandidateStatuses.push({count: 1, status: "recommended"});
				}

				// Decrease secondary count
				if (secondaryIndex !== -1) {
					updatedCandidateStatuses = updatedCandidateStatuses
						.map((statusItem, index) =>
							index === secondaryIndex
								? {...statusItem, count: statusItem.count - 1}
								: statusItem,
						)
						.filter((statusItem) => statusItem.count > 0); // Remove if count is 0
				}
			} else {
				// Decrease recommended count
				if (recommendedIndex !== -1) {
					updatedCandidateStatuses = updatedCandidateStatuses
						.map((statusItem, index) =>
							index === recommendedIndex
								? {...statusItem, count: statusItem.count - 1}
								: statusItem,
						)
						.filter((statusItem) => statusItem.count > 0); // Remove if count is 0
				}

				// Increase secondary count
				if (secondaryIndex !== -1) {
					updatedCandidateStatuses = updatedCandidateStatuses.map((statusItem, index) =>
						index === secondaryIndex
							? {...statusItem, count: statusItem.count + 1}
							: statusItem,
					);
				} else {
					updatedCandidateStatuses.push({count: 1, status: "secondary"});
				}
			}

			return {
				...state,
				projectSavedCandidates: state.projectSavedCandidates.map((c) =>
					c.id === id ? {...c, is_recommended: is_recommended} : c,
				),
				candidateStatuses: updatedCandidateStatuses,
				fetchingCandidateStatuses: false,
			};
		}

		case SET_AP_CANDIDATES_WITH_UPDATED_SUMMARY:
			return {
				...state,
				projectSavedCandidates: [
					...state.projectSavedCandidates.map((c) => {
						if (c.id === action.payload.id) {
							c.summary = action.payload.summary;
						}

						return c;
					}),
				],
			};

		case UPDATE_AP_CANDIDATE_BY_KEY: {
			const {id, key, value, status, previousStatus} = action.payload;

			// Update projectSavedCandidates in state
			const updatedSavedCandidates = state.projectSavedCandidates.map((c) =>
				c.id === id ? {...c, [key]: value} : c,
			);

			const existingCandidateStatuses = [...state.candidateStatuses];

			let statusUpdated = false; // Flag to check if the new status was updated
			let previousStatusUpdated = false; // Flag to check if previous status exists
			let secondaryExists = false; // Flag to check if "secondary" status exists
			let isStatusChange = previousStatus && previousStatus !== status; // Check if an existing status is changed

			// Calculate updated candidateStatuses in state
			const updatedCandidateStatuses = existingCandidateStatuses
				.map((statusItem) => {
					const itemStatus = statusItem.status?.toLowerCase();

					// Update the count of the current status
					if (itemStatus === status?.toLowerCase()) {
						statusUpdated = true;

						return {
							...statusItem,
							count: Math.max(0, statusItem.count + (value === null ? -1 : 1)), // Increase or decrease count
						};
					}

					// Decrease count for previousStatus if it exists and is different from the current status
					if (isStatusChange && itemStatus === previousStatus?.toLowerCase()) {
						previousStatusUpdated = true;

						return {
							...statusItem,
							count: Math.max(0, statusItem.count - 1),
						};
					}

					// Handle "secondary" status
					if (itemStatus === "secondary") {
						secondaryExists = true;
						// Only decrease "secondary" count if `previousStatus` was `null` (candidate had no status before)

						return {
							...statusItem,
							count: Math.max(
								0,
								statusItem.count + (value === null ? 1 : previousStatus ? 0 : -1),
							),
						};
					}

					return statusItem;
				})
				.filter((statusItem) => statusItem.count > 0); // Remove if count becomes 0

			// If no matching status was found, create a new entry
			if (!statusUpdated && status) {
				updatedCandidateStatuses.push({status, count: 1});
			}

			// If previousStatus was not updated and exists, add it with count = 1
			if (!previousStatusUpdated && isStatusChange) {
				updatedCandidateStatuses.push({status: previousStatus, count: 1});
			}

			// If "secondary" status was not found and value === null, add it with count = 1
			if (!secondaryExists && value === null) {
				updatedCandidateStatuses.push({status: "secondary", count: 1});
			}

			return {
				...state,
				projectSavedCandidates: updatedSavedCandidates,
				candidateStatuses: updatedCandidateStatuses,
				fetchingCandidateStatuses: false,
			};
		}

		case UPDATE_AP_CANDIDATE_BY_KEY_IN_LIST:
			return {
				...state,
				projectSavedCandidates: [
					...state.projectSavedCandidates.map((c) => {
						if (c.id === action.payload.candidate_id) {
							c[action.payload.key] = action.payload.value;
						}

						return c;
					}),
				],
			};

		case UPDATE_PROJECT_SAVED_PEOPLE_WITH_SUMMARY:
			return {
				...state,
				projectSavedCandidates: action.payload,
			};

		case SET_PROJECT_STATS_FAILED:
			return {
				...state,
				projectStatsLoading: false,
			};
		case SET_PROJECT_FREQUENTLY_HIRED_FROM_DATA: {
			const {results, pagination} = action.payload;
			const {projectFrequentlyHiredFromData} = state;

			return {
				...state,
				projectFrequentlyHiredFromData: {
					results:
						pagination.page === 1
							? results
							: [...(projectFrequentlyHiredFromData.results || []), ...results],
					pagination,
				},
			};
		}

		case GET_INDUSTRY_BACKGROUND_REPORT_INIT:
			return {
				...state,
				industryBackgroundReportProgress: true,
			};

		case GET_INDUSTRY_BACKGROUND_REPORT_DONE:
			return {
				...state,
				industryBackgroundReport: action.payload,
				industryBackgroundReportProgress: false,
			};
		case GET_INDUSTRY_BACKGROUND_REPORT_FAILED:
			return {
				...state,
				industryBackgroundReportProgress: false,
			};
		case SET_GENERATE_SUMMARY_CANDIDATE_IDS_FOR_CURRENT_PROJECT:
			return {
				...state,
				generateCandidatesSummaryByProject: {
					...state.generateCandidatesSummaryByProject,
					...action.payload,
				},
			};
		case SET_GENERATE_CONTACT_DETAILS_CANDIDATE_IDS_FOR_ALL_PROJECT:
			return {
				...state,
				generateCandidatesContactDetailsByProject: {...action.payload},
			};
		case GET_PROJECT_PROGRESS_STATUSES_INIT:
			return {
				...state,
				fetchingProgressStatuses: true,
			};
		case GET_PROJECT_PROGRESS_STATUSES_FAILED:
			return {
				...state,
				fetchingProgressStatuses: false,
				progressStatuses: [],
			};
		case SET_PROJECT_PROGRESS_STATUSES_FINISHED:
			return {
				...state,
				fetchingProgressStatuses: false,
			};
		case GET_PROJECT_PROGRESS_STATUSES_DONE:
			return {
				...state,
				fetchingProgressStatuses: false,
				progressStatuses: action.payload,
			};
		case GET_PROJECT_CANDIDATE_STATUSES_INIT:
			return {
				...state,
				fetchingCandidateStatuses: true,
			};
		case GET_PROJECT_CANDIDATE_STATUSES_FAILED:
			return {
				...state,
				fetchingCandidateStatuses: false,
				candidateStatuses: [...state.candidateStatuses],
			};
		case SET_PROJECT_CANDIDATE_STATUSES_FINISHED:
			return {
				...state,
				fetchingCandidateStatuses: false,
			};
		case GET_PROJECT_CANDIDATE_STATUSES_DONE:
			return {
				...state,
				fetchingCandidateStatuses: false,
				candidateStatuses: action.payload,
			};

		case SET_SELECTED_TRACK_CANDIDATE_IDS:
			return {
				...state,
				selectedTrackCandidateIds: action.payload,
			};

		case SET_SELECTED_TRACK_CANDIDATE_POSITION_CHANGED_DAYS:
			return {
				...state,
				selectedTrackCandidatePositionChangedDays: action.payload,
			};

		case FETCHING_PROJECT_CANDIDATE_TRACK_LEAVERS:
			return {
				...state,
				fetchingCandidateTrackLeavers: action.payload,
			};

		case SET_PROJECT_CANDIDATE_TRACK_LEAVERS:
			return {
				...state,
				projectCandidateTrackLeavers: action.payload.results,
				projectCandidateTrackLeaversPagination: action.payload.pagination,
				fetchingCandidateTrackLeavers: false,
			};

		case FETCHING_PROJECT_CANDIDATE_TRACK_PROMOTIONS:
			return {
				...state,
				fetchingCandidateTrackPromotions: action.payload,
			};

		case SET_PROJECT_CANDIDATE_TRACK_PROMOTIONS:
			return {
				...state,
				projectCandidateTrackPromotions: action.payload.results,
				projectCandidateTrackPromotionsPagination: action.payload.pagination,
				fetchingCandidateTrackPromotions: false,
			};

		case FETCHING_PROJECT_CANDIDATE_TRACK_NO_RECENT_CHANGES:
			return {
				...state,
				fetchingCandidateTrackNoRecentChanges: action.payload,
			};

		case SET_PROJECT_CANDIDATE_TRACK_NO_RECENT_CHANGES:
			return {
				...state,
				projectCandidateTrackNoRecentChanges: action.payload.results,
				projectCandidateTrackNoRecentChangesPagination: action.payload.pagination,
				fetchingCandidateTrackNoRecentChanges: false,
			};

		case LOGOUT:
			return {
				...state,
				...mapxProjectInitialState,
			};

		default:
			return state;
	}
};

export default projectReducer;
