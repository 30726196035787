import type {IconProps} from "assets/icons/types";
import type {FC} from "react";

export const MinimizeIcon: FC<IconProps> = (props) => (
	<svg
		width="32"
		height="32"
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<rect x="0.5" y="0.5" width="31" height="31" rx="3.5" stroke="#DAD8D7" />
		<mask
			id="mask0_3443_12153"
			style={{maskType: "alpha"}}
			maskUnits="userSpaceOnUse"
			x="6"
			y="6"
			width="20"
			height="20"
		>
			<rect x="6" y="6" width="20" height="20" fill="#D9D9D9" />
		</mask>
		<g mask="url(#mask0_3443_12153)">
			<path
				d="M15.1665 16.8332V21.8332H13.4998V18.4998H10.1665V16.8332H15.1665ZM18.4998 10.1665V13.4998H21.8332V15.1665H16.8332V10.1665H18.4998Z"
				fill="#837D7C"
			/>
		</g>
	</svg>
);
