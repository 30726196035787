const JobtitleIcon = () => {
	return (
		<svg
			width="48"
			height="48"
			viewBox="0 0 48 48"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M39.9604 31.5601V11.3999C39.9604 10.0103 38.83 8.87985 37.4404 8.87985H5.52003C4.13041 8.87985 3 10.0103 3 11.3999V31.5601C3 32.9497 4.13041 34.0801 5.52003 34.0801H37.4404C38.83 34.0801 39.9604 32.9497 39.9604 31.5601ZM4.68002 31.5601V11.3999C4.68002 10.9368 5.05695 10.5599 5.52003 10.5599H37.4404C37.9034 10.5599 38.2804 10.9368 38.2804 11.3999V31.5601C38.2804 32.0232 37.9034 32.4001 37.4404 32.4001H5.52003C5.05695 32.4001 4.68002 32.0232 4.68002 31.5601Z"
				fill="#837D7C"
			/>
			<path
				d="M42.4799 13.9199C42.016 13.9199 41.6399 14.296 41.6399 14.7599C41.6399 15.2238 42.016 15.5999 42.4799 15.5999C42.943 15.5999 43.3199 15.9768 43.3199 16.4399V36.6001C43.3199 37.0632 42.943 37.4401 42.4799 37.4401H10.5596C10.0965 37.4401 9.71957 37.0632 9.71957 36.6001C9.71957 36.1362 9.34345 35.7601 8.87956 35.7601C8.41566 35.7601 8.03955 36.1362 8.03955 36.6001C8.03955 37.9898 9.16995 39.1202 10.5596 39.1202H42.4799C43.8695 39.1202 44.9999 37.9898 44.9999 36.6001V16.4399C44.9999 15.0503 43.8695 13.9199 42.4799 13.9199Z"
				fill="#837D7C"
			/>
			<path
				d="M16.076 21.0549C16.8185 20.297 17.28 19.2622 17.28 18.1199C17.28 15.8042 15.3957 13.9199 13.0799 13.9199C10.7642 13.9199 8.87989 15.8042 8.87989 18.1199C8.87989 19.2622 9.34132 20.297 10.0839 21.0549C7.90622 21.9172 6.35986 24.0393 6.35986 26.52V28.2C6.35986 28.6639 6.73598 29.0401 7.19987 29.0401H18.96C19.4239 29.0401 19.8 28.6639 19.8 28.2V26.52C19.8 24.0393 18.2536 21.9172 16.076 21.0549ZM13.0799 15.5999C14.4696 15.5999 15.6 16.7303 15.6 18.1199C15.6 19.5096 14.4696 20.64 13.0799 20.64C11.6903 20.64 10.5599 19.5096 10.5599 18.1199C10.5599 16.7303 11.6903 15.5999 13.0799 15.5999ZM18.12 27.36H8.03988V26.52C8.03988 24.2043 9.92416 22.32 12.2399 22.32H13.9199C16.2357 22.32 18.12 24.2043 18.12 26.52V27.36Z"
				fill="#837D7C"
			/>
			<path
				d="M34.0799 14.7599H24.8399C23.4502 14.7599 22.3198 15.8903 22.3198 17.2799C22.3198 18.6696 23.4502 19.8 24.8399 19.8H34.0799C35.4696 19.8 36.6 18.6696 36.6 17.2799C36.6 15.8903 35.4696 14.7599 34.0799 14.7599ZM34.0799 18.12H24.8399C24.3768 18.12 23.9998 17.743 23.9998 17.2799C23.9998 16.8169 24.3768 16.4399 24.8399 16.4399H34.0799C34.543 16.4399 34.92 16.8169 34.92 17.2799C34.92 17.743 34.543 18.12 34.0799 18.12Z"
				fill="#837D7C"
			/>
			<path
				d="M34.0799 23.16H24.8399C23.4502 23.16 22.3198 24.2904 22.3198 25.68C22.3198 27.0697 23.4502 28.2001 24.8399 28.2001H34.0799C35.4696 28.2001 36.6 27.0697 36.6 25.68C36.6 24.2904 35.4696 23.16 34.0799 23.16ZM34.0799 26.52H24.8399C24.3768 26.52 23.9998 26.1431 23.9998 25.68C23.9998 25.217 24.3768 24.84 24.8399 24.84H34.0799C34.543 24.84 34.92 25.217 34.92 25.68C34.92 26.1431 34.543 26.52 34.0799 26.52Z"
				fill="#837D7C"
			/>
		</svg>
	);
};

export default JobtitleIcon;
