import React, {type FC, useCallback, useEffect, useMemo, useState} from "react";

import {SkeletonLoaderCardAsList} from "components";
import type {SICandidate} from "api/candidateApi/types";
import {ProjectCandidateCard} from "mapx-components";
import {useAppDispatch, useAppSelector} from "hooks";
import {buildCandidateApiRequestPayload} from "helpers/filterHandlers";
import css from "mapx-pages/Projects/projects.module.scss";
import {
	activeProjectResultsTabContentSelector,
	additionalProfileFiltersSelector,
	AdditionalProfilesPaginationInfoSelector,
} from "store/mapx/additional-profiles/additionalProfilesSelectors";

import type {TAPResultBlockProps} from "./types";
import {TCandidateApiFilterSelection} from "api/candidateApi/form";
import Pagination from "../../../Pagination";
import {setAPResultsPaginationInfo} from "store/mapx/additional-profiles/additionalProfilesActions";
import {globalPerPageSelector} from "store/mapx/userPreference/userPreferenceSelectors";
import {setPerPagePreference} from "store/mapx/userPreference/userPreferenceActions";

const PaginatedResultBlock: FC<TAPResultBlockProps> = ({
	handleAPICall,
	results,
	setCancelled,
	setLoadingFilteredResult,
	displayAllExperience,
	searchType,
	currentSearchStatus,
	limitResult,
	displayingLoader,
	searchID,
}) => {
	const dispatch = useAppDispatch();
	const activeContent = useAppSelector(activeProjectResultsTabContentSelector);
	const additionalProfilesFilters = useAppSelector(additionalProfileFiltersSelector);
	const paginationInfo = useAppSelector(AdditionalProfilesPaginationInfoSelector);
	const globalPerPageValue = useAppSelector(globalPerPageSelector);

	const [paginateLoading, setPaginateLoading] = useState<boolean>(false);
	const [displayedResults, setDisplayedResults] = useState<SICandidate[]>([]);

	const getProfiles = useCallback(
		async (
			filterPayload?: Nullable<TCandidateApiFilterSelection>,
			page = 1,
			perPage = globalPerPageValue,
		) => {
			const response = await handleAPICall(page, filterPayload, perPage);

			if (setCancelled) {
				setCancelled(response === "cancelled");
			}
		},
		[handleAPICall, setCancelled, globalPerPageValue],
	);

	const candidateFilterPayload = useMemo(() => {
		const payload = buildCandidateApiRequestPayload({...additionalProfilesFilters});

		return payload ? {...payload.filters, ...payload.sorting_options} : null;
	}, [additionalProfilesFilters]);

	useEffect(() => {
		if (currentSearchStatus === "Completed") {
			if (setLoadingFilteredResult) {
				setLoadingFilteredResult(true);
			}

			const fetchProfiles = async () => {
				if (candidateFilterPayload) {
					await getProfiles(
						candidateFilterPayload,
						paginationInfo?.page,
						globalPerPageValue,
					);
				} else {
					await getProfiles(null, paginationInfo?.page, globalPerPageValue);
				}
				setPaginateLoading(false);
				if (setLoadingFilteredResult) setLoadingFilteredResult(false);
			};

			fetchProfiles();
		}
	}, [
		getProfiles,
		currentSearchStatus,
		candidateFilterPayload,
		setLoadingFilteredResult,
		paginationInfo?.page,
		globalPerPageValue,
		dispatch,
	]);

	useEffect(() => {
		return () => {
			dispatch(setAPResultsPaginationInfo({page: 1, per_page: globalPerPageValue}));
		};
	}, [dispatch, searchID, candidateFilterPayload, globalPerPageValue]);

	const updateDisplayedResults = useCallback(() => {
		if (results?.count > 0 && limitResult !== undefined) {
			const filteredResults =
				results.count <= limitResult
					? results.candidates
					: results.candidates.slice(0, limitResult);

			setDisplayedResults(filteredResults);
		} else {
			setDisplayedResults(results?.candidates || []);
		}
	}, [results, limitResult]);

	useEffect(() => {
		updateDisplayedResults();
	}, [updateDisplayedResults]);

	const onPerPageChange = async (count: number) => {
		dispatch(setPerPagePreference(count));

		setPaginateLoading(true);
		dispatch(setAPResultsPaginationInfo({...paginationInfo, per_page: count}));
	};

	const onPageChange = async (newPage: number) => {
		setPaginateLoading(true);
		dispatch(setAPResultsPaginationInfo({...paginationInfo, page: newPage}));
	};

	return (
		<div>
			{paginateLoading && <SkeletonLoaderCardAsList count={paginationInfo?.per_page} />}

			{displayedResults?.length > 0 &&
				!paginateLoading &&
				displayedResults?.map((candidate, index) => (
					<ProjectCandidateCard
						key={candidate?.id}
						candidate={candidate}
						searchType={searchType}
						isAdditionalProfileList={activeContent === "search_results"}
						displayAllExperience={displayAllExperience}
						upgradeView={limitResult !== undefined ? index === limitResult : false}
					/>
				))}

			{!displayingLoader && !paginateLoading && !(displayedResults?.length > 0) && (
				<div className={css.noResultFound}>
					{(searchType === "Free Text Input Search" ||
						searchType === "Additional Profiles") && (
						<div>
							Hmm, I have not found any results &#128542;. Try to re-configure your
							search criteria on Deep Web Search.
						</div>
					)}
				</div>
			)}

			{currentSearchStatus === "Completed" &&
				activeContent !== "rejected_profiles" &&
				!paginateLoading &&
				paginationInfo &&
				paginationInfo?.count >= 10 && (
					<div className={css.paginationWrapper}>
						<Pagination
							currentPage={paginationInfo?.page}
							resultsCount={paginationInfo?.count}
							totalPages={paginationInfo?.pages}
							perPage={globalPerPageValue}
							onPageChange={onPageChange}
							onPerPageChange={onPerPageChange}
						/>
					</div>
				)}
		</div>
	);
};

export default PaginatedResultBlock;
