import {SET_SIDEBAR_CHILDITEMS_EXPANDED, SET_SIDEBAR_EXTENDED} from "store/mapx/mapXActionTypes";

export const layoutInitialState = {
	sidebarExpanded: false,
	expandedSidebarChildItems: null,
};

const layoutReducer = (state = layoutInitialState, action) => {
	switch (action.type) {
		case SET_SIDEBAR_EXTENDED:
			return {
				...state,
				sidebarExpanded: action.payload,
			};
		case SET_SIDEBAR_CHILDITEMS_EXPANDED:
			return {
				...state,
				expandedSidebarChildItems: action.payload,
			};
		default:
			return state;
	}
};

export default layoutReducer;
