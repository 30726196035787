import HttpClient from "api/index";
import {STPaginationParam} from "api/types";
import {TTrackingPositionChangeDays} from "api/projectApi/candidateTracking/types";

class CandidateTracking extends HttpClient {
	async projectTrackCandidateLeavers(
		projectId: number,
		positionChangeDays: TTrackingPositionChangeDays,
		pagination: STPaginationParam,
		config = {},
	) {
		const formData = {
			pagination,
			position_change_days: positionChangeDays,
		};

		return await this.doPost(`/projects/${projectId}/tracking/leavers`, formData, config);
	}

	async projectTrackCandidatePromotions(
		projectId: number,
		positionChangeDays: TTrackingPositionChangeDays,
		pagination: STPaginationParam,
		config = {},
	) {
		const formData = {
			pagination,
			position_change_days: positionChangeDays,
		};

		return await this.doPost(`/projects/${projectId}/tracking/promotions`, formData, config);
	}

	async projectTrackCandidateNoRecentChanges(
		projectId: number,
		positionChangeDays: TTrackingPositionChangeDays,
		pagination: STPaginationParam,
		config = {},
	) {
		const formData = {
			pagination,
			position_change_days: positionChangeDays,
		};

		return await this.doPost(
			`/projects/${projectId}/tracking/no-recent-changes`,
			formData,
			config,
		);
	}
}

const candidateTracking = new CandidateTracking();

export default candidateTracking;
