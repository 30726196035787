import axios, {CanceledError} from "axios";
import {
	getSearchedCandidatesDone,
	getSearchedCandidatesFail,
	getSearchedCandidatesInit,
	setCandidateTargetListToggle,
	setFilterForCandidates,
} from "store/mapx/filter/filterActions";
import mapXCandidateApi from "api/candidateApi";
import {targetListCompanyIdsSelector} from "store/mapx/target-list/targetListSelectors";
import {
	candidateTargetListPositionSelector,
	tlCompanyIdsByTargetListPositionSelector,
	tlCompaniesFilterKeyByPositionSelector,
} from "store/mapx/filter/filterSelectors";
import {Dispatch} from "react";
import {TAppDispatch, TRootState} from "types";
import {ICandidateSearchApiRequestPayload} from "api/candidateApi/form";

/* eslint-disable  @typescript-eslint/no-explicit-any */
const cancelTokens = {} as any;
export const getSearchedCandidatesData =
	(name: string, page: number) => async (dispatch: Dispatch<TAppDispatch>) => {
		const requestKey = "SEARCH_CANDIDATE_BY_NAME";

		if (cancelTokens[requestKey]) {
			cancelTokens[requestKey].cancel("Operation canceled due to new request.");
		}

		cancelTokens[requestKey] = axios.CancelToken.source();

		const config = {
			cancelToken: cancelTokens[requestKey].token,
		};

		dispatch(getSearchedCandidatesInit());

		let response;

		try {
			if (name !== null) {
				const payload: ICandidateSearchApiRequestPayload = {
					filters: {
						full_name: name,
					},
					pagination: {
						page,
						per_page: 40,
					},
				};

				response = await mapXCandidateApi.getCandidatesByName(payload, config);

				if (response == null) {
					throw new CanceledError("Operation canceled due to new request.");
				} else if (response.status === 200) {
					dispatch(
						getSearchedCandidatesDone({
							results: response.data.results,
							pagination: response.data.pagination,
						}),
					);
				}
			}
		} catch (error) {
			dispatch(getSearchedCandidatesFail({error}));

			return {error};
		}
	};

export const toggleAllTargetCompaniesOnCandidateFilter =
	(add = false) =>
	(dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const state = getState();

		const targetListCompanyIDs = targetListCompanyIdsSelector(state);

		const position = candidateTargetListPositionSelector(state);

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const filterKey = tlCompaniesFilterKeyByPositionSelector(state, position);

		const companyIds = tlCompanyIdsByTargetListPositionSelector(state);

		if (add) {
			const existingCompanyIds = new Set(companyIds);

			let newCompanyIds = [...companyIds];

			if (companyIds?.length > 0) {
				targetListCompanyIDs.forEach((item: number) => {
					const exist = existingCompanyIds.has(item);

					if (!exist) {
						newCompanyIds.push(item);
					}
				});
			} else {
				newCompanyIds = [...targetListCompanyIDs];
			}

			dispatch(setFilterForCandidates({type: filterKey, values: newCompanyIds}));
			dispatch(setCandidateTargetListToggle(true));
		} else {
			const targetListCompanyIds = targetListCompanyIdsSelector(state);

			// That means switch is already ON and now should be set OFF.
			dispatch(
				setFilterForCandidates({
					type: filterKey,
					values: companyIds?.filter(
						(item: number) => !targetListCompanyIds.includes(item),
					),
				}),
			);
			dispatch(setCandidateTargetListToggle(false));
		}
	};
