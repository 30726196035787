import React, {useCallback, useMemo, useState} from "react";
import {STProject, STProjectProgressStatus} from "api/projectApi/types";
import {useAppDispatch, useAppSelector, useOpenLink} from "hooks";
import {projectSelector} from "store/mapx/project-list/projectListSelectors";
import {projectProgressStatusesSelector} from "store/mapx/project/projectSelectors";
import {isCandidateContactDetailsGenerating} from "store/mapx/project/generateCandidateContactDetailsSelectors";
import {
	isCandidateSelectedForAssessmentSelector,
	isCurrentCandidateForCurrentProjectScoringInProgressSelector,
} from "store/mapx/project/candidateScoringSelectors";
import {generateCandidatesContactDetails} from "store/mapx/project/generateCandidateContactDetailsAsyncActions";
import {isCurrentCurrentCandidateRefreshIsInProgressSelector} from "store/mapx/refresh-candidate/refreshCandidateSelectors";
import {removeTrailingSlash} from "helpers/string";
import {TProjectCandidateStatusDropdownOption} from "mapx-components/Cards/ProjectCandidateCard/types";
import {changeCandidateStatusByCandidateId} from "store/mapx/project/projectAsyncActions";
import Loader from "components/Plugins/Loader";
import RemoveMinusIcon from "mapx-components/Cards/ProjectCandidateCard/RemoveMinusIcon";
import {SICandidate} from "api/candidateApi/types";
import {CandidateSearchTypes} from "mapx-components/Cards/CandidateCard/types";
import classNames from "classnames";
import styles from "mapx-components/Cards/ProjectCandidateCard/projectCandidateCard.module.scss";
import {ArrowDown} from "assets/icons";
import {Dropdown} from "components";
import {getSingleCandidateDone} from "store/mapx/candidate/candidateActions";
import {useHistory} from "react-router-dom";

const useProjectSavedCandidatesHook = (
	candidate: SICandidate,
	searchType: CandidateSearchTypes,
	statusDropdownWidth = 232,
) => {
	const dispatch = useAppDispatch();

	const openLink = useOpenLink();

	const history = useHistory();

	const project: STProject = useAppSelector(projectSelector);

	const projectProgressStatuses: STProjectProgressStatus[] = useAppSelector(
		projectProgressStatusesSelector,
	);

	const [updatingProgressStatus, setUpdatingProgressStatus] = useState(false);

	const loadingCandidateContactDetails = useAppSelector((state) =>
		isCandidateContactDetailsGenerating(state, candidate),
	);

	const isCandidateSelectedForAssessment = useAppSelector((state) =>
		isCandidateSelectedForAssessmentSelector(state, project?.id, candidate?.id),
	);

	const isCurrentCandidateAssessmentInProgress = useAppSelector((state) =>
		isCurrentCandidateForCurrentProjectScoringInProgressSelector(
			state,
			project?.id,
			candidate?.id,
		),
	);

	const positions = useMemo(() => candidate.positions ?? [], [candidate]);

	const handleItemClick = useCallback(
		(event?: React.MouseEvent<HTMLElement | SVGElement, MouseEvent>) => {
			dispatch(getSingleCandidateDone(candidate));

			if (event && (event.ctrlKey || event.metaKey || event.ctrlKey || event.altKey)) {
				const originSource = location.pathname;

				const link = `/candidate/${candidate.id}?src=${originSource}`;

				openLink(event, link, true);
			} else {
				const link = `${location.pathname}/${candidate.id}?src=${searchType}`;

				history.push(link);
			}
		},
		[candidate, dispatch, history, openLink, searchType],
	);

	/**
	 * IS THIS A AI SEARCH TYPE
	 */

	const isThisAISearchType = useMemo(
		() =>
			searchType === "Additional Profiles" ||
			searchType === "Free Text Input Search" ||
			searchType === "Generate More Results" ||
			searchType === "More Like This" ||
			searchType === "Rejected Profiles",
		[searchType],
	);

	const handleGenerateContactButtonClick = useCallback(async () => {
		await dispatch(generateCandidatesContactDetails([candidate?.id]));
	}, [dispatch, candidate?.id]);

	const isCurrentCurrentCandidateRefreshIsInProgress = useAppSelector((state) =>
		isCurrentCurrentCandidateRefreshIsInProgressSelector(
			state,
			removeTrailingSlash(candidate.linkedin_url ?? ""),
		),
	);

	const handleCandidateStatusChange = useCallback(
		async (option: TProjectCandidateStatusDropdownOption) => {
			if (candidate) {
				setUpdatingProgressStatus(true);
				await dispatch(changeCandidateStatusByCandidateId(candidate, option));
				setUpdatingProgressStatus(false);
			}
		},
		[candidate, dispatch],
	);

	const InlineLoaderComponent = <Loader height={14} width={14} color={"#4E5555"} type={"Oval"} />;

	const progressStatusDropdownOptions: TProjectCandidateStatusDropdownOption[] = useMemo(() => {
		const removeOption = {value: null, name: "Remove Status", icon: <RemoveMinusIcon />};

		const options = projectProgressStatuses.map((status) => ({
			value: status.id,
			name: status.name,
		}));

		if (candidate?.progress_status) {
			return [...options, removeOption];
		} else {
			return options;
		}
	}, [candidate?.progress_status, projectProgressStatuses]);

	const PROJECT_STATUS_DROPDOWN = (
		<Dropdown
			onSelect={handleCandidateStatusChange}
			options={progressStatusDropdownOptions}
			getOptionLabel={(option) => option.name}
			isOptionValueNull={(option) => option.value === null}
			getOptionIcon={(option) => option.icon}
			defaultSelectedOption={
				progressStatusDropdownOptions.find((s) => s.name === candidate?.progress_status) ||
				null
			}
			dropdownButtonWidth={statusDropdownWidth}
			placeholder={
				<div
					className={classNames(styles.dropdownPlaceholder, {
						[styles.hasStatus]: candidate.progress_status,
					})}
					style={{width: statusDropdownWidth}}
				>
					<div style={{display: "flex", alignItems: "center"}}>
						<span className={styles.dropdownTitle}>
							{candidate?.progress_status
								? candidate?.progress_status
								: updatingProgressStatus
								? "Updating"
								: "Candidate Status"}{" "}
						</span>
						{updatingProgressStatus && InlineLoaderComponent}
					</div>

					<div>
						<ArrowDown stroke={"#B0B5B4"} />
					</div>
				</div>
			}
			dropdownButtonClass={styles.statusDropdown}
		/>
	);

	return {
		project,
		handleItemClick,
		projectProgressStatuses,
		loadingCandidateContactDetails,
		isCandidateSelectedForAssessment,
		isCurrentCandidateAssessmentInProgress,
		positions,
		isThisAISearchType,
		handleGenerateContactButtonClick,
		isCurrentCurrentCandidateRefreshIsInProgress,
		InlineLoaderComponent,
		PROJECT_STATUS_DROPDOWN,
	};
};

export default useProjectSavedCandidatesHook;
