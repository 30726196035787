import useSearchDebounce from "hooks/useSearchDebounce";
import {AutoComplete} from "mapx-components";
import React, {useCallback, useEffect, useState} from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import {useSelector} from "react-redux";
import {clearSearchedCompanies} from "store/mapx/filter/filterActions";
import {CompanyItem} from "components";
import {TCompanySearchFilterProps} from "mapx-components/Filters/CompanySearchFilter/types";
import {
	searchedCompaniesPaginationSelector,
	searchedCompaniesSelector,
} from "store/mapx/filter/filterSelectors";
import {useAppDispatch} from "hooks";
import SelectedCompaniesTag from "mapx-components/Filters/CompanySearchFilter/SelectedCompaniesTag";
import {getSearchedCompaniesData} from "store/mapx/filter/CompanySearchPageFilters/CompanyFilterAsyncActions";
import {STCompany} from "api/companyApi/types";

function CompanySearchFilter({
	handleOnClick,
	handleRemoveClick,
	selectedCompanies = [],
	displayCompaniesList = true,
}: TCompanySearchFilterProps) {
	const dispatch = useAppDispatch();

	const searchedCompanies = useSelector(searchedCompaniesSelector);

	const searchedCompaniesPagination = useSelector(searchedCompaniesPaginationSelector);

	const [searchTerm, setSearchTerm] = useSearchDebounce(800);

	const [loading, setLoading] = useState(false);

	const searchCompanies = useCallback(
		async (companyName: string, page: number) => {
			if (companyName && companyName.length >= 2) {
				setLoading(true);

				await dispatch(getSearchedCompaniesData(companyName, page)).then(
					(response: {error: {name: string}}) => {
						if (response?.error && response?.error?.name === "CanceledError") {
							setLoading(true);
						} else {
							setLoading(false);
						}
					},
				);
			}
		},
		[dispatch],
	);

	const [loadingRef] = useInfiniteScroll({
		loading,
		hasNextPage: searchedCompaniesPagination > searchedCompaniesPagination.page,
		onLoadMore: () => searchCompanies(searchTerm, searchedCompaniesPagination.page + 1 || 1),
		rootMargin: "0px 0px 400px 0px",
	});

	useEffect(() => {
		dispatch(clearSearchedCompanies());

		if (searchTerm) {
			searchCompanies(searchTerm, 1);
		}
	}, [searchTerm, dispatch, searchCompanies]);

	return (
		<div>
			<AutoComplete
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				handleFilterChange={setSearchTerm}
				handleItemClick={(company: STCompany) => {
					handleOnClick(company);
					setSearchTerm("");
				}}
				hasMore={loading}
				isLoading={loading}
				notFoundResult="No company found"
				ref={loadingRef}
				suggestions={searchedCompanies}
				SuggestionListItemComponent={CompanyItem}
			/>

			{displayCompaniesList && (
				<SelectedCompaniesTag
					handleRemoveClick={(id: number) => {
						if (handleRemoveClick) {
							handleRemoveClick(id);
						}
						setSearchTerm("");
					}}
					selectedCompanies={selectedCompanies}
				/>
			)}
		</div>
	);
}

export default CompanySearchFilter;
