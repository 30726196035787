import React, {memo, useCallback} from "react";
import {Accordion} from "mapx-components";
import {setMultipleFilterForCompany} from "store/mapx/filter/filterActions";
import {useAppDispatch, useAppSelector} from "hooks";
import {
	allCompanySpecialtiesCountSelector,
	companySpecialitiesConnectivityLogicSelector,
} from "store/mapx/filter/filterSelectors";
import {useSelector} from "react-redux";
import {setSpecialityClustersForCompany} from "store/mapx/filter/companySpecialtyClusterFilterAsyncActions";
import {allSelectedCompanySpecialtiesByLogicSelector} from "store/mapx/filter/companySpecialtyCLusterSelectors";
import CompanySpecialityClusterSearchFilter from "mapx-components/Filters/CompanySpecialtyClusterSearchFilter";

function SpecialtyClusterFilter() {
	const dispatch = useAppDispatch();
	const allCompanySpecialtiesCount = useSelector(allCompanySpecialtiesCountSelector);

	const handleResetClick = useCallback(() => {
		dispatch(
			setMultipleFilterForCompany({
				specialties_not: [],
				specialties_or: [],
				specialties_and: [],
			}),
		);
	}, [dispatch]);

	const companySpecialitiesConnectivityLogic = useSelector(
		companySpecialitiesConnectivityLogicSelector,
	);

	const selectedSpecialityIds = useAppSelector((state) =>
		allSelectedCompanySpecialtiesByLogicSelector(state),
	);

	const handleSpecialitiesChange = useCallback(
		(id: number) => {
			dispatch(setSpecialityClustersForCompany([id], companySpecialitiesConnectivityLogic));
		},
		[dispatch, companySpecialitiesConnectivityLogic],
	);

	return (
		<Accordion title="Specialities" subtitle={allCompanySpecialtiesCount}>
			<CompanySpecialityClusterSearchFilter
				totalSpecialities={allCompanySpecialtiesCount}
				handleResetClick={handleResetClick}
				handleSpecialitiesChange={handleSpecialitiesChange}
				selectedSpecialityIds={selectedSpecialityIds}
			/>
		</Accordion>
	);
}

export default memo(SpecialtyClusterFilter);
