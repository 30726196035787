import type {AnyAction} from "redux";

import type {STMarketMap} from "api/marketMapApi/types";

import {
	ADD_COMPANIES_FROM_MARKET_MAP_TO_TARGET_LIST_DONE,
	ADD_FROM_MARKET_MAP_MODAL,
	ADD_FROM_MARKET_MAP_MODAL_FROM_MR,
	ADD_TO_MARKET_MAP_MODAL,
	CREATE_MARKET_MAP_DONE,
	CREATE_MARKET_MAP_FAIL,
	CREATE_MARKET_MAP_INIT,
	CREATE_MARKET_MAP_MODAL,
	DELETE_MARKET_MAP_DONE,
	DELETE_MARKET_MAP_FAIL,
	DELETE_MARKET_MAP_INIT,
	DOWNLOAD_MARKET_MAPS_PNG_DONE,
	DOWNLOAD_MARKET_MAPS_PNG_FAIL,
	DOWNLOAD_MARKET_MAPS_PNG_INIT,
	DUPLICATE_MARKET_MAP_MODAL,
	EDIT_MARKET_MAP_MODAL,
	GET_MARKET_MAPS_DONE,
	GET_MARKET_MAPS_FAIL,
	GET_MARKET_MAPS_FOR_MODAL_DONE,
	GET_MARKET_MAPS_FOR_MODAL_FAIL,
	GET_MARKET_MAPS_FOR_MODAL_INIT,
	GET_MARKET_MAPS_INIT,
	GET_TARGETLIST_COMPANIES_DONE,
	RESET_MARKET_MAP_ERROR,
	SET_MARKET_MAPS_PAGINATION_INFO,
	UPDATE_MARKET_MAP_DONE,
	UPDATE_MARKET_MAP_FAIL,
	UPDATE_MARKET_MAP_INIT,
} from "store/mapx/mapXActionTypes";
import {ATPagination} from "api/types";

export type TMarketMapState = {
	market_map_modal_displayed_for_mr: boolean;
	add_from_market_map_modal_props: Record<string, unknown>;
	add_from_market_map_modal: boolean;
	add_to_market_map_modal: boolean;
	create_market_map_modal_props: Record<string, unknown>;
	create_market_map_modal: boolean;
	created_by: ("mapx" | "organisation" | "self")[];
	creation_error: unknown;
	creation_in_progress: boolean;
	deletion_in_progress: number[];
	downloading: boolean;
	duplicate_market_map_modal: boolean;
	edit_market_map_modal: boolean;
	getting_in_progress: boolean;
	last_creation: unknown;
	modal_getting_in_progress: boolean;
	modal_name: string;
	modal_pagination: ATPagination | Record<never, never>;
	modal_results: STMarketMap[];
	name: string;
	pagination: ATPagination | Record<never, never>;
	results: STMarketMap[];
};

export const marketMapInitialState: TMarketMapState = {
	market_map_modal_displayed_for_mr: false,
	add_from_market_map_modal_props: {},
	add_from_market_map_modal: false,
	add_to_market_map_modal: false,
	create_market_map_modal_props: {},
	create_market_map_modal: false,
	created_by: [],
	creation_error: null,
	creation_in_progress: false,
	deletion_in_progress: [],
	downloading: false,
	duplicate_market_map_modal: false,
	edit_market_map_modal: false,
	getting_in_progress: false,
	last_creation: null,
	modal_getting_in_progress: false,
	modal_name: "",
	modal_pagination: {},
	modal_results: [],
	name: "",
	pagination: {},
	results: [],
};

const marketMapReducer = (state = marketMapInitialState, action: AnyAction) => {
	switch (action.type) {
		case DOWNLOAD_MARKET_MAPS_PNG_INIT:
			return {
				...state,
				downloading: true,
			};
		case DOWNLOAD_MARKET_MAPS_PNG_DONE:
			return {
				...state,
				downloading: false,
			};
		case DOWNLOAD_MARKET_MAPS_PNG_FAIL:
			return {
				...state,
				downloading: false,
			};
		case DELETE_MARKET_MAP_INIT: {
			const id = action.payload;

			return {
				...state,
				deletion_in_progress: [...state.deletion_in_progress, id],
			};
		}
		case DELETE_MARKET_MAP_DONE: {
			const id = action.payload;

			return {
				...state,
				deletion_in_progress: state.deletion_in_progress.filter(
					(item: number) => item !== id,
				),
				// @TODO: Define server types for TRootState
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				results: state.results.filter((item: any) => item.id !== id),
				// @TODO: Define server types for TRootState
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				modal_results: state.modal_results.filter((item: any) => item.id !== id),
			};
		}
		case DELETE_MARKET_MAP_FAIL: {
			const {id} = action.payload;

			return {
				...state,
				deletion_in_progress: state.deletion_in_progress.filter(
					(item: number) => item !== id,
				),
			};
		}
		case UPDATE_MARKET_MAP_INIT:
			return {
				...state,
				creation_in_progress: true,
				creation_error: null,
			};
		case UPDATE_MARKET_MAP_DONE: {
			const marketMap = action.payload;

			return {
				...state,
				creation_in_progress: false,
				// @TODO: Define server types for TRootState
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				results: state.results.map((item: any) =>
					item.id === marketMap.id ? marketMap : item,
				),
				// @TODO: Define server types for TRootState
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				modal_results: state.modal_results.map((item: any) =>
					item.id === marketMap.id ? marketMap : item,
				),
				edit_market_map_modal: false,
			};
		}
		case UPDATE_MARKET_MAP_FAIL:
			return {
				...state,
				creation_in_progress: false,
				creation_error: action.payload,
			};
		case ADD_TO_MARKET_MAP_MODAL:
			return {
				...state,
				add_to_market_map_modal: action.payload,
			};
		case DUPLICATE_MARKET_MAP_MODAL:
			return {
				...state,
				duplicate_market_map_modal: action.payload,
			};
		case EDIT_MARKET_MAP_MODAL:
			return {
				...state,
				edit_market_map_modal: action.payload,
			};
		case ADD_FROM_MARKET_MAP_MODAL: {
			const {display, props} = action.payload;

			return {
				...state,
				add_from_market_map_modal: display,
				market_map_modal_displayed_for_mr: false,
				add_from_market_map_modal_props: props,
			};
		}
		case ADD_FROM_MARKET_MAP_MODAL_FROM_MR: {
			const {display} = action.payload;

			return {
				...state,
				add_from_market_map_modal: display,
				market_map_modal_displayed_for_mr: display,
			};
		}
		case ADD_COMPANIES_FROM_MARKET_MAP_TO_TARGET_LIST_DONE:
		case GET_TARGETLIST_COMPANIES_DONE:
			return {
				...state,
				add_from_market_map_modal: false,
				market_map_modal_displayed_for_mr: false,
			};
		case CREATE_MARKET_MAP_MODAL: {
			const {display, props} = action.payload;

			return {
				...state,
				create_market_map_modal: display,
				create_market_map_modal_props: props,
			};
		}
		case RESET_MARKET_MAP_ERROR:
			return {
				...state,
				creation_error: null,
			};
		case CREATE_MARKET_MAP_INIT:
			return {
				...state,
				creation_in_progress: true,
				creation_error: null,
			};
		case CREATE_MARKET_MAP_DONE: {
			const marketMap = action.payload;

			return {
				...state,
				create_market_map_modal: false,
				creation_in_progress: false,
				duplicate_market_map_modal: false,
				last_creation: new Date().toJSON(),
				modal_results:
					state.modal_results.length > 0 ? [marketMap, ...state.modal_results] : [],
				results: [marketMap, ...state.results],
			};
		}
		case CREATE_MARKET_MAP_FAIL:
			return {
				...state,
				creation_in_progress: false,
				creation_error: action.payload,
			};
		case GET_MARKET_MAPS_FOR_MODAL_INIT:
			return {
				...state,
				modal_getting_in_progress: true,
			};
		case GET_MARKET_MAPS_FOR_MODAL_DONE: {
			const {
				data: {pagination, results},
				name,
			} = action.payload;

			return {
				...state,
				modal_results:
					pagination.page > 1 ? [...state.modal_results, ...results] : results || [],
				modal_pagination: pagination || {},
				modal_getting_in_progress: false,
				modal_name: name,
			};
		}
		case GET_MARKET_MAPS_FOR_MODAL_FAIL:
			return {
				...state,
				modal_getting_in_progress: false,
			};
		case GET_MARKET_MAPS_INIT:
			return {
				...state,
				getting_in_progress: true,
			};
		case GET_MARKET_MAPS_DONE:
			const {
				data: {pagination, results},
				name,
				createdBy,
			} = action.payload;

			return {
				...state,
				name,
				created_by: createdBy,
				results: results,
				pagination: pagination || {},
				getting_in_progress: false,
			};
		case GET_MARKET_MAPS_FAIL:
			return {
				...state,
				getting_in_progress: false,
			};
		case SET_MARKET_MAPS_PAGINATION_INFO:
			return {
				...state,
				pagination: action.payload,
			};
		default:
			return state;
	}
};

export default marketMapReducer;
