import React from "react";
import css from "../marketReports.module.scss";
import {SearchIcon} from "assets/icons";
import {TMarketReportSearchProps} from "../types";
import {Loader} from "components";

const MarketMapSearch: React.FC<TMarketReportSearchProps> = ({onChange, loading, value}) => {
	return (
		<div role="button" className={css.search_container}>
			<SearchIcon width="20" height="20" className={css.search_container__icon} />
			<input
				className={css.search_container__inp}
				onChange={onChange}
				placeholder="Search a Market Review"
				value={value}
			/>
			{loading && <Loader height={"10px"} width={"10px"} />}
		</div>
	);
};

export default MarketMapSearch;
