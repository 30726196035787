import {
	SET_PER_PAGE_PREFERENCE,
	SET_PROJECT_LAYOUT_VIEW_PREFERENCE,
} from "store/mapx/mapXActionTypes";
import {AnyAction} from "redux";
import {TUserPreferenceInitialState} from "store/mapx/userPreference/types";

export const userPreferenceInitialState: TUserPreferenceInitialState = {
	per_page: 20,
	project_layout_view: "max-card",
};

const userPreferenceReducer = (state = userPreferenceInitialState, action: AnyAction) => {
	switch (action.type) {
		case SET_PER_PAGE_PREFERENCE:
			return {
				...state,
				per_page: action.payload,
			};
		case SET_PROJECT_LAYOUT_VIEW_PREFERENCE:
			return {
				...state,
				project_layout_view: action.payload,
			};
		default:
			return state;
	}
};

export default userPreferenceReducer;
