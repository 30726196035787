import React from "react";
import styles from "mapx-components/Cards/ProjectCandidateTableCard/styles.module.scss";
import classNames from "classnames";
import ContentLoader from "react-content-loader";
import {SICandidate} from "api/candidateApi/types";
import useProjectSavedCandidatesHook from "mapx-pages/Project/SavedPeople/useProjectSavedCandidatesHook";
import useCandidateScoringUtils from "mapx-components/Cards/CandidateScoring/useCandidateScoringUtils";
import {CandidateSearchTypes} from "mapx-components/Cards/CandidateCard/types";

const ScoringSection = ({
	candidate,
	searchType,
}: {
	candidate: SICandidate;
	searchType: CandidateSearchTypes;
}) => {
	const {isCurrentCandidateAssessmentInProgress} = useProjectSavedCandidatesHook(
		candidate,
		searchType,
		220,
	);

	const {matchScorePercentage, CIRCULAR_PROGRESS} = useCandidateScoringUtils(
		candidate?.fit_to_brief,
	);

	return (
		<div className={styles.scoringSection}>
			{!isCurrentCandidateAssessmentInProgress &&
				(candidate?.fit_to_brief && candidate?.fit_to_brief?.score !== undefined ? (
					<div
						className={classNames(styles.score, {
							[styles.lowScoreMatch]: matchScorePercentage === "low",
							[styles.midScoreMatch]: matchScorePercentage === "mid",
							[styles.highScoreMatch]: matchScorePercentage === "high",
						})}
					>
						{CIRCULAR_PROGRESS}{" "}
						<span className={styles.percentage}>
							{candidate.fit_to_brief.score}% Match
						</span>
					</div>
				) : (
					<div className={styles.score}>No Assessment</div>
				))}

			{isCurrentCandidateAssessmentInProgress && (
				<ContentLoader
					speed={2}
					height={40}
					width={"80px"}
					backgroundColor="#EEEDEC"
					foregroundColor="#e6e6e6"
				>
					<rect x="1" y="8.8" width="99%" height="22" />
				</ContentLoader>
			)}
		</div>
	);
};

export default ScoringSection;
