import {
	ADD_PAGINATED_DATA_FOR_COMPANY_ORGANIZATIONAL_CHART,
	CLEAR_COMPANY_INDUSTRY_BACKGROUND,
	CLEAR_COMPANY_ORGANIZATIONAL_CHART,
	CLEAR_COMPANY_PAGE_FILTERS_AND_ALL_THE_RESPONSES,
	CLEAR_MAP_THIS_FUNCTION_FOR_CURRENT_COMPANY,
	CLEAR_SINGLE_COMPANY_DONE,
	COMPANY_ORGANIZATIONAL_CHART_PAGINATION_LOADING,
	GET_COMPANIES_BY_FILTER_DONE,
	GET_COMPANIES_BY_FILTER_FAIL,
	GET_COMPANIES_BY_FILTER_INIT,
	GET_COMPANIES_DONE,
	GET_COMPANIES_FAIL,
	GET_COMPANIES_INIT,
	GET_COMPANY_ORG_CHART_FILTERING,
	GET_COMPANY_ORG_CHART_FILTERS_DONE,
	GET_SIMILAR_COMPANIES_DONE,
	GET_SIMILAR_COMPANIES_FAIL,
	GET_SIMILAR_COMPANIES_INIT,
	GET_SINGLE_COMPANY_DONE,
	GET_SINGLE_COMPANY_FAIL,
	GET_SINGLE_COMPANY_INIT,
	INIT_COMPANY_COMPANY_LOCATION_REPORT,
	RESET_COMPANY_REVENUE_DISTRIBUTION_DATA,
	RESET_COMPANY_SIZE_DISTRIBUTION_DATA,
	SET_COMPANIES_PAGINATION_INFO,
	SET_COMPANY_COMPANY_LOCATION_REPORT,
	SET_COMPANY_ETHNIC_DIVERSITY_DATA,
	SET_COMPANY_ETHNIC_DIVERSITY_INIT,
	SET_COMPANY_FREQUENTLY_HIRED_FROM_DATA,
	SET_COMPANY_FUNCTIONAL_BACKGROUND,
	SET_COMPANY_GENDER_DIVERSITY_DATA,
	SET_COMPANY_GENDER_DIVERSITY_INIT,
	SET_COMPANY_INDUSTRY_BACKGROUND,
	SET_COMPANY_ORGANIZATIONAL_CHART,
	SET_COMPANY_REVENUE_DISTRIBUTION_DATA,
	SET_COMPANY_SIZE_DISTRIBUTION_DATA,
	SET_COMPANY_TRENDS_STATS,
	SET_COMPANY_TRENDS_STATS_INIT,
	SET_MAP_THIS_FUNCTION_FOR_CURRENT_COMPANY,
	SET_MAP_THIS_FUNCTION_IS_IN_PROGRESS_FOR_CURRENT_COMPANY,
	SET_ORGCHART_KEYWORDS_FILTERS_DROPDOWN_DATA,
	SET_SELECTED_COMPANIES_DONE,
	UPDATE_TOOLTIP_COMPANIES,
	UPDATE_TOOLTIP_COMPANIES_FAILED,
	UPDATE_TOOLTIP_COMPANIES_PROGRESS,
} from "store/mapx/mapXActionTypes";

export function getCompaniesInit() {
	return {type: GET_COMPANIES_INIT};
}

export function getCompaniesDone(data) {
	return {type: GET_COMPANIES_DONE, payload: data};
}

export function getCompaniesFail(error) {
	return {type: GET_COMPANIES_FAIL, payload: error};
}

export function setSelectedCompaniesDone(data) {
	return {type: SET_SELECTED_COMPANIES_DONE, payload: data};
}

export function getSingleCompanyInit() {
	return {type: GET_SINGLE_COMPANY_INIT};
}

export function getSingleCompanyDone(data) {
	return {type: GET_SINGLE_COMPANY_DONE, payload: data};
}

export function clearSingleCompanyDone(data) {
	return {type: CLEAR_SINGLE_COMPANY_DONE, payload: data};
}

export function getSingleCompanyFail(error) {
	return {type: GET_SINGLE_COMPANY_FAIL, payload: error};
}

export function clearCompanyOrganizationalChart() {
	return {type: CLEAR_COMPANY_ORGANIZATIONAL_CHART};
}

export function setCompanyOrganizationalChart(payload) {
	return {type: SET_COMPANY_ORGANIZATIONAL_CHART, payload};
}

export function setCompanyCompanyLocationReport(payload) {
	return {type: SET_COMPANY_COMPANY_LOCATION_REPORT, payload};
}

export function initCompanyCompanyLocationReport(payload) {
	return {type: INIT_COMPANY_COMPANY_LOCATION_REPORT, payload};
}

export function setCompanyOrgChartPaginationLoading(payload) {
	return {type: COMPANY_ORGANIZATIONAL_CHART_PAGINATION_LOADING, payload};
}

export function addPaginatedDataForCompanyOrganizationalChart(payload) {
	return {type: ADD_PAGINATED_DATA_FOR_COMPANY_ORGANIZATIONAL_CHART, payload};
}

export function getCompaniesByFilterInit() {
	return {type: GET_COMPANIES_BY_FILTER_INIT};
}

export function getCompaniesByFilterDone(data) {
	return {type: GET_COMPANIES_BY_FILTER_DONE, payload: data};
}

export function setCompaniesPaginationInfo(data) {
	return {type: SET_COMPANIES_PAGINATION_INFO, payload: data};
}

export function getCompaniesByFilterFail(error) {
	return {type: GET_COMPANIES_BY_FILTER_FAIL, payload: error};
}

export function getSimilarCompaniesInit() {
	return {type: GET_SIMILAR_COMPANIES_INIT};
}

export function getSimilarCompaniesDone(payload) {
	return {type: GET_SIMILAR_COMPANIES_DONE, payload};
}

export function getSimilarCompaniesFail(error) {
	return {type: GET_SIMILAR_COMPANIES_FAIL, payload: error};
}

// SIZE & REVENUE ACTIONS
export function setCompanySizeDistributionData(payload) {
	return {type: SET_COMPANY_SIZE_DISTRIBUTION_DATA, payload};
}

export function resetCompanySizeFilter() {
	return {type: RESET_COMPANY_SIZE_DISTRIBUTION_DATA};
}

export function setCompanyRevenueFilter(payload) {
	return {type: SET_COMPANY_REVENUE_DISTRIBUTION_DATA, payload};
}

export function setCompanyTrendsStats(payload) {
	return {type: SET_COMPANY_TRENDS_STATS, payload};
}

export function setCompanyTrendStatsInit() {
	return {type: SET_COMPANY_TRENDS_STATS_INIT};
}

export function setCompanyGenderDiversityInit() {
	return {type: SET_COMPANY_GENDER_DIVERSITY_INIT};
}

export function setCompanyGenderDiversityData(payload) {
	return {type: SET_COMPANY_GENDER_DIVERSITY_DATA, payload};
}

export function setCompanyEthnicDiversityInit() {
	return {type: SET_COMPANY_ETHNIC_DIVERSITY_INIT};
}

export function setCompanyEthnicDiversityData(payload) {
	return {type: SET_COMPANY_ETHNIC_DIVERSITY_DATA, payload};
}

export function resetCompanyRevenueFilter() {
	return {type: RESET_COMPANY_REVENUE_DISTRIBUTION_DATA};
}

export function setCompanyFrequentlyHiredFromData(payload) {
	return {type: SET_COMPANY_FREQUENTLY_HIRED_FROM_DATA, payload};
}

export function setCompanyIndustryBackground(payload) {
	return {type: SET_COMPANY_INDUSTRY_BACKGROUND, payload};
}

export function setCompanyFunctionalBackground(payload) {
	return {type: SET_COMPANY_FUNCTIONAL_BACKGROUND, payload};
}

export function clearCompanyIndustryBackground() {
	return {type: CLEAR_COMPANY_INDUSTRY_BACKGROUND};
}

export function getCompanyOrgChartFiltering(payload) {
	return {type: GET_COMPANY_ORG_CHART_FILTERING, payload};
}

export function getCompanyOrgChartFiltersDone(payload) {
	return {type: GET_COMPANY_ORG_CHART_FILTERS_DONE, payload};
}

export function clearCompanyPageFilterWithAllResponses() {
	return {type: CLEAR_COMPANY_PAGE_FILTERS_AND_ALL_THE_RESPONSES};
}

// Map this function

/**
 *
 * @param payload = {companyId: companyId, data: object}
 * @return {{payload, type: string}}
 */

export function setMapThisFunctionDataForCurrentCompany(payload) {
	return {type: SET_MAP_THIS_FUNCTION_FOR_CURRENT_COMPANY, payload};
}

// Map this function

/**
 *
 * @param payload = {companyId: companyId, completed: boolean}
 * @return {{payload, type: string}}
 */

export function setMapThisFunctionIsInProgressForCurrentCompany(payload) {
	return {type: SET_MAP_THIS_FUNCTION_IS_IN_PROGRESS_FOR_CURRENT_COMPANY, payload};
}

/**
 *
 * @param payload = companyId
 * @return {{payload, type: string}}
 */

export function clearMapThisFunctionDataForCurrentCompany(payload) {
	return {type: CLEAR_MAP_THIS_FUNCTION_FOR_CURRENT_COMPANY, payload};
}

/**
 *
 * @param payload = tooltipCompanies
 * @return {{payload, type: string}}
 */

export function updateTooltipCompanies(payload) {
	return {type: UPDATE_TOOLTIP_COMPANIES, payload};
}

export function updateTooltipCompaniesProgress(payload) {
	return {type: UPDATE_TOOLTIP_COMPANIES_PROGRESS, payload};
}

export function updateTooltipCompaniesFailed(payload) {
	return {type: UPDATE_TOOLTIP_COMPANIES_FAILED, payload};
}

export function setOrgChartKeywordsFiltersDropdownData(payload) {
	return {type: SET_ORGCHART_KEYWORDS_FILTERS_DROPDOWN_DATA, payload};
}
