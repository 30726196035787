import {
	GET_LICENSE_TYPES_FAIL,
	GET_LICENSE_TYPES_INIT,
	GET_USER_LOCATION_DONE,
	GET_USER_LOCATION_FAIL,
	GET_USER_LOCATION_INIT,
	LOAD_PROFILE_DONE,
	LOAD_PROFILE_FAIL,
	LOAD_PROFILE_INIT,
	REGISTER_CONTACTS_BLOCKED_BY_COUNTRY_DONE,
	REGISTER_CONTACTS_BLOCKED_BY_COUNTRY_FAIL,
	REGISTER_CONTACTS_BLOCKED_BY_COUNTRY_INIT,
	SEND_VERIFICATION_EMAIL_DONE,
	SEND_VERIFICATION_EMAIL_FAIL,
	SEND_VERIFICATION_EMAIL_INIT,
	VERIFY_VERIFICATION_FAIL,
} from "store/mapx/mapXActionTypes";
import {VERIFY_VERIFICATION_DONE} from "store/mapx/mapXActionTypes";
import {VERIFY_VERIFICATION_INIT} from "store/mapx/mapXActionTypes";
import {
	GET_USER_NAME_FAILED,
	GET_USER_NAME_FETCH,
	GET_USER_NAME_SUCCESS,
	LOGIN_FAILED,
	LOGIN_FETCH,
	LOGOUT,
	LOGIN_SUCCESS,
	TOGGLE_TUTORIAL_MODAL_OPEN,
	CHANGE_PASSWORD_FETCH,
	CHANGE_PASSWORD_FAILED,
	UPDATE_USER_CREDIT_INFORMATION,
	DELETE_USER_WITH_ID_INIT,
	DELETE_USER_WITH_ID_DONE,
	DELETE_USER_WITH_ID_FAILED,
	USERS_TRANSFER_INIT,
	USERS_TRANSFER_DONE,
	USERS_TRANSFER_FAILED,
	GET_LICENSE_TYPES_DONE,
	VERIFY_EMAIL_INIT,
	VERIFY_EMAIL_DONE,
	VERIFY_EMAIL_FAIL,
	USERS_UPDATE_INIT,
	USERS_UPDATE_DONE,
	USERS_UPDATE_FAIL,
} from "store/mapx/mapXActionTypes";

export function loginFetch() {
	return {type: LOGIN_FETCH};
}

export function loginSuccess(user) {
	return {type: LOGIN_SUCCESS, payload: user};
}

export function loginFailed(error) {
	return {type: LOGIN_FAILED, payload: error};
}

export function logout() {
	return {type: LOGOUT};
}

export function loadProfileInit() {
	return {type: LOAD_PROFILE_INIT};
}

export function loadProfileDone(user) {
	return {type: LOAD_PROFILE_DONE, payload: user};
}

export function loadProfileFail(error) {
	return {type: LOAD_PROFILE_FAIL, payload: error};
}

export function getUserNameFetch() {
	return {type: GET_USER_NAME_FETCH};
}

export function getUserNameSuccess(email) {
	return {type: GET_USER_NAME_SUCCESS, payload: email};
}

export function getUserNameFailed(error) {
	return {type: GET_USER_NAME_FAILED, payload: error};
}

export function getLicenseTypesInit() {
	return {type: GET_LICENSE_TYPES_INIT};
}

export function getLicenseTypeDone(payload) {
	return {type: GET_LICENSE_TYPES_DONE, payload};
}

export function getLicenseTypesFailed(error) {
	return {type: GET_LICENSE_TYPES_FAIL, payload: error};
}

export function changePasswordFetch(result) {
	return {type: CHANGE_PASSWORD_FETCH, payload: result};
}

export function changePasswordFailed(error) {
	return {type: CHANGE_PASSWORD_FAILED, payload: error};
}

//  payload contains {credit: xxx, credits_refresh_at: xxx}

export function setUserCreditInformation(payload) {
	return {type: UPDATE_USER_CREDIT_INFORMATION, payload};
}

export const updateToggleModalHandler = () => {
	return {type: TOGGLE_TUTORIAL_MODAL_OPEN};
};

export const deleteUserWithIdInit = () => {
	return {type: DELETE_USER_WITH_ID_INIT};
};

export const deleteUserWithIdDone = () => {
	return {type: DELETE_USER_WITH_ID_DONE};
};

export const deleteUserWithIdFailed = () => {
	return {type: DELETE_USER_WITH_ID_FAILED};
};

export const usersUpdateInit = (userID) => {
	return {type: USERS_UPDATE_INIT, userID};
};

export const usersUpdateDone = (data, userID) => {
	return {type: USERS_UPDATE_DONE, data, userID};
};

export const usersUpdateFailed = (error, userID) => {
	return {type: USERS_UPDATE_FAIL, error, userID};
};

export const usersTransferInit = () => {
	return {type: USERS_TRANSFER_INIT};
};

export const usersTransferDone = (data) => {
	return {type: USERS_TRANSFER_DONE, data};
};

export const usersTransferFailed = () => {
	return {type: USERS_TRANSFER_FAILED};
};

export const verifyEmailInit = () => {
	return {type: VERIFY_EMAIL_INIT};
};

export const verifyEmailDone = () => {
	return {type: VERIFY_EMAIL_DONE};
};

export const verifyEmailFail = () => {
	return {type: VERIFY_EMAIL_FAIL};
};

export function verifyVerificationInit(verificationCode) {
	return {type: VERIFY_VERIFICATION_INIT, payload: verificationCode};
}

export function verifyVerificationDone(verificationCode) {
	return {type: VERIFY_VERIFICATION_DONE, payload: verificationCode};
}

export function verifyVerificationFail(error) {
	return {type: VERIFY_VERIFICATION_FAIL, payload: error};
}

export function sendVerificationEmailInit(email) {
	return {type: SEND_VERIFICATION_EMAIL_INIT, payload: email};
}

export function sendVerificationEmailDone(email) {
	return {type: SEND_VERIFICATION_EMAIL_DONE, payload: email};
}

export function sendVerificationEmailFail(error) {
	return {type: SEND_VERIFICATION_EMAIL_FAIL, payload: error};
}

export function registerContactsBlockedByCountryInit() {
	return {type: REGISTER_CONTACTS_BLOCKED_BY_COUNTRY_INIT};
}

export function registerContactsBlockedByCountryDone() {
	return {type: REGISTER_CONTACTS_BLOCKED_BY_COUNTRY_DONE};
}

export function registerContactsBlockedByCountryFail(error) {
	return {type: REGISTER_CONTACTS_BLOCKED_BY_COUNTRY_FAIL, payload: error};
}

export function getUserLocationInit() {
	return {type: GET_USER_LOCATION_INIT};
}

export function getUserLocationDone(location) {
	return {type: GET_USER_LOCATION_DONE, payload: location};
}

export function getUserLocationFail(error) {
	return {type: GET_USER_LOCATION_FAIL, payload: error};
}
