import React, {type ChangeEvent, useCallback, useMemo, useState} from "react";
import {useAppDispatch, useAppSelector, useModalHook} from "hooks";
import colors from "styles/themes.module.scss";
import {
	displayMarketReportModal,
	setMarketReportNameInForm,
} from "store/mapx/market-report/marketReportActions";
import {ModalContent, ModalHeader, ModalSeparator, TextInput} from "components";
import ModalFormHeaderInformation from "mapx-components/Modals/ModalFormHeaderInformation";
import MarketReportIcon from "mapx-pages/MarketReports/Icons/MarketReportIcon";
import styles from "./styles.module.scss";
import FooterButtons from "mapx-components/Modals/FooterButtons";
import {debounce} from "lodash";
import {TUpdateMarketReportForm} from "api/marketReportApi/types";
import {
	displayMarketReportModalSelector,
	getMarketReportFormSelector,
} from "store/mapx/market-report/marketReportSelector";
import {useHistory, useLocation} from "react-router-dom";

const MarketReportModal = () => {
	const dispatch = useAppDispatch();

	const history = useHistory();

	const location = useLocation();

	const displayMarketMapModal = useAppSelector(displayMarketReportModalSelector);

	const marketReportForm: TUpdateMarketReportForm = useAppSelector(getMarketReportFormSelector);

	const {modalIsOpen, customStyles, Modal} = useModalHook(
		{
			content: {
				width: "588px",
				borderRadius: "4px",
				// maxWidth: "1020px"
			},
		},
		displayMarketMapModal,
		colors.mainThemeColor,
	);

	const [name, setName] = useState(() => {
		if (
			location.pathname.includes("edit") ||
			(location.pathname.includes("create") && marketReportForm.name)
		) {
			return marketReportForm.name;
		}

		return "";
	});

	const handleModalHide = useCallback(() => {
		dispatch(displayMarketReportModal(false));
	}, [dispatch]);

	const saveMarketReportName = useMemo(
		() =>
			debounce((name: string) => {
				dispatch(setMarketReportNameInForm(name));
			}, 800),
		[dispatch],
	);

	const handleNameChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			setName(e.target.value);
			saveMarketReportName(e.target.value);
		},
		[saveMarketReportName],
	);

	const handleSubmit = useCallback(() => {
		if (marketReportForm?.id === undefined) {
			history.push("/market-reports/create");
		}
		dispatch(displayMarketReportModal(false));
	}, [dispatch, history, marketReportForm?.id]);

	const isActionButtonEnabled = useMemo(() => {
		return marketReportForm?.name.trim().length >= 2;
	}, [marketReportForm?.name]);

	return (
		<Modal
			title={
				marketReportForm?.id !== undefined ? "Edit Market Review" : "Create a Market Review"
			}
			style={customStyles}
			isOpen={modalIsOpen}
			contentLabel="Modal"
			// onRequestClose={handleModalHide}
		>
			<ModalHeader
				title={
					marketReportForm?.id !== undefined
						? "Edit Market Review"
						: "Create a Market Review"
				}
				onClose={handleModalHide}
			/>

			<ModalSeparator />

			<ModalContent padding={48}>
				<div className={styles.modalContent}>
					<ModalFormHeaderInformation
						title="Market Review"
						description={
							"Visualise a talent pool based on specific hiring requirements to see how common or rare certain requirements are"
						}
						icon={<MarketReportIcon />}
					/>

					<div className={styles.input}>
						<TextInput
							label="Review Name*"
							onChange={handleNameChange}
							type="text"
							value={name}
							placeholder={"Enter a review name"}
						/>
					</div>
				</div>
			</ModalContent>

			<FooterButtons
				actionButtonLabel={"Continue"}
				handleActionButtonClick={handleSubmit}
				isLoading={false}
				isActionButtonDisabled={!isActionButtonEnabled}
			/>
		</Modal>
	);
};

export default MarketReportModal;
