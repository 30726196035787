import styles from "../CoreRoleRequirements.module.scss";
import {TRequirementCardProps} from "../../types";
import classNames from "classnames";
import {useEffect, useState} from "react";

const RequirementsCard = ({
	title,
	subtitle,
	icon,
	count,
	values,
	expanded = false,
}: TRequirementCardProps) => {
	const [displayAllValue, setDisplayAllValue] = useState(false);

	useEffect(() => setDisplayAllValue(expanded), [expanded]);

	return (
		<div className={styles.requirementSection}>
			{icon}
			<div className={styles.contentBlock}>
				<span className={styles.title}>
					{title} {count > 0 && <span className={styles.count}>{count}</span>}
				</span>
				{count === 0 && (
					<span className={classNames(styles.subtitle, styles.default)}>{subtitle}</span>
				)}
				{count > 0 && (
					<div
						className={styles.subtitle}
						onClick={() => setDisplayAllValue(!displayAllValue)}
					>
						{displayAllValue
							? values.map((item, index) => (
									<span key={index}>
										{item}
										{index !== values.length - 1 && ", "}
									</span>
							  ))
							: `${values[0]}${count > 1 ? ", +" + (count - 1) : ""}`}
					</div>
				)}
			</div>
		</div>
	);
};

export default RequirementsCard;
