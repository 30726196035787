import React from "react";

const MarketReportIcon = () => {
	return (
		<svg
			width="64"
			height="64"
			viewBox="0 0 64 64"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect width="64" height="64" rx="8" fill="#EDF2F2" />
			<mask
				id="mask0_3096_13905"
				style={{maskType: "alpha"}}
				maskUnits="userSpaceOnUse"
				x="8"
				y="8"
				width="48"
				height="48"
			>
				<rect x="8" y="8" width="48" height="48" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_3096_13905)">
				<path
					d="M18 50C16.9 50 15.9583 49.6083 15.175 48.825C14.3917 48.0417 14 47.1 14 46V18C14 16.9 14.3917 15.9583 15.175 15.175C15.9583 14.3917 16.9 14 18 14H26.4C26.8333 12.8 27.5583 11.8333 28.575 11.1C29.5917 10.3667 30.7333 10 32 10C33.2667 10 34.4083 10.3667 35.425 11.1C36.4417 11.8333 37.1667 12.8 37.6 14H46C47.1 14 48.0417 14.3917 48.825 15.175C49.6083 15.9583 50 16.9 50 18V46C50 47.1 49.6083 48.0417 48.825 48.825C48.0417 49.6083 47.1 50 46 50H18ZM18 46H46V18H18V46ZM22 42H36V38H22V42ZM22 34H42V30H22V34ZM22 26H42V22H22V26ZM32 16.5C32.4333 16.5 32.7917 16.3583 33.075 16.075C33.3583 15.7917 33.5 15.4333 33.5 15C33.5 14.5667 33.3583 14.2083 33.075 13.925C32.7917 13.6417 32.4333 13.5 32 13.5C31.5667 13.5 31.2083 13.6417 30.925 13.925C30.6417 14.2083 30.5 14.5667 30.5 15C30.5 15.4333 30.6417 15.7917 30.925 16.075C31.2083 16.3583 31.5667 16.5 32 16.5Z"
					fill="#0C5850"
				/>
			</g>
		</svg>
	);
};

export default MarketReportIcon;
