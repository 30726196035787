import type {STProduct} from "api/paymentApi/types";

import {
	CONTACT_ADMIN_MODAL,
	GET_PRODUCTS_DONE,
	GET_PRODUCTS_FAIL,
	GET_PRODUCTS_INIT,
	OPEN_CHECKOUT_DONE,
	OPEN_CHECKOUT_FAIL,
	OPEN_CHECKOUT_INIT,
	OPEN_CUSTOMER_PORTAL_DONE,
	OPEN_CUSTOMER_PORTAL_FAIL,
	OPEN_CUSTOMER_PORTAL_INIT,
	PRICING_TABLE_MODAL,
	UPDATE_PLAN_MODAL,
} from "store/mapx/mapXActionTypes";

export function pricingTableModal(displayModal: boolean) {
	return {type: PRICING_TABLE_MODAL, displayModal};
}

export function updatePlanModal(displayModal: boolean) {
	return {type: UPDATE_PLAN_MODAL, displayModal};
}

export function contactAdminModal(displayModal: boolean) {
	return {type: CONTACT_ADMIN_MODAL, displayModal};
}

export function getProductsInit() {
	return {type: GET_PRODUCTS_INIT};
}

export function getProductsDone(products: STProduct) {
	return {type: GET_PRODUCTS_DONE, products};
}

export function getProductsFail(error: unknown) {
	return {type: GET_PRODUCTS_FAIL, error};
}

export function openCheckoutInit(priceID: string) {
	return {type: OPEN_CHECKOUT_INIT, priceID};
}

export function openCheckoutDone() {
	return {type: OPEN_CHECKOUT_DONE};
}

export function openCheckoutFail(error: unknown) {
	return {type: OPEN_CHECKOUT_FAIL, error};
}

export function openCustomerPortalInit() {
	return {type: OPEN_CUSTOMER_PORTAL_INIT};
}

export function openCustomerPortalDone() {
	return {type: OPEN_CUSTOMER_PORTAL_DONE};
}

export function openCustomerPortalFail(error: unknown) {
	return {type: OPEN_CUSTOMER_PORTAL_FAIL, error};
}
