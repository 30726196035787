import React from "react";
import ContentLoader from "react-content-loader";

const MarketReportCriteriaBreakdownSkeletonLoader = () => {
	return (
		<div data-tooltip={"Loading criteria details..."} style={{background: "#fff"}}>
			<ContentLoader
				speed={2}
				height={420}
				width={"100%"}
				backgroundColor="#DCE7E7"
				foregroundColor="#F9F9F9"
			>
				<rect x="24" y="24" rx="3" ry="3" width="340" height="64" />
				<rect x="51" y="96" rx="3" ry="3" width="286" height="64" />
				<rect x="110" y="168" rx="3" ry="3" width="168" height="64" />
				<rect x="152" y="240" rx="3" ry="3" width="84" height="64" />

				<rect x="380" y="34" rx="3" ry="3" width="72" height="24" />
				<rect x="380" y="62" rx="3" ry="3" width="72" height="16" />
				<rect x="468" y="37" rx="3" ry="3" width="187" height="20" />
				<rect x="468" y="65" rx="3" ry="3" width="620" height="10" />

				<rect x="380" y="106" rx="3" ry="3" width="72" height="24" />
				<rect x="380" y="134" rx="3" ry="3" width="72" height="16" />
				<rect x="468" y="109" rx="3" ry="3" width="187" height="20" />
				<rect x="468" y="137" rx="3" ry="3" width="620" height="10" />
				<rect x="1112" y="118" rx="10" ry="7" width="20" height="20" />
				<rect x="1148" y="118" rx="3" ry="3" width="20" height="20" />

				<rect x="380" y="178" rx="3" ry="3" width="72" height="24" />
				<rect x="380" y="206" rx="3" ry="3" width="72" height="16" />
				<rect x="468" y="181" rx="3" ry="3" width="187" height="20" />
				<rect x="468" y="209" rx="3" ry="3" width="620" height="10" />
				<rect x="1112" y="190" rx="10" ry="7" width="20" height="20" />
				<rect x="1148" y="190" rx="3" ry="3" width="20" height="20" />

				<rect x="380" y="250" rx="3" ry="3" width="72" height="24" />
				<rect x="380" y="278" rx="3" ry="3" width="72" height="16" />
				<rect x="468" y="253" rx="3" ry="3" width="187" height="20" />
				<rect x="468" y="281" rx="3" ry="3" width="620" height="10" />
				<rect x="1112" y="262" rx="10" ry="7" width="20" height="20" />
				<rect x="1148" y="262" rx="3" ry="3" width="20" height="20" />

				<rect x="24" y="360" rx="3" ry="3" width="271" height="28" />
				<rect x="310" y="360" rx="3" ry="3" width="60" height="28" />
			</ContentLoader>
		</div>
	);
};

export default MarketReportCriteriaBreakdownSkeletonLoader;
