import {useAppDispatch, useAppSelector} from "hooks";
import React, {useEffect, useState} from "react";
import styles from "./marketReports.module.scss";
import {Button} from "components";
import {CreatePlusIcon} from "assets/icons";
import homeStyles from "../Home/HomeStyles.module.scss";
import MarketReportCard from "mapx-components/Cards/MarketReportCard";
import {MainWrapper} from "mapx-components";
import MarketReportSkeletonLoader from "./Loader";
import MarketReportModal from "mapx-pages/MarketReports/Modal/CreateMarketReportModal";
import {
	displayMarketReportModal,
	resetMarketReportForm,
	setMarketReportPaginationInfo,
} from "store/mapx/market-report/marketReportActions";
import {
	getMarketReportsPaginationSelector,
	getMarketReportsSelector,
	loadingMarketReportSelector,
} from "store/mapx/market-report/marketReportSelector";
import {IMarketReport} from "api/marketReportApi/types";
import {cancelRequest} from "api/cancelTokens";
import {GET_MARKET_REPORTS_TOKEN} from "api/requestCancelTokenStrings";
import {getMarketReports} from "store/mapx/market-report/marketReportAsyncActions";
import MarketMapSearch from "./Filters/search";
import Pagination from "mapx-components/Pagination";
import useSearchDebounce from "hooks/useSearchDebounce";
import {globalPerPageSelector} from "store/mapx/userPreference/userPreferenceSelectors";
import {setPerPagePreference} from "store/mapx/userPreference/userPreferenceActions";

const MarketReports = () => {
	const dispatch = useAppDispatch();

	const marketReportsFetchInProgress = useAppSelector(loadingMarketReportSelector);

	const marketReports = useAppSelector(getMarketReportsSelector);

	const pagination = useAppSelector(getMarketReportsPaginationSelector);

	const globalPerPageValue = useAppSelector(globalPerPageSelector);

	const [currentSearch, setCurrentSearch] = useState("");

	const [searchTerm, setSearchTerm] = useSearchDebounce(800);

	const LoaderComponent = <MarketReportSkeletonLoader count={10} />;

	const handleCreateMarketReportClick = () => {
		dispatch(displayMarketReportModal(true));
		dispatch(resetMarketReportForm());
	};

	const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(e.target.value);
		setCurrentSearch(e.target.value);
		dispatch(setMarketReportPaginationInfo({...pagination, page: 1}));
	};

	useEffect(() => {
		dispatch(getMarketReports(searchTerm));

		return () => {
			cancelRequest(GET_MARKET_REPORTS_TOKEN);
		};
	}, [dispatch, searchTerm]);

	useEffect(() => {
		return () => {
			dispatch(setMarketReportPaginationInfo({page: 1, per_page: globalPerPageValue}));
		};
	}, [dispatch, globalPerPageValue]);

	const onPageChange = async (newPage: number) => {
		dispatch(setMarketReportPaginationInfo({...pagination, page: newPage}));

		await dispatch(
			getMarketReports(currentSearch, {page: newPage, per_page: globalPerPageValue}),
		);
	};

	const onPerPageChange = async (count: number) => {
		dispatch(setPerPagePreference(count));

		dispatch(setMarketReportPaginationInfo({...pagination, per_page: count}));

		await dispatch(getMarketReports(currentSearch, {page: pagination.page, per_page: count}));
	};

	return (
		<MainWrapper>
			<div className={styles.container}>
				<div className={styles.headerSection}>
					<div className={styles.title}> Market Review Reports</div>

					<div className={styles.headerSection}>
						<MarketMapSearch
							onChange={handleSearchInput}
							loading={marketReportsFetchInProgress}
							value={currentSearch}
						/>

						<Button
							data-testid="addNewMarketReportButtonOnMarketReportsList"
							LeftIcon={CreatePlusIcon}
							onClick={handleCreateMarketReportClick}
							className={homeStyles.content__bottom__info__buttons_create}
							leftIconClasses={homeStyles.content__bottom__info__buttons_create_icon}
						>
							Create a Market Review
						</Button>
					</div>
				</div>

				{marketReportsFetchInProgress && LoaderComponent}

				{!marketReportsFetchInProgress &&
					marketReports?.length > 0 &&
					marketReports.map((item: IMarketReport, index: number) => (
						<MarketReportCard
							key={`${index}${item.id}${item.name}`}
							id={item.id}
							name={item.name}
							market_size={item.total_market_size}
							profile_matched={item.candidate_matches}
							created_date={item.created_at}
							updated_date={item.last_modified_at}
							filters={item.filters}
						/>
					))}

				{!marketReportsFetchInProgress && pagination && pagination?.count >= 10 && (
					<div className={styles.paginationWrapper}>
						<Pagination
							currentPage={pagination?.page}
							resultsCount={pagination?.count}
							totalPages={pagination?.pages}
							perPage={pagination?.per_page}
							onPageChange={onPageChange}
							onPerPageChange={onPerPageChange}
						/>
					</div>
				)}

				{!pagination?.count && searchTerm !== "" && (
					<div className={styles.noQueryMatch}>
						<h4>No market review report was found with your search query</h4>
						<p>Try with something different</p>
					</div>
				)}

				{!pagination?.count && searchTerm === "" && (
					<div className={styles.noQueryMatch} onClick={handleCreateMarketReportClick}>
						<h4>{"You don't have any market reviews yet!"}</h4>
						<p>
							Click on the Create a Market Review button to get started with a Market
							Review
						</p>
					</div>
				)}

				<MarketReportModal />
			</div>
		</MainWrapper>
	);
};

export default MarketReports;
