import {Dispatch} from "react";
import {TAppDispatch, TRootState} from "types";
import mapXCandidateApi from "api/candidateApi";
import {SICandidate, TGenerateContactDetailsApiPayload} from "api/candidateApi/types";
import {successResponse} from "helpers/map";
import {setGenerateContactDetailsCandidateIdsForAllProject} from "store/mapx/project/projectActions";
import {ICandidateSearchApiRequestPayload} from "api/candidateApi/form";
import axios from "axios";
import {generateCandidatesContactDetailsFlattenedForAllProjectSelector} from "./generateCandidateContactDetailsSelectors";
import {targetListCandidatesSelector} from "../target-list/targetListSelectors";
import {
	updateFilteredTargetListCandidatesWithContactDetails,
	updateTargetListCandidatesWithContactDetails,
} from "../target-list/targetListActions";
import {updateCandidateProfileAfterRefresh} from "../refresh-candidate/refreshCandidateActions";
import {userEmailSelector} from "store/mapx/user/userSelectors";
import {Mixpanel} from "helpers/mixpanel";
import {projectSavedPeopleSelector} from "./projectSelectors";
import {getPaginatedProjectSavedPeople} from "./projectCandidatesAsyncActions";

/* eslint-disable @typescript-eslint/no-explicit-any */
const cancelTokens = {} as any;

/**
 * Generates contact details for a list of candidates.
 *
 * @param candidateIds - The list of candidate IDs for which contact details should be generated.
 */
export const generateCandidatesContactDetails =
	(candidateIds: number[]) => async (dispatch: Dispatch<TAppDispatch>, getState: TRootState) => {
		try {
			const state = getState();

			// Get existing candidate IDs that require contact details generation
			const allIds = generateCandidatesContactDetailsFlattenedForAllProjectSelector(state);

			let ids = [];

			if (allIds && Array.isArray(allIds)) {
				ids = allIds;
			}
			// Combine existing candidate IDs with the new ones
			ids = [...candidateIds, ...ids];

			dispatch(setGenerateContactDetailsCandidateIdsForAllProject(ids));

			// Prepare API request payload
			const apiPayload: TGenerateContactDetailsApiPayload = {
				candidate_ids: ids,
			};

			// Trigger API call to generate contact details
			await mapXCandidateApi.generateContactDetails(apiPayload);

			// Track event using Mixpanel
			const userEmail = userEmailSelector(state);
			Mixpanel.track(`Candidate Contact Generated`, {
				name: `${window.name}`,
				pageTitle: `${window.name}`,
				url: window.location.pathname,
				distinct_id: userEmail,
			});
		} catch (e) {
			console.error("Error in generateCandidatesContactDetails:", e);
		}
	};

/**
 * Checks whether contact details have been generated for a list of candidates.
 *
 * @param candidateIds - The list of candidate IDs to check.
 */
export const checkCandidateContactDetailsGeneratedStatus =
	(candidateIds: number[]) => async (dispatch: Dispatch<TAppDispatch>, getState: TRootState) => {
		const requestKey = `GET_GENERATE_CONTACT_DETAILS_CANDIDATE_STATUS`;

		if (requestKey in cancelTokens) {
			cancelTokens[requestKey].cancel("Operation canceled due to new request.");
		}

		cancelTokens[requestKey] = axios.CancelToken.source();

		const config = {
			cancelToken: cancelTokens[requestKey].token,
		};

		const state = getState();

		try {
			// Define API payload to fetch candidates with the provided IDs
			const apiPayload: ICandidateSearchApiRequestPayload = {
				pagination: {
					page: 1,
					per_page: 40,
				},
				filters: {
					candidates: candidateIds,
				},
			};

			// Fetch candidate details through API call
			const response = await mapXCandidateApi.getCandidatesByFilter(apiPayload, config);

			if (successResponse(response, 200)) {
				const results = response.data.results;

				const savedPeoplesInState = targetListCandidatesSelector(state);
				const projectSavedPeopleInState = projectSavedPeopleSelector(state);

				let updatedIds = [...candidateIds];

				const updatedPeopleForState: SICandidate[] = [];
				const updatedSavedPeopleForState: SICandidate[] = [];

				// Update states for target list candidates
				savedPeoplesInState.forEach((candidate: SICandidate) => {
					const candidateFromResults = results.find(
						(c: SICandidate) => c.id === candidate.id,
					);

					if (candidateFromResults) {
						// Retain summary from existing state
						candidateFromResults.summary = candidate.summary;
					}

					const updatedCandidate = candidateFromResults ?? candidate;

					// If contact details are available, update Redux state and remove from pending list
					if (updatedCandidate.contact_details !== null) {
						dispatch(updateCandidateProfileAfterRefresh(updatedCandidate));
						updatedIds = updatedIds.filter((id) => id !== candidate.id);
					}

					updatedPeopleForState.push(updatedCandidate);

					// Ensure project saved people are also updated
					if (projectSavedPeopleInState.some((c: SICandidate) => c.id === candidate.id)) {
						updatedSavedPeopleForState.push(updatedCandidate);
					}
				});

				// Update target list candidate state with new contact details
				dispatch(updateTargetListCandidatesWithContactDetails(updatedPeopleForState));

				// Update project saved people list with new contact details
				dispatch(
					updateFilteredTargetListCandidatesWithContactDetails(
						updatedSavedPeopleForState,
					),
				);

				// Update all candidates list
				results.forEach((candidate: SICandidate) => {
					if (candidate.contact_details !== null) {
						const candidateInfoFromTL = savedPeoplesInState.find(
							(tc: {id: number}) => tc.id === candidate.id,
						);

						// we should preserve existing summary data for candidate,as summary is fetched from BE distinctly on demand, hence, is null on the current fetch
						if (candidateInfoFromTL?.summary) {
							candidate.summary = candidateInfoFromTL?.summary;
						}
						dispatch(updateCandidateProfileAfterRefresh(candidate));
						updatedIds = updatedIds.filter((id) => id !== candidate.id);
					}
				});

				// Update Redux state with remaining candidate IDs still pending contact details
				dispatch(setGenerateContactDetailsCandidateIdsForAllProject([...updatedIds]));

				dispatch(updateTargetListCandidatesWithContactDetails(updatedPeopleForState));

				if (updatedIds.length === 0) {
					dispatch(getPaginatedProjectSavedPeople());
				}
			}
		} catch (e) {
			console.error("Error in checkCandidateContactDetailsGeneratedStatus:", e);
		}
	};
