import styles from "mapx-pages/Project/Overview/IndustriesReport/industriesReport.module.scss";
import {useEffect, useMemo, useState} from "react";
import useDebouncedEffect from "use-debounced-effect";
import {IndustryCard} from "mapx-components";
import {useAppDispatch, useAppSelector} from "hooks";
import {getIndustryReportData} from "store/mapx/project/projectAsyncActions";
import {tooltipCompaniesSelector} from "store/mapx/company/companySelectors";
import {projectSelector} from "store/mapx/project-list/projectListSelectors";
import {updateTooltipCompaniesList} from "store/mapx/company/companyAsyncActions";

import {STIndustryData} from "api/filterOptionsApi/IndustryApi/types";
import {
	industryReportLoadingSelector,
	industryReportPaginationSelector,
	industryReportSelector,
} from "store/mapx/project/projectSelectors";
import {STProject} from "api/projectApi/types";
import {cancelRequest} from "api/cancelTokens";
import {PROJECT_INDUSTRIES_REPORT_TOKEN} from "api/requestCancelTokenStrings";
import Pagination from "mapx-components/Pagination";
import IndustrieskeletonLoader from "./IndustriesReportSkeletonLoader";
import {globalPerPageSelector} from "store/mapx/userPreference/userPreferenceSelectors";
import {setPerPagePreference} from "store/mapx/userPreference/userPreferenceActions";

const IndustriesReport = () => {
	const dispatch = useAppDispatch();

	const project: STProject = useAppSelector(projectSelector);
	const industryReportData = useAppSelector(industryReportSelector);
	const industryReportFetchInProgress = useAppSelector(industryReportLoadingSelector);

	const [companyId, setCompanyId] = useState<string>("");
	const [removeMouse, setRemoveMouse] = useState<boolean>(false);

	const tooltipCompanies = useAppSelector(tooltipCompaniesSelector);
	const companyToWatch = companyId ? tooltipCompanies[String(companyId)] : null;

	const pagination = useAppSelector(industryReportPaginationSelector);

	const globalPerPageValue = useAppSelector(globalPerPageSelector);

	const loading = useMemo(
		() => industryReportFetchInProgress || !industryReportData?.length,
		[industryReportFetchInProgress, industryReportData],
	);

	useEffect(() => {
		if (project?.id) {
			dispatch(getIndustryReportData(project.id));
		}

		return () => {
			cancelRequest(PROJECT_INDUSTRIES_REPORT_TOKEN);
		};
	}, [dispatch, project?.id]);

	useDebouncedEffect(
		() => {
			if (companyId && removeMouse) {
				dispatch(updateTooltipCompaniesList(companyId));
			}
		},
		1000,
		[companyId, removeMouse],
	);

	const otherData = useMemo(() => {
		const otherData: STIndustryData[] = [];

		if (!loading) {
			industryReportData
				?.filter((industry: STIndustryData) => industry.name === null)
				.forEach((item: STIndustryData) => {
					otherData.push(item);
				});
		}

		return otherData;
	}, [industryReportData, loading]);

	function onPageChange(page: number) {
		dispatch(
			getIndustryReportData(project.id, {
				page: page,
				per_page: globalPerPageValue,
			}),
		);
	}

	const onPerPageChange = async (count: number) => {
		dispatch(setPerPagePreference(count));

		dispatch(
			getIndustryReportData(project.id, {
				page: pagination.page,
				per_page: count,
			}),
		);
	};

	return (
		<div>
			{loading && <IndustrieskeletonLoader columns={2} rows={2} />}

			{!loading && (
				<div
					className={styles.industryReportContainer}
					data-testid="industries-report-data"
				>
					{industryReportData?.map((industry: STIndustryData, index: number) => (
						<IndustryCard
							index={index}
							industry={industry}
							name={industry?.name}
							setCompanyId={setCompanyId}
							currentCompanyId={companyId}
							setRemoveMouse={setRemoveMouse}
							key={`${industry?.id}-${index}`}
							companyToWatch={companyToWatch}
						/>
					))}

					{otherData?.map((industry, index) => (
						<IndustryCard
							name="Other"
							index={index}
							industry={industry}
							setCompanyId={setCompanyId}
							currentCompanyId={companyId}
							setRemoveMouse={setRemoveMouse}
							key={`${industry?.id}-${index}`}
							companyToWatch={companyToWatch}
						/>
					))}
				</div>
			)}

			{!loading && pagination && pagination?.count >= 10 && (
				<div className={styles.paginationWrapper}>
					<Pagination
						currentPage={pagination?.page}
						resultsCount={pagination?.count}
						totalPages={pagination?.pages}
						perPage={globalPerPageValue}
						onPageChange={onPageChange}
						onPerPageChange={onPerPageChange}
						scrollDelayAfterPageChange={400}
					/>
				</div>
			)}
		</div>
	);
};

export default IndustriesReport;
