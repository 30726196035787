import {useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {SearchInput} from "mapx-components";
import styles from "containers/Filters/CompanyFilters/SpecialityFilter/companySpecialityFilter.module.scss";
import useFetchListPaginateOptions from "hooks/useFetchListPaginateOptions";
import {setSpecialtyClusterSearchQuery} from "store/mapx/search/searchActions";
import {removeDuplicateObjectFromArray, removeDuplicatesFromArray} from "helpers/filterHandlers";
import {Checkbox, CheckboxSkeletonLoader, Loader, ResetFilters, TabSelect} from "components";
import {setCompanySpecialitiesSelectedConnectivityLogic} from "store/mapx/filter/filterActions";
import Tags from "./Tags";
import {
	companySpecialitiesConnectivityLogicSelector,
	filterConnectivityLogicOptionsSelector,
} from "store/mapx/filter/filterSelectors";
import {useAppDispatch} from "hooks";
import CheckboxList from "mapx-components/Inputs/CheckboxList";
import ExpandableArrow from "mapx-components/ExpandableArrow";
import {TGetExpandableDataResult} from "types";
import {getName} from "helpers/string";
import {arrayIntersected, removeInputArrayIdentifiersFromAnotherArray} from "helpers/array";
import useInfiniteScroll from "react-infinite-scroll-hook";
import {STSpecialtyCluster, TCompanySpecialityClusterSearchFilter} from "./types";
import {
	getClusterSpecialities,
	setBulkSpecialityClusterForCompanies,
} from "store/mapx/filter/companySpecialtyClusterFilterAsyncActions";
import {specialtyClusterSearchQuerySelector} from "store/mapx/filter/companySpecialtyCLusterSelectors";
import {
	specialtyClusterOptionsFromCompaniesSelector,
	specialtyClusterOptionsInProgressSelector,
	specialtyClusterOptionsSelector,
	specialtyClusterSearchInProgressSelector,
} from "store/mapx/search/searchSelectors";

const CompanySpecialityClusterSearchFilter = ({
	totalSpecialities,
	handleResetClick,
	handleSpecialitiesChange,
	selectedSpecialityIds,
}: TCompanySpecialityClusterSearchFilter) => {
	const dispatch = useAppDispatch();
	const [filteredData, setFilteredData] = useState<STSpecialtyCluster[]>([]);
	const [updatedFilteredData, setUpdatedFilteredData] = useState<STSpecialtyCluster[]>([]);
	const [selectedSpecialtiesList, setSelectedSpecialtiesList] = useState<STSpecialtyCluster[]>(
		[],
	);
	//to keep track of parent specialty that user has clicked. when value is not null, we re-order the checkbox data, and move the current expanded item to the top
	const [latestExpandedParent, setLatestExpandedParent] = useState<Nullable<number>>(null);
	const [scrollPosition, setScrollPosition] = useState(0);

	//specialties selected from companies, not from checkbox
	const selectedCompaniesSpecialties = useSelector(specialtyClusterOptionsFromCompaniesSelector);
	//and or not option
	const filterConnectivityLogicOptions = useSelector(filterConnectivityLogicOptionsSelector);
	const listRef = useRef<HTMLDivElement>(null);

	const companySpecialitiesConnectivityLogic = useSelector(
		companySpecialitiesConnectivityLogicSelector,
	);

	const specialtyClusterSearchQuery = useSelector(specialtyClusterSearchQuerySelector);

	const specialtyClusterOptions = useSelector(specialtyClusterOptionsSelector);

	const specialtyClusterOptionsInProgress = useSelector(
		specialtyClusterOptionsInProgressSelector,
	);

	const specialtyClusterSearchInProgress = useSelector(specialtyClusterSearchInProgressSelector);

	const {loading, onFilterChanged, data, page, setPage, stopPaginate, searchTerm} =
		useFetchListPaginateOptions({
			options: specialtyClusterOptions,
			setFilteredData,
			initialSearchQuery: specialtyClusterSearchQuery,
			setQueryCallbackOnState: setSpecialtyClusterSearchQuery,
			apiCall: getClusterSpecialities,
		});

	// merge selected specialties from companies with checkbox list
	const prepareSpecialtyClusterArray = useCallback(() => {
		let updatedSpecialtyClusters: STSpecialtyCluster[] = selectedCompaniesSpecialties;

		updatedSpecialtyClusters = [...updatedSpecialtyClusters, ...data];

		return removeDuplicateObjectFromArray(updatedSpecialtyClusters, "id");
	}, [selectedCompaniesSpecialties, data]);

	const [sentryRef] = useInfiniteScroll({
		loading: false,
		hasNextPage: true,
		onLoadMore: async () => {
			setPage(page + 1);
		},
		disabled: filteredData === null || stopPaginate || specialtyClusterOptionsInProgress,
		delayInMs: 300,
	});

	useEffect(() => {
		const updatedSpecialtyClusters = prepareSpecialtyClusterArray();

		const selectedItems = updatedSpecialtyClusters.filter((s) =>
			s.specialties.some((specialty) => selectedSpecialityIds.includes(specialty.id)),
		);

		const totalData = removeDuplicateObjectFromArray([...selectedItems, ...data]);
		const filtered = totalData.filter((f) => f.specialties?.length > 0);

		//update state with clean sets of data
		setFilteredData(removeDuplicateObjectFromArray(filtered, "id"));
	}, [selectedSpecialityIds, data, prepareSpecialtyClusterArray, specialtyClusterOptions]);

	const triggerResetData = () => {
		setLatestExpandedParent(1);
	};

	useEffect(() => {
		triggerResetData();
	}, [page, searchTerm]);

	const [expanded, setExpanded] = useState<TGetExpandableDataResult>({});

	const expand = useCallback(
		(id: number) => {
			// if currently there are no expanded parent, or user have clicked a new parent, set expand state with that id. otherwise, set as null,
			// that means, when user clicked new parent, this should move the previous parent to the top of the list
			setLatestExpandedParent((prev) => {
				if (prev !== id) {
					return id;
				}

				return null;
			});

			//if user has clicked on the currently expanded parent, we don't move it to the top of the list, means, set expanded state to null.
			if (expanded[id]) {
				setLatestExpandedParent(null);
			}

			// toggle expand state for the user clicked id. this indicates the accordion is open or not
			setExpanded({
				...expanded,
				[id]: !expanded[id],
			});
		},
		[expanded],
	);

	const specialityIdsByCluster = useCallback((cluster: STSpecialtyCluster) => {
		return cluster.specialties?.map(({id}) => id);
	}, []);

	const handleBulkUpdate = useCallback(
		(ids: number[]) => {
			dispatch(
				setBulkSpecialityClusterForCompanies(companySpecialitiesConnectivityLogic, ids),
			);
		},
		[dispatch, companySpecialitiesConnectivityLogic],
	);

	const hasSpecialitySelected = useCallback(
		(jobFunction: STSpecialtyCluster, id: Nullable<number> = null) => {
			const idsToCheck = id ? [id, ...selectedSpecialityIds] : selectedSpecialityIds;

			return arrayIntersected(idsToCheck, specialityIdsByCluster(jobFunction));
		},
		[selectedSpecialityIds, specialityIdsByCluster],
	);

	const handleOnChange = useCallback(
		(selected: STSpecialtyCluster) => {
			const ids = specialityIdsByCluster(selected);
			let updatedIds;

			if (hasSpecialitySelected(selected)) {
				updatedIds = removeInputArrayIdentifiersFromAnotherArray(
					ids,
					selectedSpecialityIds,
				);

				setSelectedSpecialtiesList((prevState) => {
					return prevState.filter((item) => item.id !== selected.id);
				});
			} else {
				setSelectedSpecialtiesList((prevState) => {
					return [...new Set([...prevState, selected])];
				});
				updatedIds = removeDuplicatesFromArray([...selectedSpecialityIds, ...ids]);
			}

			// after the specialty parent is clicked, we first set the expanded attribute with parent id to re-order the data, and after a second, remove it.
			//this should move the parent to the top of the list
			setTimeout(() => {
				setLatestExpandedParent(selected.id);
			}, 1000);

			setLatestExpandedParent(null);

			handleBulkUpdate(updatedIds);
		},
		[selectedSpecialityIds, handleBulkUpdate, hasSpecialitySelected, specialityIdsByCluster],
	);

	const hasSpecialityPartiallySelected = useCallback(
		(specialty: STSpecialtyCluster, id: Nullable<number> = null) => {
			if (!hasSpecialitySelected(specialty)) {
				const idsToCheck = id ? [id, ...selectedSpecialityIds] : selectedSpecialityIds;

				return specialty.specialties?.some((s) => idsToCheck.includes(s.id));
			}

			return false;
		},
		[hasSpecialitySelected, selectedSpecialityIds],
	);

	const onSpecialtiesChange = useCallback(
		(id: number, parentItem: STSpecialtyCluster) => {
			handleSpecialitiesChange(id);

			//if user clicks on  currently expanded parent we set the expanded state for previous parent to null. this prevents re-ordering of checkbox data
			if (
				latestExpandedParent &&
				Object.keys(expanded).includes(String(latestExpandedParent))
			) {
				setLatestExpandedParent(null);
			}

			//if parent is partially selected, we add parent id to selected specialties list, otherwise remove it.
			if (hasSpecialityPartiallySelected(parentItem, id)) {
				setSelectedSpecialtiesList((prevState) => {
					return [...new Set([...prevState, parentItem])];
				});
			} else {
				setSelectedSpecialtiesList((prevState) => {
					return prevState.filter((item) => item.id !== parentItem.id);
				});
			}

			//if parent is fully selected, we add parent id to selected specialties list, and after a seconds delay
			// set expanded attribute for parent as null. this should move the parent to the top of the list

			if (hasSpecialitySelected(parentItem, id)) {
				setTimeout(() => {
					setLatestExpandedParent(parentItem.id);
				}, 1000);

				setLatestExpandedParent(null);
			}
		},
		[
			expanded,
			latestExpandedParent,
			handleSpecialitiesChange,
			hasSpecialityPartiallySelected,
			hasSpecialitySelected,
		],
	);

	const reorderedFilteredData = useMemo(() => {
		// if latestExpandedParent is not null, we move the item to the top of the checkbox list
		if (latestExpandedParent) {
			const reOrderedData = filteredData?.slice().sort((a, b) => {
				const aMatches = a.specialties?.some((item) =>
					selectedSpecialityIds.includes(item.id),
				);
				const bMatches = b.specialties?.some((item) =>
					selectedSpecialityIds.includes(item.id),
				);

				if (aMatches && !bMatches) return -1;

				if (!aMatches && bMatches) return 1;

				return 0;
			});

			setUpdatedFilteredData(reOrderedData);

			return reOrderedData;
		}
	}, [filteredData, selectedSpecialityIds, latestExpandedParent]);

	const dataToRender = useMemo(() => {
		return reorderedFilteredData && reorderedFilteredData.length > 0
			? reorderedFilteredData
			: updatedFilteredData && updatedFilteredData.length > 0
			? updatedFilteredData
			: filteredData;
	}, [updatedFilteredData, reorderedFilteredData, filteredData]);

	// this holds the data to display on the checkbox, regardless if the specialties are in the current checkbox list or not after user search
	const filteredDataWithHistory = useMemo(() => {
		const updatedSelectedSpecialtiesList = selectedSpecialtiesList?.map((item) => ({
			...item,
			specialties: removeDuplicateObjectFromArray(item.specialties || [], "id"),
		}));

		return removeDuplicateObjectFromArray(
			[...updatedSelectedSpecialtiesList, ...dataToRender],
			"id",
		);
	}, [dataToRender, selectedSpecialtiesList]);

	const handleScroll = () => {
		if (listRef.current) {
			setScrollPosition(listRef.current.scrollTop);
		}
	};

	useLayoutEffect(() => {
		if (listRef.current) {
			listRef.current.scrollTop = scrollPosition;
		}
	}, [reorderedFilteredData, scrollPosition]);

	const onReset = () => {
		setSelectedSpecialtiesList([]);
		handleResetClick();
	};

	return (
		<div>
			<div className={styles.logicWrapper}>
				<span className={styles.logicLabel}>Logic</span>

				<TabSelect.LabelContainer style={{margin: 0}}>
					<TabSelect
						data-testid={`${
							companySpecialitiesConnectivityLogic || "or"
						}TabSpecialtyCluster`}
						selected={companySpecialitiesConnectivityLogic || "or"}
						onTabChange={(e) =>
							dispatch(setCompanySpecialitiesSelectedConnectivityLogic(e))
						}
						options={filterConnectivityLogicOptions}
					/>
				</TabSelect.LabelContainer>
			</div>
			<SearchInput
				className={styles.searchBox}
				defaultValue={specialtyClusterSearchQuery}
				isLoading={loading}
				onChange={onFilterChanged}
				placeholder="Search for a Specialty"
				type="text"
				errorText={undefined}
				errorClass={undefined}
			/>

			<ResetFilters
				parentStyle={{color: "#5A5A5A", marginRight: 19}}
				onClick={onReset}
				displayIcon={true}
			>
				Clear Selection
			</ResetFilters>

			{specialtyClusterSearchInProgress && <CheckboxSkeletonLoader />}

			<div>
				{!specialtyClusterSearchInProgress && (
					<CheckboxList ref={listRef} onScroll={handleScroll}>
						{filteredDataWithHistory?.map((f, index) => {
							return (
								<CheckboxList.Accordion key={index}>
									<CheckboxList.AccordionHeader>
										<ExpandableArrow
											data-testid={"expandArrowSpecialtyClusterFilter"}
											onClick={() => expand(f.id)}
											rotated={!expanded[f.id]}
										/>

										<Checkbox
											data-testid="upperCheckboxSpecialtyClusterFilter"
											borderColor="#0C5850"
											isChecked={hasSpecialitySelected(f)}
											label={`${getName(f.name)} (${f.specialties?.length})`}
											onChange={() => handleOnChange(f)}
											value={f.id.toString()}
											partiallySelected={hasSpecialityPartiallySelected(f)}
										/>
									</CheckboxList.AccordionHeader>

									<CheckboxList.AccordionContent expanded={expanded[f.id]}>
										{f.specialties?.map((s, i) => (
											<Checkbox
												borderColor="#0C5850"
												isChecked={selectedSpecialityIds.includes(s.id)}
												key={i}
												label={getName(s.name)}
												onChange={() => onSpecialtiesChange(s.id, f)}
												value={getName(s.name)}
											/>
										))}
									</CheckboxList.AccordionContent>
									{!specialtyClusterOptionsInProgress && (
										<div
											ref={sentryRef}
											key={`specialty_cluster_filter${f.id}`}
											style={{width: "100%", height: "0"}}
										/>
									)}
								</CheckboxList.Accordion>
							);
						})}
					</CheckboxList>
				)}

				{filteredDataWithHistory.length < 1 && specialtyClusterOptionsInProgress && (
					<CheckboxSkeletonLoader />
				)}

				{filteredDataWithHistory.length > 0 &&
					specialtyClusterOptionsInProgress &&
					!specialtyClusterSearchInProgress && (
						<div
							data-testid={"loader_3dots_companySpecialtyFilter"}
							style={{textAlign: "center"}}
						>
							<Loader
								width={30}
								height={30}
								type="ThreeDots"
								color="#0c5850"
								displayAtCenterOfPage={false}
							/>
						</div>
					)}
			</div>

			{totalSpecialities > 0 && (
				<div style={{display: "flex", flexDirection: "column", gap: 8, marginTop: 8}}>
					<Tags
						key={"and"}
						options={filteredDataWithHistory.flatMap((f) => f.specialties)}
						tagLabel={"AND"}
						tagValue={"and"}
					/>

					<Tags
						key={"or"}
						options={filteredDataWithHistory.flatMap((f) => f.specialties)}
						tagLabel={"OR"}
						tagValue={"or"}
					/>

					<Tags
						key={"not"}
						options={filteredDataWithHistory.flatMap((f) => f.specialties)}
						tagLabel={"NOT"}
						tagValue={"not"}
					/>
				</div>
			)}
		</div>
	);
};

export default CompanySpecialityClusterSearchFilter;
