import {TabSelect} from "components";
import {TCompanyHierarchyFilterProps} from "mapx-components/Filters/CompanyHierarchyFilter/types";
import {companyHierarchyOptionsSelector} from "store/mapx/filter/filterSelectors";
import styles from "containers/Filters/CompanyFilters/SpecialityFilter/companySpecialityFilter.module.scss";
import {CompanyHierarchyFilterOptionType} from "helpers/Filters/types";
import {useAppSelector} from "hooks";

function CompanyHierarchyFilter({handleOnClick, logic}: TCompanyHierarchyFilterProps) {
	const options = useAppSelector(companyHierarchyOptionsSelector);

	return (
		<div>
			<div className={styles.logicWrapper}>
				<span className={styles.logicLabel}>Include</span>

				<TabSelect.LabelContainer style={{margin: 0}}>
					<TabSelect
						selected={logic}
						onTabChange={(e) => handleOnClick(e as CompanyHierarchyFilterOptionType)}
						options={options}
					/>
				</TabSelect.LabelContainer>
			</div>
		</div>
	);
}

export default CompanyHierarchyFilter;
