import {FC, MouseEvent, useCallback, useMemo} from "react";

import {HeaderDropDown} from "components";
import {customConfirmAlert} from "helpers";
import {useAppDispatch, useAppSelector} from "hooks";
import {PeopleHeaderIcon, PreviewIcon, RecycleBinLine} from "assets/icons";
import type {TFeatureProp} from "components/HeaderDropDown/types";
import {projectSelector} from "store/mapx/project-list/projectListSelectors";
import {
	removeCandidateFromTargetList,
	removeCandidatesFromTargetList,
} from "store/mapx/target-list/targetListAsyncActions";
import {
	removingCandidatesFromTargetListInProgressSelector,
	targetCandidatesPaginationSelector,
	targetListCandidateIDsInProgressSelector,
	targetListCandidateInProgressSelector,
	targetListCandidatesSelector,
} from "store/mapx/target-list/targetListSelectors";

import styles from "../header.module.scss";
import {setProjectTabIndex} from "store/mapx/project-list/projectListActions";
import {useHistory} from "react-router-dom";
import ProjectTabs from "mapx-pages/Project/ProjectTabs";
import {SICandidate} from "api/candidateApi/types";

const SavedPeoples: FC = () => {
	const dispatch = useAppDispatch();

	const history = useHistory();

	const project = useAppSelector(projectSelector);

	const candidates = useAppSelector(targetListCandidatesSelector);

	const targetListCandidatePagination = useAppSelector(targetCandidatesPaginationSelector);

	const loadingCandidateTargetList = useAppSelector(targetListCandidateInProgressSelector);

	const loadingTargetListCandidateIDs = useAppSelector(targetListCandidateIDsInProgressSelector);

	const removingCandidates = useAppSelector(removingCandidatesFromTargetListInProgressSelector);

	const loading = useMemo(
		() => loadingCandidateTargetList || removingCandidates || loadingTargetListCandidateIDs,
		[loadingCandidateTargetList, removingCandidates, loadingTargetListCandidateIDs],
	);

	const handleRemoveAllClick = () => {
		customConfirmAlert({
			yesButtonLabel: "Yes",
			noButtonLabel: "No",
			title: "People Deletion Warning!",
			message: `Are you sure about deleting all people from the Target List?`,
			handlePressYes: async () => {
				await dispatch(removeCandidatesFromTargetList());
			},
		});
	};

	const handleTrashClick = (event: MouseEvent<SVGElement>, candidateID: number) => {
		event.stopPropagation();
		event.preventDefault();

		const candidate = candidates.find((c: SICandidate) => c.id === candidateID);

		customConfirmAlert({
			yesButtonLabel: "Yes",
			noButtonLabel: "No",
			title: "People Deletion Warning!",
			message: `Are you sure about deleting "${
				candidate.full_name ?? candidate.name
			}" from the Target List?`,
			handlePressYes: async () => {
				await dispatch(removeCandidateFromTargetList(candidate));
			},
		});
	};

	const handleSeeAllPeopleClick = useCallback(() => {
		if (project?.id) {
			dispatch(setProjectTabIndex(ProjectTabs.savedPeopleTabIndex));
			history.push(`/project/${project.id}`);
		}
	}, [dispatch, history, project?.id]);

	const featureUserList: TFeatureProp[] = [
		targetListCandidatePagination?.count > 50
			? {
					id: 11,
					deleteBtn: false,
					icon: PreviewIcon,
					// btnClassName: isStarterOrPlusUser ? styles.starter : "",
					text: "See All People",
					onClick: handleSeeAllPeopleClick,
			  }
			: {
					id: "EmptyItem",
			  },
		Number(!!targetListCandidatePagination?.count) > 0
			? {
					id: 2,
					deleteBtn: true,
					icon: RecycleBinLine,
					text: "Remove All People",
					onClick: handleRemoveAllClick,
			  }
			: {id: "EmptyItem"},

		Number(!!targetListCandidatePagination?.count) === 0
			? {
					id: "EmptyData",
					text: "person",
			  }
			: {id: "EmptyItem"},
	];

	return (
		<HeaderDropDown
			deleteItem
			groupCenter
			loading={loading}
			pathName="candidate"
			scrollList={candidates}
			title={targetListCandidatePagination?.count ?? 0}
			LeftIcon={PeopleHeaderIcon}
			listClasses={styles.list__width}
			featureUserList={featureUserList}
			handleTrashClick={handleTrashClick}
		/>
	);
};

export default SavedPeoples;
