import React from "react";
import {Navbar} from "mapx-components";
import TokenRefreshCheck from "components/TokenRefreshCheck";

function withAuth<T extends object>(WrappedComponent: React.ComponentType<T>): React.FC<T> {
	return function AuthenticatedComponent(props: T) {
		const tokenRefreshCheck = TokenRefreshCheck();

		return tokenRefreshCheck ? (
			tokenRefreshCheck
		) : (
			<div>
				<header>
					<Navbar />
				</header>
				<WrappedComponent {...props} />
			</div>
		);
	};
}

export default withAuth;
