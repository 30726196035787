import {useCallback, useEffect, useState} from "react";
import {
	addTargetListCompanyToCandidateTLCompanyFilter,
	moveAllCompaniesToCandidate,
	moveAllTagetListCompaniesToCandidate,
	removeAllCompaniesFromCandidateFilter,
	removeAllTargetListCompanies,
	setCompaniesOnCandidateFilter,
} from "store/mapx/filter/filterAsyncActions";
import useSearchDebounce from "hooks/useSearchDebounce";
import {
	allCompaniesCountSelector,
	candidateCompanyHierarchySelectedLogicSelector,
	candidateCompanyPositionSelector,
	candidateTargetListPositionSelector,
	candidateTargetListToggleSelector,
	companiesByPositionSelector,
	isTargetListExpandedSelector,
	searchedCompaniesSelector,
	targetListCompaniesByPositionSelector,
} from "store/mapx/filter/filterSelectors";
import {useAppDispatch, useAppSelector} from "hooks";
import {targetCompaniesPaginationSelector} from "store/mapx/target-list/targetListSelectors";
import CompanySearchFilter from "mapx-components/Filters/CompanySearchFilterWithTL";
import {STCompany} from "api/companyApi/types";
import {useSelector} from "react-redux";
import {toggleAllTargetCompaniesOnCandidateFilter} from "store/mapx/filter/candidateSearchPageAsyncActions";
import {TCompaniesPositionValues} from "./types";
import {
	setCandidateCompanyHierarchyFilterMode,
	setCandidateTargetListToggle,
} from "store/mapx/filter/filterActions";
import {Accordion} from "mapx-components";
import TargetListFilter from "mapx-components/Filters/CompanySearchFilterWithTL/TargetListFilter";
import {getSearchedCompaniesData} from "store/mapx/filter/CompanySearchPageFilters/CompanyFilterAsyncActions";
import CompanyHierarchyFilter from "mapx-components/Filters/CompanyHierarchyFilter";
import {CompanyHierarchyFilterOptionType} from "helpers/Filters/types";

function CompanyFilter({disabled}: {disabled: boolean}) {
	const dispatch = useAppDispatch();

	const [searchTerm, setSearchTerm] = useSearchDebounce(800);

	const [loading, setLoading] = useState(false);

	const position = useAppSelector(candidateCompanyPositionSelector);

	const searchedCompanies = useAppSelector(searchedCompaniesSelector);

	const companiesCount = useAppSelector(allCompaniesCountSelector);

	const pagination = useAppSelector(targetCompaniesPaginationSelector);

	const companyHierarchySelectedLogic = useAppSelector(
		candidateCompanyHierarchySelectedLogicSelector,
	);

	const handlePositionChange = useCallback(
		(value: string) => {
			if (position !== value) {
				dispatch(moveAllCompaniesToCandidate(value as TCompaniesPositionValues));
			}
		},
		[dispatch, position],
	);

	const searchCompanies = useCallback(
		async (companyName: string) => {
			if (companyName && companyName.length >= 2) {
				setLoading(true);
				await dispatch(getSearchedCompaniesData(companyName)).then(
					(response: {error: {name: string}}) => {
						if (response?.error && response?.error?.name === "CanceledError") {
							setLoading(true);
						} else {
							setLoading(false);
						}
					},
				);
			}
		},
		[dispatch],
	);

	const handleOnChange = (company: STCompany) => {
		dispatch(setCompaniesOnCandidateFilter(company));
	};

	const handleResetClick = useCallback(() => {
		dispatch(removeAllCompaniesFromCandidateFilter());
	}, [dispatch]);

	const tagCompanies = useSelector(companiesByPositionSelector);

	const handleTagClick = useCallback(
		(company: STCompany) => {
			dispatch(setCompaniesOnCandidateFilter(company));
		},
		[dispatch],
	);

	const TLFilterShouldBeExpanded = useAppSelector(isTargetListExpandedSelector);

	const TLFilterToggled = useAppSelector(candidateTargetListToggleSelector);

	const TLPosition = useAppSelector(candidateTargetListPositionSelector);

	const selectedCompaniesOnTL = useAppSelector((state) =>
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		targetListCompaniesByPositionSelector(state, TLPosition),
	);

	const handleToggleTL = useCallback(() => {
		dispatch(toggleAllTargetCompaniesOnCandidateFilter(!TLFilterToggled));
	}, [dispatch, TLFilterToggled]);

	const handleTLResetClick = useCallback(() => {
		dispatch(removeAllTargetListCompanies());
	}, [dispatch]);

	const handleSelectAllTL = useCallback(() => {
		dispatch(toggleAllTargetCompaniesOnCandidateFilter(true));
	}, [dispatch]);

	const handleTLPositionChange = useCallback(
		(value: string) => {
			dispatch(moveAllTagetListCompaniesToCandidate(value));
		},
		[dispatch],
	);

	const handleTLChange = useCallback(
		(companyID: number) => {
			dispatch(addTargetListCompanyToCandidateTLCompanyFilter(companyID));
		},
		[dispatch],
	);

	useEffect(() => {
		if (TLFilterShouldBeExpanded) {
			dispatch(setCandidateTargetListToggle(true));
		}
	}, [dispatch, TLFilterShouldBeExpanded]);

	const handleHierarchyModeChange = useCallback(
		(value: CompanyHierarchyFilterOptionType) => {
			dispatch(setCandidateCompanyHierarchyFilterMode(value));
		},
		[dispatch],
	);

	return (
		<Accordion
			title="Companies"
			subtitle={companiesCount + selectedCompaniesOnTL?.length}
			disabled={disabled}
		>
			<CompanyHierarchyFilter
				handleOnClick={handleHierarchyModeChange}
				logic={companyHierarchySelectedLogic}
			/>

			<CompanySearchFilter
				// Search Props
				searchTerm={searchTerm}
				setSearchTerm={setSearchTerm}
				loading={loading}
				position={position}
				handlePositionChange={handlePositionChange}
				searchedCompanies={searchedCompanies}
				companiesCount={companiesCount}
				tlCompaniesCount={pagination?.count}
				handleItemClick={(company: STCompany) => {
					handleOnChange(company);
					setSearchTerm("");
				}}
				handleResetClick={handleResetClick}
				searchCompanies={searchCompanies}
				// Tag props
				handleTagClick={handleTagClick}
				tagCompanies={tagCompanies}
			/>
			<TargetListFilter
				totalCompanies={pagination?.count}
				TLFilterToggled={TLFilterToggled}
				TLPosition={TLPosition}
				selectedCompaniesOnTL={selectedCompaniesOnTL}
				handleToggleTL={handleToggleTL}
				handleTLResetClick={handleTLResetClick}
				handleSelectAllTL={handleSelectAllTL}
				handleTLPositionChange={handleTLPositionChange}
				handleTLChange={handleTLChange}
			/>
		</Accordion>
	);
}

export default CompanyFilter;
