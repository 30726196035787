import styles from "./ReleventInfoSection.module.scss";
import React, {useEffect, useMemo, useState} from "react";
import ArrowRightIcon from "../Icons/ArrowRightIcon";
import {useAppDispatch, useAppSelector} from "hooks";
import {
	getMarketReportFormSelector,
	getMRSelectedCompaniesSelector,
	mrDisabledFilterKeysOnCriteriaBreakDown,
} from "store/mapx/market-report/marketReportSelector";
import {buildCandidateApiRequestPayload, isEmptyObject} from "helpers/filterHandlers";
import {ICandidateSearchApiRequestPayload} from "api/candidateApi/form";
import {getFilteredCandidatesForSearch} from "store/mapx/candidate/candidateResultsAsyncActions";
import {cancelRequest, getCancelToken} from "api/cancelTokens";
import {
	GET_CANDIDATES_RESULTS_COUNT_TOKEN,
	SEARCH_COMPANY_BY_FILTER,
} from "api/requestCancelTokenStrings";
import {
	displayMarketReportModal,
	setMRSelectedCompanies,
} from "store/mapx/market-report/marketReportActions";
import {TJobTitleFilterMode} from "store/mapx/market-report/types";
import {mRCandidateJobTitleFilterModeSelector} from "store/mapx/market-report/marketReportFilterSelectors";
import {STCandidates, TCustomCandidateResults} from "api/candidateApi/types";
import {STCompanies, STCompany, TFilterCompany} from "api/companyApi/types";
import {useHistory} from "react-router-dom";
import placeHolderImage from "assets/images/avatar-icon.png";
import ContentLoader from "react-content-loader";
import {
	setAICompanyIdeaFilterMode,
	setAllCompanyFilters,
	setFilterForCandidatesFilterSearch,
	setIgnoreTLCompaniesOnFilter,
} from "store/mapx/filter/filterActions";

import {searchCompaniesByFilter} from "store/mapx/search/searchCompanyAsyncActions";
import {filterInitialState} from "store/mapx/filter/filterReducers";
import {setSelectedCompaniesDone} from "store/mapx/company/companyActions";
import {TCandidateFilterOptions} from "api/marketReportApi/types";

const RelevantInfo = () => {
	const dispatch = useAppDispatch();

	const history = useHistory();

	const [loadingCandidates, setLoadingCandidates] = useState(false);
	const [loadingCompanies, setLoadingCompanies] = useState(false);

	const [candidatesData, setCandidatesData] = useState<STCandidates>({
		count: 0,
		candidates: [],
	});

	const [companiesData, setCompaniesData] = useState<STCompanies>({
		count: 0,
		companies: [],
	});

	const marketReportForm = useAppSelector(getMarketReportFormSelector);

	const selectedCompanies = useAppSelector(getMRSelectedCompaniesSelector);

	const jobTitleFilterMode: TJobTitleFilterMode = useAppSelector(
		mRCandidateJobTitleFilterModeSelector,
	);

	const disabledFilterKeys: (keyof TCandidateFilterOptions)[] = useAppSelector(
		mrDisabledFilterKeysOnCriteriaBreakDown,
	);

	const candidateFilterPayload = useMemo(() => {
		const filters = {...marketReportForm.filters};

		if (disabledFilterKeys.length > 0) {
			Object.keys(filters).forEach((key) => {
				if (disabledFilterKeys.includes(key as keyof TCandidateFilterOptions)) {
					delete filters[key];
				}
			});
		}

		const payload = buildCandidateApiRequestPayload(filters) as Omit<
			ICandidateSearchApiRequestPayload,
			"pagination"
		>;

		if (payload) {
			if (
				jobTitleFilterMode === "close" &&
				payload.filters &&
				payload.filters?.suggested_job_titles
			) {
				delete payload.filters.suggested_job_titles;
			}

			return payload;
		}

		return null;
	}, [marketReportForm.filters, jobTitleFilterMode, disabledFilterKeys]);

	const companyIds: number[] = useMemo(() => {
		let companies: number[] = [];

		if (marketReportForm.filters?.current_companies?.length > 0) {
			companies = [...companies, ...marketReportForm.filters.current_companies];
		} else if (marketReportForm.filters?.previous_companies?.length > 0) {
			companies = [...companies, ...marketReportForm.filters.previous_companies];
		} else if (marketReportForm.filters?.companies?.length > 0) {
			companies = [...companies, ...marketReportForm.filters.companies];
		}

		return companies;
	}, [marketReportForm.filters]);

	const companyFilterAttributes = useMemo(() => {
		const payload: Partial<TFilterCompany> = {};

		if (
			marketReportForm.filters.industries?.length > 0 ||
			marketReportForm.filters.current_industries?.length > 0 ||
			marketReportForm.filters.previous_industries?.length > 0
		) {
			payload["industries"] = [];

			if (!disabledFilterKeys.includes("industries")) {
				payload["industries"] = [
					...payload["industries"],
					...marketReportForm.filters.industries,
				];
			}

			if (!disabledFilterKeys.includes("current_industries")) {
				payload["industries"] = [
					...payload["industries"],
					...marketReportForm.filters.current_industries,
				];
			}

			if (!disabledFilterKeys.includes("previous_industries")) {
				payload["industries"] = [
					...payload["industries"],
					...marketReportForm.filters.previous_industries,
				];
			}

			if (payload["industries"].length === 0) {
				delete payload["industries"];
			}
		}

		if (
			marketReportForm.filters.company_size?.length > 0 ||
			marketReportForm.filters.current_company_size?.length > 0 ||
			marketReportForm.filters.previous_company_size?.length > 0
		) {
			payload["size"] = [];

			if (!disabledFilterKeys.includes("company_size")) {
				payload["size"] = [...payload["size"], ...marketReportForm.filters.company_size];
			}

			if (!disabledFilterKeys.includes("current_company_size")) {
				payload["size"] = [
					...payload["size"],
					...marketReportForm.filters.current_company_size,
				];
			}

			if (!disabledFilterKeys.includes("previous_company_size")) {
				payload["size"] = [
					...payload["size"],
					...marketReportForm.filters.previous_company_size,
				];
			}

			if (payload["size"].length === 0) {
				delete payload["size"];
			}
		}

		if (
			marketReportForm.filters.revenue?.length > 0 ||
			marketReportForm.filters.current_revenue?.length > 0 ||
			marketReportForm.filters.previous_revenue?.length > 0
		) {
			payload["revenue"] = [];

			if (!disabledFilterKeys.includes("revenue")) {
				payload["revenue"] = [...payload["revenue"], ...marketReportForm.filters.revenue];
			}

			if (!disabledFilterKeys.includes("current_revenue")) {
				payload["revenue"] = [
					...payload["revenue"],
					...marketReportForm.filters.current_revenue,
				];
			}

			if (!disabledFilterKeys.includes("previous_revenue")) {
				payload["revenue"] = [
					...payload["revenue"],
					...marketReportForm.filters.previous_revenue,
				];
			}

			if (payload["revenue"].length === 0) {
				delete payload["revenue"];
			}
		}

		if (
			marketReportForm.filters.company_type?.length > 0 ||
			marketReportForm.filters.current_revenue?.length > 0 ||
			marketReportForm.filters.previous_revenue?.length > 0
		) {
			payload["company_type"] = [
				...marketReportForm.filters.company_type,
				...marketReportForm.filters.current_company_type,
				...marketReportForm.filters.previous_company_type,
			];

			payload["company_type"] = [];

			if (!disabledFilterKeys.includes("company_type")) {
				payload["company_type"] = [
					...payload["company_type"],
					...marketReportForm.filters.company_type,
				];
			}

			if (!disabledFilterKeys.includes("current_company_type")) {
				payload["company_type"] = [
					...payload["company_type"],
					...marketReportForm.filters.current_company_type,
				];
			}

			if (!disabledFilterKeys.includes("previous_company_type")) {
				payload["company_type"] = [
					...payload["company_type"],
					...marketReportForm.filters.previous_company_type,
				];
			}

			if (payload["company_type"].length === 0) {
				delete payload["company_type"];
			}
		}

		return isEmptyObject(payload) ? null : payload;
	}, [disabledFilterKeys, marketReportForm.filters]);

	useEffect(() => {
		if (candidateFilterPayload) {
			const apiPayload = {
				...candidateFilterPayload,
				pagination: {
					page: 1,
					per_page: 5,
				},
			};

			setLoadingCandidates(true);

			dispatch(
				getFilteredCandidatesForSearch(apiPayload, {
					cancelToken: getCancelToken(GET_CANDIDATES_RESULTS_COUNT_TOKEN).token,
				}),
			)
				.then((response: TCustomCandidateResults) => {
					if (response) {
						setCandidatesData({
							count: response.paginationInfo.count,
							candidates: response.data,
						});
					} else {
						setCandidatesData({
							count: 0,
							candidates: [],
						});
					}
				})
				.finally(() => setLoadingCandidates(false));
		}

		return () => {
			cancelRequest(GET_CANDIDATES_RESULTS_COUNT_TOKEN);
		};
	}, [candidateFilterPayload, dispatch]);

	useEffect(() => {
		async function fetchCompanies(payload: Partial<TFilterCompany>, perPage: number) {
			const response = await dispatch(
				searchCompaniesByFilter(payload, {page: 1, per_page: perPage}),
			);

			if (response?.pagination?.count > 0) {
				setCompaniesData({
					count: response.pagination.count,
					companies: [...response.results].splice(0, 5),
				});

				return response.results;
			}

			return [];
		}

		if (companyIds?.length > 0) {
			setLoadingCompanies(true);

			const payload = {
				companies: companyIds,
			};

			fetchCompanies(payload, companyIds.length)
				.then((response: STCompany[]) => {
					if (response?.length > 0) {
						dispatch(setMRSelectedCompanies(response));
					}
				})
				.finally(() => setLoadingCompanies(false));
		} else if (!companyIds?.length && companyFilterAttributes) {
			setLoadingCompanies(true);

			fetchCompanies(companyFilterAttributes, 6).finally(() => setLoadingCompanies(false));
		} else {
			setCompaniesData({
				count: 0,
				companies: [],
			});
		}

		return () => {
			cancelRequest(SEARCH_COMPANY_BY_FILTER);
		};
	}, [companyIds, companyFilterAttributes, dispatch]);

	const InlineLoaderComponent = (
		<ContentLoader
			speed={2}
			height={60}
			width={"100%"}
			backgroundColor="#DCE7E7"
			foregroundColor="#F9F9F9"
		>
			<rect x="1" y="1" rx="50%" ry="50%" width="48" height="48" />
			<rect x="33" y="1" rx="50%" ry="50%" width="48" height="48" />
			<rect x="65" y="1" rx="50%" ry="50%" width="48" height="48" />
			<rect x="98" y="1" rx="50%" ry="50%" width="48" height="48" />

			<rect x="160" y="1" rx="3" ry="3" width="120" height="22" />
			<rect x="160" y="30" rx="3" ry="3" width="80" height="16" />
		</ContentLoader>
	);

	useEffect(() => {
		if (marketReportForm?.name?.length < 2) {
			dispatch(displayMarketReportModal(true));
		}
	}, [dispatch, marketReportForm?.name]);

	const onViewPeople = () => {
		let current_companies: STCompanies[] = [];
		let previous_companies: STCompanies[] = [];
		let companies: STCompanies[] = [];

		if (marketReportForm.filters?.current_companies?.length > 0) {
			current_companies = selectedCompanies;
		} else if (marketReportForm.filters?.previous_companies?.length > 0) {
			previous_companies = selectedCompanies;
		} else if (marketReportForm.filters?.companies?.length > 0) {
			companies = selectedCompanies;
		}

		const filters = {
			...filterInitialState.candidate_filters,
			...marketReportForm.filters,
			current_companies,
			previous_companies,
			companies,
		};

		if (filters?.suggested_job_titles?.length > 0) {
			delete filters.suggested_job_titles;
		}

		if (disabledFilterKeys.length > 0) {
			Object.keys(filters).forEach((key) => {
				if (disabledFilterKeys.includes(key as keyof TCandidateFilterOptions)) {
					delete filters[key];
				}
			});
		}

		dispatch(setFilterForCandidatesFilterSearch(filters));

		dispatch(setIgnoreTLCompaniesOnFilter(true));

		setTimeout(() => {
			history.push("/candidates-filter-search");
		}, 200);
	};

	const onViewCompany = () => {
		if (companyIds?.length > 0) {
			console.log(selectedCompanies);
			console.log(companyIds);
			dispatch(setSelectedCompaniesDone(selectedCompanies));
			dispatch(setAICompanyIdeaFilterMode("exact"));
			dispatch(
				setAllCompanyFilters({
					companies: companyIds,
				}),
			);
		} else if (!companyIds?.length && companyFilterAttributes) {
			dispatch(setAllCompanyFilters(companyFilterAttributes));
		}

		history.push("/companies");
	};

	return (
		<div className={styles.container}>
			<div className={styles.headerSection}>
				<h2>Relevant Companies and People in the Market</h2>
			</div>

			<div className={styles.listContainer}>
				{loadingCompanies && (
					<div className={styles.companiesContainer}>{InlineLoaderComponent}</div>
				)}
				{!loadingCompanies && (
					<div className={styles.companiesContainer}>
						<span className={styles.logoContainer}>
							{companiesData.companies.map((company, index) => (
								<img
									key={index}
									alt={company.name}
									src={
										company.logo_url ??
										"https://storage.googleapis.com/nebula-static/logos100x100/empty_logo.png"
									}
									className={styles.logo}
									style={{
										zIndex: index * 10 + 1,
										left: index * 30,
									}}
								/>
							))}
						</span>
						<div className={styles.viewButtonSection}>
							<span>{companiesData.count} Companies</span>
							<small onClick={onViewCompany}>
								View Companies{" "}
								<span>
									{" "}
									<ArrowRightIcon />
								</span>
							</small>
						</div>
					</div>
				)}

				{loadingCandidates && (
					<div className={styles.peopleContainer}>{InlineLoaderComponent}</div>
				)}
				{!loadingCandidates && (
					<div className={styles.peopleContainer}>
						<span className={styles.logoContainer}>
							{candidatesData.candidates.map((candidate, index) => (
								<img
									key={index}
									alt={candidate.full_name}
									src={candidate.avatar_url ?? placeHolderImage}
									onError={(e) => {
										e.currentTarget.src = placeHolderImage;
									}}
									className={styles.logo}
									style={{
										zIndex: index * 10 + 1,
										left: index * 30,
									}}
								/>
							))}
						</span>
						<div className={styles.viewButtonSection}>
							<span>{candidatesData.count} People</span>
							<small onClick={onViewPeople}>
								View People{" "}
								<span>
									{" "}
									<ArrowRightIcon />
								</span>
							</small>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default RelevantInfo;
