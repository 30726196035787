import {TLocationPosition} from "containers/Filters/PeopleFilters/LocationFilter/types";
import {handleMoveLocation} from "mapx-components/Filters/LocationSearchFilter/utils";
import {
	apCandidateCountryIdsByPositionSelector,
	apCandidateRegionIdsByPositionSelector,
	apLocationFilterKeyByPosition,
	apRegionFilterKeyByPosition,
} from "store/mapx/additional-profiles/additionalProfilesSelectors";
import {setAPMultipleFilters} from "store/mapx/additional-profiles/additionalProfilesActions";
import {Dispatch} from "redux";
import {TAppDispatch} from "types";

export const moveAPLocationsForCandidate = (params: {
	from: TLocationPosition;
	to: TLocationPosition;
	country_id: number;
	region_id: string;
}) =>
	handleMoveLocation(
		params,
		(position) => ({
			locationKey: apLocationFilterKeyByPosition(position),
			regionKey: apRegionFilterKeyByPosition(position),
		}),
		(position, state) => ({
			locations: apCandidateCountryIdsByPositionSelector(position)(state),
			regions: apCandidateRegionIdsByPositionSelector(position)(state),
		}),
		setAPMultipleFilters,
	);

export const setAPLocationForCandidate =
	({
		position,
		nextCountryIds,
		toBeSetRegionIds,
	}: {
		position: TLocationPosition;
		nextCountryIds: number[];
		toBeSetRegionIds: number[];
	}) =>
	async (dispatch: Dispatch<TAppDispatch>) => {
		const locationFilterKey = apLocationFilterKeyByPosition(position);
		const regionFilterKey = apRegionFilterKeyByPosition(position);

		dispatch(
			setAPMultipleFilters({
				[locationFilterKey]: nextCountryIds,
				[regionFilterKey]: toBeSetRegionIds,
			}),
		);
	};
