/**
 * Calculates total amount of months between two date objects.
 *
 * @export
 * @param {Date} startDate
 * @param {Date} endDate
 * @return {number} total months
 */
import {format, parseISO} from "date-fns";

export function getMonthDifference(startDate, endDate) {
	return (
		endDate.getMonth() -
		startDate.getMonth() +
		12 * (endDate.getFullYear() - startDate.getFullYear())
	);
}

/**
 * Convert the date format to a shorter version. ex: Sep 2021.
 *
 * @export
 * @return {number} total months
 * @param dateString
 */

export const convertDateToShortMonthFullYearFormat = (dateString) => {
	if (!dateString) return "";
	const date = new Date(dateString);

	return date.toLocaleString("en-US", {year: "numeric", month: "short"});
};

/**
 * Given the total amount of months, it returns a humanized string.
 *
 * @export
 * @param {number} months total number of months
 * @return {string} humanized string "1 yrs 7 months"
 */
export function humanizeMonths(months) {
	const years = Math.floor(months / 12);

	return years ? `${years} yrs ${months - years * 12} months` : `${months} months`;
}

export function getStandardDateFormatFromIso(date) {
	const d = parseISO(date);

	return format(d, "dd.MM.yyyy");
}

export function getAltStandardDateFormatFromIso(date) {
	const d = parseISO(date);

	return format(d, "MMM yyyy");
}
