import {BiBuildings, BiGridAlt, BiIdCard} from "react-icons/bi";
import type {TNavItemProps} from "./types";
import {ProjectIcon} from "assets/icons";
import {BsFunnel} from "react-icons/bs";

export const pages: (
	isSuperUser: boolean,
	superUserOrNotClient: boolean,
	hasAccess: boolean,
	isEmptyProject?: boolean,
	isAdmin?: boolean,
	locationOnboarding?: boolean,
) => TNavItemProps[] = (
	isSuperUser: boolean,
	superUserOrNotClient: boolean,
	hasAccess: boolean,
	isEmptyProject?: boolean,
	isAdmin?: boolean,
	locationOnboarding?: boolean,
) => {
	return [
		{
			name: "Home",
			path: "/home",
			Icon: BiGridAlt,
			pathGroup: [],
		},
		{
			Icon: BsFunnel,
			name: "Market Reviews",
			path: "/market-reports",
			pathGroup: ["market-reports"],
		},
		hasAccess
			? {
					name: "Company List Builder",
					path: "#",
					Icon: BiBuildings,
					pathGroup: ["companies", "company", "market-maps"],
					childPaths: [
						{
							name: "Company Search",
							path: "/companies",
							pathGroup: ["companies"],
						},
						{
							name: "Target Lists",
							path: "/market-maps",
							pathGroup: ["market-maps"],
						},
					],
			  }
			: {name: ""},

		{
			name: "People Search",
			path: "#",
			Icon: BiIdCard,
			pathGroup: [
				"candidates-filter-search",
				"candidates-text-search",
				"candidates-profile-search",
				"candidates-ai-web-search",
				"candidate",
			],
			childPaths: [
				hasAccess
					? {
							name: "Filter Search",
							path: "/candidates-filter-search",
							pathGroup: ["candidates-filter-search"],
					  }
					: {name: ""},
				{
					name: "Text Search",
					path: "/candidates-text-search",
					pathGroup: ["candidates-text-search"],
				},
				{
					name: "Profile Search",
					path: "/candidates-profile-search",
					pathGroup: ["candidates-profile-search"],
				},
				hasAccess
					? {
							name: "AI Web Search",
							path: "/candidates-ai-web-search",
							pathGroup: ["candidates-ai-web-search"],
					  }
					: {name: ""},
			],
		},

		{
			name: "Projects",
			Icon: ProjectIcon,
			path: isEmptyProject && locationOnboarding ? "/onboarding/projects" : "/projects",
			pathGroup: ["projects", "project"],
		},

		// hasAccess
		// 	? {
		// 			name: "Market Maps",
		// 			path: "/market-maps",
		// 			Icon: MarketMapsIcon,
		// 			pathGroup: [],
		// 	  }
		// 	: {name: ""},

		// hasAccess && superUserOrNotClient
		// 	? {
		// 			name: "Organizations",
		// 			path: "/organisations",
		// 			Icon: OrganisationsIcon,
		// 			pathGroup: [],
		// 			superUser: superUserOrNotClient,
		// 	  }
		// 	: {name: ""},

		// !isSuperUser
		// 	? {
		// 			name: "Org Settings",
		// 			path: "/organisation-settings",
		// 			Icon: UserSettingsIcon,
		// 			pathGroup: [],
		// 	  }
		// 	: {name: ""},

		// hasAccess && superUserOrNotClient
		// 	? {
		// 			name: "Learn to Rank",
		// 			path: "/learn-to-rank",
		// 			Icon: LearnToRankIcon,
		// 			pathGroup: [],
		// 			superUser: superUserOrNotClient,
		// 	  }
		// 	: {name: ""},
	];
};
