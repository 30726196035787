import React, {useMemo} from "react";
import {SearchInput} from "mapx-components";
import css from "./LanguageSearchFilter.module.scss";
import useFetchListOptions from "hooks/useFetchListOptions";
import {Checkbox, CheckboxSkeletonLoader, ResetFilters} from "components";
import {getName} from "helpers/string";
import CheckboxList from "mapx-components/Inputs/CheckboxList";
import {useAppSelector} from "hooks";
import {TLanguageItem, TLanguageSearchFilterProps} from "./types";
import {getLanguages} from "store/mapx/search/searchAsyncActions";
import {languageOptionSelector} from "store/mapx/search/searchSelectors";
import Tags from "./Tags/index";

export default function LanguageSearchFilter({
	handleResetClick,
	handleOnChange,
	selectedLanguages = [],
}: TLanguageSearchFilterProps) {
	const languageOptions = useAppSelector(languageOptionSelector);

	const {filteredData, onFilterChanged, loading} = useFetchListOptions({
		options: languageOptions,
		callbackMethod: getLanguages,
	});

	const typedFilteredData = filteredData as TLanguageItem[];

	// we need to move the selected languages out of the list, they're displayed separately as tags below the checkbox
	const reorderedData = useMemo(() => {
		return typedFilteredData.filter((item) => !selectedLanguages.includes(item.name));
	}, [typedFilteredData, selectedLanguages]);

	return (
		<div>
			<SearchInput
				onChange={onFilterChanged}
				placeholder="Search for a Language"
				type="text"
				errorText={undefined}
				errorClass={undefined}
				isLoading={loading}
			/>

			<ResetFilters
				parentStyle={{color: "#5A5A5A", marginRight: 19}}
				onClick={handleResetClick}
				displayIcon={true}
			>
				Clear Selection
			</ResetFilters>

			<div className={css.checkboxList}>
				<CheckboxList data-testid={"languageList"}>
					{reorderedData?.map((item: TLanguageItem) => (
						<Checkbox
							borderColor="#0C5850"
							containerClass={css.checkboxContainer}
							isChecked={selectedLanguages.includes(item.name)}
							key={item.id}
							label={getName(item.name)}
							onChange={() => handleOnChange(item.name)}
							value={getName(item.name)}
						/>
					))}
				</CheckboxList>

				{loading && <CheckboxSkeletonLoader />}
			</div>

			{/*display selected languages as tags*/}

			{selectedLanguages?.length > 0 && (
				<div style={{margin: "8px 0"}}>
					<Tags
						tagLabel={"Selected Languages"}
						handleTagClick={handleOnChange}
						tags={selectedLanguages}
						clearTags={handleResetClick}
					/>
				</div>
			)}
		</div>
	);
}
