import React from "react";

const LeaverIcon = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
		>
			<path d="M2 10L12 15.7735V4.2265L2 10ZM11 11H19V9H11V11Z" fill="#C20A0A" />
		</svg>
	);
};

export default LeaverIcon;
