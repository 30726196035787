import styles from "./CoreRoleRequirements.module.scss";
import JobtitleIcon from "../Icons/JobtitleIcon";
import StarIcon from "../Icons/StartIcon";
import SpecialismIcon from "../Icons/SpecialismIcon";
import LocationIcon from "../Icons/LocationIcon";
import RequirementsCard from "./Card/RequirementsCard";
import {TUpdateMarketReportForm} from "api/marketReportApi/types";
import {useAppDispatch, useAppSelector} from "hooks";
import {getMarketReportFormSelector} from "store/mapx/market-report/marketReportSelector";
import {useEffect, useMemo, useState} from "react";
import {
	mRCandidateSelectedJobTitlesSelector,
	mRCandidateSuggestedJobTitlesSelector,
	mrCurrentJobFunctionTagsSelector,
	mRSelectedCurrentCountriesSelector,
} from "store/mapx/market-report/marketReportFilterSelectors";
import {ExpandIcon, HelpIcon, MinimizeIcon} from "assets/icons";
import {useLocation} from "react-router-dom";
import {sortArrayStrings} from "helpers/array";
import {seniorityOptionSelector} from "store/mapx/search/searchSelectors";
import {TSeniorityItem} from "mapx-components/Filters/SenioritySearchFilter/types";
import {getSeniorityList} from "store/mapx/search/searchAsyncActions";

const CoreRoleRequirements = () => {
	const dispatch = useAppDispatch();

	const marketReportForm: TUpdateMarketReportForm = useAppSelector(getMarketReportFormSelector);

	const currentSpecialismTags = useAppSelector(mrCurrentJobFunctionTagsSelector);

	const currentLocations = useAppSelector(mRSelectedCurrentCountriesSelector);

	const selectedJobTitles: string[] = useAppSelector(mRCandidateSelectedJobTitlesSelector);

	const suggestedJobTitles: string[] = useAppSelector(mRCandidateSuggestedJobTitlesSelector);

	const seniorityOptions = useAppSelector(seniorityOptionSelector);

	const [expandedAll, setExpandedAll] = useState(false);

	const location = useLocation();

	const isMarketReportFormPage = useMemo(() => {
		const path = location.pathname;

		return path.startsWith("/market-reports/create") || path.startsWith("/market-reports/edit");
	}, [location.pathname]);

	const jobTitles = useMemo(() => {
		const data = selectedJobTitles?.length > 0 ? selectedJobTitles : suggestedJobTitles;

		return sortArrayStrings(data);
	}, [selectedJobTitles, suggestedJobTitles]);

	const seniorities = useMemo(() => {
		return marketReportForm.filters?.current_seniority &&
			marketReportForm.filters?.current_seniority?.length > 0
			? seniorityOptions
					.filter((item: TSeniorityItem) =>
						marketReportForm.filters?.current_seniority?.includes(item.id),
					)
					.map((item: TSeniorityItem) => item.name)
			: [];
	}, [marketReportForm.filters?.current_seniority, seniorityOptions]);

	const specialisms = useMemo(() => {
		const data =
			currentSpecialismTags?.map((item) => `${item.jobFunctionName}: ${item.name}`) || [];

		return sortArrayStrings(data);
	}, [currentSpecialismTags]);

	const locations = useMemo(() => {
		const data = currentLocations.map((item) => item.name) || [];

		return sortArrayStrings(data);
	}, [currentLocations]);

	useEffect(() => {
		if (!seniorityOptions.length) {
			dispatch(getSeniorityList());
		}
	}, [dispatch, seniorityOptions]);

	return (
		<div className={styles.wrapper}>
			<h2>
				Core Role Requirements{" "}
				{expandedAll ? (
					<MinimizeIcon
						style={{cursor: "pointer"}}
						onClick={() => setExpandedAll(!expandedAll)}
					/>
				) : (
					<ExpandIcon
						style={{cursor: "pointer"}}
						onClick={() => setExpandedAll(!expandedAll)}
					/>
				)}
			</h2>

			<div className={styles.container}>
				{marketReportForm?.id === undefined && (
					<div className={styles.info}>
						<HelpIcon fill={"#0095FF"} /> Core role requirements are used to generate an
						estimated market size based on current job title/role in a location.
					</div>
				)}

				<div className={styles.cards}>
					<RequirementsCard
						title={"Job title"}
						count={jobTitles?.length || 0}
						subtitle={isMarketReportFormPage ? "Add a job title..." : ""}
						values={jobTitles}
						icon={<JobtitleIcon />}
						expanded={expandedAll}
					/>
					<RequirementsCard
						title={"Seniority"}
						count={seniorities?.length || 0}
						subtitle={isMarketReportFormPage ? "Select seniority..." : ""}
						values={seniorities}
						icon={<StarIcon />}
						expanded={expandedAll}
					/>
					<RequirementsCard
						title={"Function & Specialisms"}
						count={specialisms?.length || 0}
						subtitle={isMarketReportFormPage ? "Select specialism..." : ""}
						values={specialisms}
						icon={<SpecialismIcon />}
						expanded={expandedAll}
					/>
					<RequirementsCard
						title={"Location"}
						count={locations?.length}
						subtitle={
							marketReportForm.id !== undefined ? "Worldwide" : "Select location..."
						}
						values={locations}
						icon={<LocationIcon />}
						expanded={expandedAll}
					/>
				</div>
			</div>
		</div>
	);
};

export default CoreRoleRequirements;
