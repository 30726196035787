import React from "react";

const LargeCardIcon = ({...props}) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			{...props}
		>
			<mask
				id="mask0_1697_21369"
				style={{maskType: "alpha"}}
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="20"
				height="20"
			>
				<rect width="20" height="20" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_1697_21369)">
				<path
					d="M4.5 14C4.09722 14 3.74653 13.8507 3.44792 13.5521C3.14931 13.2535 3 12.9028 3 12.5V7.5C3 7.09722 3.14931 6.74653 3.44792 6.44792C3.74653 6.14931 4.09722 6 4.5 6H15.5C15.9125 6 16.2656 6.14931 16.5594 6.44792C16.8531 6.74653 17 7.09722 17 7.5V12.5C17 12.9028 16.8531 13.2535 16.5594 13.5521C16.2656 13.8507 15.9125 14 15.5 14H4.5ZM4.5 12.5H15.5V7.5H4.5V12.5ZM3 4.5V3H17V4.5H3ZM3 17V15.5H17V17H3Z"
					fill="#B5B1B0"
				/>
			</g>
		</svg>
	);
};

export default LargeCardIcon;
