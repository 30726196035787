import {useCallback, useMemo} from "react";

import styles from "./styles.module.scss";
import {useSelector} from "react-redux";
import {useAppDispatch} from "hooks";
import {Dropdown} from "components";
import {daysFilterOption} from "./type";
import {ArrowDown} from "assets/icons";
import {selectedTrackCandidatePositionChangedDaysSelector} from "store/mapx/project/projectCandidateTrackingSelectors";
import {setSelectedTrackCandidatePositionChangedDays} from "store/mapx/project/projectActions";

// const SORT_OPTIONS: sortOption[] = [{value: "date", name: "Date"}];

const OPTIONS: daysFilterOption[] = [
	{value: 14, name: "Last 14 days"},
	{value: 30, name: "Last 30 days"},
	{value: 90, name: "Last 3 Months"},
	{value: 180, name: "Last 6 months"},
	{value: 365, name: "Last 1 year"},
	{value: 730, name: "Last 2 years"},
	{value: 365 * 5, name: "Last 5 years"},
];

const TrackingFilterSection = () => {
	const dispatch = useAppDispatch();

	const selectedDaysFilter = useSelector(selectedTrackCandidatePositionChangedDaysSelector);

	const daysFilterOnSelect = useCallback(
		(option: daysFilterOption) => {
			dispatch(setSelectedTrackCandidatePositionChangedDays(option.value));
		},
		[dispatch],
	);

	// const displayedSelectedSort = useMemo(() => {
	//     return SORT_OPTIONS.find((option) => option.value === selectedSortOption)?.name;
	// }, [selectedSortOption]);

	const displayedSelectedDaysFilter = useMemo(() => {
		return OPTIONS.find((option) => option.value === selectedDaysFilter)?.name;
	}, [selectedDaysFilter]);

	return (
		<div className={styles.filterSectionWrapper}>
			<div className={styles.left}>
				{/*<Dropdown*/}
				{/*    key={displayedSelectedSort || "Default"}*/}
				{/*    onSelect={noop}*/}
				{/*    options={SORT_OPTIONS}*/}
				{/*    getOptionLabel={(option) => option.name}*/}
				{/*    defaultSelectedOption={*/}
				{/*        SORT_OPTIONS.find((s) => s.value === selectedSortOption) || null*/}
				{/*    }*/}
				{/*    placeholder={*/}
				{/*        <div className={styles.dropdownPlaceholder}>*/}
				{/*            <div>*/}
				{/*                <span>Sort By</span> {displayedSelectedSort}*/}
				{/*            </div>*/}
				{/*            <div>*/}
				{/*                <ArrowDown/>*/}
				{/*            </div>*/}
				{/*        </div>*/}
				{/*    }*/}
				{/*    dropdownButtonWidth={145}*/}
				{/*/>*/}

				<Dropdown
					key={displayedSelectedDaysFilter || "Default"}
					onSelect={daysFilterOnSelect}
					options={OPTIONS}
					getOptionLabel={(option) => option.name}
					defaultSelectedOption={
						OPTIONS.find((s) => s.value === selectedDaysFilter) || null
					}
					placeholder={
						<div className={styles.dropdownPlaceholder}>
							<div>
								<span>Display</span> {displayedSelectedDaysFilter}
							</div>
							<div>
								<ArrowDown />
							</div>
						</div>
					}
					dropdownButtonWidth={200}
				/>
			</div>

			<div className={styles.right} />
		</div>
	);
};

export default TrackingFilterSection;
