import styles from "./CriteriaBreakDown.module.scss";
import classNames from "classnames";
import {EyeHideIcon, EyeShowIcon, PencilIcon} from "assets/icons";
import {useAppDispatch, useAppSelector} from "hooks";
import {getMarketReportFormSelector} from "store/mapx/market-report/marketReportSelector";
import {useCallback, useEffect, useMemo, useState} from "react";
import {TCriteriaFilterItem} from "mapx-pages/MarketReportDetails/CriteriaBreakDown/type";
import {
	createFunnelGroups,
	extractLogicConnector,
	HIERARCHY,
} from "mapx-pages/MarketReportDetails/CriteriaBreakDown/utils";
import {
	mRAllCompaniesFilterCountSelector,
	mrAnyJobFunctionTagsSelector,
	mRAnyKeywordTagsSelector,
	mRCandidateCompanyEventsSelector,
	mRCandidateLanguageSelector,
	mRCurrentKeywordTagsSelector,
	mrPreviousJobFunctionTagsSelector,
	mRPreviousKeywordTagsSelector,
	mRSelectedAnyCompanyHeadcountTagsSelector,
	mRSelectedAnyCompanyRevenueTagsSelector,
	mRSelectedAnyCompanyTypesSelector,
	mRSelectedAnyCountriesSelector,
	mRSelectedAnyIndustriesSelector,
	mrSelectedCurrentCompanyHeadcountTagsSelector,
	mRSelectedCurrentCompanyRevenueTagsSelector,
	mRSelectedCurrentCompanyTypesSelector,
	mRSelectedCurrentIndustriesSelector,
	mRSelectedPreviousCompanyHeadcountTagsSelector,
	mRSelectedPreviousCompanyRevenueTagsSelector,
	mRSelectedPreviousCompanyTypesSelector,
	mRSelectedPreviousCountriesSelector,
	mRSelectedPreviousIndustriesSelector,
} from "store/mapx/market-report/marketReportFilterSelectors";
import {setActiveAccordionOnFilter} from "store/mapx/filter/filterActions";
import {TCandidateFilterOptions, TMarketReportFilter} from "api/marketReportApi/types";
import {formatNumberWithCommas} from "mapx-pages/CandidateSearch/utils";
import {buildCandidateApiRequestPayload} from "helpers/filterHandlers";
import {getFilteredCandidatesForSearch} from "store/mapx/candidate/candidateResultsAsyncActions";
import {ICandidateSearchApiRequestPayload} from "api/candidateApi/form";
import MarketReportCriteriaBreakdownSkeletonLoader from "mapx-pages/MarketReportDetails/CriteriaBreakDown/Loader";
import {setMRDisabledFilterKeys} from "store/mapx/market-report/marketReportActions";

const CriteriaBreakDown = ({handleEditClick}: {handleEditClick: () => void}) => {
	const marketReportForm = useAppSelector(getMarketReportFormSelector);

	const totalCompanies = useAppSelector(mRAllCompaniesFilterCountSelector);

	const currentIndustries = useAppSelector(mRSelectedCurrentIndustriesSelector);
	const previousIndustries = useAppSelector(mRSelectedPreviousIndustriesSelector);

	const anyIndustries = useAppSelector(mRSelectedAnyIndustriesSelector);
	const previousSpecialisms = useAppSelector(mrPreviousJobFunctionTagsSelector);
	const anySpecialisms = useAppSelector(mrAnyJobFunctionTagsSelector);

	const currentKeywords = useAppSelector(mRCurrentKeywordTagsSelector);
	const previousKeywords = useAppSelector(mRPreviousKeywordTagsSelector);
	const anyKeywords = useAppSelector(mRAnyKeywordTagsSelector);

	const currentHeadcounts = useAppSelector(mrSelectedCurrentCompanyHeadcountTagsSelector);
	const previousHeadcounts = useAppSelector(mRSelectedPreviousCompanyHeadcountTagsSelector);
	const anyHeadcounts = useAppSelector(mRSelectedAnyCompanyHeadcountTagsSelector);

	const currentRevenues = useAppSelector(mRSelectedCurrentCompanyRevenueTagsSelector);
	const previousRevenues = useAppSelector(mRSelectedPreviousCompanyRevenueTagsSelector);
	const anyRevenues = useAppSelector(mRSelectedAnyCompanyRevenueTagsSelector);

	const currentCompanyTypes = useAppSelector(mRSelectedCurrentCompanyTypesSelector);
	const previousCompanyTypes = useAppSelector(mRSelectedPreviousCompanyTypesSelector);
	const anyCompanyTypes = useAppSelector(mRSelectedAnyCompanyTypesSelector);

	const previousCountries = useAppSelector(mRSelectedPreviousCountriesSelector);
	const anyCountries = useAppSelector(mRSelectedAnyCountriesSelector);

	const companyEventsFilter = useAppSelector(mRCandidateCompanyEventsSelector);

	const languages: string[] = useAppSelector(mRCandidateLanguageSelector);

	const [loading, setLoading] = useState(false);

	const dispatch = useAppDispatch();

	const [filterInfoAsHierarchy, setFilterInfoAsHierarchy] = useState<TCriteriaFilterItem[]>([]);

	const hierarchyMapping = useMemo((): Record<string, Partial<TCriteriaFilterItem>> => {
		return {
			market_size: {
				hierarchyLabel: "Estimated Total Market Size",
				title: "Estimated Total Market Size",
				subtitle: "Core Role Requirements",
				marketSizePercentage: 100,
				logicalOperator: null,
				filter_keys: [
					"current_job_titles_or",
					"current_seniority",
					"current_countries_or",
					"current_specialisms_and",
					"current_specialisms_or",
					"current_specialisms_not",
				],
			},
			companies: {
				hierarchyLabel: "In a Relevant Company",
				title: `${totalCompanies} Companies`,
				subtitle: "In a Relevant Company",
				color: "#837D7C",
				accordionId: "Company Target List",
				logicalOperator: null,
				filter_keys: ["companies", "current_companies", "previous_companies"],
			},
			current_industries: {
				hierarchyLabel: "Industry",
				title: `${currentIndustries.map((item) => item.name).join(", ")}`,
				subtitle: "Currently, Industry",
				color: "#D8A864",
				accordionId: "Industry",
				filter_keys: ["current_industries"],
			},
			previous_industries: {
				hierarchyLabel: "Industry",
				title: `${previousIndustries.map((item) => item.name).join(", ")}`,
				subtitle: "Previously, Industry",
				color: "#D8A864",
				accordionId: "Industry",
				filter_keys: ["previous_industries"],
			},
			industries: {
				hierarchyLabel: "Industry",
				title: `${anyIndustries.map((item) => item.name).join(", ")}`,
				subtitle: "Any, Industry",
				color: "#D8A864",
				accordionId: "Industry",
				filter_keys: ["industries"],
			},
			previous_specialisms: {
				hierarchyLabel: "Function & Specialisms",
				title: `${previousSpecialisms
					.map((item) => `${item.jobFunctionName}: ${item.name}`)
					.join(", ")}`,
				subtitle: "Previously, Specialisms",
				color: "#927FBB",
				accordionId: "Function & Specialism",
				filter_keys: [
					"previous_specialisms_or",
					"previous_specialisms_and",
					"previous_specialisms_not",
				],
			},
			specialisms: {
				hierarchyLabel: "Function & Specialisms",
				title: `${anySpecialisms
					.map((item) => `${item.jobFunctionName}: ${item.name}`)
					.join(", ")}`,
				subtitle: "Any, Specialisms",
				color: "#927FBB",
				accordionId: "Function & Specialism",
				filter_keys: ["specialisms_or", "specialisms_and", "specialisms_not"],
			},
			current_keywords: {
				hierarchyLabel: "Keywords",
				title: `${currentKeywords.join(", ")}`,
				subtitle: "Currently, Keywords",
				color: "#D25C3E",
				accordionId: "Keyword",
				filter_keys: [
					"current_text_keywords_or",
					"current_text_keywords_and",
					"current_text_keywords_not",
				],
			},
			previous_keywords: {
				hierarchyLabel: "Keywords",
				title: `${previousKeywords.join(", ")}`,
				subtitle: "Previously, Keywords",
				color: "#D25C3E",
				accordionId: "Keyword",
				filter_keys: [
					"previous_text_keywords_or",
					"previous_text_keywords_and",
					"previous_text_keywords_not",
				],
			},
			keywords: {
				hierarchyLabel: "Keywords",
				title: `${anyKeywords.join(", ")}`,
				subtitle: "Any, Keywords",
				color: "#D25C3E",
				accordionId: "Keyword",
				filter_keys: ["text_keywords_or", "text_keywords_and", "text_keywords_not"],
			},
			current_company_size: {
				hierarchyLabel: "Company Headcount",
				title: currentHeadcounts.map((item) => item.name).join(", "),
				subtitle: "Currently, Headcounts",
				color: "#71A0AA",
				accordionId: "Company Headcount",
				filter_keys: ["current_company_size"],
			},
			previous_company_size: {
				hierarchyLabel: "Headcount",
				title: previousHeadcounts.map((item) => item.name).join(", "),
				subtitle: "Previously, Headcount",
				color: "#71A0AA",
				accordionId: "Company Headcount",
				filter_keys: ["previous_company_size"],
			},
			company_size: {
				hierarchyLabel: "Headcount",
				title: anyHeadcounts.map((item) => item.name).join(", "),
				subtitle: "Any, Headcounts",
				color: "#71A0AA",
				accordionId: "Company Headcount",
				filter_keys: ["company_size"],
			},
			current_revenue: {
				hierarchyLabel: "Revenue",
				title: currentRevenues.map((item) => item.name).join(", "),
				subtitle: "Currently, Revenues",
				color: "#93BB61",
				accordionId: "Company Revenue",
				filter_keys: ["current_revenue"],
			},
			previous_revenue: {
				hierarchyLabel: "Revenue",
				title: previousRevenues.map((item) => item.name).join(", "),
				subtitle: "Previously, Revenues",
				color: "#93BB61",
				accordionId: "Company Revenue",
				filter_keys: ["previous_revenue"],
			},
			revenue: {
				hierarchyLabel: "Revenue",
				title: anyRevenues.map((item) => item.name).join(", "),
				subtitle: "Any, Revenue",
				color: "#93BB61",
				accordionId: "Company Revenue",
				filter_keys: ["revenue"],
			},
			current_company_type: {
				hierarchyLabel: "Company Types",
				title: currentCompanyTypes.map((item) => item.name).join(", "),
				subtitle: "Currently, Company Types",
				color: "#DFB43E",
				accordionId: "Company Type",
				filter_keys: ["current_company_type"],
			},
			previous_company_type: {
				hierarchyLabel: "Company Types",
				title: previousCompanyTypes.map((item) => item.name).join(", "),
				subtitle: "Previously, Company Types",
				color: "#DFB43E",
				accordionId: "Company Type",
				filter_keys: ["previous_company_type"],
			},
			company_type: {
				hierarchyLabel: "Company Types",
				title: anyCompanyTypes.map((item) => item.name).join(", "),
				subtitle: "Any, Company Types",
				color: "#DFB43E",
				accordionId: "Company Type",
				filter_keys: ["company_type"],
			},
			previous_locations: {
				hierarchyLabel: "Location",
				title: previousCountries.map((item) => item.name).join(", "),
				subtitle: "Previously, Location",
				color: "#94BCA9",
				accordionId: "Location",
				filter_keys: ["previous_countries_or", "previous_regions_or"],
			},
			locations: {
				hierarchyLabel: "Location",
				title: anyCountries.map((item) => item.name).join(", "),
				subtitle: "Any, Location",
				color: "#94BCA9",
				accordionId: "Location",
				filter_keys: ["countries_or", "regions_or"],
			},
			languages: {
				hierarchyLabel: "Languages",
				title: languages.join(", "),
				subtitle: "Languages",
				color: "#93BB61",
				accordionId: "Languages",
				logicalOperator: "AND",
				filter_keys: ["languages"],
			},
			deal_experience: {
				hierarchyLabel: "Deal Experience",
				title: companyEventsFilter.types.join(", "),
				subtitle: "Deal Experience",
				color: "#DFB43E",
				accordionId: "Deal Experience",
				logicalOperator: "AND",
				filter_keys: ["company_events"],
			},
		};
	}, [
		anyCompanyTypes,
		anyCountries,
		anyHeadcounts,
		anyIndustries,
		anyKeywords,
		anyRevenues,
		anySpecialisms,
		companyEventsFilter.types,
		currentCompanyTypes,
		currentHeadcounts,
		currentIndustries,
		currentKeywords,
		currentRevenues,
		previousCompanyTypes,
		previousCountries,
		previousHeadcounts,
		previousIndustries,
		previousKeywords,
		previousRevenues,
		previousSpecialisms,
		totalCompanies,
		languages,
	]);

	const handleApiCallsAndReturnResults = useCallback(
		async (requestQueue: Record<number, TMarketReportFilter>) => {
			const ids = Object.keys(requestQueue);
			const filters = Object.values(requestQueue);

			const promises = filters.map((filter) => {
				const payload = buildCandidateApiRequestPayload({...filter}) as Omit<
					ICandidateSearchApiRequestPayload,
					"pagination"
				>;
				const apiPayload = {
					...payload,
					pagination: {
						page: 1,
						per_page: 1,
					},
				};

				return getFilteredCandidatesForSearch(apiPayload);
			});

			const results = await Promise.all(promises.map((apiCall) => dispatch(apiCall)));

			const marketSizeByIds: Record<number, number> = {};
			let finalMarketSize = 0;

			ids.forEach((id, index) => {
				const count = results[index].paginationInfo.count;
				marketSizeByIds[parseInt(id)] = count;

				if (index + 1 === ids.length) {
					finalMarketSize = count;
				}
			});

			return {
				marketSizeByIds,
				finalMarketSize,
			};
		},
		[dispatch],
	);

	const findMostRecentEnabledSegment = (
		filtersHierarchy: TCriteriaFilterItem[],
		requestQueue: Record<number, TMarketReportFilter>,
		currentIndex: number,
	): TMarketReportFilter | null => {
		for (let i = currentIndex - 1; i >= 0; i--) {
			if (!filtersHierarchy[i].disabled) {
				return requestQueue[i + 1];
			}
		}

		return null;
	};

	const setCriteriaBreakdown = useCallback(
		async (disabledIds: Set<number>) => {
			const funnelGroups = createFunnelGroups(marketReportForm.filters, HIERARCHY);

			let filtersHierarchy: TCriteriaFilterItem[] = [];
			const requestQueue: Record<number, TMarketReportFilter> = {};

			funnelGroups.forEach((item, index) => {
				const id = index + 1;

				const isDisabled = disabledIds.has(id);

				const funnelItem: TCriteriaFilterItem = {
					id,
					hierarchyLabel: "",
					title: "",
					subtitle: "",
					marketSize: String(0),
					marketSizePercentage: 0,
					color: "#DAD8D7",
					accordionId: "",
					disabled: isDisabled,
					filter_keys: Object.keys(item.filters) as (keyof TCandidateFilterOptions)[],
					logicalOperator:
						hierarchyMapping[item.hierarchyLevel]?.logicalOperator !== undefined
							? extractLogicConnector(item.filters)
							: null,
					...hierarchyMapping[item.hierarchyLevel],
				};

				if (id === 1) {
					requestQueue[id] = item.filters as TMarketReportFilter;
				} else {
					const mostRecentEnabledFilters = findMostRecentEnabledSegment(
						filtersHierarchy,
						requestQueue,
						index,
					);

					if (mostRecentEnabledFilters) {
						requestQueue[id] = {...mostRecentEnabledFilters, ...item.filters};
					} else {
						requestQueue[id] = {...item.filters} as TMarketReportFilter;
					}
				}

				filtersHierarchy = [...filtersHierarchy, funnelItem];
			});

			// Only make API calls for segments starting from `startFromId`
			const filteredRequestQueue: Record<number, TMarketReportFilter> = {};

			// const startId = disabledId !== null ? disabledId + 1 : 1;

			for (let i = 1; i <= funnelGroups.length; i++) {
				filteredRequestQueue[i] = requestQueue[i];
			}

			const response = await handleApiCallsAndReturnResults(filteredRequestQueue);

			filtersHierarchy = filtersHierarchy.map((item) => {
				const marketSizeSet = response.marketSizeByIds;
				const coreRoleRequirementMarketSize = marketSizeSet[1] || 0;
				const marketSize = marketSizeSet[item.id];
				let marketSizePercentage = 0;

				if (marketSize > 0) {
					const percentage = (marketSize / coreRoleRequirementMarketSize) * 100;
					marketSizePercentage =
						percentage > 1 ? Math.round(percentage) : +percentage.toFixed(2);
				}

				return {
					...item,
					marketSize: String(marketSizeSet[item.id]) || "0",
					marketSizePercentage: marketSizePercentage,
				};
			});

			// console.log("filtersHierarchy", filtersHierarchy)
			//
			// console.log("----")

			return filtersHierarchy;
		},
		[marketReportForm.filters, handleApiCallsAndReturnResults, hierarchyMapping],
	);

	useEffect(() => {
		setLoading(true);

		const disabledIds = new Set<number>();

		setCriteriaBreakdown(disabledIds)
			.then((fData: TCriteriaFilterItem[]) => setFilterInfoAsHierarchy(fData))
			.finally(() => setLoading(false));
	}, [setCriteriaBreakdown]);

	const estimatedProfileMatch = useMemo(() => {
		if (filterInfoAsHierarchy?.length > 0) {
			for (let i = filterInfoAsHierarchy.length - 1; i >= 0; i--) {
				if (!filterInfoAsHierarchy[i].disabled) {
					return filterInfoAsHierarchy[i].marketSize;
				}
			}
		}

		return "0";
	}, [filterInfoAsHierarchy]);

	const handleDisableFilterById = useCallback(
		(id: number) => {
			if (id < filterInfoAsHierarchy.length) {
				setLoading(true);

				const disabledHierarchyIdsArray = filterInfoAsHierarchy
					.filter((i) => i.disabled)
					.map((i) => i.id);
				const disabledIds = new Set<number>(disabledHierarchyIdsArray);

				if (disabledIds.has(id)) {
					disabledIds.delete(id);
				} else {
					disabledIds.add(id);
				}

				const disabledFilterKeys = filterInfoAsHierarchy
					.filter((i) => disabledIds.has(i.id))
					.map((i) => i.filter_keys)
					.flat();

				dispatch(setMRDisabledFilterKeys(disabledFilterKeys));

				setCriteriaBreakdown(disabledIds)
					.then((fData: TCriteriaFilterItem[]) => {
						setFilterInfoAsHierarchy(fData);
					})
					.finally(() => setLoading(false));
			} else {
				setFilterInfoAsHierarchy((prevState) => {
					const updatedData = prevState.map((item) => {
						if (item.id === id) {
							return {...item, disabled: !item.disabled};
						}

						return item;
					});

					const disabledFilterKeys = updatedData
						.filter((i) => i.disabled)
						.map((i) => i.filter_keys)
						.flat();

					dispatch(setMRDisabledFilterKeys(disabledFilterKeys));

					return updatedData;
				});
			}
		},
		[filterInfoAsHierarchy, setCriteriaBreakdown, dispatch],
	);

	return (
		<div className={styles.container}>
			<div className={styles.headerSection}>
				<h2>Criteria Breakdown</h2>
			</div>
			{loading && <MarketReportCriteriaBreakdownSkeletonLoader />}

			{!loading && (
				<div className={styles.criteriaBreakdown}>
					{/* Funnel Section */}
					<div className={styles.funnel}>
						{filterInfoAsHierarchy.map((item, index) => (
							<div className={styles.funnelContainer} key={index}>
								<div
									className={classNames(styles.funnelSegment, {
										[styles.disabled]: item.disabled,
										[styles.funnel1]: index === 0,
										[styles.funnel2]: index === 1,
										[styles.funnel3]: index === 2,
										[styles.funnelSquare]:
											index > 2 && index < filterInfoAsHierarchy.length - 1,
										[styles.funnelEnd]:
											index > 2 && index === filterInfoAsHierarchy.length - 1,
									})}
									style={
										index < 3
											? {borderTopColor: item.color}
											: {backgroundColor: item.color}
									}
								>
									<span
										className={classNames(
											index > 2
												? styles.squareSegmentLabel
												: styles.segmentLabel,
										)}
										style={
											index > 2 && index === filterInfoAsHierarchy.length - 1
												? {
														top: -5,
														position: "relative",
												  }
												: {}
										}
									>
										{item.hierarchyLabel}
									</span>

									{index === 2 && (
										<span
											className={styles.funnelBottomBox}
											style={{backgroundColor: item.color}}
										/>
									)}
								</div>
							</div>
						))}
					</div>

					{/* Horizontal Bars Section */}
					<div className={styles.horizontalBars}>
						{filterInfoAsHierarchy.map((item, index) => (
							<div className={styles.barContainer} key={index}>
								<div className={classNames(styles.barInfoContainer)}>
									<div
										className={classNames(styles.barInfo, {
											[styles.disabled]: item.disabled,
										})}
									>
										<span className={styles.barCount}>
											{formatNumberWithCommas(parseInt(item.marketSize))}
										</span>
										<span className={styles.barPercentage}>
											{item.marketSizePercentage}%
										</span>
									</div>
									<div
										className={classNames(styles.progressBarContainer, {
											[styles.disabled]: item.disabled,
										})}
									>
										<span className={styles.barLabel}>
											<span className={styles.barTitle} title={item.title}>
												{item.logicalOperator && (
													<span style={{color: "#868D8D"}}>
														{item.logicalOperator}{" "}
													</span>
												)}
												{item.title}
											</span>
											<span className={styles.lightText}>
												{item.subtitle}
											</span>
										</span>
										<div className={styles.bar}>
											<div
												className={classNames(
													classNames(
														styles.barFill,
														styles.marketSizeBar,
													),
													styles.marketSizeBar,
												)}
												style={{
													width: `${item.marketSizePercentage}%`,
													background: item.color,
												}}
											></div>
										</div>
									</div>

									{index > 0 && (
										<div className={styles.actionList}>
											{item.disabled ? (
												<EyeHideIcon
													onClick={() => handleDisableFilterById(item.id)}
												/>
											) : (
												<EyeShowIcon
													onClick={() => handleDisableFilterById(item.id)}
												/>
											)}
											{/*<EyeShowIcon onClick={() => handleDisableFilterById(item.id)}/>*/}

											<PencilIcon
												color={"rgba(131, 125, 124, 1)"}
												onClick={() => {
													dispatch(
														setActiveAccordionOnFilter(
															item.accordionId,
														),
													);

													handleEditClick();
												}}
											/>
										</div>
									)}
								</div>
							</div>
						))}
					</div>
				</div>
			)}

			{!loading && (
				<h3>
					Estimated Profile Matches:{" "}
					<span>{formatNumberWithCommas(parseInt(estimatedProfileMatch))}</span>
				</h3>
			)}
		</div>
	);
};

export default CriteriaBreakDown;
