import styles from "./marketReports.module.scss";
import CriteriaBreakDown from "./CriteriaBreakDown";
import CoreRoleRequirements from "./CoreRoleRequirements";
import RelevantInfo from "./ReleventInfoSection";
import {GeneralButton, MainWrapper} from "mapx-components";
import {Button} from "../../components";
import {PencilIcon} from "../../assets/icons";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "hooks";
import {getMarketReportDetailsSelector} from "store/mapx/market-report/marketReportSelector";
import {
	downloadMarketReportAsPowerpoint,
	getMarketReportById,
} from "store/mapx/market-report/marketReportAsyncActions";
import MarketReportDetailsSkeletonLoader from "mapx-pages/MarketReportDetails/Loader";
import {IMarketReport} from "api/marketReportApi/types";
import {
	clearMarketReportDetails,
	setMRDisabledFilterKeys,
} from "store/mapx/market-report/marketReportActions";
import MarketReportCriteriaBreakdownSkeletonLoader from "mapx-pages/MarketReportDetails/CriteriaBreakDown/Loader";
import {
	getCandidateCompanyEventsList,
	getCompanyHeadcountRanges,
	getCompanyRevenueRanges,
	getCompanyTypeList,
	getCountries,
	getIndustries,
	getJobFunctions,
	getLanguages,
} from "store/mapx/search/searchAsyncActions";
import {
	companyHeadcountRangeOptionsSelector,
	companyRevenueRangeOptionsSelector,
	companyTypeOptionSelector,
	countryOptionsSelector,
	industryOptionsSelector,
	jobFunctionOptionsSelector,
	languageOptionSelector,
} from "store/mapx/search/searchSelectors";
import {CandidateCompanyEventsSelector} from "store/mapx/search/searchDealExperienceSelectors";
import MarketReportDiversity from "mapx-pages/MarketReportDetails/Diversity";
import IconPPTX from "mapx-pages/MarketReportDetails/Icons/PptxIcon";
import {isStarterTypeUserSelector} from "store/mapx/user/userSelectors";
import classNames from "classnames";

const MarketReportDetails = () => {
	const history = useHistory();

	const industryOptions = useAppSelector(industryOptionsSelector);
	const jobFunctionOptions = useAppSelector(jobFunctionOptionsSelector);
	const companyTypeOptions = useAppSelector(companyTypeOptionSelector);
	const companyHeadcountRanges = useAppSelector(companyHeadcountRangeOptionsSelector);
	const companyRevenueRanges = useAppSelector(companyRevenueRangeOptionsSelector);
	const candidateCompanyEventOptions = useAppSelector(CandidateCompanyEventsSelector);
	const languageOptions = useAppSelector(languageOptionSelector);
	const countryOptions = useAppSelector(countryOptionsSelector);
	const isStarterTypeUser = useAppSelector(isStarterTypeUserSelector);
	const [downloadPowerpointProcessing, setDownloadPowerpointProcessing] = useState(false);

	const dispatch = useAppDispatch();

	const [loading, setLoading] = useState<boolean>(false);

	const params = useParams();

	const marketReportId = useMemo(
		() => ("id" in params ? parseInt(params.id as string) : null),
		[params],
	);

	const marketReport: IMarketReport = useAppSelector(getMarketReportDetailsSelector);
	const isNavigatingToEdit = useRef(false);

	const handleEditClick = useCallback(() => {
		isNavigatingToEdit.current = true;
		history.push(`/market-reports/edit/${marketReport.id}`);
		dispatch(setMRDisabledFilterKeys([]));
	}, [history, marketReport, dispatch]);

	useEffect(
		() => {
			async function get(id: number) {
				setLoading(true);
				await dispatch(getMarketReportById(id));
			}

			if (marketReportId && marketReport?.id !== marketReportId) {
				get(marketReportId).finally(() => setLoading(false));
			}

			return () => {
				if (!isNavigatingToEdit.current) {
					dispatch(clearMarketReportDetails());
				}
				isNavigatingToEdit.current = false;
			};
		},
		// eslint-disable-next-line
		[dispatch, marketReportId],
	);

	useEffect(() => {
		if (companyTypeOptions?.length === 0) {
			dispatch(getCompanyTypeList());
		}

		if (jobFunctionOptions?.length === 0) {
			dispatch(getJobFunctions());
		}

		if (industryOptions?.length === 0) {
			dispatch(getIndustries());
		}

		if (companyHeadcountRanges?.length === 0) {
			dispatch(getCompanyHeadcountRanges());
		}

		if (companyRevenueRanges?.length === 0) {
			dispatch(getCompanyRevenueRanges());
		}

		if (candidateCompanyEventOptions?.length === 0) {
			dispatch(getCandidateCompanyEventsList());
		}

		if (languageOptions?.length === 0) {
			dispatch(getLanguages());
		}

		if (countryOptions?.length === 0) {
			dispatch(getCountries());
		}
	}, [
		companyTypeOptions?.length,
		dispatch,
		companyHeadcountRanges?.length,
		jobFunctionOptions?.length,
		companyRevenueRanges?.length,
		candidateCompanyEventOptions?.length,
		industryOptions?.length,
		languageOptions?.length,
		countryOptions?.length,
	]);

	const displayPowerpointDownload = useMemo(() => {
		return !isStarterTypeUser;
	}, [isStarterTypeUser]);

	const handleDownloadPowerpoint = useCallback(async () => {
		if (isStarterTypeUser) return;

		setDownloadPowerpointProcessing(true);

		await dispatch(downloadMarketReportAsPowerpoint());

		setDownloadPowerpointProcessing(false);
	}, [dispatch, isStarterTypeUser]);

	return (
		<MainWrapper className={styles.wrapper}>
			{loading && (
				<div className={styles.wrapper}>
					<MarketReportDetailsSkeletonLoader />
					<br />
					<br />
					<MarketReportCriteriaBreakdownSkeletonLoader />
				</div>
			)}

			{!loading && marketReport && (
				<div className={styles.wrapper}>
					<div className={styles.stickyHeader}>
						<h1>{marketReport.name}</h1>
						<div className={styles.rightSection}>
							{displayPowerpointDownload && (
								<GeneralButton
									title="Download PPT"
									className={classNames(styles.pptButton, {
										[styles.disabled]: downloadPowerpointProcessing,
									})}
									loadingSave={downloadPowerpointProcessing}
									icon={<IconPPTX />}
									onClick={handleDownloadPowerpoint}
								/>
							)}
							<Button
								name={"Edit Report"}
								onClick={handleEditClick}
								className={styles.editProjectButton}
							>
								<PencilIcon color={"#4E5555"} /> Edit Report
							</Button>
						</div>
					</div>
					<div className={styles.container}>
						<CoreRoleRequirements />
						<CriteriaBreakDown handleEditClick={handleEditClick} />
						<MarketReportDiversity />
						<RelevantInfo />
						{/*@Todo:Location component*/}
						{/*<MarketReportMobility />*/}
						{/*@Todo:Background Analysis component*/}
						{/*<MarketReportTenure />*/}
					</div>
				</div>
			)}
		</MainWrapper>
	);
};

export default MarketReportDetails;
