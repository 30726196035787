import React, {useMemo} from "react";
import CircularProgress from "mapx-components/Cards/CandidateScoring/ProgressCircle";
import {TCandidateFitToBrief} from "api/candidateApi/types";

const useCandidateScoringUtils = (fit_to_brief?: TCandidateFitToBrief) => {
	const matchScorePercentage = useMemo(() => {
		if (fit_to_brief === undefined) {
			return "low";
		} else if (fit_to_brief?.score < 33) {
			return "low";
		} else if (fit_to_brief?.score < 66) {
			return "mid";
		} else {
			return "high";
		}
	}, [fit_to_brief]);

	const progressCircleColor = useMemo(() => {
		if (matchScorePercentage === "low") {
			return "#4E5555";
		} else if (matchScorePercentage === "mid") {
			return "#960";
		} else {
			return "#26734C";
		}
	}, [matchScorePercentage]);

	const CIRCULAR_PROGRESS = (
		<CircularProgress size={20} percentage={fit_to_brief?.score} color={progressCircleColor} />
	);

	return {
		matchScorePercentage,
		progressCircleColor,
		CIRCULAR_PROGRESS,
	};
};

export default useCandidateScoringUtils;
