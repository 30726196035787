import HttpClient from "../../api";

import type {STTargetListPost} from "api/targetListApi/types";
import {ICandidateSearchApiRequestPayload} from "api/candidateApi/form";
import {TFilterCompanyPayload} from "../companyApi/types";

class Index extends HttpClient {
	async getTargetLists(pageNumber = 1) {
		return this.doGet(`/mapx/target-lists?page=${pageNumber}`);
	}

	async getTargetListsByOwnerID(ownerID: number) {
		return await this.doGet(`/mapx/target-lists?owner=${ownerID}&page=1&per_page=999999`);
	}

	async createDetachedTargetList() {
		return await this.doPost("/mapx/target-lists", {});
	}

	async createTargetList(formData: STTargetListPost) {
		return await this.doPost("/mapx/target-lists", formData);
	}

	async getTargetListCompanies(payload: TFilterCompanyPayload, config = {}) {
		return await this.doPost(`/companies`, payload, config);
	}

	async getTargetListCandidates(targetListID: number, page = 1, perPage = 20, config = {}) {
		return await this.doGet(
			`/mapx/target-lists/${targetListID}/candidates?page=${page}&per_page=${perPage}`,
			config,
		);
	}

	async getTargetListCandidateIDs(
		targetListID: number,
		payload: ICandidateSearchApiRequestPayload,
		config = {},
	) {
		return this.doPost(`/target-lists/${targetListID}/get-candidate-ids`, payload, config);
	}

	async getTargetListCompanyIDs(targetListID: number, config = {}) {
		return this.doPost(`/target-lists/${targetListID}/get-company-ids`, config);
	}

	async addCandidateToTargetList(targetListID: number, candidateID: number) {
		return await this.doPost(`/mapx/target-lists/${targetListID}/candidates`, {
			candidate_id: candidateID,
		});
	}

	async addCompanyToTargetList(targetListID: number, companyID: number) {
		return await this.doPost(`/mapx/target-lists/${targetListID}/companies`, {
			company_id: companyID,
		});
	}

	async addCompaniesFromMarketMapToTargetList(targetListID: number, marketMapID: number) {
		return await this.doPost(`/target-lists/${targetListID}/add-companies-from-market-map`, {
			market_map_id: marketMapID,
		});
	}

	async removeCandidateFromTargetList(targetListID: number, candidateID: number) {
		return await this.doDelete(`/mapx/target-lists/${targetListID}/candidates/${candidateID}`);
	}

	async removeCompanyFromTargetList(targetListID: number, companyID: number) {
		return await this.doDelete(`/mapx/target-lists/${targetListID}/companies/${companyID}`);
	}

	async removeCandidatesFromTargetList(targetListID: number) {
		return await this.doDelete(`/mapx/target-lists/${targetListID}/candidates`);
	}

	async removeCompaniesFromTargetList(targetListID: number) {
		return await this.doDelete(`/mapx/target-lists/${targetListID}/companies`);
	}

	async mergeTargetLists(sourceTargetListID: number, destinationTargetListID: number) {
		return await this.doPost("/mapx/target-lists/merge", {
			source_target_list: sourceTargetListID,
			destination_target_list: destinationTargetListID,
		});
	}

	// async updateTargetList(targetListID: number, formData: {}) {
	// 	return await this.doPatch(`/mapx/target-lists/${targetListID}`, formData);
	// }

	async saveCompaniesByFilters(
		filterQuery: string,
		targetListID: number,
		includeSimilar = false,
	) {
		return await this.doPost(
			`/mapx/target-lists/${targetListID}/add-companies-by-filters${filterQuery}&include_similar=${includeSimilar}`,
		);
	}

	async saveCandidatesByFilters(
		targetListId: number,
		payload: ICandidateSearchApiRequestPayload,
	) {
		return await this.doPost(
			`/target-lists/${targetListId}/add-candidates-by-filters?target_list_id=${targetListId}`,
			payload,
		);
	}
}

const mapXTargetListApi = new Index();

export default mapXTargetListApi;
