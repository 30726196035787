import React from "react";

const TableViewIcon = ({...props}) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			{...props}
		>
			<mask
				id="mask0_1697_21361"
				style={{maskType: "alpha"}}
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="20"
				height="20"
			>
				<rect width="20" height="20" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_1697_21361)">
				<path
					d="M7 14.5H16.5V12.5H7V14.5ZM3.5 7.5H5.5V5.5H3.5V7.5ZM3.5 11H5.5V9H3.5V11ZM3.5 14.5H5.5V12.5H3.5V14.5ZM7 11H16.5V9H7V11ZM7 7.5H16.5V5.5H7V7.5ZM3.5 16C3.0875 16 2.73438 15.8531 2.44063 15.5592C2.14688 15.2653 2 14.9119 2 14.4992V5.49417C2 5.08139 2.14688 4.72917 2.44063 4.4375C2.73438 4.14583 3.0875 4 3.5 4H16.5C16.9125 4 17.2656 4.14694 17.5594 4.44083C17.8531 4.73472 18 5.08806 18 5.50083V14.5058C18 14.9186 17.8531 15.2708 17.5594 15.5625C17.2656 15.8542 16.9125 16 16.5 16H3.5Z"
					fill="#B5B1B0"
				/>
			</g>
		</svg>
	);
};

export default TableViewIcon;
