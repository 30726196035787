import {flattenByChildren} from "helpers/TreeModification";
import {createSelector} from "reselect";

export const getSearchDataInProgressSelector = (state) => state.search.getSearchDataInProgress;

export const searchSelector = (state) => state.search;

export const searchedDataSelector = (state) => state.search.searchedData;

export const companyHeadcountRangeOptionsSelector = (state) =>
	state.search.companyHeadcountRangeOptions;

export const companyRevenueRangeOptionsSelector = (state) =>
	state.search.companyRevenueRangeOptions;

export const getJobTitleInProgressSelector = (state) => state.search.jobTitleOptionsInProgress;

export const jobTitleSearchQuerySelector = (state) => state.search.jobTitleSearchQuery;

export const jobTitleOptionsSelector = (state) => state.search.jobTitleOptions;

export const seniorityOptionSelector = (state) => state.search.seniorityOptions;
export const companyTypeOptionSelector = (state) => state.search.companyTypeOptions;

export const languageOptionSelector = (state) => state.search.languageOptions;
export const jobTitlePaginationSelector = (state) => state.search.apJobTitlesPaginationInfo;

export const jobFunctionOptionsSelector = (state) => state.search.jobFunctionOptions;

export const countryOptionsSelector = (state) => state.search.countryOptions;

export const industryOptionsSelector = (state) => state.search.industryOptions;

export const keywordOptionsInProgressSelector = (state) => state.search.keywordOptionsInProgress;

export const keywordOptionsSelector = (state) => state.search.keywordOptions;

export const keywordPaginationSelector = (state) => state.search.keywordPaginationInfo;

export const keywordSearchQuerySelector = (state) => state.search.keywordSearchQuery;

export const apKeywordPaginationSelector = (state) => state.search.apKeywordPaginationInfo;

export const apKeywordSearchQuerySelector = (state) => state.search.apKeywordSearchQuery;

export const specialtyOptionsInProgressSelector = (state) =>
	state.search.specialtyOptionsInProgress;
export const specialtiesOptionsSelector = (state) => state.search.specialtiesOptions;

export const specialtyClusterOptionsInProgressSelector = (state) =>
	state.search.specialtyClusterOptionsInProgress;

export const specialtyClusterSearchInProgressSelector = (state) =>
	state.search.specialtyClusterSearchInProgress;

export const specialtyClusterOptionsSelector = (state) => state.search.specialtyClusterOptions;

export const specialtyClusterOptionsFromCompaniesSelector = (state) =>
	state.search.selectedSpecialtyClusterOptionsFromCompanies;

export const specialismOptionsSelector = createSelector(
	[jobFunctionOptionsSelector],
	(jobFunctionOptions) => {
		return jobFunctionOptions.reduce((acc, jobFunction) => {
			return [
				...acc,
				...jobFunction.specialisms.map((s) => ({
					id: s.id,
					name: `${jobFunction.name}: ${s.name}`,
				})),
			];
		}, []);
	},
);

export const flattenIndustriesSelector = createSelector([industryOptionsSelector], (options) =>
	flattenByChildren(options),
);
