import axios, {AxiosResponse} from "axios";
import {targetListIDSelector} from "store/mapx/target-list/targetListSelectors";
import {
	getTargetListCandidateIDsDone,
	getTargetListCandidateIDsFail,
	getTargetListCandidateIDsInit,
	getTargetListCandidatesDone,
	getTargetListCandidatesFail,
	getTargetListCandidatesInit,
} from "store/mapx/target-list/targetListActions";
import {successResponse} from "helpers/map";
import {Dispatch} from "react";
import {TAppDispatch, TRootState} from "types";
import {ICandidateApiFilterOptions, ICandidateSearchApiRequestPayload} from "api/candidateApi/form";
import {clearCancelToken, getCancelToken} from "api/cancelTokens";
import {
	GET_TARGETLIST_CANDIDATE_IDS_TOKEN,
	GET_TARGETLIST_CANDIDATES_TOKEN,
} from "api/requestCancelTokenStrings";
import mapXTargetListApi from "api/targetListApi";
import {setProjectCandidatesSelectionInProgress} from "../project/projectActions";
import mapXCandidateApi from "api/candidateApi";

export const getTargetListCandidates =
	() => async (dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const cancelToken = getCancelToken(GET_TARGETLIST_CANDIDATES_TOKEN);

		try {
			const state = getState();

			const targetListID = targetListIDSelector(state);

			dispatch(getTargetListCandidatesInit());

			const apiPayload: ICandidateSearchApiRequestPayload = {
				filters: {
					target_list: targetListID,
				},
				pagination: {page: 1, per_page: 50},
				sorting_options: {
					ordering: "-added_to_project_date",
				},
			};

			const response = await mapXCandidateApi.getCandidatesByFilter(apiPayload, {
				cancelToken: cancelToken.token,
			});

			if (response) {
				if (successResponse(response, 200)) {
					const data = response.data;

					dispatch(getTargetListCandidatesDone(data));
				} else {
					dispatch(getTargetListCandidatesFail({error: "something went wrong"}));
				}
			}
		} catch (error) {
			if (axios.isCancel(error)) {
				console.log("Request canceled:", error.message);
			} else {
				dispatch(getTargetListCandidatesFail({error}));
			}
		} finally {
			clearCancelToken(GET_TARGETLIST_CANDIDATES_TOKEN);
		}
	};

export const fetchTargetListCandidateIDs =
	(source: "projectDetails" | "targetList" = "targetList", filter?: ICandidateApiFilterOptions) =>
	async (dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const cancelTokenSource = getCancelToken(GET_TARGETLIST_CANDIDATE_IDS_TOKEN);

		try {
			const state = getState();
			const targetListID: number = targetListIDSelector(state);

			source === "projectDetails"
				? dispatch(setProjectCandidatesSelectionInProgress(true))
				: dispatch(getTargetListCandidateIDsInit());

			const apiPayload: ICandidateSearchApiRequestPayload = {
				filters: filter ? {...filter} : {},
				pagination: {page: 1, per_page: 20000},
			};

			const response: AxiosResponse = await mapXTargetListApi.getTargetListCandidateIDs(
				targetListID,
				apiPayload,
				{cancelToken: cancelTokenSource.token},
			);

			if (!response || !successResponse(response, 200)) {
				dispatch(getTargetListCandidateIDsFail({error: "Something went wrong"}));

				return [];
			}

			const data = response.data.results;

			source === "projectDetails"
				? dispatch(setProjectCandidatesSelectionInProgress(false))
				: dispatch(getTargetListCandidateIDsDone(data));

			return data;
		} catch (error) {
			console.error("Error in fetchTargetListCandidateIDs:", error);

			return [];
		} finally {
			clearCancelToken(GET_TARGETLIST_CANDIDATE_IDS_TOKEN);
		}
	};
