import {
	apCandidateCompaniesFilterKeyByPositionSelector,
	apCandidateCompanyByPositionSelector,
	apCandidateCompanyPositionSelector,
	apCandidateIndustriesByPositionSelector,
	apCandidateIndustriesFilterKeyByPositionSelector,
	apTargetListPositionSelector,
	apTargetListToggleSelector,
	apTLCompaniesFilterKeyByPositionSelector,
	apTLCompanyIdsByTargetListPositionSelector,
} from "store/mapx/additional-profiles/additionalProfilesSelectors";
import {
	setAPCandidateTargetListToggle,
	setAPCompanyPosition,
	setAPCompanyTLPosition,
	setAPMultipleFilters,
} from "./additionalProfilesActions";
import {targetListCompanyIdsSelector} from "../target-list/targetListSelectors";

export const setAPIndustry =
	({position, nextIds}) =>
	async (dispatch, getState) => {
		const state = getState();

		const industryFilterKey = apCandidateIndustriesFilterKeyByPositionSelector(state, position);

		dispatch(setAPMultipleFilters({[industryFilterKey]: nextIds}));
	};

export const setAPCompanyMultiple =
	({ids}) =>
	async (dispatch, getState) => {
		const state = getState();

		const position = apCandidateCompanyPositionSelector(state);

		const companiesFilterKey = apCandidateCompaniesFilterKeyByPositionSelector(state, position);

		dispatch(setAPMultipleFilters({[companiesFilterKey]: [...ids]}));
	};

export const setAPCompany =
	({id}) =>
	async (dispatch, getState) => {
		const state = getState();

		const position = apCandidateCompanyPositionSelector(state);

		const companies = apCandidateCompanyByPositionSelector(state);

		const companiesFilterKey = apCandidateCompaniesFilterKeyByPositionSelector(state, position);

		const index = companies.indexOf(id);

		const isSelectedAlready = index !== -1;

		const nextCompanies = isSelectedAlready
			? [...companies.slice(0, index), ...companies.slice(index + 1)]
			: [...companies, id];

		dispatch(setAPMultipleFilters({[companiesFilterKey]: nextCompanies}));
	};

export const moveAPCompanyAll =
	({position}) =>
	async (dispatch, getState) => {
		const state = getState();

		const prevPosition = apCandidateCompanyPositionSelector(state);

		const companies = apCandidateCompanyByPositionSelector(state);

		const fromCompaniesFilterKey = apCandidateCompaniesFilterKeyByPositionSelector(
			state,
			prevPosition,
		);

		const toCompaniesFilterKey = apCandidateCompaniesFilterKeyByPositionSelector(
			state,
			position,
		);

		if (companies.length > 0) {
			dispatch(
				setAPMultipleFilters({
					[fromCompaniesFilterKey]: [],
					[toCompaniesFilterKey]: [...companies],
				}),
			);
		}

		dispatch(setAPCompanyPosition(position));
	};

export const moveApTlCompanyAll =
	({position}) =>
	async (dispatch, getState) => {
		const state = getState();

		const prevPosition = apTargetListPositionSelector(state);

		const companyIds = apTLCompanyIdsByTargetListPositionSelector(state);

		const fromCompaniesFilterKey = apTLCompaniesFilterKeyByPositionSelector(
			state,
			prevPosition,
		);

		const toCompaniesFilterKey = apTLCompaniesFilterKeyByPositionSelector(state, position);

		if (companyIds?.length > 0) {
			dispatch(
				setAPMultipleFilters({
					[fromCompaniesFilterKey]: [],
					[toCompaniesFilterKey]: [...companyIds],
				}),
			);
		}

		dispatch(setAPCompanyTLPosition(position));
	};

export const moveAPIndustry =
	({from, to, ids}) =>
	async (dispatch, getState) => {
		const idsSet = new Set(ids);

		const state = getState();

		const fromIndustriesFilterKey = apCandidateIndustriesFilterKeyByPositionSelector(
			state,
			from,
		);

		const toIndustriesFilterKey = apCandidateIndustriesFilterKeyByPositionSelector(state, to);

		const fromIndustries = apCandidateIndustriesByPositionSelector(state, from);

		const toIndustries = apCandidateIndustriesByPositionSelector(state, to);

		dispatch(
			setAPMultipleFilters({
				[fromIndustriesFilterKey]: fromIndustries.filter((id) => !idsSet.has(id)),
				[toIndustriesFilterKey]: [
					...toIndustries.filter((id) => !idsSet.has(id)), // prevent duplication
					...fromIndustries.filter((id) => idsSet.has(id)),
				],
			}),
		);
	};

export const toggleAllTargetCompaniesOnAPCandidateFilter =
	(add = false) =>
	(dispatch, getState) => {
		const state = getState();

		const targetListCompanyIDs = targetListCompanyIdsSelector(state);

		const position = apTargetListPositionSelector(state);

		const filterKey = apTLCompaniesFilterKeyByPositionSelector(state, position);

		const companyIds = apTLCompanyIdsByTargetListPositionSelector(state);

		if (add) {
			const existingCompanyIds = new Set(companyIds);

			let newCompanyIds = [...companyIds];

			if (companyIds?.length > 0) {
				targetListCompanyIDs.forEach((item) => {
					const exist = existingCompanyIds.has(item);

					if (!exist) {
						newCompanyIds.push(item);
					}
				});
			} else {
				newCompanyIds = [...targetListCompanyIDs];
			}

			dispatch(setAPMultipleFilters({[filterKey]: newCompanyIds}));
			dispatch(setAPCandidateTargetListToggle(true));
		} else {
			const targetListCompanyIds = targetListCompanyIdsSelector(state);

			dispatch(
				setAPMultipleFilters({
					[filterKey]: companyIds.filter((item) => !targetListCompanyIds.includes(item)),
				}),
			);
			dispatch(setAPCandidateTargetListToggle(false));
		}
	};

export const setAPCompanyToTargetList =
	({id}) =>
	async (dispatch, getState) => {
		const state = getState();

		const position = apTargetListPositionSelector(state);

		const companiesFilterKey = apTLCompaniesFilterKeyByPositionSelector(state, position);

		const companyIds = apTLCompanyIdsByTargetListPositionSelector(state);

		const index = companyIds?.indexOf(id);

		const isSelectedAlready = index !== -1;

		const nextCompanyIds = isSelectedAlready
			? [...companyIds.slice(0, index), ...companyIds.slice(index + 1)]
			: [...companyIds, id];

		dispatch(setAPMultipleFilters({[companiesFilterKey]: nextCompanyIds}));
	};

export const removeAllCompaniesFromProjectCandidateFilter = () => (dispatch, getState) => {
	const state = getState();

	const position = apTargetListPositionSelector(state);

	const companiesFilterKey = apTLCompaniesFilterKeyByPositionSelector(state, position);

	dispatch(setAPMultipleFilters({[companiesFilterKey]: []}));
};

export const removeCompanyFromProjectSavedPeople = (companyID) => (dispatch, getState) => {
	const state = getState();

	const isAPTargetListOn = apTargetListToggleSelector(state);
	if (isAPTargetListOn) {
		const apPosition = apTargetListPositionSelector(state);
		const apFilterKey = apTLCompaniesFilterKeyByPositionSelector(state, apPosition);
		const apCompanyIds = apTLCompanyIdsByTargetListPositionSelector(state);

		dispatch(
			setAPMultipleFilters({
				[apFilterKey]: apCompanyIds.filter((item) => item !== companyID),
			}),
		);
	}
};

export const setTargetListFiltersForApCompanies = () => (dispatch, getState) => {
	const state = getState();

	const apPosition = apTargetListPositionSelector(state);

	const targetListCompanyIDs = targetListCompanyIdsSelector(state);

	const apFilterKey = apTLCompaniesFilterKeyByPositionSelector(state, apPosition);

	dispatch(setAPMultipleFilters({[apFilterKey]: targetListCompanyIDs}));
};
