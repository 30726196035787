import React, {useCallback, useMemo, useRef, useState} from "react";
import styles from "./styles.module.scss";
import {ActionAltButton} from "../../index";
import classNames from "classnames";
import {MagicWandIcon} from "assets/icons";
import {TProjectCandidateSummary} from "./types";
import ContentLoader from "react-content-loader";
import {
	generateCandidatesSummary,
	updateCandidateSummary,
} from "store/mapx/project/generateCandidateSummaryAsyncActions";
import {useAppDispatch, useAppSelector} from "hooks";
import {
	generateCandidatesSummariesFlattenedForAllProjectSelector,
	isSummaryForCurrentCandidateInProgressForCurrentProjectSelector,
} from "store/mapx/project/generateCandidateSummarySelectors";
import {
	isCandidateSavedSelector,
	targetListIDSelector,
} from "store/mapx/target-list/targetListSelectors";
import {projectSelector} from "store/mapx/project-list/projectListSelectors";
import {useLocation} from "react-router-dom";
import {isArray, isString} from "lodash";

const CandidateSummary = ({id, summary, expanded}: TProjectCandidateSummary) => {
	const dispatch = useAppDispatch();

	const location = useLocation();

	const isFromProjectSource = location.pathname.split("/").includes("project");

	const isFromProjectSearchSource = location.search.toLowerCase().split("=").includes("project");

	const targetListID = useAppSelector(targetListIDSelector);

	const project = useAppSelector(projectSelector);

	const isCandidateSavedToProject = useAppSelector((state) =>
		isCandidateSavedSelector(state, id),
	);

	const showGenerateButton = project && isCandidateSavedToProject;

	const [creatingRequest, setCreatingRequest] = useState(false);

	const isSummaryForCurrentCandidateInProgressForCurrentProject = useAppSelector<boolean>(
		(state) =>
			isSummaryForCurrentCandidateInProgressForCurrentProjectSelector(
				state,
				targetListID,
				id,
			),
	);

	const flattenedCandidateSummariesForAllProject = useAppSelector(
		generateCandidatesSummariesFlattenedForAllProjectSelector,
	);

	const generatingSummary = useMemo(
		() => creatingRequest || isSummaryForCurrentCandidateInProgressForCurrentProject,
		[creatingRequest, isSummaryForCurrentCandidateInProgressForCurrentProject],
	);

	const handleGenerateButtonClick = useCallback(async () => {
		setCreatingRequest(true);
		await dispatch(generateCandidatesSummary([id]));
		setCreatingRequest(false);
	}, [dispatch, id]);

	const [loadingSummaryUpdate, setLoadingSummaryUpdate] = useState(false);
	const [editingSummary, setEditingSummary] = useState(false);
	const [editableSummary, setEditableSummary] = useState(summary || "");
	const [debounceTimeout, setDebounceTimeout] = useState<ReturnType<typeof setTimeout> | null>(
		null,
	);
	const textareaRef = useRef<Nullable<HTMLTextAreaElement>>(null);

	const handleSummaryClick = () => {
		setEditingSummary(true);

		if (summary) {
			setEditableSummary(summary);
		}

		setTimeout(() => textareaRef.current?.focus(), 0);
	};

	const handleSummaryChange = (e: {target: {value: string}}) => {
		const newSummary = e.target.value;
		setEditableSummary(newSummary);

		if (debounceTimeout) clearTimeout(debounceTimeout);

		const timeout = setTimeout(async () => {
			setLoadingSummaryUpdate(true);

			await dispatch(updateCandidateSummary(id, newSummary));

			setLoadingSummaryUpdate(false);
		}, 800);

		setDebounceTimeout(timeout);
	};

	const handleBlur = () => {
		setEditingSummary(false);
	};

	return (
		<div className={classNames(styles.summaryBlock)}>
			<div className={styles.summaryActionWrapper}>
				<div
					className={classNames(styles.summaryTitle, {
						[styles.boldTitle]: isFromProjectSource && !isFromProjectSearchSource,
					})}
				>
					{(showGenerateButton || summary) && "Career Summary"}
				</div>

				{!summary && showGenerateButton && (
					<ActionAltButton
						key={"expand"}
						customClass={classNames(styles.generateSummaryButton, {
							[styles.loading]: generatingSummary,
						})}
						iconComponent={<MagicWandIcon color={"#4E5555"} />}
						handleClick={handleGenerateButtonClick}
						title={generatingSummary ? "Generating" : "Generate"}
						loading={generatingSummary}
					/>
				)}
			</div>

			<div className={styles.description}>
				{!summary && !generatingSummary && showGenerateButton && !editingSummary && (
					<span style={{paddingTop: "10px"}}>
						Click <span className={styles.boldTitle}>&apos;generate&apos;</span> to get
						an AI-generated summary, or click{" "}
						<span
							className={classNames(styles.boldTitle, styles.pointer)}
							onClick={handleSummaryClick}
						>
							here
						</span>{" "}
						to start typing.
					</span>
				)}
				{!generatingSummary &&
					(editingSummary ? (
						<textarea
							ref={textareaRef}
							value={editableSummary}
							onChange={handleSummaryChange}
							onBlur={handleBlur}
							placeholder={"Write your own summary..."}
							rows={expanded ? 15 : 7}
							className={classNames(styles.description, styles.editSummaryBlock)}
						/>
					) : (
						!!summary &&
						isString(summary) &&
						!generatingSummary && (
							<span onClick={handleSummaryClick}>
								{expanded ? summary : `${summary.substring(0, 270)}...`}
							</span>
						)
					))}

				{isArray(flattenedCandidateSummariesForAllProject) &&
					flattenedCandidateSummariesForAllProject?.includes(id) && (
						<ContentLoader
							style={{marginTop: 10}}
							speed={2}
							height={100}
							width={"100%"}
							backgroundColor="#EEEDEC"
							foregroundColor="#e6e6e6"
						>
							<rect x="1" y="8.8" width="99%" height="22" />
							<rect x="1" y="38.8" width="96%" height="22" />
							<rect x="1" y="68.8" width="98%" height="22" />
						</ContentLoader>
					)}
			</div>

			{loadingSummaryUpdate && <span className={styles.loadingSummaryText}>Saving...</span>}
		</div>
	);
};

export default CandidateSummary;
