import React from "react";

const PromotionIcon = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
		>
			<path d="M10 2L4.2265 12H15.7735L10 2ZM11 19V11H9V19H11Z" fill="#CC8800" />
		</svg>
	);
};

export default PromotionIcon;
