import {clearCancelToken, getCancelToken} from "api/cancelTokens";
import {
	GET_PROJECT_CANDIDATE_IDS_TOKEN,
	GET_PROJECT_SAVED_PEOPLE_TOKEN,
} from "api/requestCancelTokenStrings";
import {targetListIDSelector} from "../target-list/targetListSelectors";
import {successResponse} from "helpers/map";
import axios, {AxiosResponse} from "axios";
import {TPaginationParam} from "api/candidateApi/types";
import {TAppDispatch, TRootState} from "../../../types";
import {Dispatch} from "react";
import {additionalProfileFiltersSelector} from "../additional-profiles/additionalProfilesSelectors";
import {projectSavedPeoplePaginationSelector} from "./projectSelectors";
import {buildCandidateApiRequestPayload} from "helpers/filterHandlers";
import {ICandidateApiFilterOptions, ICandidateSearchApiRequestPayload} from "api/candidateApi/form";
import {
	getProjectCandidateIDsDone,
	getProjectCandidateIDsFail,
	getProjectCandidateIDsInit,
	projectSavedPeopleFetchCompleted,
	setProjectSavedPeopleFetchIsInProgress,
} from "./projectActions";
import mapXCandidateApi from "api/candidateApi";
import mapXTargetListApi from "api/targetListApi";
import {globalPerPageSelector} from "../userPreference/userPreferenceSelectors";

/**
 * Fetches saved people for a project based on provided filters.
 *
 * @param payload - The API request payload containing filtering options.
 */
export const getProjectSavedPeople =
	(payload: ICandidateSearchApiRequestPayload) => async (dispatch: Dispatch<TAppDispatch>) => {
		try {
			dispatch(setProjectSavedPeopleFetchIsInProgress(true));

			// Make an API request to fetch saved candidates for the project
			const response = await mapXCandidateApi.getCandidatesByFilter(payload, {
				cancelToken: getCancelToken(GET_PROJECT_SAVED_PEOPLE_TOKEN).token,
			});

			if (successResponse(response, 200)) {
				const data = response.data;
				dispatch(projectSavedPeopleFetchCompleted(data));
			}
		} catch (error) {
			if (axios.isCancel(error)) {
				console.log("Request canceled:", error.message);
			}
			// Ensure the fetch progress flag is reset in case of failure
			dispatch(setProjectSavedPeopleFetchIsInProgress(false));
		}
	};

/**
 * Fetches paginated saved people for the current project.
 *
 * @param pagination - Optional pagination parameters for customizing results.
 */
export const getPaginatedProjectSavedPeople =
	(pagination?: TPaginationParam) =>
	async (dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		try {
			const state = getState();

			// Retrieve filtering options from the Redux state
			const apFilters = additionalProfileFiltersSelector(state);
			const currentPagination = projectSavedPeoplePaginationSelector(state);
			const globalPerPageValue = globalPerPageSelector(state);

			// Construct the API request payload based on current filters
			const payload = buildCandidateApiRequestPayload({...apFilters}, true);
			const targetListID = targetListIDSelector(state);

			// Define pagination parameters, falling back to defaults if not provided
			const finalPagination = {
				page: pagination?.page ?? currentPagination.page ?? 1,
				per_page: globalPerPageValue ?? pagination?.per_page ?? 20,
			};

			if (payload) {
				// Prepare API request payload with filters, pagination, and sorting options
				const apiPayload = {
					filters: {
						...payload.filters,
						target_list: targetListID,
					},
					pagination: finalPagination,
					sorting_options: {
						...payload.sorting_options,
					},
				};

				dispatch(getProjectSavedPeople(apiPayload));
			}
		} catch (error) {
			console.error("Error in getPaginatedProjectSavedPeople:", error);
		}
	};

/**
 * Fetches all candidate IDs associated with the current project's target list.
 *
 * @param filter - Optional filter parameters to refine the candidate search.
 * @returns An array of candidate IDs or an empty array in case of failure.
 */
export const fetchProjectCandidateIDs =
	(filter?: ICandidateApiFilterOptions) =>
	async (dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const cancelTokenSource = getCancelToken(GET_PROJECT_CANDIDATE_IDS_TOKEN);

		try {
			const state = getState();
			const targetListID: number = targetListIDSelector(state);

			dispatch(getProjectCandidateIDsInit());

			const apiPayload: ICandidateSearchApiRequestPayload = {
				filters: filter ? {...filter} : {},
				pagination: {page: 1, per_page: 20000}, // We limit data up to 20,000 candidate IDs in one request
			};

			const response: AxiosResponse = await mapXTargetListApi.getTargetListCandidateIDs(
				targetListID,
				apiPayload,
				{cancelToken: cancelTokenSource.token},
			);

			if (!response || !successResponse(response, 200)) {
				dispatch(getProjectCandidateIDsFail({error: "Something went wrong"}));

				return [];
			}

			const data = response.data.results;

			dispatch(getProjectCandidateIDsDone(data));

			return data;
		} catch (error) {
			console.error("Error in fetchProjectCandidateIDs:", error);

			return [];
		} finally {
			clearCancelToken(GET_PROJECT_CANDIDATE_IDS_TOKEN);
		}
	};
