import axios, {AxiosResponse, CancelTokenSource} from "axios";
import React, {Dispatch} from "react";
import {TAppDispatch} from "types";
import {setBulkImportIdForCompany} from "./linkedInBulkImportActions";
import mapXBackgroundTaskAPI from "api/backgroundTaskApi";
import {TBackgroundTaskCreate} from "api/backgroundTaskApi/types";
import {STProject} from "api/projectApi/types";
import {STCompany} from "api/companyApi/types";
import {toast} from "react-toastify";
import {ToastContent} from "components";
import {setImportLinkedInProfileInfoForCurrentImport} from "../background-tasks/backgroundTaskActions";

const cancelTokens: {
	[key: string]: CancelTokenSource;
} = {};

/**
 * Imports LinkedIn profiles into a project.
 * Dispatches an action to store import info and triggers a notification.
 *
 * @param {string[]} linkedInLinks - Array of LinkedIn profile URLs.
 * @param {STProject} project - The project to import profiles into (optional).
 * @param type - The type of import (default: "import").
 */

export const importLinkedInProfileToProject =
	(linkedInLinks: string[], project?: STProject, type = "import") =>
	async (dispatch: Dispatch<TAppDispatch>) => {
		try {
			if (project) {
				const formBody: TBackgroundTaskCreate = {
					urls: linkedInLinks,
					project_id: project.id,
					type: "Candidates Bulk Import",
				};

				//create background task request through API
				const response = await mapXBackgroundTaskAPI.createBackgroundTaskRequest(formBody);

				if (response.status === 201) {
					// in case of bulk import, we need a toaster, no need for refresh
					if (type === "import") {
						fireToaster(project, null);
					}

					dispatch(
						setImportLinkedInProfileInfoForCurrentImport({
							...response.data,
							purpose: type,
						}),
					);
				}
			}
		} catch (error) {
			console.error("Error in importing LinkedIn profiles:", error);
		}
	};

/**
 * Imports LinkedIn profiles into a company.
 * Dispatches an action to store import info and triggers a notification.
 *
 * @param {string[]} linkedInLinks - Array of LinkedIn profile URLs.
 * @param {STCompany} company - The company to import profiles into.
 */
export const importLinkedInProfileToCompany =
	(linkedInLinks: string[], company: STCompany) => async (dispatch: Dispatch<TAppDispatch>) => {
		try {
			dispatch(setBulkImportIdForCompany(company));

			const formBody: TBackgroundTaskCreate = {
				urls: linkedInLinks,
				type: "Candidates Bulk Import",
			};

			const response = await mapXBackgroundTaskAPI.createBackgroundTaskRequest(formBody);

			if (response.status === 201) {
				// we need a toaster for company imports
				fireToaster(null, company);

				dispatch(
					setImportLinkedInProfileInfoForCurrentImport({
						...response.data,
						purpose: "import",
					}),
				);
			}
		} catch (error) {
			console.error("Error in importing LinkedIn profiles:", error);
		}
	};

/**
 * Fetches the status of an individual bulk import request.
 * Uses cancel tokens to cancel previous status requests if a new one is made.
 *
 * @param {number} importId - The ID of the bulk import process.
 * @returns Import status or error.
 */
export const getIndividualBulkImportStatuses = (importId: number) => async () => {
	const requestKey = `GET_IMPORT_LINKEDIN_URL_STATUS_${importId}`;

	if (requestKey in cancelTokens) {
		cancelTokens[requestKey].cancel("Operation canceled due to new request.");
	}

	cancelTokens[requestKey] = axios.CancelToken.source();

	const config = {
		cancelToken: cancelTokens[requestKey].token,
	};

	try {
		const response: AxiosResponse = await mapXBackgroundTaskAPI.getBackgroundTasksById(
			importId,
			config,
		);

		return {
			status: response.data.status,
			data: response.data,
		};
	} catch (error) {
		return {error};
	}
};

/**
 * Displays a toast notification when a bulk import process starts.
 *
 * @param {STProject | null} project - The project where import is happening (optional).
 * @param {STCompany | null} company - The company where import is happening (optional).
 */
const fireToaster = (project: Nullable<STProject>, company: Nullable<STCompany>) => {
	const id = project?.id ?? 0;
	const name = project ? project.name : company?.name;

	const title = `The candidate import process has started  ${name ? "for" : ""} ${
		project ? " Project: " : " Company: "
	} ${name}`;
	const description = React.createElement(
		"div",
		null,
		React.createElement("p", null, "You will receive a notification when it is done"),
	);

	toast.success(ToastContent, {
		toastId: `import_started_toaster${id}`,
		autoClose: false,
		closeOnClick: false,
		data: {
			title,
			description,
		},
		delay: 100,
	});
};
