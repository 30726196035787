import axios, {CanceledError, CancelTokenSource} from "axios";
import {
	getSearchedCompaniesDone,
	getSearchedCompaniesFail,
	getSearchedCompaniesInit,
} from "store/mapx/filter/filterActions";
import mapXCompanyApi from "api/companyApi";

import {TAppDispatch, TRootState} from "types";
import {
	aiCompanyIdeaFilterModeSelector,
	selectedCompaniesSelector,
} from "store/mapx/filter/filterSelectors";
import {STSpecialtyCluster} from "mapx-components/Filters/CompanySpecialtyClusterSearchFilter/types";
import {fetchAndAddSpecialityClusters} from "../companySpecialtyClusterFilterAsyncActions";
import {addSpecialityClustersToList} from "../../search/searchActions";
import type {STCompany} from "api/companyApi/types";
import {STSpecialty} from "api/filterOptionsApi/SpecialityApi/types";
import {
	specialtyClusterOptionsFromCompaniesSelector,
	specialtyClusterOptionsSelector,
} from "../../search/searchSelectors";

const cancelTokens: {[key: string]: CancelTokenSource} = {};
export const getSearchedCompaniesData =
	(name: string, page = 1) =>
	async (dispatch: TAppDispatch) => {
		const requestKey = "SEARCH_COMPANY_BY_NAME";

		if (cancelTokens[requestKey]) {
			cancelTokens[requestKey].cancel("Operation canceled due to new request.");
		}

		cancelTokens[requestKey] = axios.CancelToken.source();

		const config = {
			cancelToken: cancelTokens[requestKey].token,
		};

		dispatch(getSearchedCompaniesInit());

		let response;

		try {
			if (name !== null) {
				const payload = {
					filters: {
						name: name,
					},
					pagination: {
						page: page,
						per_page: 20,
					},
				};

				response = await mapXCompanyApi.fetchCompaniesByFilter(payload, config);

				if (response == null) {
					throw new CanceledError("Operation canceled due to new request.");
				} else if (response.status === 200) {
					dispatch(
						getSearchedCompaniesDone({
							results: response.data.results,
							pagination: response.data.pagination,
						}),
					);
				}
			}
		} catch (error) {
			dispatch(getSearchedCompaniesFail({error}));

			return {error};
		}
	};

export const existingCluster = (item: STSpecialty, state: TRootState) => {
	const specialtyClusterOptions = specialtyClusterOptionsSelector(state);

	const selectedCompaniesSpecialties = specialtyClusterOptionsFromCompaniesSelector(state);

	return [...specialtyClusterOptions, ...selectedCompaniesSpecialties]?.find(
		(option) => option.id === item.cluster?.id,
	);
};

export const setSpecialtiesParentInListFromCompaniesByAICompanyIdea =
	() => async (dispatch: TAppDispatch, getState: TRootState) => {
		try {
			const state = getState();

			const aiCompanyIdeaFilterMode = aiCompanyIdeaFilterModeSelector(state);
			const selectedCompanies: STCompany[] = selectedCompaniesSelector(state);

			//if aiCompanyIdeaFilterMode is suggested, we need to add the specialties of the found companies to the specialty cluster filter state
			if (aiCompanyIdeaFilterMode === "suggested") {
				const clusterNames = new Set<string>();

				const existingClusters = new Set<STSpecialtyCluster>();

				// we don't have all the parents of the specialties selected in state, but, all specialty should display the whole cluster
				// in company specialty search filter. hence, we are checking if the cluster exists in the list for all the selected companies
				selectedCompanies?.map((company) => {
					company.specialties?.forEach((specialty) => {
						if (!existingCluster(specialty, state)) {
							if (specialty.cluster?.name) {
								clusterNames.add(specialty.cluster.name);
							}
						} else {
							existingClusters.add(existingCluster(specialty, state));
						}
					});
				});

				//existingClusters not found, we fetch and add that cluster to the checkbox state
				if (clusterNames.size > 0) {
					dispatch(fetchAndAddSpecialityClusters(Array.from(clusterNames)));
				}

				//existingClusters found, we just add that cluster to the checkbox state
				if (existingClusters.size > 0) {
					dispatch(addSpecialityClustersToList(Array.from(existingClusters)));
				}
			}
		} catch (error) {
			return {error};
		}
	};
