import {TCandidateFilterOptions} from "api/marketReportApi/types";
import {MarketReportFunnelGroup} from "mapx-pages/MarketReportDetails/CriteriaBreakDown/type";
import {LogicConnector} from "store/mapx/market-report/types";

export const HIERARCHY = [
	"market_size",
	"companies",
	"current_industries",
	"previous_industries",
	"industries",
	"previous_specialisms",
	"specialisms",
	"current_keywords",
	"previous_keywords",
	"keywords",
	"current_company_size",
	"previous_company_size",
	"company_size",
	"current_revenue",
	"previous_revenue",
	"revenue",
	"current_company_type",
	"previous_company_type",
	"company_type",
	"previous_locations",
	"locations",
	"languages",
	"deal_experience",
];

const HIERARCHY_FILTER_MAP: Record<string, (keyof TCandidateFilterOptions)[]> = {
	market_size: [
		"current_job_titles_or",
		"current_seniority",
		"current_specialisms_and",
		"current_specialisms_or",
		"current_specialisms_not",
		"current_countries_or",
		"current_regions_or",
	],
	companies: ["companies", "current_companies", "previous_companies"],
	current_industries: ["current_industries"],
	previous_industries: ["previous_industries"],
	industries: ["industries"],
	previous_specialisms: [
		"previous_specialisms_and",
		"previous_specialisms_or",
		"previous_specialisms_not",
	],
	specialisms: ["specialisms_and", "specialisms_or", "specialisms_not"],
	current_keywords: [
		"current_text_keywords_or",
		"current_text_keywords_and",
		"current_text_keywords_not",
		"previous_text_keywords_or",
		"previous_text_keywords_and",
		"previous_text_keywords_not",
	],
	previous_keywords: [
		"previous_text_keywords_or",
		"previous_text_keywords_and",
		"previous_text_keywords_not",
	],
	keywords: ["text_keywords_or", "text_keywords_and", "text_keywords_not"],
	current_company_size: ["current_company_size"],
	previous_company_size: ["previous_company_size"],
	company_size: ["company_size"],
	current_revenue: ["current_revenue"],
	previous_revenue: ["previous_revenue"],
	revenue: ["revenue"],
	current_company_type: ["current_company_type"],
	previous_company_type: ["previous_company_type"],
	company_type: ["company_type"],
	previous_locations: ["previous_countries_or", "previous_regions_or"],
	locations: ["countries_or", "regions_or"],
	languages: ["languages"],
	deal_experience: ["company_events"],
};

export function createFunnelGroups(
	filters: TCandidateFilterOptions,
	hierarchy: string[],
): MarketReportFunnelGroup[] {
	const funnelGroups: MarketReportFunnelGroup[] = [];
	const accumulatedKeys = new Set<keyof TCandidateFilterOptions>();

	for (const level of hierarchy) {
		const levelKeys = HIERARCHY_FILTER_MAP[level] || [];
		levelKeys.forEach((key) => accumulatedKeys.add(key));

		const currentFilters: Partial<TCandidateFilterOptions> = {};

		levelKeys.forEach((key) => {
			const value = filters[key];

			if (Array.isArray(value) && value.length > 0) {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				currentFilters[key] = value;
			} else if (
				key === "company_events" &&
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				Array.isArray(value?.types) &&
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				value?.types?.length > 0
			) {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				currentFilters[key] = value;
			}
		});

		if (Object.keys(currentFilters).length > 0) {
			funnelGroups.push({
				hierarchyLevel: level,
				filters: currentFilters,
			});
		}
	}

	return funnelGroups;
}

export function extractLogicConnector(filters: Partial<TCandidateFilterOptions>): LogicConnector {
	const logicConnectors = ["_or", "_and", "_not"];
	let foundConnector: LogicConnector = null;

	for (const [key, value] of Object.entries(filters)) {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		if (value?.length > 0) {
			// Extract the logic connector from the key
			const connector = logicConnectors.find((connector) => key.endsWith(connector));

			if (connector) {
				const formattedConnector = connector
					.toUpperCase()
					.replace("_", "") as LogicConnector;

				// If multiple filters have values, return null
				if (foundConnector !== null) {
					return null;
				}

				foundConnector = formattedConnector;
			} else {
				// If no logic connector is found, return null
				return null;
			}
		}
	}

	return foundConnector;
}
