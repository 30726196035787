import mapXCompanyApi from "api/companyApi";
import {successResponse} from "helpers/map";
import {isValidId} from "helpers/string";
import qs from "qs";

import {
	companiesOrgChartFiltersSelector,
	companiesPaginationInfoSelector,
	companiesSelector,
	companySelector,
} from "store/mapx/company/companySelectors";
import {setEnhanceCompaniesResult} from "store/mapx/filter/filterActions";
import {showEnhancedCompaniesSelector} from "store/mapx/filter/filterSelectors";
import {
	addPaginatedDataForCompanyOrganizationalChart,
	getCompaniesByFilterDone,
	getCompaniesByFilterFail,
	getCompaniesByFilterInit,
	getCompaniesDone,
	getCompaniesFail,
	getCompaniesInit,
	getCompanyOrgChartFiltering,
	getSimilarCompaniesDone,
	getSimilarCompaniesFail,
	getSimilarCompaniesInit,
	getSingleCompanyDone,
	getSingleCompanyFail,
	getSingleCompanyInit,
	initCompanyCompanyLocationReport,
	setCompanyCompanyLocationReport,
	setCompanyEthnicDiversityData,
	setCompanyEthnicDiversityInit,
	setCompanyFrequentlyHiredFromData,
	setCompanyFunctionalBackground,
	setCompanyGenderDiversityData,
	setCompanyGenderDiversityInit,
	setCompanyIndustryBackground,
	setCompanyOrganizationalChart,
	setCompanyOrgChartPaginationLoading,
	setCompanyTrendsStats,
	setCompanyTrendStatsInit,
	setMapThisFunctionDataForCurrentCompany,
	setMapThisFunctionIsInProgressForCurrentCompany,
	updateTooltipCompanies,
	updateTooltipCompaniesFailed,
	updateTooltipCompaniesProgress,
} from "./companyActions";
import {TAppDispatch, TRootState} from "types";
import {
	SIOrgChartProps,
	STCompany,
	STCompanyLocationReportItem,
	TCompanyMapItem,
	TFilterCompanyPayload,
	TReportParams,
} from "api/companyApi/types";
import {buildQueryParameter} from "helpers/filterHandlers";
import {getCancelToken} from "api/cancelTokens";
import {
	GET_COMPANY_ETHNICITY_REPORT_TOKEN,
	GET_COMPANY_FHF_REPORT_TOKEN,
	GET_COMPANY_GENDER_REPORT_TOKEN,
	GET_COMPANY_INDUSTRIES_REPORT_TOKEN,
	GET_COMPANY_LOCATION_REPORT_TOKEN,
	GET_COMPANY_MAP_HISTORY_TOKEN,
	GET_COMPANY_ORG_CHART_REPORT_TOKEN,
	GET_COMPANY_SPECIALISMS_REPORT_TOKEN,
	GET_COMPANY_TOOLTIP_TOKEN,
	GET_COMPANY_TRENDS_STATS_REPORT_TOKEN,
} from "api/requestCancelTokenStrings";
import {AxiosResponse} from "axios";
import {TPaginationParam} from "api/candidateApi/types";
import {globalPerPageSelector} from "../userPreference/userPreferenceSelectors";

export const getIndustryBackground =
	(companyId: number, params: TReportParams = {}) =>
	async (dispatch: TAppDispatch) => {
		const response: AxiosResponse = await mapXCompanyApi.getCompanyIndustryBackground(
			String(companyId),
			params,
			{
				cancelToken: getCancelToken(GET_COMPANY_INDUSTRIES_REPORT_TOKEN).token,
			},
		);

		if (successResponse(response, 200)) {
			dispatch(setCompanyIndustryBackground(response.data.results));
		}
	};

export const getFunctionalBackground =
	(companyId: number, params: TReportParams = {}) =>
	async (dispatch: TAppDispatch) => {
		try {
			const response: AxiosResponse = await mapXCompanyApi.getCompanyFunctionalBackground(
				String(companyId),
				params,
				{
					cancelToken: getCancelToken(GET_COMPANY_SPECIALISMS_REPORT_TOKEN).token,
				},
			);

			if (successResponse(response, 200)) {
				dispatch(setCompanyFunctionalBackground(response.data.results));
			}
		} catch (error) {
			return {error};
		}
	};

export const getCompaniesData =
	(pagination: TPaginationParam = {page: 1, per_page: 20}, config = {}) =>
	async (dispatch: TAppDispatch, getState: TRootState) => {
		const state = getState();

		const currentPagination = companiesPaginationInfoSelector(state);
		const globalPerPageValue = globalPerPageSelector(state);

		const finalPagination = {
			page: pagination?.page ?? currentPagination?.page ?? 1,
			per_page: globalPerPageValue ?? pagination?.per_page ?? 20,
		};

		dispatch(getCompaniesInit());

		const showEnhancedCompanies = showEnhancedCompaniesSelector(state);

		if (showEnhancedCompanies) {
			dispatch(setEnhanceCompaniesResult(false));
		}

		try {
			const response = await mapXCompanyApi.fetchCompanies(pagination, config);

			let payload;

			if (response) {
				payload = {
					data: response.status === 200 ? response.data.results : [],
					paginationInfo: response.data.pagination,
					pageNumber: finalPagination.page,
					perPage: finalPagination.per_page,
				};

				dispatch(getCompaniesDone(payload));

				// dispatch(onSuccessCompaniesResultResponse(response, payload));
			} else {
				const companies = companiesSelector(state);

				payload = {
					data: companies,
					paginationInfo: null,
					pageNumber: 1,
					perPage: finalPagination.per_page,
					cancelled: true,
				};

				dispatch(getCompaniesByFilterDone(payload));
			}

			return payload;

			//@todo send seen data information to BE
		} catch (error) {
			dispatch(getCompaniesFail({error}));

			return {error};
		}
	};

export const getFilteredCompaniesData =
	(payload: TFilterCompanyPayload, pagination?: TPaginationParam, config = {}) =>
	async (dispatch: TAppDispatch, getState: TRootState) => {
		const state = getState();

		const currentPagination = companiesPaginationInfoSelector(state);
		const globalPerPageValue = globalPerPageSelector(state);

		const finalPagination = {
			page: pagination?.page ?? currentPagination?.page ?? 1,
			per_page: globalPerPageValue ?? pagination?.per_page ?? 20,
		};

		dispatch(getCompaniesByFilterInit());

		const apiPayload = {
			...payload,
			pagination: finalPagination,
		};

		try {
			const response = await mapXCompanyApi.fetchCompaniesByFilter(apiPayload, config);

			let companiesFilterDonePayload;

			if (response) {
				companiesFilterDonePayload = {
					data: response.data.results,
					paginationInfo: response.data.pagination,
					pageNumber: finalPagination.page,
					perPage: finalPagination.per_page,
				};

				dispatch(getCompaniesByFilterDone(companiesFilterDonePayload));

				// dispatch(onSuccessCompaniesResultResponse(response, payload, queryString));
			}
			// else {
			// 	const companies: STCompany[] = companiesSelector(state);
			//
			// 	const data = {
			// 		data: companies,
			// 		paginationInfo: null,
			// 		pageNumber: 1,
			// 		perPage: finalPagination.per_page,
			// 		cancelled: true,
			// 	};
			//
			// 	dispatch(getCompaniesByFilterDone(data));
			// }

			return response;

			//@todo send seen data information to BE
		} catch (error) {
			dispatch(getCompaniesByFilterFail({error}));
			console.log(error);

			return {error};
		}
	};

export const getSingleCompanyData = (companyID: number) => async (dispatch: TAppDispatch) => {
	try {
		dispatch(getSingleCompanyInit());

		if (!isValidId(companyID)) {
			window.location.href = "/404";

			throw new Error("CompanyDetails is not exist");
		}

		const {data, status} = await mapXCompanyApi.getCompany(String(companyID));

		if (status === 403) {
			window.location.href = "/403";

			throw new Error("You don't have permission to access this company.");
		}

		if (status === 404) {
			window.location.href = "/404";

			throw new Error("CompanyDetails is not exist");
		}

		dispatch(getSingleCompanyDone(data));
	} catch (error) {
		dispatch(getSingleCompanyFail({error}));

		return {error};
	}
};

export const getSimilarCompaniesData =
	(queryString: string, isItForCompanyDetails = false, config = {}) =>
	async (dispatch: TAppDispatch) => {
		dispatch(getSimilarCompaniesInit());

		try {
			const parseQuery = qs.parse(queryString.substring(1)); //removes ? from string;

			if (parseQuery.page) {
				parseQuery.page = "1";
				queryString = qs.stringify(parseQuery);
				queryString = "?" + queryString;
			}

			const perPage = isItForCompanyDetails ? 5 : 6;

			const response: AxiosResponse = await mapXCompanyApi.getSimilarCompanies(
				queryString,
				perPage,
				config,
			);

			const payload =
				response.status === 200
					? response.data.results.map((item: STCompany) => {
							item.recommended = true;

							return item;
					  })
					: [];

			dispatch(getSimilarCompaniesDone(payload));

			// if (!isItForCompanyDetails) {
			// 	dispatch(onSuccessCompaniesResultResponse(response, payload, queryString));
			// }
		} catch (error) {
			dispatch(getSimilarCompaniesFail({error}));

			return {error};
		}
	};

export const getCompanyGenderDiversityDataFilter =
	(id: number, params: TReportParams = {}) =>
	async (dispatch: TAppDispatch) => {
		let data;

		try {
			const response = await mapXCompanyApi.getCompanyGenderDiversity(String(id), params, {
				cancelToken: getCancelToken(GET_COMPANY_GENDER_REPORT_TOKEN).token,
			});

			if (response && response?.status === 200) {
				data = response.data;
			} else {
				data = [];
			}

			dispatch(setCompanyGenderDiversityData(data));
		} catch (e) {
			console.error(e);
		}
	};

export const getCompanyGenderDiversityData = (id: number) => async (dispatch: TAppDispatch) => {
	let data;

	try {
		dispatch(setCompanyGenderDiversityInit());

		const response = await mapXCompanyApi.getCompanyGenderDiversity(
			String(id),
			{},
			{
				cancelToken: getCancelToken(GET_COMPANY_GENDER_REPORT_TOKEN).token,
			},
		);

		if (response && response?.status === 200) {
			data = response.data;
		} else {
			data = [];
		}

		dispatch(setCompanyGenderDiversityData(data));
	} catch (e) {
		console.error(e);
	}
};

export const getCompanyTrendStatsFilter =
	(id: number, params: TReportParams = {}) =>
	async (dispatch: TAppDispatch) => {
		let data;

		try {
			const response = await mapXCompanyApi.getCompanyTrendStats(String(id), params, {
				cancelToken: getCancelToken(GET_COMPANY_TRENDS_STATS_REPORT_TOKEN).token,
			});

			if (response && response?.status === 200) {
				data = response.data;
			} else {
				data = [];
			}

			dispatch(setCompanyTrendsStats(data));
		} catch (e) {
			console.error(e);
		}
	};

export const getCompanyTrendStats = (id: number) => async (dispatch: TAppDispatch) => {
	let data;

	dispatch(setCompanyTrendStatsInit());

	try {
		const response = await mapXCompanyApi.getCompanyTrendStats(
			String(id),
			{},
			{
				cancelToken: getCancelToken(GET_COMPANY_TRENDS_STATS_REPORT_TOKEN).token,
			},
		);

		if (response && response?.status === 200) {
			data = response.data;
		} else {
			data = [];
		}

		dispatch(setCompanyTrendsStats(data));
	} catch (e) {
		console.error(e);
	}
};

export const getCompanyOrganizationalChartFilter =
	(companyId: number, params: TReportParams = {}) =>
	async (dispatch: TAppDispatch) => {
		dispatch(getCompanyOrgChartFiltering(true));

		try {
			const response: AxiosResponse = await mapXCompanyApi.getCompanyOrganizationalChart(
				String(companyId),
				params,
				{
					cancelToken: getCancelToken(GET_COMPANY_ORG_CHART_REPORT_TOKEN).token,
				},
			);

			if (response && response?.status === 200) {
				dispatch(setCompanyOrganizationalChart(response.data.results));
			}
		} catch (e) {
			console.error(e);
		} finally {
			dispatch(getCompanyOrgChartFiltering(false));
		}
	};

export const getCompanyOrganizationalChart =
	(companyId: number) => async (dispatch: TAppDispatch) => {
		try {
			dispatch(getCompanyOrgChartFiltering(true));

			const response: AxiosResponse = await mapXCompanyApi.getCompanyOrganizationalChart(
				String(companyId),
				{},
				{
					cancelToken: getCancelToken(GET_COMPANY_ORG_CHART_REPORT_TOKEN).token,
				},
			);

			if (response && response?.status === 200) {
				dispatch(setCompanyOrganizationalChart(response?.data?.results));
			}
		} catch (e) {
			console.error(e);
		} finally {
			dispatch(getCompanyOrgChartFiltering(false));
		}
	};

// THIS METHOD IS FOR 2nd PAGE FULL DATA
export const getPaginatedDataForCompanyOrgChart =
	(seniorityTitle: string, pageNumber = 1) =>
	async (dispatch: TAppDispatch, getState: TRootState) => {
		try {
			dispatch(setCompanyOrgChartPaginationLoading(true));

			const state = getState();

			const company: STCompany = companySelector(state);

			const filters = companiesOrgChartFiltersSelector(state);

			const paramsObject = {...filters};

			if (paramsObject.current_seniority?.length > 0) {
				paramsObject.current_seniority = [...paramsObject.current_seniority].filter(
					(s) => s === seniorityTitle,
				);
			} else {
				paramsObject.current_seniority = [seniorityTitle];
			}

			const params = {
				...paramsObject,
				page: pageNumber, // even though its page 1, we will skip 1st 40
				per_page: 1000,
			};

			const response: AxiosResponse = await mapXCompanyApi.getCompanyOrganizationalChart(
				String(company.id),
				params,
			);

			if (response && response?.status === 200 && response?.data) {
				const data = response.data.results;

				const paginatedData = data.find(
					(item: SIOrgChartProps) => item.seniority === seniorityTitle,
				);

				dispatch(addPaginatedDataForCompanyOrganizationalChart(paginatedData));
			}
		} catch (e) {
			console.error(e);
		} finally {
			dispatch(setCompanyOrgChartPaginationLoading(false));
		}
	};

export const getCompanyEthnicDiversityDataFilter =
	(id: number, params: TReportParams = {}) =>
	async (dispatch: TAppDispatch) => {
		let data;

		try {
			const response = await mapXCompanyApi.getCompanyEthnicDiversity(String(id), params, {
				cancelToken: getCancelToken(GET_COMPANY_ETHNICITY_REPORT_TOKEN).token,
			});

			if (response && response?.status === 200) {
				data = response.data;
			} else {
				data = [];
			}

			dispatch(setCompanyEthnicDiversityData(data));
		} catch (e) {
			console.error(e);
		}
	};

export const getCompanyEthnicDiversityData = (id: number) => async (dispatch: TAppDispatch) => {
	let data;

	try {
		dispatch(setCompanyEthnicDiversityInit());

		const response = await mapXCompanyApi.getCompanyEthnicDiversity(
			String(id),
			{},
			{
				cancelToken: getCancelToken(GET_COMPANY_ETHNICITY_REPORT_TOKEN).token,
			},
		);

		if (response && response?.status === 200) {
			data = response.data;
		} else {
			data = [];
		}

		dispatch(setCompanyEthnicDiversityData(data));
	} catch (e) {
		console.error(e);
	}
};

export const getCompanyFrequentlyHiredFromDataFilter =
	(id: number, params: TReportParams = {}) =>
	async (dispatch: TAppDispatch) => {
		try {
			const response = await mapXCompanyApi.getCompanyFrequentlyHiredFrom(
				String(id),
				1,
				params,
				{
					cancelToken: getCancelToken(GET_COMPANY_FHF_REPORT_TOKEN).token,
				},
			);

			if (response && response?.status === 200) {
				dispatch(setCompanyFrequentlyHiredFromData(response.data));
			} else {
				dispatch(
					setCompanyFrequentlyHiredFromData({
						results: [],
						pagination: {
							page: 1,
						},
					}),
				);
			}
		} catch (e) {
			console.error(e);
		}
	};

export const getCompanyFrequentlyHiredFromData =
	(id: string, callback?: () => void, page = 1, params: TReportParams = {}) =>
	async (dispatch: TAppDispatch) => {
		try {
			const response = await mapXCompanyApi.getCompanyFrequentlyHiredFrom(
				String(id),
				page,
				params,
				{
					cancelToken: getCancelToken(GET_COMPANY_FHF_REPORT_TOKEN).token,
				},
			);

			if (response && response?.status === 200) {
				dispatch(setCompanyFrequentlyHiredFromData(response.data));
			} else {
				dispatch(setCompanyFrequentlyHiredFromData([]));
			}
		} catch (e) {
			console.error(e);
		} finally {
			callback && callback();
		}
	};

// MAP THIS FUNCTION ASYNC ACTIONS

export const getMapFunctionHistoryByCompanyId =
	(companyId: number) => async (dispatch: TAppDispatch) => {
		try {
			const response: AxiosResponse = await mapXCompanyApi.getCompanyMapFunctionHistory(
				String(companyId),
				{
					cancelToken: getCancelToken(GET_COMPANY_MAP_HISTORY_TOKEN).token,
				},
			);

			if (response && response?.status === 200) {
				const companyMapData = response.data.results;

				if (companyMapData?.length > 0) {
					const latestInProgressCompanyMap = companyMapData.find(
						(item: TCompanyMapItem) => item.status === "In Progress",
					);

					if (latestInProgressCompanyMap) {
						dispatch(
							setMapThisFunctionDataForCurrentCompany({
								companyId: companyId,
								data: latestInProgressCompanyMap,
							}),
						);
					} else {
						const latestCompletedMap = companyMapData.find(
							(item: TCompanyMapItem) => item.status === "Completed",
						);

						if (latestCompletedMap) {
							dispatch(
								setMapThisFunctionDataForCurrentCompany({
									companyId: companyId,
									data: latestCompletedMap,
								}),
							);

							await dispatch(getCompanyMapFunctionStatusById(latestCompletedMap.id));
						}
					}
				}
			}
		} catch (error) {
			console.log(error, "Error from map this history for company");
		}
	};

export const getCompanyMapFunctionStatusById =
	(mapId: number) => async (dispatch: TAppDispatch) => {
		try {
			const response: AxiosResponse = await mapXCompanyApi.getCompanyMapFunctionById(
				String(mapId),
			);

			if (response?.status === 200) {
				const data = response.data;

				dispatch(
					setMapThisFunctionDataForCurrentCompany({
						companyId: data.company_id,
						data,
					}),
				);

				if (
					data.status === "Canceled" ||
					data.status === "Completed" ||
					data.status === "Error"
				) {
					dispatch(
						setMapThisFunctionIsInProgressForCurrentCompany({
							companyId: data.company_id,
							completed: true,
						}),
					);
				} else {
					dispatch(
						setMapThisFunctionIsInProgressForCurrentCompany({
							companyId: data.company_id,
							completed: false,
						}),
					);
				}

				return {
					data,
					status: data.status,
					company_id: data.company_id,
					error: false,
				};
			}
		} catch (error) {
			return {error};
		}
	};

export const updateTooltipCompaniesList =
	(companyId: string) => async (dispatch: TAppDispatch, getState: TRootState) => {
		const {tooltipCompanies} = getState().company;

		const alreadyStoredCompany = tooltipCompanies && tooltipCompanies[companyId];

		if (!alreadyStoredCompany) {
			dispatch(updateTooltipCompaniesProgress());

			try {
				const {data} = await mapXCompanyApi.getCompany(String(companyId), {
					cancelToken: getCancelToken(GET_COMPANY_TOOLTIP_TOKEN).token,
				});

				if (data) {
					dispatch(
						updateTooltipCompanies({
							...tooltipCompanies,
							[companyId]: data,
						}),
					);
				}
			} catch (error) {
				dispatch(updateTooltipCompaniesFailed());
				console.log(error, "error");
			}
		}
	};

export const getCompanyLocationReportData =
	(companyId: number, params: TReportParams = {}) =>
	async (dispatch: TAppDispatch) => {
		dispatch(initCompanyCompanyLocationReport(true));

		const payload = {
			...params,
			company_id: companyId,
		};

		const stringParameters = buildQueryParameter(payload) as string;

		try {
			const response: AxiosResponse = await mapXCompanyApi.getCompanyLocationReport(
				companyId,
				stringParameters,
				{
					cancelToken: getCancelToken(GET_COMPANY_LOCATION_REPORT_TOKEN).token,
				},
			);

			if (successResponse(response, 200)) {
				const data: STCompanyLocationReportItem[] = response.data.results;

				dispatch(setCompanyCompanyLocationReport(data));
			}
		} catch (e) {
			console.error(`getCompanyLocationReportData: ${e}`);

			dispatch(initCompanyCompanyLocationReport(false));
		}
	};
