import React, {useCallback, useEffect, useMemo} from "react";
import styles from "./styles.module.scss";
import {BiTrash} from "react-icons/bi";
import Table from "components/Table";
import {sortOption, statusOption} from "mapx-pages/Project/Share/ProjectReportTable/types";
import DragIcon from "mapx-pages/Project/Share/Icons/dragIcon";

import {ArrowDown} from "assets/icons";
import {Dropdown, Loader} from "components";
import {TProjectCandidateOrdering} from "api/candidateApi/form";
import {
	fetchingProjectCandidateStatusesSelector,
	projectCandidatesStatusesSelector,
	projectProgressStatusesSelector,
	projectReportTableRowsSelector,
} from "store/mapx/project/projectSelectors";
import {useAppDispatch, useAppSelector} from "hooks";
import {setProjectReportTableRows} from "store/mapx/project/projectActions";
import {
	ProjectPowerPointTemplateSelection,
	STProjectProgressStatus,
	TPresentationSlide,
	TProjectCandidateStatus,
	TProjectCandidateStatusList,
	TProjectReportPowerpointSlideType,
} from "api/projectApi/types";
import classNames from "classnames";
import {useSelector} from "react-redux";
import {useStatusCountMap} from "../../utils";

const SORT_OPTIONS: sortOption[] = [
	{value: "full_name", name: "Full Name A to Z"},
	{value: "-full_name", name: "Full Name Z to A"},
	{value: "company_name", name: "Company A to Z"},
	{value: "-company_name", name: "Company Z to A"},
	{value: "-is_recommended", name: "Recommended"},
	{value: "-added_to_project_date", name: "Recently Added"},
];

const STATUS_OPTIONS: statusOption[] = [
	{id: "recommended", name: "Recommended Profiles"},
	{id: "secondary", name: "Other Profiles"},
	{id: "all", name: "All Profiles"},
];

const ProjectReportTable = ({availableSlides}: {availableSlides: TPresentationSlide[]}) => {
	const dispatch = useAppDispatch();

	const progressStatuses: STProjectProgressStatus[] = useAppSelector(
		projectProgressStatusesSelector,
	);

	const projectCandidateStatuses: TProjectCandidateStatusList = useAppSelector(
		projectCandidatesStatusesSelector,
	);

	const projectCandidateStatusesInProgress = useAppSelector(
		fetchingProjectCandidateStatusesSelector,
	);

	const reportRows: ProjectPowerPointTemplateSelection[] = useAppSelector(
		projectReportTableRowsSelector,
	);

	const projectCandidateStatusCount = useSelector(projectCandidatesStatusesSelector);

	const statusCountMap = useStatusCountMap(projectCandidateStatusCount);

	const displayedStatusOptions = useMemo(() => {
		const mappedProgressStatuses = progressStatuses.map((status) => ({
			id: status.name,
			name: status.name,
			count: statusCountMap?.[status.name],
		}));

		return progressStatuses.length > 0
			? [
					...STATUS_OPTIONS.map((option) => ({
						...option,
						count: statusCountMap?.[option.id] ?? 0,
					})),
					...mappedProgressStatuses,
			  ]
			: STATUS_OPTIONS.map((option) => ({
					...option,
					count: statusCountMap?.[option.id] ?? 0,
			  }));
	}, [progressStatuses, statusCountMap]);

	const handleDragStart = (event: React.DragEvent<HTMLDivElement>, index: number) => {
		event.dataTransfer.setData("colIndex", index.toString());
	};

	const handleDrop = useCallback(
		(event: React.DragEvent<HTMLDivElement>, dropIndex: number) => {
			const dragIndex = event.dataTransfer.getData("colIndex");

			if (dragIndex === null) return;

			const dragIndexNum = parseInt(dragIndex, 10);

			const updatedReportRows = [...reportRows];

			const [draggedColumn] = updatedReportRows.splice(dragIndexNum, 1); // Remove the dragged column

			updatedReportRows.splice(dropIndex, 0, draggedColumn); // Insert the dragged column at the drop position

			dispatch(setProjectReportTableRows(updatedReportRows));
		},
		[dispatch, reportRows],
	);

	const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault(); // Necessary to allow the drop
	};

	const handleDeleteClick = useCallback(
		(colIndex: number) => {
			const updatedReportRows = [...reportRows].filter((_, i) => colIndex !== i);

			dispatch(setProjectReportTableRows([...updatedReportRows]));
		},
		[dispatch, reportRows],
	);

	const handleDropdownChange = useCallback(
		(
			colIndex: number,
			attribute: keyof ProjectPowerPointTemplateSelection,
			value: ProjectPowerPointTemplateSelection[typeof attribute],
		) => {
			const updatedReportRows = reportRows.map((item, i) =>
				i === colIndex ? {...item, [attribute]: value} : item,
			);

			dispatch(setProjectReportTableRows([...updatedReportRows]));
		},
		[dispatch, reportRows],
	);

	useEffect(
		() => {
			if (availableSlides?.length > 0) {
				const updatedReportRows = [...reportRows].map((item) => {
					if (!availableSlides.find((s) => s.name === item.slide_type)) {
						item.slide_type = availableSlides[0].name;
					}

					return item;
				});

				dispatch(setProjectReportTableRows([...updatedReportRows]));
			}
		},
		[availableSlides, dispatch], //eslint-disable-line
	);

	const headers = useMemo(
		() => [
			{
				element: "",
				style: {width: "40px"},
			},
			{
				element: `Priority`,
				style: {width: "80px"},
			},
			{
				element: "Status Name",
				style: {width: "280px"},
			},
			{
				element: "Slide Type",
				style: {width: "220px"},
			},
			{
				element: "Order",
				style: {width: "244px"},
			},
			{
				element: "Assigned Candidates",
				style: {width: "144px"},
			},
			{
				element: "",
				style: {width: "40px"},
			},
		],
		[],
	);

	const getSelectedStatusName = useCallback(
		(selectedStatus: TProjectCandidateStatus) => {
			return displayedStatusOptions.find((option) => option.id === selectedStatus)?.name;
		},
		[displayedStatusOptions],
	);

	const getSelectedSlideName = useCallback(
		(selectedTemp: TProjectReportPowerpointSlideType) => {
			return (
				availableSlides.find((option) => option.name === selectedTemp)?.title ||
				"Select Slide Type"
			);
		},
		[availableSlides],
	);

	const getSelectedSortName = useCallback((selectedSort: TProjectCandidateOrdering) => {
		return SORT_OPTIONS.find((option) => option.value === selectedSort)?.name;
	}, []);

	const getAssignedCandidatesCount = useCallback(
		(selectedStatus: TProjectCandidateStatus) => {
			if (selectedStatus === "recommended") {
				return (
					projectCandidateStatuses.find((item) => item.status === selectedStatus)
						?.count || 0
				);
			} else {
				return (
					projectCandidateStatuses.find(
						(item) => item.status === selectedStatus && item.status !== "recommended",
					)?.count || 0
				);
			}
		},
		[projectCandidateStatuses],
	);

	const rows = useMemo(
		() =>
			reportRows.map((rows: ProjectPowerPointTemplateSelection, colIndex) => {
				return {
					id: colIndex,
					cells: [
						<div
							className={styles.tableCell}
							key={colIndex}
							draggable
							data-testid={`drag-project-share-dropdown-option-${colIndex}`}
							onDragStart={(e) => handleDragStart(e, colIndex)}
							onDragOver={handleDragOver}
							onDrop={(e) => handleDrop(e, colIndex)}
							style={{cursor: "move"}}
						>
							<DragIcon />
						</div>,
						<div className={styles.tableCell} key={colIndex}>
							{colIndex + 1}
						</div>,
						<div className={styles.tableCell} key={colIndex}>
							<Dropdown
								onSelect={(option) =>
									handleDropdownChange(colIndex, "status", option.id)
								}
								options={displayedStatusOptions}
								getOptionLabel={(option) => (
									<div className={styles.optionLabel}>
										<span className={styles.statusCount}>
											<span>
												{option.name} ({option.count ?? 0})
											</span>
										</span>
									</div>
								)}
								defaultSelectedOption={
									displayedStatusOptions.find((s) => s.id === rows.status) || null
								}
								placeholder={
									<div className={styles.dropdownPlaceholder}>
										<div>{getSelectedStatusName(rows.status)}</div>
										<div>
											<ArrowDown />
										</div>
									</div>
								}
								dropdownButtonClass={styles.statusDropdown}
							/>
						</div>,
						<div className={styles.tableCell} key={colIndex}>
							<Dropdown
								onSelect={(option) =>
									handleDropdownChange(colIndex, "slide_type", option.name)
								}
								options={availableSlides}
								getOptionLabel={(option) => option.title}
								defaultSelectedOption={
									availableSlides.find((s) => s.name === rows.slide_type) ||
									(availableSlides?.length > 0 ? availableSlides[0] : null)
								}
								placeholder={
									<div className={styles.dropdownPlaceholder}>
										<div className={styles.slideType}>
											{getSelectedSlideName(rows.slide_type)}
										</div>
										<div>
											<ArrowDown />
										</div>
									</div>
								}
								dropdownButtonClass={styles.statusDropdown}
							/>
						</div>,
						<div className={styles.tableCell} key={colIndex}>
							<Dropdown
								onSelect={(option) =>
									handleDropdownChange(colIndex, "order", option.value)
								}
								options={SORT_OPTIONS}
								getOptionLabel={(option) => option.name}
								defaultSelectedOption={
									SORT_OPTIONS.find((s) => s.value === rows.order) || null
								}
								placeholder={
									<div className={styles.dropdownPlaceholder}>
										<div>{getSelectedSortName(rows.order)}</div>
										<div>
											<ArrowDown />
										</div>
									</div>
								}
								dropdownButtonClass={styles.statusDropdown}
							/>
						</div>,
						<div
							className={classNames(styles.tableCell, styles.peopleCount)}
							key={colIndex}
						>
							{projectCandidateStatusesInProgress ? (
								<Loader height={15} width={15} />
							) : (
								<span>{getAssignedCandidatesCount(rows.status)} People </span>
							)}
						</div>,
						reportRows?.length > 1 ? (
							<div
								style={{cursor: "pointer"}}
								data-testid={`delete-button-for-project-share-slide-option-${colIndex}`}
								className={styles.tableCell}
								onClick={() => handleDeleteClick(colIndex)}
								key={colIndex}
							>
								<BiTrash />
							</div>
						) : (
							<div />
						),
					],
				};
			}),
		[
			reportRows,
			displayedStatusOptions,
			getSelectedStatusName,
			availableSlides,
			getSelectedSlideName,
			getSelectedSortName,
			getAssignedCandidatesCount,
			handleDrop,
			handleDropdownChange,
			handleDeleteClick,
			projectCandidateStatusesInProgress,
		],
	);

	return (
		<div className={styles.tableWrapper}>
			<Table
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				headers={headers}
				rows={rows}
				tableCustomClass={styles.table}
				tableHeaderCustomClass={styles.header}
				tableRowCustomClass={styles.tableRow}
			/>
		</div>
	);
};

export default ProjectReportTable;
