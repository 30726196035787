/**
 *
 * @param url
 * @return {(function(*, *): Promise<{cancelled}|object|null>)|*}
 */
import {userEmailSelector} from "store/mapx/user/userSelectors";
import {
	getCandidatesByFilterDone,
	importLinkedInCandidateInProgress,
	setImportLinkedInProfileInfo,
} from "store/mapx/candidate/candidateActions";
import mapXCandidateApi from "api/candidateApi";
import {Mixpanel} from "helpers/mixpanel";
import {toast} from "react-toastify";
import {ToastContent} from "components";
import {getFilteredCandidatesForSearch} from "store/mapx/candidate/candidateResultsAsyncActions";
import {
	candidatesSelector,
	importLinkedInCandidateInProgressSelector,
	importLinkedInProfileInfoSelector,
} from "store/mapx/candidate/candidateSelectors";
import axios, {AxiosResponse} from "axios";
import {Dispatch} from "react";
import {TAppDispatch, TRootState} from "types";
import {projectSelector} from "store/mapx/project-list/projectListSelectors";
import {updateCandidateProfileAfterRefresh} from "store/mapx/refresh-candidate/refreshCandidateActions";

/* eslint-disable  @typescript-eslint/no-explicit-any */
const cancelTokens = {} as any;

export const getOrImportCandidateByLinkedInUrl =
	(url: string) => async (dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const state = getState();

		const apiPayload = {
			filters: {linkedin_url: url},
			pagination: {page: 1},
		};

		try {
			const response = await dispatch(getFilteredCandidatesForSearch(apiPayload));

			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			if (response.status === 200) {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				const results = response.data;

				if (results.length > 0) {
					return results[0];
				} else {
					// if already a request is in progress, we won't allow
					const linkedInImportInProgress =
						importLinkedInCandidateInProgressSelector(state);

					const prevImportInfo = importLinkedInProfileInfoSelector(state);

					const project = projectSelector(state);

					let shouldImport = true;

					if (prevImportInfo) {
						const usernameArray = prevImportInfo?.linkedin_url.split("/");

						// when we split profile url =>
						// ["https:", "", "www.linkedin.com", "in", "example-username", "?extra-params"]
						const username = usernameArray?.length > 0 ? usernameArray[4] : null;

						shouldImport = !(username && url.indexOf(username) > 0);
					}

					if (shouldImport && !linkedInImportInProgress && results.length === 0) {
						dispatch(importLinkedInProfile(url, "filter", project?.id ?? null));
					}
				}

				return null;
			}
		} catch (error) {
			console.log(error);

			return null;
		}
	};
export const importLinkedInProfile =
	(linkedInLink: string, type = "filter", projectID: number | null) =>
	async (dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const state = getState();

		const userEmail = userEmailSelector(state);

		if (type === "filter") {
			dispatch(importLinkedInCandidateInProgress(true));
		}

		let response;

		try {
			const formBody = {linkedin_url: linkedInLink, project_id: projectID};

			response = await mapXCandidateApi.createLinkedinUrlImportRequest(formBody);

			if (response.status === 201) {
				try {
					Mixpanel.track(`LinkedIn Candidate Imported`, {
						name: type === "filter" ? `Imported from filter` : `Imported from project`,
						pageTitle:
							type === "filter" ? `Imported from filter` : `Imported from project`,
						url: linkedInLink,
						distinct_id: userEmail,
					});
				} catch (e: any) {
					console.log(e.toString());
				}

				if (type === "project") {
					toast.success(ToastContent, {
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						autoClose: true,
						closeOnClick: true,
						data: {
							title: "The candidate import process has started. After successful import, the candidate will be added to the list!",
						},
						delay: 5000,
					});

					return response;
				} else {
					dispatch(setImportLinkedInProfileInfo(response.data));

					return response.status;
				}
			} else {
				if (type === "filter") {
					toast.error(ToastContent, {
						autoClose: false,
						closeOnClick: true,
						data: {title: response?.data?.detail || "Something went wrong!"},
					});

					dispatch(importLinkedInCandidateInProgress(false));

					return response.status;
				}
			}
		} catch (error) {
			console.log(error);

			return 403;
		}
	};
export const getImportLinkedinCandidateInfoById =
	(importId: number) => async (dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		try {
			const response: AxiosResponse = await mapXCandidateApi.getLinkedinUrlImportById(
				importId,
			);

			if (response.status === 200) {
				dispatch(setImportLinkedInProfileInfo(response.data));

				const status = response.data.status;

				if (status === "Imported") {
					const state = getState();

					const candidates = candidatesSelector(state);

					const linkedInUrl = response.data.linkedin_url;

					const apiPayload = {
						filters: {linkedin_url: linkedInUrl},
						pagination: {page: 1},
					};

					const candidatesData = await dispatch(
						getFilteredCandidatesForSearch(apiPayload),
					);

					dispatch(importLinkedInCandidateInProgress(false));

					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					const candidatesDataArray = candidatesData.data;

					if (candidates?.length && candidatesDataArray?.length) {
						return {
							candidate:
								candidatesDataArray[0] !== undefined
									? candidatesDataArray[0]
									: null,
							status: response.data.status,
							message: response.data.reason,
							error: false,
						};
					} else if (candidatesDataArray?.length) {
						dispatch(getCandidatesByFilterDone(candidatesData));

						return {
							candidate:
								candidatesDataArray[0] !== undefined
									? candidatesDataArray[0]
									: null,
							status: response.data.status,
							message: response.data.reason,
							error: false,
						};
					}
				} else if (status === "Scraping Error" || status === "Import Error") {
					dispatch(importLinkedInCandidateInProgress(false));

					response.data.reason = "This url could not be processed";

					return {
						candidate: null,
						status: response.data.status,
						message: response.data.reason,
						error: true,
					};
				}
			}

			return response.data;
		} catch (error) {
			return {error};
		}
	};
export const getImportLinkedinCandidateStatus =
	(importId: number) => async (dispatch: Dispatch<TAppDispatch>) => {
		const requestKey = `GET_IMPORT_LINKEDIN_URL_STATUS_${importId}`;

		if (requestKey in cancelTokens) {
			cancelTokens[requestKey].cancel("Operation canceled due to new request.");
		}

		cancelTokens[requestKey] = axios.CancelToken.source();

		const config = {
			cancelToken: cancelTokens[requestKey].token,
		};

		try {
			const response: AxiosResponse = await mapXCandidateApi.getLinkedinUrlImportById(
				importId,
				config,
			);

			if (response.status === 200) {
				const status = response.data.status;

				if (status === "Imported") {
					const linkedInUrl = response.data.linkedin_url;

					const apiPayload = {
						filters: {linkedin_url: linkedInUrl},
						pagination: {page: 1},
					};

					const candidatesData = await dispatch(
						getFilteredCandidatesForSearch(apiPayload),
					);

					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					const candidatesDataArray = candidatesData.data;

					if (candidatesDataArray?.length > 0) {
						dispatch(updateCandidateProfileAfterRefresh(candidatesDataArray[0]));

						return {
							candidate: candidatesDataArray[0],
							status: response.data.status,
							message: response.data.reason,
							error: false,
						};
					}
				} else if (status === "Scraping Error" || status === "Import Error") {
					response.data.reason = "This url could not be processed";

					return {
						candidate: null,
						status: response.data.status,
						message: response.data.reason,
						error: true,
					};
				}
			}

			return {
				candidate: null,
				message: null,
				error: false,
				status: response.data.status,
				data: response.data,
			};
		} catch (error) {
			return {error};
		}
	};
