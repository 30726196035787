import React from "react";

const NewHiresIcon = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
		>
			<path d="M19 10L9 4.2265V15.7735L19 10ZM2 11H10V9H2V11Z" fill="#309161" />
		</svg>
	);
};

export default NewHiresIcon;
