import {useAppDispatch, useAppSelector} from "hooks";
import {Accordion} from "mapx-components";
import {useCallback} from "react";
import {filterPositionOptionsSelector} from "store/mapx/filter/filterSelectors";
import {TabSelect} from "components";
import CompanyTypeSearchFilter from "mapx-components/Filters/CandidateCompanyTypeSearchFilter";
import {
	mRAllCompanyTypesCountSelector,
	mRCandidateCompanyTypesByPositionSelector,
	mRCompanyTypeFilterConfigSelector,
	mRSelectedAnyCompanyTypesSelector,
	mRSelectedCurrentCompanyTypesSelector,
	mRSelectedPreviousCompanyTypesSelector,
} from "store/mapx/market-report/marketReportFilterSelectors";
import {
	setMarketReportMultipleFilterValueInForm,
	setMRCompanyTypeFilterConfig,
} from "store/mapx/market-report/marketReportActions";
import {TPositionLogic} from "store/mapx/market-report/types";
import {
	moveCompanyTypeForMarketReportFormFilter,
	setCompanyTypeForMarketReportFormFilter,
} from "store/mapx/market-report/marketReportFormAsyncActions";

function CompanyTypeFilter() {
	const dispatch = useAppDispatch();

	const filterPositionOptions = useAppSelector(filterPositionOptionsSelector);

	const filterConfig = useAppSelector(mRCompanyTypeFilterConfigSelector);

	const position = filterConfig.active_position;

	const count = useAppSelector(mRAllCompanyTypesCountSelector);

	const anyTags = useAppSelector(mRSelectedAnyCompanyTypesSelector);

	const currentTags = useAppSelector(mRSelectedCurrentCompanyTypesSelector);

	const previousTags = useAppSelector(mRSelectedPreviousCompanyTypesSelector);

	const selectedItems = useAppSelector((state) =>
		mRCandidateCompanyTypesByPositionSelector(state, position),
	);

	const handlePositionChange = useCallback(
		(updatedPositionValue: string) => {
			dispatch(
				setMRCompanyTypeFilterConfig({
					...filterConfig,
					active_position: updatedPositionValue,
				}),
			);
		},
		[dispatch, filterConfig],
	);

	const handleChange = useCallback(
		(id: string) => {
			dispatch(setCompanyTypeForMarketReportFormFilter({id, position}));
		},
		[dispatch, position],
	);

	const moveTagPosition = useCallback(
		(value: string, fromPosition: TPositionLogic, toPosition: TPositionLogic) => {
			dispatch(
				moveCompanyTypeForMarketReportFormFilter({
					from: fromPosition,
					to: toPosition,
					id: value,
				}),
			);
		},
		[dispatch],
	);

	const handleTagClick = useCallback(
		(id: string, position: TPositionLogic) => {
			dispatch(
				setCompanyTypeForMarketReportFormFilter({
					position,
					id,
				}),
			);
		},
		[dispatch],
	);

	const handleResetClick = useCallback(() => {
		dispatch(
			setMarketReportMultipleFilterValueInForm({
				company_type: [],
				current_company_type: [],
				previous_company_type: [],
			}),
		);
	}, [dispatch]);

	const handleClearTagClickByPosition = useCallback(
		(position: TPositionLogic) => {
			let payload;

			switch (position) {
				case "current":
					payload = {
						current_company_type: [],
					};
					break;
				case "previous":
					payload = {
						previous_company_type: [],
					};
					break;
				default:
					payload = {
						company_type: [],
					};
			}

			dispatch(setMarketReportMultipleFilterValueInForm(payload));
		},
		[dispatch],
	);

	return (
		<Accordion title="Company Type" subtitle={count}>
			<TabSelect.LabelContainer label="Position">
				<TabSelect
					selected={position}
					onTabChange={handlePositionChange}
					options={filterPositionOptions}
				/>
			</TabSelect.LabelContainer>

			<CompanyTypeSearchFilter
				selectedCompanyTypes={selectedItems}
				handleResetClick={handleResetClick}
				handleOnChange={handleChange}
				handleTagClick={handleTagClick}
				displayResetFilterOption={count > 0}
				moveCompanyTypeTagPosition={moveTagPosition}
				selectedCompanyTypeTags={{
					current: currentTags,
					previous: previousTags,
					any: anyTags,
				}}
				clearTagsByPosition={handleClearTagClickByPosition}
			/>
		</Accordion>
	);
}

export default CompanyTypeFilter;
