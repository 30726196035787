import {createSelector} from "reselect";
import type {TRootState} from "types";
import {isSuperUserSelector} from "../user/userSelectors";

export const marketMapsPaginationSelector = (state: TRootState) => state.market_map.pagination;
export const marketMapsSelector = (state: TRootState) => state.market_map.results;
export const marketMapsModalPaginationSelector = (state: TRootState) =>
	state.market_map.modal_pagination;
export const marketMapsModalSelector = (state: TRootState) => state.market_map.modal_results;
export const marketMapsModalUserHasAccessSelector = createSelector(
	[marketMapsModalSelector, isSuperUserSelector],
	(marketMaps, isSuperUser) =>
		isSuperUser
			? marketMaps
			: // @TODO: Remove "any" when state type is set
			  // eslint-disable-next-line @typescript-eslint/no-explicit-any
			  marketMaps.filter((map: any) => !map.is_global),
);
export const getMarketMapsInProgressSelector = (state: TRootState) =>
	state.market_map.getting_in_progress;
export const getMarketMapsModalInProgressSelector = (state: TRootState) =>
	state.market_map.modal_getting_in_progress;
export const creationInProgressSelector = (state: TRootState) =>
	state.market_map.creation_in_progress;
export const creationErrorSelector = (state: TRootState) => state.market_map.creation_error;
export const createMarketMapModalSelector = (state: TRootState) =>
	state.market_map.create_market_map_modal;
export const editMarketMapModalSelector = (state: TRootState) =>
	state.market_map.edit_market_map_modal;
export const addFromMarketMapModalSelector = (state: TRootState) =>
	state.market_map.add_from_market_map_modal;
export const addToMarketMapModalSelector = (state: TRootState) =>
	state.market_map.add_to_market_map_modal;
export const duplicateMarketMapModalSelector = (state: TRootState) =>
	state.market_map.duplicate_market_map_modal;

export const downloadingMarketMapPngSelector = (state: TRootState) => state.market_map.downloading;

export const addFromMarketMapModalPropsSelector = (state: TRootState) =>
	state.market_map.add_from_market_map_modal_props;
export const createMarketMapModalPropsSelector = (state: TRootState) =>
	state.market_map.create_market_map_modal_props;

export const deleteMarketMapInProgressSelector = (state: TRootState) =>
	state.market_map.deletion_in_progress;
export const marketMapModalNameSelector = (state: TRootState) => state.market_map.modal_name;
export const marketMapNameSelector = (state: TRootState) => state.market_map.name;
export const marketMapCreatedBySelector = (state: TRootState) => state.market_map.created_by;
export const marketMapLastCreationSelector = (state: TRootState) => state.market_map.last_creation;
export const marketMapModalDisplayedForMRSelector = (state: TRootState) =>
	state.market_map.market_map_modal_displayed_for_mr;
