export const GET_USER_STATS_TOKEN = "GET_USER_STATS_TOKEN";
export const GET_PROJECT_SUCCESS_CHECKLIST_TOKEN = "GET_PROJECT_SUCCESS_CHECKLIST_TOKEN";
export const PATCH_PROJECT_SUCCESS_CHECKLIST = "PATCH_PROJECT_SUCCESS_CHECKLIST";
export const SEARCH_COMPANY_BY_DESCRIPTION = "SEARCH_COMPANY_BY_DESCRIPTION";
export const SEARCH_COMPANY_BY_FILTER = "SEARCH_COMPANY_BY_FILTER";
export const GET_COMPANY_TOOLTIP_TOKEN = "GET_COMPANY_TOOLTIP_TOKEN";
export const GET_COMPANIES_SEARCH_RESULTS_TOKEN = "GET_COMPANIES_SEARCH_RESULTS_TOKEN";
export const GET_COMPANY_ORG_CHART_REPORT_TOKEN = "GET_COMPANY_ORG_CHART_REPORT_TOKEN";
export const GET_COMPANY_ETHNICITY_REPORT_TOKEN = "GET_COMPANY_ETHNICITY_REPORT_TOKEN";
export const GET_COMPANY_GENDER_REPORT_TOKEN = "GET_COMPANY_GENDER_REPORT_TOKEN";
export const GET_COMPANY_TRENDS_STATS_REPORT_TOKEN = "GET_COMPANY_TRENDS_STATS_REPORT_TOKEN";
export const GET_COMPANY_LOCATION_REPORT_TOKEN = "GET_COMPANY_LOCATION_REPORT_TOKEN";
export const GET_COMPANY_FHF_REPORT_TOKEN = "GET_COMPANY_FHF_REPORT_TOKEN";
export const GET_COMPANY_INDUSTRIES_REPORT_TOKEN = "GET_COMPANY_INDUSTRIES_REPORT_TOKEN";
export const GET_COMPANY_SPECIALISMS_REPORT_TOKEN = "GET_COMPANY_SPECIALISMS_REPORT_TOKEN";
export const GET_COMPANY_MAP_HISTORY_TOKEN = "GET_COMPANY_MAP_HISTORY_TOKEN";
export const GET_MARKET_MAPS_TOKEN = "GET_MARKET_MAPS_TOKEN";
export const GET_MARKET_REPORTS_TOKEN = "GET_MARKET_REPORTS_TOKEN";
export const GET_MARKET_DIVERSITY_STATS_REPORTS_TOKEN = "GET_MARKET_DIVERSITY_STATS_REPORTS_TOKEN";
export const GET_MARKET_DIVERSITY_PATHWAYS_REPORTS_TOKEN =
	"GET_MARKET_DIVERSITY_PATHWAYS_REPORTS_TOKEN";
export const GET_TARGETLIST_COMPANIES_TOKEN = "GET_TARGETLIST_COMPANIES_TOKEN";

export const GET_TARGETLIST_COMPANY_IDS_TOKEN = "GET_TARGETLIST_COMPANY_IDS_TOKEN";

export const GET_PROJECT_COMPANY_IDS_TOKEN = "GET_PROJECT_COMPANY_IDS_TOKEN";

export const GET_TARGETLIST_CANDIDATE_IDS_TOKEN = "GET_TARGETLIST_CANDIDATE_IDS_TOKEN";

export const GET_PROJECT_CANDIDATE_IDS_TOKEN = "GET_PROJECT_CANDIDATE_IDS_TOKEN";

export const GET_PAGINATED_TARGETLIST_COMPANIES_TOKEN = "GET_PAGINATED_TARGETLIST_COMPANIES_TOKEN";
export const GET_PAGINATED_PROJECT_SAVED_COMPANIES_TOKEN =
	"GET_PAGINATED_PROJECT_SAVED_COMPANIES_TOKEN";
export const GET_CANDIDATES_SEARCH_PAGE_TOKEN = "GET_CANDIDATES_SEARCH_PAGE_TOKEN";
export const GET_CANDIDATES_RESULTS_COUNT_TOKEN = "GET_CANDIDATES_RESULTS_COUNT_TOKEN";
export const GET_TARGETLIST_CANDIDATES_TOKEN = "GET_TARGETLIST_CANDIDATES_TOKEN";
export const GET_PROJECT_SAVED_PEOPLE_TOKEN = "GET_PROJECT_SAVED_PEOPLE_TOKEN";
export const GET_PROJECT_TRACK_LEAVERS_PEOPLE_TOKEN = "GET_PROJECT_TRACK_LEAVERS_PEOPLE_TOKEN";
export const GET_PROJECT_TRACK_PROMOTION_PEOPLE_TOKEN = "GET_PROJECT_TRACK_PROMOTION_PEOPLE_TOKEN";
export const GET_PROJECT_TRACK_NO_RECENT_CHANGES_PEOPLE_TOKEN =
	"GET_PROJECT_TRACK_NO_RECENT_CHANGES_PEOPLE_TOKEN";
export const GET_PROJECT_STATISTICS_TOKEN = "GET_PROJECT_STATISTICS_TOKEN";
export const PROJECT_INDUSTRIES_REPORT_TOKEN = "PROJECT_INDUSTRIES_REPORT_TOKEN";
export const PROJECT_DIVERSITY_REPORT_TOKEN = "PROJECT_DIVERSITY_REPORT_TOKEN";
export const PROJECT_ETHNICITY_REPORT_TOKEN = "PROJECT_ETHNICITY_REPORT_TOKEN";
export const PROJECT_LOCATION_REPORT_TOKEN = "PROJECT_LOCATION_REPORT_TOKEN";
export const PROJECT_FREQ_HIRED_FROM_REPORT_TOKEN = "PROJECT_FREQ_HIRED_FROM_REPORT_TOKEN";
export const PROJECT_JOB_FUNCTION_REPORT_TOKEN = "PROJECT_JOB_FUNCTION_REPORT_TOKEN";
export const PROJECT_INDUSTRIES_BACKGROUND_REPORT_TOKEN =
	"PROJECT_INDUSTRIES_BACKGROUND_REPORT_TOKEN";
