import {
	BACKGROUND_BULK_IMPORTS_IN_PROGRESS,
	CLEAR_PROJECT_CANDIDATE_ASSESSMENT_PROCESS,
	PROJECT_CANDIDATE_ASSESSMENT_PROCESS_REQUEST_CREATE_IN_PROGRESS,
	SET_IMPORT_LINKEDIN_PROFILE_INFO_FOR_CURRENT_IMPORT,
	SET_LAST__COMPLETE_IMPORT_LINKEDIN_PROFILE_INFO_FOR_PROJECT,
	SET_PROJECT_CANDIDATE_ASSESSMENT_PROCESS,
	UPDATE_CANDIDATE_ASSESSMENT_STATUS_ON_BT,
} from "store/mapx/mapXActionTypes";
import {STBackgroundTaskResponse} from "api/backgroundTaskApi/types";
import {TProfilesImportInProgress} from "mapx-components/AddPeopleByLinkedIn/types";
import {TSetLastCompletedImportInfoPayload} from "./types";

export function setBackgroundBulkImportsInProgress(payload: boolean) {
	return {type: BACKGROUND_BULK_IMPORTS_IN_PROGRESS, payload};
}

export function setLastCompleteImportLinkedInProfileInfoForProject(
	payload: TSetLastCompletedImportInfoPayload,
) {
	return {type: SET_LAST__COMPLETE_IMPORT_LINKEDIN_PROFILE_INFO_FOR_PROJECT, payload};
}

export function projectCandidateAssessmentProcessRequestCreateInProgress(payload: boolean) {
	return {type: PROJECT_CANDIDATE_ASSESSMENT_PROCESS_REQUEST_CREATE_IN_PROGRESS, payload};
}

export function setProjectCandidateAssessmentProcess(payload: STBackgroundTaskResponse) {
	return {type: SET_PROJECT_CANDIDATE_ASSESSMENT_PROCESS, payload};
}

export function updateCandidateAssessmentStatusOnBT(payload: {
	candidate_id: number;
	is_assessed: boolean;
}) {
	return {type: UPDATE_CANDIDATE_ASSESSMENT_STATUS_ON_BT, payload};
}

export function clearProjectCandidateAssessmentProcess() {
	return {type: CLEAR_PROJECT_CANDIDATE_ASSESSMENT_PROCESS};
}

export function setImportLinkedInProfileInfoForCurrentImport(payload: TProfilesImportInProgress) {
	return {type: SET_IMPORT_LINKEDIN_PROFILE_INFO_FOR_CURRENT_IMPORT, payload};
}
