import {Tag} from "components";
import {useAppDispatch, useAppSelector} from "hooks";
import FilterTags from "mapx-components/FilterTags";
import React, {useCallback} from "react";
import {
	moveIndustriesForMarketReportFormFilter,
	setIndustryForMarketReportFormFilter,
} from "store/mapx/market-report/marketReportFormAsyncActions";
import {mRSelectedCurrentIndustriesSelector} from "store/mapx/market-report/marketReportFilterSelectors";
import {getMarketReportFormSelector} from "store/mapx/market-report/marketReportSelector";

function CurrentTags() {
	const dispatch = useAppDispatch();

	const industries = useAppSelector(mRSelectedCurrentIndustriesSelector);

	const marketReportForm = useAppSelector(getMarketReportFormSelector);

	const selectedCurrentIndustryIds = marketReportForm.filters.current_industries;

	const handleTagClick = useCallback(
		(id) => {
			const nextIds = [...selectedCurrentIndustryIds].filter((i) => i !== id);

			dispatch(setIndustryForMarketReportFormFilter({position: "current", nextIds}));
		},
		[dispatch, selectedCurrentIndustryIds],
	);

	const handleTagDragStart = useCallback((e, id) => {
		e.dataTransfer.setData("id", id);
		e.dataTransfer.setData("from", "current");
	}, []);

	const handleDrop = useCallback(
		(e) => {
			const id = parseInt(e.dataTransfer.getData("id"), 10);
			const from = e.dataTransfer.getData("from");

			dispatch(
				moveIndustriesForMarketReportFormFilter({
					from,
					to: "current",
					ids: [id],
				}),
			);
		},
		[dispatch],
	);

	return (
		<FilterTags
			onDrop={handleDrop}
			label="Current"
			count={industries.length}
			tags={industries.map((item) => (
				<Tag
					key={item.id}
					onClick={() => handleTagClick(item.id)}
					draggable
					onDragStart={(e) => handleTagDragStart(e, item.id)}
					onDragOver={(e) => e.preventDefault()}
				>
					{item.name}
				</Tag>
			))}
		/>
	);
}

export default React.memo(CurrentTags);
