const AddMarketMapToProjectIcon = () => (
	<svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
		<mask
			id="mask0_2393_153221"
			style={{maskType: "alpha"}}
			maskUnits="userSpaceOnUse"
			x="0"
			y="0"
			width="64"
			height="64"
		>
			<rect width="64" height="64" fill="#D9D9D9" />
		</mask>
		<g mask="url(#mask0_2393_153221)">
			<path
				d="M30.4873 44.6667H33.5126V33.5127H44.6666V30.4873H33.5126V19.3333H30.4873V30.4873H19.3333V33.5127H30.4873V44.6667ZM13.1793 54.6667C12.1335 54.6667 11.2311 54.2871 10.472 53.528C9.71287 52.7689 9.33331 51.8665 9.33331 50.8207V13.1793C9.33331 12.1336 9.71287 11.2311 10.472 10.472C11.2311 9.7129 12.1335 9.33334 13.1793 9.33334H50.8206C51.8664 9.33334 52.7689 9.7129 53.528 10.472C54.2871 11.2311 54.6666 12.1336 54.6666 13.1793V50.8207C54.6666 51.8665 54.2871 52.7689 53.528 53.528C52.7689 54.2871 51.8664 54.6667 50.8206 54.6667H13.1793ZM13.1793 51.6407H50.8206C51.0255 51.6407 51.2135 51.5553 51.3846 51.3847C51.5553 51.2136 51.6406 51.0256 51.6406 50.8207V13.1793C51.6406 12.9745 51.5553 12.7865 51.3846 12.6153C51.2135 12.4447 51.0255 12.3593 50.8206 12.3593H13.1793C12.9744 12.3593 12.7864 12.4447 12.6153 12.6153C12.4446 12.7865 12.3593 12.9745 12.3593 13.1793V50.8207C12.3593 51.0256 12.4446 51.2136 12.6153 51.3847C12.7864 51.5553 12.9744 51.6407 13.1793 51.6407Z"
				fill="#0C5850"
			/>
		</g>
	</svg>
);

export default AddMarketMapToProjectIcon;
