// USER ACTIONS
export const CHANGE_PASSWORD_FETCH = "CHANGE_PASSWORD_FETCH";
export const CHANGE_PASSWORD_FAILED = "CHANGE_PASSWORD_FAILED";
export const UPDATE_USER_CREDIT_INFORMATION = "UPDATE_USER_CREDIT_INFORMATION";
export const TOGGLE_TUTORIAL_MODAL_OPEN = "TOGGLE_TUTORIAL_MODAL_OPEN";

export const VERIFY_VERIFICATION_INIT = "VERIFY_VERIFICATION_INIT";
export const VERIFY_VERIFICATION_DONE = "VERIFY_VERIFICATION_DONE";
export const VERIFY_VERIFICATION_FAIL = "VERIFY_VERIFICATION_FAIL";

export const SEND_VERIFICATION_EMAIL_INIT = "SEND_VERIFICATION_EMAIL_INIT";
export const SEND_VERIFICATION_EMAIL_DONE = "SEND_VERIFICATION_EMAIL_DONE";
export const SEND_VERIFICATION_EMAIL_FAIL = "SEND_VERIFICATION_EMAIL_FAIL";

export const REGISTER_CONTACTS_BLOCKED_BY_COUNTRY_INIT =
	"REGISTER_CONTACTS_BLOCKED_BY_COUNTRY_INIT";
export const REGISTER_CONTACTS_BLOCKED_BY_COUNTRY_DONE =
	"REGISTER_CONTACTS_BLOCKED_BY_COUNTRY_DONE";
export const REGISTER_CONTACTS_BLOCKED_BY_COUNTRY_FAIL =
	"REGISTER_CONTACTS_BLOCKED_BY_COUNTRY_FAIL";

export const GET_USER_LOCATION_INIT = "GET_USER_LOCATION_INIT";
export const GET_USER_LOCATION_DONE = "GET_USER_LOCATION_DONE";
export const GET_USER_LOCATION_FAIL = "GET_USER_LOCATION_FAIL";

// CANDIDATE ACTIONS
export const GET_CANDIDATES_INIT = "GET_CANDIDATES_INIT";
export const GET_CANDIDATES_DONE = "GET_CANDIDATES_DONE";
export const GET_CANDIDATES_FAIL = "GET_CANDIDATES_FAIL";

export const VERIFY_EMAIL_INIT = "VERIFY_EMAIL_INIT";
export const VERIFY_EMAIL_DONE = "VERIFY_EMAIL_DONE";
export const VERIFY_EMAIL_FAIL = "VERIFY_EMAIL_FAIL";

export const GET_CANDIDATES_BY_FILTER_INIT = "GET_CANDIDATES_BY_FILTER_INIT";
export const GET_SINGLE_CANDIDATE_INIT = "GET_CANDIDATE_INIT";
export const GET_SINGLE_CANDIDATE_DONE = "GET_CANDIDATE_DONE";
export const GET_SINGLE_CANDIDATE_FAIL = "GET_CANDIDATE_FAIL";

export const FLAG_CANDIDATE = "FLAG_CANDIDATE";
export const RESET_FILTERED_CANDIDATES = "RESET_FILTERED_CANDIDATES";

// COMPANY ACTIONS
export const CLEAR_SINGLE_COMPANY_DONE = "CLEAR_SINGLE_COMPANY_DONE";

export const GET_SINGLE_COMPANY_INIT = "GET_SINGLE_COMPANY_INIT";
export const GET_SINGLE_COMPANY_DONE = "GET_SINGLE_COMPANY_DONE";
export const GET_SINGLE_COMPANY_FAIL = "GET_SINGLE_COMPANY_FAIL";
export const GET_COMPANY_ORG_CHART_FILTERING = "GET_COMPANY_ORG_CHART_FILTERING";
export const GET_COMPANY_ORG_CHART_FILTERS_DONE = "GET_COMPANY_ORG_CHART_FILTERS_DONE";
export const CLEAR_COMPANY_PAGE_FILTERS_AND_ALL_THE_RESPONSES =
	"CLEAR_COMPANY_PAGE_FILTERS_AND_ALL_THE_RESPONSES";

export const GET_COMPANIES_INIT = "GET_COMPANIES_INIT";
export const GET_COMPANIES_DONE = "GET_COMPANIES_DONE";
export const GET_COMPANIES_FAIL = "GET_COMPANIES_FAIL";

export const GET_COMPANIES_BY_FILTER_INIT = "GET_COMPANIES_BY_FILTER_INIT";
export const GET_COMPANIES_BY_FILTER_DONE = "GET_COMPANIES_BY_FILTER_DONE";

export const SET_COMPANIES_PAGINATION_INFO = "SET_COMPANIES_PAGINATION_INFO";
export const GET_COMPANIES_BY_FILTER_FAIL = "GET_COMPANIES_BY_FILTER_FAIL";

export const DELETE_USER_WITH_ID_INIT = "DELETE_USER_WITH_ID_INIT";
export const DELETE_USER_WITH_ID_DONE = "DELETE_USER_WITH_ID_DONE";
export const DELETE_USER_WITH_ID_FAILED = "DELETE_USER_WITH_ID_FAILED";

export const USERS_TRANSFER_INIT = "USERS_TRANSFER_INIT";
export const USERS_TRANSFER_DONE = "USERS_TRANSFER_DONE";
export const USERS_TRANSFER_FAILED = "USERS_TRANSFER_FAILED";

export const USERS_UPDATE_INIT = "USERS_UPDATE_INIT";
export const USERS_UPDATE_DONE = "USERS_UPDATE_DONE";
export const USERS_UPDATE_FAIL = "USERS_UPDATE_FAIL";

export const GET_LICENSE_TYPES_INIT = "GET_LICENSE_TYPES_INIT";
export const GET_LICENSE_TYPES_DONE = "GET_LICENSE_TYPES_DONE";
export const GET_LICENSE_TYPES_FAIL = "GET_LICENSE_TYPES_FAIL";

export const LOAD_MORE_COMPANIES_INIT = "LOAD_MORE_COMPANIES_INIT";

export const UPDATE_TOOLTIP_COMPANIES = "UPDATE_TOOLTIP_COMPANIES";
export const UPDATE_TOOLTIP_COMPANIES_PROGRESS = "UPDATE_TOOLTIP_COMPANIES_PROGRESS";
export const UPDATE_TOOLTIP_COMPANIES_FAILED = "UPDATE_TOOLTIP_COMPANIES_FAILED";

// SIMILARITY ACTIONS
export const GET_SIMILAR_CANDIDATES_INIT = "GET_SIMILAR_CANDIDATES_INIT";
export const CLEAR_SIMILAR_CANDIDATES_RESULTS = "CLEAR_SIMILAR_CANDIDATES_RESULTS";
export const GET_SIMILAR_CANDIDATES_DONE = "GET_SIMILAR_CANDIDATES_DONE";
export const GET_SIMILAR_CANDIDATES_FAIL = "GET_SIMILAR_CANDIDATES_FAIL";
export const SET_SIMILAR_CANDIDATES_WITH_PAGINATION = "SET_SIMILAR_CANDIDATES_WITH_PAGINATION";

export const GET_SIMILAR_COMPANIES_INIT = "GET_SIMILAR_COMPANIES_INIT";
export const GET_SIMILAR_COMPANIES_DONE = "GET_SIMILAR_COMPANIES_DONE";
export const GET_SIMILAR_COMPANIES_FAIL = "GET_SIMILAR_COMPANIES_FAIL";

// SIZE & REVENUE ACTIONS
export const SET_COMPANY_SIZE_DISTRIBUTION_DATA = "SET_COMPANY_SIZE_DISTRIBUTION_DATA";
export const RESET_COMPANY_SIZE_DISTRIBUTION_DATA = "RESET_COMPANY_SIZE_DISTRIBUTION_DATA";
export const SET_COMPANY_REVENUE_DISTRIBUTION_DATA = "SET_COMPANY_REVENUE_DISTRIBUTION_DATA";
export const RESET_COMPANY_REVENUE_DISTRIBUTION_DATA = "RESET_COMPANY_REVENUE_DISTRIBUTION_DATA";
export const COMPANIES_SPECIALITIES_SELECTED_CONNECTIVITY_LOGIC =
	"COMPANIES_SPECIALITIES_SELECTED_CONNECTIVITY_LOGIC";

export const SET_COMPANY_HIERARCHY_FILTER_MODE = "SET_COMPANY_HIERARCHY_FILTER_MODE";

export const SET_CANDIDATE_COMPANY_HIERARCHY_FILTER_MODE =
	"SET_CANDIDATE_COMPANY_HIERARCHY_FILTER_MODE";

// FILTER_PARAMETERS

export const GET_SEARCHED_CANDIDATES_INIT = "GET_SEARCHED_CANDIDATES_INIT";
export const GET_SEARCHED_CANDIDATES_DONE = "GET_SEARCHED_CANDIDATES_DONE";
export const GET_SEARCHED_CANDIDATES_FAIL = "GET_SEARCHED_CANDIDATES_FAIL";
export const CLEAR_SEARCHED_CANDIDATES = "CLEAR_SEARCHED_CANDIDATES";

export const SET_SELECTED_COMPANIES_DONE = "SET_SELECTED_COMPANIES_DONE";
export const SET_SELECTED_CANDIDATES_INIT = "SET_SELECTED_CANDIDATES_INIT";

export const SET_SELECTED_CANDIDATES_DONE = "SET_SELECTED_CANDIDATES_DONE";

export const GET_SEARCHED_COMPANIES_INIT = "GET_SEARCHED_COMPANIES_INIT";
export const GET_SEARCHED_COMPANIES_DONE = "GET_SEARCHED_COMPANIES_DONE";
export const GET_SEARCHED_COMPANIES_FAIL = "GET_SEARCHED_COMPANIES_FAIL";
export const CLEAR_SEARCHED_COMPANIES = "CLEAR_SEARCHED_COMPANIES";

export const ACTIVE_ACCORDION_ON_FILTER = "ACTIVE_ACCORDION_ON_FILTER";

export const IS_TYPE_DROPDOWN_ACTIVE_ON_DE_FILTER = "IS_TYPE_DROPDOWN_ACTIVE_ON_DE_FILTER";

export const IS_SIZE_DROPDOWN_ACTIVE_ON_DE_FILTER = "IS_SIZE_DROPDOWN_ACTIVE_ON_DE_FILTER";

export const IS_DATE_DROPDOWN_ACTIVE_ON_DE_FILTER = "IS_DATE_DROPDOWN_ACTIVE_ON_DE_FILTER";

export const TOGGLE_ENHANCE_COMPANIES_RESULT = "TOGGLE_ENHANCE_COMPANIES_RESULT";
export const TOGGLE_ENHANCE_CANDIDATES_RESULT = "TOGGLE_ENHANCE_CANDIDATES_RESULT";

export const RESET_COMPANIES_FILTERS = "RESET_COMPANIES_FILTERS";
export const RESET_CANDIDATES_FILTERS = "RESET_CANDIDATES_FILTERS";

export const SET_MULTIPLE_FILTER_FOR_CANDIDATE = "SET_MULTIPLE_FILTER_FOR_CANDIDATE";

export const SET_IGNORE_TL_COMPANIES_ON_FILTER = "SET_IGNORE_TL_COMPANIES_ON_FILTER";
export const SET_CANDIDATE_FILTERS = "SET_CANDIDATE_FILTERS";
export const SET_COMPANY_FILTERS = "SET_COMPANY_FILTERS";
export const SET_ALL_COMPANY_FILTERS = "SET_ALL_COMPANY_FILTERS";

export const SET_ORGCHART_KEYWORDS_FILTERS_DROPDOWN_DATA =
	"SET_ORGCHART_KEYWORDS_FILTERS_DROPDOWN_DATA";
export const SET_MULTIPLE_FILTER_FOR_COMPANY = "SET_MULTIPLE_FILTER_FOR_COMPANY";

export const SET_CANDIDATE_COMPANY_POSITION = "SET_CANDIDATE_COMPANY_POSITION";
export const SET_CANDIDATE_TARGETLIST_POSITION = "SET_CANDIDATE_TARGETLIST_POSITION";

export const SET_CANDIDATE_TARGETLIST_TOGGLE = "SET_CANDIDATE_TARGETLIST_TOGGLE";

export const SET_AP_CANDIDATE_TARGETLIST_TOGGLE = "SET_AP_CANDIDATE_TARGETLIST_TOGGLE";

export const SET_COMPANIES_FILTERS_BY_COMPANIES_LIST = "SET_COMPANIES_FILTERS_BY_COMPANIES_LIST";
export const SET_ALL_FILTER_FOR_CANDIDATES = "SET_ALL_FILTER_FOR_CANDIDATES";

export const SET_INDUSTRIES_FILTER_CONFIG = "SET_INDUSTRIES_FILTER_CONFIG";
export const SET_LOCATIONS_FILTER_CONFIG = "SET_LOCATIONS_FILTER_CONFIG";
export const SET_AP_LOCATIONS_FILTER_CONFIG = "SET_AP_LOCATIONS_FILTER_CONFIG";
export const SET_JOB_FUNCTION_FILTER_CONFIG = "SET_JOB_FUNCTION_FILTER_CONFIG";
export const SET_KEYWORD_FILTER_CONFIG = "SET_KEYWORD_FILTER_CONFIG";
export const SET_AI_COMPANY_IDEA_FILTER_MODE = "SET_AI_COMPANY_IDEA_FILTER_MODE";

export const SET_JOB_TITLE_FILTER_MODE = "SET_JOB_TITLE_FILTER_MODE";
export const SET_HEADCOUNT_FILTER_CONFIG = "SET_HEADCOUNT_FILTER_CONFIG";
export const SET_REVENUE_FILTER_CONFIG = "SET_REVENUE_FILTER_CONFIG";
export const SET_COMPANY_TYPE_FILTER_CONFIG = "SET_COMPANY_TYPE_FILTER_CONFIG";

// SEARCH ACTION TYPE
export const GET_SEARCH_DATA_FETCH = "GET_SEARCH_DATA_FETCH";
export const GET_SEARCH_DATA_SUCCESS = "GET_SEARCH_DATA_SUCCESS";
export const GET_SEARCH_DATA_FAILED = "GET_SEARCH_DATA_FAILED";

// INDUSTRIES BACKGROUND REPORT
export const GET_INDUSTRY_BACKGROUND_REPORT_INIT = "GET_INDUSTRY_BACKGROUND_REPORT_INIT";
export const GET_INDUSTRY_BACKGROUND_REPORT_DONE = "GET_INDUSTRY_BACKGROUND_REPORT_DONE";
export const GET_INDUSTRY_BACKGROUND_REPORT_FAILED = "GET_INDUSTRY_BACKGROUND_REPORT_FAILED";

// INDUSTRIES ACTION TYPES
export const GET_INDUSTRIES_FETCH = "GET_INDUSTRIES_FETCH";
export const GET_INDUSTRIES_SUCCESS = "GET_INDUSTRIES_SUCCESS";

export const GET_INDUSTRIES_REPORT_FETCH = "GET_INDUSTRIES_REPORT_FETCH";
export const GET_INDUSTRIES_REPORT_SUCCESS = "GET_INDUSTRIES_REPORT_SUCCESS";
export const SET_INDUSTRIES_REPORT_FAILED = "SET_INDUSTRIES_REPORT_FAILED";

// INDUSTRIES ACTION TYPES
export const GET_KEYWORDS_FETCH = "GET_KEYWORDS_FETCH";
export const GET_KEYWORDS_SUCCESS = "GET_KEYWORDS_SUCCESS";

// JOB TITLES ACTION TYPES

export const GET_JOB_TITLES_FETCH = "GET_JOB_TITLES_FETCH";
export const GET_JOB_TITLES_COMPLETED = "GET_JOB_TITLES_COMPLETED";
export const GET_JOB_TITLES_SUCCESS = "GET_JOB_TITLES_SUCCESS";
export const JOB_TITLE_SEARCH_QUERY = "JOB_TITLE_SEARCH_QUERY";

// COUNTRIES ACTION TYPES
export const GET_COUNTRIES_FETCH = "GET_COUNTRIES_FETCH";
export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS";

// INDEXES ACTION TYPES
export const GET_INDEXES_FETCH = "GET_INDEXES_FETCH";
export const GET_INDEXES_SUCCESS = "GET_INDEXES_SUCCESS";
export const GET_COMPANY_HEADCOUNT_RANGE_SUCCESS = "GET_COMPANY_HEADCOUNT_RANGE_SUCCESS";
export const GET_COMPANY_REVENUE_RANGE_SUCCESS = "GET_COMPANY_REVENUE_RANGE_SUCCESS";

// SENIORITY ACTION TYPES
export const GET_SENIORITY_SUCCESS = "GET_SENIORITY_SUCCESS";
export const GET_COMPANY_TYPE_SUCCESS = "GET_COMPANY_TYPE_SUCCESS";

export const GET_LANGUAGE_FETCH = "GET_LANGUAGE_FETCH";
export const GET_LANGUAGE_SUCCESS = "GET_LANGUAGE_SUCCESS";

// JOB FUNCTIONS ACTION TYPES
export const GET_JOB_FUNCTIONS_FETCH = "GET_JOB_FUNCTIONS_FETCH";

export const GET_JOB_FUNCTIONS_SUCCESS = "GET_JOB_FUNCTIONS_SUCCESS";

// SPECIALITIES ACTION TYPES
export const ADD_SPECIALITY_TO_LIST = "ADD_SPECIALITY_TO_LIST";
export const GET_SPECIALITIES_FETCH = "GET_SPECIALITIES_FETCH";
export const GET_SPECIALITIES_SUCCESS = "GET_SPECIALITIES_SUCCESS";
export const SET_SPECIALTY_SEARCH_QUERY = "SET_SPECIALTY_SEARCH_QUERY";

// SPECIALITY CLUSTERS ACTION TYPES
export const ADD_SPECIALITY_CLUSTER_TO_LIST = "ADD_SPECIALITY_CLUSTER_TO_LIST";
export const ADD_SPECIALITY_CLUSTERS_TO_LIST = "ADD_SPECIALITY_CLUSTERS_TO_LIST";
export const GET_SPECIALITIES_CLUSTER_FETCH = "GET_SPECIALITIES_CLUSTER_FETCH";
export const GET_SPECIALITY_CLUSTERS_SUCCESS = "GET_SPECIALITY_CLUSTERS_SUCCESS";
export const SET_SPECIALTY_CLUSTER_SEARCH_QUERY = "SET_SPECIALTY_CLUSTER_SEARCH_QUERY";

// KEYWORDS ACTION TYPE
export const SET_KEYWORD_SEARCH_QUERY = "SET_KEYWORD_SEARCH_QUERY";
export const SET_AP_KEYWORD_SEARCH_QUERY = "SET_AP_KEYWORD_SEARCH_QUERY";

// SPECIALISMS ACTION TYPES
export const ADD_SPECIALISM_TO_LIST = "ADD_SPECIALISM_TO_LIST";
export const GET_SPECIALISMS_SUCCESS = "GET_SPECIALISMS_SUCCESS";

// CANDIDATE STATUSES
export const GET_PROJECT_CANDIDATE_STATUSES_INIT = "GET_PROJECT_CANDIDATE_STATUSES_INIT";
export const GET_PROJECT_CANDIDATE_STATUSES_DONE = "GET_PROJECT_CANDIDATE_STATUSES_DONE";
export const GET_PROJECT_CANDIDATE_STATUSES_FAILED = "GET_PROJECT_CANDIDATE_STATUSES_FAILED";
export const SET_PROJECT_CANDIDATE_STATUSES_FINISHED = "SET_PROJECT_CANDIDATE_STATUSES_FINISHED";

// PROGRESS STATUSES
export const GET_PROJECT_PROGRESS_STATUSES_INIT = "GET_PROJECT_PROGRESS_STATUSES_INIT";
export const GET_PROJECT_PROGRESS_STATUSES_DONE = "GET_PROJECT_PROGRESS_STATUSES_DONE";
export const GET_PROJECT_PROGRESS_STATUSES_FAILED = "GET_PROJECT_PROGRESS_STATUSES_FAILED";
export const SET_PROJECT_PROGRESS_STATUSES_FINISHED = "SET_PROJECT_PROGRESS_STATUSES_FINISHED";

// TARGET LIST ACTIONS
export const ADD_CANDIDATE_TO_TARGET_LIST_INIT = "ADD_CANDIDATE_TO_TARGET_LIST_INIT";
export const ADD_CANDIDATE_TO_TARGET_LIST_DONE = "ADD_CANDIDATE_TO_TARGET_LIST_DONE";
export const ADD_CANDIDATE_TO_TARGET_LIST_FAIL = "ADD_CANDIDATE_TO_TARGET_LIST_FAIL";

export const ADD_COMPANY_TO_TARGET_LIST_INIT = "ADD_COMPANY_TO_TARGET_LIST_INIT";
export const ADD_COMPANY_TO_TARGET_LIST_DONE = "ADD_COMPANY_TO_TARGET_LIST_DONE";
export const ADD_COMPANY_TO_TARGET_LIST_FAIL = "ADD_COMPANY_TO_TARGET_LIST_FAIL";

export const REMOVE_CANDIDATE_FROM_TARGET_LIST_INIT = "REMOVE_CANDIDATE_FROM_TARGET_LIST_INIT";
export const REMOVE_CANDIDATE_FROM_TARGET_LIST_DONE = "REMOVE_CANDIDATE_FROM_TARGET_LIST_DONE";
export const REMOVE_CANDIDATE_FROM_TARGET_LIST_FAIL = "REMOVE_CANDIDATE_FROM_TARGET_LIST_FAIL";

export const REMOVE_COMPANY_FROM_TARGET_LIST_INIT = "REMOVE_COMPANY_FROM_TARGET_LIST_INIT";
export const REMOVE_COMPANY_FROM_TARGET_LIST_DONE = "REMOVE_COMPANY_FROM_TARGET_LIST_DONE";
export const REMOVE_COMPANY_FROM_TARGET_LIST_FAIL = "REMOVE_COMPANY_FROM_TARGET_LIST_FAIL";

export const REMOVE_CANDIDATES_FROM_TARGET_LIST_INIT = "REMOVE_CANDIDATES_FROM _TARGET_LIST_INIT";
export const REMOVE_CANDIDATES_FROM_TARGET_LIST_DONE = "REMOVE_CANDIDATES_FROM _TARGET_LIST_DONE";
export const REMOVE_CANDIDATES_FROM_TARGET_LIST_FAIL = "REMOVE_CANDIDATES_FROM _TARGET_LIST_FAIL";

export const REMOVE_COMPANIES_FROM_TARGET_LIST_INIT = "REMOVE_COMPANIES_FROM_TARGET_LIST_INIT";
export const REMOVE_COMPANIES_FROM_TARGET_LIST_DONE = "REMOVE_COMPANIES_FROM_TARGET_LIST_DONE";
export const REMOVE_COMPANIES_FROM_TARGET_LIST_FAIL = "REMOVE_COMPANIES_FROM_TARGET_LIST_FAIL";

export const CREATE_DETACHED_TARGET_LIST_INIT = "CREATE_DETACHED_TARGET_LIST_INIT";
export const CREATE_DETACHED_TARGET_LIST_DONE = "CREATE_DETACHED_TARGET_LIST_DONE";
export const CREATE_DETACHED_TARGET_LIST_FAIL = "CREATE_DETACHED_TARGET_LIST_FAIL";

export const SAVE_COMPANIES_BY_FILTERS_INIT = "SAVE_COMPANIES_BY_FILTERS_INIT";
export const SAVE_COMPANIES_BY_FILTERS_DONE = "SAVE_COMPANIES_BY_FILTERS_DONE";
export const SAVE_CANDIDATES_BY_FILTERS_INIT = "SAVE_CANDIDATES_BY_FILTERS_INIT";
export const SAVE_CANDIDATES_BY_FILTERS_DONE = "SAVE_CANDIDATES_BY_FILTERS_DONE";

export const ADD_COMPANIES_FROM_MARKET_MAP_TO_TARGET_LIST_INIT =
	"ADD_COMPANIES_FROM_MARKET_MAP_TO_TARGET_LIST_INIT";
export const ADD_COMPANIES_FROM_MARKET_MAP_TO_TARGET_LIST_DONE =
	"ADD_COMPANIES_FROM_MARKET_MAP_TO_TARGET_LIST_DONE";
export const ADD_COMPANIES_FROM_MARKET_MAP_TO_TARGET_LIST_FAIL =
	"ADD_COMPANIES_FROM_MARKET_MAP_TO_TARGET_LIST_FAIL";

// PROJECT LIST ACTIONS
export const SET_PROJECT = "SET_PROJECT";

export const SET_LATEST_PROJECT = "SET_LATEST_PROJECT";

export const SAVE_PROJECT_VIA_MODAL_INIT = "SAVE_PROJECT_VIA_MODAL_INIT";
export const SAVE_PROJECT_VIA_MODAL_DONE = "SAVE_PROJECT_VIA_MODAL_DONE";
export const SAVE_PROJECT_VIA_MODAL_FAIL = "SAVE_PROJECT_VIA_MODAL_FAIL";

export const UPDATE_PROJECT_INIT = "UPDATE_PROJECT_INIT";
export const UPDATE_PROJECT_DONE = "UPDATE_PROJECT_DONE";
export const UPDATE_PROJECT_FAIL = "UPDATE_PROJECT_FAIL";

export const DUPLICATE_PROJECT_FAIL = "DUPLICATE_PROJECT_FAIL";
export const DUPLICATE_PROJECT_DONE = "DUPLICATE_PROJECT_DONE";
export const DUPLICATE_PROJECT_INIT = "DUPLICATE_PROJECT_INIT";

export const DELETE_PROJECT_INIT = "DELETE_PROJECT_INIT";
export const DELETE_PROJECT_DONE = "DELETE_PROJECT_DONE";
export const DELETE_PROJECT_FAIL = "DELETE_PROJECT_FAIL";

export const SET_PROJECT_TAB_INDEX = "SET_PROJECT_TAB_INDEX";

export const GET_PROJECT_INIT = "GET_PROJECT_INIT";
export const GET_PROJECT_DONE = "GET_PROJECT_DONE";
export const GET_PROJECT_FAIL = "GET_PROJECT_FAIL";

// TARGET ACTIONS
export const SET_COMPANY_ORGANIZATIONAL_CHART = "SET_COMPANY_ORGANIZATIONAL_CHART";
export const INIT_COMPANY_COMPANY_LOCATION_REPORT = "INIT_COMPANY_COMPANY_LOCATION_REPORT";
export const SET_COMPANY_COMPANY_LOCATION_REPORT = "SET_COMPANY_COMPANY_LOCATION_REPORT";

export const COMPANY_ORGANIZATIONAL_CHART_PAGINATION_LOADING =
	"COMPANY_ORGANIZATIONAL_CHART_PAGINATION_LOADING";
export const ADD_PAGINATED_DATA_FOR_COMPANY_ORGANIZATIONAL_CHART =
	"ADD_PAGINATED_DATA_FOR_COMPANY_ORGANIZATIONAL_CHART";
export const SET_COMPANY_INDUSTRY_BACKGROUND = "SET_COMPANY_INDUSTRY_BACKGROUND";
export const CLEAR_COMPANY_INDUSTRY_BACKGROUND = "CLEAR_COMPANY_INDUSTRY_BACKGROUND";

// MAP THIS FUNCTION FOR COMPANIES

export const SET_MAP_THIS_FUNCTION_FOR_CURRENT_COMPANY =
	"SET_MAP_THIS_FUNCTION_FOR_CURRENT_COMPANY";
export const SET_MAP_THIS_FUNCTION_IS_IN_PROGRESS_FOR_CURRENT_COMPANY =
	"SET_MAP_THIS_FUNCTION_IS_IN_PROGRESS_FOR_CURRENT_COMPANY";
export const CLEAR_MAP_THIS_FUNCTION_FOR_CURRENT_COMPANY =
	"CLEAR_MAP_THIS_FUNCTION_FOR_CURRENT_COMPANY";

// export const SET_COMPANY_ORGANIZATIONAL_CHART = "SET_COMPANY_ORGANIZATIONAL_CHART";
export const SET_COMPANY_FUNCTIONAL_BACKGROUND = "SET_COMPANY_FUNCTIONAL_BACKGROUND";

export const CLEAR_COMPANY_FUNCTIONAL_BACKGROUND = "CLEAR_COMPANY_FUNCTIONAL_BACKGROUND";

export const CLEAR_TARGET_LIST_ITEMS = "CLEAR_TARGET_LIST_ITEMS";

export const GET_TARGETLISTS_BY_OWNERID_INIT = "GET_TARGETLISTS_BY_OWNERID_INIT";
export const GET_TARGETLISTS_BY_OWNERID_DONE = "GET_TARGETLISTS_BY_OWNERID_DONE";
export const GET_TARGETLISTS_BY_OWNERID_FAIL = "GET_TARGETLISTS_BY_OWNERID_FAIL";

export const GET_TARGETLIST_CANDIDATES_INIT = "GET_TARGETLIST_CANDIDATES_INIT";
export const GET_TARGETLIST_CANDIDATES_DONE = "GET_TARGETLIST_CANDIDATES_DONE";

export const GET_TARGETLIST_CANDIDATE_IDS_INIT = "GET_TARGETLIST_CANDIDATE_IDS_INIT";

export const GET_TARGETLIST_CANDIDATE_IDS_FAIL = "GET_TARGETLIST_CANDIDATE_IDS_FAIL";
export const GET_TARGETLIST_CANDIDATE_IDS_DONE = "GET_TARGETLIST_CANDIDATE_IDS_DONE";

export const GET_PAGINATED_TL_COMPANIES_IN_PROGRESS = "GET_PAGINATED_TL_COMPANIES_IN_PROGRESS";

export const GET_PAGINATED_TL_COMPANIES_DONE = "GET_PAGINATED_TL_COMPANIES_DONE";

export const GET_PROJECT_CANDIDATE_IDS_INIT = "GET_PROJECT_CANDIDATE_IDS_INIT";
export const GET_PROJECT_CANDIDATE_IDS_FAIL = "GET_PROJECT_CANDIDATE_IDS_FAIL";
export const GET_PROJECT_CANDIDATE_IDS_DONE = "GET_PROJECT_CANDIDATE_IDS_DONE";

export const GET_TARGETLIST_COMPANY_IDS_INIT = "GET_TARGETLIST_COMPANY_IDS_INIT";

export const GET_TARGETLIST_COMPANY_IDS_FAIL = "GET_TARGETLIST_COMPANY_IDS_FAIL";
export const GET_TARGETLIST_COMPANY_IDS_DONE = "GET_TARGETLIST_COMPANY_IDS_DONE";

export const GET_PROJECT_COMPANY_IDS_INIT = "GET_PROJECT_COMPANY_IDS_INIT";

export const GET_PROJECT_COMPANY_IDS_FAIL = "GET_PROJECT_COMPANY_IDS_FAIL";
export const GET_PROJECT_COMPANY_IDS_DONE = "GET_PROJECT_COMPANY_IDS_DONE";

export const PROJECT_SAVED_PEOPLE_FETCH_COMPLETED = "PROJECT_SAVED_PEOPLE_FETCH_COMPLETED";

export const PROJECT_SAVED_PEOPLE_FETCH_IN_PROGRESS = "PROJECT_SAVED_PEOPLE_FETCH_IN_PROGRESS";
export const SET_PROJECT_SAVED_PEOPLE_PAGINATION_INFO = "SET_PROJECT_SAVED_PEOPLE_PAGINATION_INFO";
export const SET_PROJECT_SAVED_COMPANIES_PAGINATION_INFO =
	"SET_PROJECT_SAVED_COMPANIES_PAGINATION_INFO";

export const SET_SAVED_PEOPLE_PAGINATION_INFO = "SET_SAVED_PEOPLE_PAGINATION_INFO";

export const SET_AP_CANDIDATES_WITH_UPDATED_SUMMARY = "SET_AP_CANDIDATES_WITH_UPDATED_SUMMARY";
export const SET_AP_CANDIDATES_WITH_RECOMMENDED_CANDIDATES =
	"SET_AP_CANDIDATES_FILTERED_BY_RECOMMENDED_CANDIDATES";
export const UPDATE_AP_CANDIDATE_BY_KEY = "UPDATE_AP_CANDIDATE_BY_KEY";
export const UPDATE_AP_CANDIDATE_BY_KEY_IN_LIST = "UPDATE_AP_CANDIDATE_BY_KEY_IN_LIST";
export const UPDATE_TARGETLIST_CANDIDATES_WITH_SUMMARY =
	"UPDATE_TARGETLIST_CANDIDATES_WITH_SUMMARY";

export const UPDATE_PROJECT_SAVED_PEOPLE_WITH_SUMMARY = "UPDATE_PROJECT_SAVED_PEOPLE_WITH_SUMMARY";

export const UPDATE_TARGETLIST_CANDIDATES_WITH_CONTACT_DETAILS =
	"UPDATE_TARGETLIST_CANDIDATES_WITH_CONTACT_DETAILS";

export const UPDATE_FILTERED_TARGETLIST_CANDIDATES_WITH_CONTACT_DETAILS =
	"UPDATE_FILTERED_TARGETLIST_CANDIDATES_WITH_CONTACT_DETAILS";
export const GET_TARGETLIST_CANDIDATES_FAIL = "GET_TARGETLIST_CANDIDATES_FAIL";

export const GET_TARGETLIST_COMPANIES_INIT = "GET_TARGETLIST_COMPANIES_INIT";
export const GET_TARGETLIST_COMPANIES_DONE = "GET_TARGETLIST_COMPANIES_DONE";
export const GET_TARGETLIST_COMPANIES_FAIL = "GET_TARGETLIST_COMPANIES_FAIL";

export const GET_PROJECT_SAVED_COMPANIES_INIT = "GET_PROJECT_SAVED_COMPANIES_INIT";
export const GET_PROJECT_SAVED_COMPANIES_DONE = "GET_PROJECT_SAVED_COMPANIES_DONE";
export const GET_PROJECT_SAVED_COMPANIES_FAIL = "GET_PROJECT_SAVED_COMPANIES_FAIL";

export const SET_PROJECTS_LIST_PAGINATION_INFO = "SET_PROJECTS_LIST_PAGINATION_INFO";

export const GET_PROJECT_LIST_INIT = "GET_PROJECT_LIST_INIT";

export const GET_LATEST_PROJECT_LIST_INIT = "GET_LATEST_PROJECT_LIST_INIT";
export const GET_PROJECT_LIST_DONE = "GET_PROJECT_LIST_DONE";

export const GET_LATEST_PROJECT_LIST_DONE = "GET_LATEST_PROJECT_LIST_DONE";
export const GET_PROJECT_LIST_FAIL = "GET_PROJECT_LIST_FAIL";

export const GET_LATEST_PROJECT_LIST_FAIL = "GET_LATEST_PROJECT_LIST_FAIL";

export const UPDATE_PROJECT_LIST_INIT = "UPDATE_PROJECT_LIST_INIT";
export const UPDATE_PROJECT_LIST_DONE = "UPDATE_PROJECT_LIST_DONE";
export const UPDATE_PROJECT_LIST_FAIL = "UPDATE_PROJECT_LIST_FAIL";

export const SET_PROJECT_WORKFLOW_STEP = "SET_PROJECT_WORKFLOW_STEP";
export const SET_PROJECT_FORM = "SET_PROJECT_FORM";
export const CREATE_PROJECT_MODAL = "CREATE_PROJECT_MODAL";
export const EDIT_PROJECT_MODAL = "EDIT_PROJECT_MODAL";

export const DUPLICATE_PROJECT_MODAL = "DUPLICATE_PROJECT_MODAL";

// Login Action Types
export const LOGIN_FETCH = "LOGIN_FETCH";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGOUT = "LOGOUT";

// Username Action Types
export const GET_USER_NAME_FETCH = "GET_USER_NAME_FETCH";
export const GET_USER_NAME_SUCCESS = "GET_USER_NAME_SUCCESS";
export const GET_USER_NAME_FAILED = "GET_USER_NAME_FAILED";

export const LOAD_PROFILE_INIT = "LOAD_PROFILE_INIT";
export const LOAD_PROFILE_DONE = "LOAD_PROFILE_DONE";
export const LOAD_PROFILE_FAIL = "LOAD_PROFILE_FAIL";

export const SET_GENDER_DIVERSITY_DATA = "SET_GENDER_DIVERSITY_DATA";
export const SET_ETHNIC_DIVERSITY_DATA = "SET_ETHNIC_DIVERSITY_DATA";
export const SET_JOB_FUNCTION_DISTRIBUTION_INIT = "SET_JOB_FUNCTION_DISTRIBUTION_INIT";
export const SET_JOB_FUNCTION_DISTRIBUTION_DATA = "SET_JOB_FUNCTION_DISTRIBUTION_DATA";
export const SET_COUNTRY_REPORT = "SET_COUNTRY_REPORT";
export const SET_PROJECT_FREQUENTLY_HIRED_FROM_DATA = "SET_PROJECT_FREQUENTLY_HIRED_FROM_DATA";

export const SET_PROJECT_STATS = "SET_PROJECT_STATS";

export const SET_PROJECT_STATS_INIT = "SET_PROJECT_STATS_INIT";

export const SET_PROJECT_STATS_FAILED = "SET_PROJECT_STATS_FAILED";

export const SET_COMPANY_ETHNIC_DIVERSITY_INIT = "SET_COMPANY_GENDER_DIVERSITY_DATA";

export const SET_COMPANY_GENDER_DIVERSITY_INIT = "SET_COMPANY_GENDER_DIVERSITY_INIT";

export const SET_COMPANY_GENDER_DIVERSITY_DATA = "SET_COMPANY_GENDER_DIVERSITY_DATA";
export const SET_COMPANY_ETHNIC_DIVERSITY_DATA = "SET_COMPANY_ETHNIC_DIVERSITY_DATA";

export const CLEAR_COMPANY_ORGANIZATIONAL_CHART = "CLEAR_COMPANY_ORGANIZATIONAL_CHART";
export const SET_COMPANY_TRENDS_STATS_INIT = "SET_COMPANY_TRENDS_STATS_INIT";

export const SET_COMPANY_TRENDS_STATS = "SET_COMPANY_TRENDS_STATS";
export const SET_COMPANY_FREQUENTLY_HIRED_FROM_DATA = "SET_COMPANY_FREQUENTLY_HIRED_FROM_DATA";

// ORGANISATION ACTION
export const GET_ORGANISATIONS_INIT = "GET_ORGANISATIONS_INIT";
export const GET_ORGANISATIONS_DONE = "GET_ORGANISATIONS_DONE";
export const GET_ORGANISATIONS_FAIL = "GET_ORGANISATIONS_FAIL";

export const UPDATE_CREDITS_USED_KEY = "UPDATE_CREDITS_USED_KEY";

export const CREATE_ORGANISATION_INIT = "CREATE_ORGANISATION_INIT";
export const CREATE_ORGANISATION_DONE = "CREATE_ORGANISATION_DONE";
export const CREATE_ORGANISATION_FAIL = "CREATE_ORGANISATION_FAIL";

export const DELETE_ORGANIZATION_INIT = "DELETE_ORGANIZATION_INIT";
export const DELETE_ORGANIZATION_DONE = "DELETE_ORGANIZATION_DONE";
export const DELETE_ORGANIZATION_FAIL = "DELETE_ORGANIZATION_FAIL";

export const UPDATE_ORGANIZATION_INIT = "UPDATE_ORGANIZATION_INIT";
export const UPDATE_ORGANIZATION_DONE = "UPDATE_ORGANIZATION_DONE";
export const UPDATE_ORGANIZATION_FAIL = "UPDATE_ORGANIZATION_FAIL";

export const CREATE_INVITATION_INIT = "CREATE_INVITATION_INIT";
export const CREATE_INVITATION_DONE = "CREATE_INVITATION_DONE";
export const CREATE_INVITATION_FAIL = "CREATE_INVITATION_FAIL";

export const SET_NEW_ORGANISATION_NAME = "SET_NEW_ORGANISATION_NAME";

export const REVOKE_USER_INIT = "REVOKE_USER_INIT";
export const REVOKE_USER_DONE = "REVOKE_USER_DONE";
export const REVOKE_USER_FAIL = "REVOKE_USER_FAIL";

export const RESEND_INVITATION_INIT = "RESEND_INVITATION_INIT";
export const RESEND_INVITATION_DONE = "RESEND_INVITATION_DONE";
export const RESEND_INVITATION_FAIL = "RESEND_INVITATION_FAIL";

export const VERIFY_INVITATION_INIT = "VERIFY_INVITATION_INIT";
export const VERIFY_INVITATION_FAIL = "VERIFY_INVITATION_FAIL";
export const VERIFY_INVITATION_DONE = "VERIFY_INVITATION_DONE";

export const GET_ORGANIZATION_LIST_INIT = "GET_ORGANIZATION_LIST_INIT";
export const GET_ORGANIZATION_LIST_FAIL = "GET_ORGANIZATION_LIST_FAIL";
export const GET_ORGANIZATION_LIST_DONE = "GET_ORGANIZATION_LIST_DONE";
export const GET_ORGANIZATION_TABLE_DATA_INIT = "GET_ORGANIZATION_TABLE_DATA_INIT";
export const GET_ORGANIZATION_TABLE_DATA_FAIL = "GET_ORGANIZATION_TABLE_DATA_FAIL";
export const GET_ORGANIZATION_TABLE_DATA_DONE = "GET_ORGANIZATION_TABLE_DATA_DONE";
export const SET_ORGANIZATION_LIST_ORDERING = "SET_ORGANIZATION_LIST_ORDERING";

export const GET_ORGANISATION_USERS_INIT = "GET_ORGANISATION_USERS_INIT";
export const GET_ORGANISATION_USERS_DONE = "GET_ORGANISATION_USERS_DONE";
export const GET_ORGANISATION_USERS_FAIL = "GET_ORGANISATION_USERS_FAIL";

export const REVOKE_INVITATION_INIT = "REVOKE_INVITATION_INIT";
export const REVOKE_INVITATION_DONE = "REVOKE_INVITATION_DONE";
export const REVOKE_INVITATION_FAIL = "REVOKE_INVITATION_FAIL";

export const SORT_CURRENT_USERS = "SORT_CURRENT_USERS";
export const SORT_INVITED_USERS = "SORT_INVITED_USERS";

// PROJECT MODAL IsOPEN POSITION
export const OPEN_PROJECT_MODAL = "OPEN_PROJECT_MODAL";

// IMPORT LINKEDIN PROFILE
export const IMPORT_LINKEDIN_PROFILE_IN_PROGRESS = "IMPORT_LINKEDIN_PROFILE_IN_PROGRESS";
export const SET_IMPORT_LINKEDIN_PROFILE_INFO = "SET_IMPORT_LINKEDIN_PROFILE_INFO";
export const REFRESH_LINKEDIN_PROFILE_IN_PROGRESS = "REFRESH_LINKEDIN_PROFILE_IN_PROGRESS";
export const CURRENT_REFRESH_LINKEDIN_PROFILE_SCRAPE_INFO =
	"CURRENT_REFRESH_LINKEDIN_PROFILE_SCRAPE_INFO";
export const UPDATE_CANDIDATE_PROFILE_AFTER_REFRESH = "UPDATE_CANDIDATE_PROFILE_AFTER_REFRESH";

export const CLEAR_REFRESH_LINKEDIN_PROFILE_SCRAPE_INFO =
	"CLEAR_REFRESH_LINKEDIN_PROFILE_SCRAPE_INFO";

export const SEARCH_LINKEDIN_PROFILE_IN_PROGRESS_FOR_CURRENT_IMPORT =
	"SEARCH_LINKEDIN_PROFILE_IN_PROGRESS_FOR_CURRENT_IMPORT";

export const SET_BULK_IMPORT_ID_FOR_COMPANY = "SET_BULK_IMPORT_ID_FOR_COMPANY";

export const GET_CANDIDATES_BY_FILTER_DONE = "GET_CANDIDATES_BY_FILTER_DONE";

export const SET_CANDIDATES_PAGINATION_INFO = "SET_CANDIDATES_PAGINATION_INFO";

export const SET_IMPORT_LINKEDIN_PROFILE_INFO_FOR_CURRENT_IMPORT =
	"SET_IMPORT_LINKEDIN_PROFILE_INFO_FOR_CURRENT_IMPORT";

export const SET_IMPORT_LINKEDIN_PROFILE_INFO_FOR_COMPANY =
	"SET_IMPORT_LINKEDIN_PROFILE_INFO_FOR_COMPANY";

export const SET_LAST__COMPLETE_IMPORT_LINKEDIN_PROFILE_INFO_FOR_PROJECT =
	"SET_LAST__COMPLETE_IMPORT_LINKEDIN_PROFILE_INFO_FOR_PROJECT";

export const CLEAR_IMPORT_LINKEDIN_PROFILE_INFO_FOR_PROJECT =
	"CLEAR_IMPORT_LINKEDIN_PROFILE_INFO_FOR_PROJECT";

export const CLEAR_IMPORT_LINKEDIN_PROFILE_INFO_FOR_IMPORT_BY_ID =
	"CLEAR_IMPORT_LINKEDIN_PROFILE_INFO_FOR_IMPORT_BY_ID";
export const PROJECT_CANDIDATE_ASSESSMENT_PROCESS_REQUEST_CREATE_IN_PROGRESS =
	"PROJECT_CANDIDATE_ASSESSMENT_PROCESS_REQUEST_CREATE_IN_PROGRESS";
export const SET_PROJECT_CANDIDATE_ASSESSMENT_PROCESS = "SET_PROJECT_CANDIDATE_ASSESSMENT_PROCESS";
export const UPDATE_CANDIDATE_ASSESSMENT_STATUS_ON_BT = "UPDATE_CANDIDATE_ASSESSMENT_STATUS_ON_BT";
export const CLEAR_PROJECT_CANDIDATE_ASSESSMENT_PROCESS =
	"CLEAR_PROJECT_CANDIDATE_ASSESSMENT_PROCESS";
export const ACTIVE_ADD_PEOPLE_MODAL_FOR_PROJECT = "ACTIVE_ADD_PEOPLE_MODAL_FOR_PROJECT";

export const SET_BACKGROUND_BULK_IMPORTS_IN_PROGRESS_TO_WATCHER =
	"SET_BACKGROUND_BULK_IMPORTS_IN_PROGRESS_TO_WATCHER";

export const BACKGROUND_BULK_IMPORTS_IN_PROGRESS = "BACKGROUND_BULK_IMPORTS_IN_PROGRESS";
export const SET_SHOW_LAST_IMPORT_REPORT = "SET_SHOW_LAST_IMPORT_REPORT";
export const SET_SHOW_IMPORT_REPORT = "SET_SHOW_IMPORT_REPORT";

export const SET_NON_CLOSED_PROJECT_ID_FOR_LINKEDIN_BULK_IMPORT =
	"SET_NON_CLOSED_PROJECT_ID_FOR_LINKEDIN_BULK_IMPORT";
export const RESET_BULK_IMPORT_STATES_FOR_PROJECT = "RESET_BULK_IMPORT_STATES";

export const RESET_BULK_IMPORT_STATES_FOR_COMPANY = "RESET_BULK_IMPORT_STATES_FOR_COMPANY";

export const REMOVE_IMPORT_DATA_FROM_BULK_IMPORT_STATE =
	"REMOVE_IMPORT_DATA_FROM_BULK_IMPORT_STATE";

export const REMOVE_REFRESH_DATA_FROM_BULK_IMPORT_STATE =
	"REMOVE_REFRESH_DATA_FROM_BULK_IMPORT_STATE";

export const SET_SHOW_LAST_IMPORT_REPORT_FOR_COMPANY = "SET_SHOW_LAST_IMPORT_REPORT_FOR_COMPANY";

// LEARN TO RANK
export const CREATE_LEARN_TO_RANK_INIT = "CREATE_LEARN_TO_RANK_INIT";
export const CREATE_LEARN_TO_RANK_DONE = "CREATE_LEARN_TO_RANK_DONE";
export const CREATE_LEARN_TO_RANK_FAIL = "CREATE_LEARN_TO_RANK_FAIL";

export const RECORD_LEARN_TO_RANK_INIT = "RECORD_LEARN_TO_RANK_INIT";
export const RECORD_LEARN_TO_RANK_DONE = "RECORD_LEARN_TO_RANK_DONE";
export const RECORD_LEARN_TO_RANK_FAIL = "RECORD_LEARN_TO_RANK_FAIL";

export const GET_LEARN_TO_RANK_INIT = "GET_LEARN_TO_RANK_INIT";
export const GET_LEARN_TO_RANK_DONE = "GET_LEARN_TO_RANK_DONE";
export const GET_LEARN_TO_RANK_FAIL = "GET_LEARN_TO_RANK_FAIL";

// MARKET MAPS
export const GET_MARKET_MAPS_INIT = "GET_MARKET_MAPS_INIT";
export const GET_MARKET_MAPS_DONE = "GET_MARKET_MAPS_DONE";
export const GET_MARKET_MAPS_FAIL = "GET_MARKET_MAPS_FAIL";
export const SET_MARKET_MAPS_PAGINATION_INFO = "SET_MARKET_MAPS_PAGINATION_INFO";

export const GET_MARKET_MAPS_FOR_MODAL_INIT = "GET_MARKET_MAPS_FOR_MODAL_INIT";
export const GET_MARKET_MAPS_FOR_MODAL_DONE = "GET_MARKET_MAPS_FOR_MODAL_DONE";
export const GET_MARKET_MAPS_FOR_MODAL_FAIL = "GET_MARKET_MAPS_FOR_MODAL_FAIL";

export const CREATE_MARKET_MAP_INIT = "CREATE_MARKET_MAP_INIT";
export const CREATE_MARKET_MAP_DONE = "CREATE_MARKET_MAP_DONE";
export const CREATE_MARKET_MAP_FAIL = "CREATE_MARKET_MAP_FAIL";

export const UPDATE_MARKET_MAP_INIT = "UPDATE_MARKET_MAP_INIT";
export const UPDATE_MARKET_MAP_DONE = "UPDATE_MARKET_MAP_DONE";
export const UPDATE_MARKET_MAP_FAIL = "UPDATE_MARKET_MAP_FAIL";

export const RESET_MARKET_MAP_ERROR = "RESET_MARKET_MAP_ERROR";

export const CREATE_MARKET_MAP_MODAL = "CREATE_MARKET_MAP_MODAL";
export const EDIT_MARKET_MAP_MODAL = "EDIT_MARKET_MAP_MODAL";
export const ADD_FROM_MARKET_MAP_MODAL = "ADD_FROM_MARKET_MAP_MODAL";
export const ADD_FROM_MARKET_MAP_MODAL_FROM_MR = "ADD_FROM_MARKET_MAP_MODAL_FROM_MR";
export const ADD_TO_MARKET_MAP_MODAL = "ADD_TO_MARKET_MAP_MODAL";
export const DUPLICATE_MARKET_MAP_MODAL = "DUPLICATE_MARKET_MAP_MODAL";

export const DOWNLOAD_MARKET_MAPS_PNG_INIT = "DOWNLOAD_MARKET_MAPS_PNG_INIT";
export const DOWNLOAD_MARKET_MAPS_PNG_DONE = "DOWNLOAD_MARKET_MAPS_PNG_DONE";
export const DOWNLOAD_MARKET_MAPS_PNG_FAIL = "DOWNLOAD_MARKET_MAPS_PNG_FAIL";

export const DELETE_MARKET_MAP_INIT = "DELETE_MARKET_MAP_INIT";
export const DELETE_MARKET_MAP_DONE = "DELETE_MARKET_MAP_DONE";
export const DELETE_MARKET_MAP_FAIL = "DELETE_MARKET_MAP_FAIL";

export const GET_INVITATIONS_INIT = "GET_INVITATIONS_INIT";
export const GET_INVITATIONS_DONE = "GET_INVITATIONS_DONE";
export const GET_INVITATIONS_FAIL = "GET_INVITATIONS_FAIL";

export const GET_SEATS_INIT = "GET_SEATS_INIT";
export const GET_SEATS_DONE = "GET_SEATS_DONE";
export const GET_SEATS_FAIL = "GET_SEATS_FAIL";

// MARKET REPORT

export const GET_MARKET_REPORTS_INIT = "GET_MARKET_REPORTS_INIT";
export const GET_MARKET_REPORTS_DONE = "GET_MARKET_REPORTS_DONE";
export const GET_MARKET_REPORTS_FAIL = "GET_MARKET_REPORTS_FAIL";
export const SET_MARKET_REPORTS_PAGINATION_INFO = "SET_MARKET_REPORTS_PAGINATION_INFO";
export const DISPLAY_MARKET_REPORT_MODAL = "DISPLAY_MARKET_REPORT_MODAL";
export const RESET_MARKET_REPORT_FORM = "RESET_MARKET_REPORT_FORM";
export const SET_MARKET_REPORT_NAME_IN_FORM = "SET_MARKET_REPORT_NAME_IN_FORM";
export const SET_MARKET_REPORT_FILTER_VALUE_IN_FORM = "SET_MARKET_REPORT_FILTER_VALUE_IN_FORM";
export const SET_MARKET_REPORT_MULTIPLE_FILTER_VALUE_IN_FORM =
	"SET_MARKET_REPORT_MULTIPLE_FILTER_VALUE_IN_FORM";
export const SET_MARKET_REPORT_FORM_DATA = "SET_MARKET_REPORT_FORM_DATA";
export const RESET_MARKET_REPORT_FORM_DATA = "RESET_MARKET_REPORT_FORM_DATA";

export const CREATE_MARKET_REPORT_INIT = "CREATE_MARKET_REPORT_INIT";
export const CREATE_MARKET_REPORT_DONE = "CREATE_MARKET_REPORT_DONE";
export const CREATE_MARKET_REPORT_FAIL = "CREATE_MARKET_REPORT_FAIL";
export const SET_MARKET_REPORT_DETAILS = "SET_MARKET_REPORT_DETAILS";
export const CLEAR_MARKET_REPORT_DETAILS = "CLEAR_MARKET_REPORT_DETAILS";

export const UPDATE_MARKET_REPORT_INIT = "UPDATE_MARKET_REPORT_INIT";
export const UPDATE_MARKET_REPORT_DONE = "UPDATE_MARKET_REPORT_DONE";
export const UPDATE_MARKET_REPORT_FAIL = "UPDATE_MARKET_REPORT_FAIL";

export const DELETE_MARKET_REPORT_INIT = "DELETE_MARKET_REPORT_INIT";
export const DELETE_MARKET_REPORT_DONE = "DELETE_MARKET_REPORT_DONE";
export const DELETE_MARKET_REPORT_FAIL = "DELETE_MARKET_REPORT_FAIL";

export const SET_MR_COMPANY_FILTER_CONFIG = "SET_MR_COMPANY_FILTER_CONFIG";
export const SET_MR_INDUSTRIES_FILTER_CONFIG = "SET_MR_INDUSTRIES_FILTER_CONFIG";
export const SET_MR_LOCATIONS_FILTER_CONFIG = "SET_MR_LOCATIONS_FILTER_CONFIG";
export const SET_MR_JOB_FUNCTION_FILTER_CONFIG = "SET_MR_JOB_FUNCTION_FILTER_CONFIG";
export const SET_MR_KEYWORD_FILTER_CONFIG = "SET_MR_KEYWORD_FILTER_CONFIG";
export const SET_MR_JOB_TITLE_FILTER_MODE = "SET_MR_JOB_TITLE_FILTER_MODE";
export const SET_MR_HEADCOUNT_FILTER_CONFIG = "SET_MR_HEADCOUNT_FILTER_CONFIG";
export const SET_MR_REVENUE_FILTER_CONFIG = "SET_MR_REVENUE_FILTER_CONFIG";
export const SET_MR_COMPANY_TYPE_FILTER_CONFIG = "SET_MR_COMPANY_TYPE_FILTER_CONFIG";
export const SET_MR_JOB_TITLE_MODE = "SET_MR_JOB_TITLE_MODE";
export const SET_MR_COMPANIES_MODE = "SET_MR_COMPANIES_MODE";

export const DISPLAY_DIVERSITY_STATS_LOADING = "DISPLAY_DIVERSITY_STATS_LOADING";
export const SET_DIVERSITY_STATS_SELECTED_TYPE = "SET_DIVERSITY_STATS_SELECTED_TYPE";
export const SET_DIVERSITY_STATS_REPORT = "SET_DIVERSITY_STATS_REPORT";
export const DISPLAY_DIVERSITY_PATHWAYS_LOADING = "DISPLAY_DIVERSITY_PATHWAYS_LOADING";
export const SET_DIVERSITY_PATHWAYS_SELECTED_TYPE = "SET_DIVERSITY_PATHWAYS_SELECTED_TYPE";
export const SET_DIVERSITY_PATHWAYS_MODE = "SET_DIVERSITY_PATHWAYS_MODE";
export const SET_DIVERSITY_PATHWAYS_REPORT = "SET_DIVERSITY_PATHWAYS_REPORT";
export const SET_MR_DISABLED_FILTER_KEYS = "SET_MR_DISABLED_FILTER_KEYS";

// PAYMENT
export const PRICING_TABLE_MODAL = "PRICING_TABLE_MODAL";
export const UPDATE_PLAN_MODAL = "UPDATE_PLAN_MODAL";
export const CONTACT_ADMIN_MODAL = "CONTACT_ADMIN_MODAL";

export const GET_PRODUCTS_INIT = "GET_PRODUCTS_INIT";
export const GET_PRODUCTS_DONE = "GET_PRODUCTS_DONE";
export const GET_PRODUCTS_FAIL = "GET_PRODUCTS_FAIL";

export const OPEN_CHECKOUT_INIT = "OPEN_CHECKOUT_INIT";
export const OPEN_CHECKOUT_DONE = "OPEN_CHECKOUT_DONE";
export const OPEN_CHECKOUT_FAIL = "OPEN_CHECKOUT_FAIL";

export const OPEN_CUSTOMER_PORTAL_INIT = "OPEN_CUSTOMER_PORTAL_INIT";
export const OPEN_CUSTOMER_PORTAL_DONE = "OPEN_CUSTOMER_PORTAL_DONE";
export const OPEN_CUSTOMER_PORTAL_FAIL = "OPEN_CUSTOMER_PORTAL_FAIL";

export const SET_EXPANDED_CRITERIONS_FOR_PROJECT = "SET_EXPANDED_CRITERIONS_FOR_PROJECT";

export const SET_PROJECT_CANDIDATES_SELECTION_IN_PROGRESS =
	"SET_PROJECT_CANDIDATES_SELECTION_IN_PROGRESS";
export const SET_EXPANDED_CANDIDATE_IDS_FOR_PROJECT = "SET_EXPANDED_CANDIDATE_IDS_FOR_PROJECT";

export const SET_CRITERIA_FOR_PROJECT_CANDIDATE_ASSESSMENT =
	"SET_CRITERIA_FOR_PROJECT_CANDIDATE_ASSESSMENT";
export const SET_CANDIDATE_IDS_FOR_ASSESSMENT = "SET_CANDIDATE_IDS_FOR_ASSESSMENT";
export const SET_ACTIVE_PROJECT_REPORT_VIEW_ON_CANDIDATE_CARD =
	"SET_ACTIVE_PROJECT_REPORT_VIEW_ON_CANDIDATE_CARD";
export const SET_PROJECT_CHECKLIST_FULL_DISPLAY = "SET_PROJECT_CHECKLIST_FULL_DISPLAY";
export const UPDATE_PROJECT_SUCCESS_CHECKLIST = "UPDATE_PROJECT_SUCCESS_CHECKLIST";

export const SET_PROJECT_REPORT_TABLE_ROWS = "SET_PROJECT_REPORT_TABLE_ROWS";

// Starter user, onboarding
export const SET_ONBOARDING_VISIBILITY = "SET_ONBOARDING_VISIBILITY";
export const SET_ONBOARDING_STEP = "SET_ONBOARDING_STEP";

export const SET_GENERATE_SUMMARY_CANDIDATE_IDS_FOR_CURRENT_PROJECT =
	"SET_GENERATE_SUMMARY_CANDIDATE_IDS_FOR_CURRENT_PROJECT";

export const SET_GENERATE_CONTACT_DETAILS_CANDIDATE_IDS_FOR_ALL_PROJECT =
	"SET_GENERATE_CONTACT_DETAILS_CANDIDATE_IDS_FOR_ALL_PROJECT";

//Candidate company events
export const SET_CANDIDATE_COMPANY_EVENTS_SEARCH_QUERY =
	"SET_CANDIDATE_COMPANY_EVENTS_SEARCH_QUERY";

export const GET_CANDIDATE_COMPANY_EVENTS_SUCCESS = "GET_CANDIDATE_COMPANY_EVENTS_SUCCESS";

export const GET_CANDIDATE_COMPANY_EVENTS_FETCH = "GET_CANDIDATE_COMPANY_EVENTS_FETCH";

export const GET_CANDIDATE_COMPANY_EVENT_SIZE_RANGE_SUCCESS =
	"GET_CANDIDATE_COMPANY_EVENT_SIZE_RANGE_SUCCESS";

export const GET_CANDIDATE_COMPANY_DATE_RANGE_SUCCESS = "GET_CANDIDATE_COMPANY_DATE_RANGE_SUCCESS";

// Sidebar info
export const SET_SIDEBAR_EXTENDED = "SET_SIDEBAR_EXTENDED";

export const SET_SIDEBAR_CHILDITEMS_EXPANDED = "SET_SIDEBAR_CHILDITEMS_EXPANDED";

export const SET_PER_PAGE_PREFERENCE = "SET_PER_PAGE_PREFERENCE";
export const SET_PROJECT_LAYOUT_VIEW_PREFERENCE = "SET_PROJECT_LAYOUT_VIEW_PREFERENCE";

export const SET_PRESENTATION_AVAILABLE_TEMPLATES = "SET_PRESENTATION_AVAILABLE_TEMPLATES";
export const SET_SELECTED_PRESENTATION_TEMPLATE = "SET_SELECTED_PRESENTATION_TEMPLATE";
export const BULK_PROJECT_CANDIDATE_STATUS_CHANGE = "BULK_PROJECT_CANDIDATE_STATUS_CHANGE";

export const SET_CURRENT_PROJECT_ID = "SET_CURRENT_PROJECT_ID";

export const SET_SELECTED_TRACK_CANDIDATE_IDS = "SET_SELECTED_TRACK_CANDIDATE_IDS";
export const SET_SELECTED_TRACK_CANDIDATE_POSITION_CHANGED_DAYS =
	"SET_SELECTED_TRACK_CANDIDATE_POSITION_CHANGED_DAYS";

export const SET_PROJECT_CANDIDATE_TRACK_LEAVERS = "SET_PROJECT_CANDIDATE_TRACK_LEAVERS";
export const FETCHING_PROJECT_CANDIDATE_TRACK_LEAVERS = "FETCHING_PROJECT_CANDIDATE_TRACK_LEAVERS";

export const SET_PROJECT_CANDIDATE_TRACK_PROMOTIONS = "SET_PROJECT_CANDIDATE_TRACK_PROMOTIONS";
export const FETCHING_PROJECT_CANDIDATE_TRACK_PROMOTIONS =
	"FETCHING_PROJECT_CANDIDATE_TRACK_PROMOTIONS";

export const SET_PROJECT_CANDIDATE_TRACK_NO_RECENT_CHANGES =
	"SET_PROJECT_CANDIDATE_TRACK_NO_RECENT_CHANGES";
export const FETCHING_PROJECT_CANDIDATE_TRACK_NO_RECENT_CHANGES =
	"FETCHING_PROJECT_CANDIDATE_TRACK_NO_RECENT_CHANGES";
