import {
	ADD_CANDIDATE_TO_TARGET_LIST_DONE,
	ADD_CANDIDATE_TO_TARGET_LIST_FAIL,
	ADD_CANDIDATE_TO_TARGET_LIST_INIT,
	ADD_COMPANIES_FROM_MARKET_MAP_TO_TARGET_LIST_DONE,
	ADD_COMPANIES_FROM_MARKET_MAP_TO_TARGET_LIST_FAIL,
	ADD_COMPANIES_FROM_MARKET_MAP_TO_TARGET_LIST_INIT,
	ADD_COMPANY_TO_TARGET_LIST_DONE,
	ADD_COMPANY_TO_TARGET_LIST_FAIL,
	ADD_COMPANY_TO_TARGET_LIST_INIT,
	CLEAR_TARGET_LIST_ITEMS,
	CREATE_DETACHED_TARGET_LIST_DONE,
	CREATE_DETACHED_TARGET_LIST_FAIL,
	CREATE_DETACHED_TARGET_LIST_INIT,
	GET_PAGINATED_TL_COMPANIES_DONE,
	GET_PAGINATED_TL_COMPANIES_IN_PROGRESS,
	GET_TARGETLIST_CANDIDATE_IDS_DONE,
	GET_TARGETLIST_CANDIDATE_IDS_FAIL,
	GET_TARGETLIST_CANDIDATE_IDS_INIT,
	GET_TARGETLIST_CANDIDATES_DONE,
	GET_TARGETLIST_CANDIDATES_FAIL,
	GET_TARGETLIST_CANDIDATES_INIT,
	GET_TARGETLIST_COMPANIES_DONE,
	GET_TARGETLIST_COMPANIES_FAIL,
	GET_TARGETLIST_COMPANIES_INIT,
	GET_TARGETLIST_COMPANY_IDS_DONE,
	GET_TARGETLIST_COMPANY_IDS_FAIL,
	GET_TARGETLIST_COMPANY_IDS_INIT,
	GET_TARGETLISTS_BY_OWNERID_DONE,
	GET_TARGETLISTS_BY_OWNERID_FAIL,
	GET_TARGETLISTS_BY_OWNERID_INIT,
	LOGOUT,
	REMOVE_CANDIDATE_FROM_TARGET_LIST_DONE,
	REMOVE_CANDIDATE_FROM_TARGET_LIST_FAIL,
	REMOVE_CANDIDATE_FROM_TARGET_LIST_INIT,
	REMOVE_CANDIDATES_FROM_TARGET_LIST_DONE,
	REMOVE_CANDIDATES_FROM_TARGET_LIST_FAIL,
	REMOVE_CANDIDATES_FROM_TARGET_LIST_INIT,
	REMOVE_COMPANIES_FROM_TARGET_LIST_DONE,
	REMOVE_COMPANIES_FROM_TARGET_LIST_FAIL,
	REMOVE_COMPANIES_FROM_TARGET_LIST_INIT,
	REMOVE_COMPANY_FROM_TARGET_LIST_DONE,
	REMOVE_COMPANY_FROM_TARGET_LIST_FAIL,
	REMOVE_COMPANY_FROM_TARGET_LIST_INIT,
	SAVE_CANDIDATES_BY_FILTERS_DONE,
	SAVE_CANDIDATES_BY_FILTERS_INIT,
	SAVE_COMPANIES_BY_FILTERS_DONE,
	SAVE_COMPANIES_BY_FILTERS_INIT,
	SAVE_PROJECT_VIA_MODAL_DONE,
	SET_AP_CANDIDATES_WITH_RECOMMENDED_CANDIDATES,
	SET_AP_CANDIDATES_WITH_UPDATED_SUMMARY,
	SET_SAVED_PEOPLE_PAGINATION_INFO,
	UPDATE_AP_CANDIDATE_BY_KEY,
	UPDATE_AP_CANDIDATE_BY_KEY_IN_LIST,
	UPDATE_TARGETLIST_CANDIDATES_WITH_CONTACT_DETAILS,
	UPDATE_TARGETLIST_CANDIDATES_WITH_SUMMARY,
} from "store/mapx/mapXActionTypes";

export const targetListInitialState = {
	addingCandidateToTargetListInProgress: [],
	addingCompanyToTargetListInProgress: [],
	creatingDetachedTargetListInProgress: false,
	project: null,
	removingCandidateFromTargetListInProgress: [],
	removingCompanyFromTargetListInProgress: [],

	targetListCandidateIDsFetchInProgress: false,
	targetListCandidateIDs: [],

	targetListCompanyIDsFetchInProgress: false,
	targetListCompanyIDs: [],

	targetListCandidateInProgress: false,
	targetListCandidates: [],
	targetCandidatesPagination: null,

	targetListCompanyInProgress: false,
	targetListCompanies: [],
	targetCompaniesPagination: null,

	targetListCompaniesForCandidateFilterInProgress: false,
	targetListCompaniesForCandidateFilter: [],
	targetListCompaniesForCandidateFilterPagination: null,

	targetLists: [],
	targetListsInProgress: false,
	savingCompaniesByFilterInProgress: false,
	savingCandidatesByFilterInProgress: false,
	removingCandidatesFromTargetListInProgress: false,
	removingCompaniesFromTargetListInProgress: false,
	adding_from_market_map_in_progress: false,
};

const targetListReducer = (state = targetListInitialState, action) => {
	switch (action.type) {
		case ADD_COMPANIES_FROM_MARKET_MAP_TO_TARGET_LIST_INIT:
			return {
				...state,
				adding_from_market_map_in_progress: true,
			};
		case ADD_COMPANIES_FROM_MARKET_MAP_TO_TARGET_LIST_DONE: {
			const ids = new Set(state.targetListCompanies.map((company) => company.id));

			return {
				...state,
				adding_from_market_map_in_progress: false,
				targetListCompanies: [
					...state.targetListCompanies,
					...action.payload.filter((company) => !ids.has(company.id)),
				],
			};
		}
		case ADD_COMPANIES_FROM_MARKET_MAP_TO_TARGET_LIST_FAIL:
			return {
				...state,
				adding_from_market_map_in_progress: false,
			};
		case SAVE_COMPANIES_BY_FILTERS_INIT:
			return {
				...state,
				savingCompaniesByFilterInProgress: true,
			};
		case SAVE_COMPANIES_BY_FILTERS_DONE:
			return {
				...state,
				savingCompaniesByFilterInProgress: false,
			};
		case SAVE_CANDIDATES_BY_FILTERS_INIT:
			return {
				...state,
				savingCandidatesByFilterInProgress: true,
			};
		case SAVE_CANDIDATES_BY_FILTERS_DONE:
			return {
				...state,
				savingCandidatesByFilterInProgress: false,
			};
		case CREATE_DETACHED_TARGET_LIST_INIT:
			return {
				...state,
				creatingDetachedTargetListInProgress: true,
			};
		case CREATE_DETACHED_TARGET_LIST_DONE: {
			const targetList = action.payload;

			return {
				...state,
				creatingDetachedTargetListInProgress: false,
				targetLists: [...state.targetLists, targetList],
			};
		}
		case CREATE_DETACHED_TARGET_LIST_FAIL:
			return {
				...state,
				creatingDetachedTargetListInProgress: false,
			};
		case SAVE_PROJECT_VIA_MODAL_DONE: {
			const project = action.payload;

			const index = state.targetLists.findIndex(
				(targetList) => targetList.id === project.target_list_id,
			);

			return {
				...state,
				targetLists:
					index === -1
						? [
								{
									id: project.target_list_id,
									project_id: project.id,
									name: null,
									owner_id: null, // TODO: set owner id
								},
								...state.targetLists,
						  ]
						: [
								...state.targetLists.slice(0, index),
								{
									id: project.target_list_id,
									project_id: project.id,
									name: null,
									owner_id: null, // TODO: set owner id
								},
								...state.targetLists.slice(index + 1),
						  ],
			};
		}
		case REMOVE_CANDIDATES_FROM_TARGET_LIST_INIT:
			return {
				...state,
				removingCandidatesFromTargetListInProgress: true,
			};
		case REMOVE_CANDIDATES_FROM_TARGET_LIST_DONE:
			return {
				...state,
				removingCandidatesFromTargetListInProgress: false,
				targetListCandidates: [],
				targetListCandidateIDs: [],
				targetCandidatesPagination: null,
			};
		case REMOVE_CANDIDATES_FROM_TARGET_LIST_FAIL:
			return {
				...state,
				removingCandidatesFromTargetListInProgress: false,
			};
		case REMOVE_COMPANIES_FROM_TARGET_LIST_INIT:
			return {
				...state,
				removingCompaniesFromTargetListInProgress: true,
			};
		case REMOVE_COMPANIES_FROM_TARGET_LIST_DONE:
			return {
				...state,
				removingCompaniesFromTargetListInProgress: false,
				targetListCompanies: [],
				targetListCompanyIDs: [],
				targetCompaniesPagination: {page: 1, per_page: 20},
			};
		case REMOVE_COMPANIES_FROM_TARGET_LIST_FAIL:
			return {
				...state,
				removingCompaniesFromTargetListInProgress: false,
			};
		case REMOVE_COMPANY_FROM_TARGET_LIST_INIT:
			return {
				...state,
				removingCompanyFromTargetListInProgress: [
					...state.removingCompanyFromTargetListInProgress,
					action.payload,
				],
			};
		case REMOVE_COMPANY_FROM_TARGET_LIST_DONE: {
			const {companyID} = action.payload;

			const prevCompPagination = state.targetCompaniesPagination
				? {...state.targetCompaniesPagination}
				: {count: 1};

			return {
				...state,
				removingCompanyFromTargetListInProgress:
					state.removingCompanyFromTargetListInProgress.filter((id) => id !== companyID),
				targetListCompanies: [...state.targetListCompanies].filter(
					(company) => company.id !== companyID,
				),
				targetCompaniesPagination: {
					...prevCompPagination,
					count: state.targetCompaniesPagination?.count - 1,
				},

				targetListCompaniesForCandidateFilter: [
					...state.targetListCompaniesForCandidateFilter,
				].filter((company) => company.id !== companyID),
				targetListCompaniesForCandidateFilterPagination: {
					...prevCompPagination,
					count: state.targetListCompaniesForCandidateFilterPagination?.count - 1,
				},

				targetListCompanyIDsFetchInProgress: false,
				targetListCompanyIDs: [
					...(state.targetListCompanyIDs?.filter((item) => item !== companyID) || []),
				],
			};
		}
		case REMOVE_COMPANY_FROM_TARGET_LIST_FAIL: {
			const {companyID} = action.payload;

			return {
				...state,
				removingCompanyFromTargetListInProgress:
					state.removingCompanyFromTargetListInProgress.filter((id) => id !== companyID),
			};
		}
		case REMOVE_CANDIDATE_FROM_TARGET_LIST_INIT:
			return {
				...state,
				removingCandidateFromTargetListInProgress: [
					...state.removingCandidateFromTargetListInProgress,
					action.payload,
				],
			};
		case REMOVE_CANDIDATE_FROM_TARGET_LIST_DONE: {
			const {candidate} = action.payload;

			const candidateID = candidate.id;

			const prevCandidatePagination = state.targetCandidatesPagination
				? {...state.targetCandidatesPagination}
				: {count: 0};

			return {
				...state,
				removingCandidateFromTargetListInProgress: [
					...state.removingCandidateFromTargetListInProgress,
				].filter((id) => id !== candidateID),
				targetListCandidates: [...state.targetListCandidates].filter(
					(candidate) => candidate.id !== candidateID,
				),
				targetCandidatesPagination: {
					...prevCandidatePagination,
					count: state.targetCandidatesPagination?.count - 1,
				},
				targetListCandidateIDsFetchInProgress: false,
				targetListCandidateIDs: [
					...(state.targetListCandidateIDs?.filter((item) => item !== candidateID) || []),
				],
			};
		}
		case REMOVE_CANDIDATE_FROM_TARGET_LIST_FAIL: {
			const {candidateID} = action.payload;

			return {
				...state,
				removingCandidateFromTargetListInProgress:
					state.removingCandidateFromTargetListInProgress.filter(
						(id) => id !== candidateID,
					),
			};
		}
		case ADD_COMPANY_TO_TARGET_LIST_INIT:
			return {
				...state,
				addingCompanyToTargetListInProgress: [
					...state.addingCompanyToTargetListInProgress,
					action.payload,
				],
			};
		case ADD_COMPANY_TO_TARGET_LIST_DONE: {
			const {company} = action.payload;

			const prevCompPagination = state.targetCompaniesPagination
				? {...state.targetCompaniesPagination}
				: {count: 0};

			return {
				...state,
				addingCompanyToTargetListInProgress:
					state.addingCompanyToTargetListInProgress.filter(
						(companyID) => companyID !== company.id,
					),
				targetListCompanies: [company, ...state.targetListCompanies],
				targetCompaniesPagination: {
					...prevCompPagination,
					count: state.targetCompaniesPagination?.count + 1 || 1,
				},
				targetListCompanyIDsFetchInProgress: false,
				targetListCompanyIDs: [...state.targetListCompanyIDs, company.id],
			};
		}
		case ADD_COMPANY_TO_TARGET_LIST_FAIL: {
			const {companyID} = action.payload;

			return {
				...state,
				addingCompanyToTargetListInProgress:
					state.addingCompanyToTargetListInProgress.filter((id) => id !== companyID),
			};
		}
		case ADD_CANDIDATE_TO_TARGET_LIST_INIT:
			return {
				...state,
				addingCandidateToTargetListInProgress: [
					...state.addingCandidateToTargetListInProgress,
					action.payload,
				],
			};
		case ADD_CANDIDATE_TO_TARGET_LIST_DONE: {
			const {candidate} = action.payload;

			const prevCandidatePagination = state.targetCandidatesPagination
				? {...state.targetCandidatesPagination}
				: {count: 0};

			return {
				...state,
				addingCandidateToTargetListInProgress:
					state.addingCandidateToTargetListInProgress.filter(
						(candidateID) => candidateID !== candidate.id,
					),
				targetListCandidates: [candidate, ...state.targetListCandidates],
				targetCandidatesPagination: {
					...prevCandidatePagination,
					count: state.targetCandidatesPagination?.count + 1 || 1,
				},
				targetListCandidateIDsFetchInProgress: false,
				targetListCandidateIDs: [...state.targetListCandidateIDs, candidate.id],
			};
		}
		case ADD_CANDIDATE_TO_TARGET_LIST_FAIL: {
			const {candidateID} = action.payload;

			return {
				...state,
				addingCandidateToTargetListInProgress:
					state.addingCandidateToTargetListInProgress.filter((id) => id !== candidateID),
			};
		}
		case GET_TARGETLISTS_BY_OWNERID_INIT:
			return {
				...state,
				targetListsInProgress: true,
			};
		case GET_TARGETLISTS_BY_OWNERID_DONE:
			return {
				...state,
				targetListsInProgress: false,
				targetLists: action.payload || [],
			};
		case GET_TARGETLISTS_BY_OWNERID_FAIL:
			return {
				...state,
				targetListsInProgress: false,
			};
		case GET_TARGETLIST_COMPANY_IDS_INIT:
			return {
				...state,
				targetListCompanyIDsFetchInProgress: true,
			};
		case GET_TARGETLIST_COMPANY_IDS_DONE:
			return {
				...state,
				targetListCompanyIDsFetchInProgress: false,
				targetListCompanyIDs: action.payload || [],
			};
		case GET_TARGETLIST_COMPANY_IDS_FAIL:
			return {
				...state,
				targetListCompanyIDsFetchInProgress: false,
			};
		case GET_TARGETLIST_CANDIDATE_IDS_INIT:
			return {
				...state,
				targetListCandidateIDsFetchInProgress: true,
			};
		case GET_TARGETLIST_CANDIDATE_IDS_DONE:
			return {
				...state,
				targetListCandidateIDsFetchInProgress: false,
				targetListCandidateIDs: action.payload || [],
			};
		case GET_TARGETLIST_CANDIDATE_IDS_FAIL:
			return {
				...state,
				targetListCandidateIDsFetchInProgress: false,
			};
		case GET_TARGETLIST_CANDIDATES_INIT:
			return {
				...state,
				targetListCandidateInProgress: true,
			};
		case GET_TARGETLIST_CANDIDATES_DONE:
			return {
				...state,
				targetListCandidateInProgress: false,
				targetListCandidates: action.payload?.results || [],
				targetCandidatesPagination: action.payload.pagination ?? null,
			};
		case SET_SAVED_PEOPLE_PAGINATION_INFO:
			return {
				...state,
				targetCandidatesPagination: action.payload,
			};
		case SET_AP_CANDIDATES_WITH_RECOMMENDED_CANDIDATES:
			return {
				...state,
				targetListCandidates: [
					...state.targetListCandidates.map((c) => {
						if (c.id === action.payload.id) {
							c.is_recommended = action.payload.is_recommended;
						}

						return c;
					}),
				],
			};
		case SET_AP_CANDIDATES_WITH_UPDATED_SUMMARY:
			return {
				...state,
				targetListCandidates: [
					...state.targetListCandidates.map((c) => {
						if (c.id === action.payload.id) {
							c.summary = action.payload.summary;
						}

						return c;
					}),
				],
			};
		case UPDATE_AP_CANDIDATE_BY_KEY:
			return {
				...state,
				targetListCandidates: [
					...state.targetListCandidates.map((c) => {
						if (c.id === action.payload.id) {
							c[action.payload.key] = action.payload.value;
						}

						return c;
					}),
				],
			};
		case UPDATE_AP_CANDIDATE_BY_KEY_IN_LIST:
			return {
				...state,
				targetListCandidates: [
					...state.targetListCandidates.map((c) => {
						if (c.id === action.payload.candidate_id) {
							c[action.payload.key] = action.payload.value;
						}

						return c;
					}),
				],
			};
		case UPDATE_TARGETLIST_CANDIDATES_WITH_SUMMARY:
			return {
				...state,
				targetListCandidates: action.payload,
			};
		case UPDATE_TARGETLIST_CANDIDATES_WITH_CONTACT_DETAILS:
			return {
				...state,
				targetListCandidates: action.payload,
			};
		case GET_TARGETLIST_CANDIDATES_FAIL:
			return {
				...state,
				targetListCandidateInProgress: false,
			};
		case GET_TARGETLIST_COMPANIES_INIT:
			return {
				...state,
				targetListCompanyInProgress: true,
			};
		case GET_PAGINATED_TL_COMPANIES_IN_PROGRESS:
			return {
				...state,
				targetListCompaniesForCandidateFilterInProgress: action.payload,
			};
		case GET_TARGETLIST_COMPANIES_DONE:
			return {
				...state,
				adding_from_market_map_in_progress: false,
				targetListCompanyInProgress: false,
				targetListCompanies: action.payload?.results || [],
				targetCompaniesPagination: action.payload.pagination ?? null,

				targetListCompaniesForCandidateFilter: action.payload?.results,
				targetListCompaniesForCandidateFilterPagination: action.payload?.pagination ?? null,
			};
		case GET_PAGINATED_TL_COMPANIES_DONE:
			return {
				...state,
				targetListCompaniesForCandidateFilterInProgress: false,
				targetListCompaniesForCandidateFilterPagination: action.payload?.pagination ?? null,
				targetListCompaniesForCandidateFilter: [
					...(state.targetListCompaniesForCandidateFilter || []),
					...(action.payload?.results || []),
				],
			};

		case GET_TARGETLIST_COMPANIES_FAIL:
			return {
				...state,
				targetListCompanyInProgress: false,
			};

		case CLEAR_TARGET_LIST_ITEMS:
			return {
				...state,
				targetListCompanies: [],
				targetCompaniesPagination: null,
				targetListCandidates: [],
				targetCandidatesPagination: null,
			};
		case LOGOUT:
			return {
				...targetListInitialState,
			};
		default:
			return state;
	}
};

export default targetListReducer;
