import React from "react";

const RecommendedIcon = ({
	size = 20,
	circleColor = "#D58A31",
	starColor = "#FFFFFF",
	className = "",
	isInteractive = false,
	...rest
}) => {
	return (
		<div
			style={{
				display: "inline-flex",
				alignItems: "center",
				justifyContent: "center",
				transform: isInteractive ? "scale(1)" : undefined,
				transition: isInteractive ? "transform 0.2s" : undefined,
				cursor: isInteractive ? "pointer" : undefined,
				width: size,
				height: size,
			}}
			onMouseEnter={(e) => {
				if (isInteractive) {
					e.currentTarget.style.transform = "scale(1.1)";
				}
			}}
			onMouseLeave={(e) => {
				if (isInteractive) {
					e.currentTarget.style.transform = "scale(1)";
				}
			}}
			className={`${className && className}`}
			role="img"
			aria-label="recommended star icon"
			{...rest}
		>
			<svg
				width={size}
				height={size}
				viewBox="0 0 20 20"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				style={{minWidth: size, minHeight: size}}
			>
				{/* Base circle */}
				<circle cx="10" cy="10" r="10" fill={circleColor} />

				{/* Star shape - removed mask and simplified */}
				<path
					d="M5.8834 15.6667L6.96674 10.9833L3.3334 7.83333L8.1334 7.41667L10.0001 3L11.8667 7.41667L16.6667 7.83333L13.0334 10.9833L14.1167 15.6667L10.0001 13.1833L5.8834 15.6667Z"
					fill={starColor}
				/>
			</svg>
		</div>
	);
};

export default RecommendedIcon;
