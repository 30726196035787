import {FC, useMemo} from "react";
import classNames from "classnames";

import {CustomBarChart, PieChart} from "components";
import {IPieChartDataProps, TCandidateProps} from "components/Charts/PieChart/types";

import {chartStyle} from "./utils";
import type {ICustomChartDataProps, TBackgroundAnalysisContainerProps} from "./types";
import styles from "./BackgroundAnalysisContainer.module.scss";
import {orderBy} from "lodash";
import {transformToTooltipData} from "components/Tooltip/utils";
import type {TChartTooltipProps} from "components/Tooltip/types";
import {ICustomBarChartDataProps} from "components/Charts/CustomBarChart/types";

const BackgroundAnalysisContainer: FC<TBackgroundAnalysisContainerProps> = ({
	data,
	title,
	isLoading,
	pieChartClass,
	setCurrentOption,
	description = "",
	currentOption = 0,
	isRoundedChartVal,
}) => {
	const sortedData = useMemo(() => {
		if (currentOption === 0) {
			return orderBy(data, "value", "desc");
		} else {
			return orderBy(data, "frequencyValue", "desc");
		}
	}, [currentOption, data]);

	const modifiedData = useMemo(() => {
		if (!!sortedData?.length || sortedData) {
			const updatedSortedData = [
				...sortedData,
				{
					id: -1,
					name: "Other",
					value: 0,
					children: [],
					candidates: [],
					frequencyValue: 0,
					candidateCount: 0,
					totalCandidateCountForSkillAnalysis:
						sortedData?.length > 0
							? sortedData[0].totalCandidateCountForSkillAnalysis
							: 0,
				},
			];

			const {mainData, otherData} = updatedSortedData.reduce(
				(
					acc: {
						mainData: ICustomChartDataProps[];
						otherData: ICustomChartDataProps;
					},
					curVal: ICustomChartDataProps,
				) => {
					if (curVal?.value >= 3 && curVal.name?.toLowerCase() !== "other") {
						acc.mainData.push(curVal);
					} else if (curVal.name?.toLowerCase() === "other") {
						acc.otherData = {
							...acc.otherData,
							candidateCount: acc.otherData.candidateCount + curVal.candidateCount,
							candidates: [...acc.otherData.candidates, ...curVal.candidates],
							frequencyValue: acc.otherData.frequencyValue + curVal.frequencyValue,
							name: "Other",
							value: Number((acc.otherData.value + curVal.value).toFixed(1)),
							totalCandidateCountForSkillAnalysis:
								curVal.totalCandidateCountForSkillAnalysis,
						};
					} else {
						acc.otherData.value = Number(
							(acc.otherData.value + curVal.value).toFixed(1),
						);

						const isCandidateIncluded = acc.otherData.candidates.some(
							(candidate: TCandidateProps) =>
								curVal.candidates.some(
									(curValCandidate: TCandidateProps) =>
										candidate.id === curValCandidate.id,
								),
						);

						if (!isCandidateIncluded) {
							acc.otherData.frequencyValue += curVal.frequencyValue;
							acc.otherData.candidateCount += curVal.candidateCount;
							acc.otherData.candidates = [
								...acc.otherData.candidates,
								...curVal.candidates,
							];
						}

						if (curVal.children && acc.otherData.children) {
							acc.otherData.children = [
								...acc.otherData.children,
								...curVal.children,
							];
						}
					}

					return acc;
				},
				{
					mainData: [],
					otherData: {
						id: -1,
						name: "Other",
						value: 0,
						children: [],
						candidates: [],
						frequencyValue: 0,
						candidateCount: 0,
						totalCandidateCountForSkillAnalysis: 0,
					},
				},
			);

			const finalData = !otherData?.value ? sortedData : mainData?.concat(otherData);

			return !sortedData.length ? [] : finalData;
		}

		return [];
	}, [sortedData]);

	const changeCurrentOption = (id: number) => {
		setCurrentOption(id);
	};

	const renderItem = chartStyle.map(({id, title}) => (
		<div
			key={id}
			onClick={() => changeCurrentOption(id)}
			className={classNames(styles.container__header_select_item, {
				[styles.container__header_select_item_active]: id === currentOption,
			})}
		>
			{title}
		</div>
	));

	const pieChartData: IPieChartDataProps[] = useMemo(() => {
		return (
			modifiedData?.map((item: ICustomChartDataProps) => ({
				id: item.id,
				name: item.name,
				value: item.value,
				children: item.children?.map((i) => ({
					id: i.id,
					name: i.name,
					label: i.job_function.name,
					value: i.duration_percent,
				})),
			})) || []
		);
	}, [modifiedData]);

	if (!modifiedData?.length) return null;

	const customBarChartData: ICustomBarChartDataProps[] = modifiedData.map(
		(item: ICustomChartDataProps) => {
			return {
				id: item.id,
				name: item.name,
				count: item.candidateCount,
				percent: item.frequencyValue,
				totalDataCount: item.totalCandidateCountForSkillAnalysis,
				tooltipData: transformToTooltipData(item?.candidates) as TChartTooltipProps[],
				children: item.children?.map((c) => {
					return {
						id: c.id,
						count: c.candidates_count,
						name: c.id == -1 ? c.job_function.name : c.name,
						percent: parseFloat(
							(
								(c.candidates_count / item?.totalCandidateCountForSkillAnalysis) *
								100
							).toFixed(1),
						),
						tooltipData: transformToTooltipData(c?.candidates) as TChartTooltipProps[],
					};
				}),
			};
		},
	);

	return (
		<div className={styles.container}>
			<div className={styles.container__header}>
				<p className={styles.container__header_title}>{title}</p>

				{!!modifiedData?.length && (
					<div className={styles.container__header_select}>{renderItem}</div>
				)}
			</div>

			{!!modifiedData?.length && (
				<p className={styles.container_description}>{description}</p>
			)}

			{!currentOption ? (
				<PieChart pieChartClass={pieChartClass} data={pieChartData} isLoading={isLoading} />
			) : (
				<CustomBarChart isRoundedChartVal={isRoundedChartVal} data={customBarChartData} />
			)}
		</div>
	);
};

export default BackgroundAnalysisContainer;
