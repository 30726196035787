import React, {useCallback} from "react";
import {useAppDispatch, useAppSelector} from "hooks";
import {STCompany} from "api/companyApi/types";
import {setSelectedCompaniesDone} from "store/mapx/company/companyActions";
import {removeDuplicateObjectFromArray} from "helpers/filterHandlers";
import {selectedCompaniesSelector} from "store/mapx/filter/filterSelectors";
import CompanySearchByDescription from "mapx-components/Filters/CompanySearchByDescription";

const SearchByDescription = () => {
	const dispatch = useAppDispatch();

	const selectedCompanies = useAppSelector(selectedCompaniesSelector);

	const handleProcessedResults = useCallback(
		(companies: STCompany[]) => {
			if (companies?.length > 0) {
				const updatedCompanies = selectedCompanies?.length
					? removeDuplicateObjectFromArray([...selectedCompanies, ...companies])
					: companies;

				dispatch(setSelectedCompaniesDone(updatedCompanies));
			}
		},
		[dispatch, selectedCompanies],
	);

	return <CompanySearchByDescription processSearchedResults={handleProcessedResults} />;
};

export default SearchByDescription;
