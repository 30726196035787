import React from "react";

const CompanyListIcon = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
		>
			<mask id="path-1-inside-1_3096_15697" fill="white">
				<path d="M20 10C20 11.9778 19.4135 13.9112 18.3147 15.5557C17.2159 17.2002 15.6541 18.4819 13.8268 19.2388C11.9996 19.9957 9.98891 20.1937 8.0491 19.8079C6.10929 19.422 4.32746 18.4696 2.92893 17.0711C1.53041 15.6725 0.577999 13.8907 0.192148 11.9509C-0.193705 10.0111 0.00432777 8.00043 0.761204 6.17317C1.51808 4.34591 2.7998 2.78412 4.4443 1.6853C6.08879 0.58649 8.02219 1.49321e-07 10 0L10 5C9.01109 5 8.04439 5.29324 7.22215 5.84265C6.3999 6.39206 5.75904 7.17295 5.3806 8.08658C5.00216 9.00021 4.90315 10.0055 5.09607 10.9755C5.289 11.9454 5.7652 12.8363 6.46447 13.5355C7.16373 14.2348 8.05464 14.711 9.02455 14.9039C9.99445 15.0969 10.9998 14.9978 11.9134 14.6194C12.827 14.241 13.6079 13.6001 14.1573 12.7779C14.7068 11.9556 15 10.9889 15 10L20 10Z" />
			</mask>
			<path
				d="M20 10C20 11.9778 19.4135 13.9112 18.3147 15.5557C17.2159 17.2002 15.6541 18.4819 13.8268 19.2388C11.9996 19.9957 9.98891 20.1937 8.0491 19.8079C6.10929 19.422 4.32746 18.4696 2.92893 17.0711C1.53041 15.6725 0.577999 13.8907 0.192148 11.9509C-0.193705 10.0111 0.00432777 8.00043 0.761204 6.17317C1.51808 4.34591 2.7998 2.78412 4.4443 1.6853C6.08879 0.58649 8.02219 1.49321e-07 10 0L10 5C9.01109 5 8.04439 5.29324 7.22215 5.84265C6.3999 6.39206 5.75904 7.17295 5.3806 8.08658C5.00216 9.00021 4.90315 10.0055 5.09607 10.9755C5.289 11.9454 5.7652 12.8363 6.46447 13.5355C7.16373 14.2348 8.05464 14.711 9.02455 14.9039C9.99445 15.0969 10.9998 14.9978 11.9134 14.6194C12.827 14.241 13.6079 13.6001 14.1573 12.7779C14.7068 11.9556 15 10.9889 15 10L20 10Z"
				stroke="#0C5850"
				strokeWidth="3.33333"
				mask="url(#path-1-inside-1_3096_15697)"
			/>
			<circle
				cx="10"
				cy="10"
				r="9.16667"
				transform="matrix(-1 0 0 1 20 0)"
				stroke="#0C5850"
				strokeWidth="1.66667"
			/>
			<mask id="path-3-inside-2_3096_15697" fill="white">
				<path d="M16.6665 10C16.6665 11.3186 16.2755 12.6075 15.543 13.7038C14.8104 14.8001 13.7692 15.6546 12.5511 16.1592C11.3329 16.6638 9.99244 16.7958 8.69924 16.5386C7.40603 16.2813 6.21814 15.6464 5.28579 14.7141C4.35344 13.7817 3.7185 12.5938 3.46127 11.3006C3.20403 10.0074 3.33606 8.66696 3.84064 7.44879C4.34522 6.23061 5.19971 5.18942 6.29604 4.45688C7.39236 3.72434 8.68129 3.33334 9.99984 3.33334L9.99984 8.33334C9.6702 8.33334 9.34797 8.43109 9.07389 8.61423C8.79981 8.79736 8.58618 9.05766 8.46004 9.3622C8.33389 9.66675 8.30089 10.0019 8.3652 10.3252C8.4295 10.6485 8.58824 10.9454 8.82133 11.1785C9.05441 11.4116 9.35139 11.5703 9.67469 11.6347C9.99799 11.699 10.3331 11.666 10.6376 11.5398C10.9422 11.4137 11.2025 11.2 11.3856 10.926C11.5688 10.6519 11.6665 10.3296 11.6665 10L16.6665 10Z" />
			</mask>
			<path
				d="M16.6665 10C16.6665 11.3186 16.2755 12.6075 15.543 13.7038C14.8104 14.8001 13.7692 15.6546 12.5511 16.1592C11.3329 16.6638 9.99244 16.7958 8.69924 16.5386C7.40603 16.2813 6.21814 15.6464 5.28579 14.7141C4.35344 13.7817 3.7185 12.5938 3.46127 11.3006C3.20403 10.0074 3.33606 8.66696 3.84064 7.44879C4.34522 6.23061 5.19971 5.18942 6.29604 4.45688C7.39236 3.72434 8.68129 3.33334 9.99984 3.33334L9.99984 8.33334C9.6702 8.33334 9.34797 8.43109 9.07389 8.61423C8.79981 8.79736 8.58618 9.05766 8.46004 9.3622C8.33389 9.66675 8.30089 10.0019 8.3652 10.3252C8.4295 10.6485 8.58824 10.9454 8.82133 11.1785C9.05441 11.4116 9.35139 11.5703 9.67469 11.6347C9.99799 11.699 10.3331 11.666 10.6376 11.5398C10.9422 11.4137 11.2025 11.2 11.3856 10.926C11.5688 10.6519 11.6665 10.3296 11.6665 10L16.6665 10Z"
				stroke="#0C5850"
				strokeWidth="3.33333"
				mask="url(#path-3-inside-2_3096_15697)"
			/>
			<ellipse
				cx="2.5"
				cy="2.5"
				rx="2.5"
				ry="2.5"
				transform="matrix(-1 0 0 1 12.5 7.5)"
				stroke="#0C5850"
				strokeWidth="1.66667"
			/>
		</svg>
	);
};

export default CompanyListIcon;
