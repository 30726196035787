import React, {useEffect} from "react";
import styles from "./Diversity.module.scss";
import GenderDiversity from "./GenderDiversity";
import DiversityPathways from "./DiversityPathways";
import {useAppDispatch, useAppSelector} from "hooks";
import {
	getMarketReportDetailsSelector,
	mrDisabledFilterKeysOnCriteriaBreakDown,
	mrDiversePathwaysModeSelector,
	mrDiversityStatsReportSelector,
} from "store/mapx/market-report/marketReportSelector";
import {
	STDiversePathwaysMode,
	STDiversityStats,
	TCandidateFilterOptions,
} from "api/marketReportApi/types";
import {
	getMarketDiversityPathwaysReportById,
	getMarketDiversityStatsReportById,
} from "store/mapx/market-report/marketReportAsyncActions";

const MarketReportDiversity = () => {
	const genderDiversity: STDiversityStats = useAppSelector(mrDiversityStatsReportSelector);

	const diversityPathwaysMode: STDiversePathwaysMode = useAppSelector(
		mrDiversePathwaysModeSelector,
	);

	const marketReport = useAppSelector(getMarketReportDetailsSelector);

	const disabledFilterKeys: (keyof TCandidateFilterOptions)[] = useAppSelector(
		mrDisabledFilterKeysOnCriteriaBreakDown,
	);

	const dispatch = useAppDispatch();

	useEffect(() => {
		if (marketReport) {
			dispatch(getMarketDiversityStatsReportById(marketReport.id, disabledFilterKeys));
		}
	}, [dispatch, marketReport, disabledFilterKeys]);

	useEffect(() => {
		if (marketReport) {
			dispatch(
				getMarketDiversityPathwaysReportById(
					marketReport.id,
					diversityPathwaysMode,
					disabledFilterKeys,
				),
			);
		}
	}, [dispatch, diversityPathwaysMode, marketReport, disabledFilterKeys]);

	return (
		<div className={styles.container}>
			<h2>
				Overall Diversity{" "}
				{genderDiversity?.diversity_percent
					? `${genderDiversity?.diversity_percent}%`
					: "-"}
			</h2>

			<div className={styles.chartBox}>
				<GenderDiversity />
				<DiversityPathways />
			</div>
		</div>
	);
};

export default MarketReportDiversity;
