import HttpClient from "api";
import {AxiosRequestConfig} from "axios";
import {
	STDiversePathwaysMode,
	TCandidateFilterOptions,
	TCreateMarketReportForm,
	TGetMarketReports,
} from "api/marketReportApi/types";

class MapXMarketReportsApi extends HttpClient {
	async getMarketReportById(id: number | string) {
		return this.doGet(`/market-reports/${id}`);
	}

	async getMarketReports({name, pagination}: TGetMarketReports, config = {}) {
		const params = {...pagination, name};

		const queryParts: string[] = [];

		Object.keys(params).forEach((key) => {
			const value = params[key as keyof typeof params];
			if (value != null) {
				queryParts.push(
					`${encodeURIComponent(key)}=${encodeURIComponent(value.toString())}`,
				);
			}
		});

		const filterQuery = queryParts.length > 0 ? `?${queryParts.join("&")}` : "";

		return this.doGet(`/market-reports${filterQuery}`, config);
	}

	async searchMarketReports(
		{name = "", pagination = {page: 1, per_page: 40}}: TGetMarketReports,
		config?: AxiosRequestConfig,
	) {
		let filterQuery = `?page=${pagination.page}&per_page=${pagination.per_page}`;

		if (name !== "") {
			filterQuery = `${filterQuery}&name=${name}`;
		}

		return this.doGet(`/market-reports${filterQuery}`, config);
	}

	async createMarketReport(data: TCreateMarketReportForm) {
		return this.doPost("/market-reports", data);
	}

	async updateMarketReport(id: number, data: TCreateMarketReportForm) {
		return this.doPatch(`/market-reports/${id}`, data);
	}

	async duplicateMarketReportById(id: number) {
		return this.doPost(`/market-reports/${id}/duplicate`);
	}

	async deleteMarketReport(id: number) {
		return this.doDelete(`/market-reports/${id}`);
	}

	async getMarketDiversityStatsReportById(
		id: number,
		ignore_filters: (keyof TCandidateFilterOptions)[],
		config = {},
	) {
		return this.doGet(
			`/market-reports/${id}/diversity-stats?market_report_id=${id}${
				ignore_filters.length > 0 ? `&ignore_filters=${ignore_filters.join(",")}` : ""
			}`,
			config,
		);
	}

	async getMarketDiversePathwaysReportById(
		id: number,
		mode: STDiversePathwaysMode,
		ignore_filters: (keyof TCandidateFilterOptions)[],
		config = {},
	) {
		return this.doGet(
			`/market-reports/${id}/diverse-pathways-report?market_report_id=${id}&mode=${mode}${
				ignore_filters.length > 0 ? `&ignore_filters=${ignore_filters.join(",")}` : ""
			}`,
			config,
		);
	}

	async downloadProjectPowerpoint(
		id: number | string,
		config: AxiosRequestConfig = {
			responseType: "blob",
			headers: {
				Accept: "application/octet-stream",
			},
		},
	) {
		return this.doPost(`/market-reports/${id}/export-to-pptx`, {}, config);
	}
}

const mapXMarketReportsApi = new MapXMarketReportsApi();

export default mapXMarketReportsApi;
