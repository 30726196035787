//Candidate company events
import {TRootState} from "types";

export const candidateCompanyEventPaginationInfoSelector = (state: TRootState) =>
	state.search.candidateCompanyEventsPaginationInfo;
export const candidateCompanyEventsSearchQuerySelector = (state: TRootState) =>
	state.search.candidateCompanyEventsSearchQuery;
export const CandidateCompanyEventsSelector = (state: TRootState) =>
	state.search.candidateCompanyEvents;
export const CandidateCompanyEventSizeRangeOptionsSelector = (state: TRootState) =>
	state.search.candidateCompanyEventSizeRangeOptions;
export const CandidateCompanyDateRangeOptionsSelector = (state: TRootState) =>
	state.search.candidateCompanyDateRangeOptions;
