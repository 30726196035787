import React, {useRef, useState} from "react";
import styles from "mapx-components/Cards/ProjectCandidateTableCard/styles.module.scss";
import {removeCandidateFromTargetList} from "store/mapx/target-list/targetListAsyncActions";
import {recommendCandidate} from "store/mapx/additional-profiles/candidateDecisionAsyncActions";
import {Loader} from "components";
import colors from "styles/themes.module.scss";
import {BiDotsVerticalRounded} from "react-icons/bi";
import useOnClickOutside from "hooks/useOnClickOutside";
import {TProjectSavedPeopleActionsProps} from "mapx-components/Cards/ProjectCandidateTableCard/ProjectSavedPeopleActions/types";
import {useAppDispatch} from "hooks";

const ProjectSavedPeopleActions = ({
	candidate,
	isCurrentCandidateAssessmentInProgress,
	removingCandidate,
	recommendLoading,
	setRemovingCandidate,
	setRecommendLoading,
}: TProjectSavedPeopleActionsProps) => {
	const dispatch = useAppDispatch();

	const optionsRef = useRef(null);

	const [activeMenu, setActiveMenu] = useState(false);

	const toggleMenu = () => {
		setActiveMenu(!activeMenu);
	};

	useOnClickOutside(optionsRef, () => setActiveMenu(false));

	return (
		<div ref={optionsRef}>
			<span className={activeMenu ? styles.activeMenu : styles.hiddenMenu}>
				<div className={styles.projectMenu}>
					<div
						className={styles.menuItem}
						onClick={async () => {
							setActiveMenu(false);
							setRemovingCandidate(true);
							await dispatch(removeCandidateFromTargetList(candidate));
							setRemovingCandidate(false);
						}}
					>
						Remove Candidate
					</div>
					<div
						className={styles.menuItem}
						onClick={async () => {
							setActiveMenu(false);
							setRecommendLoading(true);
							if (candidate.is_recommended) {
								await dispatch(recommendCandidate(candidate.id, false));
							} else {
								await dispatch(recommendCandidate(candidate.id, true));
							}
							setRecommendLoading(false);
						}}
					>
						{!candidate.is_recommended ? "Recommend" : "Unrecommend"} Candidate
					</div>
				</div>
			</span>

			{isCurrentCandidateAssessmentInProgress || removingCandidate || recommendLoading ? (
				<Loader height={10} width={10} type="ThreeDots" color={colors.loaderDotColor} />
			) : (
				<BiDotsVerticalRounded size={24} className={styles.menuDots} onClick={toggleMenu} />
			)}
		</div>
	);
};

export default ProjectSavedPeopleActions;
