import {useAppDispatch, useAppSelector} from "hooks";
import {Accordion, KeywordSearchFilter} from "mapx-components";
import {useCallback, useMemo} from "react";
import {useSelector} from "react-redux";
import {filterPositionOptionsSelector} from "store/mapx/filter/filterSelectors";
import {setKeywordSearchQuery} from "store/mapx/search/searchActions";
import {getKeywordsList} from "store/mapx/search/searchAsyncActions";
import {
	keywordPaginationSelector,
	keywordSearchQuerySelector,
} from "store/mapx/search/searchSelectors";

import {TabSelect} from "components";
import {
	mRAllKeywordsCountSelector,
	mRAnyKeywordTagsSelector,
	mRCurrentKeywordTagsSelector,
	mRKeywordFilterConfigSelector,
	mRKeywordsByPositionSelector,
	mRPreviousKeywordTagsSelector,
} from "store/mapx/market-report/marketReportFilterSelectors";
import {
	setMarketReportMultipleFilterValueInForm,
	setMRKeywordFilterConfig,
} from "store/mapx/market-report/marketReportActions";
import {TConnectorLogic, TPositionLogic} from "store/mapx/market-report/types";
import {
	mRClearKeywordsByPositionForCandidate,
	mRMoveKeywordForCandidate,
	mRSetKeywordForCandidate,
	mRSwitchKeywordConnectorForCandidatePosition,
} from "store/mapx/market-report/marketReportFormAsyncActions";

function KeywordFilter() {
	const keywordSearchQuery = useSelector(keywordSearchQuerySelector);

	const keywordPagination = useSelector(keywordPaginationSelector);

	const dispatch = useAppDispatch();

	const keywordFilterConfig = useAppSelector(mRKeywordFilterConfigSelector);

	const position = keywordFilterConfig.active_position;
	const currentPositionConnectivityLogic =
		keywordFilterConfig.active_current_position_connect_logic;
	const previousPositionConnectivityLogic =
		keywordFilterConfig.active_previous_position_connect_logic;
	const anyPositionConnectivityLogic = keywordFilterConfig.active_any_position_connect_logic;

	const logicByCurrentlySelectedPosition = useMemo(() => {
		if (position === "current") {
			return currentPositionConnectivityLogic;
		} else if (position === "previous") {
			return previousPositionConnectivityLogic;
		} else {
			return anyPositionConnectivityLogic;
		}
	}, [
		anyPositionConnectivityLogic,
		currentPositionConnectivityLogic,
		position,
		previousPositionConnectivityLogic,
	]);

	const filterPositionOptions = useAppSelector(filterPositionOptionsSelector);

	const count = useAppSelector(mRAllKeywordsCountSelector);

	const anyTags = useAppSelector(mRAnyKeywordTagsSelector);

	const currentTags = useAppSelector(mRCurrentKeywordTagsSelector);

	const previousTags = useAppSelector(mRPreviousKeywordTagsSelector);

	const selectedKeywords = useAppSelector((state) =>
		mRKeywordsByPositionSelector(state, position, logicByCurrentlySelectedPosition),
	);

	const handlePositionChange = useCallback(
		(updatedPositionValue: string) => {
			dispatch(
				setMRKeywordFilterConfig({
					...keywordFilterConfig,
					active_position: updatedPositionValue,
				}),
			);
		},
		[dispatch, keywordFilterConfig],
	);

	const handleChange = useCallback(
		(value: string) => {
			dispatch(
				mRSetKeywordForCandidate({
					position: position,
					logic: logicByCurrentlySelectedPosition,
					text: value,
				}),
			);
		},
		[dispatch, logicByCurrentlySelectedPosition, position],
	);

	const handleTagClick = useCallback(
		(value: string, position: TPositionLogic, logic: TConnectorLogic) => {
			dispatch(
				mRSetKeywordForCandidate({
					position,
					logic,
					text: value,
				}),
			);
		},
		[dispatch],
	);

	const getLogicConnectorByPosition = useCallback(
		(positionParam: TPositionLogic) => {
			let logic: TConnectorLogic;

			switch (positionParam) {
				case "current":
					logic = currentPositionConnectivityLogic;
					break;
				case "previous":
					logic = previousPositionConnectivityLogic;
					break;
				case "any":
					logic = anyPositionConnectivityLogic;
					break;
				default:
					throw Error("This position doesn't exist");
			}

			return logic;
		},
		[
			anyPositionConnectivityLogic,
			currentPositionConnectivityLogic,
			previousPositionConnectivityLogic,
		],
	);

	const moveKeywordTagPosition = useCallback(
		(value: string, fromPosition: TPositionLogic, toPosition: TPositionLogic) => {
			const fromLogic = getLogicConnectorByPosition(fromPosition);
			const toLogic = getLogicConnectorByPosition(toPosition);

			dispatch(
				mRMoveKeywordForCandidate({
					from: fromPosition,
					to: toPosition,
					fromLogic: fromLogic,
					toLogic: toLogic,
					text: value,
				}),
			);
		},
		[dispatch, getLogicConnectorByPosition],
	);

	const handleConnectivityOptionChange = useCallback(
		(position: TPositionLogic, toLogic: TConnectorLogic) => {
			let fromLogic: TConnectorLogic;

			switch (position) {
				case "current":
					fromLogic = currentPositionConnectivityLogic;

					dispatch(
						setMRKeywordFilterConfig({
							...keywordFilterConfig,
							active_current_position_connect_logic: toLogic,
						}),
					);
					break;
				case "previous":
					fromLogic = previousPositionConnectivityLogic;

					dispatch(
						setMRKeywordFilterConfig({
							...keywordFilterConfig,
							active_previous_position_connect_logic: toLogic,
						}),
					);
					break;
				case "any":
					fromLogic = anyPositionConnectivityLogic;

					dispatch(
						setMRKeywordFilterConfig({
							...keywordFilterConfig,
							active_any_position_connect_logic: toLogic,
						}),
					);
					break;
				default:
					throw Error("This position doesn't exist");
			}

			dispatch(
				mRSwitchKeywordConnectorForCandidatePosition({
					position,
					fromLogic,
					toLogic,
				}),
			);
		},
		[
			anyPositionConnectivityLogic,
			currentPositionConnectivityLogic,
			dispatch,
			keywordFilterConfig,
			previousPositionConnectivityLogic,
		],
	);

	const handleClearTagClickByPosition = useCallback(
		(position: TPositionLogic) => {
			dispatch(mRClearKeywordsByPositionForCandidate(position));
		},
		[dispatch],
	);

	const handleResetClick = useCallback(() => {
		dispatch(
			setMarketReportMultipleFilterValueInForm({
				text_keywords_or: [],
				text_keywords_and: [],
				text_keywords_not: [],
				current_text_keywords_or: [],
				current_text_keywords_and: [],
				current_text_keywords_not: [],
				previous_text_keywords_or: [],
				previous_text_keywords_and: [],
				previous_text_keywords_not: [],
			}),
		);
	}, [dispatch]);

	return (
		<Accordion title="Keyword" subtitle={count}>
			<TabSelect.LabelContainer label="Position">
				<TabSelect
					selected={position}
					onTabChange={handlePositionChange}
					options={filterPositionOptions}
				/>
			</TabSelect.LabelContainer>

			<KeywordSearchFilter
				selectedKeywords={selectedKeywords}
				handleResetClick={handleResetClick}
				handleOnChange={handleChange}
				handleTagClick={handleTagClick}
				displayResetFilterOption={count > 0}
				moveKeywordTagPosition={moveKeywordTagPosition}
				selectedKeywordTags={{
					current: currentTags,
					previous: previousTags,
					any: anyTags,
				}}
				handleConnectivityOptionChange={handleConnectivityOptionChange}
				connectionLogics={{
					current: currentPositionConnectivityLogic,
					previous: previousPositionConnectivityLogic,
					any: anyPositionConnectivityLogic,
				}}
				clearTagsByPosition={handleClearTagClickByPosition}
				keywordSearchQuery={keywordSearchQuery}
				keywordPagination={keywordPagination}
				setKeywordQueryOnState={setKeywordSearchQuery}
				apiCall={getKeywordsList}
			/>
		</Accordion>
	);
}

export default KeywordFilter;
