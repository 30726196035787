import {
	SET_PER_PAGE_PREFERENCE,
	SET_PROJECT_LAYOUT_VIEW_PREFERENCE,
} from "store/mapx/mapXActionTypes";
import {TProjectLayoutView} from "store/mapx/userPreference/types";

export function setPerPagePreference(payload: number) {
	return {type: SET_PER_PAGE_PREFERENCE, payload};
}

export function setProjectLayoutViewPreference(payload: TProjectLayoutView) {
	return {type: SET_PROJECT_LAYOUT_VIEW_PREFERENCE, payload};
}
