import {useMemo, useState} from "react";
import {ProjectCandidateCard} from "mapx-components";
import {useAppDispatch, useAppSelector} from "hooks";
import {targetListCandidateIDsSelector} from "store/mapx/target-list/targetListSelectors";
import {SICandidate} from "api/candidateApi/types";
import {SkeletonLoaderCardAsList} from "components";
import styles from "./styles.module.scss";
import SavedPeopleHeaderSection from "mapx-pages/Project/SavedPeople/SavedPeopleHeaderSection";
import FilterSection from "mapx-pages/Project/SavedPeople/FilterSection";
import {useSelector} from "react-redux";
import {additionalProfileFiltersSelector} from "store/mapx/additional-profiles/additionalProfilesSelectors";
import {buildCandidateApiRequestPayload} from "helpers/filterHandlers";

import {selectedCandidateIdsForAssessmentSelector} from "store/mapx/project/candidateScoringSelectors";
import Pagination from "mapx-components/Pagination";
import {
	projectSavedPeopleInProgressSelector,
	projectSavedPeoplePaginationSelector,
	projectSavedPeopleSelector,
} from "store/mapx/project/projectSelectors";

import {getPaginatedProjectSavedPeople} from "store/mapx/project/projectCandidatesAsyncActions";
import {
	globalPerPageSelector,
	globalProjectLayoutViewSelector,
} from "store/mapx/userPreference/userPreferenceSelectors";
import {setPerPagePreference} from "store/mapx/userPreference/userPreferenceActions";
import classNames from "classnames";
import {TProjectLayoutView} from "store/mapx/userPreference/types";
import ProjectCandidateTableCard from "mapx-components/Cards/ProjectCandidateTableCard";

const SavedPeople = () => {
	const dispatch = useAppDispatch();

	const selectedCandidatesIdsForAssessment = useAppSelector(
		selectedCandidateIdsForAssessmentSelector,
	);

	const candidatesFetchInProgress = useAppSelector(projectSavedPeopleInProgressSelector);

	const targetListCandidateIDs = useAppSelector(targetListCandidateIDsSelector);

	const savedPeoples: SICandidate[] = useAppSelector(projectSavedPeopleSelector);

	const pagination = useAppSelector(projectSavedPeoplePaginationSelector);

	const globalPerPageValue = useAppSelector(globalPerPageSelector);

	const filterSelection = useSelector(additionalProfileFiltersSelector);

	const [expandAllCriterions, setExpandAllCriterions] = useState<boolean>(false);

	const globalProjectLayoutView: TProjectLayoutView = useAppSelector(
		globalProjectLayoutViewSelector,
	);

	const hasFilters = useMemo(() => {
		return !!buildCandidateApiRequestPayload(filterSelection, false);
	}, [filterSelection]);

	const candidateSelectionTypeForAssessment = useMemo(() => {
		if (pagination?.count === selectedCandidatesIdsForAssessment?.length) {
			return "all";
		} else if (selectedCandidatesIdsForAssessment?.length > 0) {
			return "partial";
		} else {
			return "none";
		}
	}, [pagination?.count, selectedCandidatesIdsForAssessment?.length]);

	function onPageChange(page: number) {
		dispatch(
			getPaginatedProjectSavedPeople({
				page: page,
				per_page: globalPerPageValue,
			}),
		);

		setExpandAllCriterions(false);
	}

	const onPerPageChange = async (count: number) => {
		dispatch(setPerPagePreference(count));

		dispatch(
			getPaginatedProjectSavedPeople({
				page: pagination.page,
				per_page: count,
			}),
		);
	};

	return (
		<div className={styles.listedItems}>
			<FilterSection />

			{!candidatesFetchInProgress && (
				<SavedPeopleHeaderSection
					setExpandAllCriterions={setExpandAllCriterions}
					hasActiveFilters={hasFilters}
					currentDisplayedCount={pagination?.count ?? 0}
					totalSavedPeopleCount={targetListCandidateIDs?.length ?? 0}
					candidateSelectionTypeForAssessment={candidateSelectionTypeForAssessment}
				/>
			)}

			<div className={classNames(styles.cardWrapper)}>
				{!candidatesFetchInProgress &&
					savedPeoples?.map((candidate, index) => {
						return (
							<div
								className={styles.projectCandidateCard}
								key={`${candidate?.id}${index}`}
							>
								<div
									className={classNames(styles.cardView, {
										[styles.active]:
											globalProjectLayoutView === "max-card" ||
											globalProjectLayoutView === "mini-card",
									})}
								>
									<ProjectCandidateCard
										key={`${candidate?.id}${index}`}
										candidate={candidate}
										searchType="Project"
										displayDealExperience={true}
										displayAllExperience={
											globalProjectLayoutView === "max-card"
										}
										expandAllCriterions={expandAllCriterions}
										isRecommended={candidate.is_recommended}
									/>
								</div>

								<div
									className={classNames(styles.cardView, {
										[styles.active]: globalProjectLayoutView === "table-card",
									})}
								>
									<ProjectCandidateTableCard
										candidate={candidate}
										isNextCandidateRecommended={
											savedPeoples[index + 1]?.is_recommended || false
										}
										searchType="Project"
										type={"project-saved-people"}
									/>
								</div>
							</div>
						);
					})}
			</div>

			{candidatesFetchInProgress && (
				<SkeletonLoaderCardAsList
					isMini={globalProjectLayoutView === "table-card"}
					count={5}
				/>
			)}

			{!candidatesFetchInProgress && pagination && pagination?.count >= 10 && (
				<div className={styles.paginationWrapper}>
					<Pagination
						currentPage={pagination?.page}
						resultsCount={pagination?.count}
						totalPages={pagination?.pages}
						perPage={globalPerPageValue}
						onPageChange={onPageChange}
						onPerPageChange={onPerPageChange}
						scrollDelayAfterPageChange={400}
					/>
				</div>
			)}

			{savedPeoples?.length === 0 &&
				!candidatesFetchInProgress &&
				(hasFilters
					? "No profiles found based on the selected filters"
					: "You have not saved any people on this project yet")}
		</div>
	);
};

export default SavedPeople;
