import {setMultipleFilterForCandidates} from "store/mapx/filter/filterActions";
import {Dispatch} from "redux";
import {TAppDispatch, TRootState} from "types";
import {
	candidateCountryIdsByPositionSelector,
	candidateRegionIdsByPositionSelector,
	locationFilterKeyByPosition,
	regionFilterKeyByPosition,
} from "./locationFilterSelectors";
import {TLocationPosition} from "containers/Filters/PeopleFilters/LocationFilter/types";
import {handleMoveLocation} from "mapx-components/Filters/LocationSearchFilter/utils";

export const setLocationForCandidate =
	({
		position,
		nextCountryIds,
		toBeSetRegionIds,
	}: {
		position: TLocationPosition;
		nextCountryIds: number[];
		toBeSetRegionIds: number[];
	}) =>
	async (dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const state = getState();

		const locationFilterKey = locationFilterKeyByPosition(state, position);
		const regionFilterKey = regionFilterKeyByPosition(state, position);

		dispatch(
			setMultipleFilterForCandidates({
				[locationFilterKey]: nextCountryIds,
				[regionFilterKey]: toBeSetRegionIds,
			}),
		);
	};

export const moveLocationsForCandidate = (params: {
	from: TLocationPosition;
	to: TLocationPosition;
	country_id: number;
	region_id: string;
}) =>
	handleMoveLocation(
		params,
		(position, state) => ({
			locationKey: locationFilterKeyByPosition(state, position),
			regionKey: regionFilterKeyByPosition(state, position),
		}),
		(position, state) => ({
			locations: candidateCountryIdsByPositionSelector(state, position),
			regions: candidateRegionIdsByPositionSelector(state, position),
		}),
		setMultipleFilterForCandidates,
	);
