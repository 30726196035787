import {type FC, Fragment, useState} from "react";
import classNames from "classnames";

import {ArrowDown} from "assets/icons";
import CandidatesTooltip from "components/Tooltip/CandidatesTooltip";

import {colors} from "../PieChart/utils";

import styles from "./CustomBarChart.module.scss";
import {ICustomBarChartProps} from "components/Charts/CustomBarChart/types";
import CustomBarChartLoader from "components/Charts/CustomBarChart/CustomBarChartLoader";

const CustomBarChart: FC<ICustomBarChartProps> = ({
	isLoading,
	isRoundedChartVal,
	data,
	customClass,
}) => {
	const [isActive, setIsActive] = useState<number | undefined>(undefined);

	const changeActive = (id?: number) => {
		if (isActive === id) {
			setIsActive(undefined);
		} else {
			setIsActive(id);
		}
	};

	const filterChartItems = data.find((item) => item.id === isActive);

	const childrenItems = filterChartItems?.children
		?.filter((el) => el.percent >= 0.3)
		.sort((a, b) => b.percent - a.percent);

	const renderChildrenItems = childrenItems?.map((el) => {
		return (
			<Fragment key={`${el.id}-${el.name}-${el.count}`}>
				<div
					data-tooltip-delay-show={300}
					className={styles.wrapper__children}
					data-tooltip-id={`${el.id}-${el.percent}`}
				>
					<p className={styles.wrapper__children_name}>
						{el.name} ({el.count || 0})
					</p>

					<p className={styles.wrapper__children_percent}>{el.percent}%</p>
				</div>

				{el?.tooltipData && el?.tooltipData?.length > 0 && (
					<CandidatesTooltip
						title={el.name}
						id={`${el.id}-${el.percent}`}
						percent={`${el.percent}%`}
						data={el.tooltipData}
					/>
				)}
			</Fragment>
		);
	});

	const renderChartItem =
		data.length > 0 ? (
			data?.map((elem, idx: number) => {
				const color = colors[idx];

				const originalValue = Number(elem.percent.toFixed(1)) || 0;

				return (
					<Fragment key={`${elem.id}-${elem.name}`}>
						<div
							role="button"
							className={classNames(styles.wrapper__item, {
								[styles.wrapper__item_empty]: !elem?.children?.length,
							})}
							data-testid="chart-item"
							onClick={elem?.children && (() => changeActive(elem?.id))}
						>
							<div
								className={styles.wrapper__item_background}
								style={{
									width: `${originalValue > 100 ? 100 : originalValue}%`,
									backgroundColor: `${color}`,
								}}
							/>
							<div
								className={styles.wrapper__item_content}
								data-tooltip-id={`${elem.id}-${originalValue}-${elem?.children?.length}-${elem.name}-${elem?.count}`}
							>
								<p
									className={classNames(styles.wrapper__item_content_name, {
										[styles.wrapper__item_content_name_active]:
											isActive === elem.id,
									})}
								>
									{!!elem?.children?.length && (
										<ArrowDown
											stroke="#2C3030"
											className={classNames(
												styles.wrapper__item_content_icon,
												{
													[styles.wrapper__item_content_icon_active]:
														isActive === elem.id,
												},
											)}
										/>
									)}
									{elem.name} {`(${elem?.count || 0})`}
								</p>

								<p className={styles.wrapper__item_content_percent}>
									{isRoundedChartVal ? Math.round(originalValue) : originalValue}%
								</p>
							</div>
						</div>

						{isActive === elem.id &&
							filterChartItems?.children &&
							// (
							//     elem.id === -1 ||
							//     filterChartItems?.children[0]?.id === elem?.id
							// ) &&
							renderChildrenItems}

						{elem?.tooltipData && elem?.tooltipData?.length > 0 && (
							<CandidatesTooltip
								title={elem.name}
								percent={`${originalValue}%`}
								data={elem.tooltipData}
								id={`${elem.id}-${originalValue}-${elem?.children?.length}-${elem.name}-${elem?.count}`}
							/>
						)}
					</Fragment>
				);
			})
		) : (
			<p className={styles.container__wrapper__is_empty}>No Data Found</p>
		);

	return (
		<div className={classNames(styles.wrapper, customClass && customClass)}>
			{isLoading ? <CustomBarChartLoader /> : renderChartItem}
		</div>
	);
};

export default CustomBarChart;
