import React, {type FC, useCallback, useEffect} from "react";

import type {STCompany} from "api/companyApi/types";
import {useAppDispatch, useAppSelector} from "hooks";
import {Accordion, GeneralButton} from "mapx-components";
import {Checkbox, ResetFilters, TabSelect} from "components";
import {
	getMarketReportFormSelector,
	getMRSelectedCompaniesSelector,
} from "store/mapx/market-report/marketReportSelector";
import {
	setMRCompanyFilterConfig,
	setMRSelectedCompanies,
} from "store/mapx/market-report/marketReportActions";
import CompanySearchByDescription from "mapx-components/Filters/CompanySearchByDescription";
import {
	mRAllCompaniesFilterCountSelector,
	mRCandidateCompaniesByPositionSelector,
	mRCompanyFilterConfigSelector,
} from "store/mapx/market-report/marketReportFilterSelectors";
import {filterPositionOptionsSelector} from "store/mapx/filter/filterSelectors";
import {
	moveCompaniesForMarketReportFormFilter,
	mRAddCompaniesToCompaniesSelectedList,
	setBulkCompaniesForMarketReportFormFilter,
	setCompaniesForMarketReportFormFilter,
} from "store/mapx/market-report/marketReportFormAsyncActions";
import {TPositionLogic} from "store/mapx/market-report/types";
import styles from "mapx-pages/MarketReportCreate/styles.module.scss";
import {MarketMapsIcon} from "assets/icons";
import {addFromMarketMapModalForMR} from "store/mapx/market-map/marketMapActions";
import css from "mapx-components/Filters/CompanySearchFilterWithTL/TargetListFilter/targetListFilter.module.scss";
import CheckboxList from "mapx-components/Inputs/CheckboxList";
import {searchCompaniesByFilter} from "store/mapx/search/searchCompanyAsyncActions";

const CompanyFilter: FC = () => {
	const dispatch = useAppDispatch();

	const filterPositionOptions = useAppSelector(filterPositionOptionsSelector);

	const selectedCompanies = useAppSelector(getMRSelectedCompaniesSelector);

	const companyFilterConfig = useAppSelector(mRCompanyFilterConfigSelector);

	const position = companyFilterConfig.active_position;

	const count = useAppSelector(mRAllCompaniesFilterCountSelector);

	const selectedIds: number[] = useAppSelector((state) =>
		mRCandidateCompaniesByPositionSelector(state, position),
	);

	const marketReportForm = useAppSelector(getMarketReportFormSelector);

	const handlePositionChange = useCallback(
		(updatedPositionValue: string) => {
			dispatch(
				setMRCompanyFilterConfig({
					...companyFilterConfig,
					active_position: updatedPositionValue,
				}),
			);

			dispatch(
				moveCompaniesForMarketReportFormFilter({
					from: position,
					to: updatedPositionValue as TPositionLogic,
					ids: selectedIds,
				}),
			);
		},
		[dispatch, companyFilterConfig, position, selectedIds],
	);

	const toggleCompanySelection = (id: number) => {
		dispatch(
			setCompaniesForMarketReportFormFilter({
				position,
				id: id,
			}),
		);
	};

	const handleProcessedResults = useCallback(
		(companies: STCompany[]) => {
			dispatch(mRAddCompaniesToCompaniesSelectedList(companies));
		},
		[dispatch],
	);

	const handleResetClick = useCallback(() => {
		dispatch(setMRSelectedCompanies([]));

		dispatch(setBulkCompaniesForMarketReportFormFilter({position, companies: []}));
	}, [dispatch, position]);

	useEffect(() => {
		async function fetchCompanies(ids: number[]) {
			const apiPayload = {
				companies: ids,
			};

			const companies = await dispatch(
				searchCompaniesByFilter(apiPayload, {page: 1, per_page: ids.length}),
			);

			if (companies?.pagination?.count > 0) {
				dispatch(setMRSelectedCompanies(companies.results));
			}
		}

		if (selectedIds.length > 0 && selectedCompanies.length === 0) {
			fetchCompanies(selectedIds);
		}
	}, [dispatch, selectedCompanies?.length, selectedIds]);

	useEffect(() => {
		if (selectedIds?.length === 0 && count > 0) {
			if (marketReportForm.filters?.current_companies?.length > 0) {
				handlePositionChange("current");
			} else if (marketReportForm.filters?.previous_companies?.length > 0) {
				handlePositionChange("previous");
			} else if (marketReportForm.filters?.companies?.length > 0) {
				handlePositionChange("any");
			}
		}
	}, [count, handlePositionChange, marketReportForm.filters, position, selectedIds]);

	return (
		<Accordion title="Company Target List" subtitle={count}>
			{count > 0 && (
				<ResetFilters onClick={handleResetClick} style={{marginTop: 4}} displayIcon={true}>
					Clear Selection
				</ResetFilters>
			)}

			<CompanySearchByDescription processSearchedResults={handleProcessedResults} />

			<GeneralButton
				customClass={styles.addFromTLButton}
				title={"Add From Target List"}
				icon={<MarketMapsIcon color={"#0C5850"} />}
				handleClick={() => dispatch(addFromMarketMapModalForMR(true))}
			/>

			<TabSelect.LabelContainer label="All Position">
				<TabSelect
					selected={position}
					onTabChange={handlePositionChange}
					options={filterPositionOptions}
				/>
			</TabSelect.LabelContainer>

			<CheckboxList style={{marginTop: 16}}>
				{selectedCompanies.map((item: STCompany) => (
					<Checkbox
						borderColor="#0C5850"
						containerClass={css.checkboxContainer}
						isChecked={selectedIds.includes(item.id)}
						key={item.id}
						label={item.name}
						onChange={() => toggleCompanySelection(item.id)}
						value={item.name}
					/>
				))}
			</CheckboxList>
		</Accordion>
	);
};

export default CompanyFilter;
