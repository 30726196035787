import {buildCandidateApiRequestPayload} from "helpers/filterHandlers";
import {useCallback, useEffect, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {toggleDisplayRefreshAPResult} from "store/mapx/additional-profiles/additionalProfilesActions";
import {
	additionalProfileFiltersSelector,
	additionalProfileResultsSelector,
	AdditionalProfilesPaginationInfoSelector,
	allSearchRequestsSelector,
	RejectedAdditionalProfilesPaginationInfoSelector,
	searchRequestDetailsLoadingSelector,
} from "store/mapx/additional-profiles/additionalProfilesSelectors";

import PaginatedResultBlock from "./PaginatedResultBlock";
import styles from "./styles.module.scss";
import {useAppDispatch, useAppSelector} from "hooks";
import {userLicenseTypeNameSelector} from "store/mapx/user/userSelectors";
import {
	TSearchRequestMiniResponse,
	TSearchRequestStatus,
} from "api/projectApi/searchRequestApi/types";
import {getSearchRequestedProfilesByQuery} from "store/mapx/additional-profiles/searchRequestAsyncActions";
import {targetListCandidatesSelector} from "store/mapx/target-list/targetListSelectors";
import {ATPagination} from "api/types";
import {
	ICandidateSearchApiRequestPayload,
	TCandidateApiFilterSelection,
} from "api/candidateApi/form";
import {TSearchRequestContentBlockProps} from "mapx-components/SearchRequestResults/ContentBlock/types";
import ResultSkeletonLoader from "mapx-components/SearchRequestResults/ContentBlock/ResultSkeletonLoader";
import ResultHeaderBlock from "mapx-components/SearchRequestResults/ContentBlock/ResultHeaderBlock";

let intervalId: ReturnType<typeof setInterval>;

const SearchRequestContentBlock = ({searchRequest}: TSearchRequestContentBlockProps) => {
	const [loadingFilteredResult, setLoadingFilteredResult] = useState(false);

	const [cancelled, setCancelled] = useState(false);

	const [startIntervalFetching, setStartIntervalFetching] = useState(false);

	const [displayAllExp, setDisplayAllExp] = useState(false);

	const [loadingFilteredResultWhenInProgress, setLoadingFilteredResultWhenInProgress] =
		useState(false);

	const [fetchingLastCount, setFetchingLastCount] = useState(false);

	const profilesResults = useSelector(additionalProfileResultsSelector);

	const filterSelection = useSelector(additionalProfileFiltersSelector);

	const profilesResultsPaginationInfo = useSelector(AdditionalProfilesPaginationInfoSelector);

	const rejectedProfilesPaginationInfo = useSelector(
		RejectedAdditionalProfilesPaginationInfoSelector,
	);

	const searchRequestDetailsLoading = useSelector(searchRequestDetailsLoadingSelector);

	const targetListCandidates = useSelector(targetListCandidatesSelector);

	const userLicenseTypeName = useAppSelector(userLicenseTypeNameSelector);

	const allSearchRequests = useAppSelector(allSearchRequestsSelector);

	const dispatch = useAppDispatch();

	const currentSearchRequestStatus: TSearchRequestStatus = useMemo(() => {
		return searchRequest?.status;
	}, [searchRequest]);

	const iterationCount: number = useMemo(() => {
		if (searchRequest?.type === "Free Text Input Search") {
			const childSearches = allSearchRequests.filter(
				(searchRequest: TSearchRequestMiniResponse) => {
					return searchRequest.parent_search_request_id === searchRequest.id;
				},
			);

			return childSearches.length === 0 ? 5 : 5 - childSearches.length;
		}

		return 0;
	}, [allSearchRequests, searchRequest?.type]);

	const baseApiPayload = useMemo(() => {
		return {
			found_by_search_request: [searchRequest?.id],
		};
	}, [searchRequest]);

	const candidateFilterPayload = useMemo(() => {
		const payload = buildCandidateApiRequestPayload({...filterSelection});

		if (payload !== null) {
			return {...payload.filters, ...payload.sorting_options};
		} else return null;
	}, [filterSelection]);

	const fetchResults = useCallback(
		async (
			payload: TCandidateApiFilterSelection,
			onlyCountCheck: boolean,
			paginateInfo: Nullable<ATPagination>,
		): Promise<string> => {
			if (!fetchingLastCount) {
				const apiPayload = buildCandidateApiRequestPayload(payload) as Omit<
					ICandidateSearchApiRequestPayload,
					"pagination"
				>;

				if (apiPayload) {
					setFetchingLastCount(true);
				}

				const latestCount = await dispatch(
					getSearchRequestedProfilesByQuery(apiPayload, 1, onlyCountCheck),
				);

				if (latestCount === "cancelled" && !onlyCountCheck) {
					setCancelled(true);

					return "cancelled";
				} else {
					if (onlyCountCheck) {
						if (
							latestCount &&
							paginateInfo !== null &&
							latestCount > paginateInfo?.count
						) {
							dispatch(toggleDisplayRefreshAPResult(true));
						} else {
							dispatch(toggleDisplayRefreshAPResult(false));
						}
					} else {
						// dispatch(getKeywordsListForAP(queryParams));

						dispatch(toggleDisplayRefreshAPResult(false));
					}

					setCancelled(false);
				}
			}

			return "success";
		},
		[dispatch, fetchingLastCount],
	);

	const preparePayloadAndSendRequest = useCallback(
		async (
			filterPayload: Nullable<TCandidateApiFilterSelection>,
			onlyCountCheck = false,
			paginateInfo = null,
		) => {
			if (!onlyCountCheck && filterPayload !== null) {
				setLoadingFilteredResultWhenInProgress(true);
			}

			let payload;

			if (filterPayload) {
				payload = {...filterPayload, ...baseApiPayload};
			} else {
				payload = {...baseApiPayload};
			}

			const response = await fetchResults(payload, onlyCountCheck, paginateInfo);

			if (!onlyCountCheck && filterPayload !== null && response === "success") {
				setLoadingFilteredResultWhenInProgress(false);
			} else if (filterPayload !== null && !onlyCountCheck && response === "cancelled") {
				setLoadingFilteredResultWhenInProgress(true);
			}
		},
		[baseApiPayload, fetchResults],
	);

	useEffect(() => {
		if (startIntervalFetching && candidateFilterPayload === null) {
			intervalId = setInterval(async () => {
				const paginateInfo = profilesResultsPaginationInfo;

				let onlyCountCheck: boolean;

				const type = searchRequest?.type;

				if (type === "Additional Profiles") {
					onlyCountCheck = paginateInfo !== null ? paginateInfo.count > 0 : false;
				} else {
					onlyCountCheck = false;
				}

				await preparePayloadAndSendRequest(null, onlyCountCheck, paginateInfo);
			}, 10000);

			setTimeout(() => {
				if (intervalId) {
					clearInterval(intervalId);
				}
			}, 60 * 300 * 1000); // it will clear after 5 hours
		}

		return () => {
			if (intervalId) {
				clearInterval(intervalId);
			}
		};
	}, [
		profilesResultsPaginationInfo,
		preparePayloadAndSendRequest,
		candidateFilterPayload,
		startIntervalFetching,
		searchRequest?.type,
	]);

	useEffect(() => {
		if (currentSearchRequestStatus === "In Progress" && candidateFilterPayload !== null) {
			setStartIntervalFetching(false);

			preparePayloadAndSendRequest(candidateFilterPayload, false, null);

			if (intervalId) {
				clearInterval(intervalId);
			}
		} else if (
			currentSearchRequestStatus === "In Progress" &&
			candidateFilterPayload === null
		) {
			setStartIntervalFetching(true);

			fetchResults(baseApiPayload, false, null);
		} else {
			setStartIntervalFetching(false);
		}
	}, [
		baseApiPayload,
		preparePayloadAndSendRequest,
		candidateFilterPayload,
		fetchResults,
		startIntervalFetching,
		currentSearchRequestStatus,
	]);

	const activeResultsInfo = useMemo(() => {
		return {
			count: profilesResultsPaginationInfo ? profilesResultsPaginationInfo.count : 0,
			candidates: profilesResults,
		};
	}, [profilesResults, profilesResultsPaginationInfo]);

	const displayProfilesResult = useMemo(
		() =>
			currentSearchRequestStatus === "Completed" ||
			activeResultsInfo?.count > 0 ||
			!loadingFilteredResult,
		[activeResultsInfo?.count, currentSearchRequestStatus, loadingFilteredResult],
	);

	const displaySkeletonLoader = useMemo(() => {
		// console.log(loadingFilteredResult, "loadingFilteredResult")
		// console.log(loadingFilteredResultWhenInProgress, "loadingFilteredResultWhenInProgress")
		// console.log(cancelled, "cancelled")
		// console.log(searchRequestDetailsLoading, "searchRequestDetailsLoading")
		// console.log("###ND")

		if (
			currentSearchRequestStatus === "In Progress" &&
			(startIntervalFetching || loadingFilteredResultWhenInProgress || cancelled)
		) {
			return true;
		}

		return (
			currentSearchRequestStatus === "Completed" &&
			(loadingFilteredResult || cancelled || searchRequestDetailsLoading)
		);
	}, [
		currentSearchRequestStatus,
		startIntervalFetching,
		loadingFilteredResultWhenInProgress,
		cancelled,
		loadingFilteredResult,
		searchRequestDetailsLoading,
	]);

	const displayOnlyProgressBar = useMemo(() => {
		if (displaySkeletonLoader && displayProfilesResult) {
			if (
				currentSearchRequestStatus === "In Progress" &&
				activeResultsInfo?.count > 0 &&
				!loadingFilteredResultWhenInProgress
			) {
				return true;
			}
		}

		return false;
	}, [
		displaySkeletonLoader,
		displayProfilesResult,
		activeResultsInfo?.count,
		currentSearchRequestStatus,
		loadingFilteredResultWhenInProgress,
	]);

	const handleAPICall = useCallback(
		async (
			page: number,
			filterPayload?: Nullable<TCandidateApiFilterSelection>,
			perPage = 20,
		) => {
			let payload;

			if (filterPayload) {
				payload = {...filterPayload, ...baseApiPayload};
			} else {
				payload = {...baseApiPayload};
			}

			payload = buildCandidateApiRequestPayload(payload) as Omit<
				ICandidateSearchApiRequestPayload,
				"pagination"
			>;

			const response = await dispatch(
				getSearchRequestedProfilesByQuery(payload, page, false, perPage),
			);

			return response;
		},
		[baseApiPayload, dispatch],
	);

	const progressPercentage = useMemo(() => searchRequest?.progress, [searchRequest]);

	const limitResultCountForStarterUser = useMemo(() => {
		if (userLicenseTypeName === "Starter") {
			const decisionMadeCount =
				(targetListCandidates?.length || 0) + (rejectedProfilesPaginationInfo?.count || 0);

			return 10 - (decisionMadeCount > 10 ? 10 : decisionMadeCount);
		}
	}, [userLicenseTypeName, targetListCandidates?.length, rejectedProfilesPaginationInfo?.count]);

	const displayResultHeaderBlock = useMemo(
		() =>
			userLicenseTypeName !== "Starter" ||
			(limitResultCountForStarterUser !== undefined && limitResultCountForStarterUser > 0),
		[limitResultCountForStarterUser, userLicenseTypeName],
	);

	return (
		<div className={styles.contentWrapper}>
			{displayResultHeaderBlock && (
				<ResultHeaderBlock
					searchType={searchRequest.type}
					searchStatus={currentSearchRequestStatus}
					iterationCount={iterationCount}
					displayAllExperience={displayAllExp}
					handleDisplayAllExpClick={() => setDisplayAllExp((prev) => !prev)}
					activeResultsInfo={activeResultsInfo}
					handleAPICall={async (
						page: number,
						payload?: Nullable<TCandidateApiFilterSelection>,
						perPage?: number,
					) => {
						setLoadingFilteredResultWhenInProgress(true);
						await handleAPICall(page, payload, perPage);
						setLoadingFilteredResultWhenInProgress(false);
					}}
					displayClearFilters={!!candidateFilterPayload}
				/>
			)}

			{displaySkeletonLoader && (
				<ResultSkeletonLoader
					key={profilesResultsPaginationInfo?.count || "Default"}
					searchType={searchRequest.type}
					onlyProgressBar={displayOnlyProgressBar}
					hideProgressBar={currentSearchRequestStatus === "Completed"}
					progress={progressPercentage}
					results={profilesResultsPaginationInfo?.count || 0}
					searchRequestStatus={currentSearchRequestStatus}
					uniqueUrlsCount={searchRequest?.unique_urls_count}
					uniqueUrlsRejectedCount={searchRequest?.unique_urls_rejected}
				/>
			)}

			{displayProfilesResult && (
				<PaginatedResultBlock
					displayingLoader={displaySkeletonLoader}
					displayAllExperience={displayAllExp}
					setLoadingFilteredResult={setLoadingFilteredResult}
					setCancelled={setCancelled}
					results={activeResultsInfo}
					handleAPICall={handleAPICall}
					searchType={searchRequest.type}
					searchID={searchRequest.id}
					currentSearchStatus={currentSearchRequestStatus}
					limitResult={limitResultCountForStarterUser}
				/>
			)}
		</div>
	);
};

export default SearchRequestContentBlock;
