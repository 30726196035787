import React, {useCallback, useMemo, useRef, useState} from "react";
import styles from "./styles.module.scss";
import {TProjectCandidateTableCardProps} from "mapx-components/Cards/ProjectCandidateTableCard/types";
import useProjectSavedCandidatesHook from "mapx-pages/Project/SavedPeople/useProjectSavedCandidatesHook";
import {handleCandidateSelectionForAssessment} from "store/mapx/project/projectAssessmentAsyncActions";
import {Checkbox, CompanyInfoTooltip, LazyLoadImageWrapper} from "components";
import {useAppDispatch, useAppSelector} from "hooks";
import classNames from "classnames";
import {BiUser} from "react-icons/bi";
import {getAltStandardDateFormatFromIso} from "helpers/date";
import {RecommendedIcon} from "assets/icons";
import {SICandidatePosition} from "api/candidateApi/types";
import {tooltipCompaniesSelector} from "store/mapx/company/companySelectors";
import useDebouncedEffect from "use-debounced-effect";
import {updateTooltipCompaniesList} from "store/mapx/company/companyAsyncActions";
import {NavLink} from "react-router-dom";
import RemoveMinusIcon from "mapx-components/Cards/ProjectCandidateCard/RemoveMinusIcon";
import ScoringSection from "mapx-components/Cards/ProjectCandidateTableCard/ScoringSection";
import ProjectSavedPeopleActions from "mapx-components/Cards/ProjectCandidateTableCard/ProjectSavedPeopleActions";
import {removeCandidateFromTargetList} from "store/mapx/target-list/targetListAsyncActions";

const ProjectCandidateTableCard = ({
	candidate,
	searchType,
	isNextCandidateRecommended,
	type,
}: TProjectCandidateTableCardProps) => {
	const dispatch = useAppDispatch();

	const containerRef = useRef<Nullable<HTMLDivElement>>(null);

	const [removingCandidate, setRemovingCandidate] = useState(false);

	const [recommendLoading, setRecommendLoading] = useState(false);

	const {
		handleItemClick,
		isCandidateSelectedForAssessment,
		isCurrentCandidateAssessmentInProgress,
		positions,
		isCurrentCurrentCandidateRefreshIsInProgress,
		PROJECT_STATUS_DROPDOWN,
		InlineLoaderComponent,
	} = useProjectSavedCandidatesHook(candidate, searchType, 220);

	const currentPosition = useMemo(() => {
		const currentRole = positions.find((position) => position.is_current);

		if (currentRole) {
			return currentRole;
		} else if (positions.length > 0) {
			return positions[0];
		}

		return null;
	}, [positions]);

	const previousPosition = useMemo(() => {
		let currentRoleIndex = positions.findIndex((position) => position.is_current);

		if (!currentRoleIndex) {
			currentRoleIndex = positions.length - 1;
		}

		const previousRole = positions.find((_, index) => index === currentRoleIndex - 1);

		if (previousRole) {
			return previousRole;
		}

		return null;
	}, [positions]);

	const tooltipId = `${searchType}-${candidate.id}`;
	const [companyId, setCompanyId] = useState<string>("");
	const [hoveredCompanyId, setHoveredCompanyId] = useState<string | null>(null);
	const tooltipCompanies = useAppSelector(tooltipCompaniesSelector);
	const companyToWatch = companyId ? tooltipCompanies[companyId] : null;

	const handleMouseEnter = (position: SICandidatePosition) => {
		if (position?.company) {
			const company = position.company;
			setHoveredCompanyId(String(company.id));
			setCompanyId(String(company.id));
		}
	};

	const DISPLAY_COMPANY_INFO = useCallback(
		(position: SICandidatePosition) => (
			<NavLink
				data-tooltip-id={tooltipId}
				data-tooltip-delay-show={1000}
				target="_blank"
				rel="noreferrer"
				to={`/company/${position.company.id}`}
				onMouseEnter={() => handleMouseEnter(position)}
			>
				<div className={styles.company}>
					<LazyLoadImageWrapper
						alt={position ? position.company.name : ""}
						image={
							position && position?.company.logo_url !== null
								? position.company.logo_url
								: "https://storage.googleapis.com/nebula-static/logos50x50/empty_logo.png"
						}
						className={styles.companyLogo}
					/>
					<span className={styles.companyName}>
						{position ? position.company.name : ""}
					</span>
				</div>
			</NavLink>
		),
		[tooltipId],
	);

	useDebouncedEffect(
		() => {
			if (companyId && hoveredCompanyId === companyId) {
				dispatch(updateTooltipCompaniesList(companyId));
			}
		},
		1000,
		[companyId, hoveredCompanyId],
	);

	return (
		<div
			className={classNames(styles.container, {
				[styles.recommended]: candidate.is_recommended,
				[styles.isNextCandidateRecommended]: isNextCandidateRecommended,
			})}
			ref={containerRef}
		>
			<div className={styles.basicInfo}>
				<div className={styles.name}>
					{type === "project-saved-people" && (
						<div className={styles.checkbox}>
							<Checkbox
								value={candidate.id?.toString()}
								isChecked={isCandidateSelectedForAssessment}
								onChange={() =>
									dispatch(handleCandidateSelectionForAssessment(candidate?.id))
								}
							/>
						</div>
					)}

					{candidate.is_recommended && (
						<span className={styles.recommendedIcon}>
							<RecommendedIcon size={14} isInteractive={true} />
						</span>
					)}

					<div
						className={classNames({
							[styles.rotatingWrapper]:
								isCurrentCurrentCandidateRefreshIsInProgress ||
								isCurrentCandidateAssessmentInProgress ||
								recommendLoading ||
								removingCandidate,
						})}
					>
						{candidate.avatar_url !== null ? (
							<LazyLoadImageWrapper
								className={classNames(styles.logo)}
								image={candidate.avatar_url}
								alt={candidate.full_name ?? candidate.name}
								onClick={handleItemClick}
							/>
						) : (
							<BiUser
								className={classNames(styles.iconLogo)}
								onClick={handleItemClick}
								size={20}
								color={"#808080"}
							/>
						)}
					</div>
					<span onClick={handleItemClick} style={{cursor: "pointer"}}>
						{candidate.full_name}
					</span>
				</div>

				<div className={styles.role}>
					{currentPosition ? currentPosition.job_title : ""}
				</div>

				{currentPosition ? (
					DISPLAY_COMPANY_INFO(currentPosition)
				) : (
					<div className={styles.company}>-</div>
				)}

				<div className={styles.startDate}>
					{currentPosition?.start_date
						? getAltStandardDateFormatFromIso(currentPosition?.start_date)
						: "-"}
				</div>

				{type === "project-tracking-people" && (
					<div className={styles.previousRole}>
						{previousPosition ? previousPosition.job_title : ""}
					</div>
				)}

				{type === "project-tracking-people" &&
					(previousPosition ? (
						DISPLAY_COMPANY_INFO(previousPosition)
					) : (
						<div className={styles.company}>-</div>
					))}
			</div>

			{type === "project-tracking-people" && (
				<div
					className={styles.removeCandidateFromList}
					onClick={async () => {
						setRemovingCandidate(true);
						await dispatch(removeCandidateFromTargetList(candidate));
						setRemovingCandidate(false);
					}}
				>
					{removingCandidate ? (
						InlineLoaderComponent
					) : (
						<RemoveMinusIcon isInteractive={true} color={"#F20D0D"} />
					)}
				</div>
			)}

			{type === "project-saved-people" && (
				<ScoringSection candidate={candidate} searchType={searchType} />
			)}

			{type === "project-saved-people" && (
				<div className={styles.progressStatusSection}>{PROJECT_STATUS_DROPDOWN}</div>
			)}

			{type === "project-saved-people" && (
				<ProjectSavedPeopleActions
					candidate={candidate}
					isCurrentCandidateAssessmentInProgress={isCurrentCandidateAssessmentInProgress}
					removingCandidate={removingCandidate}
					setRemovingCandidate={setRemovingCandidate}
					recommendLoading={recommendLoading}
					setRecommendLoading={setRecommendLoading}
				/>
			)}

			<CompanyInfoTooltip id={tooltipId} company={companyToWatch} />
		</div>
	);
};

export default ProjectCandidateTableCard;
