import config from "config";
import {Mixpanel} from "helpers/mixpanel";
import {useAppDispatch, useAppSelector} from "hooks";
import React, {useEffect, useState} from "react";
import ReactGA from "react-ga4";
import {hotjar} from "react-hotjar";
import {getProjectList} from "store/mapx/project-list/projectListAsyncActions";
import {
	duplicateProjectModalSelector,
	editProjectModalSelector,
	getProjectListInProgressSelector,
	projectPaginationSelector,
	projectsSelector,
} from "store/mapx/project-list/projectListSelectors";
import {userEmailSelector} from "store/mapx/user/userSelectors";
import {MainWrapper, ProjectCard} from "mapx-components";
import css from "./projects.module.scss";
import ProjectSkeletonLoader from "mapx-pages/Projects/Loader";
import {STProject} from "api/projectApi/types";
import {Button} from "components";
import {CreatePlusIcon} from "assets/icons";
import styles from "../Home/HomeStyles.module.scss";
import {
	createProjectModal,
	setProjectsListPaginationInfo,
} from "store/mapx/project-list/projectListActions";
import useSearchDebounce from "hooks/useSearchDebounce";
import ProjectSearch from "./Filter/search";
import useLocationSearchService from "services/useLocationSearchService";
import ProjectEditModal from "mapx-components/Modals/ProjectEditModal";
import {useSelector} from "react-redux";
import Pagination from "mapx-components/Pagination";
import ProjectDuplicateModal from "../../mapx-components/Modals/ProjectDuplicateModal";
import {setPerPagePreference} from "store/mapx/userPreference/userPreferenceActions";
import {globalPerPageSelector} from "store/mapx/userPreference/userPreferenceSelectors";

const ProjectPage = () => {
	const dispatch = useAppDispatch();

	const displayEditModal = useSelector(editProjectModalSelector);

	const displayDuplicateModal = useSelector(duplicateProjectModalSelector);

	const projectsFetchInProgress = useAppSelector(getProjectListInProgressSelector);

	const pagination = useAppSelector(projectPaginationSelector);

	const globalPerPageValue = useAppSelector(globalPerPageSelector);

	const projects = useAppSelector(projectsSelector);

	const userEmail = useAppSelector(userEmailSelector);

	const [searchTerm, setSearchTerm] = useSearchDebounce(800);

	const [currentSearch, setCurrentSearch] = useState("");

	const {locations} = useLocationSearchService();

	const onPageChange = async (newPage: number) => {
		dispatch(setProjectsListPaginationInfo({...pagination, page: newPage}));

		const paginationInfo = {page: newPage, per_page: globalPerPageValue};

		await dispatch(getProjectList(paginationInfo, currentSearch));
	};

	const onPerPageChange = async (count: number) => {
		dispatch(setPerPagePreference(count));

		dispatch(setProjectsListPaginationInfo({...pagination, per_page: count}));

		const paginationInfo = {page: pagination.page, per_page: count};

		await dispatch(getProjectList(paginationInfo, currentSearch));
	};

	useEffect(() => {
		const paginationInfo = {page: 1};

		dispatch(getProjectList(paginationInfo, searchTerm));
	}, [dispatch, searchTerm]);

	useEffect(() => {
		try {
			const pageTitle = `Projects | ${config.pageTitlePostFix}`;

			window.document.title = pageTitle;

			ReactGA.send({hitType: "pageview", page: window.location.pathname, title: pageTitle});

			if (hotjar.initialized()) {
				hotjar.stateChange(window.location.pathname);
			}

			Mixpanel.track(`Project List Page Viewed`, {
				name: `Project List Page`,
				pageTitle: `Project List Page`,
				url: window.location.pathname,
				distinct_id: userEmail,
			});
		} catch (e) {
			console.log(e);
		}
	}, [userEmail]);

	const LoaderComponent = <ProjectSkeletonLoader count={10} />;

	const handleCreateProjectClick = () => {
		dispatch(createProjectModal(true));
	};

	const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(e.target.value);
		setCurrentSearch(e.target.value);
		dispatch(setProjectsListPaginationInfo({...pagination, page: 1}));
	};

	return (
		<MainWrapper>
			<div className={css.container}>
				<div className={css.headerSection}>
					<p>All Projects</p>

					<div className={css.headerSection}>
						<ProjectSearch
							onChange={handleSearchInput}
							loading={projectsFetchInProgress}
							value={currentSearch}
						/>

						<Button
							data-testid="addNewProjectButtonOnProjectsList"
							LeftIcon={CreatePlusIcon}
							onClick={handleCreateProjectClick}
							className={styles.content__bottom__info__buttons_create}
							leftIconClasses={styles.content__bottom__info__buttons_create_icon}
						>
							Create a New Project
						</Button>
					</div>
				</div>

				{projectsFetchInProgress && LoaderComponent}

				{!projectsFetchInProgress &&
					projects?.map(
						(project: STProject, index: number) =>
							project && (
								<ProjectCard
									key={`${index}${project.id}${project.name}`}
									project={project}
									locations={locations}
								/>
							),
					)}

				{!projectsFetchInProgress && pagination && pagination?.count >= 10 && (
					<div className={css.paginationWrapper}>
						<Pagination
							currentPage={pagination?.page}
							resultsCount={pagination?.count}
							totalPages={pagination?.pages}
							perPage={globalPerPageValue}
							onPageChange={onPageChange}
							onPerPageChange={onPerPageChange}
						/>
					</div>
				)}

				{!projects?.length && searchTerm !== "" && (
					<div className={css.noQueryMatch}>
						<h4>No project was found with your search query</h4>
						<p>Try with something different</p>
					</div>
				)}

				{!projects?.length && searchTerm === "" && (
					<div className={css.noQueryMatch} onClick={handleCreateProjectClick}>
						<h4>{"You don't have any projects yet!"}</h4>
						<p>
							Click on the Create a New Project button to get started with a project
						</p>
					</div>
				)}
			</div>

			{displayEditModal && <ProjectEditModal />}
			{displayDuplicateModal && <ProjectDuplicateModal />}
		</MainWrapper>
	);
};

export default ProjectPage;
