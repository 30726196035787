import React from "react";

const SmallCardIcon = ({...props}) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			{...props}
		>
			<mask
				id="mask0_1697_21365"
				style={{maskType: "alpha"}}
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="20"
				height="20"
			>
				<rect width="20" height="20" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_1697_21365)">
				<path
					d="M15.5 13.5V10.75H4.5V13.5H15.5ZM15.5 9.25V6.5H4.5V9.25H15.5ZM4.5 15C4.0875 15 3.73437 14.8531 3.44062 14.5594C3.14687 14.2656 3 13.9125 3 13.5V6.5C3 6.0875 3.14687 5.73438 3.44062 5.44063C3.73437 5.14688 4.0875 5 4.5 5H15.5C15.9125 5 16.2656 5.14688 16.5594 5.44063C16.8531 5.73438 17 6.0875 17 6.5V13.5C17 13.9125 16.8531 14.2656 16.5594 14.5594C16.2656 14.8531 15.9125 15 15.5 15H4.5Z"
					fill="#B5B1B0"
				/>
			</g>
		</svg>
	);
};

export default SmallCardIcon;
