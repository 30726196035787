import {createSelector} from "reselect";
import {TRootState} from "types";
import {STProject} from "api/projectApi/types";

export const projectTabIndexSelector = (state: TRootState) => state.projectList.projectTabIndex;

export const projectSelector = (state: TRootState) => state?.projectList?.project;

export const currentProjectIDSelector = (state: TRootState) => state?.projectList?.currentProjectID;

export const projectPaginationSelector = (state: TRootState) => state.projectList.pagination;

export const projectsSelector = (state: TRootState) => state.projectList.projects;

export const latestProjectsSelector = (state: TRootState) => state.projectList.latestProjects;

export const createProjectModalSelector = (state: TRootState) =>
	state.projectList.createProjectModal;
export const editProjectModalSelector = (state: TRootState) => state.projectList.editProjectModal;

export const duplicateProjectModalSelector = (state: TRootState) =>
	state.projectList.duplicateProjectModal;
export const editProjectFormSelector = (state: TRootState) => state.projectList.projectForm;

export const updateProjectListInProgressSelector = (state: TRootState) =>
	state.projectList.updateProjectListInProgress;

export const getProjectListInProgressSelector = (state: TRootState) =>
	state.projectList.getProjectListInProgress;

export const getLatestProjectListInProgressSelector = (state: TRootState) =>
	state.projectList.getLatestProjectListInProgress;

export const loadingProjectsSelector = createSelector(
	[updateProjectListInProgressSelector, getProjectListInProgressSelector],
	(updateProjectListInProgress, getProjectListInProgress) =>
		updateProjectListInProgress || getProjectListInProgress,
);

export const getProjectNameByProjectIDSelector = createSelector(
	[projectsSelector, (_, id) => id],
	(projectList, id) => projectList.find((project: STProject) => project.id === id)?.name || null,
);

export const updateProjectInProgressSelector = (state: TRootState) =>
	state.projectList.updateProjectInProgress;

export const duplicateProjectInProgressSelector = (state: TRootState) =>
	state.projectList.duplicateProjectInProgress;

export const saveProjectInProgressSelector = (state: TRootState) =>
	state.projectList.saveProjectInProgress;

export const deletingProjectInProgressSelector = (state: TRootState) =>
	state.projectList.deletingProjectInProgress;

export const getProjectInProgressSelector = (state: TRootState) =>
	state.projectList.getProjectInProgress;

export const getProjectWorkflowStepSelector = (state: TRootState) =>
	state.projectList.projectWorkflowStep;
export const getProjectFormSelector = (state: TRootState) => state.projectList.projectForm;
