import React from "react";
import {BiUser} from "react-icons/bi";
import classNames from "classnames";
import LazyLoadImageWrapper from "components/LazyLoadImageWrapper";
import candidateInformationStyles from "../../../../Cards/CandidateCard/CandidateInformation/candidateInformation.module.scss";
import styles from "../../styles.module.scss";
import {SICandidate} from "api/candidateApi/types";
import {convertDateToShortMonthFullYearFormat} from "helpers/date";

const UserIntroInfo = (candidate: SICandidate) => {
	const currentPositions = Array.isArray(candidate?.positions)
		? candidate?.positions?.filter((c) => c?.is_current)
		: null;

	let currentPosition;

	if (currentPositions && currentPositions.length > 0) {
		currentPosition = currentPositions[0];
	} else if (Array.isArray(candidate?.positions) && candidate?.positions?.length > 0) {
		currentPosition = candidate?.positions[0];
	}

	return (
		<div className={candidateInformationStyles.candidateLocation}>
			<div
				className={classNames(
					candidateInformationStyles.candidateTop,
					candidateInformationStyles.brief,
				)}
			>
				<div
					className={candidateInformationStyles.infoDetail}
					style={{color: "rgba(78, 85, 85, 1)"}}
				>
					{currentPosition && (
						<>
							{currentPosition?.company?.logo_url ? (
								<LazyLoadImageWrapper
									className={styles.companyLogo}
									image={currentPosition.company.logo_url}
									alt={candidate?.full_name ?? candidate?.name}
								/>
							) : (
								<BiUser className={styles.companyLogo} />
							)}

							<span className={styles.boldText}>{currentPosition.job_title}</span>
							<span style={{margin: "0 5px"}}>at</span>
							<span className={styles.boldText}>{currentPosition.company?.name}</span>
							<span className={styles.dotSpacing}>.</span>
							<span>
								{convertDateToShortMonthFullYearFormat(currentPosition.start_date)}
							</span>
							<span> - </span>
							<span>
								{currentPosition.end_date
									? convertDateToShortMonthFullYearFormat(
											currentPosition.end_date,
									  )
									: "Present"}
							</span>
						</>
					)}

					{candidate?.country?.name && (
						<>
							<span className={styles.dotSpacing}>.</span>
							<span>{candidate.country.name}</span>
							{candidate.town && candidate.country.name !== candidate.town && (
								<span>
									{", "}
									{candidate.town}
								</span>
							)}
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default UserIntroInfo;
