import {TRootState} from "types";

export const selectedTrackCandidateIdsSelector = (state: TRootState) =>
	state.mapxProject.selectedTrackCandidateIds;
export const selectedTrackCandidatePositionChangedDaysSelector = (state: TRootState) =>
	state.mapxProject.selectedTrackCandidatePositionChangedDays;
export const fetchingCandidateTrackLeaversSelector = (state: TRootState) =>
	state.mapxProject.fetchingCandidateTrackLeavers;
export const projectCandidateTrackLeaversSelector = (state: TRootState) =>
	state.mapxProject.projectCandidateTrackLeavers;
export const projectCandidateTrackLeaversPaginationSelector = (state: TRootState) =>
	state.mapxProject.projectCandidateTrackLeaversPagination;
export const fetchingCandidateTrackPromotionsSelector = (state: TRootState) =>
	state.mapxProject.fetchingCandidateTrackPromotions;
export const projectCandidateTrackPromotionsSelector = (state: TRootState) =>
	state.mapxProject.projectCandidateTrackPromotions;
export const projectCandidateTrackPromotionsPaginationSelector = (state: TRootState) =>
	state.mapxProject.projectCandidateTrackPromotionsPagination;
export const fetchingCandidateTrackNoRecentChangesSelector = (state: TRootState) =>
	state.mapxProject.fetchingCandidateTrackNoRecentChanges;
export const projectCandidateTrackNoRecentChangesSelector = (state: TRootState) =>
	state.mapxProject.projectCandidateTrackNoRecentChanges;
export const projectCandidateTrackNoRecentChangesPaginationSelector = (state: TRootState) =>
	state.mapxProject.projectCandidateTrackNoRecentChangesPagination;
