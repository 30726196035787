import {loadState, saveState} from "helpers/localStorage";
import {applyMiddleware, compose, createStore} from "redux";
import {createLogger} from "redux-logger";
import thunk from "redux-thunk";
import {SET_TRACKED_EVENT_DATA} from "./mapx/eventTrackingActionTypes";
import {marketMapInitialState} from "./mapx/market-map/marketMapReducers";
import rootReducer from "./Reducers";

const middleware = [thunk];

if (process.env.REACT_APP_REDUX_LOGGER === "true") {
	middleware.push(
		createLogger({
			predicate: (getState, action) => action.type !== SET_TRACKED_EVENT_DATA,
		}),
	);
}

const composeEnhancers =
	(typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const persistedState = loadState();

const store = createStore(
	rootReducer,
	persistedState,
	composeEnhancers(applyMiddleware(...middleware)),
);

store.subscribe(() => {
	saveState({
		user: {
			...store.getState().user,
			isTutorialModalOpened: false,
			updatingUsers: [],
		},
		organisation: store.getState().organisation,
		search: {
			...store.getState().search,
			searchData: [],
			getSearchDataInProgress: null,
			searchDataError: null,
		},
		targetList: {
			...store.getState().targetList,
			targetListCandidateInProgress: false,
			filteredSavedPeopleFetchIsInProgress: false,
			targetListCompanyInProgress: false,
		},
		projectList: {
			...store.getState().projectList,
			projects: [],
			// project: null,
		},
		candidate: store.getState().candidate,
		linkedinImport: store.getState().linkedinImport,
		backgroundTask: store.getState().backgroundTask,
		refresh_candidate: store.getState().refresh_candidate,
		company: {
			...store.getState().company,
			currentCompany: null,
		},
		filter: store.getState().filter,
		mapxProject: {
			...store.getState().mapxProject,
			onboardingStep: null,
			openOnboardingComponent: false,
		},
		events: store.getState().events,
		additional_profiles: {
			...store.getState().additional_profiles,
			ap_filters: store.getState().additional_profiles.ap_filters,
		},
		market_map: {
			...marketMapInitialState,
			created_by: store.getState().market_map.created_by,
		},
		marketReport: {
			...store.getState().marketReport,
			results: [],
		},
		layout: store.getState().layout,
		userPreference: store.getState().userPreference,
	});
});

export default store;
