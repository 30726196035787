import {setFilterForCompanies, setMultipleFilterForCompany} from "store/mapx/filter/filterActions";
import {TAppDispatch, TRootState} from "types";
import axios, {AxiosResponse, CancelTokenSource} from "axios";
import {
	companySpecialtiesAndSelector,
	companySpecialtiesNotSelector,
	companySpecialtiesOrSelector,
} from "./filterSelectors";
import {buildQueryParameter, removeDuplicatesFromArray} from "helpers/filterHandlers";
import {
	addSpecialityClustersToList,
	getSpecialityClustersFetch,
	getSpecialtyClustersSuccess,
} from "../search/searchActions";
import mapxSpecialityApi from "api/filterOptionsApi/SpecialityApi";
import {successResponse} from "helpers/map";
import {
	companySpecialityClusterFilterKeyByLogic,
	companySpecialtyClusterByLogicSelector,
} from "./companySpecialtyCLusterSelectors";

const cancelTokens: {[key: string]: CancelTokenSource} = {};

export const getClusterSpecialities =
	(searchTerm = "", pageNumber = 1) =>
	async (dispatch: TAppDispatch, getState: TRootState) => {
		const requestKey = `GET_RECOMMENDED_SPECIALTIES_CLUSTER}`;

		if (requestKey in cancelTokens) {
			cancelTokens[requestKey].cancel("Operation canceled due to new request.");
		}

		cancelTokens[requestKey] = axios.CancelToken.source();

		const config = {
			cancelToken: cancelTokens[requestKey].token,
		};

		const state = getState();

		const {company_filters} = state.filter;
		const filters = {...company_filters};

		const and = companySpecialtiesAndSelector(state);
		const or = companySpecialtiesOrSelector(state);
		const not = companySpecialtiesNotSelector(state);

		if (and?.length > 0 || or?.length > 0 || not?.length > 0) {
			filters.selected_specialties = removeDuplicatesFromArray([...and, ...or, ...not]);
			delete filters.specialties;
			delete filters.specialties_not;
			delete filters.specialties_and;
			delete filters.specialties_or;
		}

		let queryParam = buildQueryParameter(filters);

		dispatch(getSpecialityClustersFetch());

		try {
			let response: Nullable<AxiosResponse> = null;

			if (searchTerm !== "" || queryParam) {
				if (searchTerm !== "" && queryParam == null) {
					queryParam = `?name=${searchTerm}`;
				} else if (searchTerm !== "" && queryParam != null) {
					queryParam = `${queryParam}&name=${searchTerm}`; // cause default query param attaches "?"
				}

				response = await mapxSpecialityApi.searchClusterSpecialities(
					queryParam,
					pageNumber,
					config,
				);
			} else if (searchTerm === "" && queryParam == null) {
				response = await mapxSpecialityApi.getClusterSpecialities(pageNumber, config);
			}

			if (response && successResponse(response, 200)) {
				const results = response.data.results;

				let shouldAddMore = false;

				if (pageNumber > 1) {
					shouldAddMore = true;
				}

				dispatch(
					getSpecialtyClustersSuccess({
						results,
						shouldAddMore,
						selectedSpecialities: filters.selected_specialties || [],
					}),
				);

				if (response.data.pagination.pages === pageNumber) {
					return {
						stopPaginate: true,
					};
				} else {
					return {
						stopPaginate: false,
					};
				}
			}
		} catch (error) {
			const apiError = error as {data: string};

			return {error: apiError.data, stopPaginate: false};
		}
	};

export const setBulkSpecialityClusterForCompanies =
	(logic: string, ids: number[]) => (dispatch: TAppDispatch) => {
		const specialitiesFilterKey = companySpecialityClusterFilterKeyByLogic("_", logic);

		const payload = {
			[specialitiesFilterKey]: ids,
		};

		dispatch(setMultipleFilterForCompany(payload));
	};

export const setSpecialityClustersForCompany =
	(ids: number[], logic: string) => async (dispatch: TAppDispatch, getState: TRootState) => {
		const state = getState();

		const specialities = companySpecialtyClusterByLogicSelector(state, logic);
		const alreadySelectedIds = ids?.filter((id) => specialities.includes(id));

		let nextSpecialities;
		if (alreadySelectedIds.length > 0) {
			nextSpecialities = specialities.filter(
				(speciality: number) => !ids.includes(speciality),
			);
		} else {
			nextSpecialities = [...specialities, ...ids];
		}

		const specialtyFilterKey = companySpecialityClusterFilterKeyByLogic(state, logic);

		dispatch(setFilterForCompanies({type: specialtyFilterKey, values: nextSpecialities}));
	};

export const moveSpecialtyClustersForCompanies =
	({from, to, ids}: {from: string; to: string; ids: number[]}) =>
	async (dispatch: TAppDispatch, getState: TRootState) => {
		const idsSet = new Set(ids);

		const state = getState();

		const fromFilterKey = companySpecialityClusterFilterKeyByLogic(state, from);

		const toFilterKey = companySpecialityClusterFilterKeyByLogic(state, to);

		const fromIds = companySpecialtyClusterByLogicSelector(state, from);

		const toIds = companySpecialtyClusterByLogicSelector(state, to);

		dispatch(
			setMultipleFilterForCompany({
				[fromFilterKey]: fromIds.filter((id: number) => !idsSet.has(id)),
				[toFilterKey]: [
					...toIds.filter((id: number) => !idsSet.has(id)), // prevent duplication
					...fromIds.filter((id: number) => idsSet.has(id)),
				],
			}),
		);
	};

export const fetchAndAddSpecialityClusters =
	(clusterNames: string[]) =>
	async (dispatch: TAppDispatch): Promise<void> => {
		try {
			// fetch all the parents of the specialties selected by user
			const requests = clusterNames.map((clusterName) => {
				const queryParam = `?name=${encodeURIComponent(clusterName)}`;

				return mapxSpecialityApi.searchClusterSpecialities(queryParam, 1);
			});

			const responses: AxiosResponse[] = await Promise.all(requests);

			//flatmapping all the results to a single array
			const results = responses.flatMap((response) => response.data?.results).filter(Boolean);

			//add the whole array of specialty cluster to the state
			dispatch(addSpecialityClustersToList(results));
		} catch (error) {
			console.error("Error fetching specialty clusters", error);
			throw error;
		}
	};
