import React, {useCallback, useState} from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";
import Switch from "components/Inputs/Switch";
import JobTitlesSearchFilter from "mapx-components/Filters/JobTitlesSearchFilter";
import {useAppDispatch, useAppSelector} from "hooks";
import {getJobTitleClassification} from "store/mapx/search/jobTitlesAsyncActions";
import {getJobTitleInProgressSelector} from "store/mapx/search/searchSelectors";
import {removeDuplicatesFromArray} from "helpers/filterHandlers";
import {getJobTitlesSuccess} from "store/mapx/search/searchActions";
import {Accordion} from "mapx-components";
import {
	mRCandidateJobTitleFilterModeSelector,
	mRCandidateSelectedJobTitlesSelector,
	mRCandidateSuggestedJobTitlesSelector,
} from "store/mapx/market-report/marketReportFilterSelectors";
import {TJobTitleFilterMode} from "store/mapx/market-report/types";
import {
	setMarketReportMultipleFilterValueInForm,
	setMRJobTitleMode,
} from "store/mapx/market-report/marketReportActions";

const JobTitleFilter = () => {
	const [loadingClassification, setLoadingClassification] = useState(false);

	const jobTitleFilterMode: TJobTitleFilterMode = useAppSelector(
		mRCandidateJobTitleFilterModeSelector,
	);

	const selectedJobTitles: string[] = useAppSelector(mRCandidateSelectedJobTitlesSelector);

	const suggestedJobTitles: string[] = useAppSelector(mRCandidateSuggestedJobTitlesSelector);

	const getJobTitleInProgress = useAppSelector(getJobTitleInProgressSelector);

	const dispatch = useAppDispatch();

	const setFilters = useCallback(
		async (updatedJobTitles: string[], mode: "contains" | "close") => {
			if (mode === "close") {
				if (updatedJobTitles?.length > 0) {
					setLoadingClassification(true);

					const filters = await dispatch(getJobTitleClassification(updatedJobTitles));

					dispatch(
						setMarketReportMultipleFilterValueInForm({
							suggested_job_titles: filters.job_titles,
							current_job_titles_or: [],
							current_specialisms_or: filters.specialisms,
							current_seniority: filters.current_seniority,
						}),
					);

					setLoadingClassification(false);
				} else {
					dispatch(
						setMarketReportMultipleFilterValueInForm({
							current_job_titles_or: [],
							suggested_job_titles: [],
						}),
					);
				}
			} else {
				dispatch(
					setMarketReportMultipleFilterValueInForm({
						current_job_titles_or: updatedJobTitles,
						suggested_job_titles: [],
						current_specialisms_or: [],
						current_seniority: [],
					}),
				);
			}
		},
		[dispatch],
	);

	const handleBulkUpdate = useCallback(
		(newJobTitles: string[]) => {
			const updatedJobTitles = removeDuplicatesFromArray([
				...selectedJobTitles,
				...newJobTitles,
			]);

			setFilters(updatedJobTitles, jobTitleFilterMode);
		},
		[jobTitleFilterMode, selectedJobTitles, setFilters],
	);

	const handleSwitchClick = useCallback(async () => {
		const mode = jobTitleFilterMode === "contains" ? "close" : "contains";

		dispatch(setMRJobTitleMode(mode));

		if (selectedJobTitles?.length > 0) {
			setFilters([...selectedJobTitles], mode);
		} else if (suggestedJobTitles?.length > 0) {
			setFilters([...suggestedJobTitles], mode);
		}
	}, [dispatch, jobTitleFilterMode, selectedJobTitles, setFilters, suggestedJobTitles]);

	const handleOnChange = useCallback(
		async (selectedItem: string) => {
			let updatedJobTitles = [...selectedJobTitles];
			let updatedSuggestedJobTitles = [...suggestedJobTitles];

			if (jobTitleFilterMode === "close") {
				if (updatedSuggestedJobTitles.includes(selectedItem)) {
					updatedSuggestedJobTitles = updatedSuggestedJobTitles.filter(
						(jt) => jt !== selectedItem,
					);
				} else {
					updatedSuggestedJobTitles = [...updatedSuggestedJobTitles, selectedItem];
				}

				setFilters(updatedSuggestedJobTitles, jobTitleFilterMode);
			} else {
				if (updatedJobTitles.includes(selectedItem)) {
					updatedJobTitles = updatedJobTitles.filter((jt) => jt !== selectedItem);
				} else {
					updatedJobTitles = [...updatedJobTitles, selectedItem];
				}

				setFilters(updatedJobTitles, jobTitleFilterMode);
			}
		},
		[jobTitleFilterMode, selectedJobTitles, setFilters, suggestedJobTitles],
	);

	const handleResetClick = useCallback(() => {
		setFilters([], jobTitleFilterMode);

		dispatch(getJobTitlesSuccess([]));
	}, [jobTitleFilterMode, setFilters, dispatch]);

	return (
		<Accordion
			title="Job Titles"
			subtitle={selectedJobTitles?.length > 0 ? selectedJobTitles.length : ""}
		>
			<div className={classNames(styles.inputContainer)}>
				<div className={styles.containSwitch}>
					<span className={styles.active}>Contains</span>
					<Switch checked={jobTitleFilterMode === "close"} onChange={handleSwitchClick} />
					<span className={styles.inactive}>Close match</span>
				</div>

				<JobTitlesSearchFilter
					handleOnChange={handleOnChange}
					handleResetClick={handleResetClick}
					selectedJobTitles={
						selectedJobTitles?.length > 0 ? selectedJobTitles : suggestedJobTitles
					}
					handleBulkUpdate={handleBulkUpdate}
					addByClick={true}
					loading={getJobTitleInProgress || loadingClassification}
					expanded={true}
				/>
			</div>
		</Accordion>
	);
};

export default JobTitleFilter;
