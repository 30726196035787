import React from "react";
import styles from "./styles.module.scss";
import TrackingFilterSection from "mapx-pages/Project/TrackingPeople/FilterSection";
import LeaverCandidates from "mapx-pages/Project/TrackingPeople/LeaverCandidates";
import PromotionCandidates from "mapx-pages/Project/TrackingPeople/PromotionCandidates";
import NoChangeCandidates from "mapx-pages/Project/TrackingPeople/NoChangeCandidates";

const ProjectTrackingPeople = () => {
	return (
		<div className={styles.container}>
			<TrackingFilterSection />

			<LeaverCandidates />

			<PromotionCandidates />

			<NoChangeCandidates />
		</div>
	);
};

export default ProjectTrackingPeople;
