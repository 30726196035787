import {formatDistanceToNow} from "date-fns";
import isObject from "lodash/isObject";
import companySizeBand from "api/companyApi/companySizeBand.json";
import {TCompanyHeadcountRangeItem} from "mapx-components/Filters/CompanyHeadcountSearchFilter/types";
import {isString} from "lodash";

export const labelCapitalized = (str: string): string => {
	return str.replace(/(^\w|\s\w)(\S*)/g, (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase());
};

export const mergeClasses = (...args: string[]): string => {
	return [...args].join(" ");
};

export const convertTimeStampToDate = (timestamp: number | string): string => {
	const date = new Date(timestamp);
	const year = date.getFullYear();
	const month = date.getMonth() + 1;
	const day = date.getDate();

	return `${day}/${month}/${year}`;
};

export const convertUnixTimeStampToHumanReadableTime = (
	timestamp: number,
	addSuffix = false,
): string => {
	return formatDistanceToNow(new Date(timestamp), {addSuffix});
};

export const formatNumber = (number: number): string => {
	if (number < 1000) {
		return number.toString();
	} else if (number < 1000000) {
		const formatted = (number / 1000).toFixed(1);

		return formatted.endsWith(".0") ? formatted.slice(0, -2) + "k" : formatted + "k";
	} else if (number < 1000000000) {
		const formatted = (number / 1000000).toFixed(1);

		return formatted.endsWith(".0") ? formatted.slice(0, -2) + "m" : formatted + "m";
	} else {
		const formatted = (number / 1000000000).toFixed(1);

		return formatted.endsWith(".0") ? formatted.slice(0, -2) + "b" : formatted + "b";
	}
};

export const parseFormattedNumber = (formattedNumber: string): number => {
	const multiplier: {[key: string]: number} = {
		k: 1000,
		m: 1000000,
		b: 1000000000,
	};

	const numericPart = parseFloat(formattedNumber);

	const suffix = formattedNumber.charAt(formattedNumber.length - 1).toLowerCase();

	return numericPart * (multiplier[suffix] || 1);
};

export const formatRevenueNumber = (revenue: number | string): string => {
	// Handle zero or negative numbers
	if (!revenue || revenue <= 0) return "";

	revenue = parseInt(revenue.toString());

	// Convert to billion
	if (revenue >= 1000000000) {
		const billions = Math.floor(revenue / 1000000000);
		const remainingMillions = revenue % 1000000000;

		return remainingMillions === 0 ? `${billions}bn` : `${billions}bn+`;
	}

	// Convert to a million
	if (revenue >= 1000000) {
		const millions = Math.floor(revenue / 1000000);
		const remainingThousands = revenue % 1000000;

		return remainingThousands === 0 ? `${millions}m` : `${millions}m+`;
	}

	// Convert to a thousand
	if (revenue >= 1000) {
		const thousands = Math.floor(revenue / 1000);
		const remaining = revenue % 1000;

		return remaining === 0 ? `${thousands}k` : `${thousands}k+`;
	}

	return revenue.toString();
};

export const formatSizeBand = (size: number | string): string => {
	const sizeBandData = companySizeBand;

	size = Number(size);

	const selectedSizeBand = sizeBandData.find(
		(item: TCompanyHeadcountRangeItem) => size >= item.min_value && size < item.max_value,
	);

	if (selectedSizeBand) {
		return selectedSizeBand.headcount_display;
	} else {
		return "10+";
	}
};

export const displayInfoIfAvailableOnObject = <K extends string>(
	object: Record<K, unknown> | null | undefined,
	attribute: K,
	postfix = "",
): string => {
	return isObject(object) && object[attribute] != null ? `${object[attribute]}${postfix}` : " - ";
};

/**
 * Truncates "text" from the end of the string. Algorithm looks
 * for a dot and a space (". ") after the "from"th and before
 * the "to"th characters to determine end of the sentence. If
 * dot does not exist between the range then sentence will be
 * truncated after the comma and if comma is not exist as well as
 * then it will be truncated from a space (" ") and dot will
 * be added to the end.
 *
 */
export const truncateSentenceEnd = (text: string, from = 150, to = 200, rate = 10): string => {
	if (text && text.length > from) {
		let truncIndex = -1;

		let i = 0;
		let isComma = false;
		while (truncIndex === -1) {
			if (from === 50 && to === text.length) {
				return text;
			}

			from = from - rate * i > 50 ? from - rate * i : 50;
			to = to + rate * i < text.length ? to + rate * i : text.length;

			const range = text.slice(from, to);

			truncIndex = range.indexOf(". ");

			if (truncIndex === -1 && i >= 2) {
				truncIndex = range.indexOf(", ");

				if (truncIndex !== -1) {
					isComma = true;
				}
			}

			i++;
		}

		const suffix = isComma ? "..." : ".";

		return `${text.slice(0, from + truncIndex)}${suffix}`;
	}

	return text;
};

export function getName(name: string, limited = false): string {
	if (typeof name !== "string") return "";

	name =
		name === name.toUpperCase() || name === name.toLowerCase() ? labelCapitalized(name) : name;

	if (name === "It" || name === "Crm" || name === "Ai") {
		name = name
			.replaceAll("It", "IT")
			.replaceAll("Crm", "CRM")
			.replaceAll("Cfo", "CFO")
			.replaceAll("Hr", "HR")
			.replaceAll("Ai", "AI");
	}

	if (limited && name.length > 35) {
		return name.substring(0, 33).replaceAll('"', "") + "...";
	} else {
		return name;
	}
}

export const isRegexExactMatch = (value: string, regexp: RegExp): boolean => {
	const res = value?.match?.(regexp);

	return Boolean(res && res[0] && res[0] === res.input);
};

export const isLinkedinProfileUrl = (value: string): boolean => {
	const linkedInProfileURLRegExp =
		/^https?:(\/\/)?((w{3}||\w\w)\.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@\-/]))?/;

	return isRegexExactMatch(value, linkedInProfileURLRegExp);
};

export const linkedInUrlPurify = (value: string): string => {
	const separatedValues = value.split(/(https?:\/\/)/);

	let result = separatedValues.shift() || "";

	for (const element of separatedValues) {
		if (element.startsWith("https://") || element.startsWith("http://")) {
			result += "\n";
		}
		result += element;
	}

	const urls = result.trim().split("\n");

	const formattedUrls = urls.map((url) => {
		const segments = url.split("?")[0].split("/");
		if (segments.length >= 4) {
			return segments.slice(0, 4).join("/") + "/" + segments[4];
		}

		return url;
	});

	result = formattedUrls.join("\n");

	return result.trim();
};

export function convertToShortFormat(num: number | string): string {
	const match = num.toString()?.match(/0+$/);

	if (match) {
		const zeros = match[0];
		const amountOfZeros = zeros.length;

		if (amountOfZeros >= 9) {
			return (parseInt(num.toString()) / 1000000000).toString() + "b";
		} else if (amountOfZeros >= 6) {
			return (parseInt(num.toString()) / 1000000).toString() + "m";
		} else if (amountOfZeros >= 3) {
			return (parseInt(num.toString()) / 1000).toString() + "k";
		} else {
			return num.toString();
		}
	}

	return num.toString();
}

export function isValidId(value: string | number): boolean {
	if (typeof value === "number" || (typeof value === "string" && /^\d+$/.test(value))) {
		return Number(value) !== 0;
	}

	return false;
}

export function decodeCharacterReferences(input: string): string {
	const parser = new DOMParser();
	const dom = parser.parseFromString(`<!doctype html><body>${input}`, "text/html");

	return dom.body.textContent || input;
}

export function toHumanReadablePrice(priceInCents: number, currency: string): string {
	const priceInDollars = priceInCents / 100;

	return priceInDollars.toLocaleString("en-US", {
		style: "currency",
		currency: currency,
	});
}

export function slugifyForParameter(param: string): string {
	return `${param.toLocaleLowerCase().replace(/ /g, "_")}`;
}

export function removeTrailingSlash(str: string) {
	if (isString(str) && str.charAt(str.length - 1) === "/") {
		// If it is, remove it
		return str.slice(0, -1);
	}

	return str;
}
