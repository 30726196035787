import React, {useEffect} from "react";
import ProjectCandidateTableCard from "mapx-components/Cards/ProjectCandidateTableCard";
import {useAppDispatch, useAppSelector} from "hooks";
import {
	fetchingCandidateTrackPromotionsSelector,
	projectCandidateTrackPromotionsPaginationSelector,
	projectCandidateTrackPromotionsSelector,
	selectedTrackCandidatePositionChangedDaysSelector,
} from "store/mapx/project/projectCandidateTrackingSelectors";
import {SICandidate} from "api/candidateApi/types";
import {SkeletonLoaderCardAsList} from "components";
import Pagination from "mapx-components/Pagination";
import {ATPagination} from "api/types";
import {globalPerPageSelector} from "store/mapx/userPreference/userPreferenceSelectors";
import {setPerPagePreference} from "store/mapx/userPreference/userPreferenceActions";
import {getProjectTrackCandidatePromotions} from "store/mapx/project/projectCandidateTrackingAsyncActions";
import {cancelRequest} from "api/cancelTokens";
import {GET_PROJECT_TRACK_PROMOTION_PEOPLE_TOKEN} from "api/requestCancelTokenStrings";
import PeopleCardTableHeader from "mapx-components/Cards/ProjectCandidateTableCard/TableHeader";
import styles from "../styles.module.scss";

const PromotionCandidates = () => {
	const dispatch = useAppDispatch();

	const isFetching = useAppSelector(fetchingCandidateTrackPromotionsSelector);

	const promotionCandidates: SICandidate[] = useAppSelector(
		projectCandidateTrackPromotionsSelector,
	);

	const pagination: ATPagination = useAppSelector(
		projectCandidateTrackPromotionsPaginationSelector,
	);

	const globalPerPageValue = useAppSelector(globalPerPageSelector);

	const positionChanged = useAppSelector(selectedTrackCandidatePositionChangedDaysSelector);

	const onPageChange = async (page: number) => {
		await dispatch(
			getProjectTrackCandidatePromotions({
				page: page,
				per_page: globalPerPageValue,
			}),
		);
	};

	const onPerPageChange = async (count: number) => {
		dispatch(setPerPagePreference(count));

		await dispatch(
			getProjectTrackCandidatePromotions({
				page: pagination.page,
				per_page: count,
			}),
		);
	};

	useEffect(() => {
		async function get() {
			await dispatch(getProjectTrackCandidatePromotions());
		}

		get();

		return () => {
			cancelRequest(GET_PROJECT_TRACK_PROMOTION_PEOPLE_TOKEN);
		};
	}, [dispatch, positionChanged]);

	return (
		<div>
			<PeopleCardTableHeader type={"Promotion"} />
			{!isFetching && !promotionCandidates.length && (
				<div className={styles.noData}>No people found</div>
			)}

			{!isFetching &&
				promotionCandidates.length > 0 &&
				promotionCandidates.map((candidate, index) => {
					return (
						<div key={index}>
							<ProjectCandidateTableCard
								candidate={candidate}
								isNextCandidateRecommended={false}
								searchType="Project"
								type={"project-tracking-people"}
							/>
						</div>
					);
				})}

			{isFetching && <SkeletonLoaderCardAsList isMini count={5} />}

			{!isFetching && pagination && pagination?.count >= 10 && (
				<div className={styles.paginationWrapper}>
					<Pagination
						currentPage={pagination?.page}
						resultsCount={pagination?.count}
						totalPages={pagination?.pages}
						perPage={globalPerPageValue}
						onPageChange={onPageChange}
						onPerPageChange={onPerPageChange}
						scrollDelayAfterPageChange={400}
					/>
				</div>
			)}
		</div>
	);
};

export default PromotionCandidates;
