const SpecialismIcon = () => {
	return (
		<svg
			width="48"
			height="48"
			viewBox="0 0 48 48"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<mask
				id="mask0_2569_1505"
				style={{maskType: "alpha"}}
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="48"
				height="48"
			>
				<rect width="48" height="48" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_2569_1505)">
				<path
					d="M7.8845 41C7.10017 41 6.42333 40.7153 5.854 40.146C5.28467 39.5767 5 38.8998 5 38.1155V15.8845C5 15.1002 5.28467 14.4233 5.854 13.854C6.42333 13.2847 7.10017 13 7.8845 13H17V8.885C17 8.10067 17.2847 7.42367 17.854 6.854C18.4233 6.28467 19.1002 6 19.8845 6H28.1155C28.8998 6 29.5767 6.28467 30.146 6.854C30.7153 7.42367 31 8.10067 31 8.885V13H40.1155C40.8998 13 41.5767 13.2847 42.146 13.854C42.7153 14.4233 43 15.1002 43 15.8845V38.1155C43 38.8998 42.7153 39.5767 42.146 40.146C41.5767 40.7153 40.8998 41 40.1155 41H7.8845ZM19.2695 13H28.7305V8.885C28.7305 8.731 28.6665 8.58983 28.5385 8.4615C28.4102 8.3335 28.2692 8.2695 28.1155 8.2695H19.8845C19.7308 8.2695 19.5898 8.3335 19.4615 8.4615C19.3335 8.58983 19.2695 8.731 19.2695 8.885V13ZM40.7305 29.104H27.9345V31.873H20.127V29.104H7.2695V38.1155C7.2695 38.2692 7.3335 38.4102 7.4615 38.5385C7.58983 38.6665 7.73083 38.7305 7.8845 38.7305H40.1155C40.2692 38.7305 40.4102 38.6665 40.5385 38.5385C40.6665 38.4102 40.7305 38.2692 40.7305 38.1155V29.104ZM22.396 29.604H25.6655V26.3345H22.396V29.604ZM7.2695 26.8345H20.127V24.0655H27.9345V26.8345H40.7305V15.8845C40.7305 15.7308 40.6665 15.5898 40.5385 15.4615C40.4102 15.3335 40.2692 15.2695 40.1155 15.2695H7.8845C7.73083 15.2695 7.58983 15.3335 7.4615 15.4615C7.3335 15.5898 7.2695 15.7308 7.2695 15.8845V26.8345Z"
					fill="#837D7C"
				/>
			</g>
		</svg>
	);
};

export default SpecialismIcon;
