import React, {useCallback, useEffect, useMemo} from "react";
import styles from "../Diversity.module.scss";
import classNames from "classnames";
import {TextTabPane} from "mapx-components";
import {useAppDispatch, useAppSelector} from "hooks";
import {
	mrDiversePathwaysLoadingSelector,
	mrDiversePathwaysModeSelector,
	mrDiversePathwaysReportSelector,
	mrDiversePathwaysSelectedTypeSelector,
} from "store/mapx/market-report/marketReportSelector";
import {TDiversityPathwaysSelectedType} from "store/mapx/market-report/types";
import {
	setDiversityPathwaysMode,
	setDiversityPathwaysSelectedType,
} from "store/mapx/market-report/marketReportActions";
import {ArrowDown} from "assets/icons";
import {CustomBarChart, Dropdown} from "components";
import {
	TDiversityPathwaysModeObj,
	TDiversityPathwaysTitle,
} from "mapx-pages/MarketReportDetails/Diversity/types";
import {STDiversePathwaysMode, STDiversePathwaysReport} from "api/marketReportApi/types";
import {ICustomBarChartDataProps} from "components/Charts/CustomBarChart/types";

const pathwaysTitles: TDiversityPathwaysTitle[] = [
	{id: "by_function", title: "By Function"},
	{id: "by_industry", title: "By Industry"},
	{id: "by_country", title: "By Location"},
];

const OPTIONS: TDiversityPathwaysModeObj[] = [
	{value: "Gender or ethnicity", name: "Gender or ethnicity"},
	{value: "Gender", name: "Gender"},
	{value: "Ethnicity", name: "Ethnicity"},
	{value: "White", name: "White"},
	{value: "South Asian", name: "South Asian"},
	{value: "East Asian", name: "East Asian"},
	{value: "Middle Eastern", name: "Middle Eastern"},
	{value: "Black", name: "Black"},
	{value: "Hispanic", name: "Hispanic"},
];

const DiversityPathways = () => {
	const diversityPathways: STDiversePathwaysReport = useAppSelector(
		mrDiversePathwaysReportSelector,
	);

	const isLoading = useAppSelector(mrDiversePathwaysLoadingSelector);

	const activeTabSelection: TDiversityPathwaysSelectedType = useAppSelector(
		mrDiversePathwaysSelectedTypeSelector,
	);

	const activeModeSelection: STDiversePathwaysMode = useAppSelector(
		mrDiversePathwaysModeSelector,
	);

	const dispatch = useAppDispatch();

	const handleTabChange = useCallback(
		(id: TDiversityPathwaysSelectedType) => {
			dispatch(setDiversityPathwaysSelectedType(id));
		},
		[dispatch],
	);

	const activeChartData = useMemo(() => {
		if (diversityPathways) {
			let data: ICustomBarChartDataProps[];

			if (activeTabSelection === "by_function") {
				const functionData = diversityPathways.by_function;

				data = functionData.map((item) => ({
					id: item.job_function.id,
					count: item.candidates_count,
					percent: item.candidates_percent,
					name: item.job_function.name,
					children: item.specialisms.map((spec) => ({
						id: spec.specialism.id,
						count: spec.candidates_count,
						percent: spec.candidates_percent,
						name: spec.specialism.name,
					})),
				}));
			} else if (activeTabSelection === "by_industry") {
				const industryData = diversityPathways.by_industry;

				data = industryData.map((item) => ({
					id: item.industry.id,
					count: item.candidates_count,
					percent: item.candidates_percent,
					name: item.industry.name,
				}));
			} else {
				const countryData = diversityPathways.by_country;

				data = countryData.map((item) => ({
					id: item.country.id as number,
					count: item.candidates_count,
					percent: item.candidates_percent,
					name: item.country.name,
				}));
			}

			return data;
		} else {
			return [];
		}
	}, [activeTabSelection, diversityPathways]);

	const BAR_CHART = useMemo(
		() => (
			<CustomBarChart
				isRoundedChartVal={true}
				data={activeChartData}
				customClass={styles.barChartWrapper}
				isLoading={isLoading}
			/>
		),
		[activeChartData, isLoading],
	);

	const tabs = useMemo(() => {
		const functionTab = (
			<TextTabPane title="By Function" key="by_function">
				<div className={styles.tabContainer}>
					<div className={styles.chartContainer}>
						<h4 className={styles.barChartTitle}>
							The most common functions for diverse candidates to have experience of
						</h4>
						{BAR_CHART}
					</div>
				</div>
			</TextTabPane>
		);
		const industryTab = (
			<TextTabPane title="By Industry" key="by_industry">
				<div className={styles.tabContainer}>
					<div className={styles.chartContainer}>
						<h4 className={styles.barChartTitle}>
							The most common industries for diverse candidates to have experience of
						</h4>
						{BAR_CHART}
					</div>
				</div>
			</TextTabPane>
		);
		const locationTab = (
			<TextTabPane title="By Location" key="by_country">
				<div className={styles.tabContainer}>
					<div className={styles.chartContainer}>
						<h4 className={styles.barChartTitle}>
							The current location of diverse candidates
						</h4>
						{BAR_CHART}
					</div>
				</div>
			</TextTabPane>
		);

		return {
			by_function: functionTab,
			by_industry: industryTab,
			by_country: locationTab,
		};
	}, [BAR_CHART]);

	const renderTabOptions = pathwaysTitles.map(({id, title}) => (
		<div
			key={id}
			onClick={() => handleTabChange(id)}
			className={classNames(styles.tabTitle, {
				[styles.activeTab]: activeTabSelection === id,
			})}
		>
			{title}
		</div>
	));

	const modeOnSelect = useCallback(
		(option: TDiversityPathwaysModeObj) => {
			dispatch(setDiversityPathwaysMode(option.value));
		},
		[dispatch],
	);

	const displayedSelectedMode = useMemo(() => {
		return OPTIONS.find((option) => option.value === activeModeSelection)?.name;
	}, [activeModeSelection]);

	useEffect(() => {
		if (!activeTabSelection) {
			handleTabChange("by_function");
		}
	}, [activeTabSelection, handleTabChange]);

	useEffect(() => {
		if (!activeModeSelection) {
			dispatch(setDiversityPathwaysMode("Gender or ethnicity"));
		}
	}, [activeModeSelection, dispatch, modeOnSelect]);

	return (
		<div className={styles.diversePathways}>
			<div className={styles.titleSection}>
				<span>Most Diverse Pathways</span>

				<Dropdown
					key={"Default"}
					onSelect={modeOnSelect}
					options={OPTIONS}
					dropdownMenuClass={classNames(styles.dropdownMenu, {
						[styles.minimizedMenu]: activeChartData?.length === 0,
					})}
					getOptionLabel={(option) => option.name}
					defaultSelectedOption={
						OPTIONS.find((s) => s.value === displayedSelectedMode) || null
					}
					placeholder={
						<div className={styles.dropdownPlaceholder}>
							<div>{displayedSelectedMode}</div>
							<div>
								<ArrowDown />
							</div>
						</div>
					}
					dropdownButtonClass={styles.dropdownButton}
				/>
			</div>

			<div className={styles.tabWrapper}>
				<div className={styles.tabContainer}>
					<div className={classNames(styles.tabs)}>{renderTabOptions}</div>
				</div>
			</div>

			{tabs[activeTabSelection]}
		</div>
	);
};

export default DiversityPathways;
